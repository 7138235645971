import React, { useState, useEffect } from 'react';
import { AiOutlineUp } from "react-icons/ai";
import ValuationDetails from '../../valuationDetails/views/ValuationDetails';
import PropertyDetails from '../../propertyDetails/views/PropertyDetails';
import DocumentDetails from '../../documentDetails/views/DocumentDetails';
import DecisionDetails from '../../decisionDetails/views/DecisionDetails';
import Trail from '../../trail/views/Trail';
import { Tabs, message } from 'antd';
import "../styles/details.css";
import { getCreditAssessmentData } from "../services/details"
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { useParams } from 'react-router-dom';
import LoanSummaryBlock from '../../../../../utils/loanSummaryBlock/LoanSummaryBlock';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
const { TabPane } = Tabs;

const Details = ({ typeCheck }) => {
  const [creditAssessmentStageData, setCreditAssessmentStageData] =
    useState(null);
  const [attachments, setAttachments] = useState([]);
  const { encrypted_stage_id } = useParams();
  const [mode] = useState("top");

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
    console.log("stage", stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchCreditAssessmentStageData = async () => {
      try {
        const data = await getCreditAssessmentData(stage_id);
        setCreditAssessmentStageData(data.data);
        setAttachments(data.data.initiate_stage_attachment_data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchCreditAssessmentStageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    { label: "Valuation Details", component: <ValuationDetails stage_id={stage_id} /> },
    { label: "Property Details", component: <PropertyDetails stage_id={stage_id} /> },
    { label: "Document Details", component: <DocumentDetails stage_id={stage_id} /> },
    { label: "Decision Details", component: <DecisionDetails stage_id={stage_id} /> },
    { label: "Trail", component: <Trail /> },
  ];

  return (
    <>
      <div className="technical_valuation_first_container">
        <div>
          <h3 className="technical_heading">Technical Valuation Report</h3>
        </div>

      </div>
      <LoanSummaryBlock data={creditAssessmentStageData} typeCheck={typeCheck} attachments={attachments} />

      <div className="customer_main_container">
        <div
          className="customer_main_heading">
          <div>
            <h2 className="heading_loan_details">Details</h2>
          </div>
          <div className="customer_icon"><AiOutlineUp />
          </div>
        </div>
        <div>
          <Tabs tabPosition={mode} type="card" className='technical_valuation_details_tabs' >
            {tabs.map((tab, index) => (
              <TabPane tab={tab.label} key={index}>
                {tab.component}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
}
export default Details;
