import { Drawer, Modal, Space, Table, message } from "antd";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import {
    getRentalDetailsByIdLoanId,
    deleteRentalDetailsByIdLoanId
} from "../services/incomeDetailsServices";
import {
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";
import Header from "../../../../layout/views/Header";
import RentalForm from "../../../customerTabDetails/employmentDetails/views/RentalForm";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import authorizeTableColumns from "../../../../../utils/authorization/authorizeTableColumns";

const PureRentalView = ({ refreshData }) => {
    const [rentalData, setRentalData] = useState(null);
    const [selectedRental, setSelectedRental] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const { encrypted_loan_id } = useParams();
    const [openRental, setOpenRental] = useState(false);
    const [refreshTableData, setRefreshTableData] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const { user_data } = useSelector((state) => state.user);

    const showDrawerRental = () => {
        setOpenRental(true);
    };

    const onCloseRental = () => {
        setOpenRental(false);
    };
    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    const toggleRefreshTableData = () => {
        setRefreshTableData((prev) => !prev);
    };
    const col = [
        {
            title: "Customer Name/Parameter",
            dataIndex: "customer_name",
            render: (customer_name) => customer_name || " - ",  // Fallback if undefined
            width: 220,
            fixed: "left",
            className: "custom-header",
        },
        {
            title: "Property Type",
            dataIndex: "property_type",
            render: (property_type) => {
                return property_type?.display_name || " - ";  // Fallback if undefined
            },
        },
        {
            title: "Year Of Start (In Year)",
            dataIndex: "year_of_start",
            render: (year_of_start) => year_of_start || " - ",
        },
        {
            title: "Gross Rent",
            dataIndex: "gross_income",
            render: (gross_income) => (gross_income ? IndianNumberFormat(gross_income) : '-'),
        },
        {
            title: "Net Rent",
            dataIndex: "net_income",
            render: (net_income) => (net_income ? IndianNumberFormat(net_income) : '-'),
            width: 120,
        },
        {
            title: "Lessee Name",
            dataIndex: "lessee_name",
            render: (lessee_name) => lessee_name || " - ",
        },
        {
            title: "Date Of Agreement",
            dataIndex: "date_of_agreement",
            render: (date_of_agreement) => date_of_agreement || " - ",
        },
        {
            title: "Municipal Tax Paid By",
            dataIndex: "municipal_tax_paid_by",
            render: (municipal_tax_paid_by) => municipal_tax_paid_by || " - ",
        },
        {
            title: "Maintainance Paid By",
            dataIndex: "maintainance_paid_by",
            render: (maintainance_paid_by) => maintainance_paid_by || " - ",
        },
        {
            title: "GST Paid By",
            dataIndex: "gst_paid_by",
            render: (gst_paid_by) => gst_paid_by || " - ",
        },
        {
            title: "Rental Mode",
            dataIndex: "rental_mode",
            render: (rental_mode) => rental_mode || " - ",
        },
    ];

    const authorizedColumns = [
        {
            title: "Actions",
            fixed: "right",
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["EDITCAM1"]}
                        >
                            <EditOutlined
                                onClick={() => {
                                    setSelectedRental(record.id);
                                    setSelectedCustomerId(record.customer_id)
                                    showDrawerRental();
                                }}
                            />
                        </ConditionalRender>
                        <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["DELTCAM1"]}
                        >
                            <DeleteOutlined
                                onClick={() => {
                                    setSelectedRental(record?.id);
                                    setOpenModal(true);
                                }}
                            />
                        </ConditionalRender>
                    </Space>
                );
            },
        },
    ];

    const tableColumns = authorizeTableColumns(
        col,
        authorizedColumns,
        user_data?.permissions,
        ["EDITCAM1", "DELTCAM1"]
    );

    const tableData = [];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getRentalDetailsByIdLoanId(loan_id);
                setRentalData(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };

        fetchData();
    }, [loan_id, refreshTableData, refreshData]);

    // Safely process results
    rentalData?.results?.forEach((customer) => {
        const rentalDetails = customer?.customer_employment?.rental || []; // Fallback to an empty array if rental is undefined

        if (rentalDetails.length > 0) {
            // Process rental records if they exist
            rentalDetails.forEach((rental) => {
                tableData.push({
                    key: `${customer.customer_id}-${rental.id}`,
                    id: rental?.id,
                    customer_id: customer?.customer_id,
                    customer_name: customer.customer_name || " - ",  // Display customer name
                    property_type: rental.property_type || " - ",  // Default to " - " if undefined
                    year_of_start: rental.year_of_start || " - ",
                    gross_income: rental.gross_income || " - ",
                    net_income: rental.net_income || " - ",
                    lessee_name: rental.lessee_name || " - ",
                    date_of_agreement: rental.date_of_agreement || " - ",
                    municipal_tax_paid_by: rental.municipal_tax_paid_by || " - ",
                    maintainance_paid_by: rental.maintainance_paid_by || " - ",
                    gst_paid_by: rental.gst_paid_by || " - ",
                    rental_mode: rental.rental_mode || "-",
                });
            });
        }
    });

    const deleteRental = async () => {
        try {
            const response = await deleteRentalDetailsByIdLoanId(selectedRental);

            if (response.status === 200 && response.success === true) {
                message.success("Rental deleted successfully");
                setOpenModal(false);
                toggleRefreshTableData();
                setSelectedRental(null);
            }
        } catch (error) {
            setOpenModal(false);
            message.error(error.response.data.message);
        }
    };

    return (
        <>


            <Drawer
                title={
                    <Header
                        title={"Edit"}
                        onClose={onCloseRental}
                        name={
                            "Rental Form"
                        }
                    />
                }
                width={1000}
                onClose={onCloseRental}
                open={openRental}
                closable={false}
            >

                <RentalForm
                    closeForm={onCloseRental}
                    open={openRental}
                    id={selectedRental}
                    customer_id={selectedCustomerId}
                    refreshData={toggleRefreshTableData}
                />

            </Drawer>

            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    deleteRental();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>
            <div className="heading_details_container">
                <div className="heading_text">
                    Rental
                    <div className="" style={{ display: "flex" }}></div>
                </div>

                <div style={{ overflowX: "auto" }}>
                    <Table
                        className="insurance_table"
                        columns={tableColumns}
                        dataSource={tableData}
                        pagination={false}
                    />
                </div>
            </div>
        </>
    );
};

export default PureRentalView;
