import React, { useEffect } from "react";
import { Button, Form, Input, Select, Space, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
const VehicleForm = ({
  closeForm,
  vehicle,
  ownerShip,
  selectedScrutiny,
  editValue,
  setEnteredData,
  refreshData,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!selectedScrutiny) {
      form.resetFields();
    }
    if (editValue) {
      form.setFieldsValue({
        documents: [
          {
            vehicle_type: editValue.vehicle_type,
            key: editValue.key,
            model_no: editValue.model_no,
            ownership_type: editValue.ownership_type,
            registration_number: editValue.registration_number,
            financed_from: editValue.financed_from,
            loan_amount: editValue.loan_amount,
          },
        ],
      });
    }
  }, [selectedScrutiny, form, editValue]);

  const handleSubmit = (values) => {
    setEnteredData(values.documents); // Pass the entered data to the parent component
    form.resetFields();
    closeForm(); // Close the form after submitting
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.List name="documents" initialValue={[{ document: "", dated: "" }]}>
        {(fields, { add, remove }) => {
          const columns = [
            {
              title: "Sr. No",
              dataIndex: "key",
              key: "sr_no",
              render: (text, record, index) => index + 1,
              width: 80,
            },
            
            {
              title: "Vehicle Type",
              dataIndex: ["vehicle_type"],
              key: "vehicle_type",
              width: 80,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "vehicle_type"]}
                  rules={[
                    { required: true, message: "Vehicle type is required" },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    allowClear
                    className="vehicle_fi_input_select"

                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {vehicle?.map((veh) => (
                      <Select.Option key={veh} value={veh}>
                        {veh}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ),
            },
            {
              title: "Model No",
              dataIndex: ["model_no"],
              key: "model_no",
              width: 80,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "model_no"]}
                  rules={[{ required: true, message: "Please enter Model No" }]}
                >
                  <Input placeholder="Enter Model No" g
                    className="vehicle_fi_input_select"
                  />
                </Form.Item>
              ),
            },
            {
              title: "Ownership Type",
              dataIndex: ["ownership_type"],
              key: "ownership_type",

              width: 80,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "ownership_type"]}
                  rules={[
                    { required: true, message: "Ownership type is required" },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    allowClear
                    className="vehicle_fi_input_select"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {ownerShip?.map((own) => (
                      <option value={own}>{own}</option>
                    ))}
                  </Select>
                  {/* <Input placeholder="Enter Ownership Type" /> */}
                </Form.Item>
              ),
            },

            {
              title: "Vehicle No",
              dataIndex: ["registration_number"],
              key: "registration_number",
              width: 200,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "registration_number"]}
                  rules={[
                    { required: true, message: "Vehicle no is required" },
                    {
                      pattern: /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
                      message: "Vehicle no is invalid",
                    },
                  ]}
                >
                  <Input placeholder="Enter Vehicle No "
                    className="vehicle_fi_input_select" />
                </Form.Item>
              ),
            }
            ,

            {
              title: "Financed From",
              dataIndex: ["financed_from"],
              key: "financed_from",
              width: 200,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "financed_from"]}
                  rules={[
                    { required: true, message: "Financed from is required" },
                  ]}
                >
                  <Input placeholder="Enter Financed From"
                    className="vehicle_fi_input_select" />
                </Form.Item>
              ),
            },
            {
              title: "Loan Amount",
              dataIndex: ["loan_amount"],
              key: "loan_amount",
              width: 200,
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "loan_amount"]}
                  rules={[
                    { required: true, message: "Loan amount is required" },
                    {
                      pattern: /^\d+$/,
                      message: "Loan amount must be a number",
                    },
                  ]}
                >
                  <Input placeholder="Enter Loan Amount" className="vehicle_fi_input_select"
                  />
                </Form.Item>
              ),
            },
            {
              title: "Actions",
              fixed: "right",
              render: (_, record, index) => (
                <Space size="middle">
                  <DeleteOutlined
                     className={
                      fields.length === 1
                        ? "field-text single-field"
                        : "field-text multiple-fields"
                    }
                    
                    onClick={() => {
                      if (fields.length > 1) {
                        remove(index);
                      }
                    }}
                    disabled={fields.length === 1}
                    title={
                      fields.length === 1
                        ? "At least one vehicle is required"
                        : "Delete this vehicle"
                    }
                  />
                </Space>
              ),
            }
          ];

          return (
            <>
              <Table
                dataSource={fields.map((field) => ({
                  ...field,
                  key: field.key,
                }))}
                columns={columns}
                scroll={{ x: 1000 }}
                pagination={false}

              />
              {!selectedScrutiny && (
                <Button
                  type="primary"
                  onClick={() => add()}

                  className="add_vehicle_fi"
                >
                  Add Vehicle
                </Button>
              )}
            </>
          );
        }}
      </Form.List>
      <Space
        direction="horizontal"
        align="center"


        className="add_vehicle_button_ok"
      >
        <Button type="primary" htmlType="submit">
         Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default VehicleForm;


