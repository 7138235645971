
import React , { useEffect }from 'react'
import { Button,Form, Input, Select, Space, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;
const IdentificationDocumentForm = ({ closeForm,documentType, selectedScrutiny ,editValue,setEnteredData,refreshData}) => {
    const [form] = Form.useForm();

    useEffect(() => {
      if (!selectedScrutiny) {
        form.resetFields();
      }
      if (editValue) {
        form.setFieldsValue({
          documents: [
            {
              document_type_id: editValue.document_type_id,
              key: editValue.key,
              document_number: editValue.document_number,
            },
          ],
        });
      }
    }, [selectedScrutiny, form, editValue]);
    
  
    const handleSubmit = (values) => { 
      setEnteredData(values.documents);  
      form.resetFields();
      closeForm();  
  
    };

  return (
    <Form form={form}  onFinish={handleSubmit}>
    <Form.List name="documents" initialValue={[{ document_type_id: "", document_number: "" }]}>
      {(fields, { add, remove }) => {
        const columns = [
          {
            title: "Sr. No",
            dataIndex: "key",
            key: "sr_no",
            render: (text, record, index) => index + 1,
          },
          {
            title: "Document Type",
            dataIndex: ["document_type_id"],
            key: "document_type_id",
            render: (text, record, index) => (
            
              <Form.Item
                name={[fields[index].name, "document_type_id"]}
                rules={[{ required: true, message: "Please enter document type" }]}
              >
                  
                 <Select
                    placeholder="Please select"
                    allowClear 
                    showSearch
                    // onChange={handleDocumentNameChange}
                    // disabled={uploadDisabled}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {documentType?.map((legaldocumenttype) => (
                      <Option
                        key={legaldocumenttype.name}
                        value={legaldocumenttype.id}
                      >
                        {legaldocumenttype.display_name}
                      </Option>
                    ))}
                  </Select>
              </Form.Item>
            ),
          },
          {
            title: "Details",
            dataIndex: ["document_number"],
            key: "document_number",
            render: (text, record, index) => (
              <Form.Item
                name={[fields[index].name,"document_number"]}
                rules={[{ required: true, message: "Please enter document detail" }]}
              >
                <Input rows={1} placeholder="Enter Details" />
              </Form.Item>
            ),
          },
     
          {
            title: "Actions",
            fixed: "right",
            render: (_, record, index) => (
              <Space size="middle">
                <DeleteOutlined
                   className={
                    fields.length === 1
                      ? "field-text single-field"
                      : "field-text multiple-fields"
                  }
                  
                  onClick={() => {
                    if (fields.length > 1) {
                      remove(index);
                    }
                  }}
                  disabled={fields.length === 1}
                  title={
                    fields.length === 1
                      ? "At least one document is required"
                      : "Delete this document"
                  }
                />
              </Space>
            ),
          }
        ];

        return (
          <>
            <Table
              dataSource={fields.map((field) => ({ ...field, key: field.key }))}
              columns={columns}
              pagination={false}
            />
            {!selectedScrutiny && (
              <Button type="primary" onClick={() => add()} className='more_dpocumnet_rv_form'>
                More Documents
              </Button>
            )}
          </>
        );
      }}
    </Form.List>
    <Space direction="horizontal" align="center"  className='rv_input_button_submit_cancel'>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
      <Button onClick={closeForm}>Cancel</Button>
    </Space>
  </Form>
  )
}

export default IdentificationDocumentForm