import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import { createInsurancePlanMagmaService, getInsurancePlanMagmaByIdService, updateInsurancePlanMagmaByIdService } from "../services/insurancePlanMagma";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;
const validationSchema = Yup.object().shape({
    code: Yup.string()
        .required("Code is required")
        .matches(/^[A-Z0-9]+$/, 'Code can only contain capital letters and numbers')
        .min(8, "Code must be at least 8 characters")
        .max(8, "Code must be at max 8 characters")
        .label("Code"),
    name: Yup.string()
        .required("Name is required")
        .matches(/^[A-Za-z ]+$/, 'Name can only contain letters and spaces')
        .min(2, "Name must be at least 2 characters")
        .max(128, "Name must be at max 128 characters")
        .label("Name"),
    display_name: Yup.string()
        .required("Display name is required")
        .matches(/^[A-Za-z ]+$/, 'Display name can only contain letters and spaces')
        .min(2, "Display name must be at least 2 characters")
        .max(128, "Display name must be at max 128 characters")
        .label("Display name"),

    convalescence_benifits: Yup.string()
        .required("Convalescence benefits is required"),
    no_of_children: Yup.string()
        .required("No of children is required"),

    sum_insured: Yup.string()
        .required("Sum insured is required"),


    no_of_adults: Yup.string()
        .required(" No of adults is required"),
    yrs_18_to_45: Yup.string()

        .required("Yrs 18 to 45 is required"),


    yrs_46_to_65: Yup.string()

        .required(" Yrs 46 to 65 is required"),
    amount_excl_gst: Yup.string()
        .required("Amount excl gst is required"),

});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const InsurancePlanMagmaForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
    const [displayNameSynced, setDisplayNameSynced] = useState(true);
    const [form] = Form.useForm();

    const getInsurancePlanMagmaData = async () => {
        if (id) {
            try {
                const response = await getInsurancePlanMagmaByIdService(id);
                const {
                    name,
                    code,
                    display_name,
                    sum_insured,
                    no_of_adults,
                    no_of_children,
                    yrs_18_to_45,
                    yrs_46_to_65,
                    convalescence_benifits,
                    amount_excl_gst,
                    is_active,
                    is_verified,
                } =
                    response?.data;
                form?.setFieldsValue({
                    name,
                    code,
                    display_name,
                    sum_insured,
                    no_of_adults,
                    no_of_children,
                    yrs_18_to_45,
                    yrs_46_to_65,
                    convalescence_benifits,
                    amount_excl_gst,
                    is_active,
                    is_verified,
                });
                setDisplayNameSynced(true);
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }
    };

    const handleSubmit = async (values) => {
        try {
            if (id) {
                const response = await updateInsurancePlanMagmaByIdService(id, values);
                if ((response.status = 200 && response?.success)) {
                    message.success("Insurance Plan Magma successfully updated");
                    refreshData();
                    closeForm();
                }
            } else {
                const response = await createInsurancePlanMagmaService(values);
                if ((response.status = 201 && response?.success)) {
                    message?.success("Insurance Plan Magma  successfully created");
                    refreshData();
                    closeForm();
                }
            }
            // Reset displayNameSynced after successful submit
            setDisplayNameSynced(true);

        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
              );
        }
    };

    // Fetch department data when the form is initially opened or for editing
    useEffect(() => {
        if (open) {
            // If it's opened for editing, fetch department data
            getInsurancePlanMagmaData();
        } else {
            form.resetFields();
            setDisplayNameSynced(true); // Reset sync state when form is closed
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const handleNameChange = (e) => {
        let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
        newValue = capitalizeWords(newValue);
        form.setFieldsValue({ name: newValue });
        if (displayNameSynced) {
            form.setFieldsValue({ display_name: newValue });
        }
    };

    const handleDisplayNameChange = (e) => {
        let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
        newValue = capitalizeWords(newValue);
        form.setFieldsValue({ display_name: newValue });
        if (newValue === "") {
            setDisplayNameSynced(true); // Reset sync state if display_name is cleared
        } else {
            setDisplayNameSynced(false);
        }
    };

    const handleInput = (e, field) => {
        const newValue = e?.target?.value?.replace(/[^A-Za-z0-9\s]/g, ""); // Accept letters, numbers, and spaces
        form.setFieldsValue({ [field]: newValue });
    };

    const handleNumberFields = (e, field) => {
        const newValue = e?.target?.value?.replace(/[^\d.]/g, "");
        form.setFieldsValue({ [field]: newValue });
    };



    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
                name: "",
                display_name: "",
                code: "",
                sum_insured: "",
                no_of_adults: "",
                no_of_children: "",
                yrs_18_to_45: "",
                yrs_46_to_65: "",
                convalescence_benifits: "",
                is_active: true,
                is_verified: true,
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="name" label="Name" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Name"
                            onChange={handleNameChange}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="display_name" label="Display Name" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Display Name"
                            onChange={handleDisplayNameChange}
                        />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="sum_insured" label="Sum Assured" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Sum Assured"
                            onChange={(e) => handleNumberFields(e, "sum_insured")}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="no_of_adults" label="No Of Adults" rules={[yupSync]} required>
                        <Input placeholder="Please Enter No Of Adults"
                            onChange={(e) => handleNumberFields(e, "no_of_adults")}

                        />
                    </Form.Item>
                </Col>

            </Row>


            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="no_of_children" label="No Of children" rules={[yupSync]} required>
                        <Input placeholder="Please No Of Children" onChange={(e) => handleNumberFields(e, "no_of_children")}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="yrs_18_to_45" label="Yrs 18 To 45" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Yrs 18 To 45"
                            onChange={(e) => handleNumberFields(e, "yrs_18_to_45")}

                        />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="yrs_46_to_65" label="Yrs 46 To 65" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Yrs 46 To 65"
                            onChange={(e) => handleNumberFields(e, "yrs_46_to_65")}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="convalescence_benifits" label="Convalescence Benifits" rules={[yupSync]} required>
                        <Input placeholder="Please Enter convalescence_benifits"
                            onChange={(e) => handleInput(e, "convalescence_benifits")}
                        />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="amount_excl_gst" label="Amount Excl Gst" rules={[yupSync]} required>
                        <Input placeholder="Please Enter Amount Excl Gst"
                            onChange={(e) => handleNumberFields(e, "amount_excl_gst")}

                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="is_active" label="Is Active" required>
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>

                <Col span={12}>
                    <Form.Item name="is_verified" label="Is Verified" required>
                        <Select placeholder="Please Select">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                        </Select>
                    </Form.Item>
                </Col>
                {isEditMode ? (
                    <Col span={12}>
                        <Form.Item name="code" label="Code" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Code" onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, ""); }} />
                        </Form.Item>
                    </Col>
                ) : null}



            </Row>

            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default InsurancePlanMagmaForm;
