import {axiosRequest} from "../../../../../utils/api/axiosRequest"


export const getBureauDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/bureau/get-bureau-detail-tab-by-loan/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    } 
  })
}

export const getCibilReportByIdService = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/bureau/cibil/fetch-full-cibil-report-by-customer/${customer_id}`);
    
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
} 

 