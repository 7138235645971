import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const createPdInitiationDataService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/initiate-stage/pd-initiate", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllUserListService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user-list-by-loan-location?loan_id=${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllPdType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/pd-type-choice`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listAllDocumentCategoryService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/document-category/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCustomerListByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `api/v1/customer/customer-detail/customer-list/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getApplicantListByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `api/v1/customer/customer-detail/personal-detail-list/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getCustomerMobileNumberByIdService = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `api/v1/customer/customer-detail/get-mobile-number/${customer_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createTelePdService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/initiate-stage/pd-initiate", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getUserPersonalDetailsServiceInPd = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/user/get-personal-details/${id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};