import React from 'react'
import { Table, } from "antd";
import "../../partDisbursedInitiate/styles/partDisbursed.css";


const DpdString = () => {
  const dpdData = [
    {
      nov: "0",
      dec: "0",
      jan: "0",
      feb: "0",
      mar: "0",
      april: "0",
      may: "0",
      jun: "0",
      action: "0",
    },
    {
      
      nov: "0",
      dec: "0",
      jan: "0",
      feb: "0",
      mar: "0",
      april: "0",
      may: "0",
      jun: "0",
      action: "0",
    },
  ]
  const columns = [
    {
      title: "Nov/23",
      dataIndex: "nov",
      key: "nov",
      render: (text) => text || "-",
    },
    {
      title: "Dec/23",
      dataIndex: "dec",
      key: "dec",
    },
    {
      title: "Jan/24",
      dataIndex: "jan",
      key: "jan",
    },
    {
      title: "Feb/24",
      dataIndex: "feb",
      key: "feb",
    },
    {
      title: "Mar/24",
      dataIndex: "mar",
      key: "mar",
    },
    {
      title: "April/24",
      dataIndex: "april",
      key: "april",
    },

    {
      title: "May/24",
      dataIndex: "may",
      key: "may",
    },

    {
      title: "Jun/24",
      dataIndex: "jun",
      key: "jun",
    },

  ];


  return (
    <div>
        <>
          <div className="part_disbursed_table">
            <div className="part_disbursed_heading">
              <div className="part_disbursed_heading_data" >
              <h3>DPD String</h3>
              </div>
            </div>

            <div className='table_content'>
              <Table
                dataSource={dpdData}
                columns={columns}
                pagination={false}
              />
            </div>

          </div>
        </>
      </div>
  )
}

export default DpdString



