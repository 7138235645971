import React, { useState, useEffect } from 'react';
import { Tabs, message } from 'antd';
import '../styles/partDisbursalDetails.css';
import "../../partDisbursedInitiate/styles/partDisbursed.css";
import { getPartDisbursalDetailsByIdService, getApprovalDetailsByIdService } from "../services/partDisbursalDetailsService";
import BeneficiaryDetails from '../../beneficiaryDetails/views/BeneficiaryDetails';
import ApprovalDetails from "../../approvalDetails/views/ApprovalDetails";
import PartDisbursalHistory from '../../partDisbursalHistory/views/PartDisbursalHistory';
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import Loading from "../../../../utils/loading/Loading";
import { useParams } from "react-router-dom";
import RViewerJS from "viewerjs-react";
import dummy_img from "../assets/dummyImage.png";
import dayjs from "dayjs";
import CustomNoData from '../../../../utils/noDataIcon/CustomNoData';

const PartDisbursalDetails = () => {
  const {part_disbursement_id } = useParams();
  const [historyData, setHistoryData] = useState([]);
  const [partDisbursalDetails, setPartDisbursalDetails] = useState([]);
  const [activePartDisbursementId, setActivePartDisbursementId] = useState(0);
  const [selectedTabData, setSelectedTabData] = useState(null);
  const [refreshPartDisbursment, setRefreshPartDisbursment] = useState(0);
  const [loading, setLoading] = useState(true);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let partdisbursementid = null;
  try {
    partdisbursementid = decrypt(part_disbursement_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getPartDisbursalDetailsByIdService(loan_id);
        setPartDisbursalDetails(response?.data);
        setActivePartDisbursementId(partdisbursementid);

      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    };
    if (loan_id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshPartDisbursment ]);

  useEffect(() => {
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApprovalDetails = async () => {
    setLoading(true);
    try {
      const response = await getApprovalDetailsByIdService(activePartDisbursementId);
      if (response?.data) 
        {
        const history = response?.data?.history || [];
        const historyTableData = history.map((item, index) => ({
          id: index + 1,
          current_status: item.current_status || "-",
          initiated_date: item.initiated_date ? dayjs(item.initiated_date, "DD-MM-YYYY").format("DD-MM-YYYY") : "-",  // Formatting date
          completed_date: item.completed_date || null, 
          approval_stage: item.approval_stage || "-",
          assigned_to: item.assigned_to || null,
        }));
        setHistoryData(historyTableData);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    if (activePartDisbursementId) {
      fetchApprovalDetails();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePartDisbursementId]);

  const refreshHistoryTable = () => {
    fetchApprovalDetails();  
  };



  const onTabChange = (key) => {
    
    setActivePartDisbursementId(key);
    const selectedData = partDisbursalDetails.find(item => item.id.toString() === key);
    const selectedPartData = {
      disbursment_amount_requested: selectedData?.disbursment_amount_requested,
      construction_completion_recommended_as_per_user: selectedData?.construction_completion_recommended_as_per_user,
    };
    setSelectedTabData(selectedPartData);
  };

  useEffect(() => {
    if (activePartDisbursementId) {
      const selectedData = partDisbursalDetails.find(item => item.id.toString() === activePartDisbursementId);
      const selectedPartData = {
        disbursment_amount_requested: selectedData?.disbursment_amount_requested,
        construction_completion_recommended_as_per_user: selectedData?.construction_completion_recommended_as_per_user,
      };

      setSelectedTabData(selectedPartData);
    }
  }, [activePartDisbursementId, partDisbursalDetails]);

  

  return (
    <div className="part_disbursed_table">
      <div className="main_disbursal">
        <div className="part_disbursed_heading">
          <div className="part_disbursed_heading_data">
            <h3>Part Disbursal Details</h3>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : partDisbursalDetails?.length > 0 ? (
          <Tabs
            defaultActiveKey={activePartDisbursementId}
            type="card"
            onChange={onTabChange}
           className='part_disbursed_tabs_content'
          >
            {partDisbursalDetails?.map((item, index) => (
              <Tabs.TabPane
                tab={`Part Disbursment ${index + 1}`}
                key={item?.id}
              >

                <div className='main_part_disbursal_details_container' >
                  <div className='part_disbursal_container'>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Property Address</div>
                      <div className="part_disbursed_data">{item?.property_detail?.property_address}</div>
                    </div>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Construction Type</div>
                      <div className="part_disbursed_data">{item?.construction_type || '-'}</div>
                    </div>

                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Builder Name</div>
                      <div className="part_disbursed_data">{item?.property_detail?.property_builder_name}</div>
                    </div>
                  </div>

                  <div className='part_disbursal_container'>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Construction Description</div>
                      <div className="part_disbursed_data">
                      <div className="part_disbursed_data">{item?.construction_description}</div>
                      </div>
                    </div>

                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Disb Amount Requested</div>
                      <div className="part_disbursed_data">
                        ₹ {new Intl.NumberFormat('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(item?.disbursment_amount_requested)}
                      </div>
                    </div>

                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Disb Requested Date</div>
                      <div className="part_disbursed_data">{item?.disbursment_requested_date}</div>
                    </div>
                  </div>

                  <div className='part_disbursal_container'>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Type of Visit</div>
                      <div className="part_disbursed_data">{item?.type_of_visit}</div>
                    </div>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Property Structure</div>
                      <div className="part_disbursed_data">{item?.property_structure.name}</div>
                    </div>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Construction Stage</div>
                      <div className="part_disbursed_data">{item?.construction_stage.name}</div>
                    </div>
                  </div>

                  <div className='part_disbursal_container'>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Construction Completion % (Policy)</div>
                      <div className="part_disbursed_data">{item?.construction_completion_policy}%</div>
                    </div>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Construction Completion % (As per User)</div>
                      <div className="part_disbursed_data">{item?.construction_completion_as_per_user}%</div>
                    </div>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Construction Completion Recommendation % (Policy)</div>
                      <div className="part_disbursed_data">{item?.construction_completion_recommended_policy}%</div>
                    </div>
                  </div>

                  <div className='part_disbursal_container'>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Construction Completion Recommendation % (As per User)</div>
                      <div className="part_disbursed_data">{item?.construction_completion_recommended_as_per_user}%</div>
                    </div>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Raised By</div>
                      <div className="part_disbursed_data">{item?.created_by.name}</div>
                    </div>

                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Credit Percentage Recommended</div>
                      <div className="part_disbursed_data">{item?.credit_percentage_recommended}%</div>
                    </div>
                  </div>

                  <div className='part_disbursal_container'>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Construction Stop</div>
                      <div className="part_disbursed_data">{item?.construction_stop}</div>
                    </div>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Loan Downsizing Recommendation</div>
                      <div className="part_disbursed_data">{item?.loan_downsizing_recommendation}</div>
                    </div>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Current Status</div>
                      <div className="part_disbursed_data">{item?.current_status.display_name}</div>
                    </div>
                  </div>

                  <div className='part_disbursal_container part_disbursed_tabs_last_content'>
                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Percentage Commercial Area</div>
                      <div className="part_disbursed_data">{item?.percentage_commercial_area}%</div>
                    </div>


                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label">Credit Approval Amount</div>
                      <div className="part_disbursed_data">
                        ₹ {new Intl.NumberFormat('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(item?.credit_approval_amount)}
                      </div>
                    </div>

                    <div className='part_disbursal_card1'>
                      <div className="part_disbursed_label"></div>
                      <div className="part_disbursed_data"></div>
                    </div>
                  </div>
                  <div className="part_disbursed_data" >
                    <h3>Part Disbursal Image</h3>
                  </div>

                  <div className='part_disbursal_image part_disbursed_tabs_last_content'>
                    {item?.part_disbursment_documents && item?.part_disbursment_documents?.length > 0 ? (
                      item?.part_disbursment_documents?.map((doc) => (
                        <div className='part_disbursal_card1_image' key={doc?.id}>
                          <div className="part_disbursed_label">{doc?.name}</div>

                          <RViewerJS
                            options={{
                              url: (image) =>
                                image.getAttribute("data-original"),
                            }}
                          >
                            <img
                              src={doc?.thumb_file_path || dummy_img}
                              data-original={doc?.file_path || dummy_img}
                              width="100px"
                              height="100px"
                              alt={doc.name}
                              className={doc?.thumb_file_path ? 'thumb-box' : 'default-box'}
                            />
                          </RViewerJS>
                        </div>
                      ))
                    ) : (
                      <div>No Documents Available</div>
                    )}
                  </div>
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>

        ) : (
          <div className="no_data_found_message">
             <CustomNoData />
          </div>
        )}

      </div>

      <div >
        <BeneficiaryDetails />
        <PartDisbursalHistory tabDisbursementId={activePartDisbursementId} data={historyData}/>
        <ApprovalDetails partdisbursementid={partdisbursementid} tabDisbursementId={activePartDisbursementId}  tabDisbursmentData={selectedTabData} data={historyData} onHistoryTableRefresh={refreshHistoryTable} refreshPartDisbursmentTab={setRefreshPartDisbursment}/>
      </div>
    </div>
  );
};

export default PartDisbursalDetails;

