function replaceMentionsWithNames(content, userNameList) {
    if (!content || !Array.isArray(userNameList)) return content;
    
    // Create a map for faster user lookup
    const userMap = new Map(userNameList?.map(user => [user.id, user.name]));
    
    // Replace each @id with the corresponding name
    return content.replace(/@(\d+)/g, (match, id) => {
      const userId = parseInt(id);
      const userName = userMap.get(userId);
      return userName ? `@${userName} ${userId}@` : match;
    });
  }
  
  // Process messages array
 export const  processMessages = (messages, userNameList) => {
    return messages?.map((item) => {
      return {
        ...item,
        content: replaceMentionsWithNames(item.content, userNameList)
      };
    });
  }