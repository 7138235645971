import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  getRelationShip
} from "../../services/getCustomerDetailData";
import * as Yup from "yup";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { updatePersonContactedAtResidenceById } from "../../services/applicantRvServices";
const validationSchema = Yup.object().shape({
  relationship_with_applicant: Yup.string()
    .required("Relation with applicant is required"),
  no_of_years_at_current_address: Yup.string()
    .required("No. of years at current address is required"),
  no_of_years_in_current_city: Yup.string()
    .required("No. of years in city is required"),
  owner_name: Yup.string()
    .required("Owner name is required"),
  rent_amount: Yup.string()
    .required("Rent amount is required"),
  permanent_address: Yup.string()
    .required("Permanent address is required"),
  area_in_sq_feet: Yup.string()
    .required("Area in sq. ft. is required"),
  is_rented: Yup.string()
    .required("Is rented is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};


const PersonContactedAtResidenceEditForm = ({ customerDetails, closeForm, updateId, open, refreshData }) => {
  const [form] = Form.useForm();
  const [relationShip, setRelationship] = useState([]);
  const [isRented, setIsRented] = useState(true);
  const { Option } = Select;

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };


  const handleIsRented = (value) => {
    setIsRented(value);
    if (!value) {
      form.resetFields(["rent_amount", "permanent_address"]);
    }
  };

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getRelationShip, setRelationship);
  }, []);

  const getData = async () => {
    if (updateId) {
      try {
        const {
          relationship_with_applicant,
          no_of_years_at_current_address,
          no_of_years_in_current_city,
          area_in_sq_feet,
          owner_name,
          rent_amount,
          permanent_address,
          is_rented
        } = customerDetails;
        form.setFieldsValue({
          relationship_with_applicant: relationship_with_applicant?.id,
          no_of_years_at_current_address,
          no_of_years_in_current_city,
          area_in_sq_feet,
          owner_name,
          rent_amount,
          permanent_address,
          is_rented
        });
        setIsRented(customerDetails?.is_rented);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateId]);

  const handleSubmit = async (values) => {
    try {
      if (updateId) {
        const response = await updatePersonContactedAtResidenceById(updateId, values);
        if ((response.status = 200 && response.success)) {
          message.success("Residence Details successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}

      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="relationship_with_applicant"
              label="Relationship With Applicant"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                className="select_field_rv"

                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {relationShip?.map((relation) => (
                  <Option value={relation.id}>{relation.display_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="no_of_years_at_current_address"
              label="No. Of Years At Current Address"
              required
              rules={[yupSync]}
            >
              <Input
                type="number"
                placeholder="Please Enter No. Of Years At Current Address"
              // onChange={(e) => handleInput(e, "no_of_years_at_current_address")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="no_of_years_in_current_city"
              label="No.Of Years In city"
              required
              rules={[yupSync]}
            >
              <Input
                type="number"
                placeholder="Please Enter No.Of Years In city"
              // onChange={(e) => handleInput(e, "no_of_years_in_current_city")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="owner_name" label="House owner's Name" required
              rules={[yupSync]}>
              <Input
                placeholder="Please Enter House owner's Name"
                onChange={(e) => handleInput(e, "owner_name")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="area_in_sq_feet"
              label="Residence Area In Sq. Ft."
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Residence Area In Sq. Ft."
              // onChange={(e) => handleInput(e, "area_in_sq_feet")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="is_rented"
              label="Is Rented"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
              className="select_field_rv"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={handleIsRented}
              >
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>

            </Form.Item>
          </Col>

        </Row>

        <Row gutter={12}>
        {isRented && (
            <>
              <Col span={8}>
                <Form.Item
                  name="rent_amount"
                  label="Please Mention Rent Amount"
                  required
                  rules={[yupSync]}
                >
                  <Input
                    type="number"
                    placeholder="Please Enter Mention Rent Amount"
                  // onChange={(e) => handleInput(e, "rent_amount")}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="permanent_address"
                  label="Please Mention Permanent Address"
                  required
                  rules={[yupSync]}Is Rented
                >
                  <Input
                    placeholder="Please Enter Permanent Address"
                    onChange={(e) => handleInput(e, "permanent_address")}
                  />
                </Form.Item>
              </Col>
            </>
          )}
       
        </Row>

        <Space
          direction="horizontal"
          align="center"
         className="rv_button_submit_cancel"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default PersonContactedAtResidenceEditForm;
