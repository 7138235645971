import { Button, Checkbox, Col, Form, Input, Row, Select, Space, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import "../style/legalInitiationForm.css";
import "../../commonCSS/initiationFormCommonForm.css"
import { PlusOutlined } from '@ant-design/icons';
import ErrorMessage from '../../../../../../utils/errorHandling/ErrorMessage';
import {
  getAllAgencyService,
  getAllAgentService,
  getAllTransactionService,
  createLegalInitiationDataService,
  getPropertyDetailsById,
  getLoanDetailsById,
  getCustomerNameByIdService
} from "../Services/legalInitiationService"
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../../utils/cryptoUtils/cryptoUtils';
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import ConditionalRender from '../../../../../../utils/authorization/AuthorizeComponent';

const validationSchema = Yup.object().shape({
  agency: Yup.string()
    .required("Vendor is required")
    .min(1, "Vendor is required"),
  agent: Yup.string()
    .required("Agent name is required")
    .min(1, "Agent name is required"),
  transaction_type: Yup.string()
    .required(" Transaction is required")
    .min(1, "Transaction is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const LegalInitiationForm = ({ closeForm, open, agencyCode ,setSubmitStageBar}) => {
  const [form] = Form.useForm();
  const { encrypted_loan_id } = useParams();
  const [value, setValue] = useState("");
  const [allAgencies, setAllAgencies] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [PropertyAddress, setpropertyAddress] = useState([]);
  const [loanDetails, setLoanDetails] = useState([]);
  const [applicantType, setApplicantType] = useState('');
  const [selectedTransactionType, setSelectedTransactionType] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isPropertyChecked, setIsPropertyChecked] = useState(true);
  const [isAgencySelected, setIsAgencySelected] = useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [loading, setLoading] = useState(false);
  const [customerMobileNumber ,setCustomerMobileNumber] =useState('');

  const generateMailSubject = () => {
    const locationName = loanDetails?.location?.display_name || '';
    const loanAccountNumber = loanDetails?.loan_account_number || '';
    return `Legal initiate  - ${loanAccountNumber} - ${customerName && `${customerName} (${applicantType})`} - ${locationName}`;
  };

  const { user_data } = useSelector((state) => state.user);
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  useEffect(() => {
    const getAllAgencyTypes = async () => {
      try {
        const response = await getAllAgencyService(agencyCode);
        setAllAgencies(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllTransactions = async () => {
      try {
        const response = await getAllTransactionService();
        setAllTransactions(response.data.choices);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllPropertyDetails = async () => {
      try {
        const response = await getPropertyDetailsById(loan_id);
        setpropertyAddress(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllLoanDetails = async () => {
      try {
        const response = await getLoanDetailsById(loan_id);
        setLoanDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllCustomers = async () => {
      try {
        const response = await getCustomerNameByIdService(loan_id);
        const customers = response.data.customers;
        const applicant = customers.find(
          customer => customer.customer_type.display_name === "Applicant" || "Company Applicant");

        if (applicant) {
          setCustomerName(applicant.name);
          setApplicantType(applicant.customer_type.display_name);
          setCustomerMobileNumber(applicant.mobile_number)
        }
        if (user_data?.email) {
          form.setFieldsValue({ mail_cc: user_data.email });
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    if (open) {
      getAllAgencyTypes();
      getAllTransactions();
      getAllPropertyDetails();
      getAllLoanDetails();
      getAllCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, user_data, open]);


  const handleSubmit = async (values) => {

    setLoading(true);
    try {
      try {
        const valuesWithoutAgency = { ...values };
        delete valuesWithoutAgency.agency;
        delete valuesWithoutAgency.mail_subject;

        const formData = new FormData();

        // Concatenate mail_cc and add_cc
        const mailCC = valuesWithoutAgency.mail_cc;
        const addCC = valuesWithoutAgency.add_cc;
        const concatenatedCC = [mailCC, addCC].filter(Boolean).join(',');

        for (let key in valuesWithoutAgency) {
          if (key === 'mail_cc') {
            formData.append(key, concatenatedCC);
          } else if (key !== 'add_cc') { // Exclude add_cc
            formData.append(key, valuesWithoutAgency[key]);
          }
        }
        fileList.forEach(file => {
          formData.append('attachment', file.originFileObj);
        });

        // Generate the email body content
        const emailBodyContent = `<style>
      .email_div_container {
          border: 1px solid #D9D9D9;
          border-radius: 5px;
          margin-top: 20px;
      }
      
      .content_block {
          display: flex;
          flex-direction: row;
      }
      
      .email_heading {
          width: 23%;
          padding: 10px;
          /* color: #000000B2; */
          border-right: 1px solid #D9D9D9;
      }
      
      .email_content {
          padding: 10px 10px 0px 10px;
          width: 77%;
      }
      
      .initiation_form_table_one {
          margin-top: 10px;
          border: 1px solid #b8b3b373;
          border-radius: 8px;
      }
      
      
      .initiation_form_table_data {
          border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;
          /* margin:10px 0px 10px 0px ; */
      }
      
      .initiation_form_table_data_heading {
          width: 35%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
          border-right: 1px solid #D9D9D9;
      }
      
      .initiation_form_table_data_value {
          padding: 10px;
          width: 65%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
      
      }
      </style>
      <span 
          width: 77%;">Dear Team,<br>
        Please initiate Legal and Title Search for ${customerName ? `${customerName} (${applicantType})` : ''}<br>
        <div style="margin-top: 10px;
          border: 1px solid #b8b3b373;
          border-radius: 8px;">
          <div style="   border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
            <div style="       width: 35%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
          border-right: 1px solid #D9D9D9;">Customer Name</div>
            <div style="      padding: 10px;
          width: 65%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;">${customerName ? `${customerName} (${applicantType})` : ''}</div>
          </div>
          <div style="  border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
            <div style="  width: 35%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
          border-right: 1px solid #D9D9D9;">LAN</div>
            <div style="padding: 10px;
          width: 65%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;">${loanDetails?.loan_account_number || '-'}</div>
          </div>
           <div style="  border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
           
           
          </div>
          <div style="border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
            <div style="  width: 35%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
          border-right: 1px solid #D9D9D9;">Product</div>
            <div style="      padding: 10px;
          width: 65%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;">${loanDetails?.loan_type?.display_name || '-'}</div>
          </div>
          
          <div style="border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
            <div style="       width: 35%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
          border-right: 1px solid #D9D9D9;">Transaction Type</div>
            <div style="      padding: 10px;
          width: 65%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;">${selectedTransactionType || '-'}</div>
          </div>
          <div style="border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
            <div style=" width: 35%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
          border-right: 1px solid #D9D9D9;">Property Address</div>
            <div style="    padding: 10px;
          width: 65%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;">${concatenatedAddress}</div>
          </div>
          <div style="    border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
            <div style="  width: 35%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
          border-right: 1px solid #D9D9D9;">Branch</div>
            <div style="    padding: 10px;
          width: 65%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;">${loanDetails?.location?.display_name || '-'}</div>
          </div>
          <div style=" border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
            <div style="   width: 35%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          padding: 10px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;
          border-right: 1px solid #D9D9D9;">Contact Person</div>
            <div style="padding: 10px;
          width: 65%;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #000000B2;
          margin-left: 8px;">(Vastu - Vishal Yadav, 8654366784)<br>
              Customer Details:<br>
              ${customerName ? `${customerName} (${applicantType}) - ${customerMobileNumber} ` : ''}
              </div>
          </div>
        </div>
        </span>
      `;


        formData.append('mail_body', emailBodyContent);
        formData.append('loan_detail', loan_id);
        formData.append('stage_initiate', 'Legal');
        formData.append('stage', "MSLGLL6");
        formData.append('stage_status', "MSSTIDA4");
        formData.append('agency', selectedAgencyId);
        formData.append('mail_subject', generateMailSubject());
        if (isPropertyChecked) {
          formData.append('property_address', concatenatedAddress);
        }

        const response = await createLegalInitiationDataService(formData);
        if (response.status === 200 && response.success) {
          message.success('Legal initiation created successfully');
          setSubmitStageBar();
          form.resetFields();
          setFileList([]);
          setValue(null);
          setIsAgencySelected(false);
          closeForm();
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message));

        // closeForm();
        // setLoading(false)


        // }finally{  
        //   setLoading(false)
        //   console.log("Loading stopped.");
        // }
      }
    } catch (error) {
      console.error("Error occurred while submitting:", error);
      message.error("An error occurred while processing the request");
    } finally {
      setLoading(false); // Stop loading after the process completes
     
    }
  };

  const handleUpload = ({ fileList: newFileList }) => {

    setFileList(newFileList);
  };
  const handleCheckboxChange = (e) => {
    setIsPropertyChecked(e.target.checked);
  };

  const handleAgencyChange = (value) => {
    if (!value) {
      setAllAgents([]);
      setSelectedAgencyId('');
      setIsAgencySelected(false);
      form.setFieldsValue({ agent: undefined });
      form.setFieldsValue({ mail_to: undefined });
    } else {
      const selectedAgency = allAgencies.find(agency => agency.code === value);
      setSelectedAgencyId(selectedAgency ? selectedAgency.id : '');
      setIsAgencySelected(!!value);
      form.setFieldsValue({ agent: undefined });
      if (value) {
        getAllAgents(value); // Call with the selected value
        // form.setFieldsValue({ agent: null })  
      }
    }
  };

  const getAllAgents = async (agencyCode) => {
    try {
      const response = await getAllAgentService(agencyCode, loan_id);
      setAllAgents(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleAgentChange = (agentId) => {
    if (!agentId) {
      form.setFieldsValue({ mail_to: undefined });
    } else {
      const selectedAgent = allAgents.find(agent => agent.id === agentId);

      // Update the mail_cc field with the selected agent's email
      if (selectedAgent) {
        form.setFieldsValue({
          mail_to: selectedAgent.email,
        });
      }
    }
  };

  useEffect(() => {
    form.resetFields();
    setIsAgencySelected(false);
    setFileList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeForm]);
  const concatenatedAddress = `${PropertyAddress?.address_line_1 || ''}, ${PropertyAddress?.address_line_2 || ''}, ${PropertyAddress?.landmark || ''}, ${PropertyAddress?.taluka?.name || ''}, ${PropertyAddress?.district?.name || ''}, ${PropertyAddress?.city?.name || ''}, ${PropertyAddress?.pincode?.name || ''}, ${PropertyAddress?.state?.name || ''}, ${PropertyAddress?.country?.name || ''}`;
  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          // agency: "",
          // agent: "",
          // transaction_type: "",
          mail_to: "",
          mail_cc: "",
          mail_subject: "",
          remark: "",
          attachment: "",
        }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="agency" label="Vendor" rules={[yupSync]} required>
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                className='select_field'
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleAgencyChange}
              >
                {allAgencies?.map((agency_type) => {
                  return (
                    <Select.Option key={agency_type.code}
                      //  value={{ code: agency_type.code, id: agency_type.id }}
                      value={agency_type.code}
                    >
                      {agency_type.display_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="agent" label="Agent Name" rules={[yupSync]} required>
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                className='select_field'
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleAgentChange}
                disabled={!isAgencySelected || allAgents?.length === 0}
              >
                {allAgents?.map((agents) => {
                  return (
                    <Select.Option key={agents.id} value={agents.id}>
                      {agents.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="transaction_type" label="Choose Transaction" rules={[yupSync]} required>
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                className='select_field'
                onChange={(value) => setSelectedTransactionType(value)}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {allTransactions?.map((transaction) => (
                  <Select.Option key={transaction.value} value={transaction.value}>
                    {transaction.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div  >
          <div >Properties</div>



          <div className='checkbox-button'>
            <div>
              <Checkbox
                onChange={handleCheckboxChange}
                checked={isPropertyChecked}
              />
            </div>

            <div className='legal_property_heading'>Property  :</div>
            <div className='legal_property_data'> {concatenatedAddress}</div>
          </div>


          {!isPropertyChecked && (
            <div className='property_check'>
              Property is required
            </div>
          )}
          {/* <div className='legal_note'>Note: Please edit the same in sales form.</div> */}
        </div>


        <div className="email_div_container">
          <div>
            <div className="content_block">
              <span className="email_heading"> <span className='all_required_sign'> *</span>To:</span>
              <span className="email_content">
                <Form.Item
                  name="mail_to"
                  rules={[{ required: true, message: 'Please enter the email' }]}
                >
                  <Input disabled />
                </Form.Item>
              </span>
            </div>


            <div className="content_block">
              <span className="email_heading"> <span className='all_required_sign'> *</span>CC :</span>
              <span className="email_content">
                <Form.Item name="mail_cc"
                  rules={[{ required: true, message: 'Please enter the cc' }]}
                >
                  <Input disabled />
                </Form.Item></span>
            </div>
            <div className="content_block">
              <span className="email_heading"> Add CC :</span>
              <span className="email_content"><Form.Item name="add_cc" >
                <Input />
              </Form.Item></span>
            </div>
            <div className="content_block">
              <span className="email_heading"> <span className='all_required_sign'> *</span>Subject :</span>
              <span className="email_content">
                <Form.Item name="mail_subject">
                  {generateMailSubject()}
                </Form.Item></span>
            </div>
            <div className="content_block">
              <span className="email_heading">Message :</span>
              <span className="email_content">Dear Team,<br></br>
                Please initiate Legal and Title Search for {customerName && `${customerName} (${applicantType})`}<br></br>


                <div className="initiation_form_table_one ">
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">Customer Name</div>
                    <div class="initiation_form_table_data_value">{customerName && `${customerName} (${applicantType})`}</div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      LAN
                    </div>
                    <div className="initiation_form_table_data_value">{loanDetails?.loan_account_number || '-'}</div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Product
                    </div>
                    <div className="initiation_form_table_data_value">{loanDetails?.loan_type?.display_name || '-'}</div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">Transaction Type</div>
                    <div className="initiation_form_table_data_value">{selectedTransactionType && (
                      <>
                        {selectedTransactionType}
                      </>
                    )}</div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">Property Address</div>
                    <div className="initiation_form_table_data_value">{concatenatedAddress}</div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">Branch</div>
                    <div className="initiation_form_table_data_value">{loanDetails?.location?.display_name || '-'}</div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">Contact Person</div>
                    <div className="initiation_form_table_data_value">{customerName && `${customerName} (${applicantType})`}
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <div className="content_block">
              <span className="email_heading">
                <span className='all_required_sign'> *</span> Remarks:<br />
                (If attached, please mention the list of documents)
              </span>
              <span className="email_content">
                <Form.Item
                  name="remark"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter remarks'
                    }
                  ]}
                >
                  <TextArea rows={4} placeholder="Please Enter Remark" value={value}
                    onChange={(val) => {
                      setValue(val);
                      // form.setFieldsValue({ remark: val });
                    }} />
                </Form.Item>
              </span>
            </div>

            <div className="content_block">
              <span className="email_heading">Attachments :</span>
              <span className="email_content">
                <Form.Item
                >
                  <Upload
                    multiple
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={fileList}
                    onChange={handleUpload}
                    beforeUpload={() => false}

                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                    }}

                    rules={[{ required: true, message: 'Please upload document' }]}

                  >
                    <button className='upload_button' type="button">
                      <PlusOutlined />
                      <div >Upload</div>
                    </button>
                  </Upload>
                </Form.Item>
              </span>
            </div>



          </div>
        </div>
        <Space
          direction="horizontal"
          align="center"
          className='fi_button_inition'
        >
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["LEGALINI"]}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Initiate
            </Button>
          </ConditionalRender>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
        <br></br>
      </Form>

    </>
  )
}

export default LegalInitiationForm