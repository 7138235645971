import React, { useState } from "react";
import { Space, Button, Modal, message, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ReceiptForm from "./ReceiptForm";
import { deleteReceipt } from "../services/receiptService";
import Header from "../../layout/views/Header";
import DataTable from "../../../utils/dataTable/DataTable";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../utils/authorization/AuthorizeComponent";
import authorizeTableColumns from "../../../utils/authorization/authorizeTableColumns";
import "../style/receipt.css";

const Receipt = () => {
  const { Title } = Typography;
  const [receipt, setReceipt] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { user_data } = useSelector((state) => state.user);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedReceipt(null);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const columns = [
    {
      title: "Loan Number",
      dataIndex: "loan_detail",
      // sorter: true,
      width: 200,
      fixed: "left",
      render: (loan_detail) =>
        loan_detail ? loan_detail.loan_account_number : " - ",
    },
    {
      title: "Pictures",
      dataIndex: "reciept",

      render: (_, record) => {

        return record.reciept ? (
          <div className="receipt_image_view">
            <>
              <RViewerJS
                options={{
                  url: (image) => image.getAttribute("data-original"),
                }}
              >
                <img
                  src={record.thumb_reciept} // Thumbnail image
                  data-original={record.reciept} // Full-size image for preview
                  alt="Receipt"
                  className="receipt_image_pointer"
                />
              </RViewerJS>
            </>
          </div>
        ) : (
          " - "
        )
      },
      width: 200,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      // sorter: true,
      render: (name) => {
        return name ? name : " - ";
      },
    },
    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      render: (payment_mode) => {
        return payment_mode ? payment_mode : " - ";
      },
    },

    {
      title: "UTR/Cheque Number",
      dataIndex: "utr_cheque_number",
      render: (utr_cheque_number) => {
        return utr_cheque_number ? utr_cheque_number : " - ";
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) => {
        return amount ? `₹${new Intl.NumberFormat('en-IN').format(amount)}` : " - ";
      },
    },
    
    {
      title: "Instrument Date",
      dataIndex: "instrument_date",
      render: (instrument_date) => {
        return instrument_date ? instrument_date : " - ";
      },
    },
    {
      title: "Receipt Date",
      dataIndex: "reciept_date",
      render: (reciept_date) => {
        return reciept_date ? reciept_date : " - ";
      },
    },
    {
      title: "Customer Branch",
      dataIndex: "bank_branch",
      render: (branch) => {
        return branch ? branch.display_name : " - ";
      },
    },
    {
      title: "Receipt Status",
      dataIndex: "reciept_status",
      render: (reciept_status) => {
        let statusClass = "";

        if (reciept_status === "Completed") {
          statusClass = "status-completed";
        } else if (reciept_status === "Pending") {
          statusClass = "status-pending";
        } else if (reciept_status === "Rejected") {
          statusClass = "status-rejected";
        }

        return (
          <span className={statusClass}>{reciept_status ? reciept_status : " - "}</span>
        );
      },
    },

    {
      title: "Added By",
      dataIndex: "created_by",
      render: (created_by) => {
        return created_by ? created_by.name : " - ";
      },
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      render: (account_number) => {
        return account_number ? account_number : " - ";
      },
    },
  ];

  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITRCPT"]}
            >
              <EditOutlined
                onClick={() => {
                  setSelectedReceipt(record);
                  showDrawer();
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["DELTRCPT"]}
            >
              <DeleteOutlined
                onClick={() => {
                  setSelectedReceipt(record);
                  setOpenModal(true);
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["EDITRCPT", "DELTRCPT"]
  );

  const deleteAgent = async () => {
    try {
      const response = await deleteReceipt(selectedReceipt.id);
      if (response.status === 200 && response.success === true) {
        message.success("Receipt deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedReceipt(null);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        className="receipt_heading"
      >
        <Title level={2} align="center">
          Receipt
        </Title>

        <div >
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["POSTRCPT"]}
          >
            <Button
              onClick={showDrawer}
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              Add Receipt
            </Button>
          </ConditionalRender>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/loan/receipt-detail/"
        tableData={receipt}
        setTableData={setReceipt}
        tableColumns={tableColumns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
      />
      <Drawer
        title={
          <Header
            title={selectedReceipt ? "Edit" : "Add"}
            onClose={onClose}
            name="Receipt"
          />
        }
        width={800}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <ReceiptForm
          refreshData={toggleRefreshTableData}
          id={selectedReceipt && selectedReceipt?.id}
          open={open}
          closeForm={onClose}
        // isEditMode={!!selectedReceipt}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteAgent();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete Receipt ?`}</p>
      </Modal>
    </div>
  );
};

export default Receipt;
