import React from "react";
import {
  FaFilePdf,
  FaFileExcel,
  FaFileCsv,
  FaFileAudio,
  FaFileVideo,
  FaFileAlt,
  FaFileWord,
} from "react-icons/fa";

const FileHandler = ({ fileType, mediaFile, index }) => {
  const fileTypeIcons = {
    "application/pdf": <FaFilePdf style={{ fontSize: "24px", color: "red", cursor: "pointer" }} />,
    "application/vnd.ms-excel": <FaFileExcel style={{ fontSize: "24px", color: "green", cursor: "pointer" }} />,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
      <FaFileExcel style={{ fontSize: "24px", color: "green", cursor: "pointer" }} />
    ),
    "text/csv": <FaFileCsv style={{ fontSize: "24px", color: "orange", cursor: "pointer" }} />,
    "audio/mpeg": <FaFileAudio style={{ fontSize: "24px", color: "blue", cursor: "pointer" }} />,
    "video/mp4": <FaFileVideo style={{ fontSize: "24px", color: "purple", cursor: "pointer" }} />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
      <FaFileWord style={{ fontSize: "24px", color: "blue", cursor: "pointer" }} />
    ),
    "application/octet-stream": <FaFileAlt style={{ fontSize: "24px", color: "gray", cursor: "pointer" }} />,
  };

  const openFile = (e) => {
    e.preventDefault();

    const isBase64 = mediaFile.startsWith("data:");
    const newWindow = window.open();

    if (newWindow) {
      if (isBase64) {
        newWindow.document.write(
          `<html><body><embed src="${mediaFile}" type="${fileType}" width="100%" height="100%" /></body></html>`
        );
      } else {
        newWindow.location.href = mediaFile;
      }
      newWindow.document.close();
    }
  };

  return (
    <div
      key={index}
      style={{
        marginBottom: "8px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <a
        href={mediaFile}
        onClick={openFile}
        title={`Open ${fileTypeIcons[fileType]?.type?.displayName || "File"}`}
        style={{ textDecoration: "none" }}
      >
        {fileTypeIcons[fileType]}
      </a>
      {(fileType === "application/vnd.ms-excel" ||
        fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "text/csv" ||
        fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
        <a
          href={mediaFile}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          Download File
        </a>
      )}
      {fileType === "audio/mpeg" && (
        <audio controls>
          <source src={mediaFile} type={fileType} />
          Your browser does not support the audio element.
        </audio>
      )}
      {fileType === "video/mp4" && (
        <video controls style={{ width: "100%", height: "auto" }}>
          <source src={mediaFile} type={fileType} />
          Your browser does not support the video element.
        </video>
      )}
      {fileType === "application/octet-stream" && (
        <p>
          Unable to preview this file type.{" "}
          <a href={mediaFile} download>
            Download
          </a>
        </p>
      )}
    </div>
  );
};

export default FileHandler;
