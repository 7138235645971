import React, { useState, useEffect } from "react";
import { getAddressTabsData, deleteAddressTabsData } from "../../services/salesformservices";
import AddressType from "../../../loanApplication/customerTabDetails/addressDetails/views/AddressType";
import { Tabs, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveAddressKey,
  setInitialCustomerAddreess,
  setAddressApicalled,
  removeAddress
} from "../../../../redux/salesFormSlice";

const SalesFormAddressForm = ({ activeCustomer, loanId }) => {
  const { customers } = useSelector((state) => state.salesForm);

  const [openModal, setOpenModal] = useState(false);
  const [targetKey, setTargetKey] = useState(null);

  const addressDetails = customers[parseInt(activeCustomer)]?.addressDetails?.addresses;
  const activeAddressKey = customers[parseInt(activeCustomer)]?.addressDetails?.activeAddressKey;
  const addressApiCalled = customers[parseInt(activeCustomer)]?.addressDetails?.addressapicalled;
  const customerId = customers[parseInt(activeCustomer)]?.id;

  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const open = true;

  useEffect(() => {
    const getCustomerAddresses = async () => {
      const response = await getAddressTabsData(customerId);
      if (response?.data?.customer_address.length === 0) {
        dispatch(
          setInitialCustomerAddreess({
            activeCustomer: activeCustomer,
            addresses: [null],
          })
        );
        if (addressApiCalled === 0) {
          dispatch(setAddressApicalled({ index: activeCustomer }));
        }
      } else {
        dispatch(
          setInitialCustomerAddreess({
            activeCustomer: activeCustomer,
            addresses: response?.data?.customer_address,
          })
        );
        if (addressApiCalled === 0) {
          dispatch(setAddressApicalled({ index: activeCustomer }));
        }
      }
    };

    if (addressApiCalled === 0) {
      getCustomerAddresses();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      addNewAddress();
    } else if (action === "remove") {
      setTargetKey(targetKey);
      setOpenModal(true); 
  };
  }
 
  const handleOk = async () => {
   
    const address = addressDetails[parseInt(targetKey)]; 

    if (address) {

      if (addressDetails.length > 1)
      {  
      const deleteTabById = async () => {
        try {
          const response = await deleteAddressTabsData(address);

          if (response.status === 200 && response.success === true) {
            message.success("Tab deleted successfully");

            dispatch(
              removeAddress({
                activeCustomerKey: activeCustomer,
                activeAddressKey: targetKey,
              })
            );

             if (activeAddressKey > 0)
             {
              // If there are multiple addresses, shift the active key
              dispatch(changeActiveAddressKey({
                activeCustomer: activeCustomer,
                key: activeAddressKey - 1,
              }));
            }
           
          } else {
            message.error("Failed to delete the tab");
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "Error deleting tab");
        }

       
      }; 
      deleteTabById();
    } else {
        message.error("Cannot Delete Last Tab")
      }

   
    } else {
      // Handle if the tab doesn't exist at the target key
      if (addressDetails.length === 1) {
        message.error("Cannot Delete Last Tab");
      } else {
        dispatch(
          removeAddress({
            activeCustomerKey: activeCustomer,
            activeAddressKey: targetKey,
          })
        );
        // Shift the active address key if there are more than one address
        if (activeAddressKey > 0)
          {
        dispatch(
          changeActiveAddressKey({
            activeCustomer: activeCustomer,
            key: activeAddressKey - 1,
          })
        );
      }
      }
    }
  
    
    setOpenModal(false);
  };


  const handleCancel = () => {
    setOpenModal(false);
  };


  const addNewAddress = () => {
    const updatedAddresses = [...addressDetails, null];

    dispatch(
      setInitialCustomerAddreess({
        activeCustomer: activeCustomer,
        addresses: updatedAddresses,
      })
    );

    dispatch(
      changeActiveAddressKey({
        activeCustomer: activeCustomer,
        key: updatedAddresses.length - 1,
      })
    );
  };

  const onChange = (key) => {
    dispatch(
      changeActiveAddressKey({
        activeCustomer: activeCustomer,
        key: key,
      })
    );
  };

  return (
    <div>
      <Tabs type="editable-card" onChange={onChange} onEdit={onEdit} activeKey={activeAddressKey.toString()}>
        {addressDetails?.map((address, index) => (
          <TabPane tab={`Address ${index + 1}`} key={index}>
            <AddressType
              isSalesForm={true}
              addressid={addressDetails[parseInt(activeAddressKey)]}
              loan_detail={loanId}
              customer_id={customerId}
              open={open}
              activeCustomerKey={activeCustomer}
              activeAddressKey={activeAddressKey}
            />
          </TabPane>
        ))}
      </Tabs>

      <Modal
     title="Confirm Delete"
     open={openModal}
     onOk={handleOk}
     onCancel={handleCancel}
     okType="danger"
   >
     <p>Are you sure you want to delete this address tab?</p>
   </Modal>
    </div>
  );
};

export default SalesFormAddressForm;
