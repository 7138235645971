import React, {useState, useEffect} from 'react'
import { Tabs, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestmentTabsData, deleteInvestmentTabsData } from '../../services/salesformservices';
import InvestmentDetailsForm from '../../../loanApplication/customerTabDetails/investmentdetails/views/InvestmentDetailsForm';
import { setInitialCustomerInvestments, changeActiveInvestmentKey, setInvestmentApicalled, removeInvestment } from '../../../../redux/salesFormSlice';
import "../../styles/investmentDetails.css"

const SalesFormInvestmentForm = (activeCustomer) => { 

  const { customers } = useSelector((state) => state.salesForm);  
  const [openModal, setOpenModal] = useState(false); 
  const [targetKey, setTargetKey] = useState(null);  

  const activeCustomerIndex = activeCustomer.activeCustomer;

  const investmentDetails = customers[parseInt(activeCustomerIndex)]?.investmentDetails?.investments; 

  const investmentKey = customers[parseInt(activeCustomerIndex)]?.investmentDetails?.activeInvestmentKey;

  const investmentApicalled = customers[parseInt(activeCustomerIndex)]?.investmentDetails?.investmentloanapicalled;

  const customerId = customers[parseInt(activeCustomerIndex)]?.id;  

  const { TabPane } = Tabs;
  
  const dispatch = useDispatch();

  useEffect(() => {
    const getCustomerInvestments = async () => {
      const response = await getInvestmentTabsData(customerId); 
      if (response?.data?.investment_account_detail?.length === 0) {
        dispatch(
            setInitialCustomerInvestments({
            activeCustomer: activeCustomer.activeCustomer,
            investments: [null],
          })
        );
      } else {
        dispatch(
          setInitialCustomerInvestments({
            activeCustomer: activeCustomer.activeCustomer,
            investments: response?.data?.investment_account_detail,
          })
        );
      }

      if (investmentApicalled === 0) { 
        dispatch(setInvestmentApicalled({ index: activeCustomerIndex }));
      }
    };


    if (investmentApicalled === 0) {
    getCustomerInvestments();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      addNewInvestment();
    } else {
      setTargetKey(targetKey);  
      setOpenModal(true); 
    }
  };
 
  const handleOk = async () => {
    const investment = investmentDetails[parseInt(targetKey)];
  
    if (investment) {
      if (investmentDetails.length > 1) {
      const deleteTabById = async () => {
        try {
          const response = await deleteInvestmentTabsData(investment);

          if (response.status === 200 && response.success === true) {
            message.success("Tab deleted successfully");
            if (investmentKey > 0)
              {
              dispatch(
                changeActiveInvestmentKey({
                  activeCustomer: activeCustomer.activeCustomer,
                  key: investmentKey - 1,
                })
              );
            } 
           
            dispatch(
              removeInvestment({
                activeCustomerKey: activeCustomer.activeCustomer,
                activeInvestmentKey: targetKey,
              })
            );
         
          } else {
            message.error("Failed to delete the tab");
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "Error deleting tab");
        }
      };
      deleteTabById();
    } else {
        message.error("Cannot delete the last tab");
      }

    
    } else {
      if (investmentDetails.length === 1) {
        message.error("Cannot delete the last tab");
      } else {
        dispatch(
          removeInvestment({
            activeCustomerKey: activeCustomer.activeCustomer,
            activeInvestmentKey: targetKey,
          })
        );

        if (investmentKey > 0)
          {
        dispatch(
          changeActiveInvestmentKey({
            activeCustomer: activeCustomer.activeCustomer,
            key: investmentKey - 1,
          })
        );
      }
      }
    }

    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false); // Close the modal without doing anything
  };

  const addNewInvestment = () => {

    const updatedInvestments = [...investmentDetails, null]

    dispatch(
      setInitialCustomerInvestments({
        activeCustomer: activeCustomer.activeCustomer,
        investments: updatedInvestments,
      })
    );

    dispatch(
      changeActiveInvestmentKey({
        activeCustomer: activeCustomer.activeCustomer,
        key: updatedInvestments.length - 1,
      })
    );
  };

  const onChange = (key) => {
    dispatch(
      changeActiveInvestmentKey({
        activeCustomer: activeCustomer.activeCustomer,
        key: key,
      })
    );
  }; 
 

  return (
    <div>
        <Tabs type="editable-card" onChange={onChange} onEdit={onEdit} activeKey={investmentKey?.toString()}>
        {investmentDetails &&
          investmentDetails.map((investment, index) => (
            <TabPane tab={`Investment Details ${index + 1}`} key={index} className='investment-form-container'> 
              <InvestmentDetailsForm investment={investment} customer_id={customerId} id={investmentDetails[index]} activeInvestmentKey={index} activeCustomerKey={activeCustomer} isSalesForm={true} className='investment-form-container'/>
            </TabPane>
          ))}
        </Tabs>

        <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okType="danger"
      >
        <p>Are you sure you want to delete this reference tab?</p>
      </Modal> 
    </div>
  )
}

export default SalesFormInvestmentForm