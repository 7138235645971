import React, { useEffect, useState } from 'react'
import "../styles/cibil.css"
import { Divider, Table, message } from 'antd';
import { useParams } from "react-router-dom";
import { getCibilReportByIdService } from '../services/bureauDetailService';
import moment from 'moment';
import "../styles/highMarkReport.css"
import Chart from "react-google-charts";
import greendot from "../assets/greendot.png"
import yellowdot from "../assets/yellowdot.png"
import reddot from "../assets/reddot.png"
import crifimage from "../assets/crifimage.png"
import ReactSpeedometer from "react-d3-speedometer";
import info from "../assets/information 6.png";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import CustomNoData from '../../../../../utils/noDataIcon/CustomNoData';

const columns = [
  {
    title: 'Address Category',
    dataIndex: 'address_category',
    key: 'address_category',

  },
  {
    title: 'Complete Address',
    dataIndex: 'complete_address',
    key: 'complete_address',
  },
  {
    title: 'Enriched Through Enquiry',
    dataIndex: 'enriched_through_enquiry',
    key: 'enriched_through_enquiry',
  },
  {
    title: 'Date Reported',
    dataIndex: 'date_reported',
    key: 'date_reported',
  },
];




const enquiryColumns = [
  {
    title: 'Enquiry Member Name',
    dataIndex: 'enquiry_member_name',
    key: 'enquiry_member_name',
  },
  {
    title: 'Date of Enquiry',
    dataIndex: 'date_of_enquiry',
    key: 'date_of_enquiry',
  },

  {
    title: 'Enquiry Purpose',
    dataIndex: 'enquiry_purpose',
    key: 'enquiry_purpose',
  },
  {
    title: 'Enquiry Amount',
    dataIndex: 'enquiry_amount',
    key: 'enquiry_amount',
  },

];


const options = {
  pieHole: 0.8,
  colors: ["#219653", "#E61D2B", "#E6AE1D"],
  pieSliceText: "none",
  legend: "none",
};
const baroptions = {
  chart: {},
  legend: { position: "none" },
  width: "20px",
};



export const CibilReport = () => {
  const { encrypted_customer_id } = useParams();
  const [cibilData, setcibilData] = useState(null);

  let customer_id = null;
  try {
    customer_id = decrypt(encrypted_customer_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const chartdata = [
    ["Task", "Number of Accounts"],
    ["Others", 6],
    [
      "Overdue",
      parseInt(
        cibilData?.all_accounts?.overdue_account
      ),
    ],
    [
      "Active",
      parseInt(
        cibilData?.all_accounts?.active_accounts
      ),
    ],
  ];
  const bardata = [
    ["Year", "Sales"],
    ["30 Days", parseInt(
      cibilData?.enquiry_graph_details?.last_30_days
    ),],
    ["60 Days", parseInt(
      cibilData?.enquiry_graph_details?.last_60_days
    ),],
    ["12 Months", parseInt(
      cibilData?.enquiry_graph_details?.last_12_months
    ),],
    ["24 Months", parseInt(
      cibilData?.enquiry_graph_details?.last_24_months
    ),],
    ["Recent", parseInt(
      cibilData?.enquiry_graph_details?.recent
    ),],
  ];
  const modifiedData = cibilData?.cibil?.addresses?.map(address => {
    const completeAddress = [
      address.address_line_1 ? `${address.address_line_1}\n` : '',
      address.address_line_2 ? `${address.address_line_2}\n` : '',
      address.address_line_3 ? `${address.address_line_3}\n` : '',
      address.address_line_4 ? `${address.address_line_4}\n` : '',
      address.address_line_5 ? `${address.address_line_5}\n` : ''
    ].join('').trim();

    return {
      ...address,
      complete_address: completeAddress || '-',
      enriched_through_enquiry: address.enriched_through_enquiry || '-',
      date_reported: address.date_reported || '-',
    };
  });



  useEffect(() => {
    const fetchData = async () => {
      try {

        const data = await getCibilReportByIdService(customer_id);
        setcibilData(data.data);

      } catch (error) {
        console.error("Error fetching property details:", error);
      }
    };

    fetchData();
  }, [customer_id,]);



  return (
    <>
      <div className='main_cibil_container'>
        <div>
          <p className="para_heading_first">CIBIL Report</p>
        </div>
        <div className='cibil_heading_second'>
          Home / HL0000000164179 / <span className='cibil_report_text'>CIBIL Report</span>
        </div>
      </div>

      <div className='cibil_score_div'>
        <div className='cibil_score_div_secomnd'>
          <div className='cibil_score_icon'>
            <ReactSpeedometer
              value={parseInt(cibilData?.cibil?.score_detail?.scorecard_score) || 0}
              customSegmentLabels={[
                {
                  text: "Very Bad",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Bad",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Ok",
                  position: "INSIDE",
                  color: "#555",
                  fontSize: "19px",
                },
                {
                  text: "Good",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Very Good",
                  position: "INSIDE",
                  color: "#555",
                },
              ]}
            />

          </div>
        </div>
        <div className='crifiimage'>
          <img src={crifimage} alt="" />
        </div>
        <Divider className='divider_cibil' />
        <div className='divider_cibil_text' >
          <h4><span className='cibil_score_text'>SCORE NAME : </span>{cibilData?.cibil?.score_detail?.scorecard_name || ' -'}</h4>
          <h4><span className='cibil_score_text'>SCORE CARD VERSION : </span>{cibilData?.cibil?.score_detail?.scorecard_version || ' -'}</h4>
          <h4><span className='cibil_score_text' >SCORE CARD DATE : </span>{cibilData?.cibil?.score_detail?.scorecard_date || ' -'}</h4>

        </div>

      </div>
      <div className="div1">
        <div
          className='div1_two'

        >
          <p className="para">
            Account Summary
          </p>
          <div className="summary_para">
            Tip: Current Balance, Disbursed Amount & Instalment Amount is considered ONLY for ACTIVE account
          </div>
        </div>
        <div className='third_div_account_first'>


          <div className="third_div_account" >
            <div className='third_div_account_two' >
              <h4 className='All_Account_text' >All Accounts</h4>
              <div className='all_account_div'>
                <div className='all_account_div_firts' >
                  <h4 className='all_account_div_firts_heading'

                  >
                    <span>
                      <img src={greendot} alt="green" />
                    </span>
                    <span className='others_cibil' >

                      Others -
                      {
                        cibilData?.all_accounts?.overdue_account
                      }
                    </span>
                  </h4>
                  <h4
                    className='all_account_div_firts_heading'
                  >
                    <span>
                      <img src={reddot} alt="green" />
                    </span>
                    <span className='others_cibil'>
                      Overdue -
                      {
                        cibilData?.all_accounts?.overdue_account
                      }
                    </span>
                  </h4>
                  <h4
                    className='all_account_div_firts_heading'

                  >
                    <span>
                      <img src={yellowdot} alt="green" />
                    </span>
                    <span className='others_cibil'>
                      Active -
                      {
                        cibilData?.all_accounts?.active_accounts
                      }
                    </span>
                  </h4>
                </div>
                <div className='chart_div' >
                  <Chart
                    chartType="PieChart"

                    data={chartdata}
                    options={options}
                    className='chart_div_two'
                  />

                </div>
              </div>
            </div>
            <div className='cibil_div_inuiiries' >
              <h4 className='cibil_div_inuiiries_heading'>Inquiries in last 24 Months (Total -  {parseInt(
                cibilData?.enquiry_graph_details?.last_24_months || 0
              )})</h4>
              {parseInt(cibilData?.enquiry_graph_details?.last_24_months || 0) > 0 &&
                bardata &&
                bardata.length > 1 &&
                bardata.some(row => row.some(value => value !== 0)) ? (
                <div className='cibil_div_inuiiries_chart' >
                  <Chart
                    className='cibil_div_inuiiries_chart_two'
                    chartType="Bar"

                    data={bardata}
                    options={baroptions}
                  />
                </div>
              ) :
                <div
                  className='cibit_report_no_data'
                >
                  <CustomNoData />
                </div>
              }

            </div>
          </div>
          <div className='Sanction_div_cibil_first'>
            <div className="Sanction_div_cibil_second" >
              <div className='Sanction_div_cibil_second_third' >
                <h4 className='Sanction_div_cibil_heading'> High Cr./Sanction Amt :</h4>
                <h3 className='current_balance_cibil_Current'>₹ {cibilData?.high_credit_sanctioned_amount || "-"}</h3>
              </div>
            </div>
            <div className="Sanction_div_cibil_second" >
              <div className='Sanction_div_cibil_second_third' >
                <h4 className='Sanction_div_cibil_heading'>Current Balance :</h4>
                <h3 className='current_balance_cibil_Current'>₹ {cibilData?.current_balance || "-"}</h3>
              </div>
            </div>

            {/* <div className='Sanction_div_cibil_second'>
              <div className=',Sanction_div_cibil_second_third'>
                <h4 className='Sanction_div_cibil_heading_current'>Current Balance :</h4>
                <h3 className='Sanction_div_cibil_heading_Current'> ₹{cibilData?.current_balance}</h3>
              </div>
            </div> */}

          </div>

          <div
            className='data_cibili_opne'>


            <div className='Date_cibile' >
              <span className='date_format'>Date Opened Oldest : </span>
              <span>
                {cibilData?.date_opened_oldest ? moment(cibilData.date_opened_oldest, "DD-MM-YYYY").format('DD-MM-YYYY') : '-'}
              </span>
            </div>
            <div className='Date_cibile'>
              <span className='date_format'>Date Opened Recent : </span>
              <span>
                {cibilData?.date_opened_recent ? moment(cibilData.date_opened_recent, "DD-MM-YYYY").format('DD-MM-YYYY') : "-"}
              </span>
            </div>

          </div>
        </div>
      </div>


      <div className='div1_Consumer' >
        <div className='div1_Consumer_seocond'>
          <p className='para'>Consumer/Borrower Information</p>
        </div>
        <div className='Borrower_Information' >
          <div className='heagin_first'
          >
            <p className='para'> {cibilData?.cibil?.consumer_info?.full_name}</p></div>

          <div className='third_div_account'>
            <div className='first_div_consumer'>
              <div className='lable_first'>


                <span className='span_lable'> Date of Birth </span> <span className='span_data'>: {cibilData?.cibil?.consumer_info?.date_of_birth || "-"}</span>
              </div>
              <div className='lable_first'>
                <span className='span_lable'>Gender </span><span className='span_data'>: {cibilData?.cibil?.consumer_info?.gender || "-"}</span>
              </div>
              <div className='lable_first'>
                <span className='span_lable'>
                  Telephone (S) <img src={info} alt="" /> </span> <span className='span_data'>: {cibilData?.cibil?.consumer_info?.telephone?.map((number, index) => (
                    <span key={index}>{number}<br></br></span>
                  )) || '-'}
                </span>

              </div>
            </div>
            <div className='second_div_cosumer'>
              <div className='lable_first' >
                <span className='span_lable'>Email </span><span className='span_data'>: {cibilData?.cibil?.consumer_info?.email || '-'}</span>

              </div>
              <div className='lable_first'>
                <span className='span_lable'>Identification (S) <img src={info} alt="" />  </span><span className='span_data'>: {cibilData?.cibil?.consumer_info?.identification?.map((id, index) => (

                  <span key={index}>{id}<br></br></span>
                )) || "-"}</span>
              </div>

            </div>
          </div>
        </div>

      </div>





      <div>

        <div className='div1'>
          <div className='div1_Consumer_seocond'>
            <p className='para' >Employment Details : </p>
          </div>

          <div className='third_div_account_first'>

            <div className='third_div_account'>

              <>
                {cibilData && cibilData.cibil.employments && cibilData.cibil.employments.length > 0 ? (
                  cibilData && cibilData.cibil.employments.map((employment, index) => (
                    <div className='first_div'>
                      <div key={index}>
                        <div className='lable_first'>
                          <span className='span_lable'> Account Type </span>
                          <span className='span_data'>: {employment.account_type || ' - '}</span>
                        </div>
                        <div className='lable_first'>
                          <span className='span_lable'>Occupation Code  </span>
                          <span className='span_data'>: {employment.occupation_code || '-'}</span>
                        </div>
                        <div className='lable_first'>
                          <span className='span_lable'>Net Income Indicator </span>
                          <span className='span_data'>: {employment.net_income_indicator || '-'}</span>
                        </div>
                      </div>
                      <div className='second_div_employment'>
                        <div className='lable_first' >
                          <span className='span_lable'>Date Reported :  </span>
                          <span className='span_data'>: {employment.date_reported_and_certified || '-'}</span>
                        </div>
                        <div className='lable_first'>
                          <span className='span_lable'>Income</span>
                          <span className='span_data'>: {employment.income || '-'}</span>
                        </div>
                        <div className='lable_first'>
                          <span className='span_lable'>Monthly Income </span>
                          <span className='span_data'>: {employment.monthly_income_indicator || '-'}</span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='no_data_div_cibil'>
                <CustomNoData />
                  </div>
                )}
                

              </>
            </div>

          </div>

        </div>

      </div>

      <div className='Adddress_conaintener'>

        <div className='div2'>

          <div className='div1_two' >
            <p className='para' >Adddress</p>
          </div>

          <div className='main_div_cibil_second'>
            <>
              {modifiedData && (
                <Table
                  className='cibil_table'
                  columns={columns}
                  dataSource={modifiedData}
                  pagination={false}
                />
              )}
            </>
            {/* <Table className='cibil_table' columns={columns} dataSource={cibilData?.cibil?.addresses || [] } pagination={false} /> */}
          </div>

        </div>
      </div>


      <div className='div1'>
        <div className='div1_two'>
          <p className='para'>Your Account</p>
        </div>
        <div className='main_div_cibil_second' >
          {cibilData && cibilData?.cibil?.account?.map((account, index) => (
            <div key={index}>
              <div className='heagin_first'>
                <p className='para'> Account {index + 1} <span><button className='credti_btn'>credit card</button></span></p>
              </div>
              <div className='third_div_account'>
                <div className='first_div'>
                  <div className='lable_first'>
                    <span className='span_lable'> Reporting Member Name :</span>
                    <span className='span_data'>{account?.reporting_member_short_name}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'> Ownership Indicator :</span>
                    <span className='span_data'>{account?.ownership_indicator}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>
                      Date of Last Payment : </span>
                    <span className='span_data'>{account?.date_of_last_payment}</span>
                  </div>

                  <div className='lable_first'>
                    <span className='span_lable'>
                      Account Type : </span>
                    <span className='span_data'> {account?.account_type}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>
                      Date Opened/Disbursed : </span>
                    <span className='span_data'>{account?.date_opened_disbursed}</span>
                  </div>
                </div>
                <div className='second_div'>
                  <div className='lable_first'>
                    <span className='span_lable'>Current Balance : </span>
                    <span className='span_data'>{account?.current_balance}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>Payment History Date : </span>
                    <span className='span_data'>{account?.payment_history_start_date}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'>Credit Limit : </span>
                    <span className='span_data'>{account?.credit_limit || '-'}</span>
                  </div>
                  {/* <div className='lable_first'>
                    <span className='span_lable'>Cash Limit : </span>
                    <span className='span_data'>{account?.cash_limit || ' - '}</span>
                  </div> */}
                  <div className='lable_first'>
                    <span className='span_lable'>
                      High Credit Amount : </span>
                    <span className='span_data'>{account?.high_credit_sanctioned_amount}</span>
                  </div>
                  <div className='lable_first'>
                    <span className='span_lable'> Payment Frequency :</span>
                    <span className='span_data'>{account?.payment_frequency || "-"}</span>
                  </div>
                </div>
              </div>

              <div className='heagin_first' >
                <p className='para' > Payment History </p>
              </div>
              <div className='heagin_first'>
                <div className='main_div_cibil_atble'>
                  <table className='Payment_table_cibil' >
                    <thead >
                      <tr>
                        <th
                          className='table_thaed_highmark'
                        >
                          <div
                            className='table_thaed_highmark_second'
                          >
                            <span
                              className='year_span_highmark'
                            >
                              Year
                            </span>
                            <span
                              className='month_span_highmark'
                            >
                              Month
                            </span>
                          </div>
                        </th>
                        {Object.keys(account?.payment_history)?.map((year, index) => (
                          index === 0 &&
                          Object.keys(account?.payment_history[year])?.map((month) => (
                            <th className='payment_hist_th' key={`${year}-${month}`}>{month}</th>
                          ))
                        ))}

                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(account?.payment_history)?.map((year) => (
                        <tr key={year}>
                          <td className='payment_hist_td' >{year}</td>
                          {Object.keys(account?.payment_history[year])?.map((month) => (
                            <td className='payment_hist_th' key={`${year}-${month}`}>{account?.payment_history[year][month] || '-'}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}

        </div>

      </div>


      <div className='Adddress_conaintener'>

        <div className='div2'>
          <div className='div1_two'>
            <p className='para' >Enquiries</p>
          </div>
          <div className='main_div_cibil_second'>

            <Table className='cibil_table' columns={enquiryColumns} dataSource={cibilData?.cibil?.enquiries || []} pagination={false} />
          </div>

        </div>

        <div className='div2'>
          <div className='memder_id_div'>
            <div>
              <span className='member_text'>Member ID:</span><span>{cibilData?.member_id || ' - '}</span>
            </div>
            <div>
              <span className='member_text'>ENQUIRY CONTROL NUMBER:</span><span>{cibilData?.enquiry_control_number || ' - '}</span>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}
