import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getInvestmentDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/investment-detail/investment-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getInvestmentById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/investment-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listInvestmentTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/investment-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateInvestmentDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/investment-detail/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createAddInvestmentService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/investment-detail/", data); 
      return resolve(response.data)
    } catch (error) { 
      return reject(error)
    }
  })
}

export const deleteInvestmentDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/investment-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}