import React from "react";
import { Modal, Input, Button, Form } from "antd";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  ownerName: Yup.string()
    .required("Property owner name is required.")
    .min(2, "Property owner name must be at least 2 characters long.")
    .max(50, "Property owner name must be at most 50 characters long."),
});

const validateYupSchema = async (rule, value) => {
  try {
    await validationSchema.validate({ ownerName: value });
  } catch (error) {
    throw new Error(error.message);
  }
};

const AddPropertyOwnerModal = ({ visible, onClose, onAddPropertyOwner, existingOwners }) => {
  const [form] = Form.useForm();

  const handleAddOwner = () => {
    form
      .validateFields()
      .then((values) => {
        onAddPropertyOwner(values.ownerName);
        form.resetFields();
        onClose();
      })
      .catch(() => {
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };
  
  const handletextInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <Modal
      title="Add Property Owner"
      visible={visible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="add" type="primary" onClick={handleAddOwner}>
          Add
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="add_property_owner">
        <Form.Item
          name="ownerName"
          rules={[
            {
              validator: validateYupSchema,
            },
            {
              validator: (_, value) => {
                if (existingOwners.some((owner) => owner.name === value)) {
                  return Promise.reject(
                    new Error("Property owner name already exists.")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder="Enter Property Owner Name"
            onChange={(e) => handletextInput(e, "ownerName")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPropertyOwnerModal;
