/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Space, Typography, Button } from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import DataTable from "../../../utils/dataTable/DataTable";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authorizeTableColumns from "../../../utils/authorization/authorizeTableColumns";
import { encrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import ConditionalRender from "../../../utils/authorization/AuthorizeComponent";
import { resetCustomers } from "../../../redux/salesFormSlice"; 
const { Title } = Typography;


const ListSalesApplication = () => {
  const [listOfApplications, setListOfApplications] = useState([]);
  const [refreshTableData] = useState(false);
  const [filterFormData] = useState({
    filter: false,
    location: null,
    loan_type: [],
    loan_status: [],
    sales_manager: [],
    amountType: null,
    created_by: [],
    modified_by: [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCustomers() ); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user_data } = useSelector((state) => state.user);

  const columns = [
    // {
    //   title: "Loan Account Number",
    //   dataIndex: "loan_account_number",
    //   fixed: "left",
    //   render: (text) => text || "-",
    // },
    {
      title: "Applicant Name",
      dataIndex: "applicant_name",
      render: (text) => text || "-",
    },
    {
      title: "Application Number",
      dataIndex: "application_number",
      render: (text) => text || "-",
    },
    {
      title: "Location",
      render: (record) => record?.location?.display_name || "-",
    },
    {
      title: "Loan Type",
      render: (record) => record?.loan_type?.display_name || "-",
    },
    {
      title: "Loan Amount",
      dataIndex: "requested_amount",
      render: (amount) => {
        return amount ? `₹${new Intl.NumberFormat('en-IN').format(amount)}` : " - ";
      },
    },
    {
      title: "Sales Manager",
      render: (record) => record?.sales_manager?.name || "-",
    },
    {
      title: "Status",
      render: (record) => (
        <div
          style={{
            backgroundColor: "#ECFFDC",
            borderRadius: "9px",
            padding: "5px 10px",
            display: "inline-block",
            color: "#40B5AD",
          }}
        >
          {record?.loan_stage || "-"}
        </div>
      ),
    },
  ];

  const authorizedColumns = [
    {
      title: "View",
      fixed: "right",
      render: (_, record) => (
        <Link to={record.id ? `/sales/${encrypt(record.id.toString())}` : "#"}>
          <EditOutlined style={{ fontSize: "18px", color: "black" }} />
        </Link>
      ),
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["EDITLOAN"]
  );

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Applications
        </Title>
        <ConditionalRender
          userPermissions={user_data?.permissions}
          requiredPermissions={["POSTLOAN"]}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link to="/sales-form">
              <Button style={{ marginLeft: "1rem" }} type="primary">
                <PlusCircleOutlined style={{ verticalAlign: "text-top" }} />{" "}
                Create Sales Application
              </Button>
            </Link>
          </div>
        </ConditionalRender>
      </Space>

      <DataTable
        apiPath="/api/v1/loan/loan-detail/?get_inactive=True"
        tableData={listOfApplications}
        setTableData={setListOfApplications}
        tableColumns={tableColumns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search "}
        filters={filterFormData}
      />
    </div>
  );
};

export default ListSalesApplication;
