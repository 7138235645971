
import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getExistingDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/existing-loan-detail/existing-loan-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createExistingLoanService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/existing-loan-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getExistingLoanById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/existing-loan-detail/${id}`);
      return resolve(response.data)
    } catch (error) {



      return reject(error)
    }
  })
}

export const listLoanPurposeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-purpose/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLoanStatusService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-status/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listFinancialInstitutionService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/banks/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateExistingLoanDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/existing-loan-detail/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listSourceOfLiability = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/source-of-liabilty`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteExistingLoanDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/existing-loan-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
