import {
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Space,
  Button,
  message,
  Input,
  Modal,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import "../styles/RCUInitiationForm.css";
import {
  getRCUProcessListById,
  getRCUProcessDetailsById,
  postRCUInitiate,
  getAllAgentService,
  getRCUoptions,
  getAllAgencyService,
} from "../services/rcuinitiationform";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import pdf from "../images/Pdf.png";
import { PlusOutlined, FileOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import RViewerJS from "viewerjs-react";
import Loading from "../../../../../../utils/loading/Loading";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const RCUInitiationForm = ({ open, loan_id, closeForm, agencyCode, setSubmitStageBar }) => {
  const [form] = Form.useForm();
  const [vendors, setVendors] = useState([]);
  const [agents, setAgents] = useState([]);
  const [rcu, setRCU] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [agentsselected, setAgentsselected] = useState([]);
  const [sampledata, setSampledata] = useState();
  const { user_data } = useSelector((state) => state.user);
  const [modifiedDatas, setModifiedDatas] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [value, setValue] = useState("");
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [rcutype, setRcutype] = useState([]);
  const [choice, setChoice] = useState("");
  const [loading, setLoading] = useState(true);
  const [customerName, setCustomerName] = useState("");
  const [applicantType, setApplicantType] = useState("");

  useEffect(() => {
    if (sampledata?.customer_document_address_detail) {
      const applicant = sampledata?.customer_document_address_detail?.find(
        (customer) =>
          customer.customer_type?.name === "Applicant" ||
          customer.customer_type?.name === "Company Applicant"
      );
      setCustomerName(applicant?.customer_name?.full_name || "");
      setApplicantType(applicant?.customer_type?.name);
    }
  }, [sampledata]);

  // console.log(
  //   "68", sampledata?.customer_document_address_detail?.find((
  //     customer) =>
  //       customer.customer_name?.mobile_number === "Applicant" 
  //   )
  // )

  const generateMailSubject = () => {
    const locationName = sampledata?.loan_detail?.location?.display_name || "";
    const loanAccountNumber =
      sampledata?.loan_detail?.loan_account_number || "";

    // Use the customerName from state directly
    const applicant = sampledata?.customer_document_address_detail?.find(
      (customer) =>
        customer.customer_type?.name === "Applicant" ||
        customer.customer_type?.name === "Company Applicant"
    );

    return `RCU initiate - ${loanAccountNumber} - ${customerName} (${applicant?.customer_type?.name}) ${locationName}`;
  };

  const handleBeforeUpload = async (file) => {
    const base64 = await convertToBase64(file);

    const newImage = {
      file_path: URL.createObjectURL(file),
      uid: file.uid,
      name: file.name,
      base64_url: base64, // Add the base64 URL to the newImage object
    };

    setImages((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    setAttachments((prevAttachments) => [...prevAttachments, base64]);

    return false;
  };

  const handleRemove = (file) => {
    URL.revokeObjectURL(file.originFileObj || file);
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);

    const updatedImages = updatedFileList?.map((file) => ({
      file_path: URL.createObjectURL(file.originFileObj || file),
      uid: file?.uid,
      name: file?.name,
    }));
    setImages(updatedImages);

    const updatedAttachments = attachments.filter(
      (_, index) => updatedFileList[index]?.uid !== file?.uid
    );
    setAttachments(updatedAttachments);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        reject(new Error("File is not a valid Blob"));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const confirmDelete = (file) => {
    URL.revokeObjectURL(deleteUploadPicture.file_path);

    // Update the images state
    setImages((prevImages) => {
      const updatedImages = prevImages.filter(
        (image) => image?.uid !== deleteUploadPicture?.uid
      );
      return updatedImages;
    });

    // Update the fileList state
    setFileList((prevFileList) => {
      const updatedFileList = prevFileList.filter(
        (file) => file?.uid !== deleteUploadPicture?.uid
      );
      return updatedFileList;
    });

    // Update the attachments state by finding the matching base64 string using uid
    setAttachments((prevAttachments) => {
      const updatedAttachments = prevAttachments.filter((attachment, index) => {
        const relatedImage = images[index];
        return relatedImage?.uid !== deleteUploadPicture?.uid;
      });
      return updatedAttachments;
    });

    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const getRcutypecheck = async () => {
    try {
      const response = await getRCUoptions();
      setRcutype(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllVendors = async () => {
    try {
      const response = await getAllAgencyService(agencyCode);
      setVendors(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllAgents = async (agentsselected) => {
    try {
      const response = await getAllAgentService(agentsselected, loan_id);
      setAgents(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (typeof agentsselected === "string") {
      getAllAgents(agentsselected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentsselected]);

  const getAllRCU = async () => {
    try {
      const response = await getRCUProcessListById();
      setRCU(response?.data?.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllRCUData = async () => {
    try {
      const response = await getRCUProcessDetailsById(loan_id);
      setSampledata(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      getAllVendors();
      getAllRCU();
      getRcutypecheck();
      getAllRCUData();
    } else {
      form.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, loan_id]);

  useEffect(() => {
    if (sampledata) {
      const modifiedDatass = sampledata?.customer_document_address_detail?.map(
        (detail) => {
          const modifiedDocuments = {};

          for (const category in detail?.customer_document) {
            if (detail?.customer_document?.hasOwnProperty(category)) {
              modifiedDocuments[category] = detail?.customer_document[
                category
              ]?.map((doc) => ({
                ...doc,
                dropdown_value: "", // Initialize dropdown_value with an empty string or any default value
              }));
            }
          }

          return {
            ...detail,
            customer_document: modifiedDocuments,
          };
        }
      );

      setModifiedDatas(modifiedDatass);
      setLoading(false);
    }
  }, [sampledata]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const mailBody = `
    <span style="padding: 10px 10px 0px 10px;width: 77%;">
      Dear Team,<br>
      Please initiate the RCU Search for ${sampledata?.customer_document_address_detail
        ?.filter((customer) => customer?.customer_type?.name === "Applicant")
        ?.map(
          (customer) =>
            `${customer?.customer_name?.full_name || ""}`
        )
        .join(", ")} (Applicant).<br><br>
      <div style=" margin-top: 10px;
      border: 1px solid #b8b3b373;
      border-radius: 8px;">
        <div style="  border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;
          font-size: 14px;font-weight: 400; 
          padding: 10px;text-align: left;
          color: #000000B2;margin-left: 8px;
          border-right: 1px solid #D9D9D9;">Customer Name</div>
          <div style="  padding: 10px;
          width: 65%;font-family: Roboto;
          font-size: 14px; font-weight: 500;
          line-height: 22px;
          text-align: left;
  color: #000000B2;
  margin-left: 8px;">${customerName} (${applicantType})</div>
        </div>
        <div style="  border-bottom: 1px solid #b8b3b373;
        display: flex;flex-direction: row;">
          <div style="  width: 35%;
  font-family: Roboto;font-size: 14px;
  font-weight: 400;padding: 10px;
  text-align: left;
  color: #000000B2;
  margin-left: 8px;
  border-right: 1px solid #D9D9D9;">LAN</div>
          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">${sampledata?.loan_detail?.loan_account_number || "N/A"
      }</div>
        </div>
        <div style="  border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;margin-left: 8px;border-right: 1px solid #D9D9D9;">Product</div>
          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">${sampledata?.loan_detail?.loan_type?.display_name || "N/A"


      }</div>
        </div>
        <div style="border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;margin-left: 8px;border-right: 1px solid #D9D9D9;">Transaction Type</div>
          <div style="  padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">${choice || "N/A"}</div>
        </div>
        <div style="border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
          <div style="width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;
  margin-left: 8px;border-right: 1px solid #D9D9D9;">Property Address</div>
          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">
            ${sampledata?.property_detail?.address_line_1 || ""}, 
            ${sampledata?.property_detail?.address_line_2 || ""}, 
            ${sampledata?.property_detail?.city?.name || ""}, 
            ${sampledata?.property_detail?.district?.name || ""}, 
            ${sampledata?.property_detail?.taluka?.name || ""}, 
            ${sampledata?.property_detail?.state?.name || ""}, 
            ${sampledata?.property_detail?.pincode?.name || ""}
          </div>
        </div>
        <div style="  border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;
  font-size: 14px;font-weight: 400;padding: 10px;text-align: left;
  color: #000000B2;margin-left: 8px;border-right: 1px solid #D9D9D9;">Branch</div>
          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">${sampledata?.loan_detail?.location?.display_name || "N/A"
      }</div>
        </div>
        <div style="  border-bottom: 1px solid #b8b3b373;
          display: flex;
          flex-direction: row;">
          <div style="  width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;
  margin-left: 8px; border-right: 1px solid #D9D9D9;">Contact Person</div>

          <div style=" padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">
(Vastu - Vishal Yadav, 8654366784)<br>
            Customer Details:<br>
         Mr. Jayesh Pawar (APPLICANT) - 9765488765</div>
     
          
        </div>
      </div>
    </span>
    `;

    const mailSubject = generateMailSubject();

    const formattedValues = {
      loan_detail: sampledata?.loan_detail?.id,
      agency: values.vendor,
      agent: values.agent_name,
      remark: values.remark,
      stage_initiate: "Rcu",
      mail_to: values.mail_to,
      mail_cc: [user_data.email],
      mail_body: mailBody,
      mail_subject: mailSubject,
      attachment: attachments,
      type_of_check: choice,
      sampling_data: sampledata?.customer_document_address_detail?.map(
        (rcue) => {
          // Conditionally handle Customer Profile documents only if choice !== "Document"
          const customerProfileDocs =
            choice !== "Documents"
              ? (rcue?.customer_document?.["Customer Profile"] || [])?.map(
                (doc) => ({
                  customer_id: rcue?.customer_name?.id,
                  rcu_process:
                    values[`document_${rcue?.customer_name?.id}_${doc?.id}`], // Default to "Screen" if no value is set
                })
              )
              : [];

          // Filter out Customer Profile documents from the documents field
          const filteredDocuments = transformDocuments(
            rcue?.customer_document
          )?.flatMap(({ keys, docs }) => {
            return docs
              ?.filter((doc) => {
                // Filter out documents associated with "Customer Profile"
                const isCustomerProfileDoc = keys.includes("Customer Profile");
                return !isCustomerProfileDoc;
              })
              ?.map((doc) => {
                const rcuProcessValue =
                  values[
                  `document_${rcue?.customer_name?.id}_${doc?.id}_${keys}_${doc?.document_type}`
                  ];
                if (rcuProcessValue) {
                  return {
                    document_id: doc?.id,
                    rcu_process: rcuProcessValue,
                  };
                }
                return undefined; // Filter out undefined entries
              })
              .filter((doc) => doc !== undefined);
          });

          // Return the formatted sampling data
          return {
            customer_id: rcue?.customer_name?.id,
            address: rcue?.customer_address?.map((addr) => ({
              address_id: addr?.id,
              rcu_process: values[`address_${addr?.id}`],
            })),
            documents: filteredDocuments,
            // Only include `customerProfileDocs` if choice !== "Document"
            customer_profile:
              customerProfileDocs.length > 0 ? customerProfileDocs : undefined,
          };
        }
      ),
    };

    try {
      const response = await postRCUInitiate(formattedValues);
      if (response && response.success) {
        message.success("RCU initiated successfully");
        setSubmitStageBar()
        closeForm();
        form.resetFields();
        setImages([]);
      } else {
        message.error("Failed to update details");
        setImages([]);
        setValue("");
      }
    } catch (error) {
      message.error("Failed to update details due to an error");
      setImages([]);
      setValue("");
      closeForm();
      setLoading(false);
    }
  };

  const vendorCodeMap = vendors?.reduce((map, vendor) => {
    map[vendor.id] = vendor.code;
    return map;
  }, {});

  const handleSelectChange = (value, dropdownId, customerId, keys, type) => {
    form.setFieldsValue({
      [`document_${customerId}_${dropdownId}_${keys}_${type}`]: value,
      // document_${rcue.customer_name.id}_${doc?.id}
    });

    form.setFieldsValue({
      ...form.getFieldsValue(),
    });
  };

  const handleChange = (id) => {
    const code = vendorCodeMap[id];
    setAgentsselected(code);

    form.setFieldsValue({
      agent_name: null,
      mail_to: undefined,
    });

    setAgents([]);
  };

  const transformDocuments = (documents) => {
    if (!documents || typeof documents !== "object") return [];
    return Object.entries(documents)?.map(([keys, docs]) => ({
      keys,
      docs,
    }));
  };

  const handleAgentChange = (agentId) => {
    if (!agentId) {
      form.setFieldsValue({ mail_to: undefined });
      form.setFieldValue({ agent_name: null });
    } else {
      const selectedAgent = agents.find((agent) => agent?.id === agentId);

      // Update the mail_cc field with the selected agent's email
      if (selectedAgent) {
        form.setFieldsValue({
          mail_to: selectedAgent.email,
        });
        form.setFieldValue({ agent_name: null });
      }
    }
  };

  const filteredDocuments = (customerDocument) => {
    return transformDocuments(customerDocument).filter(({ keys }) => {
      if (choice === "Profile") {
        return keys === "Customer Profile";
      } else if (choice === "Documents") {
        return keys !== "Customer Profile";
      } else {
        return true; // For "Both", display all documents
      }
    });
  };

  const closeForms = () => {
    closeForm();
    form.resetFields();
    setImages([]);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="rcu_inition_container"
        >
          <div>
            <Checkbox />
            <span className="legal_property_heading">
              &nbsp; Attach Application Form
            </span>
          </div>

          <div className="rcu_inition_input_row">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="vendor"
                  label="Vendor :"
                  required
                  rules={[{ required: true, message: "Vendor is required" }]}
                >
                  <Select
                    placeholder="Please Select"
                    allowClear
                    onChange={handleChange}
                  >
                    {vendors?.map((vendor) => (
                      <Option value={vendor?.id} key={vendor?.code}>
                        {vendor?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="agent_name"
                  label="Agent Name :"
                  required
                  rules={
                    !agents || agents.length === 0
                      ? []
                      : [{ required: true, message: "Agent Name is required" }]
                  }
                >
                  <Select
                    placeholder="Please Select"
                    allowClear
                    onChange={handleAgentChange}
                    disabled={!agents || agents.length === 0}
                  >
                    {agents?.map((agent) => (
                      <Option value={agent?.id} key={agent?.id}>
                        {agent?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="type_of_check"
                  label="Type Of Check:"
                  required
                  rules={[
                    { required: true, message: "Type Of Check is required" },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    onChange={(value) => setChoice(value)} // Corrected onChange event
                    allowClear
                  >
                    {rcutype?.map((choice, index) => (
                      <Option key={index} value={choice}>
                        {choice}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="Address_text_rcu">
            Address Sampling <span className="Address_text_rcu_star">*</span>
          </div>
          {modifiedDatas?.map((rcue) =>
            rcue?.customer_address && rcue?.customer_address?.length > 0 ? (
              <div
                className="rcu_div_container"
                key={rcue?.customer_name?.customer_id}
              >
                <div className="loan_fiinition">
                  <div className="loan_fiinition_heading">
                    {rcue?.customer_type?.name} Name:{" "}
                    {`${rcue?.customer_name?.full_name}`}
                  </div>
                  <div className="loan_fiinition_heading">
                    Mobile Number: {rcue?.customer_name?.mobile_number}
                  </div>
                </div>

                <div>
                  <div className="rcu_table_heading">
                    <div className="rcu_source_column">Address Type</div>
                    <div className="rcu_source_column_two">
                      Select RCU Process For Address
                    </div>
                    <div className="rcu_source_column_third">
                      Address Details
                    </div>
                  </div>
                  <div>
                    {rcue?.customer_address?.map((addr) => (
                      <div className="table_data" key={addr.id}>
                        <div className="table_data_body">
                          {addr.address_type.name}
                        </div>
                        <div className="table_data_body">
                          <Form.Item
                            name={`address_${addr.id}`}
                            rules={[
                              {
                                required: true,
                                message: "Please Select",
                              },
                            ]}
                          >
                            <Select
                              className="select_rcu_inition"
                              placeholder="Please Select"
                              allowClear
                            >
                              {rcu?.map((rcus) => (
                                <Option value={rcus?.value} key={rcus?.value}>
                                  {rcus?.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="table_data_body_two">
                          <p>
                            {addr.address_line_1}, {addr.address_line_2},{" "}
                            {addr.city.name}, {addr.district.name},{" "}
                            {addr.taluka.name}, {addr.state.name} -{" "}
                            {addr.pincode.name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null
          )}

          <div className="Address_text_rcu">
            Documents Sampling <span className="Address_text_rcu_star">*</span>
          </div>

          {modifiedDatas &&
            modifiedDatas?.map(
              (rcue) =>
                rcue?.customer_document && (
                  <div
                    className="rcu_div_container"
                    key={rcue?.customer_name?.customer_id}
                  >
                    <div className="loan_fiinition">
                      <div className="loan_fiinition_heading">
                        {rcue?.customer_type?.name} Name:{" "}
                        {rcue?.customer_name?.full_name}{" "}
                      </div>
                      {/* <div className="loan_fiinition_heading"> */}
                      {/* Mobile Number: {rcue?.customer_name?.mobile_number} */}
                      <Form.Item name={`dropdown_${rcue.id}`}>
                        <Select
                          className="select_rcu_inition"
                          placeholder="Please Select"
                          allowClear
                          onChange={(value) => {
                            const updatedFields = {
                              ...form.getFieldsValue(),
                              [`dropdown_${rcue.id}`]: value,

                              ...filteredDocuments(
                                rcue.customer_document
                              ).reduce((acc, { docs, keys }) => {
                                docs.forEach((doc) => {
                                  acc[
                                    `selectDocument_${rcue.customer_name.id}_${doc?.id}`
                                  ] = value;
                                  acc[
                                    `document_${rcue.customer_name.id}_${doc?.id}_${keys}_${doc.document_type}`
                                  ] = value;
                                });
                                return acc;
                              }, {}),
                            };

                            // Set the updated fields in the form
                            form.setFieldsValue(updatedFields);
                          }}
                        >
                          {rcu?.map((rcus) => (
                            <Option value={rcus?.value} key={rcus?.value}>
                              {rcus?.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {/* </div> */}
                    </div>

                    <div>
                      {/* <Row>
                    <Col span={6}> */}
                      <div className="rcu_initiotion_document_table">
                        <div className="table_data_body">
                          <div className="source-column-div">Group</div>
                        </div>
                        {/* </Col> */}
                        {/* <Col span={6}> */}
                        <div className="table_data_body">
                          <div className="source-column-div">RCU Process</div>
                        </div>
                        {/* </Col> */}
                        {/* <Col span={6}> */}
                        <div className="table_data_body">
                          <div className="source-column-div">Documents</div>
                        </div>
                        {/* </Col> */}
                        {/* <Col span={6}> */}
                        <div className="table_data_body">
                          <div className="source-column-div">RCU Process</div>
                        </div>
                      </div>
                      {/* </Col>
                  </Row> */}

                      {filteredDocuments(rcue?.customer_document)?.map(
                        ({ keys, docs }) => (
                          <div className="table_data" key={keys}>
                            {/* <Row> */}
                            {/* <Col span={6}> */}
                            <div className="table_data_body">
                              <div className="keys-column rcu_contnet">
                                {keys}
                              </div>
                            </div>
                            {/* </Col> */}
                            {/* <Col span={6}> */}
                            <div className="table_data_body">
                              <div className="source-column-div">
                                {docs?.[0]?.group_name}
                                <Col span={6}>
                                  <Form.Item
                                    // name={`dropdown_${docs?.[0]?.id}`}
                                    name={`selectDocument_${rcue?.customer_name?.id}_${docs?.[0]?.id}`}
                                  >
                                    <Select
                                      className="select_rcu_inition"
                                      placeholder="Please Select"
                                      allowClear
                                      onChange={(value) => {
                                        // Iterate over docs array and update each document
                                        docs.forEach((doc) => {
                                          handleSelectChange(
                                            value,
                                            doc.id,
                                            rcue.customer_name.id,
                                            keys,
                                            doc.document_type
                                          );
                                        });
                                      }}
                                    // onChange={(value) => handleSelectChange(value, docs[0]?.id, rcue.customer_name.id)}
                                    >
                                      {rcu?.map((rcus) => (
                                        <Option
                                          value={rcus?.value}
                                          key={rcus?.value}
                                        >
                                          {rcus?.label}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </div>
                            </div>
                            {/* </Col> */}
                            {/* <Col span={6}> */}
                            <div className="table_data_body">
                              <div className="document-image-container">
                                {docs?.map((doc) => {
                                  if (!doc.path) {
                                    return (
                                      <div
                                        className="document_third"
                                        key={doc?.id}
                                      >
                                        <p>No document available</p>
                                      </div>
                                    );
                                  }

                                  // Extract the file name from the URL
                                  const parts = doc.path.split("/");
                                  const fileName = parts.pop().split("?")[0]; // Get the last part before the query params
                                  const fileExtension = fileName
                                    .split(".")
                                    .pop()
                                    .toLowerCase();

                                  return (
                                    <div
                                      className="document_third"
                                      key={doc.id}
                                    >
                                      <div className="img_document">
                                        {/* Conditionally render based on file extension */}
                                        {["png", "jpg", "jpeg"].includes(
                                          fileExtension
                                        ) ? (
                                          <>
                                            <RViewerJS>
                                              <img
                                                src={doc.path}
                                                height={64}
                                                width={64}
                                                alt="Document"
                                              />
                                            </RViewerJS>
                                            <p className="rcu-doc-title">
                                              {doc?.document_type ||
                                                "Customer Profile"}
                                            </p>
                                          </>
                                        ) : fileExtension === "pdf" ? (
                                          <>
                                            <img
                                              src={pdf} // Assuming 'pdf_image' is an imported image placeholder for PDFs
                                              alt="PDF"
                                              height={64}
                                              width={64}
                                              className="rcu_image_pdf"
                                              onClick={() =>
                                                window.open(doc.path, "_blank")
                                              }
                                            />
                                            <p className="rcu-doc-title">
                                              {doc?.document_type ||
                                                "Customer Profile"}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <FileOutlined className="file_uplaod_icon_rcu" />
                                            <p className="rcu-doc-title">
                                              {doc?.document_type ||
                                                "Customer Profile"}
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            {/* </Col> */}
                            {/* <Col span={6}> */}
                            <div
                              className="table_data_body"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                              }}
                            >
                              {docs?.map((doc, index) => (
                                <div className="source-column-div">
                                  <Col span={6}>
                                    <Form.Item
                                      // name={`document_${rcue.customer_name.id}_`}
                                      name={`document_${rcue?.customer_name?.id}_${doc?.id}_${keys}_${doc?.document_type}`}
                                      style={{ width: "175px" }}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please Select",
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder="Please Select"
                                        allowClear
                                      >
                                        {rcu?.map((rcus) => (
                                          <Option
                                            value={rcus?.value}
                                            key={rcus?.value}
                                          >
                                            {rcus?.label}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </div>
                              ))}
                            </div>
                            {/* </Col> */}
                            {/* </Row> */}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
            )}

          <div className="email_div_container">
            <div className="select_rcu_initin">
              <div className="content_block">
                <span className="email_heading">
                  <span className="all_required_sign"> *</span>To :
                </span>
                <span className="email_content">
                  <Form.Item name="mail_to">
                    <Input disabled />
                  </Form.Item>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">
                  <span className="all_required_sign"> *</span>CC :
                </span>
                <span className="email_content">
                  <Form.Item name="mail_cc">
                    <Input
                      placeholder={user_data?.email}
                      value={user_data?.email}
                      disabled
                    />
                  </Form.Item>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">Add CC :</span>
                <span className="email_content">
                  <Form.Item name="add_cc">
                    <Input />
                  </Form.Item>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">
                  <span className="all_required_sign"> *</span>Subject :
                </span>
                <span className="email_content">
                  <Form.Item
                    name="mail_subject"
                  // rules={[{ required: true, message: "Subject is required" }]}
                  >
                    {generateMailSubject()}
                  </Form.Item>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">Message :</span>
                <span className="email_content">Dear Team,<br />
                  Please initiate RCU Search for {customerName} ({applicantType})
                  <div className="initiation_form_table_one ">
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Customer Name
                      </div>
                      <div className="initiation_form_table_data_value">
                        {customerName} ({applicantType})
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        LAN
                      </div>
                      <div className="initiation_form_table_data_value">
                        {sampledata?.loan_detail?.loan_account_number}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Product
                      </div>
                      <div className="initiation_form_table_data_value">
                        {sampledata?.loan_detail?.loan_type?.display_name}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Check Type
                      </div>
                      <div className="initiation_form_table_data_value">
                        {choice}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Property Address
                      </div>
                      <div className="initiation_form_table_data_value">
                        {`${sampledata?.property_detail?.address_line_1}, ${sampledata?.property_detail?.address_line_2}, ${sampledata?.property_detail?.city?.name}, ${sampledata?.property_detail?.district?.name}, ${sampledata?.property_detail?.taluka?.name}, ${sampledata?.property_detail?.state?.name}, ${sampledata?.property_detail?.pincode?.name}`}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Branch
                      </div>
                      <div className="initiation_form_table_data_value">
                        {sampledata?.loan_detail?.location?.display_name}
                      </div>
                    </div>
                    <div className="initiation_form_table_data">
                      <div className="initiation_form_table_data_heading">
                        Contact Person
                      </div>
                      <div className="initiation_form_table_data_value">
                        {customerName} ({applicantType})
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="content_block">
                <span className="email_heading">
                  <span className="all_required_sign"> *</span> Remarks:
                  <br />
                  (If attached, please mention the list of documents)
                </span>
                <span className="email_content">
                  <Form.Item
                    name="remark"
                    rules={[
                      {
                        required: true,
                        message: "Please enter remarks",
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Please Enter Remark"
                      value={value}
                      onChange={(val) => {
                        setValue(val);
                        // form.setFieldsValue({ remark: val });
                      }}
                    />
                  </Form.Item>
                </span>
              </div>

              <div className="content_block">
                <span className="email_heading">
                  Attachments:<br></br>
                </span>
                {/* <span className="email_content"></span> */}
                <div className="rcu_inition_input_row">
                  <Row gutter={16}>
                    <Col span={24}>
                      <div className="files">
                        <div className="rcu_files_two">
                          <Upload
                            beforeUpload={handleBeforeUpload}
                            fileList={fileList}
                            onRemove={handleRemove}
                            showUploadList={false}
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                            listType="picture-card"
                            className="avatar-uploader"
                          >
                            <PlusOutlined />
                          </Upload>
                        </div>
                        <div className="uploaded_files_rcu">
                          {images?.map((image) => (
                            <div
                              key={image.uid}
                              className="uploaded-file rcu_inaitin_im"
                            >
                              <RViewerJS>
                                {image.name.endsWith(".pdf") ? (
                                  <img
                                    src={pdf}
                                    height={64}
                                    width={64}
                                    alt={image.file_path}
                                  />
                                ) : (
                                  // Otherwise, display the image as usual
                                  <img
                                    src={image.file_path}
                                    alt={image.name}
                                    height={50}
                                    width={50}
                                    className="rcu_image_pdf"
                                  />
                                )}
                              </RViewerJS>
                              <p className="rcu_text_image">{image.name}</p>

                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  setDeleteUploadPicture(image);
                                  setOpenUploadModal(true);
                                }}
                                className="deletc_rcu_initition"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <Modal
                        title="Confirm Delete"
                        onOk={() => confirmDelete(deleteUploadPicture)}
                        onCancel={() => setOpenUploadModal(false)}
                        open={openUploadModal}
                      >
                        <p>Are you sure you want to delete this file?</p>
                      </Modal>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <Space
            direction="horizontal"
            align="center"
            className="intiate_cancle_button"
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Initiate
            </Button>
            <Button onClick={closeForms}>Cancel</Button>
          </Space>
        </Form>
      )}
    </>
  );
};

export default RCUInitiationForm;
