import React, { useEffect, useState } from "react";
import EditButton from "../../../../utils/editButton/EditButton";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import DocumentsRvEditForm from "./DocumentsRvEditForm";
import { getFiUploadDocumentsByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import pdf_image from "../../assets/pdf.png";
import RViewerJS from "viewerjs-react";

const DocumentsRv = ({ selectedCustomerId }) => {
  const [open, setOpen] = useState(false);
  const [collapsed6, setCollapsed6] = useState(true);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [otherDocumentsData, setOtherDocumentsData] = useState(null);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getFiUploadDocumentsByCustomerId(selectedCustomerId);
        setOtherDocumentsData(reponse?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData,selectedCustomerId]);
  const showDrawer = () => {
    setOpen(true);
  };

  const toggleCollapse6 = () => {
    setCollapsed6(!collapsed6);
  };

  const onClose6 = () => {
    setOpen(false);
  };
  const renderEditButton = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div className="fi_Views_main_container">
      <Drawer
        title={<Header title="Edit" onClose={onClose6} name="FI Documents and Other Documents" />}
        width={970}
        onClose={onClose6}
        open={open}
        closable={false}
      >
        <DocumentsRvEditForm
          open={open}
          closeForm={onClose6}
          refreshData={toggleRefreshTableData}
          images={otherDocumentsData}
          selectedCustomerId={selectedCustomerId}
        />
      </Drawer>
      <div className="fi_views_description_heading">
        <div className="fi_views_heading_name">
          FI Documents and Other Documents{" "}
        </div>
        <div className="fi_views_button_space">
          <div
           className="fi_views_edit_button"
          >
            {renderEditButton()}
          </div>
          <div
           className="fi_views_collapse_icon"
            onClick={toggleCollapse6}
          >
            {collapsed6 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed6 && (
        <div>
          <>
          <div className='document_images'>
              {otherDocumentsData?.map((image, index) => (
                <div className="document_images_rv" key={index} >
                  {image.file_type === "application/pdf" ? (
                    <img
                      src={pdf_image}
                      width="100px"
                      height="100px"
                   
                      className="pdf_rv_view"
                      alt="PDF Preview"
                      onClick={() => window.open(image.document_path, '_blank')}
                    />
                  ) : (
                    <RViewerJS
                      options={{
                        url: (img) => img.getAttribute("data-original"),
                      }}
                    >
                      <img
                        src={image.thumb_document}
                        data-original={image.document_path}
                        alt="Preview"
                        className="thummb_rv_view"

                      />
                    </RViewerJS>
                  )}
                  <div className="thumb_document_name">
                    {image.document_type.document_name}
                  </div>
                </div>
              ))}
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default DocumentsRv;
