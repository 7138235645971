// validationSchema.js
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  address: Yup.string()
   .required("Property Address is  required")
    .min(2, "Property Address must be at least 2 characters!")
    .max(256, "Property Address must be at max 32 characters!")
    .label("Property Address"),
  product: Yup.string()
    .required("Product is required")
    .label("Middle Name"),
  loan_account_number: Yup.string()
    .required("Loan Account Number is  required")
    .min(15, "Loan Account Number must be at least 15 characters!")
    .max(15, "Loan Account Number must be at max 15 characters!")
    .label("Last Account Number"),
  current_owner_name: Yup.string()
    .required("Name of Current Owner / Seller is required")
    .matches(/^[A-Za-z ]+$/, 'Name of Current Owner / Seller can only contain letters')
    // .min(5, "Name of Current Owner / Seller must be at least 5 characters!")
    .max(256, "Name of Current Owner / Seller must be at max 256 characters!")
    .label("Name of Current Owner"),
  ploat_as_per_plan: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Plot Area/UDS As per Plan/deed (in sqft) can only contain numbers')
    .label("Plot Area/UDS As per Plan/deed (in sqft)"),
  ploat_as_per_site: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Plot Area/UDS As per Site (In sqft) can only contain numbers')
    .label("Plot Area/UDS"),
  ploat_deviation: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Plot Area/UDS Deviation in % can only contain numbers')
    .label("Plot Area/UDS"),
  ploat_rate: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Plot Area/UDS Rate / Sqft can only contain numbers')
    .label("Plot Area/UDS"),
  ploat_percentage_completed: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Plot Area/UDS % Completed can only contain numbers')
    .test('max', 'Max Limit 100', value => parseFloat(value) <= 100)
    .label("Plot Area/UDS"),
  ploat_percentage_recommended: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Plot Area/UDS % Recommended can only contain numbers')
    .test('max', 'Max Limit 100', value => parseFloat(value) <= 100)
    .label("Plot Area/UDS"),
  ploat_valuation: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Plot Area/UDS Valuation INR can only contain numbers')
    .label("Plot Area/UDS"),
  eca_as_per_plan: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area As per Plan/deed (in sqft) can only contain numbers')
    .label("Plot Area/UDS"),
  eca_as_per_site: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area As per Site (In sqft) can only contain numbers')
    .label("Plot Area/UDS"),
  eca_deviation: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area Deviation in % can only contain numbers')
    .label("Plot Area/UDS"),
  eca_rate: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area Rate / Sqft	can only contain numbers')
    .label("Plot Area/UDS"),
  eca_percentage_completed: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area % Completed can only contain numbers')
    .test('max', 'Max Limit 100', value => parseFloat(value) <= 100)
    .label("Plot Area/UDS"),
  eca_percentage_recommended: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area % Recommended can only contain numbers')
    .test('max', 'Max Limit 100', value => parseFloat(value) <= 100)
    .label("Plot Area/UDS"),
  eca_valuation: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area Valuation INR Recommended can only contain numbers')
    .label("Plot Area/UDS"),
  pca_as_per_plan: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area As per Plan/deed (in sqft) can only contain numbers')
    .label("Plot Area/UDS"),
  pca_as_per_site: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area As per Site (In sqft) can only contain numbers')
    .label("Plot Area/UDS"),
  pca_deviation: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area Deviation in % can only contain numbers')
    .label("Plot Area/UDS"),
  pca_rate: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area Rate / Sqft	can only contain numbers')
    .label("Plot Area/UDS"),
    pca_percentage_completed: Yup.string()
  .required("This field is required")
  .matches(/^[\d ]+(\.\d+)?$/, "Existing construction area % Completed can only contain numbers")
  .test('max', 'Max Limit 100', value => parseFloat(value) <= 100)
  .label("Plot Area/UDS"),
  pca_percentage_recommended: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area % Recommended can only contain numbers')
    .test('max', 'Max Limit 100', value => parseFloat(value) <= 100)
    .label("Plot Area/UDS"),
  pca_valuation: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Existing construction area Valuation INR Recommended can only contain numbers')
    .label("Plot Area/UDS"),
  amenities_as_per_plan: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Amenities As Per Plan/deed (in sqft)	can only contain numbers')
    .label("Plot Area/UDS"),
  amenities_percentage_completed: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Amenities Percentage Completed can only contain numbers')
    .test('max', 'Value cannot be more than 100', value => parseFloat(value) <= 100)
    .label("Plot Area/UDS"),
  amenities_percentage_recommended: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Amenities Percentage Recommended can only contain numbers')
    .test('max', 'Value cannot be more than 100', value => parseFloat(value) <= 100)
    .label("Plot Area/UDS"),
  amenities_valuation: Yup.string()
    .required("This field is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Amenities Valuation can only contain numbers')
    .label("Plot Area/UDS"),
  land_value: Yup.string()
    .required("Government guideline/circle rate for land is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Government guideline/circle rate for land	can only contain numbers')
    .label("Government guideline/circle rate for land"),
  government_rate_for_bldg: Yup.string()
    .required("Government guideline/circle rate for building is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Government guideline/circle rate for building can only contain numbers')
    .label("Government guideline/circle rate for building"),
  land_value_government: Yup.string()
    .required("Land value as per govt rate is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Land value as per govt rate can only contain numbers')
    .label("Land value as per govt rate"),
  total_valuation_goverment: Yup.string()
    .required("Total valuation of property as per government rate is required")
    .matches(/^[\d ]+(\.\d+)?$/, 'Total valuation of property as per government rate only contain numbers')
    .label("Total valuation of property as per government rates"),
  valuation_remark: Yup.string()
    .required("Remark is required")
    // .matches(/^[\d ]+(\.\d+)?$/, 'Remark only contain numbers')
    .label("Remark"),
  date_of_visit: Yup.date()
  .max(new Date(), "Future dates are not allowed") 
    .required("Date Of Visit is required")
    .label("Date Of Visit"),
    
  date_of_valuation: Yup.date()
    .required("Date of report submission is required")
    .max(new Date(), "Future dates are not allowed") 
    .label("Date Of Valuation"),
    date_of_inspection: Yup.date()
    .required("Date Of Inspection is required")
    .max(new Date(), "Date Of Inspection cannot be in the future")
    .label("Date Of Inspection"),
  engineer_name: Yup.string()
    .required("Name of Engineer Visited the property is required")
    // .min(5, "Engineer Name must be at least 5 characters!")
    .max(256, "Engineer Name must be at max 256 characters!")
    .label("Engineer Name"),
  declaration: Yup.bool()
    .required("Declaration is required")
    .oneOf([true], "You must accept the declaration."),
  document_address1: Yup.string()
    .required("Address Line 1 is required")
    // .min(2, "Address Line 1 must be at least 5 characters!")
    .max(256, "Address Line 1 must be at max 256 characters!")
    .label("Address Line 1"),
  document_address2: Yup.string()
    .required("Address Line 2 is required")
    // .min(2, "Address Line 2 must be at least 2 characters!")
    .max(256, "Address Line 2 must be at max 256 characters!")
    .label("Address Line 2"),
  document_survey_number: Yup.string()
    .required("Survey Number is required")
    // .min(5, "Survey Number must be at least 5 characters!")
    .max(256, "Survey Number must be at max 256 characters!")
    .label("Survey Number"),
  document_plot_number: Yup.string()
    .required("Plot No. is required")
    // .min(5, "Plot No. must be at least 5 characters!")
    .max(256, "Plot No. must be at max 256 characters!")
    .label("Property Address"),
  document_house_number: Yup.string()
    .required("House No./ Flat No is required")
    // .min(5, "House No./ Flat No must be at least 5 characters!")
    .max(256, "House No./ Flat No must be at max 256 characters!")
    .label("Property Address"),
  document_state: Yup.string()
    .required("State is required")
    .label("State"),
  document_city: Yup.string()
    .required("City is required")
    .label("City"),
  document_pincode: Yup.string()
    .required("Pincode is required")
    .label("Pincode Address"),
  document_taluka: Yup.string()
    .required("Taluka is required")
    // .min(5, "Taluka must be at least 5 characters!")
    .max(256, "Taluka must be at max 256 characters!")
    .label("Taluka"),
  document_district: Yup.string()
    .required("District is required")
    // .min(5, "District must be at least 5 characters!")
    .max(256, "District must be at max 256 characters!")
    .label("District"),
  document_country: Yup.string()
    .required("Country is required")
    .min(3, "Country must be at least 3 characters!")
    .max(256, "Country must be at max 256 characters!")
    .label("Country"),
  site_address1: Yup.string()
    .required("Address Line 1 is required")
    // .min(2, "Address Line 1 must be at least 5 characters!")
    .max(256, "Address Line 1 must be at max 256 characters!")
    .label("Address Line 1"),
  site_address2: Yup.string()
    .required("Address Line 2 is required")
    // .min(2, "Address Line 2 must be at least 2 characters!")
    .max(256, "Address Line 2 must be at max 256 characters!")
    .label("Address Line 2"),
  site_survey_number: Yup.string()
    .required("Survey Number is required")
    // .min(5, "Survey Number must be at least 5 characters!")
    .max(256, "Survey Number must be at max 256 characters!")
    .label("Survey Number"),
  site_plot_number: Yup.string()
    .required("Plot No. is required")
    // .min(5, "Plot No. must be at least 5 characters!")
    .max(256, "Plot No. must be at max 256 characters!")
    .label("Property Address"),
  site_house_number: Yup.string()
    .required("House No./ Flat No is required")
    // .min(5, "House No./ Flat No must be at least 5 characters!")
    .max(256, "House No./ Flat No must be at max 256 characters!")
    .label("Property Address"),
  site_state: Yup.string()
    .required("State is required")
    .label("State"),
  site_city: Yup.string()
    .required("City is required")
    .label("City"),
  site_pincode: Yup.string()
    .required("Pincode is required")
    .label("Pincode Address"),
  site_taluka: Yup.string()
    .required("Taluka is required")
    // .min(5, "Taluka must be at least 2 characters!")
    .max(256, "Taluka must be at max 256 characters!")
    .label("Taluka"),
  site_district: Yup.string()
    .required("District is required")
    // .min(5, "District must be at least 5 characters!")
    .max(256, "District must be at max 256 characters!")
    .label("District"),
  site_country: Yup.string()
    .required("Country is required")
    .min(3, "Country must be at least 3 characters!")
    .max(256, "Country must be at max 256 characters!")
    .label("Country"),
  address_matching: Yup.string()
    .required("Address Matching is required")
    .label("Address Matching"),
    latitude: Yup.string()
    .required("Latitude is required") 
    .label("Latitude"), 
  longitude: Yup.string()
    .required("Longitude is required") 
    .label("Longitude"),
  document_north: Yup.string()
    .required("Document North is required")
    .label("Document North"),
  document_south: Yup.string()
    .required("Document South is required")
    .label("Document South"),
  document_east: Yup.string()
    .required("Document East is required")
    .label("Document East"),
  document_west: Yup.string()
    .required("Document West is required")
    .label("Document West"),
  actual_north: Yup.string()
    .required("Actual North is required")
    .label("Actual North"),
  actual_south: Yup.string()
    .required("Actual South is required")
    .label("Actual South"),
  actual_east: Yup.string()
    .required("Actual East is required")
    .label("Actual East"),
  actual_west: Yup.string()
    .required("Actual West is required")
    .label("Actual West"),
  boundaries_matching: Yup.string()
    .required("Boundaries Matching is required")
    .label("Actual West"),
  floors_constructed: Yup.string()
    .required("Number Of Floors Constructed is required")
    .label("Floors Constructed"),
  dcr_norms: Yup.string()
    .required("Complying DCR Norms is required")
    .label("DCR Norms"),
  document_number: Yup.string()
    .required("Document number is required") 
    .label("Document Approval Number"), // Adjust the label as needed
  collection_status: Yup.string()
    .required("Collection status is required") 
    .label("Collection Status"),
  overall_status: Yup.string() 
    .required("Overall status is required") 
    .label("Overall status"),
  developed_by: Yup.string() 
    .required("Layout Developed By is required") 
    .label("Developed By"),
  type_of_property_document: Yup.string() 
    .required("Type Of Property Document is required") 
    .label("Type Of Property Document"),
  zoning: Yup.string() 
    .required("Location/Zoning is required") 
    .label("Zoning"),
  approch_road_type: Yup.string() 
    .required("Approach Road Type is required") 
    .label("Approach Road Type"),
  approch_road_width: Yup.string() 
    .required("Approach Road Width is required") 
    .label("Approach Road Width"),
  govt_private_road: Yup.string() 
    .required("Govt road or Private passage is required") 
    .label("Government Private Road"),
  identified_through: Yup.string() 
    .required("Identified Through is required") 
    .label("Identified Through"),
  met_person_name: Yup.string() 
    .required("Person Met At Site is required") 
    .label("Person Met At Site"),
  occupied_since: Yup.string() 
    .required("Occupied Since is required") 
    .label("Occupied Since"),
  occupation_status: Yup.string() 
    .required("Occupation Status is required") 
    .label("Occupation Status"),
  residual_life_of_property: Yup.string() 
    .required("Residual Life Of Property is required") 
    .label("Developed By"),
  age_of_property: Yup.string() 
    .required("Age Of Property is required") 
    .label("Residual Life Of Property"),
  amenities_available: Yup.string() 
    .required("Amenities Available is required") 
    .label("Amenities Available"),
  toilet: Yup.string() 
    .required("Toilet is required") 
    .label("Toilet"),
  maintenance_level: Yup.string() 
    .required("Maintenance Level is required") 
    .label("Maintenance Level"),
  situated_near_canal: Yup.string() 
    .required("Property Situated Near Nala/Canal is required") 
    .label("Situated Near Canal"),
  distance_from_canal: Yup.string() 
    .required("Distance From Nala/Canal is required") 
    .label("Distance From Canal"),
  situated_near_ht_lines: Yup.string() 
    .required("Property Situated Near HT Lines is required") 
    .label("Situated Near HT Lines"),
  distance_from_ht_line: Yup.string() 
    .required("Distance From HT Lines is required") 
    .label("Distance From HT Lines"), 
  seismic_zone: Yup.string() 
    .required("Seismic Zone Classification is required") 
    .label("Seismic Zone"),
  marketability: Yup.string() 
    .required("Marketability is required") 
    .label("Marketability"),
  any_hazard: Yup.string() 
    .required("Any Hazard is required") 
    .label("Any Hazard"),
  market_feedback: Yup.string() 
    .required("Market Feedback is required") 
    .label("Market Feedback"),
  commercial_portion_witnessed: Yup.string() 
    .required("Commercial Portion Witnessed is  required") 
    .label("Commercial Portion Witnessed"),
  no_of_kitchen: Yup.string() 
    .required("No Of Kitchen is  required") 
    .label("No Of Kitchen"),
  recent_demolition: Yup.string() 
    .required("Recent Demolition is  required") 
    .label("Recent Demolition"), 
  property_affected: Yup.string() 
    .required("Property Affected is  required") 
    .label("Property Affected"),
  road_widening: Yup.string() 
    .required("Road Widening is  required") 
    .label("Road Widening"),
  property_negative_area: Yup.string() 
    .required("Property Negative Area is  required") // Adjust the error  message as needed
    .label("Property Negative Area"),
  lift_available: Yup.string() 
    .required("Lift Available is required") 
    .label("Lift Available"),
  distance_from_branch: Yup.string() 
    .required("Distance From Branch is  required") 
    .label("Distance From Branch"),
  house_delivery_agency: Yup.string() 
    .required("House Delivery Agency is  required") 
    .label("House Delivery Agency"),
  development_vicinity: Yup.string() 
    .required("Developement Vicinity is  required") 
    .label("Developement Vicinity"),
  mc_gp_limit: Yup.string() 
    .required("Within Corporation is  required") 
    .label("Within Corporation"),
  structure_government: Yup.string() 
    .required("Structure Government is  required") 
    .label("Structure Government"),
  as_per_approved: Yup.string() 
    .required("Availability of Approved Plan is  required") 
    .label("Availability of Approved Plan"),
   
  risk_of_demolition: Yup.string() 
    .required("Risk Of Demolition is  required") 
    .label("Risk Of Demolition"),
  basic_amenities: Yup.string() 
    .required("Availability of basic amenities required") 
    .label("Basic Amenities"),
  status_land_holding: Yup.string() 
    .required("Status Land Holding is  required") 

    .label("Status Land Holding"),
  place: Yup.string() 
    .required("Place is  required") 
    .label("Place"),
  floors_permitted: Yup.string() 
    .required("Number Of Floors Permitted is required") 
    .label("Floors Permitted"),
  fsi_achieved: Yup.string() 
    .required("FSI Achieved is  required") 
    .label("FSI Achieved"),
  apt_value_gov: Yup.string() 
    .required("Please Enter Flat/apartment value as per government rate (Rs) is  required") 
    .label("Please Enter Flat/apartment value as per government rate (Rs)"),
  type_of_structure: Yup.string() 
    .required("Type Of Roof is  required") 
    .label("Type Of Roof"),
  locality_type: Yup.string() 
    .required("Locality Type is  required") 
    .label("Locality Type"), 
  before_years: Yup.string() 
  .required("Years Before Recent Demolition is required") 
  .label("Before Years"),
  remark: Yup.string() 
  .required("Remarks /Observation is required") 
  .label("Remark"), 
  boundary_mismatch_remark: Yup.string() 
  .required("Mismatch Remark is required") 
  .label("Boundaries Mismatch Remark"),
  mobile: Yup.string()
    .required("Contact Number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits and contain only numbers")
    .label("Contact Number"),
  type_of_property_site: Yup.string()
  .required("Type Of Property Site is required") 
  .label("Type Of Property Site"),
  demarcation: Yup.string()
  .required("Plot Demarcation Available is required") 
  .label("Demarcation"),
  construction_permission_no: Yup.string()
  .required("Construction Permission No is required") 
  .label("Construction Permission No"),
  local_byelaws: Yup.string()
  .required("Designation Of Authority is required") 
  .label("Designation Of Authority"),
  setback_deviation: Yup.string()
  .required("Setback Deviation is required") 
  .label("Setback Deviation"),
  vertical_deviation: Yup.string()
  .required("Vertical Deviation is required") 
  .label("Vertical Deviation"),
  extension_in_future: Yup.string()
  .required("Extension In Future is required") 
  .label("Extension In Future"),
  upload_documents:  Yup.string()
  .required("Documents is required") 
});

export default validationSchema;
