/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { message } from "antd";
import "../../styles/CustomerForm.css";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import SalesFormLoanDetails from "./SalesFormLoanDetails";
import SalesFormCustomerDetails from "./SalesFormCustomerDetails";
import SalesFormFinalSubmit from "../SalesForm"
import { useDispatch } from "react-redux";
import { setLoanId } from "../../../../redux/salesFormSlice";

const SalesForm = () => {
  const { encrypted_loan_id } = useParams();
  const [expand, setExapnd] = useState(true);
  const [customerExpand, setCustomerExapnd] = useState(true);
  const dispatch = useDispatch()
  const toggleExpand = () => {
    setExapnd((prev) => !prev);
  };

  const toggleCustomerExpand = () => {
    setCustomerExapnd((prev) => !prev);
  };

  useEffect(() => {
    try {
      dispatch(setLoanId(decrypt(encrypted_loan_id)));
    } catch (error) {
      message.error("Invalid Loan ID");
    }
  }, [encrypted_loan_id]);

  return (
    <>
    <div>
      <SalesFormFinalSubmit/>
    </div>

    <div className="customer_main_container"> 
      <div className="customer_main_heading" style={{ borderBottom: "none" }}>
        <div>
          <h2 className="heading_loan_details">Loan Details</h2>
        
        </div>
        
        <div className="customer_icon">
          {expand ? (
            <AiOutlineUp style={{ cursor: "pointer" }} onClick={toggleExpand} />
          ) : (
            <AiOutlineDown
              style={{ cursor: "pointer" }}
              onClick={toggleExpand}
            />
          )}
        </div>
      </div>
      <div style={{ display: expand ? "block" : "none" }}>
        <SalesFormLoanDetails  />
      </div>
    </div>
    <div style={{marginTop:"36px"}}></div>
    <div className="customer_main_container">
      <div className="customer_main_heading" style={{ borderBottom: "none" }}>
        <div>
          <h2 className="heading_loan_details">Customer Details</h2>
        </div>
        <div className="customer_icon">
          {customerExpand ? (
            <AiOutlineUp style={{ cursor: "pointer" }} onClick={toggleCustomerExpand} />
          ) : (
            <AiOutlineDown
              style={{ cursor: "pointer" }}
              onClick={toggleCustomerExpand}
            />
          )}
        </div>
      </div>
      <div style={{ display: customerExpand ? "block" : "none" }}>
        <SalesFormCustomerDetails />
      </div>
    </div>
    </>

  );
};

export default SalesForm;
