import React, { useEffect, useState } from "react";
import { Space, Modal, Typography, Drawer, Button } from "antd";
import { FilterOutlined, EyeOutlined } from "@ant-design/icons";
import { Form } from "antd";
import '../styles/partDisbursedinitiate.css'
import PartDisbursedFilterForm from "./PartDisbursedFilterForm";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import authorizeTableColumns from "../../../../utils/authorization/authorizeTableColumns";
import { encrypt } from "../../../../utils/cryptoUtils/cryptoUtils";

const { Title } = Typography;

const PartDisbursedInitiate = () => {
  const [listOfPartDisbursment, setListOfPartDisbursment] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedListOfPartDisbursement, setSelectedListOfPartDisbursement] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    location: null,
    loan_type: [],
    loan_status: [],
    sales_manager: [],
    amountType: null,
    created_by: [],
    modified_by: [],
  });

  const [form] = Form.useForm();

  const { user_data } = useSelector((state) => state.user);

  const showFilterDrawer = () => {
    setFilterOpen(true);
  };

  const closeFilterDrawer = () => {
    setSelectedListOfPartDisbursement(null);
    setFilterOpen(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!filterOpen && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false);
    }
  }, [filterOpen, actionPerformed]);

  const columns = [
    {
      title: "Loan Account Number",
      dataIndex: "loan_detail",
      render: (loanDetail) => loanDetail?.loan_account_number || "-",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (text) => text || "-",
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      render: (text) => text || "-",
    },
    {
      title: "Requested Amount",
      dataIndex: "disbursment_amount_requested",
      render: (amount) =>
        amount !== undefined ? `₹${amount.toLocaleString("en-IN")}` : "-",
    },
    
    {
      title: "Initiated By",
      dataIndex: "initiated_by",
      render: (initiatedBy) => initiatedBy?.name || "-",
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      render: (assignedTo) => assignedTo?.name || "-",
    },
    {
      title: "Initiated Date",
      dataIndex: "created_at",
      render: (date) => date || "-",
    },
    {
      title: "Status",
      render: (record) => (
        <div
          className="part_disbursed_initiate_status"
        >
          {record?.current_status?.display_name || "-"}
        </div>
      ),
    },
  ];

  const authorizedColumns = [
    {
      title: "View",
      fixed: "right",
      render: (_, record) => {
        const loanId = record?.loan_detail?.id;
        const part_disbursement_id =record?.id;
        
        const encryptedLoanId = loanId ? encrypt(loanId.toString()) : null;
        const encryptedpart_disbursement_id = part_disbursement_id ? encrypt(part_disbursement_id.toString()) : null;

        return (
          <Link
            to={{
              pathname: `/parstDisbursmenttable/${encryptedLoanId}/${encryptedpart_disbursement_id}`,
            }}
          >
            <EyeOutlined  className="part_disbursed_initiate_view" />
          </Link>
        );
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["MPANOB6"]
  );

  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
      className="part_disbursed_initiate_heading"
      >
        <Title level={2}>Part Disbursed Initiated</Title>

        <div
         className="part_disbursed_initiate_filter_box"
        >
          <Button
            onClick={showFilterDrawer}
            icon={<FilterOutlined />}
           className="part_disbursed_initiate_filter_button"
          >
            More Filter
          </Button>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/loan/part-disbursment/"
        tableData={listOfPartDisbursment}
        setTableData={setListOfPartDisbursment}
        tableColumns={tableColumns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search "}
        filters={filterFormData}
      />

      <Drawer
        title={<Header title="Filter" name="Applications" onClose={closeFilterDrawer} />}
        width={720}
        onClose={closeFilterDrawer}
        open={filterOpen}
        closable={false}
      >
        <PartDisbursedFilterForm
          refreshData={toggleRefreshTableData}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          open={showFilterDrawer}
          closeForm={closeFilterDrawer}
          form={form}
          setActionPerformed={setActionPerformed}
        />
      </Drawer>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedListOfPartDisbursement?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default PartDisbursedInitiate;
