import React, { useState, useEffect } from "react";
import slide1 from "../assets/slide1.png";
import slide2 from "../assets/slide2.png";
import slide3 from "../assets/slide3.png";
import slide4 from "../assets/slide4.png";
import "../style/DashBoardLiteImageSlider.css"
const DashBoardLiteImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Array of images
  const slides = [
    {
      url: slide4,
      title: "Beach Sunset",
      description: "Golden sunset over tropical beach",
    },
    {
      url: slide1,
      title: "Mountain Lake",
      description: "Serene mountain lake surrounded by pine forests",
    },
    {
      url: slide2,
      title: "City Skyline",
      description: "Modern city skyline at sunset",
    },
    {
      url: slide3,
      title: "Beach Sunset",
      description: "Golden sunset over tropical beach",
    },
  ];

  // Automatic sliding with 20-second interval
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Change slide every 20 seconds

    return () => clearInterval(intervalId);
  }, [slides.length]);

  return (
    <div className="relative w-full max-w-5xl mx-auto bg-gray-900 rounded-xl shadow-2xl">
      <div className="relative h-[500px] overflow-hidden rounded-xl">
        {/* Images container */}
        <div
          className="flex h-full w-full"
          style={{
            transition: "transform 1000ms ease-out",
            transform: `translateX(-${currentIndex * (100 / slides.length)}%)`, // Move the container by the correct slide width
            width: `${slides.length * 100}%`,
            display: "flex",
            flexDirection: "row",
            borderRadius: "10px",
          }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="relative flex-shrink-0"
              style={{
                width: `${100 / slides.length}%`, // Set each slide to occupy an equal portion of the container width
                height: "50vh",
              }}
            >
              <img
                src={slide.url}
                alt={slide.title}
                style={{ width: "100%", height: "100%", borderRadius:'10px'}}
                className="object-cover"
              />
            </div>
          ))}
        </div>

        {/* Slide indicators */}
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full transition-all duration-300 ${
                index === currentIndex
                  ? "bg-white scale-150 w-4"
                  : "bg-white/50"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashBoardLiteImageSlider;
