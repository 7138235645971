import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  HistoryOutlined,
  UpOutlined,
  // PlusOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Space, Table, message, } from "antd";
import {
  deleteOTCPDDByIdService,
  getOTCPDDByLoanIdService,
} from "../services/otcpddServices";
import { useParams } from "react-router-dom";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";
import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import AddOTCPDD from "./AddOTCPDD";
import Header from "../../../../../layout/views/Header";
import Remark from "./Remark";
import '../../OTCPDD/style/style.css'
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { FilePdfOutlined } from '@ant-design/icons';
import ConditionalRender from "../../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import authorizeTableColumns from "../../../../../../utils/authorization/authorizeTableColumns";
import Loading from "../../../../../../utils/loading/Loading";
import CustomNoData from "../../../../../../utils/noDataIcon/CustomNoData";

const OTCPDD = () => {
  const [collapsed1, setCollapsed1] = useState(false);
  const [collapsed2, setCollapsed2] = useState(false);
  const [collapsed3, setCollapsed3] = useState(false);
  const [collapsed4, setCollapsed4] = useState(false);
  const [collapsed5, setCollapsed5] = useState(false);
  const [openModal, setOpenModal] = useState(false);


  // const [selectedOtcPddData, setSelectedOtcPddData] = useState();
  const [otc, setOtc] = useState([]);
  const [pdd, setPdd] = useState([]);
  const [preDisbursals, setPreDisbursals] = useState([]);
  const [deferred, setDeferred] = useState([]);
  const [waived, setWaived] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [showAddOtcForm, setShowAddOtcForm] = useState(false);
  const [selectedOtcData, setSelectedOtcData] = useState();
  const [loading, setLoading] = useState(true);
  const { user_data } = useSelector((state) => state.user);

  const { encrypted_loan_id } = useParams();
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const onCloseOtcForm = () => {
    setShowAddOtcForm(false);
    setSelectedOtcData(null);
  };

  const showOtcFormDrawer = () => {
    setShowAddOtcForm(true);
  };


  useEffect(() => {
    const fetchCheques = async () => {
      try {
        const response = await getOTCPDDByLoanIdService(loan_id);
        setOtc(response?.data.OTC || []);
        setPdd(response?.data.PDD || []);
        setPreDisbursals(response?.data["Pre Disbursals"] || []);
        setDeferred(response?.data.Deferred || []);
        setWaived(response?.data.Waived || []);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
      finally {
        setLoading(false); // Set loading to false after fetching is done
      }
    };

    fetchCheques();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshTableData]);


  const deleteOtc = async (remark) => {
    try {
      const response = await deleteOTCPDDByIdService(
        selectedOtcData?.id,
        remark
      );

      if ((response.status = 200 && response.success === true)) {
        message.success("OTC PDD deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedOtcData(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };



  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };

  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };

  const toggleCollapse5 = () => {
    setCollapsed5(!collapsed5);
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      // width: "10px",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Type",
      dataIndex: "otc_pdd_type",
      // width: "100px",
      key: "otc_pdd_type",
      render: (text) => text || " - ",
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
      // width: "150px",
      render: (text) => text || " - ",
    },
    {
      title: "Description",
      dataIndex: "description",
      // width: "100px",
      key: "description",
      render: (text) => text || " - ",
    },
    {
      title: "Received Location",
      dataIndex: "received_at",
      key: "received_at",
      // width: "150px",
      render: (text) => text || " - ",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      // width: "100px",
      render: (text) => text || " - ",
    },
    {
      title: "Date",
      dataIndex: "due_date",
      key: "due_date",
      // width: "150px",
      render: (text) => text || " - ",
    },

    {
      title: "Documents",
      dataIndex: "docs_otc_pdc",
      // width: "150px",
      key: "docs_otc_pdc",
      render: (documents) => {
        return documents && documents.length ? (
          <div className="document_div_otc" >
            {documents.map((doc, index) => {
              return (
                <div key={index} className="document_div_otc_second" >
                  {doc.file_type === 'image/jpeg' ? (
                    <RViewerJS
                      options={{
                        url: (image) => image.getAttribute("data-original"), // This will fetch the full-size image for preview
                      }}
                    >
                      <img
                        src={doc.thumb_document_path} // Thumbnail image
                        data-original={doc.document_path}
                        alt={`Document ${index + 1}`}
                        className="otc_document_path"

                      />
                    </RViewerJS>

                  ) : doc.file_type === 'application/pdf' ? (
                    <a href={doc.document_path} target="_blank" rel="noopener noreferrer"
                      className="otc_pdf_documnet"
                    >
                      <FilePdfOutlined className="fileupload_otc" />
                    </a>
                  ) : (
                    "Unsupported file type"

                  )}
                </div>
              );
            })}
          </div>
        ) : (
          " - "
        );
      },
    },
  ];

  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITOTCP"]}
            >
              <EditOutlined

                className="edit_button_otc"
                onClick={() => {
                  setSelectedOtcData(record);
                  showOtcFormDrawer();
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["DELTOTCP"]}
            >
              <DeleteOutlined

                className="delete_button_otc"
                onClick={() => {
                  setOpenModal(true);
                  setSelectedOtcData(record);
                }}
              />
            </ConditionalRender>
            <HistoryOutlined
              className="history_button_otc" />
          </Space>
        );
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["EDITOTCP", "DELTOTCP"]
  );
  return (
    <div className="tab_main_container">
      <Drawer
        title={
          <Header
            title={selectedOtcData ? "Edit" : "Add"}
            onClose={onCloseOtcForm}
            name="OTC/PDD"
          />
        }
        width={900}
        onClose={onCloseOtcForm}
        open={showAddOtcForm}
        closable={false}
      >
        <AddOTCPDD
          id={selectedOtcData?.id}
          refreshData={toggleRefreshTableData}
          open={showAddOtcForm}
          closeForm={onCloseOtcForm}
        />
      </Drawer>
      <Remark
        refreshTableData={refreshTableData}
        selectedOtcData={selectedOtcData}
        visible={openModal}
        onConfirm={deleteOtc}
        onCancel={() => setOpenModal(false)}
      />



      <div className="add_otc_button" >
        <ConditionalRender
          userPermissions={user_data?.permissions}
          requiredPermissions={["POSTOTCP"]}
        >
          <Button
            icon={<PlusCircleOutlined />}
            onClick={showOtcFormDrawer}
            className="add_otc_button_icon"
          >
            Add OTC/PDD
          </Button>
        </ConditionalRender>
      </div>
      <div className="pddc_div_two">


        <div className="heading_details_container_tab_operation">

          <div

          >

            <div className={`heading_text ${collapsed1 ? "no-border" : ""}`}>
              <div>OTC</div>
              <div>
                <div
                  onClick={toggleCollapse1}
                >
                  {collapsed1 ? <DownOutlined /> : <UpOutlined />}
                </div>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : otc.length > 0 ? (
              <>
                {!collapsed1 && (

                  <div className="otc_pdd_table_container">
                    <Table dataSource={otc} columns={tableColumns} pagination={false} />
                  </div>
                )}
              </>
            ) : (
              <div className="no_data_found_message">
                <CustomNoData />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="pddc_div_two">
        <div className="heading_details_container_tab_operation">
          {/* <div className="heading_details_container"
          style={{ borderBottom: !collapsed2 && "none" }}> */}
          <div

          >
            <div className={`heading_text ${collapsed1 ? "no-border" : ""}`}>
              <div>PDD</div>
              <div>
                <div
                  onClick={toggleCollapse2}
                >
                  {collapsed2 ? <DownOutlined /> : <UpOutlined />}
                </div>
              </div>
            </div>
            {!collapsed2 && (
              <div className="otc_pdd_table_container">
                <Table dataSource={pdd} columns={tableColumns} pagination={false} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="pddc_div_two">

        <div className="heading_details_container_tab_operation"

        >

          <div className={`heading_text ${collapsed1 ? "no-border" : ""}`}>
            <div> Waived</div>
            <div>
              <div
                onClick={toggleCollapse3}
              >
                {collapsed3 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {!collapsed3 && (
            <div className="otc_pdd_table_container">
              <Table dataSource={waived} columns={tableColumns} pagination={false} />
            </div>
          )}
        </div>
      </div>

      <div className="pddc_div_two">
        <div
          className="heading_details_container_tab_operation"
        >
          <div className={`heading_text ${collapsed1 ? "no-border" : ""}`}>
            <div>Deferred</div>
            <div>
              <div
                onClick={toggleCollapse4}
              >
                {collapsed4 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {!collapsed4 && (
            <div className="otc_pdd_table_container">
              <Table dataSource={deferred} columns={tableColumns} pagination={false} />
            </div>
          )}
        </div>
      </div>

      <div className="pddc_div_two">
        <div
          className="heading_details_container_tab_operation"
        >
          <div className={`heading_text ${collapsed1 ? "no-border" : ""}`}>
            <div>Pre Disbursals</div>
            <div>
              <div
                onClick={toggleCollapse5}
              >
                {collapsed5 ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
          </div>
          {!collapsed5 && (
            <div className="otc_pdd_table_container">
              <Table
                dataSource={preDisbursals}
                columns={tableColumns}
                pagination={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OTCPDD;
