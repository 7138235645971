import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPersonalSalesDetails,
  getAllCastService,
  getApplicantListByIdService, 
  getPersonalSalesDetails,
  listCustomersalesType,
  listEducation,
  listFamilyType,
  listGenderType,
  listMaritalStatusType,
  listNationality,
  listRelation,
  listReligion,
  updatePersonalDetailsByIdService,
} from "../../loanApplication/customerTabDetails/personalDetails/services/personalDetailsServices";
import dayjs from "dayjs";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import {
  listAddressType,
  listBusinessContructionType,
} from "../services/salesformservices";
import { filterData } from "../helperFunction/filterData";
import {
  changeCustomerId,
  changeStepperActiveKey,
  setCompanyId,
  setCustomerClass,
} from "../../../redux/salesFormSlice";
const { Option } = Select;
const CustomerPersonalDetail = ({ 
  loanId,
  activeCustomer,
  customers,
  yupSync,
}) => {
  const [form] = Form.useForm();
  const [castCategories, setCastCategories] = useState([]);
  const [genderType, setGenderType] = useState([]);
  const [maritalStatusType, setMaritalStatusType] = useState([]);
  const [religionType, setReligionType] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [relation, setRelation] = useState([]);
  const [education, setEducation] = useState([]);
  const [familyType, setFamilyType] = useState([]);
  const [isSpouseNameDisabled, setIsSpouseNameDisabled] = useState(false);
  const [applicanttype, setApplicantType] = useState("");
  const [customerType, setCustomerType] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerTypeId, setCustomerTypeId] = useState();
  const [businessConstruction, setBusinessConstruction] = useState([]);
  const [, setLoading] = useState(false);
  const [alladdress, setAddressType] = useState([]);
  const [, setEditSales] = useState();
  const dispatch = useDispatch();
  const { documentcheckcustomers } = useSelector((state) => state?.salesForm);

  const uuid = useSelector((state) => state?.salesForm?.customers[activeCustomer]?.documentData); 
  
  const customer = customers[activeCustomer];
  // const onNext = () => {};
  const getSalesPersonalData = async () => {
    if (customer?.id) {
      try {
        const response = await getPersonalSalesDetails(customer?.id);
        let {
          first_name,
          middle_name,
          last_name,
          gender,
          marital_status,
          caste_category,
          place_of_birth,
          nationality,
          religion,
          university,
          father_name,
          email,
          name,
          phone_number,
          alt_mobile_number,
          business_construction_type,
          spoc_location,
          mobile_number,
          spouse_name,
          relation_with_applicant,
          office_address_type,
          family_type,
          education,
          customer_type,
          date_of_birth,
          incorporation_date,
          tin,
          cin,
        } = response?.data || {};
        // date_of_birth = dayjs(date_of_birth, "DD-MM-YYYY");
        if (response.data.date_of_birth || response?.data?.incorporation_date) {
          date_of_birth = dayjs(date_of_birth, "DD-MM-YYYY");
          incorporation_date = dayjs(incorporation_date, "DD-MM-YYYY");
        }
        setEditSales(response.data.id);
        if (
          marital_status === "Single" ||
          marital_status === "Widow" ||
          marital_status === "Divorced"
        ) {
          setIsSpouseNameDisabled(true);
        }

        setApplicantType(customer_type?.name); // Added optional chaining 
        form?.setFieldsValue({
          first_name,
          middle_name,
          last_name,
          gender,
          marital_status,
          caste_category: caste_category?.id,
          place_of_birth,
          nationality,
          religion,
          university,
          father_name,
          email,
          name,
          phone_number,
          alt_mobile_number,
          mobile_number,
          spouse_name,
          office_address_type,
          business_construction_type,
          spoc_location,
          incorporation_date,
          relation_with_applicant: relation_with_applicant?.id,
          family_type,
          education,
          customer_type: customer_type?.name,
          date_of_birth,
          cin,
          tin,
        });
      } catch (error) {
        console.error("Error fetching personal data:", error);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  useEffect(() => {
    if (customer?.documentData?.documentName !== "Business pan card") {
      const name = customer?.documentData?.name ?? "";
      // Split the name into parts
      const nameParts = name?.split(" ");
      // Assign first_name, middle_name, and last_name based on the parts
      const first_name = nameParts[0];
      const middle_name = nameParts.length > 2 ? nameParts[1] : "";
      const last_name =
        nameParts.length > 2 ? nameParts[2] : nameParts[1] || "";

      form.setFields([
        {
          name: "first_name",
          value: first_name ?? "",
        },
        {
          name: "middle_name",
          value: middle_name ?? "",
        },
        {
          name: "last_name",
          value: last_name ?? "",
        },
        {
          name: "date_of_birth",
          value: customer?.documentData?.date_of_birth
            ? dayjs(customer.documentData.date_of_birth, "DD-MM-YYYY")
            : null,
        },
        {
          name: "gender",
          value: customer?.documentData?.gender ?? null,
        },
      ]);
    } else {
      form.setFields([
        {
          name: "name",
          value: customer?.documentData?.name ?? "",
        },
        {
          name: "incorporation_date",
          value: customer?.documentData?.date_of_birth
            ? dayjs(customer.documentData.date_of_birth, "DD-MM-YYYY")
            : null,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customer?.id) {
      getSalesPersonalData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id]);

  const handleSubmit = async (values) => {
    setLoading(true);
    // Format date_of_birth

    const getCustomerId = customerType.find(
      (item) => item.name === values?.customer_type
    );
    if (getCustomerId?.id < 4 || values?.customer_type < 4) {
      values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
    } else {
      values.incorporation_date =
        values?.incorporation_date?.format("YYYY-MM-DD");
    }
    if (values.phone_number) {
      values.phone_country_code = "91";
    }
    if (values.alt_mobile_number) {
      values.alt_mobile_country_code = "91";
    }
    if (values.mobile_number) {
      values.mobile_country_code = "91";
    }
    // Remove customer_type if it's not a number
    if (isNaN(values.customer_type)) {
      delete values.customer_type;
    }

    let customer_uuid = uuid;

    let payload = {
      ...values,
      middle_name: values.middle_name ? values.middle_name : "",
      loan_detail: loanId,
      mobile_country_code: "91",
      customer_type: customerTypeId,
      customer_class: customerTypeId > 4 ? "Company" : "Individual",
      ocr_customer_uuid: uuid?.customer_uuid,
      request_file: uuid?.request_file,
      // request_file: documentPath[index],
      // ocr_customer_uuid: customerresponseData[index]?.customer_uuid,
    };
    try {
      if (customer?.id) {
        const response = await updatePersonalDetailsByIdService(
          customer?.id,
          values,
          customer_uuid
        );
        if (response && response?.data) {
          if (response?.status === 200 && response?.success) {
            message.success("Personal details updated successfully");
            // if (onNext) {
            //   onNext();
            //   // dispatch(addState({ index: index, value: response.data.id }));
            // }
          }
        } else {
          message.error("Failed to update personal details");
        }
      } else if (true) {
        // Changed to 'else if'
        const response = await createPersonalSalesDetails(payload);

        if (response && response?.data) {
          if (response?.status === 200 && response?.success) {
            message.success("Personal details updated successfully");
            dispatch(
              changeStepperActiveKey({
                index: parseInt(activeCustomer),
                value: 2,
              })
            );
            dispatch(
              changeCustomerId({
                index: parseInt(activeCustomer),
                value: response?.data?.id,
              })
            );
            dispatch(setCustomerClass({
              index: parseInt(activeCustomer),
              value: response?.data?.customer_class,
            }))
            dispatch(setCompanyId({
              index: parseInt(activeCustomer),
              value: response?.data?.company_detail,
            }))
            
            // dispatch(addState({ index: index, value: response.data.id }));

            // dispatch(
            //   setCustomerdocumentData({
            //     index: activeCustomer,
            //     value: ocrData,
            //   })
            // );
          }
        } else {
          message.error("Failed to update personal details");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const getAllBusinessConstruction = async () => {
    try {
      const response = await listBusinessContructionType();
      setBusinessConstruction(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllAddress = async () => {
    try {
      const response = await listAddressType();
      setAddressType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    // if (open) {
    // getPersonalData();
    getAllCastList();
    getAllGenderType();
    getAllMaritalStatusType();
    getAllReligionType();
    getAllNationality();
    getAllRelation();
    getAllEducation();
    getAllFamilyType();
    getCustomerType();
    getAllAddress();
    getAllBusinessConstruction();
    if (loanId) {
      getApplicantList();
    }

    // } else {
    //   form?.resetFields();
    // }

    const currentMaritalStatus = form?.getFieldValue("marital_status");

    if (
      currentMaritalStatus === "Single" ||
      currentMaritalStatus === "Widow" ||
      currentMaritalStatus === "Divorced"
    ) {
      setIsSpouseNameDisabled(true);
    } else {
      setIsSpouseNameDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId, form]);

  const getAllCastList = async () => {
    try {
      const response = await getAllCastService();
      setCastCategories(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getAllNationality = async () => {
    try {
      const response = await listNationality();
      setNationality(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllGenderType = async () => {
    try {
      const response = await listGenderType();
      setGenderType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllMaritalStatusType = async () => {
    try {
      const response = await listMaritalStatusType();
      setMaritalStatusType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllReligionType = async () => {
    try {
      const response = await listReligion();
      setReligionType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllRelation = async () => {
    try {
      const response = await listRelation();
      setRelation(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllEducation = async () => {
    try {
      const response = await listEducation();
      setEducation(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getAllFamilyType = async () => {
    try {
      const response = await listFamilyType();
      setFamilyType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getCustomerType = async () => {
    try {
      const response = await listCustomersalesType();
      const filteredData = filterData(customer, response?.data);
      setCustomerType(filteredData);
      return;
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getApplicantList = async () => {
    try {
      const response = await getApplicantListByIdService(loanId);
      setCustomerList(response.data.customer_list);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleCustomerTypeChange = (value) => {
    setCustomerTypeId(value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > dayjs().endOf("day");
  };

  const handleInput = (e, name) => {
    let value = e.target.value.replace(/\D/g, "");
    value = value.slice(0, 15);
    form.setFieldsValue({ [name]: value });
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        first_name: documentcheckcustomers?.name,
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        gender: null,
        marital_status: null,
        caste_category: null,
        place_of_birth: "",
        nationality: null,
        religion: null,
        university: "",
        father_name: "",
        spouse_name: "",
        email: "",
        phone_number: "",
        // phone_country_code: "",
        mobile_number: "",
        // alt_mobile_number: "",

      }}
    >
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Form.Item name="customer_type" label="Customer Type" required>
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              onChange={handleCustomerTypeChange}
              disabled={customer?.id}
            >
              {customerType
                .filter((type) => type.name !== applicanttype) // Filter out the "Co-Applicant" option
                .map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        {applicanttype === "Applicant" && typeof customer === "number" && (
          <Col span={8}>
            <Form.Item
              name="new_applicant_id"
              label="Select New Applicant"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                showSearch
                allowClear
                onChange={handleCustomerTypeChange}
              >
                {customerList
                  // Filter out the "Co-Applicant" option
                  .map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {applicanttype !== "Applicant" && customer === 1 && (
          <Col span={8}>
            <Form.Item
              name="new_customer_type"
              label="Customer Type Of Applicant"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select Customer Type of applicant"
                showSearch
                allowClear
              >
                {customerType
                  .filter((type) => type.name !== "Applicant") // Filter out the "Co-Applicant" option
                  .map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      {customer?.customer_class !== "Company" && customer?.documentData?.documentName !== "Business pan card"  ? (
        <div>
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter First Name"
                  // onChange={(e) => handleInput(e, "first_name")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="middle_name"
                label="Middle Name"
                // rules={[yupSync]}
              >
                <Input
                  placeholder="Please Enter Middle Name"
                  // onChange={(e) => handleInput(e, "middle_name")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter Last Name"
                  // onChange={(e) => handleInput(e, "last_name")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="date_of_birth"
                label="Date Of Birth"
                rules={[yupSync]}
                required
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Date"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[yupSync]}
                required
              >
                <Select placeholder="Please Select" showSearch allowClear>
                  {genderType.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="marital_status"
                label="Marital Status"
                rules={[yupSync]}
                required
              >
                <Select
                  placeholder="Please Select"
                  showSearch
                  allowClear
                  onChange={(value) => {
                    form.setFieldsValue({ marital_status: value });
                    if (value !== "Married") {
                      form.setFieldsValue({ spouse_name: "" });
                      setIsSpouseNameDisabled(true);
                    } else {
                      setIsSpouseNameDisabled(false);
                    }
                  }}
                >
                  {maritalStatusType.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="caste_category"
                label="Caste Category"
                rules={[yupSync]}
                required
              >
                <Select
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  // onSearch={onSearch}
                  filterOption={filterOption}
                >
                  {castCategories?.map((category, index) => (
                    <Option key={category?.id} value={category?.id}>
                      {category?.display_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="place_of_birth"
                label="Place Of Birth"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter Place Of Birth"
                  // onChange={(e) => handleInput(e, "place_of_birth")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="nationality"
                label="Nationality"
                rules={[yupSync]}
                required
              >
                <Select placeholder="Please Select" showSearch allowClear>
                  {nationality.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="religion"
                label="Religion"
                rules={[yupSync]}
                required
              >
                <Select placeholder="Please Select" showSearch allowClear>
                  {religionType.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="university"
                label="University"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter University"
                  // onChange={(e) => handleInput(e, "university")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="father_name"
                label="Father's Name"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter Father's Name"
                  // onChange={(e) => handleInput(e, "father_name")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="spouse_name"
                label="Spouse's Name"
                rules={!isSpouseNameDisabled ? [yupSync] : false}
                required={!isSpouseNameDisabled ? true : false}
              >
                <Input
                  placeholder="Please Enter Spouse's Name"
                  // onChange={(e) => handleInput(e, "spouse_name")}
                  disabled={isSpouseNameDisabled}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="email" label="Email" rules={[yupSync]} required>
                <Input
                  placeholder="Please Enter Email"
                  // onChange={(e) => handleInput(e, "email")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="relation_with_applicant"
                label="Relation With Applicant"
                required
                rules={[yupSync]}
              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {relation?.map((relation) => {
                    return (
                      <Select.Option key={relation.id} value={relation.id}>
                        {relation.display_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="education"
                label="Education"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {education?.map((type) => {
                    return (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="family_type"
                label="Family Type"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {familyType?.map((type) => {
                    return (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={<span>Phone Number</span>}>
                <Input.Group compact>
                  <Form.Item
                    name="phone_country_code"
                    rules={[yupSync]}
                    noStyle
                  >
                    <Input
                      style={{
                        width: "20%",
                        textAlign: "center",
                        background: "#f1f1f1",
                      }}
                      defaultValue="91"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item name="phone_number" rules={[yupSync]} noStyle>
                    <Input
                      style={{ width: "80%" }}
                      placeholder="Please Enter Phone Number"
                      onChange={(e) => handleInput(e, "phone_number")}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<span>Mobile Number</span>} required>
                <Input.Group compact>
                  <Form.Item
                    name="mobile_country_code"
                    noStyle
                    rules={[yupSync]}
                  >
                    <Input
                      style={{
                        width: "20%",
                        textAlign: "center",
                        background: "#f1f1f1",
                      }}
                      defaultValue="91"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item name="mobile_number" rules={[yupSync]} noStyle>
                    <Input
                      style={{ width: "80%" }}
                      placeholder="Please Enter Mobile Number"
                      onChange={(e) => handleNumberFields(e, "mobile_number")}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={<span>Alt Mobile Number</span>}>
                <Input.Group compact>
                  <Form.Item
                    name="alt_mobile_country_code"
                    rules={[yupSync]}
                    noStyle
                  >
                    <Input
                      style={{
                        width: "20%",
                        textAlign: "center",
                        background: "#f1f1f1",
                      }}
                      defaultValue="91"
                      disabled
                    />
                  </Form.Item>

                  <Form.Item
                    name="alt_mobile_number"
                    noStyle
                    rules={[
                      {
                        pattern: /^[0-9]{10}$/,
                        message:
                          "Alternate Mobile number must be exact 10 digits",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "80%" }}
                      placeholder="Please Enter Alt Mobile Number"
                      maxLength={10} // Limit input to 10 characters
                      onChange={(e) => handleInput(e, "alt_mobile_number")}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="name"
                label="Company Name"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter Company Name"
                  // onChange={(e) => handleInput(e, "name")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="office_address_type"
                label="Office Address Type"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {alladdress?.map((add) => {
                    return (
                      <Select.Option key={add} value={add}>
                        {add}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="business_construction_type"
                label="Business Construction Type"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please Select"
                  required={true}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {businessConstruction?.map((item) => {
                    return (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item
                name="spoc_location"
                label="SPOC Location"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please Enter SPOC Location"
                  // onChange={(e) => handleInput(e, "spoc_location")}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="incorporation_date"
                label="Incorporation Date"
                rules={[yupSync]}
                required
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Incorporation Date"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={<span>Mobile Number</span>} required>
                <Input.Group compact>
                  <Form.Item
                    name="mobile_country_code"
                    noStyle
                    rules={[yupSync]}
                  >
                    <Input
                      style={{
                        width: "20%",
                        textAlign: "center",
                        background: "#f1f1f1",
                      }}
                      defaultValue="91"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item name="mobile_number" rules={[yupSync]} noStyle>
                    <Input
                      style={{ width: "80%" }}
                      placeholder="Please Enter Mobile Number"
                      onChange={(e) => handleNumberFields(e, "mobile_number")}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <Form.Item name="cin" label="CIN">
                <Input placeholder="Please Enter CIN " />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="tin" label="TIN">
                <Input placeholder="Please Enter TIN" />
              </Form.Item>
            </Col>
          </Row>

          {/* <Form.Item>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
        <Button type="default" onClick={closeForm}>
          Cancel
        </Button>
      </Space>
    </Form.Item> */}
        </div>
      )}
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Space>
    </Form>
  );
};

export default CustomerPersonalDetail;
