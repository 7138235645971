import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import "../styles/fiInitiationForm.css";
import "../../commonCSS/initiationFormCommonForm.css";
import { Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {
  createFIInitiationDataService,
  getAllAgentService,
  getAllAgencyService,
  getAddressDataByIdService,
  // getPropertyDetailsById,
  getLoanDetailsById,
  getCustomerNameByIdService,
} from "../Services/fiInitiationService";
import { useParams } from "react-router-dom";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import ConditionalRender from "../../../../../../utils/authorization/AuthorizeComponent";

const validationSchema = Yup.object().shape({
  agency: Yup.string()
    .required("Vendor is required")
    .min(1, "Vendor is required"),
  agent: Yup.string()
    .required("Agent name is required")
    .min(1, "Agent name is required"),
  agency_type: Yup.string()
    .required("Agency type is required")
    .min(1, "Agency type is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const FIInitiationForm = ({ closeForm, open, agencyCode ,setSubmitStageBar}) => {
  const [form] = Form.useForm();
  const { encrypted_loan_id } = useParams();
  const [value, setValue] = useState("");
  const [allAgencies, setAllAgencies] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [allAddress, setAllAddress] = useState([]);
  const [selectedRowKeysMap, setSelectedRowKeysMap] = useState({});
  const [loanDetails, setLoanDetails] = useState([]);
  const [applicantType, setApplicantType] = useState("");
  const [fileList, setFileList] = useState([]);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [isAgencySelected, setIsAgencySelected] = useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState("");
  const [customerName, setCustomerName] = useState("");

  const [loading, setLoading] = useState(false);

  const generateMailSubject = () => {
    const locationName = loanDetails?.location?.display_name || "";
    const loanAccountNumber = loanDetails?.loan_account_number || "";
    return `FI initiate  - ${loanAccountNumber} - ${customerName && `${customerName} (${applicantType})`} - ${locationName}`;
  };

  const { user_data } = useSelector((state) => state.user);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const getAllAgencyTypes = async () => {
      try {
        const response = await getAllAgencyService(agencyCode);
        setAllAgencies(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllAddress = async () => {
      try {
        const response = await getAddressDataByIdService(loan_id);
        setAllAddress(response.data.results);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllLoanDetails = async () => {
      try {
        const response = await getLoanDetailsById(loan_id);
        setLoanDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllCustomers = async () => {
      try {
        const response = await getCustomerNameByIdService(loan_id);
        const customers = response.data.customers;
        const applicant = customers.find(
          (customer) =>
            customer.customer_type.display_name === "Applicant" ||
            "Company Applicant"
        );

        if (applicant) {
          setCustomerName(applicant.name);
          setApplicantType(applicant.customer_type.display_name);
        }
        if (user_data?.email) {
          form.setFieldsValue({ mail_cc: user_data.email });
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    if (open) {
      getAllAgencyTypes();
      getAllAddress();
      // getAllPropertyDetails();
      getAllLoanDetails();
      getAllCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, loan_id, user_data, open]);

  const handleAgencyChange = (value) => {
    if (!value) {
      setAllAgents([]);
      setSelectedAgencyId("");
      setIsAgencySelected(false);
      form.setFieldsValue({ agent: undefined });
      form.setFieldsValue({ mail_to: undefined });
    } else {
      const selectedAgency = allAgencies.find(
        (agency) => agency.code === value
      );
      setSelectedAgencyId(selectedAgency ? selectedAgency.id : "");
      setIsAgencySelected(!!value);
      form.setFieldsValue({ agent: undefined });
      if (value) {
        getAllAgents(value);
        // form.setFieldsValue({ agent: null })
      }
    }
  };

  const getAllAgents = async (agencyCode) => {
    try {
      const response = await getAllAgentService(agencyCode, loan_id);
      setAllAgents(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleAgentChange = (agentId) => {
    if (!agentId) {
      form.setFieldsValue({ mail_to: undefined });
    } else {
      const selectedAgent = allAgents.find((agent) => agent.id === agentId);

      // Update the mail_cc field with the selected agent's email
      if (selectedAgent) {
        form.setFieldsValue({
          mail_to: selectedAgent.email,
        });
      }
    }
  };

  const handleUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const columns = [
    {
      title: "Address Type",
      dataIndex: "Address_Type",
    },
    {
      title: "Complete Address",
      dataIndex: "Complete_Address",
      render: (text) => (text.includes("undefined") ? "" : text),
    },
  ];

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatData = (customerAddress) => {
    const formattedData = [];
    for (const [addressType, addressDetails] of Object.entries(
      customerAddress
    )) {
      const addresses = Array.isArray(addressDetails)
        ? addressDetails
        : [addressDetails];
      addresses.forEach((details) => {
        if (details) {
          formattedData.push({
            key: details.id,
            Address_Type: capitalizeWords(addressType.replace("_", " ")),
            Complete_Address: details
              ? `${details.address_line_1}, ${details.address_line_2}, ${details.landmark},${details.city?.display_name}, ${details.state?.display_name} - ${details.pincode?.display_name}`
              : "",
          });
        }
      });
    }
    const filteredData = formattedData.filter((row) =>
      Object.values(row).every((value) => value !== undefined && value !== "")
    );
    return filteredData;
  };

  const handleRowSelectionChange = (customerId, selectedRowKeys) => {
    const updatedSelectedRowKeysMap = {
      ...selectedRowKeysMap,
      [customerId]: selectedRowKeys,
    };

    setSelectedRowKeysMap(updatedSelectedRowKeysMap);

    const selectedAddressesList = Object.entries(
      updatedSelectedRowKeysMap
    ).flatMap(([id, keys]) => {
      const customer = allAddress.find((c) => c.customer_id === parseInt(id));
      if (customer && customer.customer_address) {
        const addresses = [
          customer.customer_address.current_address,
          customer.customer_address.permanent_address,
          ...customer.customer_address.office_address,
        ];

        return keys.map((key) => {
          const address = addresses.find((a) => a.id === key);
          if (address) {
            const addressType = address.address_type?.display_name;
            const customerType = customer?.customer_type;
            const customerName = customer?.customer_name;
            const mobileNumber = customer?.customer_mobile_number;
            return (
              <>
                <ul>
                  <li>
                    <b>
                      {addressType}: ( {customerName} - {customerType} -{" "}
                      {mobileNumber})
                    </b>{" "}
                    <br></br>
                    {address.address_line_1}, {address.address_line_2},{" "}
                    {address.landmark}, {address.city?.display_name},{" "}
                    {address.state?.display_name} -{" "}
                    {address.pincode?.display_name}
                  </li>
                </ul>
              </>
            );
            // return `{addressType}: (${customerType} - ${customerName} - ${mobileNumber}) ${address.address_line_1}, ${address.address_line_2}, ${address.landmark}, ${address.city.display_name}, ${address.state.display_name} - ${address.pincode.display_name}`;
          }
          return "";
        });
      }
      // }
      return [];
    });

    setSelectedAddresses(selectedAddressesList);
  };

  const validateSelectedAddresses = () => {
    const hasSelectedAddresses = Object.values(selectedRowKeysMap).some(
      (selectedKeys) => selectedKeys.length > 0
    );
    return hasSelectedAddresses;
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (!validateSelectedAddresses()) {
        message.error("Please select at least one address type.");
        return;
      }

      try {
        const valuesWithoutAgency = { ...values };
        delete valuesWithoutAgency.agency;
        delete valuesWithoutAgency.mail_subject;

        const formData = new FormData();

        const mailCC = valuesWithoutAgency.mail_cc;
        const addCC = valuesWithoutAgency.add_cc;
        const concatenatedCC = [mailCC, addCC].filter(Boolean).join(",");

        for (let key in valuesWithoutAgency) {
          // if (key === "remark") {
          //   formData.append(key, htmlToText(value));
          // } else
          if (key === "mail_cc") {
            formData.append(key, concatenatedCC);
          } else if (key !== "add_cc") {
            // Exclude add_cc
            formData.append(key, valuesWithoutAgency[key]);
          }
        }

        fileList.forEach((file) => {
          formData.append("attachment", file.originFileObj);
        });

        const attachments = form.getFieldValue("attachment");
        if (attachments) {
          attachments.fileList.forEach((file) => {
            formData.append("attachment", file.attachment);
          });
        }

        const selectedAddresses = Object.values(selectedRowKeysMap).flat();
        formData.append(
          "field_investigation_address",
          JSON.stringify(selectedAddresses)
        );

        formData.append("loan_detail", loan_id);
        formData.append("stage_initiate", "Fi");

        const selectedAddressesHTML = selectedAddresses
          .map((key) => {
            const customer = allAddress.find(
              (c) =>
                c.customer_address.current_address.id === key ||
                c.customer_address.permanent_address.id === key ||
                c.customer_address.office_address.some((a) => a.id === key)
            );
            if (customer && customer.customer_address) {
              const addresses = [
                customer.customer_address.current_address,
                customer.customer_address.permanent_address,
                ...customer.customer_address.office_address,
              ];

              const address = addresses.find((a) => a.id === key);
              if (address) {
                const addressType = address.address_type?.display_name;
                const customerType = customer.customer_type;
                const customerName = customer.customer_name;
                const mobileNumber = customer.mobile || "N/A"; // Replace with actual mobile number if available
                return `<li><b>${addressType}: ( ${customerName} - ${customerType} - ${mobileNumber})</b> <br>${address.address_line_1}, ${address.address_line_2}, ${address.landmark}, ${address.city?.display_name}, ${address.state?.display_name} - ${address.pincode?.display_name}</li>`;
              }
            }
            return "";
          })
          .join("");

        const emailBodyContent = `
        Dear Team,<br>
        Please initiate Field Investigation for ${customerName ? `${customerName} (${applicantType})` : ""
          }<br>
        <div style=" margin-top: 10px;border: 1px solid #b8b3b373; border-radius: 8px;">
          <div style="  border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
            <div style=" width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;text-align: left;color: #000000B2;margin-left: 8px;
  border-right: 1px solid #D9D9D9;">Customer Name</div>
            <div style="  padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left; color: #000000B2;margin-left: 8px;">${customerName && `${customerName} (${applicantType})`
          }</div>
          </div>
          <div style="border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
            <div style="  width: 35%;font-family: Roboto;font-size: 14px;
  font-weight: 400; padding: 10px;text-align: left; color: #000000B2;margin-left: 8px; border-right: 1px solid #D9D9D9;">LAN</div>
            <div style="padding: 10px;width: 65%; font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2; margin-left: 8px;">${loanDetails?.loan_account_number || "-"
          }</div>
          </div>
          <div style="border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
            <div style=" width: 35%;font-family: Roboto;
  font-size: 14px;font-weight: 400;padding: 10px; text-align: left;
  color: #000000B2;margin-left: 8px; border-right: 1px solid #D9D9D9;">Product</div>
            <div style="  padding: 10px;
  width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;
">${loanDetails?.loan_type?.display_name || "-"
          }</div>
          </div>
          <div style="  border-bottom: 1px solid #b8b3b373; display: flex;flex-direction: row;">
            <div style=" width: 35%;
  font-family: Roboto;font-size: 14px;font-weight: 400;padding: 10px;
  text-align: left;color: #000000B2;margin-left: 8px;border-right: 1px solid #D9D9D9;">Branch</div>
            <div style="  padding: 10px;width: 65%;font-family: Roboto;font-size: 14px; font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;
  margin-left: 8px;">${loanDetails?.location?.display_name || "-"
          }</div>
          </div>
          <div style="border-bottom: 1px solid #b8b3b373;display: flex;flex-direction: row;">
            <div style=" width: 35%;font-family: Roboto;font-size: 14px;font-weight: 400; padding: 10px;text-align: left;color: #000000B2;margin-left: 8px;border-right: 1px solid #D9D9D9;">Addresses</div>
            <div style="  padding: 10px;width: 65%;font-family: Roboto;font-size: 14px;font-weight: 500;line-height: 22px;text-align: left;color: #000000B2;margin-left: 8px;">
              <ul>${selectedAddressesHTML}</ul>
            </div>
          </div>
        </div>
      `;

        formData.append("mail_body", emailBodyContent);
        formData.append("stage", "MSFFFI9");
        formData.append("stage_status", "MSSTIDA4");
        formData.append("agency", selectedAgencyId);
        formData.append("mail_subject", generateMailSubject());

        const response = await createFIInitiationDataService(formData);
        if (response.status === 200 && response.success) {
          message.success("FI initiation created successfully");
          setSubmitStageBar();
          form.resetFields();
          setFileList([]);
          setValue(null);
          setSelectedRowKeysMap({});
          setIsAgencySelected(false);
          closeForm();
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
        // closeForm()
        // setLoading(false)
      }
    } catch (error) {
      console.error("Error occurred while submitting:", error);
      message.error("An error occurred while processing the request");
    } finally {
      setLoading(false); // Stop loading after the process completes
    }

  
  };

  useEffect(() => {
    form.resetFields();
    setIsAgencySelected(false);
    setSelectedRowKeysMap({});
    setFileList([]);
    setSelectedAddresses([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeForm]);

  return (
    <div>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="agency"
              label="Vendor"
              rules={[yupSync]}
              required
            // rules={[{ required: true, message: "Please select a vendor" }]}
            >
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                className="select_field"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleAgencyChange}
              >
                {allAgencies?.map((agency_type) => (
                  <Select.Option
                    key={agency_type.code}
                    value={agency_type.code}
                  >
                    {agency_type?.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="agent"
              label="Agent Name"
              rules={[yupSync]}
              required
            >
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                className="select_field"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleAgentChange}
                disabled={!isAgencySelected}
              >
                {allAgents?.map((agents) => (
                  <Select.Option key={agents.id} value={agents.id}>
                    {agents.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {allAddress.map((customer, index) => (
          <div className="email_div_container" key={index}>
            <div className="loan_fiinition">
              <div className="loan_fiinition_heading">
                {`${customer?.customer_type} Name: ${customer?.customer_name}`}
              </div>
              <div className="loan_fiinition_heading">
                {`Mobile Number: ${customer?.customer_mobile_number}`}
              </div>
            </div>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please at least one address type selected",
                },
              ]}
            >
              <div className="scrollable-table-container">
                <Table
                  className="no-radius-table scrollable-table"
                  rowSelection={{
                    type: "checkbox",
                    onChange: (selectedRowKeys, selectedRows) =>
                      handleRowSelectionChange(
                        customer.customer_id,
                        selectedRowKeys,
                        selectedRows
                      ),
                    selectedRowKeys:
                      selectedRowKeysMap[customer.customer_id] || [],
                  }}
                  columns={columns}
                  dataSource={formatData(customer.customer_address)}
                  pagination={false}
                />
              </div>
            </Form.Item>
          </div>
        ))}

        <div className="email_div_container">
          <div>
            <div className="content_block">
              <span className="email_heading">
                <span className="all_required_sign"> *</span>To :
              </span>
              <span className="email_content">
                <Form.Item
                  name="mail_to"
                  rules={[
                    { required: true, message: "Please enter the email" },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </span>
            </div>
            <div className="content_block">
              <span className="email_heading">
                {" "}
                <span className="all_required_sign"> *</span>CC :
              </span>
              <span className="email_content">
                <Form.Item
                  name="mail_cc"
                  rules={[{ required: true, message: "Please enter the cc" }]}
                >
                  <Input disabled />
                </Form.Item>
              </span>
            </div>
            <div className="content_block">
              <span className="email_heading">Add CC :</span>
              <span className="email_content">
                <Form.Item name="add_cc">
                  <Input />
                </Form.Item>
              </span>
            </div>
            <div className="content_block">
              <span className="email_heading">
                {" "}
                <span className="all_required_sign"> *</span>Subject :
              </span>
              <span className="email_content">
                <Form.Item name="mail_subject">
                  {generateMailSubject()}
                </Form.Item>
              </span>
            </div>
            <div className="content_block">
              <span className="email_heading">Message :</span>
              <span className="email_content">
                Dear Team,<br></br>
                Please initiate Field Investigation for {customerName && `${customerName} (${applicantType})`}
                <br></br>
                <div className="initiation_form_table_one ">
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Customer Name
                    </div>
                    <div className="initiation_form_table_data_value">
                      {customerName && `${customerName} (${applicantType})`}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      LAN No
                    </div>
                    <div className="initiation_form_table_data_value">
                      {loanDetails?.loan_account_number || "-"}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Product
                    </div>
                    <div className="initiation_form_table_data_value">
                      {loanDetails?.loan_type?.display_name || "-"}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Branch
                    </div>
                    <div className="initiation_form_table_data_value">
                      {loanDetails?.location?.display_name || "-"}
                    </div>
                  </div>
                  <div className="initiation_form_table_data">
                    <div className="initiation_form_table_data_heading">
                      Addresses
                    </div>
                    <div className="initiation_form_table_data_value">
                      {selectedAddresses.length > 0
                        ? selectedAddresses.map((address, index) => (
                          <div key={index}>{address}</div>
                        ))
                        : "No address selected"}
                    </div>
                  </div>
                </div>
              </span>
            </div>

            <div className="content_block">
              <span className="email_heading">
                <span className="all_required_sign"> *</span>Remarks:
                <br />
                (If attached, please mention the list of documents)
              </span>
              <span className="email_content">
                <Form.Item
                  name="remark"
                  rules={[
                    {
                      required: true,
                      message: "Please enter remarks",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Please Enter Remark"
                    value={value}
                    onChange={setValue}
                  />
                </Form.Item>
              </span>
            </div>

            <div className="content_block">
              <span className="email_heading">Attachments :</span>
              <span className="email_content">
                <Form.Item>
                  <Upload
                    multiple
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={fileList}
                    onChange={handleUpload}
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                    }}
                    beforeUpload={() => false}
                  >
                    <button type="button" className="upload_button">
                      <PlusOutlined />
                      <div>Upload</div>
                    </button>
                  </Upload>
                </Form.Item>
              </span>
            </div>
          </div>
        </div>
        <Space
          direction="horizontal"
          align="center"
          className="fi_button_inition"
        >
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["FINTIATE"]}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Initiate
            </Button>
          </ConditionalRender>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
      <br></br>
      <div></div>
    </div>
  );
};

export default FIInitiationForm;
