import {axiosRequest} from "../../../../utils/api/axiosRequest"


export const getPartDisbursalDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/part-disbursment/?loan_detail=${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getApprovalDetailsByIdService = (tabDisbursementId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/part-disbursment/approval-status/${tabDisbursementId}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}