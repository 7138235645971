export function cleanText(inputString) {
  // Replace @[name](number) with @number
  let cleanedString = inputString.replace(/@\[[^\]]+\]\((\d+)\)/g, '@$1');
  
  // Trim extra spaces
  return cleanedString.trim();
}


export function extractNumber(inputString) {
  if (inputString && typeof inputString === 'string') {
    // Match a number after @ and capture it
    const match = inputString.match(/@(\d+)/);
    // Return the captured number or null if not found
    return match ? match[1] : null;
  }
  return null; // Return null if inputString is null, undefined, or not a string
}
