import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Space, message } from "antd";
import * as Yup from "yup";
import { getPropertyAndCustomerDetailByLoanId, getPropertyDataIdService, postPropertyData, updatePropertyDataIdService } from "../services/SecurityInterestPendingList";
import "../styles/PropertyForm.css"

const validationSchema = Yup.object().shape({
  project_name: Yup.string().required("Project Name is required"),
  street_number: Yup.string().required("Street No is required"),
  building_number: Yup.string().required("Building No is required"),
  carpet_build_up_area: Yup.string().required("Carpet Build Up Area is required"),
  house_number: Yup.string()
  .required("House No is required")
  .matches(
    /^[A-Za-z0-9\s/-]+$/,
    "House Number can only contain alphanumeric characters, slashes, spaces, and hyphens"
  )
  .test('no-multiple-spaces', 'Only one space between words is allowed', 
    (value) => !value || !/\s{2,}/.test(value)),
    survey_number: Yup.string()
    .required("Survey No is required")
    .matches(
      /^[A-Za-z0-9\s/\-.]+$/,
      "Survey Number can only contain alphanumeric characters, slashes, hyphens, spaces, and periods"
    )
    .test('no-multiple-spaces', 'Only one space between words is allowed', 
      (value) => !value || !/\s{2,}/.test(value)),
  
  plot_number: Yup.string()
    .required("Plot No is required")
    .matches(
      /^[A-Za-z0-9\s/\-.]+$/,
      "Plot Number can only contain alphanumeric characters, slashes, hyphens, spaces, and periods"
    )
    .test('no-multiple-spaces', 'Only one space between words is allowed', 
      (value) => !value || !/\s{2,}/.test(value)),
  landmark: Yup.string().required("Landmark is required"),
  state: Yup.string().required("State is required"),
  area: Yup.string().required("Area is required"),
  district: Yup.string().required("District is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]{6}$/, "Pincode must be 6 digits"),
})

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PropertyForm = ({ closeForm,loan_id,id,open,mode,refreshData}) => {
  console.log("loan_id",loan_id)
  const [propertyData, setPropertyData] = useState(null);
  const [form] = Form.useForm();

// Fetch Property and Customer Data
const fetchPropertyAndCustomerDetailByLoanId = async () => {
  try {
    const response = await getPropertyAndCustomerDetailByLoanId(loan_id);
    const propertyDetails = response.data.property_detail;
    setPropertyData(propertyDetails);
  } catch (error) {
    message.error("Failed to fetch the Property And Customer Data.");
  }
};

// Fetch specific property data by ID for edit mode
const getPropertyDataById = async () => {
  if (id) {
    try {
      const response = await getPropertyDataIdService(id);
      const propertyData = response?.data;

      form.setFieldsValue({
        project_name: propertyData.project_name,
        building_number: propertyData.building_number,
        street_number: propertyData.street_number,
        carpet_build_up_area: propertyData.carpet_build_up_area, 
        plot_number: propertyData.plot_number,
        house_number: propertyData.house_number,
        survey_number: propertyData.survey_number,
        landmark: propertyData.landmark,
        state: propertyData.state,
        area: propertyData.area,
        district: propertyData.district,
        pincode: propertyData.pincode,
      });
    } catch (error) {
      console.error("Error fetching property data:", error);
      message.error(error?.response?.data?.message || "Failed to fetch data");
    }
  }
};

// Fetch customer and property details on component mount
useEffect(() => {
  fetchPropertyAndCustomerDetailByLoanId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

// Handle form initialization based on mode
useEffect(() => {
  if (open) {
    // Reset form for add mode
    if (mode === 'add') {
      // form.resetFields();
    }
    
    // Fetch data for edit mode
    if (mode === 'edit' && id) {
      getPropertyDataById();
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [open, mode, id, form]);


  const handleSubmit = async (values) => {
    try {
      const loanId = propertyData?.loan_detail;
      const payload = {
        property_data: {
          loan_detail:propertyData?.loan_detail,
          nature_of_property:propertyData?.nature_of_property,
          plot_number: values.plot_number,
          project_name: values.project_name,
          building_number: values.building_number,
          street_number: values.street_number,
          area: values.area,
          survey_number: values.survey_number,
          house_number: values.house_number,
          carpet_build_up_area: values.carpet_build_up_area,
          locality: values.area,
          landmark:values.landmark,
          district: values.district,
          state: values.state,
          pincode: values.pincode,
          action: "Pending", 
          status: "A", 
        },
      };
  
      // Include loan_detail only in "add" mode
      if (mode === "add") {
        payload.loan_detail = loanId; 
        payload.status = "Pending"; 
      }
  
      if (mode === "add") {
        const response = await postPropertyData(payload);
        if (response.status === 200 && response.success) {
          message.success("Property data created successfully");
          refreshData();
          form.resetFields(); // Reset the form after submission
          closeForm(); // Close the form after success
        }
      } else if (mode === "edit" && id) {
        const response = await updatePropertyDataIdService(id, values);
        if (response.status === 200 && response.success) {
          message.success("Property data updated successfully");
          refreshData();
          form.resetFields(); // Reset the form after update
          closeForm(); // Close the form after success
        }
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to create/update property data"
      );
    }
  };

  useEffect(() => {
    if (propertyData && mode === 'add') {
      const initialData = {
        project_name: "",
        street_number: "",
        building_number: "",
        carpet_build_up_area: propertyData.area || "",
        plot_number: propertyData.plot_number || "",
        house_number: propertyData.house_number || "",
        survey_number: propertyData.survey_number || "",
        landmark: propertyData.landmark || "",
        state: propertyData.state || "",
        area: propertyData.city || "",
        district: propertyData.district || "",
        pincode: propertyData.pincode || "",
      };
      form.setFieldsValue(initialData);
    }
  }, [propertyData, form, mode]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row gutter={8}>
        <Col span={8}>
          <Form.Item name="project_name" label="Project Name" rules={[yupSync]} required>
            <Input placeholder="Enter Project Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="building_number" label="Building No." rules={[yupSync]} required>
            <Input placeholder="Enter Building No." />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={8}>
          <Form.Item name="street_number" label="Street No" rules={[yupSync]} required>
            <Input placeholder="Enter Street No" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="carpet_build_up_area" label="Carpet Build Up Area" rules={[yupSync]} required>
            <Input placeholder="Enter Carpet Area" type="number" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={8}>
          <Form.Item name="plot_number" label="Plot No." rules={[yupSync]} required>
            <Input placeholder="Enter Plot No" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="house_number" label="House No./Flat No." rules={[yupSync]} required>
            <Input placeholder="Enter House No. or Flat No."  />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={8}>
          <Form.Item name="survey_number" label="Survey No." rules={[yupSync]} required>
            <Input placeholder="Enter Survey No." />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="landmark" label="Landmark" rules={[yupSync]} required>
            <Input placeholder="Enter Landmark" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Input placeholder="Enter State" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="area" label="Area" rules={[yupSync]} required>
            <Input placeholder="Enter Area" disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={8}>
          <Form.Item name="district" label="District" rules={[yupSync]} required>
            <Input placeholder="Enter District" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Input placeholder="Enter Pincode" />
          </Form.Item>
        </Col>
      </Row>

      <Space direction="horizontal" className="property-detail-form-save-button">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={closeForm}>Close</Button>
      </Space>
    </Form>
  );
};

export default PropertyForm;
