import React, { useState, useEffect } from "react";
import { Table, Input, message, Form, Tooltip } from "antd";
import { Button, Space } from "antd";
import {
  getPropertyTechMarketValueByIdService,
  updateMarketAndTechnicalByIdService,
} from "../services/valuationDetailsServices";
import * as Yup from "yup";
import { isUndefined } from "lodash";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const validationSchema = Yup.object().shape({
  pas_per_plan: Yup.string().required("This field is required"),
  pas_per_site: Yup.string().required("This field is required"),
  pdeviation: Yup.string().required("This field is required"),
  prate: Yup.string().required("This field is required"),
  ppercentage_completed: Yup.string().required("This field is required"),
  ppercentage_recommended: Yup.string().required("This field is required"),
  pvaluation: Yup.string().required("This field is required"),

  // Existing Construction Area
  eas_per_plan: Yup.string().required("This field is required"),
  eas_per_site: Yup.string().required("This field is required"),
  edeviation: Yup.string().required("This field is required"),
  erate: Yup.string().required("This field is required"),
  epercentage_completed: Yup.string().required("This field is required"),
  epercentage_recommended: Yup.string().required("This field is required"),
  evaluation: Yup.string().required("This field is required"),
  // Proposed Construction Area
  pcas_per_plan: Yup.string().required("This field is required"),
  pcas_per_site: Yup.string().required("This field is required"),
  pcdeviation: Yup.string().required("This field is required"),
  pcrate: Yup.string().required("This field is required"),
  pcpercentage_completed: Yup.string().required("This field is required"),
  pcpercentage_recommended: Yup.string().required("This field is required"),
  pcvaluation: Yup.string().required("This field is required"),
  // Amenities
  aas_per_plan: Yup.string().required("This field is required"),
  aas_per_site: Yup.string().required("This field is required"),
  adeviation: Yup.string().required("This field is required"),
  arate: Yup.string().required("This field is required"),
  apercentage_completed: Yup.string().required("This field is required"),
  apercentage_recommended: Yup.string().required("This field is required"),
  avaluation: Yup.string().required("This field is required"),
  // Technical Data
  total_market_value: Yup.string().required("This field is required"),
  forced_sale_value: Yup.string().required("This field is required"),
  completed_value: Yup.string().required("This field is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ValuationPropertyDetailsForm = ({
  refreshData,
  open,
  closeForm,
  id,
  stage_id,
}) => {
  const [form] = Form.useForm();
  const [technicalid, setTechnicalid] = useState();
  const [technicaldata, setTechnicaldata] = useState();
  const [rate, setRate] = useState(0);
  const [, setPRecommended] = useState(0);
  const [pcrate, setPcrate] = useState(0);
  const [erate, setErate] = useState(0);
  const [valuation, setValuation] = useState(0);
  const [ploatAsPerPlan, setPloatAsPerPlan] = useState(0);
  const [ploatAsPerSite, setPloatAsPerSite] = useState(0);
  const [ploatDeviation, setPloatDeviation] = useState(0);
  const [eploatAsPerPlan, setEPloatAsPerPlan] = useState(0);
  const [eploatAsPerSite, setEPloatAsPerSite] = useState(0);
  const [eploatDeviation, setEPloatDeviation] = useState(0);
  const [eevaluation, setEvaluation] = useState(0);
  const [pploatAsPerPlan, setPPloatAsPerPlan] = useState(0);
  const [pploatAsPerSite, setPPloatAsPerSite] = useState(0);
  const [pploatDeviation, setPPloatDeviation] = useState(0);
  const [pploatValuation, setPPloatValuation] = useState(0);
  const [aploatAsPerSite, setAPloatAsPerSite] = useState(0);
  const [aavaluation, setAvaluation] = useState(0);
  const [erecommended, setERecommended] = useState(0);
  const [pcrecommended, setPCRecommended] = useState(0);
  const [arecommended, setARecommended] = useState(0);
  const [ffmv, setFmv] = useState(0);
  const [ttpc, setTpc] = useState(0);
  const [ffsv, setFsv] = useState(0);

  const getPersonalData = async () => {
    const response = await getPropertyTechMarketValueByIdService(stage_id);
    setTechnicaldata(response?.data?.technical_market_valuation_details);
    setTechnicalid(
      response?.data?.technical_market_valuation_details?.amenities[0]
        ?.technical_detail
    );
    setPloatDeviation(
      response?.data?.technical_market_valuation_details?.plot_area[0]
        ?.deviation ?? 0
    );
    setEPloatDeviation(
      response?.data?.technical_market_valuation_details
        ?.existing_construction_area[0]?.deviation ?? 0
    );
    setPPloatDeviation(
      response?.data?.technical_market_valuation_details
        ?.proposed_construction_area[0]?.deviation ?? 0
    );

    setAvaluation(
      response?.data?.technical_market_valuation_details?.amenities[0]
        ?.valuation
    );
    setValuation(
      response?.data?.technical_market_valuation_details?.plot_area[0]
        ?.valuation
    );
    setEvaluation(
      response?.data?.technical_market_valuation_details
        ?.existing_construction_area[0]?.percentage_completed
    );
    setPPloatValuation(
      response?.data?.technical_market_valuation_details
        ?.proposed_construction_area[0]?.valuation
    );
    setFmv(
      response?.data?.technical_market_valuation_details?.technical_data[0]
        ?.completed_value
    );
    setTpc(
      response?.data?.technical_market_valuation_details?.technical_data[0]
        ?.total_market_value
    );
    setFsv(
      response?.data?.technical_market_valuation_details?.technical_data[0]
        ?.forced_sale_value
    );

    let {
      plot_area,
      existing_construction_area,
      proposed_construction_area,
      amenities,
    } = response?.data?.technical_market_valuation_details;

    form?.setFieldsValue({
      pas_per_plan: Number(plot_area[0]?.as_per_plan),
      pas_per_site: Number(plot_area[0]?.as_per_site),
      prate: Number(plot_area[0]?.rate),
      ppercentage_completed: Number(plot_area[0]?.percentage_completed),
      ppercentage_recommended: Number(plot_area[0]?.percentage_recommended),
      eas_per_plan: Number(existing_construction_area[0]?.as_per_plan),
      eas_per_site: Number(existing_construction_area[0]?.as_per_site),
      erate: Number(existing_construction_area[0]?.rate),
      epercentage_completed: Number(
        existing_construction_area[0]?.percentage_completed
      ),
      epercentage_recommended: Number(
        existing_construction_area[0]?.percentage_recommended
      ),
      pcas_per_plan: Number(proposed_construction_area[0]?.as_per_plan),
      pcas_per_site: Number(proposed_construction_area[0]?.as_per_site),
      pcrate: Number(proposed_construction_area[0]?.rate),
      pcpercentage_completed: Number(
        proposed_construction_area[0]?.percentage_completed
      ),
      pcpercentage_recommended: Number(
        proposed_construction_area[0]?.percentage_recommended
      ),
      aas_per_plan: Number(amenities[0]?.as_per_plan),
      apercentage_completed: Number(amenities[0]?.percentage_completed),
      apercentage_recommended: Number(amenities[0]?.percentage_recommended),
    });

    setRate(Number(plot_area[0]?.rate));

    setPloatAsPerPlan(Number(plot_area[0]?.as_per_plan));

    setPloatAsPerSite(Number(plot_area[0]?.as_per_site));

    setEPloatAsPerPlan(Number(existing_construction_area[0]?.as_per_plan));

    setEPloatAsPerSite(Number(existing_construction_area[0]?.as_per_site));

    setERecommended(
      Number(existing_construction_area[0]?.percentage_recommended)
    );

    setErate(Number(existing_construction_area[0]?.rate));

    setPPloatAsPerPlan(Number(proposed_construction_area[0]?.as_per_plan));

    setPPloatAsPerSite(Number(proposed_construction_area[0]?.as_per_site));

    setPCRecommended(
      Number(proposed_construction_area[0]?.percentage_recommended)
    );

    setPcrate(Number(proposed_construction_area[0]?.rate));

    setAPloatAsPerSite(Number(amenities[0]?.as_per_plan));

    setARecommended(Number(amenities[0]?.percentage_recommended));
  };

  useEffect(() => {
    let deviation = 0;
    if (ploatAsPerPlan > ploatAsPerSite) {
      deviation = 0;
    } else if (isNaN(ploatAsPerPlan) || isNaN(ploatAsPerSite)) {
      deviation = 0;
    } else if (ploatAsPerSite === 0 || ploatAsPerPlan === 0) {
      deviation = 0;
    } else {
      deviation = (
        ((ploatAsPerSite - ploatAsPerPlan) / ploatAsPerPlan) *
        100
      ).toFixed(2);
    }

    setPloatDeviation(parseFloat(deviation));
  }, [ploatAsPerPlan, ploatAsPerSite, rate]);

  useEffect(() => {
    let valuation = (Math.min(ploatAsPerPlan, ploatAsPerSite) * rate).toFixed(
      2
    );

    if (isNaN(valuation)) {
      valuation = 0;
    }

    setValuation(valuation);
  }, [ploatAsPerPlan, ploatAsPerSite, rate]);

  useEffect(() => {
    let eploatDeviation = 0;
    if (eploatAsPerPlan > eploatAsPerSite) {
      eploatDeviation = 0;
    } else if (
      isNaN(eploatAsPerPlan) ||
      isNaN(eploatAsPerSite) ||
      isUndefined(eploatAsPerSite) ||
      isUndefined(eploatAsPerPlan) ||
      eploatAsPerSite === "" ||
      eploatAsPerPlan === ""
    ) {
      eploatDeviation = 0;
    } else if (eploatAsPerPlan === "0" || eploatAsPerSite === "0") {
      eploatDeviation = 0;
    } else {
      eploatDeviation = (
        ((eploatAsPerSite - eploatAsPerPlan) / eploatAsPerPlan) *
        100
      ).toFixed(2);
    }
    setEPloatDeviation(parseFloat(eploatDeviation));

    let evaluation = (
      ((Math.min(eploatAsPerPlan, eploatAsPerSite) * erecommended) / 100) *
      erate
    ).toFixed(2);

    if (
      evaluation === "Infinity" ||
      evaluation === "-Infinity" ||
      isNaN(evaluation)
    ) {
      evaluation = "0.00";
    }
    setEvaluation(evaluation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eploatAsPerPlan, eploatAsPerSite, erecommended, erate]);

  useEffect(() => {
    let ppploatDeviation = 0;
    if (pploatAsPerSite < pploatAsPerPlan) {
      ppploatDeviation = 0;
    } else if (
      isNaN(pploatAsPerPlan) ||
      isNaN(pploatAsPerSite) ||
      isUndefined(pploatAsPerSite) ||
      isUndefined(pploatAsPerPlan) ||
      pploatAsPerSite === "" ||
      pploatAsPerPlan === "" ||
      pploatAsPerPlan === "0" ||
      pploatAsPerSite === "0"
    ) {
      ppploatDeviation = 0;
    } else {
      ppploatDeviation = (
        ((pploatAsPerSite - pploatAsPerPlan) / pploatAsPerPlan) *
        100
      ).toFixed(2);
    }
    setPPloatDeviation(parseFloat(ppploatDeviation));

    let pvaluation = (
      ((Math.min(pploatAsPerPlan, pploatAsPerSite) * pcrecommended) / 100) *
      pcrate
    ).toFixed(2);

    if (pvaluation === "Infinity" || pvaluation === "-Infinity") {
      pvaluation = "0.00";
    }

    if (isNaN(pvaluation)) {
      pvaluation = "0.00";
    }

    setPPloatValuation(parseFloat(pvaluation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pploatAsPerPlan, pploatAsPerSite, pcrecommended, pcrate]);

  useEffect(() => {
    let avaluation = (aploatAsPerSite * arecommended) / 100;
    setAvaluation(avaluation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aploatAsPerSite, arecommended]);

  useEffect(() => {
    let fmv =
      Number(valuation) +
      Number(eevaluation) +
      Number(pploatValuation) +
      Number(aavaluation);

    if (isNaN(fmv)) {
      fmv = 0;
    }

    setFmv(parseFloat(fmv).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuation, eevaluation, pploatValuation, aavaluation]);

  let tpc4 = Number(valuation);

  let tpc5 = Number(aploatAsPerSite);

  useEffect(() => {
    let tpc =
      Math.min(pploatAsPerPlan, pploatAsPerSite) * pcrate +
      Math.min(eploatAsPerPlan, eploatAsPerSite) * erate +
      tpc4 +
      tpc5;

    if (isNaN(tpc)) {
      tpc = 0;
    }

    setTpc(tpc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pploatAsPerPlan,
    pcrate,
    eploatAsPerPlan,
    erate,
    valuation,
    aploatAsPerSite,
  ]);

  useEffect(() => {
    let fsv = ttpc * 0.75;

    if (isNaN(fsv)) {
      fsv = 0;
    }

    setFsv(fsv);
  }, [ttpc]);

  const columns1 = [
    {
      title: "Area Type",
      width: 80,
      dataIndex: "area_type",
    },

    {
      title: "As per Plan/deed (in sqft)",
      dataIndex: "as_per_plan_deed_in_sqft",
      width: 100,
    },

    {
      title: "As per Site (In sqft)",
      dataIndex: "as_per_site_in_sqft",
      width: 100,
    },

    {
      title: "Deviation in %",
      dataIndex: "deviation_in_percentage",
      width: 100,
    },

    {
      title: "Rate / Sqft",
      dataIndex: "rate_per_sqft",
      width: 100,
    },

    {
      title: "Completed %",
      dataIndex: "percentage_completed",
      key: "percentage_completed",
      width: 100,
    },
    {
      title: "Recommended %",
      dataIndex: "percentage_recommended",
      key: "percentage_recommended",
      width: 100,
    },
    {
      title: "Valuation INR",
      dataIndex: "valuation_INR",
      key: "valuation_INR",
      width: 100,
    },
  ];

  const data1 = [
    {
      key: "1",
      area_type: "Plot Area/UDS",
      as_per_plan_deed_in_sqft: (
        <Form.Item name="pas_per_plan" rules={[yupSync]}>
          <Input
            placeholder="as per plan"
            className="valuation_property_detail_input"
            onChange={(e) => {
              handleNumberFields(e, "pas_per_plan");
              setPloatAsPerPlan(parseFloat(e.target.value));
            }}
          />
        </Form.Item>
      ),
      as_per_site_in_sqft: (
        <Form.Item name="pas_per_site" rules={[yupSync]}>
          <Input
            placeholder="as per site"
            className="valuation_property_detail_input"
            onChange={(e) => {
              handleNumberFields(e, "pas_per_site");
              setPloatAsPerSite(parseFloat(e.target.value));
            }}
          />
        </Form.Item>
      ),
      deviation_in_percentage: (
        <Form.Item name="pdeviation">
          <Input
            className="valuation_property_detail_input"
            value={ploatDeviation}
            placeholder={ploatDeviation}
            readOnly
          />
        </Form.Item>
      ),
      rate_per_sqft: (
        <Form.Item name="prate" rules={[yupSync]} required>
          <Input
            placeholder=""
            className="valuation_property_detail_input"
            onChange={(e) => {
              setRate(e.target.value);
              handleNumberFields(e, "prate");
            }}
          />
        </Form.Item>
      ),
      percentage_completed: (
        <Form.Item name="ppercentage_completed" rules={[yupSync]} required>
          <Input
            placeholder=""
            className="valuation_property_detail_input"
            onChange={(e) => {
              handleNumberFields(e, "ppercentage_completed");
            }}
          />
        </Form.Item>
      ),
      percentage_recommended: (
        <Form.Item name="ppercentage_recommended" rules={[yupSync]} required>
          <Input
            className="valuation_property_detail_input"
            onChange={(e) => {
              setPRecommended(e.target.value);
              handleNumberFields(e, "ppercentage_recommended");
            }}
          />
        </Form.Item>
      ),
      valuation_INR: (
        <Tooltip title="Min (As per Site (In sqft) , As per Plan/deed (in sqft)) * Rate/qSft">
          <Form.Item name="pvaluation" required>
            <Input
              className="valuation_property_detail_input"
              value={valuation}
              placeholder={valuation}
              readOnly
            />
          </Form.Item>
        </Tooltip>
      ),
    },
    {
      key: "2",
      area_type: "Existing construction area",
      as_per_plan_deed_in_sqft: (
        <Form.Item name="eas_per_plan" rules={[yupSync]} required>
          <Input
            placeholder="as per plan"
            className="valuation_property_detail_input"
            onChange={(e) => {
              setEPloatAsPerPlan(e.target.value);
              handleNumberFields(e, "eas_per_plan");
            }}
          />
        </Form.Item>
      ),
      as_per_site_in_sqft: (
        <Form.Item name="eas_per_site" rules={[yupSync]} required>
          <Input
            placeholder="as per site"
            className="valuation_property_detail_input"
            onChange={(e) => {
              setEPloatAsPerSite(e.target.value);
              handleNumberFields(e, "eas_per_site");
            }}
          />
        </Form.Item>
      ),
      deviation_in_percentage: (
        <Tooltip title="(As per Site (In sqft) / As per Plan/deed (in sqft)) * 100; 0 if less than 0">
          <Form.Item name="edeviation">
            <Input
              value={eploatDeviation}
              placeholder={eploatDeviation}
              readOnly
            />
          </Form.Item>
        </Tooltip>
      ),
      rate_per_sqft: (
        <Form.Item name="erate" rules={[yupSync]} required>
          <Input
            placeholder=""
            className="valuation_property_detail_input"
            onChange={(e) => {
              setErate(e.target.value);
              handleNumberFields(e, "erate");
            }}
          />
        </Form.Item>
      ),
      percentage_completed: (
        <Form.Item name="epercentage_completed" rules={[yupSync]} required>
          <Input
            className="valuation_property_detail_input"
            onChange={(e) => {
              handleNumberFields(e, "epercentage_completed");
            }}
          />
        </Form.Item>
      ),
      percentage_recommended: (
        <Form.Item name="epercentage_recommended" rules={[yupSync]} required>
          <Input
            placeholder=""
            className="valuation_property_detail_input"
            onChange={(e) => {
              handleNumberFields(e, "epercentage_recommended");
              setERecommended(e.target.value);
            }}
          />
        </Form.Item>
      ),
      valuation_INR: (
        <Tooltip title="Min (As per Site (In sqft) , As per Plan/deed (in sqft)) * Rate/qSft">
          <Form.Item name="evaluation" required>
            <Input
              className="valuation_property_detail_input"
              value={eevaluation}
              placeholder={eevaluation}
              readOnly
            />
          </Form.Item>
        </Tooltip>
      ),
    },
    {
      key: "3",
      area_type: (
        <>
          Proposed Construction area
          <span className="valuation_data_red">
            <br></br>
            (Under-construction properties should be updated here)
          </span>
        </>
      ),
      as_per_plan_deed_in_sqft: (
        <Form.Item name="pcas_per_plan" rules={[yupSync]} required>
          <Input
            placeholder=""
            className="valuation_property_detail_input"
            onChange={(e) => {
              handleNumberFields(e, "pcas_per_plan");
              setPPloatAsPerPlan(e.target.value);
            }}
            // onBlur={(e) => setPPloatAsPerPlan(e.target.value)}
          />
        </Form.Item>
      ),
      as_per_site_in_sqft: (
        <Form.Item name="pcas_per_site" rules={[yupSync]} required>
          <Input
            placeholder=""
            className="valuation_property_detail_input"
            onChange={(e) => {
              handleNumberFields(e, "pcas_per_site");
              setPPloatAsPerSite(parseFloat(e.target.value));
            }}
            // onBlur={(e) => setPPloatAsPerSite(parseFloat(e.target.value))}
          />
        </Form.Item>
      ),
      deviation_in_percentage: (
        <Tooltip title="(As per Site (In sqft) / As per Plan/deed (in sqft)) * 100; 0 if less than 0">
          <Form.Item name="pcdeviation" required>
            <Input
              className="valuation_property_detail_input"
              value={pploatDeviation}
              placeholder={pploatDeviation}
              readOnly
            />
          </Form.Item>
        </Tooltip>
      ),
      rate_per_sqft: (
        <Form.Item name="pcrate" rules={[yupSync]} required>
          <Input
            placeholder=""
            className="valuation_property_detail_input"
            onChange={(e) => {
              setPcrate(e.target.value);
              handleNumberFields(e, "pcrate");
            }}
          />
        </Form.Item>
      ),
      percentage_completed: (
        <Form.Item name="pcpercentage_completed" rules={[yupSync]} required>
          <Input
            className="valuation_property_detail_input"
            onChange={(e) => {
              handleNumberFields(e, "pcpercentage_completed");
            }}
          />
        </Form.Item>
      ),
      percentage_recommended: (
        <Form.Item name="pcpercentage_recommended" rules={[yupSync]} required>
          <Input
            className="valuation_property_detail_input"
            onChange={(e) => {
              setPCRecommended(e.target.value);
              handleNumberFields(e, "pcpercentage_recommended");
            }}
          />
        </Form.Item>
      ),
      valuation_INR: (
        <Tooltip title="Min (As per Site (In sqft) , As per Plan/deed (in sqft)) * Rate/qSft">
          <Form.Item name="pcvaluation" required>
            <Input
              className="valuation_property_detail_input"
              value={pploatValuation}
              placeholder={pploatValuation}
              readOnly
            />
          </Form.Item>
        </Tooltip>
      ),
    },
    {
      key: "4",
      area_type: "Amenities",
      as_per_plan_deed_in_sqft: (
        
        <Form.Item name="aas_per_plan" rules={[yupSync]} required>
          <Input
            onChange={(e) => {
              setAPloatAsPerSite(e.target.value);
              handleNumberFields(e, "aas_per_plan");
            }}
         className="amenities_input"
          />
        </Form.Item>
       
      ),
      percentage_completed: (
        <Form.Item name="apercentage_completed" rules={[yupSync]} required>
          <Input
            className="valuation_property_detail_input"
            onChange={(e) => handleNumberFields(e, "apercentage_completed")}
          />
        </Form.Item>
      ),
      percentage_recommended: (
        <Form.Item name="apercentage_recommended" rules={[yupSync]} required>
          <Input
            className="valuation_property_detail_input"
            onChange={(e) => {
              setARecommended(e.target.value);
              handleNumberFields(e, "apercentage_recommended");
            }}
          />
        </Form.Item>
      ),
      valuation_INR: (
        <Form.Item name="avaluation" required>
          <Input
            className="valuation_property_detail_input"
            disabled
            placeholder={aavaluation}
            value={aavaluation}
            onChange={(e) => {
              handleNumberFields(e, "avaluation");
            }}
          />
        </Form.Item>
      ),
    },
    {
      key: "5",
      area_type: "Fair Market Value (INR)",
      valuation_INR: (
        <Tooltip title="(Existsing valuation + Proposed valuation + Plot valuation + Amenities) * % Recommended">
          <Form.Item name="completed_value" required>
            <Input
              className="valuation_property_detail_input"
              value={ffmv}
              placeholder={ffmv}
              readOnly
            />
          </Form.Item>
        </Tooltip>
      ),
    },
    {
      key: "6",
      area_type: "Total Property value on 100% completion (INR)",
      valuation_INR: (
        <Tooltip title="(Existsing valuation + Proposed valuation + Plot valuation + Amenities)">
          <Form.Item
            name="total_market_value"
           
            required
          >
          
            <Input
              className="valuation_property_detail_input"
              value={ttpc}
              placeholder={ttpc}
              readOnly
            />
          </Form.Item>
        </Tooltip>
      ),
    },
    {
      key: "7",
      area_type: "Forced sale Value(INR) at 75%",
      valuation_INR: (
        <Tooltip title="(Existsing valuation + Proposed valuation + Plot valuation + Amenities) * 75%">
          <Form.Item
            name="forced_sale_value"
            //rules={[yupSync]}
            required
          >
            {/* <Input disabled placeholder={fsv} onChange={(e) => { handleNumberFields(e, "forced_sale_value");}}/> */}
            <Input
              className="valuation_property_detail_input"
              value={ffsv}
              placeholder={ffsv}
              readOnly
            />
          </Form.Item>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    if (open) {
      getPersonalData();
    } else {
      form?.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form, stage_id]);

  const handleSubmit = async (values) => {
    const payload = {
      plot_area: [
        {
          id: technicaldata?.plot_area[0]?.id,
          as_per_plan: values.pas_per_plan,
          as_per_site: values.pas_per_site,
          deviation: ploatDeviation,
          percentage_completed: values.ppercentage_completed,
          percentage_recommended: values.ppercentage_recommended,
          rate: values.prate,
          valuation: valuation,
        },
      ],
      existing_construction_area: [
        {
          id: technicaldata?.existing_construction_area[0]?.id,
          as_per_plan: values.eas_per_plan,
          as_per_site: values.eas_per_site,
          deviation: eploatDeviation,
          percentage_completed: values.epercentage_completed,
          percentage_recommended: values.epercentage_recommended,
          rate: values.erate,
          valuation: eevaluation,
        },
      ],
      proposed_construction_area: [
        {
          id: technicaldata?.proposed_construction_area[0]?.id,
          as_per_plan: values.pcas_per_plan,
          as_per_site: values.pcas_per_site,
          deviation: pploatDeviation,
          percentage_completed: values.pcpercentage_completed,
          percentage_recommended: values.pcpercentage_recommended,
          rate: values.pcrate,
          valuation: pploatValuation,
        },
      ],
      amenities: [
        {
          id: technicaldata?.amenities[0]?.id,
          as_per_plan: values.aas_per_plan,
          as_per_site: values.aas_per_site,
          percentage_completed: values.apercentage_completed,
          percentage_recommended: values.apercentage_recommended,
          rate: values.arate,
          valuation: aavaluation.toString(),
        },
      ],
      technical_data: [
        {
          total_market_value: ttpc,
          forced_sale_value: ffsv,
          completed_value: ffmv,
        },
      ],
    };

    try {
      const response = await updateMarketAndTechnicalByIdService(
        technicalid,
        payload
      );
      if (response && response?.data) {
        if (response?.status === 200 && response?.success) {
          message.success("Valuation Of Property updated successfully");
          refreshData();
          closeForm();
        }
      } else {
        message.error("Failed to update Valuation Of Property");
      }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/[^\d.]/g, ""); // Allow digits and single decimal point
    newValue = newValue.slice(0, 10); // Limit to a maximum of 10 characters
    form.setFieldsValue({ [field]: newValue });
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          date_of_visit: "",
          report_date: "",
          met_person_name: "",
          place: "",
        }}
      >
        <div className="valuation_property_detail_table_div">
          <Table
            className=""
            columns={columns1}
            dataSource={data1}
            pagination={false}
            size="middle"
          />
        </div>

        <Space
          direction="horizontal"
          align="center"
          className="valuation_property_detail_space"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default ValuationPropertyDetailsForm;
