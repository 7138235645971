import { axiosRequest } from '../../../utils/api/axiosRequest';

export const getEmployeeWorkAnniversery = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get("/api/v1/dashboard_lite/get-work-anniversery/");
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getEmployeePunchInDetails = (time_sheet_event_type, user_id, day, month, year) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/user-time-sheet-detail/get-user-in-out?time_sheet_event_type=${time_sheet_event_type}&user=${user_id}&day=${day}&month=${month}&year=${year}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const employeePunchOutService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/user/user-time-sheet-detail", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}