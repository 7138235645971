import { Button, Col, Form, Input, Radio, Row, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import {
  getMiscellaneousDetailServiceById,
  updateMiscellaneousDetailServiceById,
} from "../services/miscellaneousDetailsService";
import * as Yup from "yup";
import '../styles/miscellaneousDetails.css'
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";

const validationSchema = Yup.object().shape({
  first_owner: Yup.string()
    .trim()
    .required("First Owner is required")
    .matches(/^[A-Za-z][A-Za-z ]*$/, 'First Owner can only contain letters and spaces and cannot start with a space')
    .min(2, "First Owner must be at least 2 characters")
    .max(32, "First Owner must be at max 32 characters")
    .label("First Owner"),
  market_clear: Yup.string().required("Market clear status is required"),
  chain_of_events: Yup.string().required("Chain of Events is required"),
});


const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ProvisionForm = ({ loanId, stageId, updateId, closeForm, open, refreshData }) => {
  const [marketClear, setMarketClear] = useState(null);
  const [provisionData, setProvisionData] = useState(null); 
  const [form] = Form.useForm();

  const getProvisionData = async () => {
    try {
      const response = await getMiscellaneousDetailServiceById(stageId);
      const data = response?.data[0];
      setProvisionData(data);
      const { first_owner, present_owner, market_clear, chain_of_events } = data;

      form.setFieldsValue({
        first_owner,
        present_owner,
        market_clear: market_clear ? 1 : 2,
        chain_of_events,
      });

     
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      getProvisionData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form]);

  useEffect(() => {
    if (open) {
      getProvisionData(); 
    } else {
      form.resetFields();
      // Cleanup Quill instance if form is closed 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  

  const onChange = (e) => {
    setMarketClear(e.target.value);
  };

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleSubmit = async (values) => {
    try {
      const marketClearValue = values.market_clear === 1;
      const updatedValues = {
        ...values,
        market_clear: marketClearValue, 
      };
      const response = await updateMiscellaneousDetailServiceById(updateId, updatedValues);
      if (response.status === 200 && response.success) {
        message.success("Provision Form successfully updated");
        refreshData();
        closeForm();
      }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        first_owner: "",
        present_owner: "",
        title: provisionData?.title,
        chain_of_events: "",
      }}
    >
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="first_owner"
            label="Who was the first owner of the Property?"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter First Owner Name"
              onChange={(e) => handleInput(e, "first_owner")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            name="present_owner"
            label="Who is the present owner of the Property?"
            required
          >
            <Input
              placeholder="Please Enter Present Owner Name"
              onChange={(e) => handleInput(e, "present_owner")}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            name="market_clear"
            label="Is the title clear and marketable?"
            rules={[yupSync]}
            required
          >
            <Radio.Group onChange={onChange} value={marketClear}>
              <Space direction="vertical">
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="chain_of_events"
        label="How the title devolve to current owner by which document (Chain of event must be mention with document no and date.)"
        rules={[
         
        ]}
        required
      >
        <TextArea rows={4}  placeholder="Please Enter Chain Of Events"  />
      </Form.Item>

      <Space
        direction="horizontal"
        align="center"
       className="provision_space_button"
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ProvisionForm;
