import { Navigate, Outlet, useLocation } from 'react-router-dom'

// Protected Routes
const AuthProtectedRoutes = () => {
  const location = useLocation();

  if (localStorage.getItem('access')) {
    return <Outlet />
} else {
    return <Navigate to="/login" state={{from: location}} />
}
}

export default AuthProtectedRoutes