import React, { useEffect, useState } from "react";
import { Table, message, Modal, Divider, Form, Skeleton, Input } from "antd";
import { Tooltip } from "antd";
import {
  getLoanStagesListService,
  stageToCompleteService,
} from "../services/loanapplicationService";
import { useParams, Link } from "react-router-dom";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { decrypt, encrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { getActionRoute } from "../../../../utils/stageFlow/StageFlow";
import SendBackForm from "./SendBackForm";
import QueryIcon from "../assets/query.svg";
import ForwardIcon from "../assets/forward.svg";
import CompleteIcon from "../assets/complete.svg";
import ForwardForm from "./ForwardForm";
import CancelIcon from "../assets/cancel.svg";
import { useSelector } from "react-redux";
import { updateUserAndStatusById } from "../services/sendBackService";

const LoanApplicationDetails = ({ open, setSubmitStageBar, loan_details }) => {
  // console.log(loan_details);
  const { encrypted_loan_id } = useParams();
  const [stagesData, setStagesData] = useState(null);
  const [stageCode, setStageCode] = useState(null);
  const [getID, setID] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isForwardModalVisible, setIsForwardModalVisible] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState(null);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmStatusModalVisible, setConfirmStatusModalVisible] =
    useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedStageCode, setSelectedStageCode] = useState(null);
  const [sendBackForm] = Form.useForm();
  const [forwaedForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const allowedStages = ["CAM", "Dedupe", "Technical", "Legal", "RCU", "FI"];

  const { user_data } = useSelector((state) => state.user);
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  // const showModal = (stage_code) => {
  //   setIsModalVisible(true);
  //   setStageCode(stage_code);
  // };

  const showModal = (record) => {
    setIsModalVisible(true);
    setStageCode(record); // You may still need the stage_code for some logic.

    sendBackForm.setFieldsValue({
      // stage: record.stage,
      assign_to: record?.handled_by?.id,
    });
  };
  // const showForwardModal = (id) => {
  //   setIsForwardModalVisible(true);
  //   setID(id);
  // };

  const showForwardModal = (record) => {
    setIsForwardModalVisible(true);
    setID(record.id); // Store the record ID for future operations
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    sendBackForm.resetFields();
  };

  const handleCloseForwardModal = () => {
    setIsForwardModalVisible(false);
    forwaedForm.resetFields();
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const showConfirmModal = (id, stage, stage_code) => {
    setSelectedStage(stage);
    setSelectedStageCode(stage_code);
    setSelectedStageId(id);
    setConfirmModalVisible(true);
  };

  const showConfirmStatusModal = (id, stage) => {
    setSelectedStageId(id);
    setSelectedStage(stage);
    setConfirmStatusModalVisible(true);
  };

  const handleConfirmOk = async () => {
    setLoading(true); // Start loading

    try {
      await handleCompleteStage(
        selectedStageId,
        selectedStage,
        selectedStageCode
      );

      setConfirmModalVisible(false); // Close the modal after completion
    } catch (error) {
      // Handle error if any
      setLoading(false); // Stop loading
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };


  const handleConfirmStatusOk = async () => {
    try {
      const values = await form.validateFields();
      const reason = values.reason;
      handleCancelStage(selectedStageId, selectedStage, reason);
      setConfirmStatusModalVisible(false);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLoanStagesListService(loan_id);
        setStagesData(response?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    if (refreshTableData || open) {
      fetchData();
    }
  }, [loan_id, refreshTableData, open]);

  const handleCompleteStage = async (selectedStageId, stage, stage_code) => {
    try {
      const payload = {
        loan_stage: selectedStageId,
        loan_detail: loan_id,
        stage: stage_code,
        stage_status: "MSSTPDD5",
      };
      const response = await stageToCompleteService(payload);
      if ((response.status = 200 && response.success)) {
        message.success(`${stage} Stage completed successfully`);
        setSubmitStageBar();
        toggleRefreshTableData();
      }
    } catch (error) {
      setLoading(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleCancelStage = async (id, stage, reason) => {
    try {
      if (id) {
        const payload = {
          stage_status: "MSSNLLL1",
          reason
        };
        const response = await updateUserAndStatusById(id, payload);
        if ((response.status = 200 && response.success)) {
          message.success(`${stage} Stage cancelled successfully`);
          form.resetFields();
          setSubmitStageBar();
          toggleRefreshTableData();
        }
      }
    } catch (error) {
      form.resetFields();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const getActionButton = (record) => {
    let { id, stage, stage_code, status, handled_by } = record;

    let isCreditManagerOrSuperAdmin = false;

    if (
      user_data?.permissions.some(
        (permission) => (permission.code === "SUPADMIN")
      )
    ) {
      isCreditManagerOrSuperAdmin = true;
    }

    if (loan_details?.credit_manager?.id === user_data?.id) {
      isCreditManagerOrSuperAdmin = true;
    }

    if (handled_by?.id !== user_data?.id) {
      if (isCreditManagerOrSuperAdmin === false) {
        status = "default";
      }
    }

    const tooltipText = (action) => {
      switch (action) {
        case "Complete":
          return `Complete ${stage}`;
        case "Forward":
          return `Forward ${stage}`;
        case "Cancel":
          return `Cancel ${stage}`;
        case "Query":
          return `Query ${stage}`;
        default:
          return "";
      }
    };

    switch (status) {
      case "Completed":
        if (stage !== "Lead") {
          if (isCreditManagerOrSuperAdmin) {
            return (
              <Tooltip title={tooltipText("Query")}>
                <img
                  className="stage_icons"
                  alt="Query Icon"
                  src={QueryIcon}
                  onClick={() => showModal(record)} // Pass the entire record
                />
              </Tooltip>
            );
          }
        }
        return <div>-</div>;

      case "Re - Initiated":
        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                className="stage_icons"
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Forward")}>
              <img
                alt="Forward Icon"
                className="stage_icons"
                src={ForwardIcon}
                onClick={() => showForwardModal(id)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Cancel")}>
              <img
                alt="Cancel Icon"
                className="stage_icons"
                src={CancelIcon}
                onClick={() => showConfirmStatusModal(id, stage)}
              />
            </Tooltip>
          </>
        );

      case "Initiated":
        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                className="stage_icons"
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Forward")}>
              <img
                alt="Forward Icon"
                className="stage_icons"
                src={ForwardIcon}
                onClick={() => showForwardModal(record)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Cancel")}>
              <img
                alt="Cancel Icon"
                className="stage_icons"
                src={CancelIcon}
                onClick={() => showConfirmStatusModal(id, stage)}
              />
            </Tooltip>
          </>
        );

      case "Forward":
        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                className="stage_icons"
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Forward")}>
              <img
                alt="Forward Icon"
                className="stage_icons"
                src={ForwardIcon}
                onClick={() => showForwardModal(record)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Cancel")}>
              <img
                alt="Cancel Icon"
                className="stage_icons"
                src={CancelIcon}
                onClick={() => showConfirmStatusModal(id, stage)}
              />
            </Tooltip>
          </>
        );

      case "Vendor Completed":
        return (
          <>
            <Tooltip title={tooltipText("Complete")}>
              <img
                alt="Complete Icon"
                className="stage_icons"
                src={CompleteIcon}
                onClick={() => showConfirmModal(id, stage, stage_code)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Forward")}>
              <img
                alt="Forward Icon"
                className="stage_icons"
                src={ForwardIcon}
                onClick={() => showForwardModal(record)}
              />
            </Tooltip>
            <Tooltip title={tooltipText("Cancel")}>
              <img
                alt="Cancel Icon"
                className="stage_icons"
                src={CancelIcon}
                onClick={() => showConfirmStatusModal(id, stage)}
              />
            </Tooltip>
          </>
        );
      case "Cancelled":
        return <div>-</div>;

      default:
        return <div>-</div>;
    }
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      key: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      render: (text) => text || "-",
    },
    {
      title: "Assigned To",
      dataIndex: "handled_by",
      key: "handled_by",
      render: (handled_by) => handled_by?.name || "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const { stage, status, id, handled_by } = record;
        const encrypted_stage_id = encrypt(id.toString());

        // if (allowedStages.includes(stage) && status === "Completed") {
        if (
          allowedStages.includes(stage) &&
          (status === "Completed" || status === "Vendor Completed")
        ) {
          // Always show the Link for "Completed" status
          return (
            <Link
              to={getActionRoute(
                stage,
                status,
                encrypted_loan_id,
                encrypted_stage_id
              )}
              className="stage_status_link"
            >
              {status}
            </Link>
          );
        }

        if (
          allowedStages.includes(stage) &&
          (status === "Initiated" || status === "Forward") &&
          handled_by?.id === user_data?.id
        ) {
          // Show the Link for "Initiated" status only if handled_by matches user_data
          return (
            <Link
              to={getActionRoute(
                stage,
                status,
                encrypted_loan_id,
                encrypted_stage_id
              )}
              className="stage_status_link"
            >
              {status}
            </Link>
          );
        }

        // Default rendering for statuses not meeting the conditions
        return <span>{status || "-"}</span>;
      },
    },
    {
      title: "Initiated Date",
      dataIndex: "initiated_date",
      key: "initiated_date",
      render: (text) => text || "-",
    },
    {
      title: "Completed Date",
      dataIndex: "completed_date",
      key: "completed_date",
      render: (text) => text || "-",
    },
    {
      title: "TAT",
      dataIndex: "tat",
      key: "tat",
      render: (text) => text || "-",
    },
    {
      title: "Action",
      key: "action",
      // render: (text, record) =>
      //   record?.handled_by?.id === user_data?.id
      //     ? getActionButton(record)
      //     : "-",
      render: (text, record) => (record ? getActionButton(record) : "-"),
    },
  ];

  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {stagesData?.length > 0 && (
            <Table
              columns={columns}
              dataSource={stagesData}
              pagination={false}
            />
          )}
        </>
      )}
      <Modal
        width={700}
        title="Sendback"
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="sendback_modal"
      >
        <Divider className="sendback_modal_divider" />

        <SendBackForm
          closeForm={handleCancel}
          stage_code={stageCode}
          form={sendBackForm}
          toggleTableRefreshData={toggleRefreshTableData}
          setSubmitStageBar={setSubmitStageBar}
        />
      </Modal>
      <Modal
        width={700}
        title="Forward"
        open={isForwardModalVisible}
        footer={null}
        onCancel={handleCloseForwardModal}
      >
        <Divider className="sendback_modal_divider" />

        <ForwardForm
          closeForm={handleCloseForwardModal}
          // stage_code={stageCode}
          form={forwaedForm}
          id={getID}
          setSubmitStageBar={setSubmitStageBar}
          toggleTableRefreshData={toggleRefreshTableData}
        />
      </Modal>
      <Modal
        title="Confirm Completion"
        open={confirmModalVisible}
        onOk={handleConfirmOk}
        onCancel={() => setConfirmModalVisible(false)}
        okType="danger"
        okButtonProps={{ loading,
          className: 'fixed-width-button'
         }}
         
      >
        <p>{`Are you sure you want to complete the ${selectedStage} stage?`}</p>
      </Modal>
      <Modal
        title="Confirm Cancel"
        open={confirmStatusModalVisible}
        onOk={() => form.submit()}
        onCancel={() => {
          setConfirmStatusModalVisible(false);
          form.resetFields();
        }}
        okType="danger"
      >
        <Form form={form} layout="vertical"
          onFinish={handleConfirmStatusOk}>
          <p>Are you sure you want to cancel the <b>{selectedStage}</b> stage?</p>
          <Form.Item
            style={{ margin: 0 }}
            name="reason"
            label="Remark"
            rules={[
              { required: true, message: "Please enter your reason" },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Enter your reason here"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LoanApplicationDetails;
