import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Space, message } from 'antd';
import * as Yup from "yup";
import { createStrengthsOfTheCaseService, getstrngthById, updateStrengthOfcaseService } from '../services/creditAnalysisServices';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import TextArea from 'antd/es/input/TextArea';

const validationSchema = Yup.object().shape({
    description: Yup.string()
        .required("Description is required")
        .min(2, "Description must be at least 2 characters")
        .max(1000, "Description cannot exceed 1000 characters"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const StrenthForm = ({ closeForm, open, id, loan_id, refreshData }) => {
    const [form] = Form.useForm();

    // const handleInput = (e, field) => {
    //     const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    //     form.setFieldsValue({ [field]: newValue });
    // };

    const getStregthData = async () => {
        if (id) {
            try {
                const response = await getstrngthById(id);
                const { description } = response.data;
                form.setFieldsValue({ description });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        }
    };

    useEffect(() => {
        if (open) {
            getStregthData();
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleSubmit = async (values) => {
        try {
            if (id) {
                // Update Strength of the Case
                const response = await updateStrengthOfcaseService(id, values);
                if (response.status === 200 && response.success) {
                    message.success("Strength of the case updated successfully");
                    refreshData();
                    closeForm();
                }
            } else {
                // Create new Strength of the Case
                const payload = {
                    loan_detail: loan_id,
                    key_type: "Strength",
                    description: values.description,
                };
                const response = await createStrengthsOfTheCaseService(payload);
                if (response.status === 200 && response.success) {
                    message.success("Strength of the case successfully created");
                    refreshData();
                    closeForm();
                }
            }
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
        >
            <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={8}>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[yupSync]}
                        required
                    >
                        {/* <Input
                            placeholder="Please enter description"
                            onChange={(e) => handleInput(e, "description")}
                        /> */}
                        <TextArea
                            placeholder="Please Enter Description"
                            maxLength={1001}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default StrenthForm;
