import { axiosRequest } from "../../../../utils/api/axiosRequest";

export const getCalenderEvents = (queryParams) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/user/user-time-sheet-detail`, {
                params: queryParams, // Dynamically append query params
            });
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};

export const getEventDetailsById = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.get(`/api/v1/user/user-time-sheet-detail/${id}`)
            return resolve(response.data);
        } catch (error) {
            return reject(error);
        }
    });
};