import React, { useState } from 'react';
import '../styles/decisionDetails.css'
import { Table } from "antd";

import { GoDotFill } from "react-icons/go";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import CommonDecisionDetails from './CommonDecisionDetails';

const DecisionDetails = ({stage_id}) => {

    const [collapsed3, setCollapsed3] = useState(true);
    const [collapsed4, setCollapsed4] = useState(true);
    const onChange = (pagination, filters, sorter, extra) => {
    };


    const toggleCollapse3 = () => {
        setCollapsed3(!collapsed3);
    };
    const toggleCollapse4 = () => {
        setCollapsed4(!collapsed4);
    };



    const columns3 = [
        {
            title: 'ID',
            dataIndex: 'id',

        },
        {
            title: 'Status',
            dataIndex: 'status',

        },
        {
            title: 'Remark',
            dataIndex: 'remark',
        },

        {
            title: 'Done By',
            dataIndex: 'done_By',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
    ];

    const data3 = [
        {
            key: '1',
            id: '1',
            status: <div className="disbursed-container_red">
                <GoDotFill className='technical_decision_table_status' />
                <span className='red '>
                    Forward
                </span>
            </div>,
            remark: 'Since the property is located in Gram panchayat where the surrounding area is in Under development stage however, basic amenities like Road, Water and Electricity are observed and the property is in Good locality.',
            done_By: 'Srinivasarao Avula',
            date: '27-Dec-2023 06:09:28 pm',

        },

        {
            key: '2',
            id: '2',
            status: <div className="disbursed-container_red ">
                <GoDotFill  className='technical_decision_table_status' />
                <span className='Success'>
                    Success
                </span>
            </div>,
            remark: ' OK',
            done_By: 'Murali Dara',
            date: '27-Dec-2023 06:09:28 pm',

        },
    ];

    const columns4 = [
        {
            title: 'SrNo.',
            dataIndex: 'no',

        },
        {
            title: 'Property',
            dataIndex: 'property',

        },
        {
            title: 'Text',
            dataIndex: 'text',
        },

        {
            title: 'Document',
            dataIndex: 'document',
        },

        {
            title: 'Written By',
            dataIndex: 'written_by',
        },


        {
            title: 'Stage',
            dataIndex: 'stage',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
    ];

    const data4 = [
        {
            key: '1',
            no: '1',
            property: 'Question',
            text: 'this is not considerable doc',
            document: 'NA',
            written_by: 'Ramesh Krishnamoorthy',
            stage: 'Technical',
            date: '03-Jan-2024 08:19:02 PM',
        },

        {
            key: '2',
            no: '2',
            property: 'Answer',
            text: 'ok',
            document: 'Jebaraj Dharmaraj',
            written_by: 'Jebaraj Dharmaraj',
            stage: 'Technical',
            date: '04-Jan-2024 11:37:09 AM',
        },
    ];

    return (
        <>
            <div className='main_basic_container'>
                <div className='technical_views_decision_form_main_container'>

                <div className="legal_manual_deviation_container">
          <CommonDecisionDetails
          stageId={stage_id}
          type="technical"
          
          />
          </div>

                </div>
                <div className='technical_decision_container_technical_block'>
                    <div  className='technical_decision_table'>
                      
                            <div className='decision_question_heading_text'>Technical Decision</div>
                      
                        <div className='technical_decision_auto_deviation_heading_collapse'
                           onClick={toggleCollapse3}
                       >
                            {collapsed3 ? (
                                <UpOutlined onClick={() => setCollapsed3(!collapsed3)} />
                            ) : (
                                <DownOutlined onClick={() => setCollapsed3(!collapsed3)} />
                            )}
                        </div>
                    </div>

                    {(collapsed3 && (

                        <Table columns={columns3} dataSource={data3} onChange={onChange} pagination={false} />
                    ))}
                </div>


                <div className='technical_decision_container_technical_block'>
                
                        <div className='technical_decision_table'>
                            <div className='decision_question_heading_text'>Decision Question</div>
                        
                        <div className='technical_decision_auto_deviation_heading_collapse'
                            onClick={toggleCollapse4}>
                            {collapsed4 ? (
                                <UpOutlined onClick={() => setCollapsed4(!collapsed4)} />
                            ) : (
                                <DownOutlined onClick={() => setCollapsed4(!collapsed4)} />
                            )}
                        </div>

                    </div>

                    {(collapsed4 && (

                        <Table columns={columns4} dataSource={data4} onChange={onChange} pagination={false}

                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export default DecisionDetails;
