import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import { createQuestionnaireAnswerService, getQuestionnaireAnswerByIdService, listAllQuestionnaireServices, updatecQuestionnaireAnswerByIdService } from "../services/questionnaireAnswerServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  code: Yup.string()
  .required("Code is required")
  .matches(/^[A-Z0-9]+$/, 'Code can only contain capital letters and numbers')
  .min(8, "Code must be at least 8 characters")
  .max(8, "Code must be at max 8 characters")
  .label("Code"),
  questionnaire: Yup.string().required("Questionnaire is required")
    .min(1, "Questionnaire is required"),
  option: Yup.string()
    .required("Option is required")
    .label("Option"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const QuestionnaireAnswerForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [allQuestionnaireAnswer, setAllQuestionnaireAnswer] = useState([]);

  const getQuestionnaireAnswerData = async () => {
    if (id) {
      try {
        const response = await getQuestionnaireAnswerByIdService(id);
        const { option, code, questionnaire, is_active, is_verified } =
          response?.data;
        form.setFieldsValue({
          option,
          code,
          questionnaire: questionnaire?.id,
          is_active,
          is_verified,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };


  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updatecQuestionnaireAnswerByIdService(id, values);
        if ((response.status === 200 && response.success)) {
          message.success("Questionnaire Answer successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createQuestionnaireAnswerService(values);
        if ((response.status === 201 && response.success)) {
          message.success("Questionnaire Answer successfully created");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      closeForm();
      message.error(error.response.data.message);
    }
  };

  // Fetch department data when the form is initially opened or for editing
  useEffect(() => {
    if (open) {
      // If it's opened for editing, fetch department data
      getQuestionnaireAnswerData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  useEffect(() => {
    const getAllQuestionnaire = async () => {
      try {
        const response = await listAllQuestionnaireServices();
        setAllQuestionnaireAnswer(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getAllQuestionnaire();
  }, [form]);

  const handleSelectedLoans = (value) => {
    console.log(value);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        code: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="option" label="Option" rules={[yupSync]} required>
            <Select placeholder="Please Select">
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="questionnaire"
            label="Questionnaire"
            rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              onChange={handleSelectedLoans}
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {allQuestionnaireAnswer?.map((scheme) => {
                return (
                  <Select.Option
                    key={scheme.id}
                    value={scheme.id}>
                    {scheme.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default QuestionnaireAnswerForm;
