import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getEmploymentDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/employment-detail/employment-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getEmploymentByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/salaried/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getSelfemplyedByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/self-employed/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getPensionerByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/pensioner/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getRentalByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/rental/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createSalariedService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/salaried/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createSelfEmployedService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/self-employed/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createPensionerService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/pensioner/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createRentalService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/rental/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateEmploymentByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/salaried/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateSelfemployedByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/self-employed/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updatePensionerByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/pensioner/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateRentalByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/rental/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listIndustryervice = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/industry-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listOrganizationNatureService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/organization-nature/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};  

export const listLineOfWorkService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/line-of-work/get-line-of-work-by-industry-type/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listEmploymentNatureService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/employment-nature/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};  

export const listSourceOfFund  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/source-of-fund`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listFamilyBusiness  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/family-business`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listBusinessLegalStructure  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/business-legal-structure`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listBusinessSeasonality  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/seanality`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listEmploymentType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/employment-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listPropertyType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/property-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllBusinessProofChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/business-registration-proof`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllOwnershipPrimisesChoiceService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/ownership-of-business-primisess`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllLocationTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/business-location-type`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllStructureTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/type-of-structure`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllMaintencePaidByService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/maintence-paid-by`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllMunicipalTaxPaidByService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/munciple-tax-paid-by`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllGSTPaidByService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/gst-paid-by`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllRentalModeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/rental-mode`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAddressCountService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/customer/employment-detail/employment-detail-customer/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const deleteSalariedDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/salaried/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteSelfSalariedDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/self-employed/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteRentalDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/rental/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deletePensionerDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/pensioner/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}