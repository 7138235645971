import { useSelector } from "react-redux";

const ConditionalRender = ({
  userPermissions,
  requiredPermissions,
  children,
}) => {
  const { user_data } = useSelector((state) => state.user);
  const userRoles = user_data?.roles || [];
  const hasPermission = userPermissions?.some((permission) =>
    requiredPermissions.includes(permission?.code)
  );
  const hasAdminRole = userRoles?.some(
    (role) => role.code === "MPADMIN1" || role.code === "SUPADMIN"
  );
  // return hasPermission ? children : null;
  if (hasPermission || hasAdminRole) {
    return children;
  } else {
    return null;
  }
};

export default ConditionalRender;
