import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined
} from "@ant-design/icons";
import { deleteSchemeConfigrationByIdService } from "../services/schemeConfigrationService";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import SchemeConfigrationForm from "./SchemeConfigrationForm";
import SchemeConfigrationFilterForm from "./SchemeConfigrationFilterForm";

import ConditionalRender from "../../../../utils/authorization/AuthorizeComponent";
import authorizeTableColumns from "../../../../utils/authorization/authorizeTableColumns";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const Schemeconfigration = () => {
  const { Title } = Typography;
  const [schemeConfigration, setSchemeConfigration] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSchemeConfigration, setSelectedSchemeConfigration] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

  const { user_data } = useSelector((state) => state.user);
  // Filter Scheme
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    is_active: null,
    is_verified: null,
    loan_type: [],
  });

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedSchemeConfigration(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);

  const columns = [
    {
      title: "Loan type",
      dataIndex: ["loan_scheme_detail", "loan_type"],
      key: "loan_type",
      sorter: true,
      fixed: "left",
      width: 200,
    },


    {
      title: "Scheme",
      dataIndex: ["loan_scheme_detail", "scheme"],
      key: "scheme"
    },

    {
      title: "Minimum Loan Amount",
      dataIndex: "min_loan_amt",
      key: "min_loan_amt",
      render: (text) => `₹${parseFloat(text).toLocaleString()}`
    },

    {
      title: "Maximum Loan Amount",
      dataIndex: "max_loan_amt",
      key: "max_loan_amt",
      render: (text) => `₹${parseFloat(text).toLocaleString()}`
    },

    {
      title: "Max Tenor",
      dataIndex: "max_tenor",
      key: "max_tenor"
    },
    {
      title: "Max Age",
      dataIndex: "max_age",
      key: "max_age"
    },

    {
      title: "Max LTV",
      dataIndex: "max_ltv",
      key: "max_ltv"
    },

    {
      title: "Min Roi",
      dataIndex: "min_roi",
      sorter: true,
      render: (min_roi) => {
        return min_roi !== null ? min_roi : " null";
      },
    },
    {
      title: "Max ROI",
      dataIndex: "max_roi",
      key: "max_roi",
      render: (text) => `${text}%`
    },

    {
      title: "FOIR Applicability",
      dataIndex: "FOIR_applicability",
      sorter: true,
      render: (FOIR_applicability) => {
        return FOIR_applicability !== null ? FOIR_applicability : " null";
      },
    },


    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => {
        return record.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },

    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => {
        return record.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },

    {
      title: "Created By",
      dataIndex: "created_by",

    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
    },

  ];

  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["AEMASTER"]}
            >
              <EditOutlined
                onClick={() => {
                  setSelectedSchemeConfigration(record);
                  showDrawer();
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["DLMASTER"]}
            >
              <DeleteOutlined
                onClick={() => {
                  setSelectedSchemeConfigration(record);
                  setOpenModal(true);
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["AEMASTER", "DLMASTER"]
  );



  const deleteSchema = async () => {
    try {
      const response = await deleteSchemeConfigrationByIdService(selectedSchemeConfigration.id);

      if (response.status === 200 && response.success === true) {
        message.success("Scheme deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedSchemeConfigration(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Scheme Configration
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["VWMASTER"]}
          >
            <Button
              onClick={() => setShowFilterForm(true)}
              icon={<FilterOutlined />}
              style={{ marginLeft: "1rem" }}
            >
              More Filters
            </Button>
          </ConditionalRender>
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["AEMASTER"]}
          >
            <Button
              onClick={showDrawer}
              type="primary"
              icon={<PlusCircleOutlined />}
              style={{ marginLeft: "1rem" }}
            >
              Add Scheme Configration
            </Button>
          </ConditionalRender>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/master/scheme-configuration/"
        tableData={schemeConfigration}
        setTableData={setSchemeConfigration}
        tableColumns={tableColumns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}

      />
      <Drawer
        title={<Header title={selectedSchemeConfigration ? "Edit" : "Add"} onClose={onClose} name="Scheme Configration" />}
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >

        <SchemeConfigrationForm
          refreshData={toggleRefreshTableData}
          id={selectedSchemeConfigration && selectedSchemeConfigration.id}
          open={open}
          closeForm={onClose}
          isEditMode={!!selectedSchemeConfigration}
        />

      </Drawer>
      <Drawer
        title={
          <Header
            title={"Filter"}
            onClose={closeFilterForm}
            name="Scheme Configration"
          />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <SchemeConfigrationFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteSchema();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedSchemeConfigration?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default Schemeconfigration;

