import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Select, Space, message } from 'antd';
import { initiateFinalApproval, listUserService } from '../services/finalApprovalServices';
import ErrorMessage from '../../../../../../utils/errorHandling/ErrorMessage';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../../utils/cryptoUtils/cryptoUtils';
import TextArea from "antd/es/input/TextArea";

const FinalApproval = ({ closeForm, open ,setSubmitStageBar}) => {
    const [form] = Form.useForm();
    const { encrypted_loan_id } = useParams();
    const [users, setUsers] = useState([]);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const response = await listUserService(loan_id);
                setUsers(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        fetchAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleSubmit = async (values) => {

        setLoading(true); 

        const payload = {
            ...values,
            // comments: plainTextComment
        };

        // console.log(payload);
        try {
            const response = await initiateFinalApproval(loan_id, payload);
            if ((response.status = 201 && response.success)) {
                message.success("Final approved successfully");
                setSubmitStageBar();
                setValue(null);
                closeForm();
            }
        } catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );

        } finally {
            setLoading(false)

        }
    };

    useEffect(() => {
        form.resetFields();
        setValue(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeForm]);

    return (
        <>
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical">
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item name="assigned_to" label="Recommend To"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select recommend to'
                                }
                            ]}>
                            <Select
                                mode="single"
                                placeholder="Please select"
                                allowClear
                                className="initiation_form_select_width"
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        ? option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        : false
                                }

                            >
                                {users?.map((created) => {
                                    return (
                                        <Select.Option key={created.id} value={created.id}>
                                            {created.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="comments"
                            label="Comment"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter remarks'
                                }
                            ]}
                        >
                            <TextArea rows={4}  placeholder="Please Enter Remark"  value={value}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Space
                    direction="horizontal"
                    align="center"
                    className='initiate_button'
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Initiate
                    </Button>

                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
                <br></br>
            </Form>

        </>
    )
}

export default FinalApproval