import React from 'react';
import '../../documentDetails/styles/documentstyle.css'

import { Badge, Table } from 'antd';
import '../../trail/styles/trail.css'
import EditButton from '../../../../../utils/editButton/EditButton';

const columns = [
  {
      title: 'ID',
      dataIndex: 'id',      
  },
  {
      title: 'Assigned To',
      dataIndex: 'Assigned_to',
      
  },
  {
      title: 'Forwarded To',
      dataIndex: 'Forwarded_To',
  },


  {
    title: 'Status',
    dataIndex: 'Initiated_Date',
    render: (_, record) => {
      let badgeColor = '';
      switch(record.status) {
        case 'Initiated':
          badgeColor = "#2E90FA";
          break;
        case 'Success':
          badgeColor = '#389E0D'; // Green
          break;
        case 'Re-Initiated':
          badgeColor = '#2E90FA'; // Blue
          break;
          case 'Vendor-Completed':
            badgeColor = '#5925DC';
            break;
          case 'Forward':
            badgeColor = '#C4320A'; // Blue
            break;
        default:
          badgeColor = '#000000';
      }
      return (
        <Badge
          text={record.status}
          color={badgeColor}
          className='technical_trail_status'
          style={{color: badgeColor}}
        />
      );
    }
  },
  
  {
    title: 'Initiated Date',
    dataIndex: 'Initiated_Date',
},
 
  {
      title: 'Completion Date',
      dataIndex: 'Completion_Date',
  },


];
const data = [
  {
      key: '1',
      id: '1',
      Assigned_to: 'SMR ENGINEERS AND VALUERS',
      Forwarded_To: "Kairam Narendhar",
      status: "Initiated",
      Initiated_Date: "27-Dec-2023 06:09:28 pm",
      Completion_Date: "27-Dec-2023 06:16:04 pm",
      
  },
  {
    key: '2',
    id: '2',
    Assigned_to: 'SMR ENGINEERS AND VALUERS',
    Forwarded_To: "Kairam Narendhar",
    status: "Success",
    Initiated_Date: "27-Dec-2023 06:09:28 pm",
    Completion_Date: "27-Dec-2023 06:16:04 pm",
    
},
{
  key: '3',
  id: '3',
  Assigned_to: 'SMR ENGINEERS AND VALUERS',
  Forwarded_To: "Kairam Narendhar",
  status: "Re-Initiated",
  Initiated_Date: "27-Dec-2023 06:09:28 pm",
  Completion_Date: "27-Dec-2023 06:16:04 pm",
  
},
{
  key: '4',
  id: '4',
  Assigned_to: 'SMR ENGINEERS AND VALUERS',
  Forwarded_To: "Kairam Narendhar",
  status: "Vendor-Completed",
  Initiated_Date: "27-Dec-2023 06:09:28 pm",
  Completion_Date: "27-Dec-2023 06:16:04 pm",
  
},
{
  key: '4',
  id: '4',
  Assigned_to: 'SMR ENGINEERS AND VALUERS',
  Forwarded_To: "Kairam Narendhar",
  status: "Forward",
  Initiated_Date: "27-Dec-2023 06:09:28 pm",
  Completion_Date: "-",
},
]

const onChange = (pagination, filters, sorter, extra) => {
};

  

const Trail = () => {
  return (
    <>
     <div className='main_basic_container'>
      <div className='tarsil_container'>
        <div className='document_details valuation_property_blue_heading '>
          <div className='technical_details_blue'>Vendor Stage Details</div>
          <div>
            <div className='basic_edit_container basic_button'>
            <EditButton />
            </div>
          </div>
        </div>
        <Table  columns={columns} dataSource={data} onChange={onChange} pagination={false} />
      </div>
      </div>
    </>
  );
}

export default Trail;
