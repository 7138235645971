import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  DatePicker,
  message,
} from "antd";
import * as Yup from "yup";
import {
  getPropertyAndCustomerDetailByLoanId,
  postApplicantData,
} from "../services/SecurityInterestPendingList";
import dayjs from "dayjs";
import "../styles/ApplicantEditForm.css"

const validationSchema = Yup.object().shape({
  salutation: Yup.string().required("Salutation is required"),
  first_name: Yup.string()
    .required("First Name is required")
    .matches(
      /^[A-Za-z]+( [A-Za-z]+)*$/,
      "First Name must contain only alphabets with single spaces"
    )
    .test(
      "no-multiple-spaces",
      "Only one space between words is allowed",
      (value) => !value || !/\s{2,}/.test(value)
    ),
  middle_name: Yup.string()
    .matches(
      /^[A-Za-z]+( [A-Za-z]+)*$/,
      "Middle Name must contain only alphabets with single spaces"
    )
    .test(
      "no-multiple-spaces",
      "Only one space between words is allowed",
      (value) => !value || !/\s{2,}/.test(value)
    )
    .nullable(),

  last_name: Yup.string()
    .required("Last Name is required")
    .matches(
      /^[A-Za-z]+( [A-Za-z]+)*$/,
      "Last Name must contain only alphabets with single spaces"
    )
    .test(
      "no-multiple-spaces",
      "Only one space between words is allowed",
      (value) => !value || !/\s{2,}/.test(value)
    ),
    date_of_birth: Yup.date()
    .required("Date of birth is required")
    .test(
      "not-future-date",
      "Date of birth cannot be a future date",
      function (value) {
        return value ? dayjs(value).isBefore(dayjs()) : true;
      }
    )
    .test(
      "valid-age",
      "Customer must be at least 18 years old",
      function (value) {
        if (!value) return true; // Skip if no value
        if (!dayjs(value).isBefore(dayjs())) return false; // Skip if future date

        const givenDate = dayjs(value);
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, "year");

        return givenDate.isValid() && givenDate.isBefore(eighteenYearsAgo);
      }
    )
    .label("Date Of Birth"),
    relation_with_applicant: Yup.string()
    .required("Spouse/Father Type is required")
    .matches(/^[A-Za-z]+( [A-Za-z]+)*$/, "Relation must contain only alphabets with single spaces")
    .test('no-multiple-spaces', 'Only one space between words is allowed', 
      (value) => !value || !/\s{2,}/.test(value)),
  spouse_name: Yup.string()
    .required("Spouse/Father Name is required")
    .matches(
      /^[A-Za-z]+( [A-Za-z]+)*$/,
      "Spouse/Father Name must contain only alphabets with single spaces"
    )
    .test(
      "no-multiple-spaces",
      "Only one space between words is allowed",
      (value) => !value || !/\s{2,}/.test(value)
    ),
  marital_status: Yup.string().required("Marital Status is required"),
  occupationType: Yup.string().required("Occupation Type is required"),
  identityProofType: Yup.string().required("Identity Proof is required"),
  permanentAddressProof: Yup.string().required("Address Proof is required"),
  address_line_1: Yup.string().required("Address Line 1 is required"),
  district: Yup.string().required("District is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ApplicantEditForm = ({
  closeForm,
  refreshData,
  customerDetailId,
  loan_id,
  customerDetail,
  cersaidetail,
  customerId
}) => {
  const [form] = Form.useForm();
  const [applicantDetails, setApplicantDetails] = useState([]);

  const fetchPropertyAndCustomerDetailByLoanId = async () => {
    try {
      const response = await getPropertyAndCustomerDetailByLoanId(loan_id);
      setApplicantDetails(response.data);
    } catch (error) {
      message.error("Failed to fetch the Property And Customer Data.");
    }
  };

  useEffect(() => {
    fetchPropertyAndCustomerDetailByLoanId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, loan_id]);

  const handleSubmit = async (values) => {
    try {
      const currentApplicant =
        applicantDetails?.customer_detail[customerDetailId];
      const payload = {
        salutation: values.salutation,
        first_name: values.first_name,
        middle_name: values.middle_name || "",
        last_name: values.last_name,
        date_of_birth: values.date_of_birth
          ? dayjs(values.date_of_birth).format("YYYY-MM-DD")
          : null,
        pan_number: values.pan_number || "",
        relation_with_applicant: values.relation_with_applicant,
        marital_status: values.marital_status,
        spouse_name: values.spouse_name,
        customer_type: customerDetail.customertype,
        address_details: {
          permanent_address: {
            plot_number: values.plot_number || "",
            address_type: customerDetail.addresstype, 
            address_line_1: values.address_line_1 || "",
            address_line_2: values.address_line_2 || "",
            district: values.district || "",
            state: values.state || "",
            city: values.city || "",
            pincode: values.pincode || "",
          },
        },
        cersai_detail:cersaidetail,//new added
        cersai_customer_detail: currentApplicant?.cersai_customer_detail,
        // cersai_detail: customerDetail.cersaiDetail,
        customer_detail: customerDetail.customerDetail,
        // customer_detail: customerId,

        // loan_detail: loandetail,
      };

      const response = await postApplicantData(payload);
      if (response.status === 200 && response.success) {
        // Check if cersai_customer_detail exists
        if (currentApplicant?.cersai_customer_detail) {
          message.success("Applicant data updated successfully");
        } else {
          message.success("Applicant data created successfully");
        }
        refreshData();
        closeForm();
        // form.resetFields();
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to create Applicant Data");
    }
  };
  useEffect(() => {
    if (
      applicantDetails?.customer_detail?.length &&
      customerDetailId !== null
    ) {
      const currentApplicant =
        applicantDetails.customer_detail[customerDetailId];

      const initialData = {
        salutation: currentApplicant?.salutation,
        first_name: currentApplicant?.first_name,
        last_name: currentApplicant?.last_name,
        middle_name: currentApplicant?.middle_name,
        date_of_birth: dayjs(currentApplicant?.date_of_birth, "DD-MM-YYYY"),
        relation_with_applicant: currentApplicant?.relation_with_applicant,
        spouse_name:
          currentApplicant?.spouse_name || currentApplicant?.father_name,
        marital_status: currentApplicant?.marital_status,
        address_line_1:
          currentApplicant?.customer_address_detail?.permanent_address
            ?.address_line_1,
        address_line_2:
          currentApplicant?.customer_address_detail?.permanent_address
            ?.address_line_2,
        state:
          currentApplicant?.customer_address_detail?.permanent_address?.state,
        city: currentApplicant?.customer_address_detail?.permanent_address
          ?.city,
        district:
          currentApplicant?.customer_address_detail?.permanent_address
            ?.district,
        pincode:
          currentApplicant?.customer_address_detail?.permanent_address?.pincode,
      };
      form.setFieldsValue(initialData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantDetails, customerDetailId]);

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <div className="applicant-detail-form-content">
        <h3>Applicant Detail</h3>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="salutation"
              label="Salutation"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter Salutation" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter First Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="middle_name" label="Middle Name" required>
              <Input placeholder="Enter Middle Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter Last Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="date_of_birth"
              label="Date Of Birth"
              rules={[yupSync]}
              required
            >
              <DatePicker
                className="applicant-detail-date-field"
                format="DD-MM-YYYY"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="relation_with_applicant"
              label="Spouse/Father Type"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter Type" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="spouse_name"
              label="Spouse/Father Name"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="marital_status"
              label="Marital Status"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter Status" />
            </Form.Item>
          </Col>
        </Row>
      </div>

      {/* Address Detail Section */}
      <div className="applicant-detail-form-content">
        <h3>Address Detail</h3>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="address_line_1"
              label="Permanent Address Line 1"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter Address Line 1" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="address_line_2" label="Permanent Address Line 2">
              <Input placeholder="Enter Address Line 2" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="district"
              label="Permanent District"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter District" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="city"
              label="Permanent City"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter City" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="state"
              label="Permanent State"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter State" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="pincode"
              label="Permanent Pincode"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Enter Pincode" type="number"/>
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Space className="confirm-and-save-button">
        <Button type="primary" htmlType="submit">
          Confirm & Save
        </Button>
      </Space>
    </Form>
  );
};

export default ApplicantEditForm;
