import React, { useEffect, useState } from "react";
import "../styles/cibil.css";
import "../styles/highMarkReport.css";
import { Divider, Table, message } from "antd";
import { getHighMarkReportByIdService } from "../services/highMarkReportService";

import green_rectangle from "../assets/green_rectangle .png";
import red_rectangle from "../assets/red_rectangle.png";
import Chart from "react-google-charts";
import greendot from "../assets/greendot.png";
import yellowdot from "../assets/yellowdot.png";
import reddot from "../assets/reddot.png";
import crifimage from "../assets/crifimage.png";
import layer from "../assets/Layer.png";
import ReactSpeedometer from "react-d3-speedometer";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";

const appendix_column = [
  {
    title: "Section",
    dataIndex: "section",
    key: "section",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
];
const appendix_data = [
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
  {
    key: "1",
    section: "Account Summary",
    code: "Number of Delinquent Accounts",
    description:
      "Indicates number of accounts that the applicant has defaulted on within the last 6 months",
  },
];

// const data = [
//   {
//     key: "1",
//     type: "Primary Match",
//     own: "0",
//     other: "0",
//     active: "0",
//     closed: "0",
//     default: "0",
//   },
//   {
//     key: "1",
//     type: "Secondary Match ",
//     own: "0",
//     other: "0",
//     active: "0",
//     closed: "0",
//     default: "0",
//   },
// ];

const options = {
  pieHole: 0.8,
  colors: ["#219653", "#E61D2B", "#E6AE1D"],
  pieSliceText: "none",
  legend: "none",
};
const baroptions = {
  chart: {
    // title: "Company Performance",
    // subtitle: "Sales, Expenses, and Profit: 2014-2017",
  },
  legend: { position: "none" },
  width: "20px",
};
export const HighMarkReport = () => {
  const { encrypted_customer_id } = useParams();
  const [highMarkData, setHighMarkData] = useState(null);

  let customer_id = null;
  try {
    customer_id = decrypt(encrypted_customer_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }


  const chartdata = [
    ["Task", "Number of Accounts"],
    ["Others", parseInt(
      highMarkData?.data?.account_summary
        ?.all_accounts?.primary_secured_number_of_account
    ) +
      parseInt(
        highMarkData?.data?.account_summary
          ?.all_accounts?.primary_unsecured_number_of_account
      ) +
      parseInt(
        highMarkData?.data?.account_summary
          ?.all_accounts?.primary_untagged_number_of_account
      )],
    [
      "Overdue",
      parseInt(
        highMarkData?.data?.account_summary?.all_accounts?.primary_overdue_number_of_account
      ),
    ],
    [
      "Active",
      parseInt(
        highMarkData?.data?.account_summary?.all_accounts?.primary_active_number_of_account
      ),
    ],
  ];

  const bardata = [
    ["Year", "Sales"],
    ["30 Days", parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_30_days
    ),],
    ["60 Days", parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_60_days
    ),],
    ["12 Months", parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_12_months
    ),],
    ["24 Months", parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.last_24_months
    ),],
    ["Recent", parseInt(
      highMarkData?.data?.account_summary?.inquiries_history?.recent
    ),],
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getHighMarkReportByIdService(customer_id);
        setHighMarkData(data);
      } catch (error) {
        console.error("Error fetching highmark report data:", error);
      }
    };

    fetchData();
  }, [customer_id]);

  const personalInfoVariations = highMarkData?.data?.personal_info_variation;
  return (
    <>
      <div className="main_cibil_container">
        <div>
          <p className="para_heading_first">CRIF Highmark Report</p>
        </div>
        <div className="cibil_heading_second">
          Home / HL0000000164179 /
          <span className="cibil_report_text">CRIF Highmark Report</span>
        </div>
      </div>

      <div className="cibil_score_div">
        <div className="cibil_score_div_secomnd">
          <div className="cibil_score_icon">
            <ReactSpeedometer
              value={parseInt(highMarkData?.data?.score?.score_value)}
              customSegmentLabels={[
                {
                  text: "Very Bad",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Bad",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Ok",
                  position: "INSIDE",
                  color: "#555",
                  fontSize: "19px",
                },
                {
                  text: "Good",
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  text: "Very Good",
                  position: "INSIDE",
                  color: "#555",
                },
              ]}
            />
          </div>
        </div>
        <div className="crifiimage">
          <img src={crifimage} alt="" />
        </div>
        <Divider
          className="divider_cibil"
        />
        <div
          className="divider_cibil_text"
        >
          <h4>
            <span className="cibil_score_text">SCORE NAME:</span>
            {highMarkData?.data?.score?.score_type || '-'} 
          </h4>
          <h4>
            <span className="cibil_score_text">Application ID:</span>
            {highMarkData?.data?.score?.application_number || '-'}
          </h4>
          <h4>
            <span className="cibil_score_text">DATE OF REQUEST:</span>
            {highMarkData?.data?.score?.created_at || '-'}
          </h4>
        </div>
      </div>
      <div className="div1 Adddress_conaintener" >
        <div
       className="div1_two"
        >
          <p className="para" >
            Account Summary
          </p>
          <div className="summary_para">
            Tip: Current Balance, Disbursed Amount & Instalment Amount is
            considered ONLY for ACTIVE account
          </div>
        </div>
        <div className="second_div_employment">
          <div className="third_div_account">
            <div
              className="third_div_account_two"
            >
              <h4
                className="All_Account_text"
              >
                All Accounts
              </h4>
              <div className="all_account_div">
                <div className="all_account_div_firts">
                  <h4
                    className="all_account_div_firts_heading"
                  >
                    <span>
                      <img src={greendot} alt="green" />
                    </span>
                    <span className="others_cibil">
                      {" "}
                      Others -
                      {parseInt(
                        highMarkData?.data?.account_summary
                          ?.all_accounts?.primary_secured_number_of_account
                      ) +
                        parseInt(
                          highMarkData?.data?.account_summary
                            ?.all_accounts?.primary_unsecured_number_of_account
                        ) +
                        parseInt(
                          highMarkData?.data?.account_summary
                            ?.all_accounts?.primary_untagged_number_of_account
                        )}
                    </span>
                  </h4>
                  <h4
                    className="all_account_div_firts_heading"
                  >
                    <span>
                      <img src={reddot} alt="green" />
                    </span>
                    <span className="others_cibil">
                      Overdue -
                      {
                        highMarkData?.data?.account_summary
                          ?.all_accounts?.primary_overdue_number_of_account
                      }
                    </span>
                  </h4>
                  <h4
                    className="all_account_div_firts_heading"
                  >
                    <span>
                      <img src={yellowdot} alt="green" />
                    </span>
                    <span className="others_cibil">
                      Active -
                      {
                        highMarkData?.data?.account_summary
                          ?.all_accounts?.primary_active_number_of_account
                      }
                    </span>
                  </h4>
                </div>
                <div className="chart_div">
                  <Chart
                    chartType="PieChart"
                    className="chart_div_two"
                    data={chartdata}
                    options={options}
                  />
                </div>
              </div>
            </div>
            <div
              className="cibil_div_inuiiries"
            >
              <h4
                className="cibil_div_inuiiries_heading"
              >
                Inquiries in last 24 Months (Total - {parseInt(
                  highMarkData?.data?.account_summary?.inquiries_history?.last_24_months || 0
                )})
                <span className="cibil_div_inuiiries_heading_icon">
                  <img src={layer} alt="" />
                </span>
              </h4>
              {parseInt( highMarkData?.data?.account_summary?.inquiries_history?.last_24_months || 0) > 0 &&
                bardata &&
                bardata.length > 1 &&
                bardata.some(row => row.some(value => value !== 0)) ? (
              <div className="cibil_div_inuiiries_chart">
                <Chart
                  chartType="Bar"
                  className="cibil_div_inuiiries_chart_two"
                  data={bardata}
                  options={baroptions}
                />
              </div>
                   ) :
                   <div
                     className='cibit_report_no_data'
                   >
                     <CustomNoData />
                   </div>
                 }
   
            </div>
          </div>
          <div

            className="Sanction_div_highmark_second"
          >
            <div
              className="Sanction_div_cibil_second"
            >
              <div className="amt_hiighmark" >
                <h4 className="amt_hiighmark" >Amt Disbd/ High Credit</h4>
                <h2 className="amt_hiighmark_text_rupess" >
                  ₹
                  {
                    highMarkData?.data?.account_summary
                      ?.all_accounts?.primary_disbursed_amount
                   || '-'}
                </h2>
              </div>
            </div>
            <div
              className="Sanction_div_cibil_second"
            >
              <div className="amt_hiighmark" >
                <h4 className="amt_hiighmark" >Current Balance</h4>
                <h2 className="amt_hiighmark_text_rupess" >
                  ₹
                  {highMarkData?.data?.account_summary?.all_accounts?.primary_current_balance || '-'}
                </h2>
              </div>
            </div>
          </div>
          <div className="new_acc_higmark">
            <div className="amt_hiighmark">
              <h4 className="amt_hiighmark_text" >
                New Account(s) in last 6 Months:
                <span className="amt_hiighmark_text_two" >
                  {
                    highMarkData?.data?.account_summary
                      ?.all_accounts?.new_account_in_last_six_month
                  }
                </span>
              </h4>
            </div>
            <div className="amt_hiighmark">
              <h4 className="amt_hiighmark_text">
                New Delinquent Account(s) in last 6 Months:
                <span className="amt_hiighmark_text_two">
                  {
                    highMarkData?.data?.account_summary
                      ?.all_accounts?.new_delinq_account_in_last_six_month
                  }
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div className="div1 div2_container" >
              <div
                className="div1_two"
              >
                <p className="para" >
                  Inquiry Input Information
                </p>
              </div>
              <div className="second_div_employment">
                <div
                  className="heagin_first"
                 
                >
                  <p className="para">
                    {highMarkData?.data?.customer_detail?.customer_name}
                  </p>
                </div>

                <div>
                  <div className="third_div_account">
                    <div className="first_div">
                      <div className="lable_first">
                        <span className="span_lable"> Date of Birth </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.date_of_birth || '-'}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable">Gender </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.gender || '-'}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable"> Father </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.father_name || '-'}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable"> Spouse </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.spouse_name || '-'}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable"> Entity ID </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.gender || '-'}
                        </span>
                      </div>
                    </div>
                    <div className="second_div">
                      <div className="lable_first">
                        <span className="span_lable">Phone Number (S) </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.phone_number || "-"}
                        </span>
                      </div>
                      <div className="lable_first">
                        <span className="span_lable">Identification (S) </span>
                        <span className="span_data">
                          :{" "}
                          {highMarkData?.data?.customer_detail?.identification || '-'}
                        </span>
                      </div>

                      <div className="lable_first">
                        <span className="span_lable">Email ID (S) </span>
                        <span className="span_data">
                          : {highMarkData?.data?.customer_detail?.email || '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div >
                    <div className="customer_div">
                      <span className="customer_lable"> Current address </span>
                      <span className="customer_address">
                        : {highMarkData?.data?.customer_detail?.current_address || '-'}
                      </span>
                    </div>
                    <div className="customer_div">
                      <span className="customer_lable"> Other Address </span>
                      <span className="customer_address">
                        : {highMarkData?.data?.customer_detail?.other_address || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="div1 Adddress_conaintener">
              <div
            className="div1_two"
              >
                <p className="para">
                  CRIF HM Score (S)
                </p>
                <div className="summary_para">
                  Tip: Current Balance, Disbursed Amount & Instalment Amount is
                  considered ONLY for ACTIVE account
                </div>
                <div className="Adddress_conaintener">
                  <p
               className="all_account_div_firts_heading"
                  >
                    <span  className="others_cibil">
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span className="others_cibil">
                      Positive impact on credit score
                    </span>
                  </p>
                  <p
                 className="all_account_div_firts_heading"
                  >
                    <span  className="others_cibil" >
"
                      <img src={red_rectangle} alt="red" />
                    </span>
                    <span className="others_cibil">
                      Negative impact on credit score
                    </span>
                  </p>
                </div>
              </div>
              <div className="second_div_employment">
                <div >
                  <h4
                className="all_account_div_firts_heading"
                  >
                    <span>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span className="others_cibil">
                      No/minimal missed payments in recent past
                    </span>
                  </h4>
                  <h4
               className="all_account_div_firts_heading"
                  >
                    <span>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span className="others_cibil">
                      Normal proportion of outstanding balance to disbursed
                      amount
                    </span>
                  </h4>
                  <h4
               className="all_account_div_firts_heading"
                  >
                    <span>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span className="Sanction_div_cibil_heading_Current">
                      Consistent decrease in outstanding balance on self/overall
                      installment loans in the past
                    </span>
                  </h4>
                  <h4
                   className="all_account_div_firts_heading"
                  >
                    <span>
                      <img src={green_rectangle} alt="green" />
                    </span>
                    <span className="others_cibil">
                      No/minimal missed payments in recent past
                    </span>
                  </h4>
                  <h4
                className="all_account_div_firts_heading"
                  >
                    <span>
                      <img src={red_rectangle} alt="red" />
                    </span>
                    <span  className="others_cibil">
                      No/minimal missed payments in recent past
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="Adddress_conaintener">
              <div className="div2">
                <div className="div1_two">
                  <p className="para">
                    Personal Information - Variations
                  </p>
                </div>

                <div className="main_div_cibil_second main_div_cibil_second" >
                  {personalInfoVariations &&
                    Object.entries(personalInfoVariations).map(
                      ([variationKey, variationValue], index) => (
                        <div key={index}>
                          {variationValue.length > 0 && (
                            <>
                              <div className="personal_information_div">
                                <div className="reported_div">
                                  <span className="variation_key">
                                    {variationKey}
                                  </span>
                                  <span className="variation_key">
                                    {" "}
                                    Reported On
                                  </span>
                                </div>
                                {variationValue.map((item, i) => (
                                  <div key={i} className="reported_div">
                                    <span className="value_lable">
                                      {item.value}
                                    </span>
                                    <span className="reported_data">
                                      {item.reported_date}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>

            <div className="div1 Adddress_conaintener">
              <div className="div1_two">
                <p className="para">
                  Account Information
                </p>
              </div>
              <div className="main_div_cibil_second">
                {highMarkData &&
                  highMarkData?.data?.account_information?.Active?.map(
                    (account, index) => (
                      <div key={index}>
                        <div
                          className="heagin_first"
                    
                     
                        >
                          <p
                            className="para"
                            
                          >
                            Active Account {index + 1}
                            <span>
                              <button
                                className={
                                  index === 1 ? "personal_btn" : "consumer_btn"
                                }
                              >
                                {index === 1
                                  ? "Personal Loan"
                                  : "Consumer Loan"}
                              </button>
                            </span>
                          </p>
                        </div>
                        <div className="third_div_account">
                          <div className="first_div">
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Credit Grantor{" "}
                              </span>
                              <span className="span_data">
                                : {account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Account Number
                              </span>
                              <span className="span_data">
                                : {account.account_number}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Ovwnership</span>
                              <span className="span_data">
                                : {account.ownership_ind}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Credit Limit</span>
                              <span className="span_data">
                                : {account.credit_limit || "-"}
                              </span>
                            </div>
                            {/* <div className="lable_first">
                              <span className="span_lable">Cash Limit</span>
                              <span className="span_data">
                                :{account.cash_limit}
                              </span>
                            </div> */}
                            <div className="lable_first">
                              <span className="span_lable">InstlAmt/Freq</span>
                              <span className="span_data">
                                :{account.installment_amount}
                              </span>
                            </div>

                          </div>
                          <div className="second_div">
                            <div className="lable_first">
                              <span className="span_lable">
                                Last Payment Date{" "}
                              </span>
                              <span className="span_data">
                                : {account.last_payment_date}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Disbursed Date</span>
                              <span className="span_data">
                                : {account.disbursed_date}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Closed Date </span>
                              <span className="span_data">
                                {" "}
                                :{account.closed_date}
                              </span>
                            </div>

                            {/* <div className="lable_first">
                              <span className="span_lable">Tenure(month) </span>
                              <span className="span_data">
                                :{account.tenure_month}
                              </span>
                            </div> */}
                            <div className="lable_first">
                              <span className="span_lable">
                                Disbd Amt/High Credit{" "}
                              </span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.disbursed_amount}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Current Balance
                              </span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.current_balanace}{" "}
                              </span>
                            </div>
                            {/* <div className="lable_first">
                              <span className="span_lable"> Last Paid Amt</span>
                              <span className="span_data">
                                {" "}
                                : {account.last_paid_amonut}
                              </span>
                            </div> */}
                            <div className="lable_first">
                              <span className="span_lable"> Overdue Amt</span>
                              <span className="span_data">
                                {" "}
                                : {account.overdue_amount || "-"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          className="heagin_first"

                        >
                          <p className="para">
                            Payment History
                          </p>
                        </div>
                        <div className="heagin_first">
                          <div
                            className="second_div_employment"
                       
                          >
                            <table className="second_div_employment">
                              <thead>
                                <tr>
                                  <th
                               className="table_thaed_highmark"
                                  >
                                    <div
                                  className="table_thaed_highmark_second"
                                    >
                                      <span
                                    className="year_span_highmark"
                                      >
                                        Year
                                      </span>
                                      <span
                                    className="month_span_highmark"
                                      >
                                        Month
                                      </span>
                                    </div>
                                  </th>

                                  {Object.keys(
                                    account?.combined_payment_history
                                  )?.map(
                                    (year, index) =>
                                      index === 0 &&
                                      Object.keys(
                                        account?.combined_payment_history[year]
                                      )?.map((month) => (
                                        <th
                                         className="Sanction_div_cibil_heading"
                                          key={`${year}-${month}`}
                                        >
                                          {month}
                                        </th>
                                      ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(
                                  account?.combined_payment_history
                                )?.map((year) => (
                                  <tr key={year}>
                                    <td
                                   className="Sanction_div_highmark_td"
                                    >
                                      {year}
                                    </td>
                                    {Object.keys(
                                      account?.combined_payment_history[year]
                                    )?.map((month) => (
                                      <td
                                       className="Sanction_div_cibil_heading"
                                        key={`${year}-${month}`}
                                      >
                                        {account?.combined_payment_history[
                                          year
                                        ][month] || "-"}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  )}

                {highMarkData &&
                  highMarkData?.data?.account_information?.Closed?.map(
                    (account, index) => (
                      <div key={index}>
                        <div
                          className="heagin_second"

                        >
                          <p
                            className="para_closed_highmark"
                         
                          >
                            Closed Account 1
                            <span>
                              <button className="commercial_btn">
                                Commercial Vehicle Loan
                              </button>
                            </span>
                          </p>
                        </div>

                        <div className="third_div_account">
                          <div className="first_div">
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Credit Grantor{" "}
                              </span>
                              <span className="span_data">
                                {" "}
                                : {account.credit_guarantor}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Account Number
                              </span>
                              <span className="span_data">
                                :{account.account_number}{" "}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Ovwnership</span>
                              <span className="span_data">
                                : {account.ownership_ind}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Credit Limit</span>
                              <span className="span_data">
                                :{account.credit_limit}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Cash Limit</span>
                              <span className="span_data">:</span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">InstlAmt/Freq</span>
                              <span className="span_data">
                                :{account.installment_amount}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Status</span>
                              <span className="span_data">
                                : {account.account_status}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Settlement Amt</span>
                              <span className="span_data">
                                : {account.settlement_amt || "-"}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Disbursed Date</span>
                              <span className="span_data">
                                : {account.disbursed_date}
                              </span>
                            </div>
                          </div>
                          <div className="second_div">
                            <div className="lable_first">
                              <span className="span_lable">
                                Last Payment Date{" "}
                              </span>
                              <span className="span_data">
                                : {account.last_payment_date}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">Closed Date </span>
                              <span className="span_data">
                                {" "}
                                :{account.closed_date}
                              </span>
                            </div>

                            <div className="lable_first">
                              <span className="span_lable">Tenure(month) </span>
                              <span className="span_data">
                                :{account.original_term}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                Disbd Amt/High Credit{" "}
                              </span>
                              <span className="span_data">
                                {" "}
                                : {account.amt_hight}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Current Balance
                              </span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.current_balanace}{" "}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable"> Overdue Amt</span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.overdue_amount}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                Principal Writeoff Amt
                              </span>
                              <span className="span_data">
                                {" "}
                                : ₹ {account.principal_write_off_amt || "-"}
                              </span>
                            </div>
                            <div className="lable_first">
                              <span className="span_lable">
                                {" "}
                                Total Writeoff Amt
                              </span>
                              <span className="span_data">
                                {" "}
                                :{account.write_off_amount || "-"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          className="heagin_first"

                        >
                          <p className="para" >
                            Payment History
                          </p>
                        </div>
                        <div className="heagin_second_first">
                          <div
                            className="main_div_cibil_second second_div_employment"
                            
                          >
                            <table className="second_div_employment">
                              <thead>
                                <tr>
                                  <th
                                  
                                    className="table_thaed_highmark"
                                  >
                                    <div
                          
                                      className="table_thaed_highmark_second"
                                    >
                                      <span
                                   
                                        className="year_span_highmark"
                                      >
                                        Year
                                      </span>
                                      <span
                                      className="month_span_highmark"
                                    
                                      >
                                        Month
                                      </span>
                                    </div>
                                  </th>
                                  {Object.keys(
                                    account?.combined_payment_history
                                  )?.map(
                                    (year, index) =>
                                      index === 0 &&
                                      Object.keys(
                                        account?.combined_payment_history[year]
                                      )?.map((month) => (
                                        <th className="Sanction_div_cibil_heading"
                                     
                                          key={`${year}-${month}`}
                                        >
                                          {month}
                                        </th>
                                      ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(
                                  account?.combined_payment_history
                                )?.map((year) => (
                                  <tr key={year}>
                                    <td
                                    
                                      className="Sanction_div_highmark_td"
                                    >
                                      {year}
                                    </td>
                                    {Object.keys(
                                      account?.combined_payment_history[year]
                                    )?.map((month) => (
                                      <td
                                        className="Sanction_div_cibil_heading"
                                        key={`${year}-${month}`}
                                      >
                                        {account?.combined_payment_history[
                                          year
                                        ][month] || "-"}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>

         
            <div className="div2_container" >
              <div className="div2">
                <div className="Appendix_div">
                  <p className="para_highmark" >
                    Appendix
                  </p>
                </div>
                <div className="main_div_cibil_second">
                  <Table
                    className="highmark_table"
                    columns={appendix_column}
                    dataSource={appendix_data}
                    pagination={false}
                  />
                  <div className="disclaimer_div">
                    Disclaimer: This document contains proprietary information
                    to CRIF High Mark and is prepared by using the proprietary
                    match logic of CRIF High Mark may not be used or disclosed
                    to others, except with the written permission of CRIF High
                    Mark. Any paper copy of this document will be considered
                    uncontrolled. If you are not the intended recipient, you are
                    not authorized to read, print, retain, copy, disseminate,
                    distribute, or use this information or any part thereof.
                    PERFORM score provided in this document is joint work of
                    CRIF SPA (Italy) and CRIF High Mark (India).
                  </div>
                </div>
              </div>
            </div>
            <div

              className="italicfont_div_coniantenr"
            >
              <div
                className="italicfont_div"

              >
                <h4>

                  <span className="italicfont">CHM Ref Number:</span>
                  VAST240325CR261986949
                </h4>
                <h4>
                  <span className="italicfont"> PREPARED FOR:</span>VASTU
                  HOUSING
                </h4>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
