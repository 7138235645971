import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const pincodeCodeService = (pincode) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/pincode/get-data-by-pincode/?name=${pincode}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };