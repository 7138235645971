/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Tooltip, message, Select, Button, Drawer, Skeleton } from "antd";
import { QuestionCircleOutlined, MessageOutlined } from "@ant-design/icons";
import "../styles/loanApplication.css";
import CustomerDetails from "../../customerTabDetails/customerDetails/views/CustomerDetails";
import {
  getLoanDetailsById,
  getLoanDetailsByLoanId,
  getAllStages,
  getMasterStages,
  getStageHistory,
  getAllUserHaveAccess,
  getConversationIdByLoanId,
} from "../services/loanapplicationService";
import LoanDetails from "../../loanTabDetails/loanDetails/views/LoanDetails";
import { useParams } from "react-router-dom";
import LoanApplicationDetails from "./LoanApplicationDetails";
import Header from "../../../layout/views/Header";
import "viewerjs-react/dist/index.css";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import "../../loanSummary/styles/loanApplication.css";
import LegalInitiationForm from "../initiationForms/LegalInitiationForm/views/LegalInitiationForm";
import FIInitiationForm from "../initiationForms/FIInitiationForm/views/FIInitiationForm";
import RCUInitiationForm from "../initiationForms/RCUInitiationForm/views/RCUInitiationForm";
import PDInitiationForm from "../initiationForms/PDInitiationForm/views/PDInitiationForm";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../utils/authorization/AuthorizeComponent";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import TechnicalInitiationForm from "../initiationForms/TechnicalInitiatonForm/views/TechnicalInitiationForm";
import LoanDetailMainCard from "./LoanDetailMainCard";
import PrincipleInitiationForm from "../initiationForms/PricipleInititationForm/views/PrincipleInitiationForm";
import FinalApproval from "../initiationForms/FinalApprovalInitiationForm/views/FinalApproval";
import { FloatButton } from "antd";
import CaseHealthSummary from "../../caseHealthSummary/views/CaseHealthSummary";
import { AiOutlineVerticalAlignTop } from "react-icons/ai";
import baseConfig from "../../../../config/baseConfig";
import useWebSocket from "react-use-websocket";
import "react-toastify/dist/ReactToastify.css";
import RejectForm from "../initiationForms/RejectInitiationForm/views/RejectForm";
import FloatingWindow from "../../../chatbot/views/FloatingWindow";
const { Option } = Select;

const LoanApplication = () => {
  const { encrypted_loan_id } = useParams();
  const { user_data } = useSelector((state) => state.user);
  const [loanDetails, setLoanDetails] = useState(null);
  const [, setLoanDetailsByLoanId] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isFloatingWindowVisible, setIsFloatingWindowVisible] = useState(false);
  const [allStages, setAllStages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [title, setTitle] = useState("");
  const [userNameList, setUserNameList] = useState([]);
  const [isDocumentsDrawerVisible, setDocumentsDrawerVisible] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [masterStages, setMasterStages] = useState(null);
  const [refreshLoanDetailMainCard, setRefreshLoanDetailMainCard] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [agencyCode, setAgencyCode] = useState("");
  const handleRefresh = () => {
    // This changes the state, which will cause the component to re-render
    setRefreshLoanDetailMainCard((prev) => !prev);
  };
  const token = localStorage.getItem("access");
  const newUrl = conversationId
    ? `${baseConfig.socketurl}chat/${conversationId}/?Authorization=${token}`
    : null;

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    newUrl,
    {
      share: false,
      shouldReconnect: () => true,
    }
  );

  const fetchUserList = async () => {
    const response = await getAllUserHaveAccess(loan_id);
    const userNameList = response?.data
      ?.filter((user) => user?.name) // Filter users with valid names
      .map((user) => ({
        id: user.id, // Assuming `id` exists
        name: String(user.name).toLowerCase(), // Ensure it's a string and convert to lowercase
        profile_img: user.profile_img || "https://via.placeholder.com/30", // Provide a default image if missing
      }));

    setUserNameList(userNameList);
  };
  const showDocumentsDrawer = () => {
    setDocumentsDrawerVisible(true);
  };

  const closeDocumentsDrawer = () => {
    setDocumentsDrawerVisible(false);
  };

  const showChatDrawer = () => {
    setIsFloatingWindowVisible(true);
  };

  const handleSelectStage = (value) => {
    const getCode = allStages?.find((item) => item.display_name === value);
    if (getCode.agency_code) {
      setAgencyCode(getCode?.agency_code.code);
    }

    setSelectedStage(value);
  };

  const showFormDrawer = () => {
    if (!selectedStage) {
      message.error("Please select a stage before proceeding.");
      return;
    }
    setOpen(true);
  };

  const formOnClose = () => {
    setOpen(false);
    setSelectedStage(null);
    // fetchStages();
    // setAllStages(null);
  };

  const fetchStages = async () => {
    setLoading(true);
    try {
      const allStagesListResponse = await getAllStages(loan_id);
      const currentStageResponse = await getStageHistory(loan_id);
      const masterStagesResponse = await getMasterStages();

      let response = currentStageResponse.data;
      let stages = masterStagesResponse.data;
      const insertCode = {
        Initiated: "section_initiated",
        Completed: "section_completed",
        "Vendor Completed": "section_initiated",
        "Re - Initiated": "section_reinitiated",
        Query: "section_query",
        Hold: "section_hold",
      };

      const priority = [
        "Initiated",
        "Re - Initiated",
        "Query",
        "Hold",
        "Completed",
        "Cancelled",
      ];

      // First, create a dictionary to track the highest priority status for each stage
      let stagePriority = {};

      // Go through the response array to determine the highest priority status for each stage
      response.forEach((item) => {
        const stageCode = item.stage_code;
        const status = item.status;
        if (stagePriority[stageCode]) {
          // Compare the current status priority with the existing one
          const currentPriorityIndex = priority.indexOf(
            stagePriority[stageCode]
          );
          const newPriorityIndex = priority.indexOf(status);
          if (newPriorityIndex < currentPriorityIndex) {
            stagePriority[stageCode] = status;
          }
        } else {
          stagePriority[stageCode] = status;
        }
      });

      // Now, modify the stages array by adding the class_name key based on the highest priority status
      stages.forEach((stage) => {
        const stageCode = stage.code;
        if (stagePriority[stageCode]) {
          const status = stagePriority[stageCode];
          if (insertCode[status]) {
            stage.class_name = insertCode[status];
          }
        }
      });
      setMasterStages(stages);
      setAllStages(allStagesListResponse.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchConversationIdByLoanId = async () => {
      try {
        const response = await getConversationIdByLoanId(loan_id);
        setConversationId(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchConversationIdByLoanId();
  }, []);
  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const response = await getLoanDetailsById(loan_id);
        setLoanDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const fetchLoanDetailsByLoanId = async () => {
      try {
        const response = await getLoanDetailsByLoanId(loan_id);
        setLoanDetailsByLoanId(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchUserList();
    fetchLoanDetails();
    fetchLoanDetailsByLoanId();
    fetchStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan_id, refreshLoanDetailMainCard]);

  useEffect(() => {
    // Trigger a toast whenever useEffect is called
    const checkIsTaggedUser = lastJsonMessage?.message?.content
      ? lastJsonMessage?.message?.content.includes("@")
      : false;
    const matches = lastJsonMessage?.message?.content
      ? lastJsonMessage?.message?.content.match(/@(\d+)/g)
      : [];

    const matchedUser = matches?.map((match) => match.slice(1)); // Slice to remove '@'

    // Ensure matchedUser is an array before checking for inclusion
    if (checkIsTaggedUser) {
      // Filter the users whose IDs are present in the matchedUser array
      const usersToReplace = matchedUser
        ?.map((userId) => {
          return userNameList.find((user) => user.id === Number(userId));
        })
        ?.filter(Boolean); // Filter out undefined values

      // Replace each ID with the respective user name
      let updatedMessageContent = lastJsonMessage?.message?.content;

      let matchingUser = null; // Track the matching user

      usersToReplace?.forEach((user) => {
        if (user && user.id === user_data?.id) {
          // Only replace the matched ID with the user name if it matches user_data.id
          updatedMessageContent = updatedMessageContent.replace(
            `@${user.id}`,
            user.name
          );
          matchingUser = user; // Store the matching user for the toast
        }
      });

      if (matchingUser) {
        toast.info(
          <span>
            <strong>
              {lastJsonMessage?.message?.content.replace(/@\d+/g, "").trim()}
            </strong>
          </span>,
          {
            position: "bottom-center",
            autoClose: 9000,
          }
        );
      }
    }
  }, [lastJsonMessage]);

  const showDrawer = async () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    // fetchStages();
  };

  const handleChildSubmit = () => {
    fetchStages(); // Call the fetch function directly
  };

  const handleScrollToSection = (e) => {
    if (e) e.preventDefault(); // Ensure event is passed and prevent default behavior
    document
      .getElementById("loan_details")
      .scrollIntoView({ behavior: "smooth" });
  };

  const CustomTooltip = () => {
    return (
      <div>
        <div className="loan_status_tooltip">
          Loan Application Progress - Colour Code Reference
        </div>
        <div className="tooltip-content">
          <div className="loan_tooltip_color_status_block">
            <div
              className="color-code loan_tooltip_complete_status"
            ></div>
            <div>Completed</div>
          </div>
          <div className="loan_tooltip_color_status_block">
            <div
              className="color-code loan_tooltip_initiated_status"
            ></div>
            <div>Initiated</div>
          </div>
          {/* <div className="loan_tooltip_color_status_block">
            <div
              className="color-code"
            ></div>
            <div>Cancelled</div>
          </div> */}
          <div className="loan_tooltip_color_status_block">
            <div
              className="color-code loan_tooltip_pending_status"
            ></div>
            <div>Pending</div>
          </div>
          <div className="loan_tooltip_color_status_block">
            <div
              className="color-code loan_tooltip_query_status"
            ></div>
            <div>Query</div>
          </div>
          <div className="loan_tooltip_color_status_block">
            <div
              className="color-code loan_tooltip_void_status"
            ></div>
            <div>Void</div>
          </div>
        </div>

      </div>
    );
  };
  React.useEffect(() => {
    const loanNumber = loanDetails?.loan_account_number
      ? ` (${loanDetails.loan_account_number})`
      : "";
    switch (selectedStage) {
      case "Legal":
        setTitle(`Legal ${loanNumber}`);
        break;
      case "In Principle Approval":
        setTitle(`In Principle Approval ${loanNumber}`);
        break;
      case "Final Approval":
        setTitle(`Final Approval ${loanNumber}`);
        break;
      case "FI":
        setTitle(`FI ${loanNumber}`);
        break;
      case "RCU":
        setTitle(`RCU ${loanNumber}`);
        break;
      case "PD":
        setTitle(`PD ${loanNumber}`);
        break;
      case "Technical":
        setTitle(`Technical ${loanNumber}`);
        break;
      case "Rejected":
        setTitle(`Rejected ${loanNumber}`);
        break;
      default:
        setTitle("");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage]);

  return (
    <div id="loan_details">
      <Drawer
        title={
          <Header title={"Initiate"} onClose={formOnClose} name={`${title}`} />
        }
        width={950}
        onClose={formOnClose}
        open={open}
        closable={false}
      >
        {selectedStage === "Legal" && (
          <LegalInitiationForm
            closeForm={formOnClose}
            open={open}
            agencyCode={agencyCode}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "FI" && (
          <FIInitiationForm
            closeForm={formOnClose}
            open={open}
            agencyCode={agencyCode}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "RCU" && (
          <RCUInitiationForm
            loan_id={loan_id}
            open={open}
            closeForm={formOnClose}
            agencyCode={agencyCode}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "PD" && (
          <PDInitiationForm
            closeForm={formOnClose}
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "Technical" && (
          <TechnicalInitiationForm
            closeForm={formOnClose}
            open={open}
            agencyCode={agencyCode}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "In Principle Approval" && (
          <PrincipleInitiationForm
            closeForm={formOnClose}
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "Final Approval" && (
          <FinalApproval
            closeForm={formOnClose}
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
        {selectedStage === "Rejected" && (
          <RejectForm
            closeForm={formOnClose}
            open={open}
            setSubmitStageBar={handleChildSubmit}
          />
        )}
      </Drawer>

      <LoanDetailMainCard refresh={refreshLoanDetailMainCard} />
      <div className="scroll-button">
        <Tooltip title={<div>Chats</div>} placement="top">
          <FloatButton
            icon={<MessageOutlined />}
            onClick={showChatDrawer}
            style={{ marginRight: "120px" }}
          />
        </Tooltip>

        <Tooltip title={<div>Documents</div>} placement="top">
          <FloatButton onClick={showDocumentsDrawer} className="float_button" />
        </Tooltip>

        <a href="#loan_details" className="scroll-button">
          <Button
            shape="circle"
            icon={<AiOutlineVerticalAlignTop />}
            onClick={handleScrollToSection}
            className="float_Button_two"
          />
        </a>

        <Drawer
          title={
            <Header
              title=<div className="home_application_summary_heading">
                Application Summary
              </div>
              onClose={closeDocumentsDrawer}
              visible={isDocumentsDrawerVisible}
            />
          }
          width={1100}
          onClose={closeDocumentsDrawer}
          open={isDocumentsDrawerVisible}
          body-Style={{ paddingBottom: 80 }}
          closable={false}
          loan_id={loan_id}
        >
          <CaseHealthSummary loan_id={loan_id} />
        </Drawer>

        {isFloatingWindowVisible && 
        <FloatingWindow 
           loan_id={loan_id}
           sendJsonMessage={sendJsonMessage}
           lastJsonMessage={lastJsonMessage}
           readyState={readyState}
           conversationId={conversationId}
        />}
      </div>

      <Drawer
        title={
          <Header
            title="View"
            onClose={onCloseDrawer}
            name="Application Stages"
          />
        }
        width={1050}
        onClose={onCloseDrawer}
        open={drawerVisible}
        closable={false}
      >
        <LoanApplicationDetails
          open={drawerVisible}
          setSubmitStageBar={handleChildSubmit}
          loan_details={loanDetails}
        />
      </Drawer>

      <div className="loan_application_progress">
        <div className="loan_application_progress_content">
          <div className="left_content">
            <p className="tooltip-text">Loan Application Progress</p>
            <Tooltip
              title={<CustomTooltip />}
              overlayClassName="custom-tooltip"
            >
              <QuestionCircleOutlined className="qustion_circle_outline" />
            </Tooltip>
            <Button type="link" className="stage_button" onClick={showDrawer}>
              <u> View Detailed Progress </u>
            </Button>
          </div>
          <div className="right_content">
            <Select
              placeholder="Select Stage "
              className="select_stage"
              onChange={handleSelectStage}
              value={selectedStage}
            >
              {allStages
                ?.sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map((stage, index) => {
                  return (
                    <Option key={index} value={stage.display_name}>
                      {stage.display_name}
                    </Option>
                  );
                })}
            </Select>
            <Button
              onClick={showFormDrawer}
              type="primary"
              className="go_button_div"
            >
              Go
            </Button>
          </div>
        </div>

        <div className="container">
          <Skeleton active loading={loading}>
            {masterStages?.map((stage, index) => {
              const stageClass = stage?.class_name || "section_default";
              return (
                <div
                  key={index}
                  className={`section ${stageClass}`}
                  onClick={showDrawer}
                >
                  {stage.stage}
                </div>
              );
            })}
          </Skeleton>
        </div>
      </div>

      <ConditionalRender
        userPermissions={user_data?.permissions}
        requiredPermissions={["VWCUSTMR"]}
      >
        <CustomerDetails loanDetails={loanDetails} onRefresh={handleRefresh} />
      </ConditionalRender>

      <ConditionalRender
        userPermissions={user_data?.permissions}
        requiredPermissions={["VWLOANID"]}
      >
        <LoanDetails loanDetails={loanDetails} onRefresh={handleRefresh} />
        {/* <PartDisbursedTable/> */}
      </ConditionalRender>
      <ToastContainer />
    </div>
  );
};

export default LoanApplication;
