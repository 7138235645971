import { axiosRequest } from "../../../../../utils/api/axiosRequest";
export const getPartnerDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/all-business-patner-details/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getPartnerDetailsByLoanIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getPartnerDetailsyIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/business-partner-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updatePertnerDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/customer/business-partner-detail/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllComaonyList = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/get-list-of-comapny/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const createPartnerService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/business-partner-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deletePartnerDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/customer/business-partner-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listRelationType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/relationship`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};