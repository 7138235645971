import { axiosRequest } from "../../../utils/api/axiosRequest";

export const getAllUserservice = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;
      let path = `/api/v1/user/get-all-users?page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {
        path = `/api/v1/user/get-all-users?search=${searchQuery}&page=${page}&limit=${limit}`;
      }
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDepartmentListService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/master/departments/list"
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDesignationListService = (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `api/v1/master/designations/get-designations-by-department?department_id=${value}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLocationListService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get("/api/v1/master/locations/list");
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getZoneListService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get("/api/v1/master/zones/list");
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

////create user personal details
export const createUserPersonalDetailsService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        "/api/v1/user/register-user",
        data
      );
      return resolve(response.data);
      // console.log(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
////get user personal details
export const getUserPersonalDetailsService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/user/get-personal-details/${id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

////get user  details by id
export const getUserDetailsServicebyId = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
/////update user personal details
export const updateUserPersonalDetailsService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/user/edit-user/${id}`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

/////update user roles  details by id
export const updateUserRolesDetailsServicebyId = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/user/asssign-remove-roles-to-user/${id}`,
        {
          roles: data,
        }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

/////update user permissions  details by id
export const updateUserPermissionsDetailsServicebyId = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/user/asssign-remove-permissions-to-user/${id}`,
        {
          permissions: data,
        }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllUserAssignedRolesAndPermissions = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/user/get-user-roles-permissions?id=${id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

/////update user zones  details by id
export const updateUserZonesDetailsServicebyId = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/user/asssign-remove-zones-to-user/${id}`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

/////update user locations  details by id
export const updateUserLocationsDetailsServicebyId = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/user/asssign-remove-locations-to-user/${id}`,

        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

/////update user work details
export const updateUserWorkDetailsService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/user/work-details/${id}`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

/////get workdetails by id
export const getUserWorkDetailsService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/user/work-details/${id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getUserByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateUserByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(
        `/api/v1/user/edit-user/${id}`,
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const deleteUserByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(
        `/api/v1/user/delete-user/${id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllRolesService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      page = page ? page : 1;
      limit = limit ? limit : 10;
      let path = `/api/v1/master/roles/?page=${page}&limit=${limit}`;
      if (searchQuery.length > 0) {
        path = `/api/v1/master/roles/?search=${searchQuery}&page=${page}&limit=${limit}`;
      }
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllRoleService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/master/roles/list`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllDesignationService = (page, limit, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/master/designations/list`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const listPermissionsService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/master/permissions/list`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllZoneService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/master/zones/get-all-zones-locations/"
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const checkMobileNumberService = (mobile,id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let api_path = `/api/v1/user/checkmobile?mobile=${mobile}`
      if(id !== null ) {
        api_path += `&user_id=${id}`
      }
      const response = await axiosRequest.get(api_path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const checkMobileEmailService = (email,id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let api_path = `/api/v1/user/checkemail?email=${email}`
      if(id !== null ) {
        api_path += `&user_id=${id}`
      }  
      const response = await axiosRequest.get(api_path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllPermissionsFormatted = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/master/permissions/permission-by-module-name/"
      );

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllRolesFormatted = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/master/roles/list-formatted-roles/"
      );

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getUserAssignedRolesAndPermissions = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/user/get-user-roles-permissions"
      );

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getUserList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        "/api/v1/user/get-user-list"
      );

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listGenderService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/choices/gender`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listOfUsersService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user-list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listOfAuthorityLevelService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/choices-api/authority-level`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

// export const listUserService = () => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axiosRequest.get(`/api/v1/user/filter_user_list`);
//       return resolve(response.data);
//     } catch (error) {
//       return reject(error);
//     }
//   });
// };