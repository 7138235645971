import React, { useState } from "react";
import { Table, Typography, Space, Input, Select, Drawer } from "antd";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import Header from "../../../../../../layout/views/Header";
import CersaiView from "./CersaiView";
const { Option } = Select;

const SecuritySatisfactionPendingList = () => {
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    // form.resetFields();
  };

  const dataSource = [
    {
      key: "1",
      sr_no: 1,
      loan_no: "LN123456",
      customer: "John Doe",
      branch: "New York",
      status: "Pending",
    },
    {
      key: "2",
      sr_no: 2,
      loan_no: "LN654321",
      customer: "Jane Smith",
      branch: "Los Angeles",
      status: "Pending",
    },
    {
      key: "3",
      sr_no: 3,
      loan_no: "LN789012",
      customer: "Alice Johnson",
      branch: "Chicago",
      status: "Pending",
    },
    {
      key: "4",
      sr_no: 4,
      loan_no: "LN345678",
      customer: "Bob Brown",
      branch: "Houston",
      status: "Pending",
    },
    {
      key: "5",
      sr_no: 5,
      loan_no: "LN901234",
      customer: "Sara White",
      branch: "Phoenix",
      status: "Pending",
    },
    {
      key: "6",
      sr_no: 6,
      loan_no: "LN567890",
      customer: "Mike Green",
      branch: "Philadelphia",
      status: "Pending",
    },
    // Add more entries as needed
  ];

  const filelistdata = [
    {
      key: "1",
      sr_no: 1,
      generation_date: "24-10-2022",
      generated_file: "CUST12345-2024-001 ",
      reference_no: "0987654321",
      response_file: "CERSAI_Batch_Report",
      emp_name: "Kalpita Ninave",
    },
  ];

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Laon No.",
      dataIndex: "loan_no",
      key: "loan_no",
      render: (text) => text || "-",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text) => text || "-",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <EyeOutlined 
            onClick={() => {
            //   setSelectedCharges(record);
              showDrawer();
            }}
          />
        </Space>
      ),
    },
  ];

  const filelist = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Generation Date",
      dataIndex: "generation_date",
      key: "generation_date",
      render: (text) => text || "-",
    },
    {
      title: "Generated File",
      dataIndex: "generated_file",
      key: "generated_file",
      render: (text) => text || "-",
    },
    {
      title: "Reference No.",
      dataIndex: "reference_no",
      key: "reference_no",
    },
    {
      title: "Response File",
      dataIndex: "response_file",
      key: "response_file",
    },
    {
      title: "Emp Name",
      dataIndex: "emp_name",
      key: "emp_name",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <DownloadOutlined
          //   onClick={() => {
          //     setSelectedCharges(record);
          //     showDrawer();
          //   }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Drawer
        title={
          <Header
            title={"View"}
            onClose={onClose}
            name="Cersai Details"
          />
        }
        width={1000}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <CersaiView
        //   refreshData={toggleRefreshTableData}
        //   id={selectedCharges && selectedCharges?.id}
          open={open}
          closeForm={onClose}
        //   isEditMode={!!selectedCharges}
        />
      </Drawer>
      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Title level={4} style={{ margin: 0 }}>
          Pending List
        </Title>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span>Filter:</span>
          <Select defaultValue="All" style={{ width: 120 }}>
            <Option value="all">All</Option>
            <Option value="pending">Without Errors</Option>
            <Option value="approved">With Errors(Only CERSAI)</Option>
            <Option value="rejected">Rejected</Option>
          </Select>

          <span>Search:</span>
          <Input placeholder="Enter search text" style={{ width: 200 }} />
        </div>
      </Space>

      <Table dataSource={dataSource} columns={columns} pagination={true} />

      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Title level={4} style={{ margin: 0 }}>
          File List
        </Title>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span>Search:</span>
          <Input placeholder="Enter search text" style={{ width: 200 }} />
        </div>
      </Space>

      <Table dataSource={filelistdata} columns={filelist} pagination={true} />
    </div>
  );
};

export default SecuritySatisfactionPendingList;
