import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Space, message } from "antd";
import * as Yup from "yup";
import { getPropertyTechnicalDataByIdService, updatePropertyDetailsByIdService } from "../services/valuationDetailsServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const validationSchema = Yup.object().shape({
    land_value: Yup.string()
        .required("Government guideline/circle rate for land is required")
        .label("Government guideline/circle rate for land"),
    land_value_government: Yup.string()
        .required("Land value as per government rate is required")
        .label("Land value as per government rate"),
    government_rate_for_bldg: Yup.string()
        .required("Government guideline/circle rate for building is required")
        .label("Government guideline/circle rate for building"),
    apt_value_gov: Yup.string()
        .required("Flat/apartment value as per government is required")
        .label("Flat/apartment value as per government"),
    total_valuation_goverment: Yup.string()
        .required("Total valuation of property as per government is required")
        .label("Total valuation of property as per government"),
    valuation_remark: Yup.string()
        .required("Remarks are required")
        .label("Remarks")
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const ValuePropertyDetailsForm = ({ open, refreshData, closeForm, stage_id }) => {
    const [form] = Form.useForm();
    const [technicalid, setTechnicalid] = useState();

    const getPersonalData = async () => {
        try {
            const response = await getPropertyTechnicalDataByIdService(stage_id);
            setTechnicalid(response.data.technical_detail[0].id)
            let {
                land_value,
                land_value_government,
                government_rate_for_bldg,
                apt_value_gov,
                total_valuation_goverment,
                valuation_remark
            } = response?.data?.technical_detail[0];

            form?.setFieldsValue({
                land_value,
                land_value_government,
                government_rate_for_bldg,
                apt_value_gov,
                total_valuation_goverment,
                valuation_remark
            });
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {

        if (open) {
            getPersonalData();
        } else {
            form?.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, form, stage_id]);

    const handleSubmit = async (values) => {
        const payload = {
            ...values
        };

        try {
            const response = await updatePropertyDetailsByIdService(technicalid, payload);
            if (response && response?.data) {
                if (response?.status === 200 && response?.success) {
                    message.success("Property values updated successfully");
                    refreshData();
                    closeForm();
                }
            } else {
                message.error("Failed to update As Per Govt. Value Of Property");
            }
        } catch (error) {
            closeForm();
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    const handleNumberFields = (e, field) => {
        // Get the value from the input
        let newValue = e.target.value;

        // Remove all characters except digits and decimal points
        newValue = newValue.replace(/[^0-9.]/g, "");

        // Ensure that only one decimal point is allowed
        const decimalCount = (newValue.match(/\./g) || []).length;
        if (decimalCount > 1) {
            newValue = newValue.replace(/\.(?=.*\.)/g, ""); // Remove all but the last decimal point
        }

        // Ensure the value does not exceed 10 characters (including decimal point)
        if (newValue.length > 10) {
            const [integerPart, decimalPart] = newValue.split('.');
            const truncatedIntegerPart = integerPart.slice(0, 10);
            const truncatedDecimalPart = decimalPart ? decimalPart.slice(0, 10) : '';

            newValue = truncatedDecimalPart
                ? `${truncatedIntegerPart}.${truncatedDecimalPart}`
                : truncatedIntegerPart;
        }
        form.setFieldsValue({ [field]: newValue });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                land_value: "",
                land_value_government: "",
                government_rate_for_bldg: "",
                apt_value_gov: "",
                total_valuation_goverment: "",
                valuation_remark: "",
            }}
            onFinish={handleSubmit}
        >
            <Row gutter={16} className="valuation_certification_row">
                <Col span={8}>
                    <Form.Item
                        label="Government guideline/circle rate for land(₹ Per Sq.ft)"
                        required
                    >
                        <Input.Group compact>
                            <Form.Item
                                noStyle
                            >
                                <Input
                                    className="valuation_certification_row_input"
                                    disabled
                                    defaultValue="₹"
                                />
                            </Form.Item>
                            <Form.Item name="land_value" rules={[yupSync]} noStyle>
                                <Input
                                    className="value_property_detail_input_second"
                                    placeholder="Enter Government guideline/circle rate for land(₹Per Sq.ft)" onChange={(e) => handleNumberFields(e, "land_value")}
                                />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Land value as per govt rate (₹)"
                        required
                    ><br></br>
                        <Input.Group compact>
                            <Form.Item
                                noStyle
                            >
                                <Input
                                    className="valuation_certification_row_input"
                                    disabled
                                    defaultValue="₹"
                                />
                            </Form.Item>
                            <Form.Item name="land_value_government" rules={[yupSync]} noStyle>
                                <Input
                                    className="value_property_detail_input_second"
                                    placeholder="Enter Land value as per govt rate (₹)" onChange={(e) => handleNumberFields(e, "land_value_government")}
                                />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Government guideline/circle rate for building (₹ Per Sq.ft)"
                        required
                    >
                        <Input.Group compact>
                            <Form.Item
                                noStyle
                            >
                                <Input
                                    className="valuation_certification_row_input"
                                    disabled
                                    defaultValue="₹"
                                />
                            </Form.Item>
                            <Form.Item name="government_rate_for_bldg" rules={[yupSync]} noStyle>
                                <Input
                                    className="value_property_detail_input_second"
                                    placeholder="Enter Government guideline/circle rate for building"
                                    onChange={(e) => handleNumberFields(e, "government_rate_for_bldg")}
                                />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16} className="valuation_certification_row">
                <Col span={8}>
                    <Form.Item
                        label="Flat/apartment value as per government rate (₹)"
                        required
                    >
                        <Input.Group compact>
                            <Form.Item
                                // name="phone_country_code"
                                noStyle
                            >
                                <Input
                                    className="valuation_certification_row_input"
                                    disabled
                                    defaultValue="₹"
                                />
                            </Form.Item>
                            <Form.Item name="apt_value_gov" rules={[yupSync]} noStyle>
                                <Input
                                    className="value_property_detail_input_second"
                                    placeholder="Enter Flat/apartment value as per government" onChange={(e) => handleNumberFields(e, "apt_value_gov")}
                                />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Total valuation of property as per government rate"
                        required
                    >
                        <Input.Group compact>
                            <Form.Item
                                // name="phone_country_code"
                                noStyle
                            >
                                <Input
                                    className="valuation_certification_row_input"
                                    disabled
                                    defaultValue="₹"
                                />
                            </Form.Item>
                            <Form.Item name="total_valuation_goverment" rules={[yupSync]} noStyle>
                                <Input
                                    className="value_property_detail_input_second"
                                    placeholder="Enter Total valuation of property as per government"
                                    onChange={(e) => handleNumberFields(e, "total_valuation_goverment")}
                                />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    {/* <Form.Item
                        name="valuation_remark"
                        label="Remarks"
                        rules={[yupSync]}
                        required
                    >
                        <br></br>
                        <Input placeholder="Enter Remarks" />
                    </Form.Item> */}
                    <Form.Item name="valuation_remark" label="Remarks" rules={[yupSync]} required >

                        <Input className="value_property_detail_input" placeholder="Enter Remarks" />
                    </Form.Item>
                </Col>
            </Row>

            <Space
                direction="horizontal"
                align="center"
                className="value_property_detail_space"
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    );
};

export default ValuePropertyDetailsForm;
