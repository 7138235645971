import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const submitRejection = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosRequest.post(`/api/v1/loan/loan-stage-history/`, data);
            return resolve(response.data)
        } catch (error) {
            return reject(error)
        }
    })
}