import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { deleteAgentByIdService } from "../services/agentServices";
import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import AgentFilterForm from "./AgentFilterForm";

import ConditionalRender from "../../../../utils/authorization/AuthorizeComponent";
import authorizeTableColumns from "../../../../utils/authorization/authorizeTableColumns";
import { useSelector } from "react-redux";
import AgentFormController from "./AgentFormController";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const Agent = () => {
  const { Title } = Typography;
  const [agent, setAgent] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  // Filter Agent
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    is_active: null,
    is_verified: null,
  });

  const { user_data } = useSelector((state) => state.user);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedAgent(null);
    setSelectedAgentId(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
      width: 200,
    },

    {
      title: "Personal Email",
      dataIndex: "personal_email",
      sorter: true,
      render: (email) => {
        return email !== null ? email : " -";
      },
      width: 200,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: true,
      render: (mobile) => {
        return mobile !== null ? mobile : " -";
      },
    },

    {
      title: "Agency",
      sorter: true,
      dataIndex: "agency",
      render: (agency) => {
        return agency && agency.name ? agency.name : " - ";
      },
    },
    {
      title: "Work Email",
      dataIndex: "work_email",
      sorter: true,
      render: (email) => {
        return email !== null ? email : " -";
      },
      width: 200,
    },
    {
      title: "Location",
      sorter: true,
      dataIndex: "location",
      render: (location) => {
        return location && location.display_name ? location.display_name : " - ";
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => {
        return record.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => {
        return record.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (_, record) => {
        return record.created_by.name;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => {
        return record.modified_by.name;
      },
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
    },
  ];

  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["AEMASTER"]}
            >
              <EditOutlined
                onClick={() => {
                  setSelectedAgent(record);
                  setSelectedAgentId(record?.id);
                  showDrawer();
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["DLMASTER"]}
            >
              <DeleteOutlined
                onClick={() => {
                  setSelectedAgent(record);
                  setOpenModal(true);
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["AEMASTER", "DLMASTER"]
  );


  const deleteAgent = async () => {
    try {
      const response = await deleteAgentByIdService(selectedAgent.id);
      if (response.status === 200 && response.success === true) {
        message.success("Agent deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedAgent(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Agent
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["VWMASTER"]}
          >
            <Button
              onClick={() => setShowFilterForm(true)}
              icon={<FilterOutlined />}
              style={{ marginLeft: "1rem" }}
            >
              More Filters
            </Button>
          </ConditionalRender>
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["AEMASTER"]}
          >
            <Button
              onClick={showDrawer}
              type="primary"
              icon={<PlusCircleOutlined />}
              style={{ marginLeft: "1rem" }}
            >
              Add Agent
            </Button>
          </ConditionalRender>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/master/agent/"
        tableData={agent}
        setTableData={setAgent}
        tableColumns={tableColumns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}
      />
      <Drawer
        title={
          <Header
            title={selectedAgent ? "Edit" : "Add"}
            onClose={onClose}
            name="Agent"
          />
        }
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <AgentFormController
          refreshData={toggleRefreshTableData}
          id={selectedAgentId}
          open={open}
          closeForm={onClose}
          setId={setSelectedAgentId}
        />
      </Drawer>
      <Drawer
        title={
          <Header title={"Filter"} onClose={closeFilterForm} name="Agent" />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <AgentFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteAgent();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedAgent?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default Agent;
