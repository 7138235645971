import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
  DatePicker,
} from "antd";
import { getPropertyTechnicalDataByIdService } from "../../valuationDetails/services/valuationDetailsServices";
import { updateTechnicalPropertyDetailsByIdService } from "../services/propertyDetailsServices"
import * as Yup from "yup";
import moment from "moment";
import dayjs from "dayjs";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const { TextArea } = Input;

const validationSchema = Yup.object().shape({
  date_of_inspection: Yup.date()
    .max(new Date(), "Date of Inspection/Site Visit must be today or in the past")
    .required("Date of Inspection/Site Visit is required")
    .label("Date Of Inspection"),
  address: Yup.string()
    .required("Property address is required")
    .label("Property Address"),

  current_owner_name: Yup.string()
    .required("Name of current owner/seller is required")
    .label("Name of Current Owner / Seller"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PropertyDetailsForm = ({ refreshData, id, open, closeForm, stage_id }) => {
  const [form] = Form.useForm();
  const [technicalid, setTechnicalid] = useState("");



  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z0-9\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleText = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/[^\d.]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const getPersonalData = async () => {
    try {
      const response = await getPropertyTechnicalDataByIdService(stage_id);
      setTechnicalid(response.data.technical_detail[0].id);
      let {
        loan_detail: { loan_account_number },
        date_of_inspection,
        loan_type: { product_name },
        current_owner_name,
        address,
      } = response?.data?.technical_detail[0];
      date_of_inspection = dayjs(date_of_inspection, "DD-MM-YYYY");
      form?.setFieldsValue({
        loan_account_number,
        date_of_inspection,
        product_name,
        current_owner_name,
        address,
      });
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleSubmit = async (values) => {


    values.date_of_inspection = values.date_of_inspection.format("YYYY-MM-DD");

    const payload = {
      ...values,

    }

    try {

      const response = await updateTechnicalPropertyDetailsByIdService(technicalid, payload);

      if (response && response?.data) {
        if (response?.status === 200 && response?.success) {
          message.success("Property Details updated successfully");
          refreshData();
          closeForm();
        }
      } else {
        message.error("Failed to update Property Details");
      }
      //   }
    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {

    if (open) {
      getPersonalData();
    } else {
      form?.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form]);




  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        loan_account_number: "",
        date_of_inspection: "",
        product: "",
        current_owner_name: "",
        address: ""
      }}
      onFinish={handleSubmit}
    >
      <Row gutter={16} >
        <Col span={8}>
          <Form.Item name="loan_account_number" label="Loan Account No" required>
            <Input placeholder="Please Enter Loan Account No" onChange={(e) => handleNumberFields(e, "loan_account_number")} disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="product_name" label="Product" required>
            <Input placeholder="Please Enter Product" onChange={(e) => handleInput(e, "product")} disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="date_of_inspection"
            label="Date of Inspection / Site visit"
            rules={[{ validator: yupSync.validator, field: "date_of_inspection" }]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"

              className="property_details_select"
              placeholder="Select Date"
              disabledDate={(current) => current && current > moment().endOf('day')}
            />
          </Form.Item>
        </Col>

      </Row>

      <Row gutter={16} >
        <Col span={8}>
          <Form.Item name="current_owner_name" label="Name of Current Owner / Seller" rules={[yupSync]} required>
            <Input placeholder="Please Enter Name of Current Owner / Seller" onChange={(e) => handleText(e, "current_owner_name")} />
          </Form.Item>
        </Col>



        <Col span={8}>
          <Form.Item
            name="address"
            label="Property Address"
            rules={[yupSync]}
            required
          >
            <TextArea
              placeholder="Please Enter Property Address"
              onChange={(e) => handleInput(e, "address")}
              className="text_propert_technical"
              disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        className="cancel_button_property_technical"
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default PropertyDetailsForm;
