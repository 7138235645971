import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import "../styles/SalesForm.css"; // Make sure the CSS path is correct
import { postConvertSalesForm } from "../services/salesformservices";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
 

const SalesFormFinalSubmit = () => {
  // Destructure `loanid` prop

  const [loanid, setLoanid] = useState("");
  const navigate = useNavigate(); // Navigation hook

  const { encrypted_loan_id } = useParams();

  useEffect(() => {
    try {
      setLoanid(decrypt(encrypted_loan_id));
    } catch (error) {
      message.error("Invalid Loan ID");
    }
  }, [encrypted_loan_id]);

  const convertSalesApplication = async () => {
    try {
      // Assuming postConvertSalesForm expects the `loanid`
      const response = await postConvertSalesForm(loanid);

      if (response.status === 200 && response.success) {
        message.success("Files synced successfully");
        navigate("/applications"); // Redirect to applications page
      }
    } catch (error) {
      // Display a proper error message if something goes wrong
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message) ||
          "An unexpected error occurred."
      );
    }
  };

  return (
    <div>
 

      <div className="sales-header-container">
        <div>
          <h1 className="sales-form-header">Sales Application Form</h1>
        </div>
        <div> 
          <Button type="primary" onClick={convertSalesApplication}>
            Convert Lead
          </Button> 
        </div>
      </div>
    </div>
  );
};

export default SalesFormFinalSubmit;
