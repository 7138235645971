import React, { useEffect } from "react";
import { getSalesCustomerData } from "../../services/customerservices";
import { deleteCustomerData } from "../../services/salesformservices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { Divider, message, Tabs, Button, Space } from "antd";
import DocumentCheck from "./DocumentCheck";
import SalesPersonalDetailForm from "../SalesPersonalDetailForm";
import BureauDetailsForm from "../BureauDetailsForm";
import DocumentVerification from "../DocumentVerification";
import SalesFormReferenceForm from "./SalesFormReferenceForm";
import SalesFormExistingForm from "./SalesFormExistingForm";
import SalesFormBankAccountForm from "./SalesFormBankAccountForm";
import SalesFormInvestmentForm from "./SalesFormInvestmentForm";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveCustomer,
  setCustomers,
  deleteCustomer
} from "../../../../redux/salesFormSlice";
import SalesFormAddressForm from "./SalesFormAddressForm";
import SalesFormStepper from "./SalesFormStepper";
import SalesFormDependentForm from "./SalesFormDependentForm";
import SalesFormEmploymentForm from "./SalesFormEmploymentForm";
import SalesFormPartnerForm from "./SalesFormPartnerForm";
import { changeStepperActiveKey } from "../../../../redux/salesFormSlice";
import "../../styles/SalesForm.css"
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';

const { TabPane } = Tabs;

const SalesFormCustomerDetails = () => {
  const dispatch = useDispatch();
  const { customers, activeCustomer,loanId } = useSelector((state) => state.salesForm);

  const salesCustomer = useSelector((state) => state.salesForm.activeCustomer);


  const onChange = (newActiveKey) => {
    dispatch(changeActiveCustomer(newActiveKey));
  }; 

  const currentStep = customers[parseInt(activeCustomer)]?.activeKey; 
  
  const goToPreviousStep = () => {
    if (currentStep > 0) {
      dispatch(changeStepperActiveKey({
        index: activeCustomer,
        value: currentStep - 1
      }));
    } 
  };

  const goToNextStep = () => { 
    if (customerId || currentStep < 1 )
    {
      dispatch(changeStepperActiveKey({
        index: activeCustomer,
        value: currentStep + 1
      })); 
 
    } else {
      message.error("Please fill personal details form")
    }
  };

  const customerId = customers[parseInt(activeCustomer)]?.id;
  const customer = customers[activeCustomer];

  const CustomerDetailsTabs = [
    {
      key: "1",
      title: "Documents Check",
      Content: (
        <DocumentCheck
          activeCustomer={activeCustomer}
          activeKey={currentStep}
          customers={customers}
        />
      ),
    },
    {
      key: "2",
      title: "Personal Details",
      Content: (
        <SalesPersonalDetailForm
          loanId={loanId}
          activeCustomer={activeCustomer}
          activeKey={currentStep}
          customers={customers}
        />
      ),
    },
    {
      key: "3",
      title: "Document Verification",
      Content: (
        <DocumentVerification loanId={loanId} customer_id={customerId} activeKey={currentStep} />
      ),
    },
    {
      key: "4",
      title: "Address Details",
      Content: (
        <SalesFormAddressForm activeCustomer={activeCustomer} loanId={loanId} />
      ),
    },
    {
      key: "5",
      title: "Bureau Details",
      Content: <BureauDetailsForm customer_id={customerId} activeCustomer={activeCustomer}/>
    },
    {
      key: "6",
      title: "Employment Details",
      Content: <SalesFormEmploymentForm activeCustomer={activeCustomer} />,
    },
    {
      key: "7",
      title: "Bank Account Details",
      Content: (
        <SalesFormBankAccountForm
          activeCustomer={activeCustomer}
          loanId={loanId}
        />
      ),
    },
    {
      key: "8",
      title: "Investment Details",
      Content: <SalesFormInvestmentForm activeCustomer={activeCustomer} />,
    },
    {
      key: "9",
      title: "Existing Loan Details",
      Content: <SalesFormExistingForm activeCustomer={activeCustomer} />,
    },
    {
      key: "10",
      title: "Reference Details",
      Content: (
        <SalesFormReferenceForm
          activeCustomer={activeCustomer}
          loanId={loanId}
        />
      ),
    },

    {
      key: "11",
      title: "Dependent Details",
      Content: <SalesFormDependentForm activeCustomer={activeCustomer} />,
    },

    customer?.customer_class === "Company"
    ? {
        key: "12",
        title: "Partner",
        Content: <SalesFormPartnerForm activeCustomer={activeCustomer} />,
      }
    : null
  
  ];

  useEffect(() => {
    if (!loanId) {
      dispatch(
        setCustomers([
          {
            first_name: "",
            middle_name: "",
            last_name: "",
            customer_type: "Applicant",
            id: null,
            customer_type_id: 1,
            activeKey: 0,
            addressDetails: {
              activeAddressKey: 0,
              addresses: [],
              addressapicalled: 0,
            },
            bankDetails: {
              activeBankAccountKey: 0,
              bankaccounts: [],
              bankaccountapicalled: 0,
            },
            investmentDetails: {
              activeInvestmentKey: 0,
              investments: [],
              investmentloanapicalled: 0,
            },
            existingloanDetails: {
              activeExistingLoanKey: 0,
              existingloan: [],
              existingloanapicalled: 0,
            },
            referenceDetails: {
              activeReferenceKey: 0,
              references: [],
              referenceapicalled: 0,
            },
            dependentDetails: {
              activeDependentKey: 0,
              dependents: [],
              dependentapicalled: 0,
            },
            employmentDetails: {
              activeEmploymentKey: 0,
              employments: [],
              employeeapicalled: 0,
            },
            partnerDetails: {
              activePartnerKey: 0,
              partners: [],
              partnerapicalled: 0
            },
          },
        ])
      );
    } else {
      const fetchCustomerData = async () => {
        try {
          const response = await getSalesCustomerData(loanId);
          if (response.success && response.status === 200) {
            // setCustomerList(response?.data?.customer_list);
            if (response?.data?.customer_list?.length === 0) {
              dispatch(
                setCustomers([
                  {
                    first_name: "",
                    middle_name: "",
                    last_name: "",
                    customer_type: "Applicant",
                    id: null,
                    customer_type_id: 1,
                    activeKey: 0,
                    addressDetails: {
                      activeAddressKey: 0,
                      addresses: [],
                      addressapicalled: 0,
                    },
                    bankDetails: {
                      activeBankAccountKey: 0,
                      bankaccounts: [],
                      bankaccountapicalled: 0,
                    },
                    investmentDetails: {
                      activeInvestmenttKey: 0,
                      investments: [],
                      investmentloanapicalled: 0,
                    },
                    existingloanDetails: {
                      activeExistingLoanKey: 0,
                      existingloan: [],
                      existingloanapicalled: 0,
                    },
                    referenceDetails: {
                      activeReferenceKey: 0,
                      references: [],
                      referenceapicalled: 0,
                    },
                    dependentDetails: {
                      activeDependentKey: 0,
                      dependents: [],
                      dependentapicalled: 0,
                    },
                    employmentDetails: {
                      activeEmploymentKey: 0,
                      employments: [],
                      employeeapicalled: 0,
                    },
                    partnerDetails: {
                      activePartnerKey: 0,
                      partners: [],
                      partnerapicalled: 0
                    },
                  },
                ])
              );
            } else {
              const updatedCustomer = response?.data?.customer_list?.map(
                (item) => {
                  return {
                    ...item,
                    activeKey: 0,
                    addressDetails: {
                      activeAddressKey: 0,
                      addresses: [],  
                      addressapicalled: 0,
                    },
                    bankDetails: {
                      activeBankAccountKey: 0,
                      bankaccounts: [],
                      bankaccountapicalled: 0,
                    },
                    investmentDetails: {
                      activeInvestmenttKey: 0,
                      investments: [],
                      investmentloanapicalled: 0,
                    },
                    existingloanDetails: {
                      activeExistingLoanKey: 0,
                      existingloan: [],
                      existingloanapicalled: 0,
                    },
                    referenceDetails: {
                      activeReferenceKey: 0,
                      references: [],
                      referenceapicalled: 0,
                    },
                    dependentDetails: {
                      activeDependentKey: 0,
                      dependents: [],
                      dependentapicalled: 0,
                    },
                    employmentDetails: {
                      activeEmploymentKey: 0,
                      employments: [],
                      employeeapicalled: 0,
                    },
                    partnerDetails: {
                      activePartnerKey: 0,
                      partners: [],
                      partnerapicalled: 0
                    },
                  };
                }
              );
              dispatch(setCustomers(updatedCustomer));
            }
          } else {
            message.error("Failed to fetch customer data.");
          }
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      };
      fetchCustomerData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId]);

  const addNewCustomer = () => {
    dispatch(
      setCustomers([
        ...customers,
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          customer_type: "Applicant",
          id: null,
          customer_type_id: 1,
          activeKey: 0,
          addressDetails: {
            activeAddressKey: 0,
            addresses: [],
            addressapicalled: 0,
          },
          bankDetails: {
            activeBankAccountKey: 0,
            bankaccounts: [],
            bankaccountapicalled: 0,
          },
          investmentDetails: {
            activeInvestmenttKey: 0,
            investments: [],
            investmentloanapicalled: 0,
          },
          existingloanDetails: {
            activeExistingLoanKey: 0,
            existingloan: [],
            existingloanapicalled: 0,
          },
          referenceDetails: {
            activeReferenceKey: 0,
            references: [],
            referenceapicalled: 0,
          },
          dependentDetails: {
            activeDependentKey: 0,
            dependents: [],
            dependentapicalled: 0,
          },
          employmentDetails: {
            activeEmploymentKey: 0,
            employments: [],
            employeeapicalled: 0,
          },
          partnerDetails: {
            activePartnerKey: 0,
            partners: [],
            partnerapicalled: 0
          },
        },
      ])
    );

    dispatch(changeActiveCustomer(customers.length));
  };

  const handleTabEdit = (targetKey, action) => {
    if (action === "remove") {

      const customerid = customers[targetKey].id;

      if (customerid) {
        if (customers.length > 1) {
        const deleteTabById = async () => {
          try {
            const response = await deleteCustomerData(customerid);

            if (response.status === 200 && response.success === true) {
              message.success("Tab deleted successfully"); 
            } else {
              message.error("Failed to delete the tab");
            }
          } catch (error) {
            message.error(error?.response?.data?.message || "Error deleting tab");
          }
        };

        dispatch(deleteCustomer({ indexToDelete: targetKey })); 
      dispatch(changeActiveCustomer(salesCustomer - 1));

        deleteTabById()
      } else {
        message.error("Cannot delete last tab")
      }
      }
      else {
      if (customers.length > 1)
      {
      dispatch(deleteCustomer({ indexToDelete: targetKey })); 
      dispatch(changeActiveCustomer(salesCustomer - 1));
      } else {
        message.error("Cannot delete last tab")
      }
    }
    } else if (action === "add") {
      addNewCustomer();
    }
  };
  

  return (
    <div>
      <Tabs
        type="editable-card"
        onChange={onChange}
        onEdit={handleTabEdit}
        activeKey={String(salesCustomer)}
      >
        {customers.map((customer, index) => (
          <TabPane tab={`Customer ${index + 1}`} key={index}>
            <div className="main_container" style={{ marginTop: "10px" }}>
              <div className="costumer_details_sidebar_container">
                <div className="costumer_details_sidebar_header" style={{ marginTop: "34px" }}>
                  <h3>Timeline</h3>
                </div>
                <Divider />
                <div className="sales-stepper-margin">
                  <SalesFormStepper
                    activeCustomer={activeCustomer}
                    newCurrent={currentStep}
                    items={CustomerDetailsTabs}
                    style={{ cursor: "pointer" }}
                    customerId={customerId}
                    customerKey={index}
                  />
                </div>
              </div>
              <div className="document_header" style={{ borderTop: "1px solid #e4e7ec" }}>
                {CustomerDetailsTabs[currentStep] && (
                  <div>
                    <div className="document_header_items">
                      <h3 style={{ marginTop: "29px" }}>
                        {CustomerDetailsTabs[currentStep].title}
                      </h3>
                      <p>{`${currentStep + 1}/${CustomerDetailsTabs.length}`}</p>
                    </div>
                    <Divider />
                    <div style={{ padding: "20px" }}>
                      {CustomerDetailsTabs[currentStep].Content}
                    </div>
                    
                   
                    <div className="sales-tab-navigation"> 
                    <div className="document_footer_items">
                    
                      <Space
                        direction="horizontal"
                        align="center"

                      >
                        <Button onClick={goToPreviousStep} 
                          disabled={currentStep === 0}
                          type="primary"
                        >
                          <ArrowLeftOutlined/>
                          Previous
                        </Button>
                        <Button
                          onClick={goToNextStep}
                          disabled={currentStep === 10}
                          type="primary"
                        >
                          Next
                          <ArrowRightOutlined/>
                        </Button>
                      </Space>
                    </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default SalesFormCustomerDetails;
