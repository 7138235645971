import { Button, Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons'; // Make sure this import is correct
import React from 'react';

const ActionBox = ({ activeTabKey, handleTabChange, setIsActionBoxVisible }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Button
        icon={<CloseOutlined />}
        onClick={() => {
          // If you have a specific action to close the box, you can call it here
          if (setIsActionBoxVisible) setIsActionBoxVisible(false);  // Example: Close the ActionBox when clicked
        }}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'transparent',
          border: 'none',
          color: '#000',
          zIndex: 10,  // Ensure it's not being overlapped by other elements
        }}
      />
      <Tabs activeKey={activeTabKey} onChange={handleTabChange} style={{ marginTop: '20px' }}>
        <Tabs.TabPane tab="ROI" key="1">
          <div>ROI</div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Events" key="2">
          <div>Events</div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Other" key="3">
          <div>Other</div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default ActionBox;
