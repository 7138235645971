import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  // HistoryOutlined,
  UpOutlined,
  // PlusOutlined,
} from "@ant-design/icons";
import {
  deleteChequeByIdService,
  getBankAccountsByLoanId,
  getChequeByLoanIdService,
} from "../services/PDCService";
import { Button, Modal, Space, Table, message, Drawer } from "antd";
import "../style/PDC.css";
import AddCheque from "./AddCheque";
import Header from "../../../../../layout/views/Header";
import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import AddBankAccount from "./AddBankAccount";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";
import EditCheque from "./EditCheque";
import authorizeTableColumns from "../../../../../../utils/authorization/authorizeTableColumns";
import ConditionalRender from "../../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";
import Loading from "../../../../../../utils/loading/Loading";
import CustomNoData from "../../../../../../utils/noDataIcon/CustomNoData";
const PDC = () => {
  const [collapsed1, setCollapsed1] = useState(false);
  const [pdccollapse, setPDCCollapse] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showAddChequeForm, setShowAddChequeForm] = useState(false);
  const [showEditChequeForm, setShowEditChequeForm] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [chequesData, setChequesData] = useState();
  const [selectedChequesData, setSelectedChequesData] = useState();
  const [bankData, setBankData] = useState(null);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [showBankAccountForm, setShowBankAccountForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const { encrypted_loan_id } = useParams();

  const { user_data } = useSelector((state) => state.user);

  console.log("refreshTableData", refreshTableData)

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const showChequeFormDrawer = () => {
    setShowAddChequeForm(true);
  };
  const showEditChequeFormDrawer = () => {
    setShowEditChequeForm(true);
  };

  const onCloseChequeForm = () => {
    setShowAddChequeForm(false);
    setSelectedBankAccount(null);
    setSelectedChequesData(null);
  };
  const onCloseEditChequeForm = () => {
    setShowEditChequeForm(false);
    setSelectedChequesData(null);
  };
  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const openBankAccountForm = () => {
    setShowBankAccountForm(true);
  };

  const closeBankAccountForm = () => {
    setShowBankAccountForm(false);
  };

  const PDCCollapse = () => {
    setPDCCollapse(!pdccollapse);
  };

  useEffect(() => {
    const fetchCheques = async () => {
      try {
        const response = await getChequeByLoanIdService(loan_id);
        setChequesData(response?.data?.results);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const fetchBankAccounts = async () => {
      try {
        const response = await getBankAccountsByLoanId(loan_id);
        setBankData(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
      finally {
        setLoading(false); // Set loading to false after fetching is done
      }
    };

    fetchBankAccounts();
    fetchCheques();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Bank Name",
      key: "bank",
      render: (record) => {
        return (
          <div
            className="add_bank_account_div"
            onClick={() => handleBankAutofillForm(record)}
          >
            {record?.bank.display_name}
          </div>
        );
      },
    },

    {
      title: "Branch",
      key: "branch",
      render: (record) => {
        return record?.bank_branch.display_name;
      },
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Holder Name",
      dataIndex: "account_holder_name",
      key: "account_holder_name",
    },
    {
      title: "IFSC Code",
      dataIndex: "ifsc_code",
      key: "ifsc_code",
    },
    {
      title: "MICR Code   ",
      dataIndex: "micr_code",
      key: "micr_code",
    },
  ];

  const ChequeColumns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Cheque Number",
      dataIndex: "cheque_number",
      key: "cheque_number",
      render: (text) => text || "-",
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      render: (text) => text || "-",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
      render: (text) => text || "-",
    },
    {
      title: "MICR",
      dataIndex: "micr",
      key: "micr",
      render: (text) => text || "-",
    },
    {
      title: "Type",
      dataIndex: "cheque_type",
      key: "cheque_type",
      render: (text) => text || "-",
    },
    {
      title: "Payable Type",
      dataIndex: "payable_type",
      key: "payable_type",
      render: (text) => text || "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => text || "-",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (text) => text || "-",
    },

  ];

  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITPDCD"]}
            >
              <EditOutlined
                className="edit_icon_pdc"
                onClick={() => {
                  setSelectedChequesData(record);
                  showEditChequeFormDrawer();
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["DELTPDCD"]}
            >
              <DeleteOutlined
                className="delete_icon_pdc"
                onClick={() => {
                  setSelectedChequesData(record);
                  setOpenModal(true);
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    ChequeColumns,
    authorizedColumns,
    user_data?.permissions,
    ["EDITPDCD", "DELTPDCD"]
  );
  const deleteCheque = async () => {
    try {
      const response = await deleteChequeByIdService(selectedChequesData?.id);

      if (response.status === 200 && response.success === true) {
        message.success("Cheque deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedChequesData(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(error.response.data.message);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleBankAutofillForm = (record) => {
    setSelectedBankAccount({
      id: record?.id
    });
    setShowAddChequeForm(true);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = changeableRowKeys.filter(
            (_, index) => index % 2 !== 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = changeableRowKeys.filter(
            (_, index) => index % 2 === 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <div>
      <Drawer
        title={
          <Header
            title="Add"
            onClose={closeBankAccountForm}
            name="Bank Account"
          />
        }
        width={900}
        onClose={closeBankAccountForm}
        open={showBankAccountForm}
        closable={false}
      >
        <AddBankAccount loanId={loan_id} refreshData={toggleRefreshTableData} open={showBankAccountForm} closeForm={closeBankAccountForm} />
      </Drawer>
      <Drawer
        title={
          <Header
            title="Add"
            onClose={onCloseChequeForm}
            name="Cheque Details"
          />
        }
        width={900}
        onClose={onCloseChequeForm}
        open={showAddChequeForm}
        closable={false}
      >
        <AddCheque
          autoFillBankAccount={selectedBankAccount}
          // id={selectedChequesData?.id}
          refreshData={toggleRefreshTableData}
          open={showAddChequeForm}
          closeForm={onCloseChequeForm}
        // selectedRows={selectedRowKeys}
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title="Edit"
            onClose={onCloseEditChequeForm}
            name="Cheque Details"
          />
        }
        width={900}
        onClose={onCloseEditChequeForm}
        open={showEditChequeForm}
        closable={false}
      >
        <EditCheque
          id={selectedChequesData?.id}
          refreshData={toggleRefreshTableData}
          open={showEditChequeForm}
          closeForm={onCloseEditChequeForm}
          selectedRows={selectedRowKeys}
        />
      </Drawer>
      <div className="tab_main_container"  >
        <div >
          <div className="heading_details_container_pdc" >
            <div
              className={`pdc_detail_heading ${collapsed1 ? "no-border" : ""}`}
            >
              <div className="pdc_description_data">Bank Details </div>
              <div
                className="bank_button_pdc"

              >
                <div>
                  <ConditionalRender
                    userPermissions={user_data?.permissions}
                    requiredPermissions={["POSTPDCD"]}
                  >
                    <Button
                      icon={<PlusCircleOutlined />}
                      className="add_pdc_bank"
                      onClick={() => openBankAccountForm()}
                    >
                      Add Bank Account
                    </Button>
                  </ConditionalRender>
                </div>
                <div className="add_bank_account_div" onClick={toggleCollapse1}>
                  {collapsed1 ? <DownOutlined /> : <UpOutlined />}
                </div>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : bankData?.length > 0 ? (
              <>

                {!collapsed1 && (
                  <div className="otc_pdd_table_container">
                    <Table
                      dataSource={bankData}
                      columns={columns}
                      pagination={false}
                      rowKey="id" // Added this line
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="no_data_found_message">
                <CustomNoData />
              </div>
            )}
          </div>
        </div>

        <div className="pdc_details_table_second">
          <div className="heading_details_container_tab_operation">
            <div
              className={`pdc_detail_heading ${collapsed1 ? "no-border" : ""}`}
            >
              <div className="pdc_description_data">PDC</div>
              <div

                className="pdc_div_container"
              >
                <div>
                  <ConditionalRender
                    userPermissions={user_data?.permissions}
                    requiredPermissions={["EDITPDCD"]}
                  >
                    <Button
                      className={`update-cheque-button ${selectedRowKeys.length === 0 ? "disabled" : "enabled"
                        }`}
                      disabled={selectedRowKeys.length === 0}
                      onClick={() => showEditChequeFormDrawer()}
                    >
                      Update Cheque status
                    </Button>
                  </ConditionalRender>
                </div>
                <div>
                  <ConditionalRender
                    userPermissions={user_data?.permissions}
                    requiredPermissions={["POSTPDCD"]}
                  >
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={showChequeFormDrawer}
                      className="add_chequ_button_icon"
                    >
                      Add Cheque
                    </Button>
                  </ConditionalRender>
                </div>
                <div className="add_bank_account_div" onClick={PDCCollapse}>
                  {pdccollapse ? <DownOutlined /> : <UpOutlined />}
                </div>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : bankData?.length > 0 ? (
              <>
                {!pdccollapse && (
                  <div className="otc_pdd_table_container">
                    <Table

                      dataSource={chequesData}
                      columns={tableColumns}
                      pagination={false}
                      rowSelection={rowSelection}
                      rowKey="id" // Added this line
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="no_data_found_message">
                <CustomNoData />
              </div>
            )}
          </div>
        </div>

        <Modal
          title="Confirm Delete"
          open={openModal}
          onOk={() => {
            setOpenModal(false);
            deleteCheque();
          }}
          onCancel={() => setOpenModal(false)}
          okType="danger"
        >
          <p>{`Are you sure you want to delete?`}</p>
        </Modal>
      </div>
    </div>
  );
};

export default PDC;
