import React, { useState } from 'react';
import { FloatButton, Popover } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import ChatBot from './Chatbot';

const FloatingChatButton = ({ loan_id, sendJsonMessage, lastJsonMessage, readyState, conversationId }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  return (
    <Popover
      content={
        <div >
          <ChatBot
            loan_id={loan_id}
            sendJsonMessage={sendJsonMessage}
            lastJsonMessage={lastJsonMessage}
            readyState={readyState}
            conversationId={conversationId}
          />
        </div>
      }
      trigger="click"
      open={popoverVisible}
      onOpenChange={handleVisibleChange}
      placement="leftTop"
      // overlayClassName="floating-chat-popover"
    >
      <FloatButton
        icon={<MessageOutlined />}
        tooltip={<div>Chats</div>}
        style={{ marginRight: '120px' }}
      />
    </Popover>
  );
};

export default FloatingChatButton;
