import { Button, Col, Form, Row, Select, Space, message } from "antd";
import React, { useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {
  getMiscellaneousDetailServiceById,
  listOverallTypeService,
  updateMiscellaneousDetailServiceById,
} from "../services/miscellaneousDetailsService";
import * as Yup from "yup";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  overall_status: Yup.string().required("Overall Status is required"),
  conclusion: Yup.string().required("Overall remark is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ConclusionsForm = ({
  loanId,
  stageId,
  closeForm,
  open,
  updateId,
  refreshData,
}) => {
  const [overallstatus, setOverallstatus] = useState([]);
  const [form] = Form.useForm();

  const fetchoverallstatus = async () => {
    try {
      const response = await listOverallTypeService();
      setOverallstatus(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getConclusionData = async () => {
    try {
      const response = await getMiscellaneousDetailServiceById(stageId);
      const { overall_status, conclusion } = response?.data[0] || {};
      form.setFieldsValue({
        overall_status,
        conclusion,
      });
    } catch (error) {
      ErrorMessage(error?.response?.status, error?.response?.data?.message)
    }
  };

  useEffect(() => {
    if (open) {
      getConclusionData();
      fetchoverallstatus();
    } else {
      form.resetFields();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, form]);

  const handleSubmit = async (values) => {
    try {
      const response = await updateMiscellaneousDetailServiceById(updateId, values);
      if (response.status === 200 && response.success) {
        message.success("Conclusion Form successfully updated");
        refreshData();
        closeForm();
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="overall_status"
            label="Overall Status"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {overallstatus?.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="conclusion" label="Overall Remark"  rules={[yupSync]}required>
        <TextArea rows={4} placeholder="Please Enter Conclusion" />
      </Form.Item>

      <Space direction="horizontal" align="center" className="provision_space_button">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ConclusionsForm;
