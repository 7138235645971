import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Select,
  Row,
  Upload,
  message,
  Modal,
  Space,
  Table,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  UploadOutlined,
  UpOutlined,
  DeleteOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { RCUPostService, getRCUdetails } from "../services/rcuservices";
import pdf from "../assets/Pdf.png";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../utils/cryptoUtils/cryptoUtils";
import Loading from "../../../utils/loading/Loading";
import RViewerJS from "viewerjs-react";
import dummy_img from "../assets/dummyImage.png";
import LoanSummaryBlock from "../../../utils/loanSummaryBlock/LoanSummaryBlock";
import "../styles/rcuInitiateInputForm.css";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import { stageToCompleteService } from "../../loanApplication/loanSummary/services/loanapplicationService";
import CommonDeviation from "../../../utils/deviation/views/CommonDeviation";
import CustomNoData from "../../../utils/noDataIcon/CustomNoData";
const { Option } = Select;

const RcuPost = ({ typeCheck }) => {
  const [form] = Form.useForm();
  const [isCustomersVisible, setIsCustomersVisible] = useState(true);
  const [rcuStatusVisible, setRcuStatusVisible] = useState(true);
  // const [deviationVisible, setDeviationVisible] = useState(true);
  const [conclusionVisible, setConclusionVisible] = useState(true);
  const [completeRemarkVisible, setCompleteRemarkVisible] = useState(true);
  // const [vendorVisible, setVendorVisible] = useState(true);
  const [data, setData] = useState(true);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [images, setImages] = useState([]);
  const [isPropertyDocuments, setIsPropertyDocuments] = useState(true);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [loanid, setLoanid] = useState(null);
  const [loading, setLoading] = useState(false);
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  const [buttonloading, ] = useState(false);
  // const [vendorVisible, setVendorVisible] = useState(false)
  const [attachments, setAttachments] = useState([]);
  // const navigate = useNavigate();
  // const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [manualDeviationData, setManualDeviationData] = useState([]);
  const [operationType, setOperationType] = useState("RCU"); 
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    try {
      setLoanid(decrypt(encrypted_loan_id));
    } catch (error) {
      message.error("Invalid Loan ID");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encrypted_loan_id]);

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const toggleCustomers = () => {
    setIsCustomersVisible(!isCustomersVisible);
  };

  const togglercustatus = () => {
    setRcuStatusVisible(!rcuStatusVisible);
  };

  // const togglerDevation = () => {
  //   setDeviationVisible(!deviationVisible);
  // };

  const togglerConclusion = () => {
    setConclusionVisible(!conclusionVisible);
  };

  const togglerCompleteRemark = () => {
    setCompleteRemarkVisible(!completeRemarkVisible);
  };

  const getRCUData = async () => {
    try {
      setLoading(true);
      const response = await getRCUdetails(stage_id);
      if (response.status === 200 && response.success) {
        setData(response.data);

        setAttachments(
          response.data.initiate_stage_attachment_detail || dummy_img
        );
        setOperationType("RCU");
        setLoading(false);

      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const base64Files = await Promise.all(
      newFileList.map((file) => fileToBase64(file.originFileObj))
    );
    const updatedImages = newFileList.map((file, index) => ({
      uid: file.uid,
      file_path: base64Files[index],
      file_name: file.name,
    }));

    setImages(updatedImages);
  };

  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    setImages((prevImages) =>
      prevImages.filter((item) => item.uid !== file.uid)
    );
  };

  const confirmDelete = () => {
    setImages((prevImages) =>
      prevImages.filter((image) => image.uid !== deleteUploadPicture.uid)
    );
    setFileList((prevList) =>
      prevList.filter((file) => file.uid !== deleteUploadPicture.uid)
    );
    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const formattedDeviations = manualDeviationData.map((item) => ({
    loan_detail: parseInt(loanid),
    deviation: item.deviation,
    actual_description: item.actual_description,
    mitigates: item.mitigates,
  }));

  const handlevalidations = async(values) => {
    try { 
      const isValid = await form.validateFields();
  
      if (isValid) {
        setOpenModal(true); 
      } 
      } catch {
          message.error("Please Fill all values")
      }
  
  }



  // const handleSubmit = async (values) => {
  //   setLegalData(values)
  //   setConfirmModalVisible(true)
  // };

  const handleSubmit = async (values) => {
    const stripHtmlTags = (htmlString) => {
      const doc = new DOMParser().parseFromString(htmlString, "text/html");
      return doc.body.textContent || "";
    };
    // setbuttonLoading(true)
    try {
   
        const formattedValues = {
          ...values,
          rcu_deviations: formattedDeviations,
          credit_assessment_stage_initiate: data?.credit_assessment_detail?.id,
          product_type: 2,
          sourcing_location: data?.loan_detail?.location?.id,
          reporting_date: "2022-10-12",
          rcu_customer_details: (
            data.rcu_detail?.customer_wise_rcu_address_and_doc ?? []
          ).map((customer, index) => ({
            customer_detail: customer.customer_id,
            dedup_check: values[`dedup-check_${index}`],
          })),
          rcu_detail_docs: (
            data.rcu_detail?.customer_wise_rcu_address_and_doc ?? []
          ).flatMap((docs, index) => {
            const filteredDocs = docs.rcu_document_detail.filter(
              (type) => type.document_type !== "Profile"
            );
  
            return filteredDocs.map((doc) => {
              return {
                rcu_document_detail: doc.rcu_document_id,
                remark: stripHtmlTags(values[`remarks_${doc.rcu_document_id}`]),
                status: values[`status_${doc.rcu_document_id}`]
                  ? "Satisfactory"
                  : "RCU-Medium Risk",
                documents: filteredDocs,
              };
            });
          }),
          rcu_profile_detail: (
            data.rcu_detail?.customer_wise_rcu_address_and_doc ?? []
          ).flatMap((customer, index) => {
            const profileDocs = customer.rcu_document_detail.filter(
              (type) => type.document_type === "Profile"
            );
  
            return profileDocs.map((doc) => {
              return {
                rcu_profile_detail: doc.rcu_document_id,
                remark: stripHtmlTags(values[`remarks_${doc.rcu_document_id}`]),
                status: values[`status_${doc.rcu_document_id}`]
                  ? "Satisfactory"
                  : "RCU-Medium Risk",
                documents: profileDocs, // Keep only "Profile" documents here
              };
            });
          }),
          overall_remark: stripHtmlTags(values.overall_remark), // Remove HTML tags from overall_remark
          rcu_document_upload: images.map((image) => ({
            document_path: image.file_path,
          })),
        };
  
        const response = await RCUPostService(formattedValues);
        if (response?.status === 200 && response?.success) {
          handleCompleteStage(stage_id)
        }
      
    } catch (error) {
    
      console.error(error);
    }
  };


  const handleCompleteStage = async (stage_id, stage_code) => {
    try {
      const payload = {
        loan_stage: stage_id,
        loan_detail: loanid,
        stage: "MSCRRC2",
        stage_status: "VENDORCO",
      };
      const response = await stageToCompleteService(payload);
      if ((response.status = 200 && response.success)) {
        message.success(`RCU stage completed successfully`);
      }
    } catch (error) {
      setLoading(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (loanid) {
      getRCUData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanid, stage_id]);

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      render: (_, record) => (
        <div className="value wrap-text">{record.customer_name}</div>
      ),
    },
    {
      title: "Residence Address",
      dataIndex: "residenceAddress",
      key: "residenceAddress",
      render: (_, record) => (
        <div className="value wrap-text">
          {record.rcu_address_detail?.map(
            (address, idx) =>
              address.address_type === "Permanent Address" && (
                <div key={idx}>{address.address_detail}</div>
              )
          )}
        </div>
      ),
    },
    {
      title: "Office Address",
      dataIndex: "officeAddress",
      key: "officeAddress",
      render: (_, record) => (
        <div className="value wrap-text">
          {record.rcu_address_detail?.map(
            (address, idx) =>
              address.address_type === "Office Address" && (
                <div key={idx}>{address.address_detail}</div>
              )
          )}
        </div>
      ),
    },
    {
      title: "Dedupe Check",
      dataIndex: "dedupeCheck",
      key: "dedupeCheck",
      render: (_, record, index) => (
        <Form.Item
          name={`dedup-check_${index}`}
          rules={[{ required: true, message: "Dedupe Check is required" }]}
        >
          <Select placeholder="Search To Select" allowClear>
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        </Form.Item>
      ),
    },
  ];

  return (
    <>
      {/* <Modal
        title="Confirm Completion"
        open={confirmModalVisible}
        onOk={handleConfirmOk}
        onCancel={() => setConfirmModalVisible(false)}
        okType="danger"
        okButtonProps={{ loading }}
      >
        <p>{`Are you sure you want to complete the stage?`}</p>
      </Modal> */}
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {loading ? (
          <div>
            <Loading
              className="rcu_initiate_input_form_loading_div"
              loading={loading}
            />
          </div>
        ) : (
          <div>
            <div>
              <div className="main_technical_report">
                <div>
                  <p className="loan_summary_rcu_valuation_report">
                    RCU Valuation Report
                  </p>
                </div>
              </div>
              <LoanSummaryBlock
                data={data}
                typeCheck="RCU"
                attachments={attachments}
              />
              <div className="rcu_views_main_container">
                <div className="rcu_initiate_input_form_heading">
                  <div className="rcu_details">
                    <div className="rcu_details_blue">Customers</div>
                    <div>
                      <UpOutlined
                        className={`rcu_initiate_input_up_icon ${isCustomersVisible
                            ? "rcu_initiate_input_up_icon-rotated"
                            : "rcu_initiate_input_up_icon-inverted"
                          }`}
                        onClick={toggleCustomers}
                      />
                    </div>
                  </div>
                </div>
                {isCustomersVisible && (
                  <div className="rcu_input_form_customer_table">
                    {data?.rcu_detail?.customer_wise_rcu_address_and_doc &&
                      Array.isArray(
                        data.rcu_detail?.customer_wise_rcu_address_and_doc
                      ) &&
                      data.rcu_detail.customer_wise_rcu_address_and_doc.length >
                      0 ? (
                      <Table
                        columns={columns}
                        dataSource={
                          data.rcu_detail.customer_wise_rcu_address_and_doc
                        }
                        rowKey={(record, index) => index}
                        tableLayout="fixed"
                        pagination={false}
                      />
                    ) : (
                      <CustomNoData className="rcu_initiate_input_form_empty_div" />
                    )}
                  </div>
                )}
              </div>

              <div className="rcu_views_main_container">
                <div>
                  <div className="rcu_details">
                    <div className="rcu_details_blue">RCU Status</div>
                    <div>
                      <UpOutlined
                        className={`rcu_initiate_input_up_icon ${isCustomersVisible
                            ? "rcu_initiate_input_up_icon-rotated"
                            : "rcu_initiate_input_up_icon-inverted"
                          }`}
                        onClick={togglercustatus}
                      />
                    </div>
                  </div>
                </div>
                {rcuStatusVisible && (
                  <>
                    <div className="rcu_initiate_input_customer_form_heading ">
                      <div className="rcu-inside-container">
                        <Row gutter={16}>
                          <Col span={4}>
                            <div className="rcu_basic_card_content">
                              <div className="rcu_input_table_heading_label">
                                SR.NO
                              </div>
                            </div>
                          </Col>
                          <Col span={5}>
                            <div className="rcu_basic_card_content">
                              <div className="rcu_input_table_heading_label">
                                Document
                              </div>
                            </div>
                          </Col>
                          <Col span={5}>
                            <div className="rcu_basic_card_content">
                              <div className="rcu_input_table_heading_label  rcu_initiate_input_form_rcu_status_image">
                                Images
                              </div>
                            </div>
                          </Col>
                          <Col span={5}>
                            <div className="rcu_basic_card_content">
                              <div className="rcu_input_table_heading_label">
                                Remarks
                              </div>
                            </div>
                          </Col>
                          <Col span={5}>
                            <div className="rcu_basic_card_content">
                              <div className="rcu_input_table_heading_label">
                                Status
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div>
                      <div className="rcu-inside-container">
                        {data?.rcu_detail?.customer_wise_rcu_address_and_doc &&
                          Array.isArray(
                            data?.rcu_detail?.customer_wise_rcu_address_and_doc
                          ) &&
                          data?.rcu_detail?.customer_wise_rcu_address_and_doc
                            .length > 0 ? (
                          data?.rcu_detail?.customer_wise_rcu_address_and_doc.map(
                            (item, index, array) => (
                              <Row
                                gutter={16}
                                key={index}
                                className={`rcu_value_property_deviation ${index < array.length - 1
                                    ? "rcu_value_property_deviation-active"
                                    : "rcu_value_property_deviation-last"
                                  }`}
                              >
                                <Col span={4}>
                                  <div className="basic_card-content">
                                    <div className="rcu_initiate_input_form_rcu_status_sr_no">
                                      {index + 1}
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  span={5}
                                  className="rcu_initiate_input_form_document_block"
                                >
                                  {item?.rcu_document_detail.map(
                                    (items, index) => (
                                      <div className="basic_card-content">
                                        <div className="label_technical">
                                          <React.Fragment key={index}>
                                            <div className="rcu_initiate_input_form_document_lable">
                                              {items?.document_type}
                                            </div>
                                          </React.Fragment>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </Col>
                                <Col span={5}>
                                  <div className="basic_card-content">
                                    <div className="label_technical">
                                      {item?.rcu_document_detail?.map(
                                        (items, index) => {
                                          const url =
                                            items.document_detail_url || "";
                                          const parts = url.split("/");
                                          const fileName = parts
                                            .pop()
                                            .split("?")[0];
                                          const fileExtension = fileName
                                            .split(".")
                                            .pop()
                                            .toLowerCase();

                                          return (
                                            <React.Fragment key={index}>
                                              <div className="img_document  rcu_initiate_input_form_document_image">
                                                {[
                                                  "png",
                                                  "jpg",
                                                  "jpeg",
                                                ].includes(fileExtension) ? (
                                                  <RViewerJS>
                                                    <img
                                                      src={url || dummy_img}
                                                      height={45}
                                                      width={45}
                                                      className="rcu_initiate_input_form_document_dummy_img"
                                                      alt={`Document: ${fileName}`}
                                                    />
                                                  </RViewerJS>
                                                ) : fileExtension === "pdf" ? (
                                                  <>
                                                    <img
                                                      src={pdf || dummy_img} // Ensure 'pdf' is a valid imported image placeholder for PDFs
                                                      alt="PDF"
                                                      height={45}
                                                      width={45}
                                                      className="rcu_initiate_input_form_document_dummy_pdf"
                                                      onClick={() =>
                                                        window.open(
                                                          url,
                                                          "_blank"
                                                        )
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <FileOutlined />
                                                )}
                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </Col>

                                <Col
                                  span={5}
                                  className="rcu_initiate_input_form_document_rcu_status_remark"
                                >
                                  {item?.rcu_document_detail?.map(
                                    (items, index) => (
                                      <div className="basic_card-content">
                                        <div className="label_technical">
                                          <Form.Item
                                            key={index} // Make sure each item has a unique key
                                            name={`remarks_${items.rcu_document_id}`}
                                            required
                                            rules={[
                                              {
                                                required: true,
                                                message: "Remark is required",
                                              },
                                            ]}
                                          >
                                            <Input placeholder="Please Enter Remark" />
                                          </Form.Item>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </Col>

                                <Col
                                  span={5}
                                  className="rcu_initiate_input_form_document_rcu_status_remark"
                                >
                                  {item?.rcu_document_detail?.map(
                                    (items, index) => (
                                      <div
                                        className="basic_card-content"
                                        key={items.rcu_document_id}
                                      >
                                        <div className="label_technical">
                                          <Form.Item
                                            name={`status_${items.rcu_document_id}`}
                                            label=""
                                            rules={[
                                              {
                                                required: true,
                                                message: "Status is required",
                                              },
                                            ]}
                                          >
                                            <Select
                                              placeholder="Search To Select"
                                              allowClear
                                            >
                                              <Option value={true}>
                                                Satisfactory
                                              </Option>
                                              <Option value={false}>
                                                RCU-Medium Risk
                                              </Option>
                                            </Select>
                                          </Form.Item>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </Col>
                              </Row>
                            )
                          )
                        ) : (
                          <div>No document details available</div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="rcu_views_main_container">
                <div className="container_technical  rcu_initiate_input_form_heading">
                  <div className="rcu_ibput_table_blue_heading ">
                    <div className="valuation_details_blue">
                      Manual Deviation Details
                    </div>
                    <div>
                      <UpOutlined
                        className={`rcu_initiate_input_up_icon ${
                          deviationVisible
                            ? "rcu_initiate_input_up_icon-rotated"
                            : "rcu_initiate_input_up_icon-inverted"
                        }`}
                        onClick={togglerDevation}
                      />
                    </div>
                  </div>
                </div>
                {deviationVisible && (
                  <>
                    {data?.loan_deviation_detail?.length > 0 ? (
                      <>
                        <div className="rcu_initiate_input_customer_form_heading">
                          <div className="rcu-inside-container">
                            <Row gutter={16}>
                              <Col span={4}>
                                <div className="basic_card-content">
                                  <div className="rcu_input_table_heading_label">
                                    SR.NO
                                  </div>
                                </div>
                              </Col>
                              <Col span={6}>
                                <div className="basic_card-content">
                                  <div className="rcu_input_table_heading_label">
                                    Deviations
                                  </div>
                                </div>
                              </Col>
                              <Col span={6}>
                                <div className="basic_card-content">
                                  <div className="rcu_input_table_heading_label">
                                    Actual Deviation
                                  </div>
                                </div>
                              </Col>
                              <Col span={6}>
                                <div className="basic_card-content">
                                  <div className="rcu_input_table_heading_label">
                                    Mitigates
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div>
                          <div className="rcu-inside-container">
                            {data.loan_deviation_detail.map(
                              (deviation, index, array) => (
                                <Row
                                  gutter={16}
                                  className={`rcu_initiate_input_form_item ${
                                    index < array.length - 1
                                      ? "rcu_initiate_input_form_item-border"
                                      : ""
                                  }`}
                                  key={index}
                                >
                                  <Col span={4}>
                                    <div className="basic_card-content">
                                      <div className="label_technical">
                                        {index + 1}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col span={6}>
                                    <div className="basic_card-content">
                                      <div className="label_technical">
                                        {deviation.deviation.name}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col span={6}>
                                    <div className="basic_card-content">
                                      <div className="label_technical">
                                        {deviation.actual_description}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col span={6}>
                                    <div className="basic_card-content">
                                      <div className="label_technical">
                                        {deviation.mitigates}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <CustomNoData className="rcu_initiate_input_form_empty_div" />
                    )}
                  </>
                )}
              </div> */}

              <div className="rcu_views_main_container">
                <div className="rcu_ibput_table_blue_heading">
                  <div className="valuation_details_blue">Conclusions</div>
                  <div>
                    <UpOutlined
                      className={`rcu_initiate_input_up_icon ${conclusionVisible
                          ? "rcu_initiate_input_up_icon-rotated"
                          : "rcu_initiate_input_up_icon-inverted"
                        }`}
                      onClick={togglerConclusion}
                    />
                  </div>
                </div>
                {/* </div> */}
                {conclusionVisible && (
                  <>
                    <div>
                      <div className="rcu-inside-container">
                        <Row gutter={16}>
                          <Col span={24}>
                            <div className="basic_card-content">
                              <div className="label_technical">
                                Overall Status{" "}
                                <span className="all_required_sign"> *</span>
                              </div>
                            </div>
                            <Form.Item
                              name="overall_status"
                              rules={[
                                {
                                  required: true,
                                  message: "Overall Status is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Please Enter Remark"
                                allowClear
                              >
                                <Select.Option value={"Satisfactory"}>
                                  Satisfactory
                                </Select.Option>
                                <Select.Option value={"RCU-Medium Risk"}>
                                  RCU-Medium Risk
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <div className="basic_card-content">
                              <div className="label_technical">
                                Overall Remark{" "}
                                <span className="all_required_sign"> *</span>
                              </div>
                            </div>
                            <Form.Item
                              name="overall_remark"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Overall Remark is required",
                                },
                              ]}
                            >
                              <TextArea
                                rows={4}
                                placeholder="Please Enter Overall Remark"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div></div>
                  </>
                )}
              </div>

              <div className="rcu_views_main_container">
                <CommonDeviation
                  manualDeviationData={manualDeviationData}
                  setManualDeviationData={setManualDeviationData}
                  operationType={operationType}
                />
              </div>

              <div>
                <div>
                  <div className="rcu_views_main_container">
                    <div className="container_technical">
                      <div className="rcu_ibput_table_blue_heading">
                        <div className="valuation_details_blue">
                          Property Pictures and Other Documents{" "}
                        </div>
                        <div>
                          <UpOutlined
                            className={`rcu_property_documents_up_icon ${isPropertyDocuments
                                ? "rcu_property_documents_up_icon-rotated"
                                : "rcu_property_documents_up_icon-inverted"
                              }`}
                            onClick={() =>
                              setIsPropertyDocuments(!isPropertyDocuments)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {isPropertyDocuments && (
                      <div className="technical_property_container">
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item name="file_path" label=" ">
                              <Upload
                                multiple
                                fileList={fileList}
                                onChange={handleUploadChange}
                                onRemove={handleRemove}
                                beforeUpload={() => false} // Prevent automatic upload
                                showUploadList={false}
                              >
                                <Button
                                  type="primary"
                                  icon={<UploadOutlined />}
                                >
                                  Upload Document
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>

                        <div className="rcu_initiat_input_property_divider">
                          <div className="rcu_initiat_input_form_property_div">
                            {images.map((picture) =>
                              picture?.file_path?.includes(
                                "data:application/pdf"
                              ) ? (
                                <div
                                  key={picture.uid}
                                  className="rcu_div_image"
                                >
                                  <a
                                    href={picture.file_path}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={pdf}
                                      alt="PDF Icon"
                                      className="rcu_image"
                                    />
                                  </a>
                                  <div className="rcu_image_first_div">
                                    {picture.file_name}
                                  </div>
                                  <div
                                    className="rcu_image_second_div"
                                    onClick={() => {
                                      setDeleteUploadPicture(picture);
                                      setOpenUploadModal(true);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  key={picture.uid}
                                  className="rcu_div_image"
                                >
                                  <RViewerJS>
                                    <img
                                      src={picture.file_path}
                                      alt=""
                                      className="rcu_image"
                                    />
                                  </RViewerJS>
                                  <div className="rcu_image_first_div">
                                    {picture.file_name}{" "}
                                    {/* Display the file name */}
                                  </div>
                                  <div
                                    className="rcu_image_second_div"
                                    onClick={() => {
                                      setDeleteUploadPicture(picture);
                                      setOpenUploadModal(true);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>

                        <Modal
                          title="Confirm Delete"
                          open={openUploadModal}
                          onOk={confirmDelete}
                          onCancel={() => {
                            setOpenUploadModal(false);
                            setDeleteUploadPicture(null);
                          }}
                          okType="danger"
                        >
                          <p>
                            Are you sure you want to delete this uploaded image?
                          </p>
                        </Modal>
                      </div>
                    )}
                  </div>
                </div>
              </div>



              <div className="rcu_views_main_container">
                <div className="rcu_ibput_table_blue_heading">
                  <div className="valuation_details_blue">Complete Remark</div>
                  <div>
                    <UpOutlined
                      className={`rcu_complete_remark_up_icon ${completeRemarkVisible
                          ? "rcu_complete_remark_up_icon-rotated"
                          : "rcu_complete_remark_up_icon-inverted"
                        }`}
                      onClick={togglerCompleteRemark}
                    />
                  </div>
                </div>
                {completeRemarkVisible && (
                  <>
                    <div>
                      <div className="rcu-inside-container">
                        <Row gutter={16}>
                          <Col span={24}>
                            <div className="basic_card-content">
                              <div className="label_technical">
                                Complete Remark
                                <span className="all_required_sign"> *</span>
                              </div>
                            </div>
                            <Form.Item
                              name="completion_remark"
                              rules={[
                                {
                                  required: true,
                                  message: "Complete Remark is required",
                                },
                              ]}
                            >
                              <Input placeholder="Please Enter Complete Remark" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                )}
              </div>


              <Form.Item>
                <Space
                  direction="horizontal"
                  align="center"
                  className="rcu_initiate_input_form_space_button"
                >
                  <Button
                    type="primary"
                    // htmlType="submit"
                    className="rcu_initiate_input_submit_form"
                    buttonloading={buttonloading}
                    onClick={handlevalidations}  
                  >
                    Submit
                  </Button>
                </Space>
              </Form.Item>

              <Modal
                title="Confirm Submit"
                open={openModal}
                onOk={() => {
                  form.submit();
                  setOpenModal(false);
                }}
                onCancel={() => {
                  setOpenModal(false);
                }}
                okType="danger"
              >
                <p>Are you sure you want to submit this form?</p>
              </Modal>

            </div>
          </div>
        )}
      </Form>
    </>
  );
};
export default RcuPost;
