import React from "react";

const MessageBubble = ({ children, isActionMessage, isCurrentUser, msg }) => {
  return (
    <div
      style={{
        position: "relative",
        width: msg?.content?.length < 10 && !isActionMessage ? "80px" : "auto",
        maxWidth: isActionMessage ? "70%" : "70%",
        marginLeft: isCurrentUser && !isActionMessage ? "15px" : "5px",
        marginRight: isCurrentUser && !isActionMessage ? "10px" : "10px",
        fontSize: "28px",
      }}
    >
      {/* Top left arrow/tail - Only show for non-action messages from other users */}
      {!isActionMessage && !isCurrentUser && (
        <div
          style={{
            position: "absolute",
            left: "-8px",
            top: "18px",
            width: "12px",
            height: "12px",
            clipPath: "polygon(100% 0%, 0% 100%, 100% 100%)",
            backgroundColor: "rgba(98, 116, 137, 255)",
          }}
        />
      )}

      <div
        style={{
          padding: "5px",
          borderRadius: "4px",
          backgroundColor: isActionMessage
            ? "white"
            : isCurrentUser
            ? "#e7e6e6"
            : "rgba(98,116,137,255)",
          border:
            !isCurrentUser && !isActionMessage ? "0px solid #252568" : "none",
          textAlign: isActionMessage ? "center" : "left",
          display: "flex",
          alignItems: isActionMessage ? "center" : "left",
          color: isCurrentUser ? "black" : "white",
          fontSize: "10px",
          fontFamily: "Open Sans",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default MessageBubble;
