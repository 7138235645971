export const fileTypeExt = [
    // 'image/png','image/png',
    // 'image/jpeg','image/jpeg',
    // 'image/jpg','image/jpg',
    'application/pdf','application/pdf',
    'application/vnd.ms-excel','application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/octet-stream','application/octet-stream',
    'text/csv','text/csv',
    'audio/mpeg','audio/mpeg',
    'video/mp4','video/mp4',
    'text/plain','text/plain'
]