import { axiosRequest } from "../../../utils/api/axiosRequest";

export const getTodoList = (selectedDate) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/dashboard_lite/to-do-list/?date=${selectedDate}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }



  export const postTodo = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/dashboard_lite/to-do-list/",data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const updateTodo = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(
          `/api/v1/dashboard_lite/to-do-list/${id}`,
          data
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };