import React, { useState } from 'react';
import { Form, Select } from 'antd';
import Customer from '../customerType/views/CustomerType';
import Department from '../department/views/Department';
import Designation from '../designation/views/Designation';
import Industry from '../industryType/views/IndustryType';
import Permission from '../permission/views/Permission';
import Role from '../role/views/Role';
import State from '../state/views/State';
import Zone from '../zone/views/Zone';
import LoanType from '../loanType/views/LoanType';
import EmploymentNature from '../employmentNature/views/EmploymentNature';
import masterConfig from '../../../config/masterConfig';
import Bank from '../bank/views/Bank';
import BankBranch from '../bankBranch/views/BankBranch';
import CasteCategory from '../casteCategory/views/CasteCategory';
import City from '../city/views/City';
import DocumentType from '../documentType/views/DocumentType';
import LoanPurpose from '../loanPurpose/views/LoanPurpose';
import Schema from '../scheme/views/Scheme';
import Schemeconfigration from '../schemeConfigration/views/Schemeconfigration';
import Stage from '../stage/views/Stage';
import LoanStatus from '../loanStatus/views/LoanStatus';
import PinCode from '../pincode/views/Pincode';
import EmploymentType from '../employmentType/views/EmploymentType';
import Relation from '../relation/views/Relation';
import Occupation from '../occupations/views/Occupation';
import Source from '../source/views/Source';
import Status from '../status/views/Status';
import LeadStatus from '../leadStatus/views/LeadStatus';
import Country from '../country/views/Country';
import Taluka from '../taluka/views/Taluka';
import District from '../district/views/District';
import DocumentCategory from '../documentCategory/views/DocumentCategory';
import OrganizationNature from '../organizationNature/views/OrganizationNature';
import FinanceInstitution from '../financeInstitution/views/FinanceInstitution';
import Address from '../addressType/views/AddressType';
import Charges from '../chargesType/views/ChargesType';
import ChargesStatus from '../chargesStatus/views/ChargesStatus';
import LineOfWork from '../lineOfWork/views/LineOfWork';
import PropertyType from '../property/views/PropertyType';
import StageStatus from '../stageStatus/views/StageStatus';
import LandAuthority from '../landAuthority/Views/LandAuthority';
import VehicleType from '../vehicleType/views/VehicleType';
import VehicleManufacture from '../vehicleManufacture/views/VehicleManufacture';
import VehicleModel from '../vehicleModel/views/VehicleModel';
import VehicleBody from '../vehicleBody/views/VehicleBody';
import SanctionCondition from '../sanctionCondition/views/SanctionCondition';
import Questionnaire from '../questionnaire/view/Questionnaire';
import QuestionnaireCategory from '../questionnaireCategory/views/QuestionnaireCategory';
import QuestionnaireAnswer from '../questionnaireAnswer/views/QuestionnaireAnswer';
import DeviationCategory from '../deviationCategory/views/DeviationCategory';
import Deviation from '../Deviation/views/Deviation';
import SanctionConditionCategory from '../sanctionConditionCategory/views/SanctionConditionCategory';
import StageFlow from '../stageFlow/views/StageFlow';
import VehicleVariant from '../vehicleVariant/views/VehicleVariant';
import InvestmentType from '../investmentType/views/InvestmentType';
import Locations from '../locations/views/Locations';
import AgencyType from '../agencyType/views/AgencyType';
import Agency from '../agency/views/Agency';
import Agent from '../agent/views/Agent';
import Product from '../product/views/Product';
import EndUse from '../endUse/views/EndUse';
import NegativeProfile from '../negativeProfile/views/NegativeProfile';
import SpocLocation from '../spocLocation/views/SpocLocation';
import Asset from '../assets/views/Asset';
import SubSection from '../subSection/views/SubSection';
import MediaCategory from '../mediaCategory/views/MediaCategory';
import MediaType from '../mediaType/views/MediaType';
import TechnicalDocument from '../technicalDocument/views/TechnicalDocument';
import InsuranceCompony from '../insuranceCompony/views/InsuranceCompony';
import RtoOffice from '../rtoOffice/views/RtoOffice';
import InsurancePlanMagma from '../insurancePlanMagma/views/InsurancePlanMagma';
import Charge from '../charge/views/Charge';
import VastuAccount from '../vastuAccount/views/VastuAccount';
import IciciInsuranceQustionar from '../iciciInsuranceQuationar/views/IciciInsuranceQustionar';
import PropertyStructure from '../propertyStucture/views/PropertyStructure';
import PartDisbursement from '../partDisbursement/views/PartDisbursement';
import TimeSheetType from '../timeSheetEvent/views/TimeSheetType';
const Master = () => {
  const [selectedValue, setSelectedValue] = useState(null);

  const componentMapping = {
    'Address Type': <Address />,
    'Agency': <Agency />,
    'Agency Type': <AgencyType />,
    'Agent': <Agent />,
    'Asset': <Asset/>,
    'Bank': <Bank />,
    'Bank Branch': <BankBranch />,
    'Charge':<Charge/>,
    'Vastu Account':<VastuAccount/>,
    'Caste Category': <CasteCategory />,
    'Charge Type': <Charges />,
    'Charge Status': <ChargesStatus />,
    'City': <City />,
    'Country': <Country />,
    'Customer Type': <Customer />,
    'District': <District />,
    'Department': <Department />,
    'Designation': <Designation />,
    'Deviation': <Deviation />,
    'Deviation Category': <DeviationCategory />,
    'Document Type': <DocumentType />,
    'Document Category': <DocumentCategory />,
    'Employment Nature': <EmploymentNature />,
    'Employment Type': <EmploymentType />,
    'End Use': <EndUse />,
    'Finance Institution': <FinanceInstitution />,
    'Insurance Plan Magma' : <InsurancePlanMagma/>,
    'Industry Type': <Industry />,
    'Insurance company':<InsuranceCompony/>,
    'ICICI Questionnaire':<IciciInsuranceQustionar/>,
    'Investment Type': <InvestmentType />,
    'Line Of Work': <LineOfWork />,
    'Loan Type': <LoanType />,
    'Loan Purpose': <LoanPurpose />,
    'Loan Status': <LoanStatus />,
    'Location': <Locations />,
    'Lead Status': <LeadStatus />,
    'Negative Profile': <NegativeProfile />,
    'Media Category':<MediaCategory/>,
    'Media Type':<MediaType/>
,    'Occupation': <Occupation />,
    'Organization Nature': <OrganizationNature />,
    'Part Disbursement': <PartDisbursement />,
    'Permissions': <Permission />,
    'Property Type': <PropertyType />,
    'Property Structure': <PropertyStructure />,
    'Pincode': <PinCode />,
    'Product': <Product />,
    'Questionnaire': <Questionnaire />,
    'Questionnaire Category': <QuestionnaireCategory />,
    'Questionnaire Answer': <QuestionnaireAnswer />,
    'RTO' : <RtoOffice/>,
    'Role': <Role />,
    'Relation': <Relation />,
    'Sanction Condition': <SanctionCondition />,
    'Sanction Condition Category': <SanctionConditionCategory />,
    'Spoc Location': <SpocLocation />,
    'State': <State />,
    'Stage Status': <StageStatus />,
    'Status': <Status />,
    'Stage': <Stage />,
    'Stage Flow': <StageFlow />,
    'Source': <Source />,
    'Scheme': <Schema />,
    'Scheme Configration': <Schemeconfigration/>,
    'Sub Section':<SubSection/>,
    'Taluka': <Taluka />,
    'Time Sheet Event Type':<TimeSheetType/>,
    'Technical Document':<TechnicalDocument/>,
    'Vehicle Body': <VehicleBody />,
    'Zones': <Zone />,
    'Land Authority': <LandAuthority />,
    'Vehicle Type': <VehicleType />,
    'Vehicle Manufacturer': <VehicleManufacture />,
    'Vehicle Model': <VehicleModel />,
    'Vehicle Variant': <VehicleVariant />
  };

  const handleSelectChange = (newValue) => {
    setSelectedValue(newValue);
  };

  return (
    <div>
      <Form
        initialValues={{
          table: { label: 'Search Master Table', value: null }, // Set initial value with label
        }}
      >
        <h3>Select Master Table</h3>
        <Form.Item name="table">
          <Select
            showSearch
            placeholder="Search data"
            optionFilterProp="children"
            onChange={handleSelectChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              width: '35%',
              margin: '15px 10px 0px 0px',
            }}
          >
            {masterConfig.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      {selectedValue && componentMapping[selectedValue]}
    </div>
  );
};

export default Master;
