import React, { useEffect, useState } from 'react';
import ReferenceDetailsForm from '../../../loanApplication/customerTabDetails/referenceDetails/views/ReferenceDetailsForm';
import { Tabs, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getReferencesTabsData, deleteReferencesTabsData } from '../../services/salesformservices';
import { setInitialCustomerReferences, changeActiveReferenceKey, setReferenceApicalled, removeReference } from '../../../../redux/salesFormSlice';

const SalesFormReferenceForm = ({ activeCustomer }) => {
  const { customers } = useSelector((state) => state.salesForm);
  const [openModal, setOpenModal] = useState(false); // Modal visibility state
  const [targetKey, setTargetKey] = useState(null); // Store the target key to delete

  const referencesDetails = customers[parseInt(activeCustomer)]?.referenceDetails?.references;
  const referenceApiCalled = customers[parseInt(activeCustomer)]?.referenceDetails?.referenceapicalled;
  const activeReferenceKey = customers[parseInt(activeCustomer)]?.referenceDetails?.activeReferenceKey;
  const customerId = customers[parseInt(activeCustomer)]?.id;

  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  useEffect(() => {
    const getCustomerReferences = async () => {
      const response = await getReferencesTabsData(customerId);
      if (response?.data?.reference_detail?.length === 0) {
        dispatch(
          setInitialCustomerReferences({
            activeCustomer: activeCustomer,
            references: [null],
          })
        );

        if (referenceApiCalled === 0) {
          dispatch(setReferenceApicalled({ index: activeCustomer }));
        }
      } else {
        dispatch(
          setInitialCustomerReferences({
            activeCustomer: activeCustomer,
            references: response?.data?.reference_detail,
          })
        );

        if (referenceApiCalled === 0) {
          dispatch(setReferenceApicalled({ index: activeCustomer }));
        }
      }
    };
    if (referenceApiCalled === 0) {
      getCustomerReferences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (key) => {
    dispatch(
      changeActiveReferenceKey({
        activeCustomer: activeCustomer,
        key: key,
      })
    );
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      addNewReference();
    } else if (action === 'remove') {
      setTargetKey(targetKey); // Store the target key for deletion
      setOpenModal(true); // Show the confirmation modal
    }
  };

  const handleOk = async () => {
    const reference = referencesDetails[parseInt(targetKey)];

    if (reference) {
      if (referencesDetails.length > 1) {
        try {
          const response = await deleteReferencesTabsData(reference);

          if (response.status === 200 && response.success === true) {
            message.success("Tab deleted successfully");

            dispatch(removeReference({
              activeCustomerKey: activeCustomer,
              activeReferenceKey: targetKey,
            }));

            const newActiveKey = activeReferenceKey > 0 ? activeReferenceKey - 1 : 0;
            dispatch(
              changeActiveReferenceKey({
                activeCustomer: activeCustomer,
                key: newActiveKey,
              })
            );
          } else {
            message.error("Failed to delete the tab");
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "Error deleting tab");
        }
      } else {
        message.error("Cannot delete the last tab");
      }
    } else {
      if (referencesDetails.length === 1) {
        message.error("Cannot delete the last tab");
      } else {
        dispatch(removeReference({
          activeCustomerKey: activeCustomer,
          activeReferenceKey: targetKey,
        }));

        const newActiveKey = activeReferenceKey > 0 ? activeReferenceKey - 1 : 0;
        dispatch(
          changeActiveReferenceKey({
            activeCustomer: activeCustomer,
            key: newActiveKey,
          })
        );
      }
    }

    setOpenModal(false); // Close the modal after deletion
  };

  const handleCancel = () => {
    setOpenModal(false); // Close the modal without doing anything
  };

  const addNewReference = () => {
    const updatedReferences = [...referencesDetails, null];

    dispatch(
      setInitialCustomerReferences({
        activeCustomer: activeCustomer,
        references: updatedReferences,
      })
    );

    dispatch(
      changeActiveReferenceKey({
        activeCustomer: activeCustomer,
        key: updatedReferences.length - 1,
      })
    );
  };

  return (
    <div>
      <Tabs type="editable-card" onChange={onChange} onEdit={onEdit} activeKey={activeReferenceKey.toString()}>
        {referencesDetails &&
          referencesDetails.map((reference, index) => (
            <TabPane tab={`Reference Details ${index + 1}`} key={index}>
              <ReferenceDetailsForm
                reference={reference}
                customer_id={customerId}
                id={referencesDetails[parseInt(activeReferenceKey)]}
                activeReferenceKey={activeReferenceKey}
                activeCustomerKey={activeCustomer}
                isSalesForm={true}
              />
            </TabPane>
          ))}
      </Tabs>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okType="danger"
      >
        <p>Are you sure you want to delete this reference tab?</p>
      </Modal>
    </div>
  );
};

export default SalesFormReferenceForm;
