import React, {useState } from "react";
import {Divider, Button, Space, message } from "antd";
import "../../styles/CustomerForm.css";
import Stepper from "../../views/Stepper";
import BasicDetailsForm from "../../../loanApplication/loanTabDetails/basicDetails/views/BasicDetailsForm";
import VehicleDetailsForm from "../../../loanApplication/loanTabDetails/vehicleDetails/views/VehicleDetailsForm";
import SalesPropertyDetailForm from "../SalesPropertyDetailForm";
import { useSelector, useDispatch } from "react-redux";
import { setActiveLoanTabKey } from "../../../../redux/salesFormSlice";
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';


const SalesFormLoanDetails = () => { 
  const { loanId } = useSelector((state) => state.salesForm);


  const [, setCurrent] = useState(null)
  const open = true;
  const { activeKey } = useSelector((state) => state.salesForm);
  const dispatch = useDispatch();

  const goToPreviousStep = () => { 
      dispatch(setActiveLoanTabKey({
        activeKey: activeKey -1 , 
      })); 
  };

  const goToNextStep = () => { 
    if (loanId)
    {
      dispatch(setActiveLoanTabKey({ 
        activeKey: activeKey + 1,
      })); 
    }  
      else {
        message.error("Please fill out the Basic Details before proceeding");
      } 
  };
 

  const onClose = () => {};

 
  const loanDetailsTabs = [
    {
      key: "1",
      title: "Basic Details",
      Content: (
        <BasicDetailsForm
          onNext={true}
          loan_detail={loanId}
          open={open}
          refreshData={true}
        />
      ),
    },
    {
      key: "2",
      title: "Property Details",
      Content: (
        <SalesPropertyDetailForm
          onNext={true}
          loan_detail={loanId}
          open={open}
          closeForm={onClose}
          // refreshData={toggleRefreshData}
        />
      ),
    },
    {
      key: "3",
      title: "Vehicle Details",
      Content: (
        <VehicleDetailsForm
          onNext={true}
          loan_detail={loanId}
          open={open}
          refreshData={true}
        />
      ),
    },
  ];
  
  return (
    <div className="property_description_sales_container">
      <div>
        <div className="main_container">
          <div className="costumer_details_sidebar_container">
            <div
              className="costumer_details_sidebar_header"
              style={{ marginTop: "34px" }}
            >
              <h3>Timeline</h3>
            </div>
            <Divider />
            <Stepper
              currentStep={activeKey}
              steps={loanDetailsTabs}
              setCurrent={setCurrent} 
              loanId={loanId}
            />
          </div>
          <div
            className="document_header"
            style={{ borderTop: "1px solid #e4e7ec" }}
          >
            {loanDetailsTabs[activeKey] && (
              <div>
                <div className="document_header_items">
                  <h3 style={{ marginTop: "29px" }}>
                    {loanDetailsTabs[activeKey].title}
                  </h3>
                  <p>{`${activeKey + 1}/${loanDetailsTabs.length}`}</p>
                </div>
                <Divider /> 
                <div style={{ padding: "20px" }}>
                  {loanDetailsTabs[activeKey].Content}
                </div>
                <div className="document_footer_loan_container">
                <div 
                    className="document_footer_loan_items"
                    >
                    
                      <Space
                        direction="horizontal"
                        align="center"

                      >
                        <Button onClick={goToPreviousStep}
                         disabled={activeKey === 0}
                          type="primary"
                        >
                          <ArrowLeftOutlined/>
                          Previous
                        </Button>
                        <Button
                          onClick={goToNextStep}
                          disabled={activeKey === 2}
                          type="primary"
                        >
                          Next
                          <ArrowRightOutlined />
                        </Button>
                      </Space> 
                    </div>
                  </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesFormLoanDetails;
