import React, { useEffect, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import EditButton from "../../../../utils/editButton/EditButton";
import ResidentialDetailsForm from "./ResidentialDetailsForm";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import '../../styles/rvViews.css'
import { getResidenceOverallStatusByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import Loading from "../../../../utils/loading/Loading";
import CustomNoData from "../../../../utils/noDataIcon/CustomNoData";

const ResidentialDetails = ({ selectedCustomerId }) => {
  const [open, setOpen] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [collapsed1, setCollapsed1] = useState(true);
  const [residenceData, setResidenceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getResidenceOverallStatusByCustomerId(selectedCustomerId);
        setResidenceData(reponse?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, selectedCustomerId]);

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const renderEditButton = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div className="fi_Views_main_container">
      <Drawer
        title={
          <Header title="Edit" onClose={onClose} name="Residence Details" />
        }
        width={970}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <ResidentialDetailsForm
          open={open}
          updateId={residenceData?.id}
          // loan_id={loan_id}
          closeForm={onClose}
          refreshData={toggleRefreshTableData}
          customerDetails={residenceData}
        />
      </Drawer>
      <div className="fade-in">
        {loading ? (
          <Loading loading={loading} /> // Loading component while data is fetching
        ) : residenceData ? (
          <>
            <div className="fi_views_description_heading">
              <div className="fi_views_heading_name" >Resdence Details</div>
              <div className="fi_views_button_space">
                <div
                  className="fi_views_edit_button"
                >
                  {renderEditButton()}
                </div>
                <div
                  className="fi_views_collapse_icon"
                  onClick={toggleCollapse1}

                >
                  {collapsed1 ? <DownOutlined /> : <UpOutlined />}
                </div>
              </div>
            </div>
            {collapsed1 && (
              <div>
                <>
                  <div className="fi_description_basic_card_container">
                    <div className="fi_description_basic_card">
                      <div className="">
                        <div className="fi_description_basic_card_label">
                          Residence Ownership Details:
                        </div>
                        <div className="fi_description_basic_card_data">
                          {residenceData?.residence_ownership || "-"}
                        </div>
                      </div>
                    </div>

                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Type Of House:
                        </div>
                        <div className="fi_description_basic_card_data">
                          {residenceData?.house_type ||
                            "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            )}
          </>
        ) : (
          <div className="no_data_found_message">
             <CustomNoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResidentialDetails;
