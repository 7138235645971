import React, { useEffect } from "react";
import "../../styles/fi.css";
import UseCustomer from "../../hooks/UseCustomer";
import Occupation from "./Occupation";
import BusinessPremises from "./BusinessPremises";
import AssetsOv from "./AssetsOv";
import OverallStatusOv from "./OverallStatusOv";
import DocumentsOv from "./DocumentsOv";

const ApplicantOV = ({ selectedData,selectedCustomerId }) => {
  const {
    setCustomerId,
    assets,
    fiBusinessOccupation,
    fiBusinessPremises,
    fiDetail,
  } = UseCustomer();
  const customerId = selectedData?.id;

  useEffect(() => {
    setCustomerId(customerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <div className="rv_view_container" >
      <div >
        <div className="">
          <div className="heading_text_ov">
          Office Address : {selectedData.customer_address.full_address}
          </div>
       
        </div>
      </div>

      <Occupation fiBusinessOccupation={fiBusinessOccupation}  selectedCustomerId={selectedCustomerId} />

      <BusinessPremises fiBusinessPremises={fiBusinessPremises} selectedCustomerId={selectedCustomerId} />

      <AssetsOv assets={assets}  selectedCustomerId={selectedCustomerId}/>

      <OverallStatusOv fiDetail={fiDetail} selectedCustomerId={selectedCustomerId}  />

      <DocumentsOv selectedCustomerId={selectedCustomerId} />
    </div>
  );
};

export default ApplicantOV;
