import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user_data: {},
  user_location: null, 
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user_data = action.payload;
    },
    setLocation: (state, action) => {
      state.user_location = action.payload;
    },  
}
}); 

export const { 
  setUser, 
  setLocation,  
} = userSlice.actions;

export const fetchUserLocation = (location) => (dispatch) => {
  dispatch(setLocation(location));
};

export default userSlice.reducer;
