import React, { useEffect, useState } from "react";
import NachForm from "./NachForm";
import { Button, Table, Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import {
  getBankAccountFilterDetailsByIdService,
  getFilterByStatus,
} from "../services/nachDetailService";

const NotChecked = () => {
  const [open, setOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [notChecked, setNotChecked] = useState();
  const [filterNotChecked, setFilterNotChecked] = useState();
  const [filterNachId, setFilterNachId] = useState();
  const [, setLoading] = useState(false);
  const [show, setShow] = useState();

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  const onClose = () => {
    setOpen(false);
  };

  const openNachDrawer = (record) => {
    setFilterNachId(record?.id);
    setShow((pre) => !pre);
    setOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getBankAccountFilterDetailsByIdService(
          filterNachId
        );
        const results = response.data;
        setFilterNotChecked(results);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    };

    if (filterNachId) {
      fetchData();
    }
  }, [filterNachId, show, open, refreshData]);

  const columns1 = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      render: (text) => text || "-",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
      render: (text) => text || "-",
    },

    {
      title: "A/C Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch",
    },
    {
      title: "Holder Name",
      dataIndex: "holder_name",
      key: "holder_name",
    },
    {
      title: "IFSC Code",
      dataIndex: "ifsc_number",
      key: "ifsc_code",
    },
    {
      title: "MICR",
      dataIndex: "micr_number",
      key: "micr",
    },

    {
      title: "EMI",
      dataIndex: "emi",
      key: "emi",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Vendor",
      key: "vendor",
      render: (text, record) => record.vendor?.display_name,
    },

    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
    },
  ];

  useEffect(() => {
    const fetchNachData = async () => {
      try {
        const response = await getFilterByStatus("Not-Checked");
        setNotChecked(response?.data || []);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchNachData();
  }, [refreshData]);

  return (
    <div>
      <Drawer
        title={
          <Header
            title="Edit"
            onClose={onClose}
            name="Nach person bank details"
          />
        }
        width={900}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <NachForm
          onClose={onClose}
          filterNachId={filterNachId}
          filterNotChecked={filterNotChecked}
          show={show}
          closeForm={onClose}
          refreshData={toggleRefreshData}
        />
      </Drawer>

      <div className="pdc_details_table">
        <div
          className="nach-document-button-container"
        >
          <Button type="primary">Upload Document</Button>
          <Button type="primary">Download Document</Button>
        </div>

        <div className="pdc_detail_heading">
          <div className="pdc_description_data">Not Checked</div>
        </div>

        <div className="table_content">
          <Table
            dataSource={notChecked}
            columns={columns1}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                openNachDrawer(record); // Open drawer when row is clicked
              },
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default NotChecked;
