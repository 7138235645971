import React, { useEffect } from "react";
import { message, Button, Modal } from "antd";
import "../../styles/fi.css"
import FiSwitchTab from "../FiSwitchTab";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import { getFiInputFormData } from "../../services/filloanservices";
import { useState } from "react";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import LoanSummaryBlock from "../../../../utils/loanSummaryBlock/LoanSummaryBlock";
import { fifinalsubmit } from "../../services/FIInputFormServices";
import { useNavigate } from "react-router-dom";
import { stageToCompleteService } from "../../services/FIInputFormServices";

const FiInputForm = ({ typeCheck }) => {
  const [legalInputData, setLegalInputData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const { encrypted_loan_id, encrypted_stage_id } = useParams();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const navigate = useNavigate()

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);

  } catch (error) {
    message.error("Invalid Loan ID");
  }

  let stage_id = null;
  try {
    stage_id = decrypt(encrypted_stage_id);

  } catch (error) {
    message.error("Invalid Loan ID");
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFiInputFormData(stage_id);
        setLegalInputData(response.data);
        setAttachments(response.data.initiate_stage_attachment_data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompleteStage = async (stage_id) => {
    try {
      const payload = {
        loan_stage: stage_id,
        loan_detail: loan_id,
        stage: "MSFFFI9",
        stage_status: "VENDORCO",
      };
      const response = await stageToCompleteService(payload);
      if ((response.status = 200 && response.success)) {
        message.success(`FI stage completed successfully`);
        navigate(`/loandetails/${encrypted_loan_id}`)
      }
    } catch (error) { 
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleSubmit = async () => {

    const data = {
      field_investigation_address_list: legalInputData.fi_addresses.map((fi) => fi["fi address id"])
    };




    try {

      const response = await fifinalsubmit(data);

      if (response.status === 200 && response?.success) { 
        message.success("Form submitted successfully");
        await handleCompleteStage(stage_id);
      }
    } catch (error) {
      setConfirmModalVisible(false);
      const errorMessage = error?.response
        ? ErrorMessage(error.response.status, error.response.data?.message)
        : "An unexpected error occurred";

      message.error(errorMessage);
    } finally {
      setConfirmModalVisible(false);
    }

  
  
  };


  return (
    <>
       <Modal
        title="Confirm Completion"
        open={confirmModalVisible}
        onOk={handleSubmit}
        onCancel={() => setConfirmModalVisible(false)}
        okType="danger"
        
      >
        <p>{`Are you sure you want to submit this form?`}</p>
      </Modal>
      

      <div className="">
        <div>
          <h3 className="FI_heading">FI</h3>
        </div>


      </div>
      <LoanSummaryBlock data={legalInputData} attachments={attachments} typeCheck={typeCheck} />
      <div className="fi-final-submit">
        <Button type="primary" onClick={() => setConfirmModalVisible(true)}>Final Submit</Button>
      </div>

      <FiSwitchTab loanId={loan_id} stageId={stage_id} />
    </>
  );
};

export default FiInputForm;
