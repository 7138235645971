import React, { useState, useEffect } from "react";
import "../styles/documentDetails.css";
// import group from "../assets/Group.png";
import "../../style/customerStyle.css";
import { Drawer, message } from "antd";
import Header from "../../../../layout/views/Header";
import DocumentDetailsForm from "./DocumentDetailsForm";
import { getDocumentDetailsByIdService } from "../services/documentDetailsServices";
import { useParams } from "react-router-dom";
import { FaFile } from "react-icons/fa";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import "../../../../commonCss/commonStyle.css";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import pdf_image from "../assets/pdf.png";
import csvimage from "../assets/csv.png";
import dummy_img from "../assets/dummy.png";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";

const DocumentDetails = () => {
  const [open, setOpen] = useState(false);
  const { encrypted_loan_id } = useParams();
  const [documentData, setDocumentData] = useState(null);

  const [loading, setLoading] = useState(true);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocumentDetailsByIdService(loan_id);
        setDocumentData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
  }, [loan_id]);

  const Headings = [
    "Key Parameter's",
    "Kyc Documents",
    "Financial Documents",
    "Income Documents",
    "Property Documents",
  ];

  const onClose = () => {
    setOpen(false);
  };

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return "";
    }
  };

  const handleImageError = (e) => {
    e.target.src = dummy_img;
  };

  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={<Header title="Edit" onClose={onClose} />}
          width={970}
          onClose={onClose}
          open={open}

          closable={false}
        >
          <DocumentDetailsForm open={open} closeForm={onClose} />
        </Drawer>
        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : documentData?.some(
            (person) => person?.document_details?.length > 0
          ) ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">Key Parameter's</th>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <th key={index} className="tab_form_top_heading">
                        <span>{`${person?.customer_name}`} </span>
                        <span>
                          <span>{`(${person?.customer_type})`} </span>
                          {/* <img
                            src={group}
                            alt="group"
                            className="verify_img"
                           
                          /> */}
                        </span>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                <tr className="tab_form_gray_highlighted_row">
                  <td className="tab_form_left_heading" style={{ height: "100px" }}>KYC Documents</td>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <td
                        key={index}
                        className={`tab_form_main_data_table ${getTableColumnClass(
                          documentData?.length
                        )}`}
                      >
                        {Headings.includes("Kyc Documents") && (
                          <div className="main_card_first_box">
                            {person?.document_details?.length > 0
                              ? person?.document_details?.map(
                                (properties, propIndex) =>
                                  properties.document_category?.map((category, catIndex) => {
                                    const hasKYC = category.display_name;

                                    if (
                                      hasKYC === "Kyc Documents" &&
                                      properties.file_type === "application/pdf"
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <img
                                              src={pdf_image}
                                              alt="PDF"
                                              height={64}
                                              width={64}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="docummnet_imge_other"
                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasKYC === "Kyc Documents" &&
                                      (properties.file_type ===
                                        "application/vnd.ms-excel" ||
                                        properties.file_type ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                        properties.file_type ===
                                        "application/octet-stream" ||
                                        properties.file_type === "text/csv")
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <img
                                              src={csvimage}
                                              alt="CSV"
                                              height={64}
                                              width={64}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="docummnet_imge_other"
                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasKYC === "Kyc Documents" &&
                                      (properties.file_type === "image/jpeg" ||
                                        properties.file_type === "image/jpg" ||
                                        properties.file_type === "image/png")
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <RViewerJS
                                              options={{
                                                url: (image) => image.getAttribute("data-original"), // Retrieves the full-sized image URL from the data-original attribute
                                              }}
                                            >
                                              <img
                                                className="profile docummnet_imge_other"
                                                src={
                                                  properties.thumb_document_path ||
                                                  dummy_img
                                                } // Uses the thumbnail image path
                                                data-original={
                                                  properties.document_path ||
                                                  dummy_img
                                                } // Stores the full-sized image path
                                                alt="Document"
                                                height={64}
                                                width={64}

                                                onError={handleImageError}
                                              />
                                            </RViewerJS>

                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasKYC === "Kyc Documents" &&
                                      properties.file_type === "text/plain"
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <FaFile
                                              size={64}
                                              color="red"
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="documnet_div"

                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return ""; // or any fallback element
                                    }
                                  })
                              )
                              : "-"}
                          </div>
                        )}
                      </td>
                    ))}
                </tr>

                <tr className="tab_form_gray_highlighted_row">
                  <td className="tab_form_left_heading" style={{ height: "100px" }}>Financial Documents</td>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <td key={index} className="tab_form_main_data_table">
                        {Headings.includes("Financial Documents") && (
                          <div className="main_card_first_box">
                            {person?.document_details?.length > 0
                              ? person?.document_details?.map(
                                (properties, propIndex) => properties.document_category?.map((category, catIndex) => {
                                  const hasFinancial = category.display_name;
                                  if (
                                    hasFinancial ===
                                    "Financial Documents" &&
                                    properties.file_type === "application/pdf"
                                  ) {
                                    return (
                                      <div
                                        key={propIndex}
                                        className="card_box"
                                      >
                                        <div className="boxs_div">
                                          <img
                                            src={pdf_image}
                                            alt="PDF"
                                            height={64}
                                            width={64}
                                            onClick={() =>
                                              window.open(
                                                properties.document_path,
                                                "_blank"
                                              )
                                            }
                                            className="documnet_div"
                                          />
                                          <p className="card_lable_name">
                                            {
                                              category.document_type
                                                ?.display_name
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    hasFinancial ===
                                    "Financial Documents" &&
                                    (properties.file_type ===
                                      "application/vnd.ms-excel" ||
                                      properties.file_type ===
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                      properties.file_type ===
                                      "application/octet-stream" ||
                                      properties.file_type === "text/csv")
                                  ) {
                                    return (
                                      <div
                                        key={propIndex}
                                        className="card_box"
                                      >
                                        <div className="boxs_div">
                                          <img
                                            src={csvimage}
                                            alt="CSV"
                                            height={64}
                                            width={64}
                                            onClick={() =>
                                              window.open(
                                                properties.document_path,
                                                "_blank"
                                              )
                                            }
                                            className="documnet_div"
                                          />
                                          <p className="card_lable_name">
                                            {
                                              category.document_type
                                                ?.display_name
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    hasFinancial ===
                                    "Financial Documents" &&
                                    (properties.file_type === "image/jpeg" ||
                                      properties.file_type === "image/jpg" ||
                                      properties.file_type === "image/png")
                                  ) {
                                    return (
                                      <div
                                        key={propIndex}
                                        className="card_box"
                                      >
                                        <div className="boxs_div">
                                          <RViewerJS
                                            options={{
                                              url: (image) => image.getAttribute("data-original"), // Retrieves the full-sized image URL from the data-original attribute
                                            }}
                                          >
                                            <img
                                              className="profile docummnet_imge_other"
                                              src={
                                                properties.thumb_document_path ||
                                                dummy_img
                                              } // Uses the thumbnail image path
                                              data-original={
                                                properties.document_path ||
                                                dummy_img
                                              } // Stores the full-sized image path
                                              alt="Document"
                                              height={64}
                                              width={64}

                                              onError={handleImageError}
                                            />
                                          </RViewerJS>
                                          <p className="card_lable_name">
                                            {
                                              category.document_type
                                                ?.display_name
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    hasFinancial ===
                                    "Financial Documents" &&
                                    properties.file_type === "text/plain"
                                  ) {
                                    return (
                                      <div
                                        key={propIndex}
                                        className="card_box"
                                      >
                                        <div className="boxs_div">
                                          <FaFile
                                            size={64}
                                            color="red"
                                            onClick={() =>
                                              window.open(
                                                properties.document_path,
                                                "_blank"
                                              )
                                            }
                                            className="documnet_div"
                                          />
                                          <p className="card_lable_name">
                                            {
                                              category.document_type
                                                ?.display_name
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return "";
                                  }
                                })
                              )
                              : "-"}
                          </div>
                        )}
                      </td>
                    ))}
                </tr>

                <tr className="tab_form_gray_highlighted_row">
                  <td className="tab_form_left_heading" style={{ height: "100px" }}>Income Documents</td>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <td key={index} className="tab_form_main_data_table">
                        {Headings.includes("Property Documents") && (
                          <div className="main_card_first_box">
                            {person?.document_details?.length > 0
                              ? person?.document_details?.map(
                                (properties, propIndex) =>
                                  properties.document_category?.map((category, catIndex) => {
                                    const hasIncome = category.display_name;

                                    if (
                                      hasIncome === "Income Documents" &&
                                      properties.file_type === "application/pdf"
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <img
                                              src={pdf_image}
                                              alt="PDF"
                                              height={64}
                                              width={64}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="documnet_div"
                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasIncome === "Income Documents" &&
                                      (properties.file_type ===
                                        "application/vnd.ms-excel" ||
                                        properties.file_type ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                        properties.file_type ===
                                        "application/octet-stream" ||
                                        properties.file_type === "text/csv")
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <img
                                              src={csvimage}
                                              alt="CSV"
                                              height={64}
                                              width={64}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }

                                              className="documnet_div"
                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasIncome === "Income Documents" &&
                                      (properties.file_type === "image/jpeg" ||
                                        properties.file_type === "image/jpg" ||
                                        properties.file_type === "image/png")
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <RViewerJS
                                              options={{
                                                url: (image) => image.getAttribute("data-original"), // Retrieves the full-sized image URL from the data-original attribute
                                              }}
                                            >
                                              <img
                                                className="profile docummnet_imge_other"
                                                src={
                                                  properties.thumb_document_path ||
                                                  dummy_img
                                                } // Uses the thumbnail image path
                                                data-original={
                                                  properties.document_path ||
                                                  dummy_img
                                                } // Stores the full-sized image path
                                                alt="Document"
                                                height={64}
                                                width={64}

                                                onError={handleImageError}
                                              />
                                            </RViewerJS>
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasIncome === "Income Documents" &&
                                      properties.file_type === "text/plain"
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <FaFile
                                              size={64}
                                              color="red"
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="documnet_div"
                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return "";
                                    }
                                  })
                              )
                              : "-"}
                          </div>
                        )}
                      </td>
                    ))}
                </tr>

                <tr className="tab_form_gray_highlighted_row">
                  <td className="tab_form_left_heading">Other Documents</td>
                  {documentData &&
                    documentData?.map((person, index) => (
                      <td key={index} className="tab_form_main_data_table">
                        {Headings.includes("Property Documents") && (
                          <div className="main_card_first_box">
                            {person?.document_details?.length > 0
                              ? person?.document_details?.map(
                                (properties, propIndex) =>
                                  properties.document_category?.map((category, catIndex) => {
                                    const hasUnother = category.display_name;


                                    if (
                                      hasUnother !== "Income Documents" &&
                                      hasUnother !==
                                      "Financial Documents" &&
                                      hasUnother !== "Kyc Documents" &&
                                      properties.file_type === "application/pdf"
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <img
                                              src={pdf_image}
                                              alt="PDF"
                                              height={64}
                                              width={64}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="documnet_div"
                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasUnother !== "Income Documents" &&
                                      hasUnother !==
                                      "Financial Documents" &&
                                      hasUnother !== "Kyc Documents" &&
                                      (properties.file_type ===
                                        "application/vnd.ms-excel" ||
                                        properties.file_type ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                        properties.file_type ===
                                        "application/octet-stream" ||
                                        properties.file_type === "text/csv")
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <img
                                              src={csvimage}
                                              alt="CSV"
                                              height={64}
                                              width={64}
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="documnet_div"
                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasUnother !== "Income Documents" &&
                                      hasUnother !==
                                      "Financial Documents" &&
                                      hasUnother !== "Kyc Documents" &&
                                      (properties.file_type === "image/jpeg" ||
                                        properties.file_type === "image/jpg" ||
                                        properties.file_type === "image/png")
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <RViewerJS
                                              options={{
                                                url: (image) => image.getAttribute("data-original"), // Retrieves the full-sized image URL from the data-original attribute
                                              }}
                                            >
                                              <img
                                                className="profile docummnet_imge_other"
                                                src={
                                                  properties.thumb_document_path ||
                                                  dummy_img
                                                } // Uses the thumbnail image path
                                                data-original={
                                                  properties.document_path ||
                                                  dummy_img
                                                } // Stores the full-sized image path
                                                alt="Document"
                                                height={64}
                                                width={64}

                                                onError={handleImageError}
                                              />
                                            </RViewerJS>
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      hasUnother !== "Income Documents" &&
                                      hasUnother !==
                                      "Financial Documents" &&
                                      hasUnother !== "Kyc Documents" &&
                                      properties.file_type === "text/plain"
                                    ) {
                                      return (
                                        <div
                                          key={propIndex}
                                          className="card_box"
                                        >
                                          <div className="boxs_div">
                                            <FaFile
                                              size={64}
                                              color="red"
                                              onClick={() =>
                                                window.open(
                                                  properties.document_path,
                                                  "_blank"
                                                )
                                              }
                                              className="documnet_div"
                                            />
                                            <p className="card_lable_name">
                                              {
                                                category.document_type
                                                  ?.display_name
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return "";
                                    }
                                  })
                              )
                              : "-"}
                          </div>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
          ) : (
            <div className='fade-in'>
              <div>
                <table className='tab_form_main_table no_data_table_height'>
                  <thead>
                    <tr>
                      <th
                        className="tab_form_key_parameters"
                      >
                        Key Parameter's
                      </th>
                      {documentData?.map((person, index) => (
                        <th
                          key={index}
                          className="tab_form_top_heading no_data_heading_bottom_border"
                        >
                          <span className='no_data_heading_content'>
                            <div>
                              <span>{`${person?.customer_name}`} </span>
                              <span>
                                <span>{`(${person?.customer_type})`} </span>
                                {/* <img
                                src={group}
                                alt="group"
                                className='verify_icon'
                              /> */}
                              </span>
                            </div>

                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Placeholder to ensure table body aligns */}
                    {documentData?.length ? (
                      <tr>
                        <td colSpan={documentData?.length + 1}>
                          {/* Your data rows go here */}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={documentData?.length + 1}
                        ></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className='no_data_icon'
              >
                <CustomNoData />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentDetails;
