import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import PersonalDetails from "./PersonalDetailsForm";
import WorkDetails from "./WorkDetailsForm"; //
import RolesPermissions from "./RolesPermissionsForm";
import ZonesLocation from "./ZonesLocationForm";
import { useLocation } from 'react-router-dom';
import "../styles/UserProfile.css"
const { TabPane } = Tabs;

const UserFormController = ({ closeDrawer, open, id, refreshData, setId ,userData }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [activeKey, setActiveKey] = useState("1");
  const [formCompletion, setFormCompletion] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });

  const handleCancel = () => {
    if (activeKey !== "1") {
      const previousTab = String(Number(activeKey) - 1);
      setActiveKey(previousTab);
    }
  };

  const onNextTab = () => {
    const nextTab = String(Number(activeKey) + 1);
    setActiveKey(nextTab);
  };

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  const formCompleted = (formKey, isCompleted) => {
    setFormCompletion((prevFormCompletion) => ({
      ...prevFormCompletion,
      [formKey]: isCompleted,
    }));
  };

  useEffect(() => {
    if (open === true) {
      setActiveKey("1");
      setFormCompletion({
        1: true,
        2: false,
        3: false,
        4: false,
      });
    }
  }, [open]);

  useEffect(() => {
    if (open === true && id) {
      setFormCompletion({
        1: true,
        2: true,
        3: true,
        4: true,
      });
    }
  }, [open, id]);

  const items = [
    {
      key: "1",
      label: "Personal details",
      content: (
        <PersonalDetails
          userData={userData}
          currentRoute= {currentPath}
          onCancel={handleCancel}
          onNext={onNextTab}
          closeDrawer={closeDrawer}
          open={open}
          id={id}
          setId={setId}
          formCompleted={(isCompleted) => formCompleted("1", isCompleted)}
        />
      ),
    },
    {
      key: "2",
      label: "Work details",
      content: (
        <WorkDetails
        userData={userData}
        currentRoute= {currentPath}
          closeDrawer={closeDrawer}
          onCancel={handleCancel}
          open={open}
          id={id}
          formCompleted={(isCompleted) => formCompleted("2", isCompleted)}
        />
      ),
    },
    {
      key: "3",
      label: "Roles & Permissions",
      content: (
        <RolesPermissions
        userData={userData}
        currentRoute= {currentPath}
          closeDrawer={closeDrawer}
          onCancel={handleCancel}
          open={open}
          id={id}
          formCompleted={(isCompleted) => formCompleted("3", isCompleted)}
        />
      ),
    },
    {
      key: "4",
      label: "Zones & Locations",
      content: (
        <ZonesLocation
        userData={userData}
        currentRoute= {currentPath}
          closeDrawer={closeDrawer}
          onCancel={handleCancel}
          open={open}
          id={id}
          refreshData={refreshData}
          formCompleted={(isCompleted) => formCompleted("4", isCompleted)}
        />
      ),
    },
  ];

  return (
    <div className={currentPath === "/user_profile" ? "tabs-container" : ""}>
      <Tabs activeKey={activeKey} onChange={onTabChange}>
        {items.map((item, index) => (
          <TabPane
            tab={item.label}
            key={item.key}
            disabled={
              currentPath === "/users" &&
              !formCompletion[item.key] &&
              item.key !== activeKey
            }
          >
            {React.cloneElement(item.content, {
              onCancel: handleCancel,
              onNext: onNextTab,
              formCompleted: (isCompleted) =>
                formCompleted(item.key, isCompleted),
              // mode: id ? "edit" : "add",
            })}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default UserFormController;
