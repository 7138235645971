import React, { useEffect, useState } from "react";
import EditButton from "../../../../utils/editButton/EditButton";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import AssetsOvEditForm from "./AssetsOvEditForm";
import { getAssestsByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import "../../styles/assetsseen.css";

const AssetsOv = ({selectedCustomerId}) => {
  const [open, setOpen] = useState(false);
  const [collapsed4, setCollapsed4] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [assetsOvData, setAssetsOvData] = useState(null);

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };

  const onClose4 = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAssestsByCustomerId(selectedCustomerId);
     
        setAssetsOvData(response?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData,selectedCustomerId]);
  const renderEditButton = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div className="asset_conatianr" >
      <Drawer
        title={<Header title="Edit" onClose={onClose4} name="Assets" />}
        width={970}
        onClose={onClose4}
        open={open}
        closable={false}
      >
        <AssetsOvEditForm
          open={open}
          updateId={selectedCustomerId}
          closeForm={onClose4}
          refreshData={toggleRefreshData}
          customerDetails={assetsOvData}
        />
      </Drawer>
      <div className="fi_description_heading">
        <div className="fi_description_data">Assests </div>
        <div className="fi_views_button_space">
          <div
       className="ov_views_edit_button"
          >
            {renderEditButton()}
          </div>
          <div
          className="ov_views_collapse_icon"
            onClick={toggleCollapse4}
          >
            {collapsed4 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed4 && (
        <div className="asset-grid">
          {assetsOvData?.map((asset, index) => (
            <div key={index} className="asset-card">
              <div className="asset-name">{asset?.name || "-"}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssetsOv;
