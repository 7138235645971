import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Button,
  Col,
  Space,
  Select,
  message,
  Modal,
} from "antd";
import FiOvUploadInputForm from "./FiOvUploadInputForm";
import {
  createFIInputFormData,
  patchFIInputFormData,
  // stageToCompleteService,
} from "../../services/FIInputFormServices";
import { getInputformdata } from "../../services/FIInputFormServices";
import { decrypt } from "../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import {
  getAssests,
  getBusinessNatureChoice,
  getOccupationTypeChoice,
  getOfficeLocationTypeChoice,
  getOfficeTypeChoice,
  getOverallStatus,
  getPremisesTypeChoice,
  getBusinessPremisesOwner,
  getSurroundings,
} from "../../services/getCustomerDetailData";
import * as Yup from "yup";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
// import Deviation from "../../../../utils/deviation/views/Deviation";

// Add customer id also
const FiOVForm = ({
  selectedData,
  onSuccess,
  stageId,
  onSuccessfulSubmit,
  fiAddresses,
  formData,
  setFormData,
  index
}) => {
  const { Option } = Select;

  const [BusinessNatureChoice, setBusinessNatureChoice] = useState([]);
  const [premisesTypeChoice, setPremisesTypeChoice] = useState([]);
  const [businessPremisesOwner, setBusinessPremisesOwner] = useState([]);
  const [occupationTypeChoice, setOccupationTypeChoice] = useState([]);
  const [officeTypeChoice, setOfficeTypeChoice] = useState([]);
  const [officeLocationTypeChoice, setOfficeLocationTypeChoice] = useState([]);
  const [overAllStatus, setOverAllStatus] = useState([]);
  const [assets, setAssets] = useState([]);
  const [data, setData] = useState([]);
  // const [businessDesignationChoice, setBusinessDesignationChoice] = useState(
  //   []
  // );
  const [surroundings, setSurroundings] = useState([]);
  const [form] = Form.useForm();
  const [documentUpload, setDocumentUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const { encrypted_loan_id } = useParams();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [documents, setDocuments] = useState([]); 
 
  const validationSchema = Yup.object().shape({
    occupation_type: Yup.string().required("Occupation Type is required"),
    person_contacted_at_office: Yup.string().required(
      "Person Contacted At Office is required"
    ),
    met_person_designation: Yup.string().required(
      "Met Person's Designation is required"
    ),
    customer_designation: Yup.string().required(
      "Applicant's Designation is required"
    ),
    no_of_yrs_in_organization: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .required("No. of Years in Organization is required"),

    no_of_employee_seen: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .required("No. of Employees Seen is required")
      .min(0, "Must be greater than or equal to 0"),

    nature_of_business: Yup.string().required("Nature of Business is required"),
    type_of_premises: Yup.string().required("Type Of Premises is required"),
    area_in_sqft: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .required("Area in Sq.ft is required")
      .min(0, "Must be greater than or equal to 0"),

    turnover: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
      .required("Salary income/turnover is required")
      .min(0, " Salary income/turnover must be greater than 0"),
    ownership: Yup.string().required("Business Premises Ownership is required"),
    company_board: Yup.boolean().required(
      "Company Board selection is required"
    ),
    activity: Yup.boolean().required("Activity selection is required"),
    setup: Yup.boolean().required("Setup selection is required"),
    business_surrounding: Yup.array()
      .of(Yup.string())
      .required("Surrounding is required"),
    office_type: Yup.string().required("Type of Office is required"),
    office_location: Yup.string().required("Location of Office is required"),
    overall_status: Yup.string().required("Overall Status is required"),
    neighbor_check: Yup.string().required("Neighbour Check is required"),
    assets: Yup.array()
      .of(Yup.string())
      .required("At least one asset must be selected"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    setFormData(allValues)
  };


  const getOVFormData = async (selectedData) => {
    try {
      const fiAddressId = selectedData["fi address id"];  
      const response = await getInputformdata(fiAddressId);  
  
      
      if (!response?.data || (Array.isArray(response?.data) && response?.data.length === 0)) {
        return;
      }
      
  
      const parsedSurroundings = JSON.parse(response?.data?.fi_business_premises?.surrounding.replace(/'/g, '"'));
  
      const {
        property_description_heading,
        legal_initiate_input_main_container
      } = response?.data;
  
      let assets = response?.data?.assets.map((asset) => asset.assets.id);
  
      form.setFieldsValue({
        occupation_type: response?.data?.fi_business_occupation?.occupation_type,
        person_contacted_at_office: response?.data?.fi_business_occupation?.person_contacted_at_office,
        met_person_designation: response?.data?.fi_business_occupation?.met_person_designation,
        customer_designation: response?.data?.fi_business_occupation?.customer_designation,
        no_of_yrs_in_organization: response?.data?.fi_business_occupation?.no_of_yrs_in_organization,
        no_of_employee_seen: response?.data?.fi_business_occupation?.no_of_employee_seen,
        nature_of_business: response?.data?.fi_business_occupation?.nature_of_business,
        type_of_premises: response?.data?.fi_business_occupation?.type_of_premises,
        area_in_sqft: response?.data?.fi_business_occupation?.area_in_sqft,
        turnover: response?.data?.fi_business_occupation?.turnover,
        ownership: response?.data?.fi_business_premises?.ownership,
        company_board: response?.data?.fi_business_premises?.company_board,
        activity: response?.data?.fi_business_premises?.activity,
        setup: response?.data?.fi_business_premises?.setup,
        business_surrounding: parsedSurroundings,
        office_type: response?.data?.fi_business_premises?.office_type,
        office_location: response?.data?.fi_business_premises?.office_location,
        overall_status: response?.data?.fi_business_premises?.fi_customer_detail?.overall_status,
        neighbor_check: response?.data?.fi_business_premises?.fi_customer_detail?.neighbor_check,
        assets: assets,
        property_description_heading,
        legal_initiate_input_main_container
      });
      setData(response.data);
      setDocuments(response.data.other_docs);
    } catch (error) {
      
     
      message.error(error);
    }
  };
  
 

  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) { 
      form.setFieldsValue(formData);
    } else if (selectedData) {
      getOVFormData(selectedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, selectedData]);

  
 

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [
          officeypeChoiceResponse,
          occupationTypeChoiceResponse,
          premisesTypeChoiceResponse,
          businessNatureChoiceResponse,
          officeLocationTypeChoiceResponse,
          businessPremisesOwnerResponse,
          SurroundingsResponse,
          overallStatusResponse,
          assetsResponse,
        ] = await Promise.all([
          getOfficeTypeChoice(),
          getOccupationTypeChoice(),
          getPremisesTypeChoice(),
          getBusinessNatureChoice(),
          getOfficeLocationTypeChoice(),
          getBusinessPremisesOwner(),
          getSurroundings(),
          getOverallStatus(),
          getAssests(),
        ]);

        // Batch state updates
        setBusinessNatureChoice(businessNatureChoiceResponse.data);
        setOfficeTypeChoice(officeypeChoiceResponse.data);
        setOccupationTypeChoice(occupationTypeChoiceResponse.data);
        setPremisesTypeChoice(premisesTypeChoiceResponse.data);
        setOfficeLocationTypeChoice(officeLocationTypeChoiceResponse.data);
        setBusinessPremisesOwner(businessPremisesOwnerResponse.data);
        setSurroundings(SurroundingsResponse.data);
        setOverAllStatus(overallStatusResponse.data);
        setAssets(assetsResponse.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchAllData();
  }, []);

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");

    form.setFieldsValue({ [field]: newValue });
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
    console.log(error);
  }

  const handleSubmit = async (values) => {
    // setLegalData(values)
    // setConfirmModalVisible(true)

    setLoading(true);
    try {
      const businessSurroundingString = values.business_surrounding ? values.business_surrounding.toString() : "";


      const newValues = {
        ...values,
        business_surrounding: businessSurroundingString,
        loan_stage_id: stageId,
        loan_detail: loan_id,
        customer_address_detail_id: selectedData["fi address id"],
        visit_type: "OV",
        other_docs: documentUpload,
      };
  
  
      const newpatchValues = {
        ...values,
        id: selectedData["fi address id"],
        business_surrounding: businessSurroundingString,
        loan_stage_id: stageId,
        loan_detail: loan_id,
        customer_address_detail_id: selectedData["fi address id"],
        visit_type: "OV",
        other_docs: documentUpload,
      };

      if (Array.isArray(data) && data.length === 0) {

        const response = await createFIInputFormData(newValues);
        if (response.status === 200 && response?.success) {
          message.success("Form Submitted successfully")
          if (fiAddresses.length === 1) {
          //  setConfirmModalVisible(false);
          } else {
            onSuccessfulSubmit();
            onSuccess();
          }
        
          setConfirmModalVisible(false);
         
    
        }
      } else {

        const response = await patchFIInputFormData(selectedData["fi address id"], newpatchValues);
        if (response.status === 200 && response?.success) {
          message.success("Form Updated successfully")
          if (fiAddresses.length === 1) {
            setConfirmModalVisible(true);
            message.success("Form Updated successfully")
          } else {
            onSuccessfulSubmit();
            onSuccess();
          }
          setConfirmModalVisible(false);
        }

     

      }

   
  
     
   
    } catch (error) {
      setLoading(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }finally {
      setLoading(false);
    }


  


  };



  // const handleConfirmOk = async () => {
   
  // };

 

  return (
    <>
      <Modal
        title="Confirm Completion"
        open={confirmModalVisible}
        // onOk={handleConfirmOk}
        onCancel={() => setConfirmModalVisible(false)}
        okType="danger"
        okButtonProps={{ loading }}
      >
        <p>{`Are you sure you want to complete this form?`}</p>
      </Modal>
      <div className="ov_office">
        <div className="property_description_heading">
          <div className="property_description_data">
            Office Address: {selectedData.full_address}
          </div>
        </div>
      </div>

      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
        className="input_fiald_rv"
        onValuesChange={handleFormValuesChange}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="occupation_type"
              label="Occupation Type"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {occupationTypeChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="person_contacted_at_office"
              label="Person Contacted At Office"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Type Of House"
                onChange={(e) => handleInput(e, "person_contacted_at_office")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="met_person_designation"
              label="Met Person's Designation"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Type Of House"
                onChange={(e) => handleInput(e, "met_person_designation")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="customer_designation"
              label="Applicant's Designation"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter Type Of House"
                onChange={(e) => handleInput(e, "customer_designation")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="no_of_yrs_in_organization"
              label="No. Of Years in Organization"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter No. Of Years in Organization"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="no_of_employee_seen"
              label="No. Of Employees Seen"
              required
              rules={[yupSync]}
            >
              <Input
                placeholder="Please Enter No. Of Employees Seen"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="nature_of_business"
              label="Nature of Business"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {BusinessNatureChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="type_of_premises"
              label="Type Of Premises & Sq.Ft"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {premisesTypeChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="area_in_sqft"
              label="Area in Sq.ft"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter area in sq.ft"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="turnover"
              label="Salary Income/Turnover"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Salary Income/Turnover"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="property_description_heading">
          <div className="property_description_data">Business Premises</div>
        </div>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="ownership"
              label="Business Premises Ownership"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {businessPremisesOwner?.map((business) => (
                  <Option value={business}>{business}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="company_board"
              label="Company Board"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="activity"
              label="Activity"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="setup" label="Setup" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="business_surrounding"
              label="Surrounding"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  const selectedValues = value.join(", ");
                  console.log(selectedValues);
                }}
              >
                {surroundings?.map((surrounding) => (
                  <Option key={surrounding} value={surrounding}>
                    {surrounding}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="office_type"
              label="Type of Office"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {officeTypeChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="office_location"
              label="Location of Office"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {officeLocationTypeChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="property_description_heading">
          <div className="property_description_data">Assets Seen</div>
        </div>

        <Col span={12}>
          <Form.Item name="assets" label="Assets" rules={[yupSync]} required>
            <Select
              mode="multiple"
              placeholder="Please select"
              required={true}
              allowClear
              className="slect_rv_asset"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {assets.map((asset) => (
                <Option value={asset.id}>{asset.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>


        {/* <div className="legal_initiate_input_main_container">
          <Deviation />
        </div> */}

        <div className="property_description_heading">
          <div className="property_description_data">Overall Status</div>
        </div>

        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="overall_status"
              label="Overall Status"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {overAllStatus?.choices?.map((overall) => (
                  <Option value={overall.value}>{overall.value}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="neighbor_check"
              label="Neighbour Check"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter Neighbour Check"
                onChange={(e) => handleInput(e, "neighbor_check")}
              />
            </Form.Item>
          </Col>
        </Row>


        <FiOvUploadInputForm
          form={form}
          setDocumentUpload={setDocumentUpload}
          documents={documents}
        />

        <Space
          direction="horizontal"
          align="center"
          className="neighbour_button_sumbit"
        >
          <Button type="primary" htmlType="submit" >
            Submit
          </Button>
        </Space>
      </Form>
    </>
  );
};

export default FiOVForm;
