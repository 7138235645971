/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Tabs, message, Modal } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveEmploymentKey,
  setInitialCustomerEmployment,
  setEmployeementApicalled,
  removeEmployeement
} from "../../../../redux/salesFormSlice";
import { getEmploymentsTabsData, deletePensionerDetailsTabsData, deleteSalariedTabsData, deleteRentalTabsData, deleteSelfEmployedTabsData } from "../../services/salesformservices";
import EmploymentType from "../../../loanApplication/customerTabDetails/employmentDetails/views/EmploymentType";

const SalesFormEmploymentForm = ({ activeCustomer, isSalesForm }) => {

  const [openModal, setOpenModal] = useState(false);
  const [targetKey, setTargetKey] = useState(null);

  const { customers } = useSelector((state) => state.salesForm);

  const employmentDetails =
    customers[parseInt(activeCustomer)]?.employmentDetails?.employments;

  const activeEmploymentKey =
    customers[parseInt(activeCustomer)]?.employmentDetails?.activeEmploymentKey;

  const employeeApiCalled =
    customers[parseInt(activeCustomer)]?.employmentDetails?.employeeapicalled;

  const activeEmployment = employmentDetails[activeEmploymentKey];

  const customerId = customers[parseInt(activeCustomer)]?.id;
  const dispatch = useDispatch();

  useEffect(() => {
    const getCustomerAddresses = async () => {
      const response = await getEmploymentsTabsData(customerId);

      if (response?.data?.length === 0) {
        dispatch(
          setInitialCustomerEmployment({
            activeCustomer: activeCustomer,
            employments: [
              {
                id: null,
                type: null,
              },
            ],
          })
        );
        dispatch(
          setEmployeementApicalled({ index: activeCustomer })
        )
      } else {
        const employments = response.data.map((item) => {
          return {
            id: item.id,
            type: item.employment_type_id,
          };
        });
        dispatch(
          setInitialCustomerEmployment({
            activeCustomer: activeCustomer,
            employments: employments,
          })
        );
        dispatch(
          setEmployeementApicalled({ index: activeCustomer })
        )
      }
    };

    if (employeeApiCalled === 0) {
      getCustomerAddresses();
    }
  }, []);


  const onEdit = (targetKey, action) => {


    if (action === 'add') {
      addNewEmployment();
    } else {
      setTargetKey(targetKey);
      setOpenModal(true);
    }
  };

  const handleOk = async () => {
    const employment = employmentDetails[parseInt(activeEmploymentKey)];


    if (employment.id) {
      if (employmentDetails.length > 1) {
        const deleteTabById = async () => {
          try {
            if (employment.type === 1) {
              const response = await deleteSalariedTabsData(employment.id);

              if (response.status === 200 && response.success === true) {
                message.success("Tab deleted successfully");

                dispatch(removeEmployeement({
                  activeCustomerKey: activeCustomer,
                  activeEmploymentKey: targetKey,
                }));

                if (activeEmploymentKey > 0)
                {  
                dispatch(
                  changeActiveEmploymentKey({
                    activeCustomer: activeCustomer,
                    key: activeEmploymentKey - 1,
                  })
                );
              }
              } else {
                message.error("Failed to delete the tab");
              }
            } else if (employment.type === 2) {
              const response = await deleteSelfEmployedTabsData(employment.id);

              if (response.status === 200 && response.success === true) {
                message.success("Tab deleted successfully");

                dispatch(removeEmployeement({
                  activeCustomerKey: activeCustomer,
                  activeEmploymentKey: targetKey,
                }));

                if (activeEmploymentKey > 0)
                { 
                dispatch(
                  changeActiveEmploymentKey({
                    activeCustomer: activeCustomer,
                    key: activeEmploymentKey - 1,
                  })
                );
              }
              } else {
                message.error("Failed to delete the tab");
              }
            } else if (employment.type === 3) {
              const response = await deleteRentalTabsData(employment.id);

              if (response.status === 200 && response.success === true) {
                message.success("Tab deleted successfully");

                dispatch(removeEmployeement({
                  activeCustomerKey: activeCustomer,
                  activeEmploymentKey: targetKey,
                }));

                if (activeEmploymentKey > 0)
                {
                dispatch(
                  changeActiveEmploymentKey({
                    activeCustomer: activeCustomer,
                    key: activeEmploymentKey - 1,
                  })
                );
              }
              } else {
                message.error("Failed to delete the tab");
              }
            } else if (employment.type === 4) {
              const response = await deletePensionerDetailsTabsData(employment.id);

              if (response.status === 200 && response.success === true) {
                message.success("Tab deleted successfully");

                dispatch(removeEmployeement({
                  activeCustomerKey: activeCustomer,
                  activeEmploymentKey: targetKey,
                }));

                if (activeEmploymentKey > 0)
                  {
                dispatch(
                  changeActiveEmploymentKey({
                    activeCustomer: activeCustomer,
                    key: activeEmploymentKey - 1,
                  })
                );
              }
              } else {
                message.error("Failed to delete the tab");
              }
            }
          } catch (error) {
            message.error(error?.response?.data?.message || "Error deleting tab");
          }
        };

        deleteTabById();
      } else {
        message.error("Cannot Delete Last Tab")
      }
    } else {
      if (employmentDetails.length > 1) {
        dispatch(removeEmployeement({
          activeCustomerKey: activeCustomer,
          activeEmploymentKey: activeEmploymentKey,
        }));

        if (activeEmploymentKey > 0)
          {
        dispatch(
          changeActiveEmploymentKey({
            activeCustomer: activeCustomer,
            key: activeEmploymentKey - 1,
          })
        );
      }
      } else {
        message.error("Cannot Delete Last Tab")
      }
    }

    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };



  const addNewEmployment = () => {

    const updatedEmployees = [
      ...employmentDetails,
      {
        id: null,
        type: null,
      },
    ];

    dispatch(
      setInitialCustomerEmployment({
        activeCustomer: activeCustomer,
        employments: updatedEmployees,
      })
    );

    dispatch(
      changeActiveEmploymentKey({
        activeCustomer: activeCustomer,
        key: updatedEmployees.length - 1,
      })
    );
  };

  const onChange = (key) => {
    dispatch(
      changeActiveEmploymentKey({
        activeCustomer: activeCustomer,
        key: key,
      })
    );
  };

  return (
    <>
    <Tabs
      type="editable-card"
      onChange={onChange}
      onEdit={onEdit}
      destroyInactiveTabPane
      activeKey={activeEmploymentKey.toString()}
    >
      {employmentDetails?.map((employment, index) => (
        <TabPane tab={`Employment ${index + 1}`} key={index.toString()}>
          <EmploymentType
            customer_id={customerId}
            open={true}
            activeCustomerKey={activeCustomer}
            activeEmploymentKey={activeEmploymentKey}
            isSalesForm={true}
            id={activeEmployment?.id}
            employment_type_id={activeEmployment?.type}
          />

        </TabPane>
      ))}

     

    </Tabs>
     <Modal
     title="Confirm Delete"
     open={openModal}
     onOk={handleOk}
     onCancel={handleCancel}
     okType="danger"
   >
     <p>Are you sure you want to delete this reference tab?</p>
   </Modal>
  </>

  );
};

export default SalesFormEmploymentForm;
