import React, { useEffect, useState } from "react";
import "../styles/bankAccountDetails.css";
// import verify from "../assets/Group.png";
import "../../style/customerStyle.css";
import add from "../assets/Add.png";
import { Drawer, Modal, message } from "antd";
import Header from "../../../../layout/views/Header";
import BankAccountDetailsForm from "./BankAccountDetailsForm";
import { getBankAccountDetailsByIdService, deleteBankByIdService } from "../services/bankAccountDetailsService";
import { useParams } from "react-router-dom";
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import HistoryTable from "../../../history/views/HistoryTable";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import "../../../../commonCss/commonStyle.css";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import DeleteButton from "../../../../../utils/deleteButton/DeleteButton";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";

const BankAccountDetails = () => {
  const [open, setOpen] = useState(false);
  const [bankAccountData, setBankAccountData] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { encrypted_loan_id } = useParams();
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState();
  const [loading, setLoading] = useState(true);
  const { user_data } = useSelector((state) => state.user);
  const [selectedBank, setSelectedBank] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedCustomer(null);
    setSelectedPerson(null);
    setSelectedBank(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBankAccountDetailsByIdService(loan_id);
        setBankAccountData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  const renderEditButton = (showDrawer, id, person) => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
          setSelectedCustomer(id);
          setSelectedPerson(person);
          setSelectedCustomerId(person.customer_id);
        }}
      >
        <EditButton />
      </div>
    );
  };

  const BankAccountParameters = [
    {
      label: "Bank Account Details",
    },
    {
      label: "Account Holder Name",
      value: "account_holder_name",
    },
    {
      label: "Bank",
      value: "bank.display_name",
    },
    {
      label: "Account Number",
      value: "account_number",
    },
    {
      label: "Bank Branch",
      value: "bank_branch.display_name",
    },
    {
      label: "Account Type",
      value: "account_type",
    },
    {
      label: "IFSC Code",
      value: "ifsc_code",
    },
    {
      label: "MICR Code",
      value: "micr_code",
    },
  ];

  const fieldNameMap = {
    account_holder_name: "Account Holder Name",
    bank: "Bank",
    account_number: "Account Number",
    bank_branch: "Bank Branch",
    account_type: "Account Type",
    ifsc_code: "IFSC Code",
    bd_modified_at:"Business Modified Date",
    bd_create_date_at:"Business Created Date",
    micr_code: "MICR Code",
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const handleAddButtonClick = (person) => {
    setSelectedCustomerId(person.customer_id);
    setSelectedPerson(person);
  };
  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      // case 4:
      //   return 'tab_form_main_data_four_entries';
      default:
        return "";
    }
  };
  const deleteBank = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteBankByIdService(selectedBank);
      if (response.status === 200 && response.success === true) {
        message.success("Bank deleted successfully");
        toggleRefreshTableData();
        setSelectedBank(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };
  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title={selectedCustomer ? "Edit" : "Add"}
              onClose={onClose}
              name={
                selectedPerson
                  ? `${selectedPerson?.customer_name}'s  Bank Account Details`
                  : ` ${selectedPerson?.customer_name}'s Bank Account Details`
              }
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          <BankAccountDetailsForm
            refreshData={toggleRefreshTableData}
            id={selectedCustomer}
            customer_id={selectedCustomerId}
            open={open}
            closeForm={onClose}
          />
        </Drawer>

        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : bankAccountData && bankAccountData.max_count > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">Key Parameter's</th>
                  {bankAccountData?.results.map((person, index) => (
                    <th key={index} className="tab_form_top_heading">
                      <div className="add_button_alignment">
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`} </span>
                            {/* <img
                              src={verify}
                              alt="group"
                             
                              className="verify_img_bank"
                            /> */}
                          </span>
                        </div>
                        <ConditionalRender
                          userPermissions={user_data?.permissions}
                          requiredPermissions={["POSTCUST"]}
                        >
                          <div
                            className="tab_form_sub_edit"
                            onClick={() => {
                              showDrawer();
                              handleAddButtonClick(person);
                            }}
                          >
                            <img src={add} alt="Add Button" />
                          </div>
                        </ConditionalRender>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {bankAccountData &&
                  [...Array(bankAccountData.max_count)].map((_, rowIndex) =>
                    BankAccountParameters.map((header, headerIndex) => (
                      <tr
                        key={`row-${headerIndex}`}
                        className={
                          header.label === "Bank Account Details"
                            ? "bank-row"
                            : ""
                        }
                      >
                        <td className="tab_form_left_heading">
                          {header.label === "Bank Account Details"
                            ? `Bank Account Details ${rowIndex + 1}`
                            : header.label}
                        </td>

                        {bankAccountData?.results.map((person, colIndex) => (
                          <td
                            key={`col-${colIndex}`}
                            className={`tab_form_main_data_table ${getTableColumnClass(
                              bankAccountData.results.length
                            )}`}
                          >
                            {person.customer_bank_details &&
                              person.customer_bank_details[rowIndex] ? (
                              <>
                                {header.hasOwnProperty("value") &&
                                  (header.value
                                    .split(".")
                                    .reduce(
                                      (acc, key) => acc?.[key],
                                      person?.customer_bank_details?.[rowIndex]
                                    ) ||
                                    "-")}

                                {header.label === "Bank Account Details" && (
                                  <div className="bank_account_deatils_heading"
                           
                                  >
                                    <ConditionalRender
                                      userPermissions={user_data?.permissions}
                                      requiredPermissions={["DELTCUST"]}
                                    >
                                      <div className="tab_form_sub_edit"
                                        onClick={() => {
                                          setSelectedBank(person.customer_bank_details[rowIndex].id);
                                          setOpenModal(true);
                                        }}>
                                        <DeleteButton />
                                      </div>
                                    </ConditionalRender>
                                    {person?.customer_bank_details[rowIndex]
                                      ?.created_at &&
                                      person?.customer_bank_details[rowIndex]
                                        ?.modified_at &&
                                      isModifiedAfterCreated(
                                        person?.customer_bank_details[rowIndex]
                                          ?.created_at,
                                        person?.customer_bank_details[rowIndex]
                                          ?.modified_at
                                      ) && (
                                        <div className="tab_form_sub_edit">
                                          <HistoryTable
                                            id={
                                              person?.customer_bank_details[
                                                rowIndex
                                              ]?.id
                                            }
                                            bench_id={"3fb485af05858055"}
                                            fieldNameMap={fieldNameMap}
                                          />
                                        </div>
                                      )}
                                    <ConditionalRender
                                      userPermissions={user_data?.permissions}
                                      requiredPermissions={["EDITCUST"]}
                                    >
                                      {renderEditButton(
                                        showDrawer,
                                        person.customer_bank_details[rowIndex]
                                          .id,
                                        person
                                      )}
                                    </ConditionalRender>
                                  </div>
                                )}
                              </>
                            ) : header === "Bank Account Details" ? (
                              ""
                            ) : (
                              <span>-</span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
              </tbody>
            </table>
          ) : (
            <div className='fade-in'>
            <div>
              <table className='tab_form_main_table no_data_table_height'>
                <thead>
                  <tr>
                    <th className="tab_form_key_parameters">
                    Key Parameter's
                    </th>
                    {bankAccountData?.results?.map((person, index) => (
                      <th
                        key={index}
                        className="tab_form_top_heading no_data_heading_bottom_border"
                      >
                        <span className='no_data_heading_content'>
                          <div>
                            <span>{`${person?.customer_name}`} </span>
                            <span>
                              <span>{`(${person?.customer_type})`} </span>
                              {/* <img
                                src={verify}
                                alt="group"
                                className='verify_icon'
                              /> */}
                            </span>
                          </div>
                          <ConditionalRender
                            userPermissions={user_data?.permissions}
                            requiredPermissions={["POSTCUST"]}
                          >
                            <div
                              className="tab_form_sub_edit"
                              onClick={() => {
                                showDrawer();
                              handleAddButtonClick(person);
                              }}
                            >
                              <img src={add} alt="Add Button" />
                            </div>
                          </ConditionalRender>
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Placeholder to ensure table body aligns */}
                  {bankAccountData?.results?.length ? (
                    <tr>
                      <td colSpan={bankAccountData?.results?.length + 1}>
                        {/* Your data rows go here */}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td
                        colSpan={bankAccountData?.results?.length + 1}
                      ></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div
              className='no_data_icon'
            >
             <CustomNoData />
            </div>
          </div>
          )}
        </div>
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteBank();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </>
  );
};

export default BankAccountDetails;
