import React, { useEffect } from "react";
import { Button, Col, Form, Row, Space, Input, Select } from "antd";

// import {
//   createDepartmentService,
//   getDepartmentByIdService,
//   updateDepartmentByIdService,
// } from "../../services/departmentService";
import "../styles/documentDetailsForm.css";

import * as Yup from "yup";
// import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;
const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required!")
    .matches(/^[A-Za-z0-9]+$/, 'Code can only contain letters and digits')
    .min(2, "Code must be at least 2 characters!")
    .max(16, "Code must be at max 16 characters!")
    .label("Code"),
  name: Yup.string()
    .required("Name is required!")
    .matches(/^[A-Za-z ]+$/, 'Name can only contain letters')
    .min(2, "Name must be at least 2 characters!")
    .max(32, "Name must be at max 32 characters!")
    .required("Name is required")
    .label("Name"),
  display_name: Yup.string()
    .required("Display Name is required!")
    .matches(/^[A-Za-z ]+$/, 'Display Name can only contain letters')
    .min(2, "Display Name must be at least 2 characters!")
    .max(32, "Display Name must be at max 32 characters!")
    .required("Display Name is required")
    .label("Display Name"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const DocumentDetailsForm = ({ refreshData, id, open, closeForm,onNext }) => {
  const [form] = Form.useForm();

  // Function to fetch department data when the form is opened for editing
  //   const getDepartmentData = async () => {
  //     if (id) {
  //       try {
  //         const response = await getDepartmentByIdService(id);
  //         const { name, code, display_name, is_active, is_verified } =
  //           response?.data;
  //         form.setFieldsValue({
  //           name,
  //           code,
  //           display_name,
  //           is_active,
  //           is_verified,
  //         });
  //       } catch (error) {
  //         message.error(
  // ErrorMessage(error?.response?.status, error?.response?.data?.message)
  // );
  //       }
  //     }
  //   };

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  //   const handleSubmit = async (values) => {
  //     try {
  //       if (id) {
  //         const response = await updateDepartmentByIdService(id, values);
  //         if ((response.status = 200 && response.success)) {
  //           message.success("Department successfully updated");
  //           refreshData();
  //           closeForm();
  //         }
  //       } else {
  //         const response = await createDepartmentService(values);
  //         if ((response.status = 201 && response.success)) {
  //           message.success("Department successfully created");
  //           refreshData();
  //           closeForm();
  //         }
  //       }
  //     } catch (error) {
  //       closeForm();
  //       message.error(
  // ErrorMessage(error?.response?.status, error?.response?.data?.message)
  // );
  //     }
  //   };

  useEffect(() => {
    if (open) {
      //   getDepartmentData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  return (


    <Form
      form={form}
      //   onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        is_active: true,
        is_verified: true,
      }}
    >


      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="document_path" label="Document Path" rules={[yupSync]}>
            <Input placeholder="Please Enter Your Document Path"
              onChange={(e) => handleInput(e, "document_path")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="document_type" label="Document Type">
            <Select placeholder="Please Select">
              <Option value={true}>ABC</Option>
              <Option value={false}>XYZ</Option>
            </Select>
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item name="file_type" label="File Type">
            <Select placeholder="Please Select">
              <Option value={true}>ABC</Option>
              <Option value={false}>XYZ</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>



      <Row>
        <Col span={8}>
          <Form.Item name="file_size" label="File Size">
            <Select placeholder="Please Select">
              <Option value={true}>ABC</Option>
              <Option value={false}>XYZ</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>


     

{onNext ? ( 
  <Space
    direction="horizontal"
    align="center"
    className="customer_tabs_form_buttons"
  >
    <Button type="primary" onClick={onNext}>
      Save And Next
    </Button>
  </Space>
) : (
  <Space
        direction="horizontal"
        align="center"
        className="customer_tabs_form_buttons"
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>

)}
    </Form>
  );
};

export default DocumentDetailsForm;
