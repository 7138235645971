import { axiosRequest } from "../../../../../utils/api/axiosRequest";

export const getBasicDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateBasicDetailsByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/loan-detail/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}



export const getListSchemeService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/scheme/get-scheme-by-loantype/${id}`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllListSchemeService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/scheme/list`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const listLoanPurposeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-purpose/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLoanStatusService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/loan-status/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLoanTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLocationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/locations/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getProductEndUseService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/product-end-use/list`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getSPOCLocationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/spoc-location/list`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getProductService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/product/list`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getDueDateService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/get-emi-due-date`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getInterestTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/interest-type`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getRiskCategorizationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/risk-categorization`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getTransactionTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/transacation-type`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateBasicDetailsSalesByIdService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/loan/loan-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}