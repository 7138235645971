import React from 'react';
import { Space, Typography } from 'antd';
import UserPopover from './UserPopOver';

const { Text } = Typography;

const UserMentionProcessor = ({ content,isCurrentUser }) => {
  // Match @username@ pattern where username can contain spaces
  const processContent = () => {
    const parts = [];
    let currentIndex = 0;
    const regex = /@([^@]+)@/g;
    let match;

    while ((match = regex.exec(content)) !== null) {
      // Add text before the match
      if (match.index > currentIndex) {
        parts.push({
          type: 'text',
          content: content.slice(currentIndex, match.index)
        });
      }

      // Add the username
      parts.push({
        type: 'mention',
        username: match[1].trim(),
        original: match[0]
      });

      currentIndex = match.index + match[0].length;
    }

    // Add remaining text after last match
    if (currentIndex < content.length) {
      parts.push({
        type: 'text',
        content: content.slice(currentIndex)
      });
    }

    return parts;
  };

  return (
    <Space direction="horizontal" wrap size={0} style={{marginBottom:'10px'}}>
      {processContent().map((part, index) => {
        if (part.type === 'mention') {
          return (
            <UserPopover
              key={`mention-${index}`}
              username={part.username}
              part={part.original}
              isCurrentUser={isCurrentUser}
            />
          );
        }
        return (
          <Text 
          key={`text-${index}`} 
          style={{
            color: !isCurrentUser ? "white" : "black",
            fontSize: '15px',
            marginLeft: !isCurrentUser ? '3px' : '3px',
            overflow: 'auto',
            whiteSpace: 'nowrap'
          }}
        >
          {part.content}
        </Text>
        
        );
      })}
    </Space>
  );
};

export default UserMentionProcessor;