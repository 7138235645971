import React, { useEffect } from "react";
import { Button, Form, Input, Select, Space, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;

const ResidenceNeighborForm = ({ closeForm, editValue, setEnteredData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editValue) {
      form.setFieldsValue({
        documents: [
          {
            name: editValue.name,
            key: editValue.key,
            remark: editValue.remark,

          },
        ],
      });
    }
  }, [form, editValue]);

  const handleSubmit = (values) => {
    setEnteredData(values.documents);
    form.resetFields();
    closeForm();
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.List name="documents" initialValue={[{ name: "", remark: "" }]}>
        {(fields, { add, remove }) => {
          const columns = [
            {
              title: "Sr. No",
              dataIndex: "key",
              key: "sr_no",
              render: (text, record, index) => index + 1,
            },
            {
              title: "Name  ",
              dataIndex: "name",
              key: "name",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "name"]}
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <TextArea rows={1} placeholder="Enter Name" />
                </Form.Item>
              ),
            },
            {
              title: "Remark",
              dataIndex: "remark",
              key: "remark",
              render: (text, record, index) => (
                <Form.Item
                  name={[fields[index].name, "remark"]}
                  rules={[{ required: true, message: "Remark is required" }]}
                >
                  <Select placeholder="Select Remark" className="remark_seletc_rv">
                    <Option value="Positive">Positive</Option>
                    <Option value="Negative">Negative</Option>
                  </Select>
                </Form.Item>
              ),
            },

            {
              title: "Actions",
              fixed: "right",
              render: (_, record, index) => (
                <Space size="middle">
                  <DeleteOutlined
                    className={
                      fields.length === 1 ? "field-color single" : "field-color multiple"
                    }
                    onClick={() => {
                      if (fields.length > 1) {
                        remove(index);
                      }
                    }}
                    disabled={fields.length === 1}
                    title={
                      fields.length === 1
                        ? "At least one document is required"
                        : "Delete this document"
                    }
                  />
                </Space>
              ),
            }

          ];

          return (
            <>
              <Table
                dataSource={fields.map((field) => ({ ...field, key: field.key }))}
                columns={columns}
                pagination={false}
              />
              <Button type="primary" onClick={() => add()}>
                Add Neighbour
              </Button>
            </>
          );
        }}
      </Form.List>
      <Space direction="horizontal" align="center"
        className="neighbour_button_sumbit">
        <Button type="primary" htmlType="submit">
         Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ResidenceNeighborForm;
