import { Form, Row, Col, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import {
  listAddressType,
  viewAddressDetailsByIdService,
} from "../services/addressDetailsServices";
import OfficeForm from "./OfficeForm";

const validationSchema = Yup.object().shape({
  address_type: Yup.string().required("Address type is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const AddressType = ({
  customer_id,
  open,
  addressid,
  loan_detail,
  isSalesForm,
  activeCustomerKey,
  activeAddressKey,
  closeForm,
  refreshData,
}) => {
  const [form] = Form.useForm();
  const [addressTypes, setAddressTypes] = useState([]);
  const [selectedAddressType, setSelectedAddressType] = useState(null);

  useEffect(() => {
    const getAllEmploymentType = async () => {
      try {
        const response = await listAddressType();
        setAddressTypes(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllEmploymentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleAddressTypeChange = (value) => {
    setSelectedAddressType(value);
  };

  const getOfficeData = async (addressid) => {
    try {
      const response = await viewAddressDetailsByIdService(addressid);
      const addressTypeId = response?.data?.address_type?.id; // Directly extract the address type id

      // Set the selected address type and form field
      setSelectedAddressType(addressTypeId);
      form.setFieldsValue({
        address_type: addressTypeId,
      });
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (addressid) {
      getOfficeData(addressid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setSelectedAddressType(null);
    }
  }, [open, form]);

  return (
    <>
      <Form form={form} layout="vertical" initialValues={{}}>
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col span={8}>
            <Form.Item
              name="address_type"
              label="Address Type"
              rules={[yupSync]}
              required
            
            >
              <Select
                mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                onChange={handleAddressTypeChange}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={addressid}
              >
                {addressTypes?.map((Address_type) => (
                  <Select.Option
                    key={Address_type.id}
                    value={Address_type.id} // Option value
                  >
                    {Address_type.display_name} {/* Option display name */}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {selectedAddressType && (
        <OfficeForm
          customer_id={customer_id}
          open={open}
          closeForm={closeForm}
          selectedAddressType={selectedAddressType}
          loan_detail={loan_detail}
          id={addressid}
          isSalesForm={isSalesForm}
          activeCustomerKey={activeCustomerKey}
          activeAddressKey={activeAddressKey}
          refreshData={refreshData}
        />
      )}
    </>
  );
};

export default AddressType;
