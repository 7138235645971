import { Table } from 'antd';
import React, { useState } from 'react'
import IndianNumberFormat from '../../../../../utils/indianNumberFormat/IndianNumberFormat';
import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import { useSelector } from 'react-redux';
import { UpOutlined, DownOutlined } from "@ant-design/icons";

const IncomeEstimateView = ({ nonCashIncomeData, renderEditButton, showDrawer }) => {
    const [collapseSections, setCollapseSections] = useState(true);
    const { user_data } = useSelector((state) => state.user);

    const IncomeMultipliercolumns = [
        {
            title: 'SR.NO',
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'F.Y',
            dataIndex: 'financial_year',
            render: (text) => text ? text : '-',
        },
        {
            title: 'Profit After Tax  - APPLICANT',
            dataIndex: 'profit_after_tax',
            render: (text) => (text ? IndianNumberFormat(text) : '-'),
        },
        {
            title: 'Depreciation',
            dataIndex: 'depreciation',
            render: (text) => (text ? IndianNumberFormat(text) : '-'),
        },
        {
            title: 'Interest Of Loan Emi',
            dataIndex: 'interest_on_loans',
            render: (text) => (text ? IndianNumberFormat(text) : '-'),
        },
        {
            title: (
                <div>
                    Interest paid to family members - <br />
                    provided they are on loan structure
                </div>
            ),
            // title: 'Interest paid to family members - provided they are on loan structure ',
            dataIndex: 'interest_paid_to_family_members',
            key: 'interest_paid_to_family_members',
            render: (text) => (text ? IndianNumberFormat(text) : '-'),
        },
        {
            title: 'Rental Income - Cash',
            dataIndex: 'rental_income_cash',
            key: 'rental_income_cash',
            render: (text) => (text ? IndianNumberFormat(text) : '-'),
        },

        {
            title: 'Rental Income - Non Cash',
            dataIndex: 'rental_income_non_cash',
            key: 'rental_income_non_cash',
            render: (text) => (text ? IndianNumberFormat(text) : '-'),
        },
        {
            title: 'Dividend / Interest',
            dataIndex: 'dividend_or_interest',
            key: 'dividend_or_interest',
            render: (text) => (text ? IndianNumberFormat(text) : '-'),
        },
        {
            title: 'Agriculture Income',
            dataIndex: 'agricultural_income',
            key: 'agricultural_income',
            render: (text) => (text ? IndianNumberFormat(text) : '-'),
        },
    ];
    return (
        <div className="heading_details_container">
            <div className={`heading_text_cam_income_details ${collapseSections ? "" : "no-border"}`}>
                <div>Income details</div>
                {collapseSections ? (
                    <UpOutlined
                        onClick={() => setCollapseSections(!collapseSections)}
                    />
                ) : (
                    <DownOutlined
                        onClick={() => setCollapseSections(!collapseSections)}
                    />
                )}
            </div>
            {collapseSections && (
                <div>
                    {nonCashIncomeData?.map((data) => (
                        <div key={data.id} style={{ marginBottom: "20px" }}>
                            <div className="heading_text">{data.customer?.name}
                                <ConditionalRender
                                    userPermissions={user_data?.permissions}
                                    requiredPermissions={["EDITCAM1"]}
                                >
                                    {renderEditButton(showDrawer, data.customer?.id)}
                                </ConditionalRender></div>
                            <div style={{ overflowX: "auto" }}>
                                <Table
                                    className="insurance_table"
                                    columns={IncomeMultipliercolumns}
                                    dataSource={data.income_detail}
                                    pagination={false}
                                    rowKey="id"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default IncomeEstimateView