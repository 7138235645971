import { useEffect } from 'react';

const useAutoLogout = (logoutFunction, timeout = 30 * 60 * 1000) => {
  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        logoutFunction();
        localStorage.setItem('autoLogout', Date.now()); // Notify other tabs
      }, timeout);
    };

    const handleActivity = () => {
      resetTimer();
      localStorage.setItem('userActivity', Date.now()); // Notify activity
    };

    // Sync logout across tabs
    const handleStorageEvent = (event) => {
      if (event.key === 'userActivity') {
        resetTimer(); // Reset timer if activity occurs in another tab
      }
      if (event.key === 'autoLogout') {
        logoutFunction(); // Logout if another tab triggers logout
      }
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('storage', handleStorageEvent);

    // Start the timer
    resetTimer();

    // Cleanup
    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [logoutFunction, timeout]);
};

export default useAutoLogout;
