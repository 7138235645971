import { useSelector } from "react-redux";

// Define the reusable function
function AuthorizeTableColumns(
  columns,
  columnsToAdd,
  userPermissions,
  requiredPermissions
) {
  const { user_data } = useSelector((state) => state.user);
  let updatedColumns = [...columns];
  const userRoles = user_data?.roles || [];
  const hasRequiredPermissions = userPermissions?.some((permission) =>
    requiredPermissions.includes(permission?.code)
  );

  // const hasAdminPermission = userPermissions?.some(
  //   (permission) => permission.code === "MPADMIN1" || permission.code === "SUPADMIN"
  // );

  const hasAdminRole = userRoles?.some(
    (role) => role.code === "MPADMIN1" || role.code === "SUPADMIN"
  );
  if (hasRequiredPermissions || hasAdminRole) {
    updatedColumns = [...columns, ...columnsToAdd];
  }

  return updatedColumns;
}

export default AuthorizeTableColumns;
