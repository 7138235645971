import { axiosRequest } from "../api/axiosRequest";

export const getTableDataService = (
  apiPath,
  page,
  limit,
  searchQuery,
  sortField,
  sortOrder,
  filters
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Default pagination values if not provided
      page = page ? page : 1;
      limit = limit ? limit : 10;

      // Construct the path with pagination parameters
      let path = `${apiPath}${apiPath.includes('?') ? '&' : '?'}page=${page}&limit=${limit}`;

      // If searchQuery is provided, append the search query to the path
      if (searchQuery.length > 0) {
        path = `${apiPath}${apiPath.includes('?') ? '&' : '?'}search=${searchQuery}&page=${page}&limit=${limit}`;
      }

      // Uncomment this section if you need to check for specific conditions like 'get_inactive=True'
      // const containsInactive = apiPath.includes("get_inactive=True");

      // If the apiPath contains both 'get_inactive=True' and 'order=descend', only append pagination parameters
      // if (containsInactive) {
      //   path = `${apiPath}&page=${page}&limit=${limit}`;
      // } else {
      //   // Otherwise, construct the path with pagination and other optional parameters
      //   path = `${apiPath}?page=${page}&limit=${limit}`;

      //   // Append search query if provided
      //   if (searchQuery.length > 0) {
      //     path = `${apiPath}?search=${searchQuery}&page=${page}&limit=${limit}`;
      //   }

      // Append sorting parameters if provided
      if (sortField && sortOrder) {
        path += `&field=${sortField}&order=${sortOrder}`;
      }

      // Append filters if provided
      if (filters && filters.filter) {
        Object.keys(filters).forEach((key) => {
          if (filters[key] !== null) {
            if (Array.isArray(filters[key]) && filters[key].length > 0) {
              const value = filters[key].join(",");
              path += `&${key}=${value}`;
            } else {
              path += `&${key}=${filters[key]}`;
            }
          }
        });
      }
      // }

      // Make the GET request with the constructed path
      const response = await axiosRequest.get(path);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};





