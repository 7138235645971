const IndianRupeeFormatter =({ value }) => {
    const formattedValue = new Intl.NumberFormat('en-IN', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    }).format(value);

    return <span>{formattedValue}</span>;
}


export default IndianRupeeFormatter