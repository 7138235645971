import React from 'react'
import {
    DeleteOutlined,
} from "@ant-design/icons";
const DeleteButton = () => {
    return (
        <><DeleteOutlined
            style={{
                // border: "1px solid rgb(228, 231, 236)",
                // border:"1px solid red",
                border:"1px solid #d9d9d9",
                width: "25px",
                height: "24.5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#FFFFFF",
                color: "#1788AF",
                // color:"#667085",
                fontSize: "12px",
                cursor: "pointer",
                marginRight:"4px",
                // margin:"0.5px",
                marginBottom:'1px',
                marginTop:"0px",
             
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
            }}
 /></>
    )
}

export default DeleteButton