import React, { useState } from "react";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import ConstructionLine from '../../construction_timeline/views/ConstructionLine';
import DpdString from '../../dpdString/views/DpdString';
import ChargesReceivable from '../../chargesReceivable/views/ChargesReceivable';
import PartDisbursalDetails from '../../partdisbursalDetails/views/PartDisbursalDetails';
import "../../../loanApplication/customerTabDetails/customerDetails/styles/customerDetails.css"
import "../styles/partDisbursedTable.css";
import PaymentDetails from "../../paymentDetails/views/PaymentDetails";

const PartDisbursedTable = () => {
    const [showCustomerDetails, setShowCustomerDetails] = useState(true);
    const toggleCustomerDetails = () => {
        setShowCustomerDetails((prev) => !prev);
    };

    
    return (
        <div className="part_disbursed_main_container" >
            <div
                className={`part_disbursed_main_heading ${!showCustomerDetails ? 'no-bottom-border' : ''}`}
            >
                <div>
                    <h2 className="heading_loan_details">Part Disbursed</h2>
                </div>
                <div className="customer_icon">
                    {showCustomerDetails ? (
                        <AiOutlineUp
                            className="part_disbursed_table_collapse"
                            onClick={toggleCustomerDetails}
                        />
                    ) : (
                        <AiOutlineDown
                            className="part_disbursed_table_collapse"
                            onClick={toggleCustomerDetails}
                        />
                    )}
                </div>
            </div>
            <div  className={showCustomerDetails ? 'show-customer-details' : 'hide-customer-details'}>
                <ConstructionLine/>
                <DpdString />
                <PaymentDetails/>
                <ChargesReceivable />
                <PartDisbursalDetails />
            </div>
        </div>
    );
};

export default PartDisbursedTable;
