import React, { useState } from 'react';
import '../styles/documentVerificationForm.css';
import { Button, Checkbox, message } from "antd"; 
import DataTable from "../../../utils/dataTable/DataTable";
import { submitVerifiedDocuments } from '../services/salesformservices';
import RViewerJS from 'viewerjs-react';

const DocumentListVerification = ({ onNext, customer_id }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [checkedItems, setCheckedItems] = useState(new Set()); // Use Set for better performance


  // Handle selecting all checkboxes
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      // Select all checkboxes
      setCheckedItems(new Set(filteredData.map(record => record.id)));
    } else {
    // Deselect all checkboxes
      setCheckedItems(new Set());
    }
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (record) => {
    setCheckedItems(prev => {
      const updated = new Set(prev);
      if (updated.has(record.id)) {
        updated.delete(record.id); // Deselect the item
      } else {
        updated.add(record.id); // Select the item
      }
      return updated;
    });
  };

  // Check if all items are selected (for the master checkbox)
  const isAllSelected = filteredData.length > 0 && filteredData.every(record => checkedItems.has(record.id));

  // Table column configuration
  const tableColumns = [
    {
      title: (
        <Checkbox
          onChange={handleSelectAllChange}
          checked={isAllSelected}
        />
      ),
      render: (_, record) => {
        const isChecked = checkedItems.has(record.id);

        return <Checkbox checked={isChecked} onChange={() => handleCheckboxChange(record)} />;
      },
    },
    {
      title: "Document List",
      dataIndex: "code",
      sorter: true,
      render: (_, record) => record.document_category?.name || "-",
    },
    {
      title: "Document Type",
      dataIndex: "code",
      sorter: true,
      render: (_, record) => record.document_type?.display_name || "-",
    },
    {
      title: "Document Number",
      dataIndex: "document_number",
      sorter: true,
      render: (_, record) => record.document_number || "-",
    },
    {
      title: "Date Of Check",
      dataIndex: "date_of_check",
      render: (_, record) => record?.created_at || "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => record?.status || "-",
    },
    {
      title: "View Document",
      dataIndex: "view_document",
      render: (_, record) => {
        if (record?.file_type === "image/jpeg") {
          return (
            <RViewerJS key={record?.id}>
              <div style={{ display: "flex" }}> 
                <img src={record.document_path} alt="Document" style={{ width: "50px", height: "50px", marginLeft: 30 }} />
              </div>
            </RViewerJS>
          );
        }  
      },
    }
  ];



  // Submit selected documents
  const uploadDocuments = async () => {
    try {
      // Prepare the payload with the selected document IDs in the required format
      const payload = Array.from(checkedItems).map(id => ({ document_id: id }));

      // Call the API to submit the selected documents
      const response = await submitVerifiedDocuments(payload);  
      if (response.data) {
        message.success("Documents Verified Successfully"); 
      }
    } catch (error) {
      message.error("Error uploading documents");
      console.error("Upload error:", error);
    }
  };

  return (
    <div>
      <div><p>Please Select the documentList to verify</p></div>
      <Button type="primary" onClick={uploadDocuments}>Verify</Button> 
      <div className='documentListVerify'>
        {customer_id && ( // Conditional rendering to ensure customers is valid
          <DataTable
            apiPath={`api/v1/customer/combine-sales-form/sales-documents-verify/${customer_id}`}
            tableData={filteredData}
            setTableData={setFilteredData}
            tableColumns={tableColumns}
            searchPlaceholder={"Type to search"}
          />
        )}
       
      </div>
    </div>
  );
};

export default DocumentListVerification;
