import React, { useEffect } from "react";
import { Button, Col, Form, Input, Row, Space, message } from "antd";
import * as Yup from "yup";
import { LTVCalculationsById, updateLTVCalculations } from "../services/incomeDetailsServices";


const validationSchema = Yup.object().shape({
    approved_tenure: Yup.string()
        .required("Approved tenure is required")
        .matches(/^[0-9.,]+$/, 'Approved tenure can only contain numbers'),
    approved_roi: Yup.string()
        .required("Approved ROI is required")
        .matches(/^[0-9., ]+$/, "Approved ROI can only contain numbers"),
    approved_amount: Yup.string()
        .required("Approved amount is required")
        .matches(/^[0-9., ]+$/, "Approved amount can only contain numbers"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const LTVCalculationForm = ({ id, open, closeForm,refreshData }) => {
    const [form] = Form.useForm();


    const getLTVData = async () => {
        if (id) {
            try {
                const response = await LTVCalculationsById(id);
                const { approved_amount, approved_tenure, approved_roi } =
                    response?.data;
                form.setFieldsValue({
                    approved_amount,
                    approved_tenure,
                    approved_roi,
                });
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }
    };

    const handleSubmit = async (values) => {
        try {
          if (id) {
            const response = await updateLTVCalculations(id, values);
            if ((response.status = 200 && response.success)) {
              message.success("LTV calculations successfully updated");
              refreshData();
              closeForm();
            }
          } 
        } catch (error) {
          message.error(error.response.data.message);
        }
    };

    useEffect(() => {
        if (open) {
            getLTVData();
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    const restrictToNumbersCommas = (e) => {
        const input = e.target.value;
        e.target.value = input.replace(/[^0-9.,]/g, ""); // Allow only numbers, commas, and decimals
    };
    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
        >
            <Row gutter={16}>


                <Col span={12}>
                    <Form.Item name="approved_amount" label="Approved Amount" rules={[yupSync]} required>
                        <Input placeholder="Please Enter approved name"
                            onInput={restrictToNumbersCommas}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="approved_tenure" label="Approved Tenure" rules={[yupSync]} required>
                        <Input placeholder="Please Enter approved tenure" onInput={restrictToNumbersCommas} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>

                <Col span={12}>
                    <Form.Item name="approved_roi" label="Approved ROI" rules={[yupSync]} required>
                        <Input placeholder="Please Enter approved ROI"
                            onInput={restrictToNumbersCommas}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Space
                direction="horizontal"
                align="center"
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
            </Space>
        </Form>
    )
}

export default LTVCalculationForm