import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../../modules/dashboard/services/homeService";
import { setUser } from "../../redux/userSlice";
import { message } from "antd";
import { useEffect, useState, useMemo } from "react";
import FullScreenLoader from "../../modules/pages/notfound/views/FullScreenLoader";

// Custom component for authorization check
const AuthorizedRoute = ({ permissions }) => {
  const isAuthenticated = localStorage.getItem("access"); // Check if user is authenticated
  const { user_data } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const userRequest = async () => {
    try {
      const response = await getUser();
      if (response.success === true) {
        const userData = response.data;
        dispatch(setUser(userData));
      } else {
        setError(true);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(user_data).length === 0) {
      userRequest();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_data]);

  // Memoize isAuthorized calculation to optimize performance
  // const isAuthorized = useMemo(() => {
  //   if (!isAuthenticated || loading) return false;

  //   const userPermissions = user_data?.permissions || [];
  //   const userRoles = user_data?.roles || [];
  //   return (
  //     userPermissions.some((permission) =>
  //       permissions.includes(permission?.code)
  //     ) || userPermissions.some((permission) => permission.code === "MPADMIN1" || permission.code === "SUPADMIN")
  //   );
  // }, [isAuthenticated, loading, user_data, permissions]);

  const isAuthorized = useMemo(() => {
    if (!isAuthenticated || loading) return false;
  
    const userPermissions = user_data?.permissions || [];
    const userRoles = user_data?.roles || [];
  
    // Check user permissions
    const hasPermission = userPermissions.some((permission) =>
      permissions.includes(permission?.code)
    );
    if (hasPermission) {
      return true; // If user has the required permissions, return true
    }
  
    // Check user roles if no permission is found
    const hasRole =
      userRoles.some((role) =>
        role.code === "MPADMIN1" || role.code === "SUPADMIN"
      );
  
    return hasRole; // Return true if user has the required roles
  }, [isAuthenticated, loading, user_data, permissions]);
  

  if (loading) {
    return <FullScreenLoader />; // Show a loading spinner or some placeholder
  }

  if (error) {
    return <Navigate to="/login" />;
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/not-found" />;
};

export default AuthorizedRoute;
