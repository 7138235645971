import React, { useEffect, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import EditButton from "../../../../utils/editButton/EditButton";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import AssetSeenForm from "./AssetSeenForm";
import { getAssestsByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import "../../styles/assetsseen.css";
import '../../styles/rvViews.css'

const AssetSeen = ({ selectedCustomerId }) => {
  const [openEditSeven, setOpenEditSeven] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [collapsed7, setCollapsed7] = useState(true);
  const [assetsData, setAssetsData] = useState(null);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getAssestsByCustomerId(selectedCustomerId);
        setAssetsData(reponse?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
  }, [refreshTableData, selectedCustomerId]);

  const toggleCollapse7 = () => {
    setCollapsed7(!collapsed7);
  };
  const showDrawerSeven = () => {
    setOpenEditSeven(true);
  };
  const onCloseSeven = () => {
    setOpenEditSeven(false);
  };
  const renderEditButtonSeven = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawerSeven();
        }}
      >
        <EditButton />
      </div>
    );
  };

  return (
    <div className="asset-seen-container">
      <Drawer
        title={<Header title="Edit" onClose={onCloseSeven} name="Assets " />}
        width={970}
        onClose={onCloseSeven}
        open={openEditSeven}
        closable={false}
      >
        <AssetSeenForm
          updateId={selectedCustomerId}
          open={openEditSeven}
          refreshData={toggleRefreshTableData}
          closeForm={onCloseSeven}
          customerDetails={assetsData}
        />
      </Drawer>

      <div className="fi_views_description_heading" >
        <div className="fi_views_heading_name">Assets Seen</div>
        <div className="fi_views_button_space">
          <div
           className="fi_views_edit_button"
          >
            {renderEditButtonSeven()}
          </div>
          <div
           className="fi_views_collapse_icon"
            onClick={toggleCollapse7}
          >
            {collapsed7 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>

      {collapsed7 && (
        <div className="asset-grid">
          {assetsData?.map((asset, index) => (
            <div key={index} className="asset-card">
              <div className="asset-name">{asset?.name || "-"}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssetSeen;
