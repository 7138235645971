import React, { useState, useEffect } from "react";
import { Button, Form, Space, message, Select, Input } from "antd";
import {
  getRCUDataByIdService,
  patchRCUStatusService,
} from "../services/rcuservices";
import '../styles/rcuInitiateInputForm.css'
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";


const { TextArea } = Input;

const Rcustatusform = ({ open, refreshData, closeForm, loanid, stage_id }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const status = ["Satisfactory", "RCU-Medium Risk"];

  const getRCUData = async () => {
    try {
      const response = await getRCUDataByIdService(stage_id);
      setData(response.data); 
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    getRCUData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanid, open]);

  useEffect(() => {
    if (data?.rcu_data?.rcu_docs) {
      // Set initial values for form fields if needed
      const initialValues = data.rcu_data?.rcu_docs.reduce(
        (acc, doc, index) => {
          acc[`remark_${index}`] = doc.remark || "";
          acc[`status_${index}`] = doc.status || "";
          return acc;
        },
        {}
      );

      form.setFieldsValue(initialValues);
    }
  }, [data, form, loanid]);

  const handleSubmit = async (values) => {
 
    let update_docs = {
      document: [],
      profile: []
    };

    data?.rcu_data?.rcu_docs?.forEach((doc, index) => {
      const docTypeName = doc?.document_detail?.document_type?.name;

      // If document_type.name is not "Profile"
      if (docTypeName && docTypeName !== "Profile") {
        update_docs.document.push({
          id: doc.id,
          remark: values[`remark_${index}`],
          status: values[`status_${index}`]
        });
      }
      if (!docTypeName) {
        update_docs.profile.push({
          id: doc.id,
          remark: values[`remark_${index}`],
          status: values[`status_${index}`]
        });
      }
    });


    let payload = { update_docs };
    try {
      const response = await patchRCUStatusService(payload);
      if (response.status === 200 && response.success) {
        message.success("Completion Remark successfully updated");
        refreshData();
        closeForm();
      } else {
        message.error("Failed to update completion remark");
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <div className="rcu_content">
        <div className="valuation_details_heading">
          <div className="rcu_value_property_heading">SR.No</div>
          <div className="rcu_value_property_heading">Document</div>
          <div className="rcu_value_property_heading">Images</div>
          <div className="rcu_value_property_heading">Remarks</div>
          <div className="rcu_value_property_heading">Status</div>
        </div>

        {data?.rcu_data?.rcu_docs?.map((docs, index) => (
          <div className="rcu_value_property" key={docs.id}>
            <div className="rcu_value_property_heading">
              <div className="value">{index + 1}</div>
            </div>
            <div className="rcu_value_property_heading">
              <div className="value">
                {docs?.document_detail?.document_type?.dispaly_name || docs?.document_type || "-"}
              </div>
            </div>
            <div className="rcu_value_property_heading">
              <div className="value">
                <img
                  className="rcu_status_image"
                  src={docs?.document_detail?.document_path || docs?.image_path}
                  alt="Document"
                />
              </div>
            </div>
            <div className="rcu_value_property_heading">
              <Form.Item
                name={`remark_${index}`}
                label="Remark"
                rules={[{ required: true, message: "Please enter a remark" }]}
              >
                <TextArea rows={4} placeholder="Enter remark here..." />
              </Form.Item>
            </div>
            <div className="rcu_value_property_heading">
              <Form.Item
                name={`status_${index}`}
                label="Status"
                rules={[{ required: true, message: "Please select a status" }]}
              >
                <Select placeholder="Please Select Status" allowClear>
                  {status.map((statusOption, statusIndex) => (
                    <Select.Option key={statusIndex} value={statusOption}>
                      {statusOption}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        ))}
      </div>

      <Form.Item>
        <Space
          direction="horizontal"
          align="center"
          className="rcu_initiate_input_form_space_button"
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button type="default" onClick={closeForm}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default Rcustatusform;
