import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Drawer, message, Modal } from "antd";
import { useSelector } from "react-redux";
import Header from "../../../../layout/views/Header";
import EditButton from "../../../../../utils/editButton/EditButton";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
// import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import "react-loading-skeleton/dist/skeleton.css";
import add from "../assets/image/Add.png";
import Loading from "../../../../../utils/loading/Loading";
import HistoryTable from "../../../history/views/HistoryTable";
// import group from "../assets/image/Group.png";
// import dummy_img from "../assets/dummyImage.png";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import isModifiedAfterCreated from "../../../../../utils/history/date";
import PartnerDetailsForm from "./PartnerDetailsForm";
import {
  deletePartnerDetailsByIdService,
  getPartnerDetailsByLoanIdService,
} from "../services/partnerservices";
import "../style/partner.css";
import DeleteButton from "../../../../../utils/deleteButton/DeleteButton";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";

const PartnerDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [open, setOpen] = useState(false);
  const [personalData, setPersonalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [maxBusinessPartners, setMaxBusinessPartners] = useState(0);
  const { user_data } = useSelector((state) => state.user);
  const [customHeaders, setCustomHeaders] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedPerson(null);
    setSelectedCustomer(null);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getPartnerDetailsByLoanIdService(loan_id);
        const filteredCustomers = response.data.customers.filter((person) => {
          const customerType = person.customer_detail?.customer_type?.trim();

          return (
            customerType === "Company Applicant" ||
            customerType === "Company Co-Applicant" ||
            customerType === "Company Guarantor"

          );
        });

        // Set filtered data only
        setPersonalData(filteredCustomers);
        let newHeaders = [...additionalHeaders];
        setCustomHeaders(newHeaders);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, loan_id]);

  const additionalHeaders = [
    "Key Parameter's",
    "Bussiness Partner",

    "First Name",
    "Middle Name",
    "Last Name",
    "Date Of Birth",
    "Relation",
    "Mobile Number",
  ];
  const fieldNameMap = {
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    date_of_birth: "Date Of Birth",
    relationship: "Relation",
    mobile_number: "Mobile Number",

    bd_modified_at: "Business Modified Date",

  };


  const renderEditButton = (showDrawer, id, person) => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
          setSelectedCustomer(id);
          setSelectedPerson(person);
        }}
      >
        <EditButton />
      </div>
    );
  };
  useEffect(() => {
    if (personalData && personalData.length > 0) {
      // Find the maximum count of `business_partners` in the personalData array
      const maxCount = personalData.reduce((max, item) => {
        const count = item.business_partners
          ? item.business_partners.length
          : 0;
        return Math.max(max, count);
      }, 0);

      setMaxBusinessPartners(maxCount);
    }
  }, [personalData, refreshData, loan_id]);

  const deleteRecord = async () => {
    setLoadingDelete(true);
    try {
      const response = await deletePartnerDetailsByIdService(selectedRecord);
      if (response.status === 200 && response.success === true) {
        message.success("Partner details deleted successfully");
        toggleRefreshData();
        setSelectedRecord(null);
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };

  return (
    <>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title={selectedCustomer ? "Edit" : "Add"}
              onClose={onClose}
              name={`${selectedPerson?.customer_detail.name}'s Partner Details`}
            />
          }
          width={970}
          onClose={onClose}
          closable={false}
          open={open}
        >
          <PartnerDetailsForm
            refreshData={toggleRefreshData}
            open={open}
            closeForm={onClose}
            customer_id={selectedCustomerId}
            id={selectedCustomer}
          />
        </Drawer>
        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : personalData?.length > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">
                    {customHeaders[0]}
                  </th>
                  {personalData?.map((person, index) => (
                    <th key={index} className="tab_form_top_heading">

                      {customHeaders[0] === "Key Parameter's" && (
                        <span>
                          <div className="add_button_alignment">
                            <div>
                              {person && (
                                <span>
                                  {`${person.customer_detail.name}`}
                                  <span>{`(${person.customer_detail?.customer_type || ""
                                    })`}</span>
                                </span>
                              )}

                              {/* <span>
                              <img
                                src={group}
                                alt="group"
                                className="partner_tab_verify"
                              />
                            </span> */}
                            </div>
                            <ConditionalRender
                              userPermissions={user_data?.permissions}
                              requiredPermissions={["POSTCUST"]}
                            >
                              <div
                                className="tab_form_sub_edit"
                                onClick={() => {
                                  showDrawer();
                                  setSelectedCustomerId(person.company_detail);
                                  setSelectedPerson(person);
                                }}
                              >
                                <img
                                  src={add}
                                  alt="Add Button"
                                />
                              </div>
                            </ConditionalRender>
                          </div>
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: maxBusinessPartners })?.map(
                  (_, rowIndex) =>
                    customHeaders?.slice(1)?.map((header, colIndex) => (
                      <tr
                        className={
                          header === "Bussiness Partner"
                            ? "investment-row"
                            : header === "PD Entries"
                              ? "investment-pd-row "
                              : ""
                        }
                        key={rowIndex}
                      >
                        <td className="tab_form_left_heading">
                          {header === "Bussiness Partner"
                            ? `Bussiness Partner ${rowIndex + 1}`
                            : header}
                        </td>

                        {personalData?.map((person, colIndex) => (
                          <td
                            key={`${colIndex}-${rowIndex}`}
                            className={`tab_form_main_data_table`}

                          >
                            {person?.business_partners &&
                              person.business_partners[rowIndex] ? (
                              <>
                                {/* {header === "Profile Photo" ? (


                                                            <RViewerJS
                                                                options={{
                                                                    url: (image) =>
                                                                        image.getAttribute("data-original"),
                                                                }}
                                                            >
                                                                <img
                                                                    className="profile"
                                                                    src={person.thumb_image || dummy_img}
                                                                    data-original={person.image || dummy_img}
                                                                    alt="Profile"
                                                                    style={{
                                                                        width: person.thumb_image ? "100px" : "85px",
                                                                        height: "100px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </RViewerJS>
                                                        ) : (
                                                            person[header.replace(/ /g, "_")] || ""
                                                        )} */}

                                {header === "First Name"
                                  ? person?.business_partners[rowIndex]
                                    ?.first_name || "-"
                                  : header === "Middle Name"
                                    ? person?.business_partners[rowIndex]
                                      ?.middle_name || "-"
                                    : header === "Last Name"
                                      ? person?.business_partners[rowIndex]
                                        ?.last_name || "-"
                                      : header === "Date Of Birth"
                                        ? person?.business_partners[rowIndex]
                                          ?.date_of_birth || "-"
                                        : header === "Relation"
                                          ? person?.business_partners[rowIndex]
                                            ?.relationship || "-"
                                          : header === "Mobile Number"
                                            ? person?.business_partners[rowIndex]
                                              ?.mobile_number || "-"
                                            : ""}

                                {header === `Bussiness Partner` && (
                                  <div className="partner_heaidng">
                                    <ConditionalRender
                                      userPermissions={user_data?.permissions}
                                      requiredPermissions={["DELTCUST"]}
                                    >
                                      <div
                                        onClick={() => {
                                          setSelectedRecord(
                                            person?.business_partners[rowIndex]
                                              .id
                                          );
                                          setOpenModal(true);
                                        }}
                                      >
                                        <DeleteButton />
                                      </div>
                                    </ConditionalRender>
                                    {person?.business_partners[rowIndex]
                                      ?.created_at &&
                                      person?.business_partners[rowIndex]
                                        ?.modified_at &&
                                      isModifiedAfterCreated(
                                        person?.business_partners[rowIndex]
                                          ?.created_at,
                                        person?.business_partners[rowIndex]
                                          ?.modified_at
                                      ) && (
                                        <HistoryTable
                                          id={
                                            person?.business_partners[rowIndex]
                                              ?.id
                                          }
                                          bench_id={"c814e9d7b3a2f7d5"}
                                          fieldNameMap={fieldNameMap}
                                        />
                                      )}
                                    <ConditionalRender
                                      userPermissions={user_data?.permissions}
                                      requiredPermissions={["EDITCUST"]}
                                    >
                                      {renderEditButton(
                                        showDrawer,
                                        person?.business_partners[rowIndex]?.id,
                                        person
                                      )}
                                    </ConditionalRender>
                                  </div>
                                )}
                              </>
                            ) : header === "  Bussiness Partner" ? (
                              ""
                            ) : (
                              <span>-</span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          ) : (
            <div className="fade-in">
              <div>
                <table className="tab_form_main_table no_data_table_height">
                  <thead>
                    <tr>
                      <th className="tab_form_key_parameters">
                        {customHeaders[0]}
                      </th>
                      {personalData?.map((person, index) => (
                        <th
                          key={index}
                          className="tab_form_top_heading no_data_heading_bottom_border"
                        >
                          <span className="no_data_heading_content">
                            <div>
                              {person && (
                                <span>
                                  {`${person.customer_detail.name}`}
                                  <span>{`(${person.customer_detail?.customer_type || ""
                                    })`}</span>
                                </span>
                              )}
                              {/* <span>
                                <img
                                  src={group}
                                  alt="group"
                                  className="verify_icon"
                                />
                              </span> */}
                            </div>
                            <ConditionalRender
                              userPermissions={user_data?.permissions}
                              requiredPermissions={["POSTCUST"]}
                            >
                              <div
                                className="tab_form_sub_edit"
                                onClick={() => {
                                  showDrawer();
                                  setSelectedCustomerId(person.company_detail);
                                  setSelectedPerson(person);
                                }}
                              >
                                <img src={add} alt="Add Button" />
                              </div>
                            </ConditionalRender>
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Placeholder to ensure table body aligns */}
                    {personalData?.length ? (
                      <tr>
                        <td colSpan={personalData?.length + 1}>
                          {/* Your data rows go here */}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={personalData?.length + 1}></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="no_data_icon">
              <CustomNoData />
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteRecord();
        }}
        onCancel={() => setOpenModal(false)}
        okButtonProps={{ loading: loadingDelete }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
    </>
  );
};

export default PartnerDetails;
