import React, { useEffect, useState } from 'react'
import EditButton from "../../../../utils/editButton/EditButton";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";
import Header from '../../../layout/views/Header';
import OccupationEditForm from './OccupationEditForm';
import { getFiBusinessOccupationByCustomerId } from '../../services/FIViewFormServices';
import ErrorMessage from '../../../../utils/errorHandling/ErrorMessage';
import Loading from '../../../../utils/loading/Loading';
import "../../styles/ovViewStyle.css"
import CustomNoData from '../../../../utils/noDataIcon/CustomNoData';
const Occupation = ({ selectedCustomerId }) => {
  const [open, setOpen] = useState(false);
  const [collapsed1, setCollapsed1] = useState(true);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [occupation, setOccupation] = useState(null);
  const [loading, setLoading] = useState(true);

  const toggleRefreshData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFiBusinessOccupationByCustomerId(selectedCustomerId);

        setOccupation(response?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, selectedCustomerId]);

  const showDrawer = () => {
    setOpen(true);
  };

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const onClose = () => {
    setOpen(false);
  };
  const renderEditButton = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div className='occupation_ov' >
      <Drawer
        title={
          <Header title="Edit" onClose={onClose} name="Occupation" />
        }
        width={970}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <OccupationEditForm
          open={open}
          updateId={occupation?.id}
          closeForm={onClose}
          refreshData={toggleRefreshData}
          customerDetails={occupation}
        />
      </Drawer>
      <div className='occupation_div_container'>
      <div className="fade-in">
        {loading ? (
          <Loading loading={loading} /> // Loading component while data is fetching
        ) : occupation ? (
          <>
            <div className="fi_description_heading">
              <div className="fi_description_data">Occupation</div>
              <div className='edit_button_ov'>
                <div
                className='edit_button_ov'
                >
                  {renderEditButton()}
                </div>
                <div
                className='ov_toggele'
               
                  onClick={toggleCollapse1}
                >
                  {collapsed1 ? <DownOutlined /> : <UpOutlined />}
                </div>
              </div>
            </div>
            {collapsed1 && (
              <div>

                <>
                  <div className="fi_description_basic_card_container">
                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Occupation Type:
                        </div>
                        <div className="fi_description_basic_card_data">
                          {occupation?.occupation_type || "-"}
                        </div>
                      </div>

                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          No.Of Years in Organization
                        </div>
                        <div className="fi_description_basic_card_data">
                          {occupation?.no_of_yrs_in_organization || "-"}
                        </div>
                      </div>

                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Salary Income/Turnover
                        </div>
                        <div className="fi_description_basic_card_data">
                          {`₹ ${occupation?.turnover}` || "-"}
                        </div>
                      </div>

                    </div>

                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Person Contacted At Office
                        </div>
                        <div className="fi_description_basic_card_data">
                          {occupation?.person_contacted_at_office || "-"}
                        </div>
                      </div>
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          No. Of Employees Seen
                        </div>
                        <div className="fi_description_basic_card_data">
                          {occupation?.no_of_employee_seen || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Met Person's Designation
                        </div>
                        <div className="fi_description_basic_card_data">
                          {occupation?.met_person_designation || "-"}
                        </div>
                      </div>
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Nature of Business
                        </div>
                        <div className="fi_description_basic_card_data">
                          {occupation?.nature_of_business || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Applicant's Designation
                        </div>
                        <div className="fi_description_basic_card_data">
                          {occupation?.customer_designation || "-"}
                        </div>
                      </div>

                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Type Of Premises & Sq.Ft
                        </div>
                        <div className="fi_description_basic_card_data">
                          {occupation?.type_of_premises || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </>

              </div>
            )}
          </>
        ) : (
          <div className="no_data_found_message">
           <CustomNoData />
          </div>
        )}
      </div>
      </div>
    </div>
  )
}

export default Occupation