import { axiosRequest } from "../../../utils/api/axiosRequest";
export const getSalesCustomerData  = (loan_id) =>  {
    return new Promise(async (resolve, reject) => {
      try { 
        const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail-list/${loan_id}?get_inactive=True`); 
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  