import "./App.css";
import Router from "./routes/Router";
import { ConfigProvider } from "antd";
import { customTheme } from "./utils/styles/themeConfig";
import useAutoLogout from "./utils/autoLogOutHook/useAutoLogout";
import logout from "./utils/autoLogOutHook/logout";

function App() {
  useAutoLogout(logout, 30 * 60 * 1000);

  return (
    <ConfigProvider theme={customTheme}>
      <Router />
    </ConfigProvider>
  );
}

export default App;
