import { axiosRequest } from "../../../../utils/api/axiosRequest";
export const getLoanDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLoanDetailsByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/loan-detail/loan-id/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllStages = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/loan-stage-history/stage-dropdown/?loan_detail_id=${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLoanStagesListService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/loan-stage-history/stage-history/?loan_detail_id=${id}`
      );
      return resolve(response?.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLAN = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `api/v1/loan/loan-detail/loan-number/${id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const stageToCompleteService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        "/api/v1/loan/loan-stage-history/",
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getMasterStages = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/loan-stage-history/stage_bar/`
      );
      return resolve(response.data); // Resolve with the "data" array
    } catch (error) {
      return reject(error);
    }
  });
};

export const getStageHistory = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/loan/loan-stage-history/stage-history/?loan_detail_id=${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllChatListByLoanId = (loan_id,pageNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/communication/messages/get-message-by-loan-id/${loan_id}?page=${pageNumber}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getAllUserHaveAccess = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/user/get-user-list-by-loan-location?loan_id=${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLoanAllNotification = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/communication/messages/notifications/`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateMessageSeen = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
        "/api/v1/communication/messages/conversation/messages/seen/",
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getLastMessageId = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/communication/messages/last-message/`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getConversationIdByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/communication/messages/get-conversation-by-loan-id/${loan_id}`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};