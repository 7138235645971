import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import * as Yup from "yup";
import dayjs from "dayjs";
import { UploadOutlined, DeleteOutlined, FilePdfOutlined } from "@ant-design/icons";
import {
  createOTCPDDService,
  deleteDocumentDetailsByIdService,
  getOTCPDDById,
  listLocationService,
  updateOTCPDDByIdService,
} from "../services/otcpddServices";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import {
  listDocumentTypeService,
  listGroupService,
  listReceivedAtService,
  listRiskService,
  listTypeService,
  listUserService,
} from "../services/otcpddServices";
import RViewerJS from "viewerjs-react";
import pdfIcon from "../assets/pdf.png";
import '../style/style.css'

const validationSchema = Yup.object().shape({
  otc_pdd_type: Yup.string().required("Type  is required"),
  group: Yup.string().required("Group is required"),
  received_at: Yup.string().required("Received at is required"),
  approved_by: Yup.string().required("Approved By is required"),
  risk_categorization: Yup.string().required("Risk categorization is required"),
  due_date: Yup.string().required("Due date is required"),
  location: Yup.string().required("Location is required"),
  description: Yup.string()
    .required("Description is required")
    .min(2, "Description must be at least 2 characters")
    .max(1000, "Description must be at max 1000 characters")
    .label("Description"),
  remark: Yup.string()
    .required("Remark is required")
    .min(2, "Remark must be at least 2 characters")
    .max(1000, "Remark must be at max 1000 characters")
    .label("Remark"),
  documents: Yup.array()
    // .min(1, "Please upload at least one document")
    .required("Please upload at least one document"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const AddOTCPDD = ({ closeForm, open, refreshData, id }) => {
  const [form] = Form.useForm();
  const { encrypted_loan_id } = useParams();
  const [fileList, setFileList] = useState([]);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [, setDocumentType] = useState([]);
  const [type, setType] = useState([]);
  const [group, setGroup] = useState([]);
  const [risk, setRisk] = useState([]);
  const [receivedAt, setreceivedAt] = useState([]);
  const [approved, setApproved] = useState([]);
  const [allLocation, setAllLocation] = useState([]);
  const [showLocationField, setShowLocationField] = useState(false);
  const [isReceivedAtDisabled, setIsReceivedAtDisabled] = useState(false);
  const [images, setImages] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [image, setImage] = useState([]);
  const [deletePicture, setDeletePicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [, setIsEditFormClick] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTypeChange = (value) => {
    setSelectedType(value); // Make fields visible if any type is selected
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const handleReceivedDateChange = (value) => {
    // setIsReceivedAtDisabled(!value);
    if (value) {
      setIsReceivedAtDisabled(true);
    } else if (!setIsEditFormClick) {

      setIsReceivedAtDisabled(false);
      form.resetFields(["received_at"]);
      setShowLocationField(false);

    }

  };

  const fetchDocumentType = async () => {
    try {
      const response = await listDocumentTypeService();

      setDocumentType(response.data.choices);
    } catch (error) {
      message.error("Failed to fetch documents");
    }
  };
  const fetchType = async () => {
    try {
      const response = await listTypeService();
      setType(response.data);
    } catch (error) {
      message.error("Failed to fetch documents");
    }
  };
  const fetchGroup = async () => {
    try {
      const response = await listGroupService();
      setGroup(response.data);
    } catch (error) {
      message.error("Failed to fetch documents");
    }
  };
  const fetchReceivedAt = async () => {
    try {
      const response = await listReceivedAtService();
      setreceivedAt(response.data);
    } catch (error) {
      message.error("Failed to fetch documents");
    }
  };
  const fetchRisk = async () => {
    try {
      const response = await listRiskService();
      setRisk(response.data.choices);
    } catch (error) {
      message.error("Failed to fetch documents");
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await listUserService(loan_id);
      setApproved(response.data);
    } catch (error) {
      message.error("Problem fetching users!");
    }
  };

  const fetchLocation = async () => {
    try {
      const locationResponse = await listLocationService();
      setAllLocation(locationResponse.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    fetchDocumentType();
    fetchType();
    fetchGroup();
    fetchReceivedAt();
    fetchRisk();
    fetchAllUsers();
    fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customDateValidator = (_, value) => {
    const today = moment().startOf("day");

    if (!value) {
      return Promise.reject("Due date is required");
    }

    if (value && value.isAfter(today, "day")) {
      return Promise.reject("Date can not be in the future date");
    }
    return Promise.resolve();
  };

  const customReceivedDateValidator = (_, value) => {
    const today = moment().startOf("day");


    if (value && value.isAfter(today, "day")) {
      return Promise.reject("Date can not be in the future date");
    }
    return Promise.resolve();
  };

  const getOtcData = async () => {
    if (id) {
      try {
        const response = await getOTCPDDById(id);
        const {
          otc_pdd_type,
          group,
          received_at,
          received_date,
          approved_by,
          risk_categorization,
          due_date,
          location,
          description,
          remark,
          docs_otc_pdc,
        } = response.data;

        setImages(docs_otc_pdc ? docs_otc_pdc : []);
        setSelectedType(otc_pdd_type ? otc_pdd_type : null);

        if (received_at === "Branch" || received_at === "Sales") {
          setShowLocationField(true);
        }
        if (received_date !== null) {
          setIsReceivedAtDisabled(true);
        }

        form.setFieldsValue({
          otc_pdd_type: otc_pdd_type ? otc_pdd_type : null,
          group: group ? group : null,
          received_at: received_at ? received_at :undefined,
          received_date: received_date ? dayjs(received_date, "DD-MM-YYYY") : null,
          approved_by: approved_by ? approved_by.id : null,
          risk_categorization: risk_categorization ? risk_categorization : null,
          due_date: due_date ? dayjs(due_date, "DD-MM-YYYY") : null,
          location: location ? location.id : undefined,
          description: description ? description : null,
          remark: remark ? remark : null,
          docs_otc_pdc: docs_otc_pdc ? docs_otc_pdc : [],
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };


  useEffect(() => {
    if (open) {
      getOtcData();
      setIsEditFormClick(true);

    } else {
      setImages([]);
      form.resetFields();
      setFileList([]);
      setImage([]);
    }
    setSelectedType(null);
    setIsReceivedAtDisabled(false);
    setShowLocationField(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // const handleInput = (e, field) => {
  //   const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
  //   form.setFieldsValue({ [field]: newValue });
  // };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };


  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.received_date) {
        values.received_date = values.received_date.format("YYYY-MM-DD");
      }
      if (values.due_date) {
        values.due_date = values.due_date.format("YYYY-MM-DD");
      }

      if (values.received_date === null) {
        delete values.received_date;
      }

      values.loan_detail = parseInt(loan_id);
      values.remark = values.remark.trim();
      values.description = values.description.trim();
      const propertyDocumentDetail = await Promise.all(
        fileList.map(async (file) => {
          const base64 = await fileToBase64(file.originFileObj);
          return base64;  // Only return the base64 URL
        })
      );


      const payload = {
        ...values,
        location: showLocationField ? values.location : null,
        documents: propertyDocumentDetail
      };


      if (id) {
        const response = await updateOTCPDDByIdService(id, payload);
        if ((response.status = 200 && response.success)) {
          message.success("OTC PDD updated successfully");
          form.resetFields();
          closeForm(false);
          refreshData();
        }
      } else {
        const response = await createOTCPDDService(payload);
        if ((response.status = 201 && response.success)) {
          message.success("OTC PDD created successfully");
          form.resetFields();
          setImages([]);
          closeForm(false);
          refreshData();
        }
      }
    } catch (error) {
      message.error("Failed to create/update OTC PDD");
    } finally {
      setLoading(false)
    }
  };


  const handleUploadChange = (info) => {
    let updatedFileList = [...info.fileList];
    // Map over the updatedFileList and update the last file in the list
    updatedFileList = updatedFileList.map((file, index) => {
      // Update the most recently uploaded file
      if (index === updatedFileList.length - 1) {
        return {
          ...file,
          document_type: form.getFieldValue("document_type"),
          document_number: form.getFieldValue("document_number"),
          document_path: file.originFileObj
            ? file.originFileObj.name // Set the document path from the file
            : file.document_path, // Or retain the previous document path
        };
      }
      return file;
    });

    // Update the state for fileList
    setFileList(updatedFileList);

    // Update the form field with the newly uploaded documents as an array
    form.setFieldsValue({
      documents: updatedFileList, // Ensure 'documents' is set as an array
    });

    // Reset the form fields for document type and number
    form.setFieldsValue({
      document_type: undefined,
      document_number: undefined,
      document_path: undefined,
    });
  };

  const handleBeforeUpload = (file) => {
    // const isJpgOrPngOrPdf =
    //   file.type === "image/jpeg" ||
    //   file.type === "image/png" ||
    //   file.type === "application/pdf";
    // if (!isJpgOrPngOrPdf) {
    //   message.error("You can only upload JPG/PNG/PDF file!");
    //   return Upload.LIST_IGNORE;
    // }

    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf" ||
      file.type === "application/vnd.ms-excel" ||  // For .xls files
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // For .xlsx files

    if (!isJpgOrPngOrPdf) {
      return Upload.LIST_IGNORE;
    }


    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdfIcon,
      uid: file.uid,
    };

    

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };

  const confirmDelete = () => {

    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false);
    setDeleteUploadPicture(null);
  };

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteDocumentDetailsByIdService(deletePicture.id);

      if (response?.status === 200 && response?.success === true) {
        message?.success("Document deleted successfully");
        setOpenModal(false);
        // toggleRefreshTableData();
        // setSelectedPropertyPicture(null);
        refreshData();
        getOtcData();
      }
    } catch (error) {
      // setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };


  const handleReceivedAtChange = (value) => {
    console.log("111", value)
    if (value === "Branch" || value === "Sales") {
      setShowLocationField(true);
    } else {
      setShowLocationField(false);
      form.setFieldsValue({ location: undefined });
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{}}
    >
      <Row gutter={16} className="otc_form_row">
        <Col span={8}>
          <Form.Item
            name="otc_pdd_type"
            label="Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleTypeChange}
            >
              {type?.map((item) => {

                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );

              })}

            </Select>
          </Form.Item>
        </Col>
      </Row>
      {selectedType && (
        <>
          <Row gutter={16} className="otc_form_row">
            <Col span={8}>
              <Form.Item name="group" label="Group" rules={[yupSync]} required>
                <Select
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {group?.map((group) => {
                    return (
                      <Select.Option key={group} value={group}>
                        {group}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              {["Deferred", "OTC", "PDD", "Pre Disbursals"].includes(
                selectedType
              ) && (
                  <Form.Item
                    name="due_date"
                    label="Due Date"
                    rules={[{ validator: customDateValidator }]}
                    required
                  >
                    <DatePicker
                    
                      className="date_pick"
                      format="DD-MM-YYYY"
                      placeholder="DD-MM-YYYY"
                    />
                  </Form.Item>
                )}
            </Col>
          </Row>

          <Row gutter={16}  className="otc_form_row">
            <Col span={8}>

              {["OTC", "PDD", "Pre Disbursals"].includes(selectedType) && (
                <Form.Item
                  name="received_date"
                  label="Received Date"
                  rules={[{ validator: customReceivedDateValidator }]}

                >
                  <DatePicker
                     className="date_pick"
                    format="DD-MM-YYYY"
                    placeholder="DD-MM-YYYY"
                    onChange={handleReceivedDateChange}
                  />
                </Form.Item>
              )}
            </Col>

            <Col span={8}>
              {isReceivedAtDisabled &&
                ["OTC", "PDD", "Pre Disbursals"].includes(selectedType) && (
                  <Form.Item
                    name="received_at"
                    label="Received At"
                    rules={[yupSync]}
                    required
                  >
                    <Select
                      mode="single"
                      placeholder="Please select"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={handleReceivedAtChange}
                    >
                      {receivedAt?.map((received) => {
                        return (
                          <Select.Option key={received} value={received}>
                            {received}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
            </Col>
            


            {showLocationField &&
              ["OTC", "PDD", "Pre Disbursals"].includes(selectedType) && (
                <>
                  <Col span={8}>
                    <Form.Item
                      name="location"
                      label="Location"
                      rules={[yupSync]}
                      required={showLocationField}

                    >
                      <Select
                        mode="single"
                        placeholder="Please select"
                        required={true}
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allLocation?.map((location) => (
                          <Select.Option key={location.id} value={location.id}>
                            {location.display_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                </>
              )}

          </Row>

          <Row gutter={16} className="otc_form_row">
            <Col span={8}>
              <Form.Item
                name="approved_by"
                label="Approved By"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {approved?.map((approved) => {
                    return (
                      <Select.Option key={approved.id} value={approved.id}>
                        {approved.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="risk_categorization"
                label="Risk Categorization"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  required={true}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {risk?.map((risk) => {
                    return (
                      <Select.Option key={risk.value} value={risk.value}>
                        {risk.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="otc_form_row">
            <Col span={12}>
              <Form.Item
                name="remark"
                label="Remark"
                rules={[yupSync]}
                required
              >
                <TextArea placeholder="Please Enter Remark" maxLength={1001} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[yupSync]}
                required
              >
                <TextArea
                  placeholder="Please Enter Description"
                  maxLength={1001}
                />
              </Form.Item>
            </Col>
          </Row>


          <Row gutter={16} className="otc_form_row"  >
            {images && images.length > 0 ? (
              <RViewerJS>
                <div className="otc_pdf_contianer" >
                  {images.map((doc, index) => (
                    <div className="otc_pdf_contianer_second" key={index} >
                      {doc.file_type === "application/pdf" ? (
                        <a className="pdf_anchor_tag_otc"
                          href={doc.document_path}
                          target="_blank"
                          rel="noopener noreferrer"
                        
                        >
                          <FilePdfOutlined  className="filepdoutline_icon"  />
                          <span className="pdf_content">PDF</span>
                        </a>
                      ) : (
                        <img
                          src={doc.thumb_document_path || doc.document_path} // Use thumbnail if available, otherwise full image
                          alt={`images ${index + 1}`}
                          className="thumbnail__img_otc"
                        />
                      )}
                      <div className="delete_otc_img"
                        
                    
                        onClick={() => {
                          setDeletePicture(doc); // Assuming you have this state to handle deletion
                          setOpenModal(true); // Open modal to confirm deletion
                        }}
                      >
                        <DeleteOutlined />
                      </div>
                    </div>
                  ))}
                </div>
              </RViewerJS>
            ) : null}
          </Row>

          <Row gutter={16} >
            <Col span={12}>
              <Form.Item
                name="documents"
                label="Documents"
                rules={images.length > 0 ? [] : [yupSync]}
                required
            
              >
                <Upload
                  single
                  fileList={fileList}
                  onChange={handleUploadChange}
                  beforeUpload={handleBeforeUpload}
                  showUploadList={false}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    className="date_pick"
                  >
                    Upload Document
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <div className="otc_img_add_form">
        <div className="otc_img_add_form_second_div">
          {image?.map((picture, index) => (
            <div className="otc_img_container" >
              <RViewerJS>
                <img
                  src={picture.file_path}
                  alt=""
             
                  className="otc_image_div_add"
                />
              </RViewerJS>
              <div
              
                className="add_otc_image_text"
                onClick={() => {
                  setDeleteUploadPicture(picture);
                  setOpenUploadModal(true);
                }}
              >
                <DeleteOutlined />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title="Confirm Delete"
        open={openUploadModal}
        onOk={confirmDelete}
        onCancel={() => {
          setOpenUploadModal(false);
          setDeleteUploadPicture(null);
        }}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this upload image?`}</p>
      </Modal>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={deletePropertyPictures}
        onCancel={() => {
          setOpenModal(false);
          setDeletePicture(null);
        }}
        okType="danger"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>

      <Space
        direction="horizontal"
        align="center"
        className="add_otc_button_submit"

      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default AddOTCPDD;
