/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  DatePicker,
} from "antd";
import {
  getDependentByIdService,
  updateDependentDetailsByIdService,
  listRelationService,
  listGenderService,
  createDependentService,
  pincodeCodeService,
  getallpincode,
  getAllCountriesService,
  getAllStateService,
  getAllDistrictService,
  getAllTalukaService,
  getAllCityService,
  listAddressTypeService
} from "../services/dependentDetailsServices";
import dayjs from "dayjs";
import * as Yup from "yup";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { useDispatch } from "react-redux";
import { addNewCustomerDependent } from "../../../../../redux/salesFormSlice";
const { Option } = Select;


const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(128, "First name must be at max 128 characters")
    .matches(/^[A-Za-z\s]+$/, "First name can only contain letters"),

  // middle_name: Yup.string()
  //   .required("Middle name is required")
  //   .min(2, "Middle name must be at most 2 characters")
  //   .max(128, "Middle name must be at max 128 characters")
  //   .matches(/^[A-Za-z ]+$/, "Middle name can only contain letters"),
  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at most 2 characters")
    .max(128, "Last name must be at max 128 characters")
    .matches(/^[A-Za-z ]+$/, "Last name can only contain letters"),
  // date_of_birth: Yup.date()
  //   .required("Date of birth is required")
  //   .test(
  //     "not-future-date",
  //     "Date of birth cannot be a future date",
  //     function (value) {
  //       return value ? dayjs(value).isBefore(dayjs()) : true;
  //     }
  //   )
  //   .test(
  //     "not-today",
  //     "Date of birth cannot be today's date",
  //     function (value) {
  //       return value ? !dayjs(value).isSame(dayjs(), "day") : true;
  //     }
  //   ),
  date_of_birth: Yup.string().required("Date Of Birth is required"),
  gender: Yup.string().required("Gender is required"),
  relation: Yup.string().required("Relation is required"),
  address_type: Yup.string().required("Address type is required"),
  phone_number: Yup.string()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return originalValue.replace(/\D/g, ''); // Remove all non-numeric characters
      }
      return originalValue;
    })
    .matches(/^\d{8,15}$/, {
      message: 'Phone number must be between 8 to 15 digits',
      excludeEmptyString: true,
    })
    .label("Phone number"),
  mobile_number: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),
  mobile_country_code: Yup.string()
  // .min(2, "Country Code must be exact 2 digits")
  .max(2, "Country code must be exact 2 digits")
  .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),
  address_line_1: Yup.string()
    .required("Address line 1 is required")
    .min(2, "Address line 1 must be atleast 2 characters")
    .max(225, "Address line 1 must be at max 225 characters"),
  address_line_2: Yup.string()
    .required("Address line 2 is required")
    .min(2, "Address line 2 must be atleast 2 characters")
    .max(225, "Address line 2 must be at max 225 characters"),
  landmark: Yup.string()
    .required("Landmark is required")
    .min(2, "Landmark must be atleast 2 characters")
    .max(128, "Landmark must be at max 128 characters"),
  city: Yup.string().required("City is required"),
  taluka: Yup.string().required("Taluka is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]+$/, "Pincode should contain numbers only"),
  district: Yup.string()
    .required("District is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const DependentDetailsForm = ({
  refreshData,
  open,
  closeForm,
  id,
  customer_id, 
  activeCustomer,
  isSalesForm,
  activeDependentKey
}) => {
  const [form] = Form.useForm();
  const [allRelationType, setAllRelationType] = useState([]);
  const [genders, setGenders] = useState();
  const [pincode, setPincode] = useState();
  const [city, setCity] = useState();
  const [taluka, setTaluka] = useState();
  const [district, setDistrict] = useState();
  const [states, setStates] = useState();
  const [country, setCountry] = useState();
  const [alladdress, setAddressType] = useState([]);
  const [, setLoading] = useState(false);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const dispatch = useDispatch()

  const handleInput = (e, field) => {
    // Allow letters, numbers, comma, forward slash, and empty spaces
    const newValue = e.target.value.replace(/[^A-Za-z0-9,/\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  useEffect(() => {
    const getAllRelation = async () => {
      try {
        const response = await listRelationService();
        setAllRelationType(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllRelation();
    getAllGenders();
  }, [form]);

  const getAllGenders = async () => {
    try {
      const response = await listGenderService();
      setGenders(response?.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    const getAllPincodelist = async () => {
      try {
        const response = await getallpincode();
        setPincode(response.data);
        if (response && response.data) {
          if (response.status === 200 && response.data.success) {
            message.success("Address details updated successfully");
            if (!isSalesForm) {
              refreshData();
              closeForm();
            }
          }
        }
      } catch (error) {
        if (!isSalesForm) {
          closeForm();
        }
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCitylist = async () => {
      try {
        const response = await getAllCityService();
        setCity(response.data);
        // if (response && response.data) {
        //   if (response.status === 200 && response.data.success) {
        //     message.success("Address details updated successfully");
        //     refreshData();
        //     closeForm();
        //   }
        // }
      } catch (error) {
        // closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllTalukalist = async () => {
      try {
        const response = await getAllTalukaService();
        setTaluka(response.data);
        // if (response && response.data) {
        //   if (response.status === 200 && response.data.success) {
        //     message.success("Address details updated successfully");
        //     refreshData();
        //     closeForm();
        //   }
        // }
      } catch (error) {
        // closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllDistrict = async () => {
      try {
        const response = await getAllDistrictService();
        setDistrict(response.data);
        // if (response && response.data) {
        //   if (response.status === 200 && response.data.success) {
        //     message.success("Address details updated successfully");
        //     refreshData();
        //     closeForm();
        //   }
        // }
      } catch (error) {
        // closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllState = async () => {
      try {
        const response = await getAllStateService();
        setStates(response.data);
        // if (response && response.data) {
        //   if (response.status === 200 && response.data.success) {
        //     message.success("Address details updated successfully");
        //     refreshData();
        //     closeForm();
        //   }
        // }
      } catch (error) {
        // closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllAddress = async () => {
      try {
        const response = await listAddressTypeService();
        setAddressType(response.data);
      }
      catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const getAllCountries = async () => {
      try {
        const response = await getAllCountriesService();
        setCountry(response.data);
        // if (response && response.data) {
        //   if (response.status === 200 && response.data.success) {
        //     message.success("Address details updated successfully");
        //     refreshData();
        //     closeForm();
        //   }
        // }
      } catch (error) {
        // closeForm();
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllPincodelist();
    getAllCitylist();
    getAllTalukalist();
    getAllDistrict();
    getAllState();
    getAllCountries();
    getAllAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const disabledDate = (current) => {
    const today = dayjs().startOf("day");

    // Disable dates before 100 years ago
    if (current && current < today.subtract(100, "year")) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
    try {
      if (id) {
        const response = await updateDependentDetailsByIdService(id, values);
        if (response?.status === 200 && response?.success) {
          message.success("Dependent details updated successfully"); 
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
        }
      } else {
        values.loan_detail = parseInt(loan_id);
        values.customer_detail = customer_id;
        const response = await createDependentService(values);
        if ((response.status = 200 && response.success)) {
          message.success("Dependent details successfully created");
          if (
            typeof activeDependentKey !== "undefined" &&
            typeof activeCustomer !== "undefined"
          ) {
            dispatch(
              addNewCustomerDependent({
                activeCustomerKey: activeCustomer,
                activeDependentKey: activeDependentKey,
                id: response?.data?.id,
              })
            );
          }
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
        }
      }
    } catch (error) {

      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message));

    } finally {
      setLoading(false)
    }


  };

  useEffect(() => {
    const getDependentData = async () => {
      if (id) {
        try {
          const response = await getDependentByIdService(id);
          let {
            first_name,
            middle_name,
            last_name,
            date_of_birth,
            age,
            gender,
            relation,
            phone_number,
            mobile_number,
            address_line_1,
            address_line_2,
            landmark,
            city,
            taluka,
            district,
            state,
            pincode,
            country,
            address_type,
          } = response?.data;
          date_of_birth = dayjs(date_of_birth, "DD-MM-YYYY");

          form.setFieldsValue({
            first_name,
            middle_name,
            last_name,
            date_of_birth,
            age,
            gender,
            relation: relation?.id,
            phone_number,
            mobile_number,
            address_line_1,
            address_line_2,
            landmark,
            city: city?.id,
            taluka: taluka?.id,
            district: district?.id,
            state: state?.id,
            pincode: pincode?.id,
            country: country?.id,
            address_type: address_type.id,
          });
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      }
    };

    if (open || id) {
      getDependentData();
    } else {
      form.resetFields();
    }
  }, [open, id, form]);

  const handlePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);
    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await pincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, district, state, taluka, country } = response.data;
        form.setFieldsValue({
          city: city?.id || undefined,
          district: district?.id || undefined,
          state: state?.id || undefined,
          taluka: taluka?.id || undefined,
          country: country?.id || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const handleInputt = (e, name) => {
    let value = e.target.value.replace(/\D/g, "");
    value = value.slice(0, 15);
    form.setFieldsValue({ [name]: value });
  };

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;



  return (
      <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",

        mobile_number: "",
        phone_number: "",
        phone_country_codee: "+91",
        dependents_address: "",
        // gender: "",
        // relation: "",
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your First Name"
              onChange={(e) => handleInput(e, "first_name")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="middle_name"
            label="Middle Name"
          >

            <Input
              placeholder="Please Enter Your Middle Name"
              onChange={(e) => handleInput(e, "middle_name")}
            />

          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Your Last Name"
              onChange={(e) => handleInput(e, "last_name")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="date_of_birth"
            label="Date Of Birth"
            rules={[yupSync]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"
              className="dependent_form_fields_width"
              placeholder="Select Date"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>



        <Col span={8}>
          <Form.Item name="gender" label="Gender" rules={[yupSync]} required>
            <Select placeholder="Please select" allowClear showSearch>
              {genders?.map((gender) => (
                <Option key={gender} value={gender}>
                  {gender}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="relation"
            label="Relation"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allRelationType?.map((relation) => {
                return (
                  <Select.Option key={relation.id} value={relation.id}>
                    {relation.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} >
        <Col span={8}>
          <Form.Item label={<span>Mobile Number</span>} required>
            <Input.Group compact>
              <Form.Item name="mobile_country_code" noStyle rules={[yupSync]}>
                <Input
                  className="dependent_form_country_code"
                  defaultValue="+91"
                  disabled
                />
              </Form.Item>
              <Form.Item name="mobile_number" noStyle rules={[yupSync]}>
                <Input
                  className="dependent_form_number_field"
                  placeholder="Please Enter Mobile Number"
                  onChange={(e) => handleNumberFields(e, "mobile_number")}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<span>Phone Number</span>}>
            <Input.Group compact>
              <Form.Item name="phone_country_codee" noStyle>
                <Input
                 className="dependent_form_country_code"
                  defaultValue="91"
                  disabled
                />
              </Form.Item>
              <Form.Item name="phone_number" rules={[yupSync]} noStyle>
                <Input
                  className="dependent_form_number_field"
                  placeholder="Please Enter Phone Number"
                  onChange={(e) => handleInputt(e, "phone_number")}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="address_type" label="Address Type" rules={[yupSync]} required>

            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {alladdress?.map((add) => {

                return (
                  <Select.Option
                    key={add.id}
                    value={add.id}>
                    {add.display_name}
                  </Select.Option>
                );
              })}

            </Select>
          </Form.Item>
        </Col>



      </Row>
      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="address_line_1"
            label="Address Line 1"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 1"
              onChange={(e) => handleInput(e, "address_line_1")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="address_line_2"
            label="Address Line 2"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Address Line 2"
              onChange={(e) => handleInput(e, "address_line_2")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="landmark"
            label="Landmark"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Landmark"
              onChange={(e) => handleInput(e, "landmark")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} >
        <Col span={8}>
          <Form.Item name="pincode" label="Pincode" rules={[yupSync]} required>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please Select"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handlePincodeChange}
            >
              {pincode?.map((pincodes, index) => (
                <Option key={pincodes.id} value={pincodes.id}>
                  {pincodes.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="city" label="City" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {city?.map((locations, index) => (
                <Option key={locations.id} value={locations.id}>
                  {locations.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="taluka" label="Taluka" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {taluka?.map((talukas, index) => (
                <Option key={talukas.id} value={talukas.id}>
                  {talukas.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} >
        <Col span={8}>
          <Form.Item
            name="district"
            label="District"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {district?.map((districts, index) => (
                <Option key={districts.id} value={districts.id}>
                  {districts.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="state" label="State" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {states?.map((states, index) => (
                <Option key={states.id} value={states.id}>
                  {states.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="country" label="Country" rules={[yupSync]} required>
            <Select
              placeholder="Please Select"
              showSearch
              optionFilterProp="children"
              filterOption={filterOption}
              disabled
            >
              {country?.map((countries, index) => (
                <Option key={countries.id} value={countries.id}>
                  {countries.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        className="customer_tabs_form_buttons"
      >
         {isSalesForm ? (
          <Button type="primary"  htmlType="submit"
          >
            Submit
          </Button>
        ) : (
          <>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button onClick={closeForm}>Cancel</Button>
          </>
        )}
      </Space>
    </Form>
  );
};

export default DependentDetailsForm;



