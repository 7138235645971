import React, { useState, useEffect } from 'react';
import { Drawer, message, Button } from "antd";
import { useParams } from 'react-router-dom';
import KarzaDetailsForm from './karzaDetailsForm';
import Header from "../../../../layout/views/Header";
import { getKarzaDetailsService } from "../services/karzaDetailsServices";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import Skeleton from "react-loading-skeleton";
import Loading from "../../../../../utils/loading/Loading";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import add from "../../addressDetails/assets/Add.png";

const KarzaDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [customerData, setCustomerData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedPersonId, setSelectedPersonId] = useState(null);  // Store the selected person ID

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getKarzaDetailsService(loan_id);
        setCustomerData(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bank account details:", error);
      }
    };

    fetchData();
  }, [refreshData, loan_id]);

  const getTableColumnClass = (entryCount) => {
    switch (entryCount) {
      case 2:
        return "tab_form_main_data_two_entries";
      case 3:
        return "tab_form_main_data_three_entries";
      case 4:
        return 'tab_form_main_data_four_entries';
      default:
        return "";
    }
  };

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .replace(/\b\w/g, match => match.toUpperCase());
  }

  const uniqueObjectNames = new Set();

  customerData.forEach(customer => {
    customer.karza_response.forEach(response => {
      Object.keys(response).forEach(key => {
        uniqueObjectNames.add(toTitleCase(key));
      });
    });
  });

  const uniqueObjectNamesList = [...uniqueObjectNames];

  const customHeaders = [
    "Key Parameter's",
    ...uniqueObjectNamesList
  ];

  const showDrawer = (personId) => {
    setSelectedPersonId(personId);  // Store the selected person's ID
    setOpen(true);  // Open the drawer
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleRefreshData = () => {
    setRefreshData(prev => !prev);
  };

  const isAllKarzaResponseEmpty = customerData?.every(customer =>
    customer.karza_response?.length === 0 || customer.karza_response?.every(response => Object.keys(response).length === 0)
  );

  return (
    <div className='tab_form_container'>
      <div className='karza_tab_form_main_container'>
        {/* <Button type="primary" onClick={() => showDrawer(null)}>
          Verify
        </Button> */}
        <Drawer
          title={
            <Header
              title="Edit"
              onClose={onClose}
              name="Verification Types"
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
          loan_id={loan_id}
        >
          <KarzaDetailsForm
            refreshData={toggleRefreshData}
            open={open}
            closeForm={onClose}
            loan_id={loan_id}
            selectedPersonId={selectedPersonId}  
          />
        </Drawer>
      </div>

      <div className="tab_form_main_container fade-in">
        {loading ? (
          <Loading loading={loading} />
        ) : (isAllKarzaResponseEmpty? (
          <>
          <div className='karza_tab_form_main_container'>
          <Button type="primary" onClick={() => showDrawer(null)}>
            Verify
          </Button>
          </div>
          <CustomNoData />
          </>
        ): (
          <table className="tab_form_main_table">
            <thead>
              <tr>
                <th className="tab_form_key_parameters">{customHeaders[0]}</th>
                {customerData?.map((person, index) => (
                   <th key={index} className="tab_form_top_heading">
                   <div className="add_button_alignment">
                     <div>
                       <span>{`${person?.customer_name}`} </span>
                       <span>
                         <span>{`(${person?.customer_type})`} </span>
                         {/* <img
                           src={verify}
                           alt="group"
                          
                           className="verify_img"
                         /> */}
                       </span>
                     </div>
                      
                       <div
                         className="tab_form_sub_edit"
                        
                       >
                       <img
                       src={add}
                       alt="Add Button"
                       onClick={() => showDrawer(person.customer_id
                       )}  />
                       </div> 
                   </div>
                 </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? Array.from({ length: customHeaders?.length - 1 }).map(
                  (_, index) => (
                    <tr key={`loading-${index}`}>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  )
                )
                : customHeaders?.slice(1).map((header, rowIndex) => (
                  <tr
                    key={`row-${rowIndex}`}
                    className={`Personal_main_data_table ${header === "PD Entries"
                      ? " tab_form_gray_highlighted_row"
                      : header === "No Of Years In Current City"
                        ? " tab_form_highlighted_row_orange"
                        : ""
                      }`}
                  >
                    <td
                      className={`tab_form_left_heading${header === "PD Entries" ||
                        header === "No Of Years In Current City"
                        ? ` ${header === "PD Entries"
                          ? "tab_form_gray_highlighted_row "
                          : "tab_form_highlighted_row_orange"
                        }`
                        : ""
                        }`}
                    >
                      {header}
                    </td>

                    {customerData?.map((person, colIndex) => (
                      <td
                        key={`col-${colIndex}`}
                        className={`tab_form_main_data_table ${getTableColumnClass(
                          customerData?.length
                        )}`}
                      >
                        {header === "Pan Card" && (
                          <span>
                            {toTitleCase(
                              person.karza_response.find(item => item["PAN CARD"])?.["PAN CARD"]?.remark || "-"
                            )}
                          </span>
                        )}
                        {header === "Bank Account" && (
                          <span>
                            {
                              toTitleCase(
                                person.karza_response.find(item => item["BANK ACCOUNT"])?.["BANK ACCOUNT"]?.remark || "-"
                              )
                            }
                          </span>
                        )}

                        {header === "Driving License" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["DRIVING LICENSE"])?.["DRIVING LICENSE"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Mobile Verification" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["MOBILE VERIFICATION"])?.["MOBILE VERIFICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Epf Uan Lookup" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["EPF UAN LOOKUP"])?.["EPF UAN LOOKUP"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Epf Verification" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["EPF VERIFICATION"])?.["EPF VERIFICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Electricity Bill Authentication" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["ELECTRICITY BILL AUTHENTICATION"])?.["ELECTRICITY BILL AUTHENTICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Employment Verification Advanced" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["EMPLOYMENT VERIFIATION ADVANCED"])?.["EMPLOYMENT VERIFIATION ADVANCED"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Fssai License Authentication" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["FSSAI LICENSE AUTHENTICATION"])?.["FSSAI LICENSE AUTHENTICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Form 16" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["FORM 16"])?.["FORM 16"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Gst Authentication" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["GST AUTHENTICATION"])?.["GST AUTHENTICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Gst By Pan" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["GST BY PAN"])?.["GST BY PAN"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Ifsc" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["IFSC"])?.["IFSC"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Itr Verification" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["ITR VERIFICATION"])?.["ITR VERIFICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Lpg Id Authentication" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["LPG ID AUTHENTICATION"])?.["LPG ID AUTHENTICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Png Authentication" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["PNG AUTHENTICATION"])?.["PNG AUTHENTICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Passport Verification" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["PASSPORT VERIFICATION"])?.["PASSPORT VERIFICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Ration Card Authentication" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["RATION CARD AUTHENTICATION"])?.["RATION CARD AUTHENTICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Voter Id Authentication" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["VOTER ID AUTHENTICATION"])?.["VOTER ID AUTHENTICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Verification Type" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["VERIFICATION TYPE"])?.["VERIFICATION TYPE"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Shop And Establishment" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["SHOP AND ESTABLISHMENT"])?.["SHOP AND ESTABLISHMENT"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

                        {header === "Tan Authentication" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["TAN AUTHENTICATION"])?.["TAN AUTHENTICATION"]?.remark || "-"
                            )
                          }
                          </span>
                        )}

{header === "Voter Id" && (
                          <span> {
                            toTitleCase(
                              person.karza_response.find(item => item["VOTER ID"])?.["VOTER ID"]?.remark || "-"
                            )
                          }
                          </span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
};

export default KarzaDetails;
