import React, { useState, useEffect } from 'react';
import "../styles/karzaDetails.css"
import { Button, Col, Form, Input, Row, Select, Space, message, DatePicker } from "antd";
import { verifyGSTAuthenticationByIdService, getVerificationTypes, verifyExistingVehicleByIdService, verifyPanCardByIdService, verifyOtpByIdService, verifyCommercialVehicleByIdService, verifyBankAccountByIdService, verifyIFSCCodeByIdService, verifyDrivingLincenseByIdService, verifyVoterIdByIdService, verifyTANAuthenticationIdService, verifyShopAndEstablishmentByIdService, verifyPassportVerificationByIdService, verifyPNGAuthenticationByIdService, verifyLPGIDAuthenticationByIdService, verifyIncomeTaxChallanAuthenticationByIdService, verifyITRVerificationByIdService, verifyIECDetailedProfileByIdService, verifyGSTByPANByIdService, verifyForm16ByIdService, verifyFssaiLinceseAuthenticationByIdService, verifyEmployementVerificationByIdService, verifyelectricitybillByIdService, verifyEPFVerificationByIdService, verifyEPFUANLookupByIdService, verifyAadharOtpByIdService, verifyAadharByIdService, verifyMobileByIdService, verifyMobileOTPByIdService, getElectricityPProviderService, getAreaCodeService, getPNGService, getLoanDetailsByLoanId } from "../services/karzaDetailsServices"
import * as Yup from "yup";
const { Option } = Select;

const validationSchema = Yup.object().shape({
    account_holder_name: Yup.string()
        .required("Account Holder Name is required!"),
    registration_no: Yup.string()
        .required("Registration Number is required!"),
    uan: Yup.string()
        .required("UAN is required!"),
    aadhaar_no: Yup.string()
        .required("Aadhar Number is required!"),
    amount: Yup.string()
        .required("Amount is required!"),
    dob: Yup.string()
        .required("Date Of Birth is required!"),
    passport_dob: Yup.string()
        .required("Date Of Birth is required!"),
    challan_number: Yup.string()
        .required("Challan Number is required!"),
    bsr_code: Yup.string()
        .required("BSR Code is required!"),
    bp_no: Yup.string()
        .required("BP Number is required!"),
    consumer_id: Yup.string()
        .required("Consumer ID is required!"),
    service_provider: Yup.string()
        .required("Service Provider is required!"),
    cin_no: Yup.string()
        .required("CIN NO is required!"),
    iec_no: Yup.string()
        .required("IEC NO is required!"),
    employee_name: Yup.string()
        .required("Employee Name is required!"),
    employer_name: Yup.string()
        .required("Employer Name is required!"),
    lpg_no: Yup.string()
        .required("LPG Number is required!"),
    tan_no: Yup.string()
        .required("TAN Number is required!"),
    licence_no: Yup.string()
        .required("Lincence Number is required!"),
    fiscal_year: Yup.string()
        .required("Fiscal Year is required!"),
    cert_no: Yup.string()
        .required("Cert Number is required!"),
    pan_no: Yup.string()
        .required("PAN Number is required!"),
    file_no: Yup.string()
        .required("File Number is required!"),
    area_code: Yup.string()
        .required("Area Code is required!"),
    reg_no: Yup.string()
        .required("Registration Number is required!"),
    mobile: Yup.string()
        .required("Mobile is required!"),
    pan: Yup.string()
        .required("PAN Number is required!"),
    gstin_no: Yup.string()
        .required("GSTIN Number is required!"),
    district: Yup.string()
        .required("District is required!"),
    consumer_no: Yup.string()
        .required("Consumer Number is required!"),
    ack_no: Yup.string()
        .required("ACK is required!"),
    dl_no: Yup.string()
        .required("Driving Licence is required!"),
    ifsc_no: Yup.string()
        .required("IFSC Number is required!"),
    account_no: Yup.string()
        .required("Account Number is required!"),
    epic_no: Yup.string()
        .required("Epic Number is required!"),
    mobile_number: Yup.string()
        .required("Mobile Number is required!"),
    mobile_no: Yup.string()
        .required("Mobile Number is required!"),
    address_type: Yup.string()
        .required("Address Type is required!"),
    type: Yup.string()
        .required("Verification Type is required!"),
    customer_id: Yup.string()
        .required("Account Holder Name is required!"),
    otp: Yup.string()
        .required("OTP is required!"),
    electricity_service_provider: Yup.string()
        .required("Electricity Service Provider is required!"),
    png_service_provider: Yup.string()
        .required("PNG Service Provider is required!"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const KarzaDetailsForm = ({ refreshData, open, closeForm, loan_id, selectedPersonId }) => {  

    const [customerData, setCustomerData] = useState()
    const [verificationtypes, setVerificationtypes] = useState()
    const [selectedVerificationType, setSelectedVerificationType] = useState("");
    const [requestid, setRequestid] = useState(null);
    const [mobilerequestid, setMobilerequestid] = useState("")
    const [form] = Form.useForm();
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [electricityproviders, setElectricityproviders] = useState()
    const [electricityproviderid, setElectricityproviderid] = useState()
    const [areacodeproviders, setAreacodeproviders] = useState()
    const [areacode, setAreacode] = useState()
    const [pngcodes, setPngcodes] = useState()
    const [pngcode, setpngcode] = useState()
    const [otpSent, setOtpSent] = useState(false);

 

    useEffect(() => {
        

        const fetchVerificationData = async () => {
            try {

                const data = await getVerificationTypes();
                setVerificationtypes(data?.data);

            } catch (error) {
                console.error("Error fetching bank account details:", error);
            }
        };

        const fetchElecricityProviders = async () => {
            try {

                const data = await getElectricityPProviderService();
                setElectricityproviders(data?.data);

            } catch (error) {
                console.error("Error fetching bank account details:", error);
            }
        };

        const fetchAreaCodes = async () => {
            try {

                const data = await getAreaCodeService();
                setAreacodeproviders(data?.data);

            } catch (error) {
                console.error("Error fetching bank account details:", error);
            }
        };

        const fetchPNGCodes = async () => {
            try {

                const data = await getPNGService();
                setPngcodes(data?.data);

            } catch (error) {
                console.error("Error fetching bank account details:", error);
            }
        };
        if (open) {
            fetchPNGCodes();
            fetchAreaCodes();
            fetchElecricityProviders();
            fetchVerificationData();
            

        } else {
            form?.resetFields();
            setSelectedVerificationType("");
            setElectricityproviderid("");
            setpngcode("");
            setAreacode("");
        }
    }, [open, loan_id, form])

    useEffect(() => {

        const fetchData = async () => {
            try {

                const data = await getLoanDetailsByLoanId(loan_id);
                setCustomerData(data?.data);
            } catch (error) {
                console.error("Error fetching bank account details:", error);
            }
        };

        if (selectedPersonId === null) {
            fetchData();
            }
    },[selectedPersonId, loan_id])

    const handleOptionSelect = (customerId) => {
        setSelectedCustomerId(customerId);
    };

    const handleElectricityProviderTypeChange = (customerId) => {
        setElectricityproviderid(customerId);
    };

    const handleAreaCodeTypeChange = (customerId) => {
        setAreacode(customerId);
    };

    const handlePNGCodeChange = (customerId) => {
        setpngcode(customerId);
    };

    const handleVerificationTypeChange = value => {
        setSelectedVerificationType(value);
    };


    const handleSubmit = async (values) => {

        if (selectedVerificationType === "EXISTING VEHICLE") {
            try {
                const payload = {
                    ...values,
                    customer_id: selectedPersonId || selectedCustomerId,
                    consent: "Y",
                    loan_id: loan_id,
                    type: "EXISTING_VEHICLE",
                };

                const response = await verifyExistingVehicleByIdService(payload);
                if (response.code === 200) {
                    message.success("Existing Vehicle successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType("");
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "PAN CARD") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "PAN CARD",
                    loan_id: loan_id,
                };

                const response = await verifyPanCardByIdService(payload);
                if (response.code === 200) {
                    message.success("Pan Card successfully validated");
                    refreshData();
                    form.resetFields();
                    form.setFieldsValue({});
                    closeForm();
                    setSelectedVerificationType("")
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "MOBILE VERIFICATION OTP") {
            try {
                const response = await verifyOtpByIdService(values);
                if (response.code === 200) {
                    message.success("Mobile Number successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "VOTER ID") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "VOTER ID",
                    loan_id: loan_id,
                };


                const response = await verifyVoterIdByIdService(payload);
                if (response.code === 200) {
                    message.success("Voter Id successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data.message);
            }
        } else if (selectedVerificationType === "BANK ACCOUNT") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "BANK ACCOUNT",
                    loan_id: loan_id,
                };


                const response = await verifyBankAccountByIdService(payload);
                if (response.code === 200) {
                    message.success("Bank Account successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "COMMERCIAL VEHICLE") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "COMMERCIAL_VEHICLE_ADVANCED",
                    loan_id: loan_id,
                };


                const response = await verifyCommercialVehicleByIdService(payload);
                if (response.code === 200 && response.message === "ok") {
                    message.success("Commercial Vehicle successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "IFSC") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "IFSC",
                    loan_id: loan_id,
                };


                const response = await verifyIFSCCodeByIdService(payload);
                if (response.code === 200 && response.message === "ok") {
                    message.success("IFCS Code successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "DRIVING LICENSE") {
            values.dob = values.dob.format("DD-MM-YYYY");
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "DRIVING LICENSE",
                    loan_id: loan_id,
                };


                const response = await verifyDrivingLincenseByIdService(payload);
                if (response.code === 200) {
                    message.success("Driving License successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "TAN AUTHENTICATION") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "TAN AUTHENTICATION",
                    loan_id: loan_id,
                };


                const response = await verifyTANAuthenticationIdService(payload);
                if (response.code === 200) {
                    message.success("TAN Authentication successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "SHOP AND ESTABLISHMENT") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "SHOP AND ESTABLISHMENT",
                    loan_id: loan_id,
                    area_code: areacode
                };


                const response = await verifyShopAndEstablishmentByIdService(payload);
                if (response.code === 200) {
                    message.success("Shop & Establishment successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "PASSPORT VERIFICATION") {
            values.passport_dob = values?.passport_dob?.format("DD/MM/YYYY");

            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "PASSPORT VERIFICATION",
                    loan_id: loan_id,
                };


                const response = await verifyPassportVerificationByIdService(payload);
                if (response.code === 200) {
                    message.success("Passport successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "PNG AUTHENTICATION") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "PNG AUTHENTICATION",
                    loan_id: loan_id,
                };


                const response = await verifyPNGAuthenticationByIdService(payload);
                if (response.code === 200) {
                    message.success("PNG Authentication successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "LPG ID AUTHENTICATION") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "LPG ID AUTHENTICATION",
                    loan_id: loan_id,
                };


                const response = await verifyLPGIDAuthenticationByIdService(payload);
                if (response.code === 200) {
                    message.success("LPG ID successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "INCOME TAX CHALLAN AUTHENTICATION") {
            values.dob = values.dob.format("DD-MM-YYYY");
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "INCOME TAX CHALLAN AUTHENTICATION",
                    loan_id: loan_id,
                };


                const response = await verifyIncomeTaxChallanAuthenticationByIdService(payload);
                if (response.code === 200) {
                    message.success("Income Tax Challan successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "ITR VERIFICATION") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "ITR VERIFICATION",
                    loan_id: loan_id,
                };


                const response = await verifyITRVerificationByIdService(payload);
                if (response.code === 200) {
                    message.success("ITR Verification successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "IEC DETAILED PROFILE") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "IEC DETAILED PROFILE",
                    loan_id: loan_id,
                };


                const response = await verifyIECDetailedProfileByIdService(payload);
                if (response.code === 200) {
                    message.success("IEC Detailed Profile successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "GST BY PAN") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "GST BY PAN",
                    loan_id: loan_id,
                };


                const response = await verifyGSTByPANByIdService(payload);
                if (response.code === 200) {
                    message.success("GST By Pan successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "FORM 16") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "FORM 16",
                    loan_id: loan_id,
                };


                const response = await verifyForm16ByIdService(payload);
                if (response.code === 200) {
                    message.success("Form 16 successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "FSSAI LICENSE AUTHENTICATION") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "FSSAI LICENSE AUTHENTICATION",
                    loan_id: loan_id,
                };


                const response = await verifyFssaiLinceseAuthenticationByIdService(payload);
                if (response.code === 200) {
                    message.success("FSSAI License Authentication successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "EMPLOYMENT VERIFICATION ADVANCED") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "EMPLOYMENT VERIFICATION ADVANCED",
                    loan_id: loan_id,
                };


                const response = await verifyEmployementVerificationByIdService(payload);
                if (response.code === 200) {
                    message.success("Employment Verification Advanced successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "ELECTRICITY BILL AUTHENTICATION") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "ELECTRICITY BILL AUTHENTICATION",
                    loan_id: loan_id,

                };


                const response = await verifyelectricitybillByIdService(payload);
                if (response.code === 200) {
                    message.success("Electricity Bill Authentication successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "EPF VERIFICATION") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "EPF VERIFICATION",
                    loan_id: loan_id,
                };


                const response = await verifyEPFVerificationByIdService(payload);
                if (response.code === 200) {
                    message.success("EPF Verification successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "EPF UAN LOOKUP") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "EPF UAN LOOKUP",
                    loan_id: loan_id,
                };


                const response = await verifyEPFUANLookupByIdService(payload);
                if (response.code === 200) {
                    message.success("EPF UAN Lookup successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "GST AUTHENTICATION") {
            try {
                const payload = {
                    ...values,
                    consent: "Y",
                    type: "GST AUTHENTICATION",
                    loan_id: loan_id,
                    additionalData: "true",
                };


                const response = await verifyGSTAuthenticationByIdService(payload);
                if (response.code === 200) {
                    message.success("GST Validation successfully validated");
                    refreshData();
                    closeForm();
                    setSelectedVerificationType(null);
                }
            } catch (error) {
                closeForm();
                setSelectedVerificationType(null);
                message.error(error?.response?.data?.message);
            }
        } else if (selectedVerificationType === "AADHAAR VERIFICATION") {
            if (requestid !== null) {
                try {
                    const payload = {
                        ...values,
                        consent: "Y",
                        type: "AADHAAR VERIFICATION",
                        loan_id: loan_id,
                        karza_request_id: requestid
                    };

                    const response = await verifyAadharByIdService(payload);
                    if (response.code === 200) {
                        message.success("Aadhar Card successfully validated");
                        setRequestid("");
                        refreshData();
                        closeForm();
                    }
                } catch (error) {
                    closeForm();
                    setSelectedVerificationType(null);
                    message.error(error?.response?.data?.message);
                }
            } else {

                try {
                    const payload = {
                        ...values,
                        consent: "Y",
                        type: "AADHAAR OTP",
                        loan_id: loan_id,
                    };



                    const response = await verifyAadharOtpByIdService(payload);
                    if (response.code === 200) {
                        message.success("Aadhar Card OTP sent successfully");
                        setRequestid(response.requestId);
                    }
                } catch (error) {
                    closeForm();
                    message.error(error?.response?.data?.message);
                }
            }
        }
        else if (selectedVerificationType === "MOBILE VERIFICATION") {
            if (mobilerequestid !== "") {
                try {
                    const payload = {
                        ...values,
                        consent: "Y",
                        type: "MOBILE VERIFICATION",
                        loan_id: loan_id,
                        karza_request_id: mobilerequestid
                    };

                    const response = await verifyMobileByIdService(payload);
                    if (response.code === 200) {
                        message.success("Mobile Number successfully validated");
                        setMobilerequestid("");
                        refreshData();
                        closeForm();
                    }
                } catch (error) {
                    closeForm();
                    setSelectedVerificationType(null);
                    message.error(error?.response?.data?.message);
                }
            } else {
                try {
                    const payload = {
                        ...values,
                        consent: "Y",
                        type: "MOBILE VERIFICATION",
                        loan_id: loan_id,
                    };

                    const response = await verifyMobileOTPByIdService(payload);
                    if (response.code === 200) {
                        message.success("Mobile Number OTP sent successfully");
                        setMobilerequestid(response.requestId);
                    }
                } catch (error) {
                    closeForm();
                    message.error(error?.response?.data?.message);
                }
            }
        }


    };

    const handleButtonClick = () => {
        setOtpSent(true);
    };


    const handleInput = (e, field) => {
        let newValue = e.target.value;
        // Allow only alphabetic characters for certain fields
        if (

            field === "employee_name"
        ) {
            newValue = newValue.replace(/[^A-Za-z\s]/g, "");
        } else if (field === "email") {
            // Allow alphabetic characters, ".", "@", and numbers for the email field
            newValue = newValue.replace(/[^A-Za-z.@0-9]/g, "");
        } else if (field === "reg_no" || field === "lpg_no" || field === "ack" || field === "amount" || field === "uan" || field === "consumer_no" || field === "mobile" || field === "otp" || field === "mobile_no" || field === "account_no" || field === "mobile_number" || field === "aadhaar_no") {
            // Allow only numbers for the mobile_number field
            newValue = newValue.replace(/[^0-9]/g, "");
        }
        form?.setFieldsValue({ [field]: newValue });
    };


    return (
        <Form layout="vertical" onFinish={handleSubmit}
            form={form}
            initialValues={{
                account_holder_name: "",
                registration_number: "",
                pan: "",
            }}>
            <Row gutter={16}>
            {selectedPersonId === null && (
                    <Col span={12}>
                        <Form.Item name="customer_id" label="Account Holder Name" rules={[yupSync]} required>
                            <Select
                                id="account_holder_name"
                                placeholder="Account Holder Name"
                                rules={[yupSync]}
                                allowClear
                            >
                                {customerData?.customer_details?.map((customer, index) => (
                                    <Select.Option
                                        key={index}
                                        value={customer?.customer_id}
                                        onClick={() => handleOptionSelect(customer?.customer_id)}
                                        placeholder="Account Holder Name"
                                    >
                                        {customer?.full_name} ({customer?.customer_type?.customer_type_name})
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                )}
                <Col span={12}>
                    <Form.Item name="type" label="Verification Type" rules={[yupSync]} required>
                        <Select id="type" placeholder="Please Select" onChange={handleVerificationTypeChange} rules={[yupSync]} allowClear>
                            {verificationtypes?.map((verificationtype, index) => (
                                <Option key={index} value={verificationtype}>{verificationtype}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {selectedVerificationType === "EXISTING VEHICLE" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="registration_no" label="Registration Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Registration Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "MOBILE VERIFICATION" ? (
                <>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="mobile_no" label="Mobile Number" rules={[yupSync]} required>
                                <Input placeholder="Please Enter Mobile Number" onChange={(e) => handleInput(e, "mobile_no")} />
                            </Form.Item>
                        </Col>
                        {mobilerequestid &&
                            <Col span={12}>
                                <Form.Item name="otp" label="OTP" required>
                                    <Input placeholder="Please Enter OTP" onChange={(e) => handleInput(e, "otp")} />
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                </>
            ) : selectedVerificationType === "COMMERCIAL VEHICLE" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="registration_no" label="Registration Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Registration Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "PAN CARD" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="pan" label="PAN Card Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter PAN Card Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "VOTER ID" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="epic_no" label="EPIC Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter EPIC Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "BANK ACCOUNT" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="ifsc_no" label="IFSC Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter IFSC Number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="account_no" label="Account Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Account Number" onChange={(e) => handleInput(e, "account_no")} />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "IFSC" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="ifsc_no" label="IFSC Code" rules={[yupSync]} required>
                            <Input placeholder="Please Enter IFSC Code" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "DRIVING LICENSE" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="dob" label="Date Of Birth" rules={[yupSync]} required>
                            <DatePicker
                                format="DD-MM-YYYY"
                                className='datepicker'
                                placeholder="Select Date"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="dl_no" label="Driving License Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Driving License Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "ITR VERIFICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="ack_no" label="ACK" rules={[yupSync]} required>
                            <Input placeholder="Please Enter ACK Number" onChange={(e) => handleInput(e, "ack")} />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "ELECTRICITY BILL AUTHENTICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="consumer_no" label="Consumer Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Consumer Number" onChange={(e) => handleInput(e, "consumer_no")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="electricity_service_provider" label="Service Provider" rules={[yupSync]} required>
                            <Select id="electricity_service_provider" placeholder="Please Select" rules={[yupSync]}
                                onChange={handleElectricityProviderTypeChange}
                                allowClear>
                                {electricityproviders?.map((provider, index) => (
                                    <Option key={index} value={provider.electricity_code}>
                                        {provider.electricity_board_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {electricityproviderid === "UPPCL" && (
                        <Col span={12}>
                            <Form.Item name="district" label="District">
                                <Input placeholder="Please Enter District" />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            ) : selectedVerificationType === "GST AUTHENTICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="gstin_no" label="GSTIN Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter GSTIN Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "GST BY PAN" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="pan" label="PAN Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter PAN Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "EPF UAN LOOKUP" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="mobile_no" label="Mobile Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Mobile Number" onChange={(e) => handleInput(e, "mobile")} />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "UDYOG AADHAR NUMBER" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="aadhaar_no" label="Aadhar Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Aadhar Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "FSSAI LICENSE AUTHENTICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="reg_no" label="Registration Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Registration Number" onChange={(e) => handleInput(e, "reg_no")} />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "SHOP AND ESTABLISHMENT" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="reg_no" label="Registration Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Registration Number" onChange={(e) => handleInput(e, "reg_no")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="area_code" label="Area Code" rules={[yupSync]} required>
                            <Select id="area_code" placeholder="Please Select" rules={[yupSync]}
                                onChange={handleAreaCodeTypeChange}
                                allowClear>
                                {areacodeproviders?.map((provider, index) => (
                                    <Option key={index} value={provider.area_code}>
                                        {provider.area_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "PASSPORT VERIFICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="passport_dob" label="Date Of Birth" rules={[yupSync]} required>
                            <DatePicker
                               className='datepicker'
                                placeholder="Select Date"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="file_no" label="File Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter File Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "FORM 16" ? (
                <>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="tan_no" label="TAN Number" rules={[yupSync]} required>
                                <Input placeholder="Please Enter TAN Number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="pan_no" label="PAN Number" rules={[yupSync]} required>
                                <Input placeholder="Please Enter PAN Number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="cert_no" label="CERT Number" rules={[yupSync]} required>
                                <Input placeholder="Please Enter CERT Number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="amount" label="AMOUNT" rules={[yupSync]} required>
                                <Input placeholder="Please Enter Amount" onChange={(e) => handleInput(e, "amount")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="fiscal_year" label="Fiscal Year" rules={[yupSync]} required>
                                <Input placeholder="Please Enter Fiscal Year" />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ) : selectedVerificationType === "FDA LICENSE AUTHENTICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="licence_no" label="License Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter License Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "TAN AUTHENTICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="tan_no" label="TAN Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter TAN Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "MCA SIGNATORIES" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="tan_no" label="TAN Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter TAN Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "LPG ID AUTHENTICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="lpg_no" label="LPG ID" rules={[yupSync]} required>
                            <Input placeholder="Please Enter LPG ID" onChange={(e) => handleInput(e, "lpg_no")} />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "EMPLOYMENT VERIFICATION ADVANCED" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="employer_name" label="Employer Name" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Employer Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="employee_name" label="Employee Name" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Employee Name" onChange={(e) => handleInput(e, "employee_name")} />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "IEC DETAILED PROFILE" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="iec_no" label="IEC Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter IEC Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "COMPANY IDENTIFICATION NUMBER" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="cin_no" label="CIN Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter CIN Number" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "PNG AUTHENTICATION" ? (
                <Row gutter={16}>

                    <Col span={12}>
                        <Form.Item name="png_service_provider" label="Service Provider" rules={[yupSync]} required>

                            <Select id="png_service_provider" placeholder="Please Select" rules={[yupSync]}
                                onChange={handlePNGCodeChange}
                                allowClear>
                                {pngcodes?.map((provider, index) => (
                                    <Option key={index} value={provider}>
                                        {provider}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="consumer_id" label="Consumer Id" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Consumer Id" />
                        </Form.Item>
                    </Col>
                    {(pngcode === 'IG' || pngcode === 'MG') && (
                        <Col span={12}>
                            <Form.Item
                                name="bp_no"
                                label="BP Number"
                                rules={[yupSync]}
                                required
                            >
                                <Input placeholder="Please Enter BP Number" />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            ) : selectedVerificationType === "INCOME TAX CHALLAN AUTHENTICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="bsr_code" label="BSR Code" rules={[yupSync]} required>
                            <Input placeholder="Please Enter BSR Code" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="challan_number" label="Challan Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Challan Number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="dob" label="Date Of Birth" rules={[yupSync]} required>
                            <DatePicker
                                format="DD-MM-YYYY"
                                className='datepicker'
                                placeholder="Select Date"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="amount" label="Amount" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Amount" />
                        </Form.Item>
                    </Col>
                </Row>
            ) : selectedVerificationType === "AADHAAR VERIFICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="aadhaar_no" label="Aadhar Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter Aadhar Number" onChange={(e) => handleInput(e, "aadhaar_no")} />
                        </Form.Item>
                    </Col>
                    {requestid &&
                        <Col span={12}>
                            <Form.Item name="otp" label="Aadhar OTP" rules={[yupSync]} required>
                                <Input placeholder="Please Enter Aadhar OTP" />
                            </Form.Item>
                        </Col>
                    }
                </Row>
            ) : selectedVerificationType === "EPF VERIFICATION" ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="uan" label="UAN Number" rules={[yupSync]} required>
                            <Input placeholder="Please Enter UAN Number" onChange={(e) => handleInput(e, "uan")} />
                        </Form.Item>
                    </Col>
                </Row>
            )
                : ""}

            <Space direction="horizontal" align="center" className='submit_space' >
                <Button
                    type="primary"
                    onClick={handleButtonClick}
                    htmlType="submit"
                >
                    {((selectedVerificationType === "AADHAAR VERIFICATION") || (selectedVerificationType === "MOBILE VERIFICATION"))
                        ? (otpSent ? "Verify" : "Send OTP")
                        : "Verify"}
                </Button>
            </Space>
        </Form>

    );
};

export default KarzaDetailsForm;