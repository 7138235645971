import React, { useState, useEffect } from 'react';
import { Tabs, message } from 'antd';
import '../../partdisbursalDetails/styles/partDisbursalDetails.css';
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { getApprovalDetailsByIdService } from '../services/approvalDetailsService';
import TechnicalApprovalForm from './TechnicalApprovalForm';
import CollectionForm from './CollectionForm';
import CreditForm from './CreditForm';
import OperationForm from './Operationform';
import Loading from "../../../../utils/loading/Loading";
import CustomNoData from '../../../../utils/noDataIcon/CustomNoData';

const ApprovalDetails = ({ tabDisbursementId, tabDisbursmentData, onHistoryTableRefresh, refreshPartDisbursmentTab }) => {
  const [approvalDetails, setApprovalDetails] = useState({});
  const [approvalTab, setApprovalTab] = useState(0)
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState({
    Technical: false,
    Collection: false,
    Credit: false,
    Operations: false,
  });

  const toggleRefreshData = () => {
    refreshPartDisbursmentTab((prev) => !prev);
  };


  const fetchApprovalDetails = async () => {
    setLoading(true);
    try {
      const response = await getApprovalDetailsByIdService(tabDisbursementId);
      if (response?.data) {
        setApprovalDetails(response?.data);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tabDisbursementId) {
      fetchApprovalDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabDisbursementId]);

  const handleFormSubmit = (department, index) => {
    setFormSubmitted((prevState) => ({
      ...prevState,
      [department]: true,
    }));
    toggleRefreshData();
    fetchApprovalDetails();
    setApprovalTab(index)
    onHistoryTableRefresh();
  };

  const renderApprovalDetails = (department) => (
    <div className="main_part_disbursal_details_container">
      <div className="part_disbursal_container">
        <div className="part_disbursal_card1">
          <div className="part_disbursed_label">Approval Status</div>
          <div className="data">
            {approvalDetails?.department_approvals?.[department]?.current_status?.display_name || "-"}
          </div>
        </div>
        <div className="part_disbursal_card1">
          <div className="part_disbursed_label">Remark</div>
          <div className="data">
            {approvalDetails?.department_approvals?.[department]?.remark || "-"}
          </div>
        </div>
        {department === "Credit" && (
          <>
            <div className="part_disbursal_card1">
              <div className="part_disbursed_label">Construction Completion Recommended</div>
              <div className="data">
                {approvalDetails?.department_approvals?.[department]?.construction_completion_recommended_as_per_user ||
                  tabDisbursmentData?.construction_completion_recommended_as_per_user ||
                  "-"}%
              </div>
            </div>
            <div className="part_disbursal_card1">
              <div className="part_disbursed_label">Disbursement Amount Requested</div>
              <div className="data">
                {approvalDetails?.department_approvals?.[department]?.disbursment_amount_requested
                  ? `₹${new Intl.NumberFormat('en-IN').format(
                    approvalDetails.department_approvals[department].disbursment_amount_requested
                  )}`
                  : tabDisbursmentData?.disbursment_amount_requested
                    ? `₹${new Intl.NumberFormat('en-IN').format(
                      tabDisbursmentData.disbursment_amount_requested
                    )}`
                    : "-"}
              </div>

            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="part_disbursment_tables_content">
      <div className="main_disbursal">
        <div className="part_disbursed_heading">
          <div className="part_disbursed_heading_data">
            <h3>Approval</h3>
          </div>
        </div>
        <div className="tab_container">
          {loading ? (
            <Loading />
          ) : Object.keys(approvalDetails)?.length > 0 ? (
            <Tabs
              type="card"
              defaultActiveKey={approvalTab}
              items={[
                {
                  label: 'Technical',
                  key: '1',
                  children: (
                    approvalDetails?.department_approvals?.Technical ? (
                      renderApprovalDetails('Technical')
                    ) : (
                      <TechnicalApprovalForm
                        tabDisbursementId={tabDisbursementId}
                        tabDisbursmentData={tabDisbursmentData}
                        onFormSubmit={handleFormSubmit}
                        index='1'
                      />
                    )
                  ),
                },
                {
                  label: 'Collection',
                  key: '2',
                  disabled: !(
                    approvalDetails?.department_approvals?.Technical ||
                    formSubmitted.Technical
                  ),
                  children: (
                    approvalDetails?.department_approvals?.Collection ? (
                      renderApprovalDetails('Collection')
                    ) : (
                      <CollectionForm
                        tabDisbursementId={tabDisbursementId}
                        tabDisbursmentData={tabDisbursmentData}
                        onFormSubmit={handleFormSubmit}
                        index='2'
                      />
                    )
                  ),
                },
                {
                  label: 'Credit',
                  key: '3',
                  disabled: !(
                    approvalDetails?.department_approvals?.Collection ||
                    formSubmitted.Collection
                  ),
                  children: (
                    approvalDetails?.department_approvals?.Credit ? (
                      renderApprovalDetails('Credit')
                    ) : (
                      <CreditForm
                        tabDisbursementId={tabDisbursementId}
                        tabDisbursmentData={tabDisbursmentData}
                        onFormSubmit={handleFormSubmit}
                        index='3'
                      />
                    )
                  ),
                },
                {
                  label: 'Operations',
                  key: '4',
                  disabled: !(
                    approvalDetails?.department_approvals?.Credit ||
                    formSubmitted.Credit
                  ),
                  children: (
                    approvalDetails?.department_approvals?.Operations ? (
                      renderApprovalDetails('Operations')
                    ) : (
                      <OperationForm
                        tabDisbursementId={tabDisbursementId}
                        tabDisbursmentData={tabDisbursmentData}
                        onFormSubmit={handleFormSubmit}
                        index='4'
                      />
                    )
                  ),
                },
              ]}
            />
          ) : (
            <div className="no_data_found_message">
              <CustomNoData />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalDetails;
