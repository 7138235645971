import React, { useState, useEffect } from 'react';
import ExistingLoanDetailsForm from '../../../loanApplication/customerTabDetails/existingLoansDetails/views/ExistingLoanDetailsForm';
import { Tabs, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeementTabsData, deleteExistingTabsData } from '../../services/salesformservices';
import { setInitialCustomerExistingLoan, changeActiveExistingLoanKey, setExistingApicalled, removeExisting } from '../../../../redux/salesFormSlice';

const SalesFormExistingForm = (activeCustomer) => {

  const { customers } = useSelector((state) => state.salesForm);
  const [openModal, setOpenModal] = useState(false);
  const [targetKey, setTargetKey] = useState(null);

  const existingloanDetails = customers[parseInt(activeCustomer.activeCustomer)]?.existingloanDetails?.existingloan;
  const existingloanActiveKey = customers[parseInt(activeCustomer.activeCustomer)]?.existingloanDetails?.activeExistingLoanKey;
  const existingApiCalled = customers[parseInt(activeCustomer.activeCustomer)]?.existingloanDetails?.existingloanapicalled;
  const customerId = customers[parseInt(activeCustomer.activeCustomer)]?.id;

  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  useEffect(() => {
    const getCustomerExistingLoans = async () => {
      try {
        const response = await getEmployeementTabsData(customerId);

        if (response?.data?.existing_loan_detail?.length === 0) {
          dispatch(setInitialCustomerExistingLoan({
            activeCustomer: activeCustomer.activeCustomer,
            existingloan: [null],
          }));
        } else {
          dispatch(setInitialCustomerExistingLoan({
            activeCustomer: activeCustomer.activeCustomer,
            existingloan: response?.data?.existing_loan_detail,
          }));
        }

        if (existingApiCalled === 0) {
          dispatch(setExistingApicalled({ index: activeCustomer.activeCustomer }));
        }
      } catch (error) {
        console.error('Error fetching existing loan details:', error);
      }
    };

    if (existingApiCalled === 0) {
      getCustomerExistingLoans();
    }

  }, [customerId, activeCustomer, existingApiCalled, dispatch]);

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      addNewExisting();
    } else {
      setTargetKey(targetKey);  // Store the target key of the tab to delete
      setOpenModal(true);  // Open the confirmation modal
    }
  };

  const handleOk = async () => {
 
    const existing = existingloanDetails[parseInt(existingloanActiveKey)]; 

    if (existing) {
      if (existingloanDetails.length > 1) 
      {
      const deleteTabById = async () => {
        try {
          
          const response = await deleteExistingTabsData(existing);

          if (response.status === 200 && response.success === true) {
            message.success("Tab deleted successfully"); 
            dispatch(removeExisting({
              activeCustomerKey: activeCustomer.activeCustomer,
              activeExistingLoanKey: targetKey,
            }));

            if (existingloanActiveKey > 0)
              {
            dispatch(
              changeActiveExistingLoanKey({
                activeCustomer: activeCustomer.activeCustomer,
                key: existingloanActiveKey - 1,
              })
            );
          }
          } else {
            message.error("Failed to delete the tab");
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "Error deleting tab");
        }
      };
      deleteTabById();
      } else {
        message.error("Cannot Delete Last Tab");
      }
      
    } else {
      if (existingloanDetails.length > 1) {
      dispatch(removeExisting({
        activeCustomerKey: activeCustomer.activeCustomer,
        activeExistingLoanKey: targetKey,
      }));

      if (existingloanActiveKey > 0)
      {
      dispatch(
        changeActiveExistingLoanKey({
          activeCustomer: activeCustomer.activeCustomer,
          key: existingloanActiveKey - 1,
        })
      );
      }
    } else {
      message.error("Cannot Delete Last Tab");
    }

    }
    setOpenModal(false); 
  };

  const handleCancel = () => {
    setOpenModal(false);  // Close the modal without doing anything
  };

  const addNewExisting = () => {
    const updatedExistingLoans = [...existingloanDetails, null];

    dispatch(setInitialCustomerExistingLoan({
      activeCustomer: activeCustomer.activeCustomer,
      existingloan: updatedExistingLoans,
    }));

    dispatch(changeActiveExistingLoanKey({
      activeCustomer: activeCustomer.activeCustomer,
      key: updatedExistingLoans.length - 1,
    }));
  };

  const onChange = (key) => {
    dispatch(changeActiveExistingLoanKey({
      activeCustomer: activeCustomer.activeCustomer,
      key: key,
    }));
  };

  return (
    <div>
      <Tabs type="editable-card" onChange={onChange} onEdit={onEdit} activeKey={existingloanActiveKey.toString()}>
        {existingloanDetails &&
          existingloanDetails.map((existing, index) => (
            <TabPane tab={`Existing Loan Details ${index + 1}`} key={index}>
              <ExistingLoanDetailsForm
                existing={existing}
                customer_id={customerId}
                id={existingloanDetails[index]}
                activeExistingLoanKey={index}
                activeCustomerKey={activeCustomer.activeCustomer}
                isSalesForm={true}
              />
            </TabPane>
          ))}
      </Tabs>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okType="danger"
      >
        <p>Are you sure you want to delete this existing loan tab?</p>
      </Modal>
    </div>
  );
};

export default SalesFormExistingForm;
