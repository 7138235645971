import React, { useEffect, useState } from "react";
import { getCustomersByLoanId } from "../services/PDCService";
import { message, Select } from "antd";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import BankAccountDetailsForm from "../../../../customerTabDetails/bankAccountDetails/views/BankAccountDetailsForm";

const AddBankAccount = ({ loanId, refreshData, open, closeForm }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    const fetchCustomersByLoanId = async () => {
      try {
        const response = await getCustomersByLoanId(loanId);
        const customers = response?.data?.customer_list?.map((customer) => {
          return {
            id: customer.id,
            name: `${customer.name} (${customer.customer_type})`,
          };
        });
        setCustomerList(customers);

        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchCustomersByLoanId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!open) {
      setSelectedCustomer(null);
    }
  }, [open]);

  const handleCustomerchange = (value) => {
    setSelectedCustomer(value);
  };

  return (
    <div>
      <Select
        value={selectedCustomer}
        onChange={handleCustomerchange}
        className="select_bank"
        placeholder="Please Select Customer"
        labe
      >
        {customerList.length > 0 &&
          customerList.map((customer) => {
            return (
              <Select.Option value={customer.id}>{customer.name}{customer.customer_type}</Select.Option>
            );
          })}
      </Select>

      {selectedCustomer && (
        <BankAccountDetailsForm
          refreshData={refreshData}
          open={open}
          closeForm={closeForm}
          customer_id={selectedCustomer}
        />
      )}
    </div>
  );
};

export default AddBankAccount;
