import React, { useEffect, useState } from "react";
import "../styles/fi.css";
import { Tabs, message} from "antd";
import ApplicantRV from "./RVView/ApplicantRV";
import ApplicantOV from "./OVView/ApplicantOV";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import UseCustomer from "../hooks/UseCustomer";
import { getCustomerDetailListByLoanId, getCustomerListByLoanId } from "../services/FIViewFormServices";

const { TabPane } = Tabs;

const FiTabDetails = ({loanId, stageId}) => {
  const { customerId } = UseCustomer();
  const [fiAdresses, setFiAddresses] = useState()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedApplicantId, setSelectedApplicantId] = useState(customerId);
  const [showCustomerDetails, setShowCustomerDetails] = useState(true);
  const [customerDetails, setCustomerDetails] = useState();

  const fetchCustomerDetails = async () => {
    try {
      const response = await getCustomerDetailListByLoanId(selectedApplicantId);
      const details = response?.data;
      setCustomerDetails(details);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };  
  useEffect(() => {
    if (selectedApplicantId) {
      fetchCustomerDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApplicantId]);

  const fetchFiViewData = async () => {
    try {
      const response = await getCustomerListByLoanId(stageId);
      const addresses = response?.data;
      setFiAddresses(addresses);
      if (addresses.length > 0) {
        const firstApplicantId = addresses[0].id;
        setSelectedApplicantId(firstApplicantId); // Set the default selected applicant ID
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    fetchFiViewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCustomerDetails = () => {
    setShowCustomerDetails((prev) => !prev);
  };

  const ApplicantFIdata = fiAdresses?.map((item) => {
    const newData = {
      ...item,
      id: item.id,
      label:
        item.visit_type === "RV"
          ? "ApplicantRV"
          : "ApplicantOV",
      type: item.customer_type.name,
    };
    return newData;
  });

  const typeToComponentMap = {
    ApplicantRV: ApplicantRV,
    ApplicantOV: ApplicantOV,
  };

  const renderTabContent = () => {
    const selectedData = ApplicantFIdata?.find(
      (item) => item.id === selectedApplicantId
    );
    if (!selectedData) return null;

    const Component = typeToComponentMap[selectedData.label] || null;
    return Component ? <Component selectedData={selectedData} customerDetails={customerDetails} selectedCustomerId={selectedApplicantId}/> : null;
  };
  const handleTabClick = (id) => {
    setSelectedApplicantId(id);
  };

  const handleTabChange = (key) => {
    setSelectedTabIndex(Number(key));
    const selectedApplicant = fiAdresses[Number(key)];
    if (selectedApplicant) {
      handleTabClick(selectedApplicant.id);
    }
  };

  return (
    <>
   
      <div className="legal_tabs_container">
       
        <div
           className={
            showCustomerDetails
              ? "customer_main_heading_fi with-border"
              : "customer_main_heading_fi no-border"
          }
        >
          <div>
            <h2 className="heading_loan_details">Details</h2>
          </div>
          <div className="customer_icon">
            {showCustomerDetails ? (
              <AiOutlineUp
              className="detail_fi_input"
                onClick={toggleCustomerDetails}
              />
            ) : (
              <AiOutlineDown
              className="detail_fi_input"
                onClick={toggleCustomerDetails}
              />
            )}
          </div>
        </div>

        <div className={showCustomerDetails ? "customer-details visible" : "customer-details hidden"}>
          <Tabs
            activeKey={String(selectedTabIndex)}
            onChange={handleTabChange}
            type="card"
          className="fi_tabs"
          >
            {fiAdresses?.map((applicant, index) => (
              <TabPane
                tab={
                  <div onClick={() => handleTabClick(applicant.id)}>
                    {applicant.customer_name}-{applicant.visit_type}
                  </div>
                }
                key={index}
              >
              </TabPane>
            ))}
            
          </Tabs>

          <div>{renderTabContent()}</div>
        </div>
      </div>
    </>
  );
};

export default FiTabDetails;
