import React, { useEffect, useState } from "react";
import { Space, Input, Tooltip, message, Typography, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getTableDataService } from "./dataTableService";

const DataTable = ({
  tableData,
  setTableData,
  apiPath,
  tableColumns,
  refreshData,
  searchPlaceholder,
  filters,
  customerid,
  ocrDatas
}) => {
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sort: {
      field: null,
      order: null,
    },
    searchQuery: "",
  });

  const { Text } = Typography; 
 

  const handleTableChange = (pagination, filter, sorter) => {
    const { field, order } = sorter;

    const newTableParams = {
      ...tableParams,
      pagination: {
        ...pagination,
      },
      sort: {
        field,
        order,
      },
    };

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTableData([]);
    }

    setTableParams(newTableParams);
  };

  const fetchTableData = async () => {
    try {
       const page = tableParams.pagination.current;
      const limit = tableParams.pagination.pageSize;
      const searchQuery = tableParams.searchQuery;
      const sortField = tableParams.sort.field;
      const sortOrder = tableParams.sort.order;

      setLoading(true); 
      
      // Make the API call with the final API path
      let response = await getTableDataService(
        apiPath,
        page,
        limit,
        searchQuery,
        sortField,
        sortOrder,
        filters
      );

      if (Math.ceil(response.count / limit) < page) {
        response = await getTableDataService(
          apiPath,
          Math.ceil(response.count / limit),
          limit,
          searchQuery,
          sortField,
          sortOrder,
          filters
        );
      }

      setTableData(response?.data);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams?.pagination,
          total: response?.count,
        },
      });
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (tableParams.searchQuery.length < 1) {
      fetchTableData();
    } else {
      const delayDebounceFn = setTimeout(() => {
        fetchTableData();
      }, 800);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiPath,
    tableParams.pagination.current,
    tableParams.searchQuery,
    tableParams.sort.field,
    tableParams.sort.order, 
    customerid,
    ocrDatas,
    tableParams.pagination.pageSize,
    refreshData,
  ]);

  return (
    <>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Input
          placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
          suffix={
            <Tooltip title="Search in table.">
              <SearchOutlined className="site-form-item-icon" />
            </Tooltip>
          }
          value={tableParams.searchQuery}
          onChange={(e) =>
            setTableParams((prevTableParams) => ({
              ...prevTableParams,
              searchQuery: e.target.value,
              pagination: {
                ...prevTableParams.pagination,
                current: 1, // This updates the 'current' value to 1 when the search query changes.
              },
            }))
          }
        />
        <Text>
          Showing{" "}
          {tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
            1}{" "}
          to{" "}
          {tableParams.pagination.total >=
          tableParams.pagination.pageSize * tableParams.pagination.current
            ? tableParams.pagination.pageSize * tableParams.pagination.current
            : tableParams.pagination.total}{" "}
          out of {tableParams.pagination.total}
        </Text>
      </Space>

      <div style={{ marginTop: "16px" }}>
        <Table
          style={{ marginTop: "0.5rem" }}
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={tableData}
          pagination={{
            ...tableParams.pagination,
            showSizeChanger: true,
            position: ["bottomRight"],
          }}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </div>
    </>
  );
};

export default DataTable;
