import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import getDocumentTypeList from "../../services/getDocumentTypeList";
import { getIdentificationDocumentsById } from "../../services/FIViewFormServices";
import { updateIdentificationDocumentsById } from "../../services/applicantRvServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
const IdentificationDocumentsEditForm = ({  closeForm, id, open, refreshData }) => {
  const [form] = Form.useForm();
  const [documentType, setDocumentType] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    const fetchDocumentTypeList = async () => {
      const response = await getDocumentTypeList();
      setDocumentType(response.data);
    };
    fetchDocumentTypeList();
  }, []);

  const getDocumentData = async () => {
    if (id) {
      try {
        const response = await getIdentificationDocumentsById(id);
        const { document_type, document_number } =
          response?.data;
        form.setFieldsValue({
          document_type: document_type?.id,
          document_number,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };
  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updateIdentificationDocumentsById(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Identification documents successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      getDocumentData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
  
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="document_type"
              label="Enter Identification Document"
              required
            >
              <Select
                placeholder="Please select"
                allowClear
                
                showSearch
                // onChange={handleDocumentNameChange}
                // disabled={uploadDisabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {documentType?.map((legaldocumenttype) => (
                  <Option
                    key={legaldocumenttype.name}
                    value={legaldocumenttype.id}
                  >
                    {legaldocumenttype.display_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="document_number"
              label=" Details"
            >
              <Input rows={1} placeholder="Enter document" />
            </Form.Item>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
className="rv_button_submit_cancel"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default IdentificationDocumentsEditForm;
