// CustomNoData.js
import React from 'react';
import { Empty } from 'antd';

const CustomNoData = () => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  );
};

export default CustomNoData;
