import React, { useEffect, useState } from 'react';
import "../styles/televerification.css"
import { EllipsisOutlined } from '@ant-design/icons';
import { SlCallOut, SlCallIn } from "react-icons/sl";
import { IoCallOutline } from "react-icons/io5";
import { Badge, Drawer, message } from 'antd';
import ReactAudioPlayer from 'react-audio-player';
import { getTeleverificationDetailsByIdService } from '../services/televerificationDetailsService';
import { useParams } from 'react-router-dom';
import Loading from "../../../../../utils/loading/Loading"
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import "../../../../commonCss/commonStyle.css";
// import Group from "../assets/Group.png"
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import TeleVerificationCallDetailsForm from './TeleVerificationCallDetailsForm';
import Header from '../../../../layout/views/Header';
import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import { useSelector } from 'react-redux';
import CustomNoData from '../../../../../utils/noDataIcon/CustomNoData';

const additionalHeaders = [
  "Key Parameter's",
  "Tele Verification",
];
const TeleVerificationCallDetails = () => {
  const { encrypted_loan_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [televerificationData, setTeleverificationData] = useState();
  const [selectedPerson, setSelectedPerson] = useState(null);

  const { user_data } = useSelector((state) => state.user);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    // setSelectedCustomer(null);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const data = await getTeleverificationDetailsByIdService(loan_id);
        setTeleverificationData(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);
  return (
    <div>
      <div className="tab_form_container">
        <Drawer
          title={
            <Header
              title="Call"
              onClose={onClose}
              name={`${selectedPerson?.customer_name}`}
            />
          }
          width={970}
          onClose={onClose}
          open={open}
          closable={false}
        >
          <TeleVerificationCallDetailsForm
            refreshData={toggleRefreshTableData}
            customer_id={selectedCustomer}
            open={open}
            closeForm={onClose}
          />

        </Drawer>

        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : televerificationData && televerificationData.max_count > 0 ? (
            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">{additionalHeaders[0]}
                  </th>
                  {televerificationData?.results.map((person, index) => (
                    <th className="tab_form_top_heading" >
                      <span className='tab_televarifican'>
                        <div>
                          <span>{`${person?.customer_name}`} </span>
                          <span>
                            <span>{`(${person?.customer_type})`}  </span>
                            {/* <img className='image_verify_tele' src={Group} alt="group" /> */}

                          </span>
                        </div>
                        <ConditionalRender
                          userPermissions={user_data?.permissions}
                          requiredPermissions={["POSTCUST"]}
                        >
                          <div
                            className="tab_form_sub_edit"
                            onClick={() => {
                              setSelectedCustomer(person?.customer_id);
                              setSelectedPerson(person)
                              showDrawer();
                            }}
                          >
                            <IoCallOutline
                              className='call_icon'
                            />
                          </div>
                        </ConditionalRender>
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>

                <tr >
                  <td className='tab_form_left_heading' >
                    {additionalHeaders[1]}
                  </td>
                  {
                    televerificationData?.results?.map((person, index) => {
                      return (
                        <td className='tele_td'>
                          <div>
                            {person?.audio_calls?.length > 0 ? (
                              person?.audio_calls?.map((media, index) => (
                                <div className='televerification_card' key={index}>
                                  <div className='televerification_card_two'>
                                    <div className='televerification_response_box'>
                                      <div className='televerification_response'
                                        style={{
                                          background:
                                            media?.call_status === "completed"
                                              ? "#F6FFED"
                                              : media?.call_status === "in-progress"
                                                ? "#FFFBE6"
                                                : "#FFF1F0",
                                        }}>
                                        <Badge
                                          status={
                                            media?.call_status === "completed"
                                              ? "success"
                                              : media?.call_status === "in-progress"
                                                ? "processing"
                                                : "error"
                                          }
                                          text={media?.call_status}
                                        />
                                      </div>
                                    </div>
                                    <div className='more_icon'>
                                      <EllipsisOutlined className='ellipsisoutlined_icon' />
                                    </div>
                                  </div>
                                  <div className='televerification_number'>
                                    <div className='number_first'>
                                      <div><SlCallOut /></div>
                                      <div>{media.call_from}</div>
                                    </div>
                                    <div className='number_second'>
                                      <div><SlCallIn /></div>
                                      <div>{media.call_to}</div>
                                    </div>
                                  </div>
                                  <div className='audio_package'>
                                    <ReactAudioPlayer src={media.recording} controls />
                                  </div>

                                </div>
                              ))
                            ) : (
                              <div className='no_media_details'>
                                -
                              </div>
                            )}
                          </div>
                        </td>

                      )
                    })
                  }

                </tr>

              </tbody>
            </table>
          ) : (
            <div className='fade-in'>
              <div>
                <table className='tab_form_main_table no_data_table_height'>
                  <thead>
                    <tr>
                      <th
                        className="tab_form_key_parameters"
                      >
                        {additionalHeaders[0]}
                      </th>
                      {televerificationData?.results.map((person, index) => (
                        <th
                          key={index}
                          className="tab_form_top_heading no_data_heading_bottom_border"
                        >
                          <span className='no_data_heading_content'>
                            <div>
                              <span>{`${person?.customer_name}`} </span>
                              <span>
                                <span>{`(${person?.customer_type})`} </span>
                                {/* <img
                                  src={Group}
                                  alt="group"
                                  className='verify_icon'
                                /> */}
                              </span>
                            </div>
                            <ConditionalRender
                              userPermissions={user_data?.permissions}
                              requiredPermissions={["POSTCUST"]}
                            >
                              <div
                                className="tab_form_sub_edit"
                                onClick={() => {
                                  setSelectedCustomer(person?.customer_id);
                                  setSelectedPerson(person);
                                  showDrawer();
                                }}

                              >
                                <IoCallOutline
                                  className='call_icon'
                                />
                              </div>
                            </ConditionalRender>
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Placeholder to ensure table body aligns */}
                    {televerificationData?.results.length ? (
                      <tr>
                        <td colSpan={televerificationData.results.length + 1}>
                          {/* Your data rows go here */}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={televerificationData?.results.length + 1}
                        ></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className='no_data_icon'
              >
                <CustomNoData />
              </div>
            </div>


          )}
        </div>
      </div>
    </div>
  );
}

export default TeleVerificationCallDetails;