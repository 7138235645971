import React from 'react'
import UserFormController from './UserFormController'
import { useSelector } from "react-redux";


const UserProfile = () => {
  const { user_data } = useSelector((state) => state.user);

  return (
    <div>
        <UserFormController userData={user_data} style={{border:"5px solid red", padding: "20px", backgroundColor: "#f0ff0" }} />
    </div>
  )
}

export default UserProfile