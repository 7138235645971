import {axiosRequest} from "../../../../utils/api/axiosRequest"


export const getApprovalDetailsByIdService = (tabDisbursementId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/part-disbursment/approval-status/${tabDisbursementId}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getPartDisbursalDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/part-disbursment/?loan_detail=${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listApprovalStatusService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/status/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const listDepartmentService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/departments/`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const updateApprovalStatusByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/part-disbursment/approval-status/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};