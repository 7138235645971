import React, { useEffect, useState } from "react";
import { Form, Select, Input, Button, Space, Row, Col, message } from "antd";
import {
  listApprovalStatusService,
  updateApprovalStatusByIdService,
} from "../services/approvalDetailsService";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const { TextArea } = Input;

const CollectionForm = ({ tabDisbursmentData, tabDisbursementId, onFormSubmit,index }) => {
  const [allApprovalStatus, setAllApprovalStatus] = useState([]);
  const [form] = Form.useForm();
  
  useEffect(() => {
    const getAllApprovalStatus = async () => {
      try {
        const response = await listApprovalStatusService();
        if (response?.data?.length) {
          setAllApprovalStatus(response?.data);
        }
      } catch (error) {
        message.error(`Failed to fetch approval statuses: ${error?.message}`);
      }
    };
    getAllApprovalStatus();
  }, []);


  const handleSubmit = async (values) => {
    try {
      const updatedValues = {
        ...values,
        department: "MDNTLL6",
       ...tabDisbursmentData
      };
      const response = await updateApprovalStatusByIdService(
        tabDisbursementId,
        updatedValues
      );
     
  
      if (response && response.success) {
        message.success("Approval status updated successfully!");
        form.resetFields();
        onFormSubmit(updatedValues,index);
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  return (
    <div className="approval_details_tabs_form">
      <Form
        name="Technical-approval-form"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
      >
        <Row gutter={16} className="approval_details_tabs_form_row">
          <Col span={8}>
            <Form.Item
              name="current_status"
              label="Approval Status"
            >
              <Select
                placeholder="Select approval status"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {allApprovalStatus.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="remark"
              label="Remarks"
            >
              <TextArea placeholder="Enter remarks" rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Space  className="approval_details_tabs_form_button">
          <Button type="default" onClick={() => form.resetFields()}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default CollectionForm;
