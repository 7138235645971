import React, { useEffect, useState } from "react";
import "../styles/calenderEventView.css";
import GoogleMapLocation from "../../../../utils/googleMapLocation/GoogleMapLocation";
import { getEventDetailsById } from "../services/getCalenderEvents";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { message } from "antd";

const CalendarEventView = ({ eventId }) => {
  const [eventDetailsById, setEventDetailsById] = useState([]);
  const [inlatitude, setInLatitude] = useState();
  const [inlongitude, setInLongitude] = useState();
  const [outlatitude, setOutLatitude] = useState();
  const [outlongitude, setOutLongitude] = useState();


  useEffect(() => {
    const fetchEventDetailsById = async (id) => {
      try {
        const response = await getEventDetailsById(id);
        setEventDetailsById(response.data);
        setInLatitude(parseFloat(response.data.in_latitude));
        setInLongitude(parseFloat(response.data.in_longitude));
        setOutLatitude(parseFloat(response.data.out_latitude));
        setOutLongitude(parseFloat(response.data.out_longitude));
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    if (eventId) {
      fetchEventDetailsById(eventId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="Calendar_details_container">
        <div className="calendar_card-content">
          <div className="calendar_label">Name:</div>
          <div className="calendar_data">
            {eventDetailsById?.user?.name || "-"}
          </div>
        </div>

        <div className="calendar_card-content">
          <div className="calendar_label">Mobile:</div>
          <div className="calendar_data">
            {eventDetailsById?.additional_details?.mobile || "-"}
          </div>
        </div>

        <div className="calendar_card-content">
          <div className="calendar_label">Visit Type:</div>
          <div className="calendar_data">
            {eventDetailsById?.time_sheet_event_type?.name || "-"}
          </div>
        </div>
      </div>

      <div className="Calendar_details_container">
        <div className="calendar_card-content">
          <div className="calendar_label">Start Time:</div>
          <div className="calendar_data">
            {eventDetailsById?.in_timestamp
              ? (() => {
                  try {
                    const fullTimestamp = eventDetailsById.in_timestamp;
                    const [dateTimePart] = fullTimestamp.split(".");
                    return dateTimePart;
                  } catch (error) {
                    console.error("Error parsing in_timestamp:", error);
                    return eventDetailsById.in_timestamp;
                  }
                })()
              : "-"}
          </div>
        </div>
        <div className="calendar_card-content">
          <div className="calendar_label">End Time:</div>
          <div className="calendar_data">
            {eventDetailsById?.out_timestamp
              ? (() => {
                  try {
                    // Split the timestamp to remove microseconds
                    const fullTimestamp = eventDetailsById.out_timestamp;
                    const [dateTimePart] = fullTimestamp.split(".");
                    return dateTimePart;
                  } catch (error) {
                    console.error("Error parsing out_timestamp:", error);
                    return eventDetailsById.out_timestamp;
                  }
                })()
              : "-"}
          </div>
        </div>

        <div className="calendar_card-content">
          <div className="calendar_label">Remark:</div>
          <div className="calendar_data">- </div>
        </div>
      </div>

      <div className="Calendar_details_container">
        <div className="calendar_card-content">
          <div className="calendar_label">Created At:</div>
          <div className="calendar_data">{eventDetailsById?.created_at}</div>
        </div>

        <div className="calendar_card-content">
          <div className="calendar_label">Stability:</div>
          <div className="calendar_data">-</div>
        </div>

        <div className="calendar_card-content">
          <div className="calendar_label">Distance:</div>
          <div className="calendar_data">-</div>
        </div>
      </div>

      <div className="Calendar_details_container">
        <div className="calendar_card-content">
          <div className="calendar_label">Duration:</div>
          <div className="calendar_data">-</div>
        </div>

        <div className="calendar_card-content">
          <div className="calendar_label">Collection Number:</div>
          <div className="calendar_data">-</div>
        </div>

        <div className="calendar_card-content">
          <div className="calendar_label">Collection Amount:</div>
          <div className="calendar_data">-</div>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        {eventDetailsById?.media_details?.map((media, index) => (
          <img
            key={media.id || index} 
            src={media.time_sheet_image} 
            style={{ width: "20%", margin: "10px" }} 
            alt={media.image_type || `Image ${index + 1}`} 
          />
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        <img
          src="http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
          style={{ width: "5%" }}
          alt=""
        />
        <h4>Branch</h4>

        <img
          src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
          style={{ width: "5%" }}
          alt=""
        />
        <h4>Previous Visit</h4>

        <img
          src="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          style={{ width: "5%" }}
          alt=""
        />
        <h4>Curent Visit</h4>

        <img
          src="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          style={{ width: "5%" }}
          alt=""
        />
        <h4>Other Visit</h4>
      </div>

      <div style={{ marginTop: "20px" }}>
        <GoogleMapLocation
          latitude={inlatitude}
          longitude={inlongitude}
          branchLatitude={outlatitude}
          branchLongitude={outlongitude}
        />
      </div>
    </div>
  );
};

export default CalendarEventView;
