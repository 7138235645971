import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useLocation } from 'react-router-dom';
import "../../../user/styles/UserProfile.css"
import ZonesLocationForm from "../../../user/views/ZonesLocationForm";
import RolesPermissionsForm from "../../../user/views/RolesPermissionsForm";
import AgentForm from "./AgentForm";
const { TabPane } = Tabs;

const AgentFormController = ({ closeForm, open, id, refreshData, setId }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [activeKey, setActiveKey] = useState("1");
    const [formCompletion, setFormCompletion] = useState({
        1: false,
        2: false,
        3: false,
    });

    const handleCancel = () => {
        if (activeKey !== "1") {
            const previousTab = String(Number(activeKey) - 1);
            setActiveKey(previousTab);
        }
    };

    const onNextTab = () => {
        const nextTab = String(Number(activeKey) + 1);
        setActiveKey(nextTab);
    };

    const onTabChange = (key) => {
        setActiveKey(key);
    };

    const formCompleted = (formKey, isCompleted) => {
        setFormCompletion((prevFormCompletion) => ({
            ...prevFormCompletion,
            [formKey]: isCompleted,
        }));
    };

    useEffect(() => {
        if (open === true) {
            setActiveKey("1");
            setFormCompletion({
                1: true,
                2: false,
                3: false,
            });
        }
    }, [open]);

    useEffect(() => {
        if (open === true && id) {
            setFormCompletion({
                1: true,
                2: true,
                3: true,
            });
        }
    }, [open, id]);

    const items = [
        {
            key: "1",
            label: "Personal details",
            content: (
                <AgentForm
                    onCancel={handleCancel}
                    onNext={onNextTab}
                    closeDrawer={closeForm}
                    currentRoute={currentPath}
                    open={open}
                    id={id}
                    setId={setId}
                    formCompleted={(isCompleted) => formCompleted("1", isCompleted)}
                />
            ),
        },
        {
            key: "2",
            label: "Roles & Permissions",
            content: (
                <RolesPermissionsForm
                    currentRoute={currentPath}
                    closeDrawer={closeForm}
                    onCancel={handleCancel}
                    open={open}
                    id={id}
                    formCompleted={(isCompleted) => formCompleted("2", isCompleted)}
                />
            ),
        },
        {
            key: "3",
            label: "Zones & Locations",
            content: (
                <ZonesLocationForm
                    currentRoute={currentPath}
                    closeDrawer={closeForm}
                    onCancel={handleCancel}
                    open={open}
                    id={id}
                    refreshData={refreshData}
                    formCompleted={(isCompleted) => formCompleted("3", isCompleted)}
                />
            ),
        },
    ];

    return (
        <div className={currentPath === "/user_profile" ? "tabs-container" : ""}>
            <Tabs activeKey={activeKey} onChange={onTabChange}>
                {items.map((item, index) => (
                    <TabPane
                        tab={item.label}
                        key={item.key}
                        disabled={
                            currentPath === "/master" &&
                            !formCompletion[item.key] &&
                            item.key !== activeKey
                        }
                    >
                        {React.cloneElement(item.content, {
                            onCancel: handleCancel,
                            onNext: onNextTab,
                            formCompleted: (isCompleted) =>
                                formCompleted(item.key, isCompleted),
                            // mode: id ? "edit" : "add",
                        })}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default AgentFormController;
