import React, { useState, useEffect } from 'react';
import '../styles/bureaDetailsForm.css';
import arrow from "../assets/arrow-narrow-right.png";
import { Button, message } from 'antd';
import ErrorMessage from '../../../utils/errorHandling/ErrorMessage';
import { getCibilDetails, getHighmarkDetails } from '../services/salesformservices';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { addCibilData, addHighmarkData } from '../../../redux/salesFormSlice';
import { useSelector, useDispatch } from 'react-redux';

const BureauDetailsForm = ({ loan_detail, customer_id, activeCustomer }) => {
  const [cibildata, setCibildata] = useState([]);
  const [getHighmarkDetail,setHighMarkDetail] = useState([]);
  const dispatch = useDispatch();
  const cibil = useSelector((state) => state?.salesForm.cibil[activeCustomer]);
  const highmark = useSelector((state) => state?.salesForm.highmark[activeCustomer]); 

  useEffect(() => {
    if (cibil) {
      setCibildata(cibil);  
    }
  }, [cibil]);

  useEffect(() => {
    if (highmark) {
      setHighMarkDetail(highmark);  
    }
  }, [highmark]);

  const getCibilData = async () => {
    try { 
      const payload = {
        bureau_name: "01", 
        customer_detail: customer_id,  
        // loan_detail: loan_detail  
      };
  
      const response = await getCibilDetails(payload);  
  
      if (response && response.data) {
        setCibildata(response.data);  
        
        if (response.status === 200) {
          dispatch(addCibilData({
            index: activeCustomer,  
            value: response.data    
          })); 
          message.success("CIBIL data fetched successfully");
        }
      } 
    } catch (error) { 
      message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }
  };
  

  const postHighmarkData = async () => {
    try {

      const payload = {
        bureau_name: "02", 
        customer_detail: customer_id,  
        // loan_detail: loan_detail  
      };

      const response = await getHighmarkDetails(payload);
      if (response && response.data) {
       setHighMarkDetail(response.data);
        if (response.status === 200) {
          dispatch(addHighmarkData({
            index: activeCustomer,   
            value: response.data   
          })); 
          message.success("Highmark data fetched successfully");
        }
      }
    } catch (error) {
      message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }
  };

  // useEffect(() => {
  //   if (customer) {
  //     getCibilData();
  //     getHighmarkData();
  //   }
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [customer]);

  return (
    <div>
      <div className='burea_main_container'>
        <div className='cibil_main_container'>
         {isEmpty(cibildata) ? (  
            <div className='cibil_heading'>
              <Button className='report_button' size='large' onClick={getCibilData}>
                GET CIBIL SCORE
                <img src={arrow} alt='' style={{ marginLeft: "5px" }} />
              </Button>
            </div>
         ) : ( 
            <div className='cibil_heading'>
              <span>CIBIL Score</span>
              <div className='bureau_main_block'>
                <div className='firstBlock'>
                  <p className='cibil'>Applicant’s Cibil Score</p>
                  <p className='light_name'>
                    Score Name: <span className='dark_name'>{cibildata.score_name}</span>
                  </p>
                  <p className='light_name'>
                    Scorecard Version: <span className='dark_name'>{cibildata.scorecard_name}</span>
                  </p>
                  <div className='burea_number'>
                    <h1 className='score'>{cibildata.score !== undefined ? cibildata.score : 0}</h1>
                  </div>
                  <p className='light_name'>
                    Customer's Credit Standing: <span className='dark_name'>Good</span>
                  </p>
                  <Link to={`/cibil_report/${loan_detail}`}>
                  <Button className='report_button'>
                    VIEW FULL REPORT
                    <img src={arrow} alt='' style={{ marginLeft: "5px" }} />
                  </Button>
                  </Link>
                </div>
              </div>
            </div>
          )} 
        </div>

        <div className='highmark_main_container'>
      {isEmpty(getHighmarkDetail) ? (
        <div className='cibil_heading'>
          <Button className='report_button' size='large' onClick={postHighmarkData}>
            GET HIGHMARK SCORE
            <img src={arrow} alt='Arrow Icon' style={{ marginLeft: '5px' }} />
          </Button>
        </div>
      ) : (
        <>
          <div className='cibil_heading'>HIGHMARK Score</div>
          <div className='bureau_main_block'>
            <div className='firstBlock'>
              <p className='cibil'>Applicant’s Highmark Score</p>
              <p className='light_name'>
                Score Name: <span className='dark_name'>{getHighmarkDetail.score_name}</span>
              </p>
              <p className='light_name'>
                Scorecard Version: <span className='dark_name'>{getHighmarkDetail.scorecard_name}</span>
              </p>
              <div className='burea_number'>
                <h1 className='score'>
                  {getHighmarkDetail.score !== undefined ? getHighmarkDetail.score : 746}
                </h1>
              </div>
              <p className='light_name'>
                Customer's Credit Standing: <span className='dark_name'>Good</span>
              </p>
              <Link to={`/highmark_report/${loan_detail}`}>
              <Button className='report_button'>
                VIEW FULL REPORT
                <img src={arrow} alt='Arrow Icon' style={{ marginLeft: '5px' }} />
              </Button>
              </Link>
            </div>
          </div>
          <br />
        </>
      )}
    </div>
      </div>
    </div>
  );
};

export default BureauDetailsForm;
