import React, { useEffect, useState } from "react";
import AutoDeviation from "./AutoDeviation";
import ManualDeviation from "./ManualDeviation";
import "../styles/deviation.css";
import {
  getAllDeviationById,
  getDeviationList,
} from "../services/getDeviation";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";

import { Button, Drawer, message } from "antd";
import Header from "../../../modules/layout/views/Header";
import ManualDeviationForm from "./ManualDeviationForm";
import { useParams } from "react-router-dom";
import { decrypt } from "../../cryptoUtils/cryptoUtils";
import ErrorMessage from "../../errorHandling/ErrorMessage";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import ConditionalRender from "../../authorization/AuthorizeComponent";

const Deviation = ({ toggleRefreshTableData, refreshTableData }) => {
  const [manualDeviationData, setManualDeviationData] = useState([]);
  const [autoDeviationData, setAutoDeviationData] = useState([]);
  const [deviationList, setDeviationList] = useState([]);
  const [selectedManual, setSelectedManual] = useState(false);
  const [title, setTitle] = useState("");
  const [manualDataValue, setManualDataValue] = useState();
  const [collapsedDeviation, setCollapsedDeviation] = useState(true);

  const { user_data } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);

  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllDeviationById(loan_id);
        setManualDeviationData(data?.data.manual);
        setAutoDeviationData(data?.data.auto);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const fetchDeviationList = async () => {
      try {
        const deviationList = await getDeviationList();
        setDeviationList(deviationList.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchDeviationList();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  const showDrawer = (title) => {
    setOpen(true);
    setTitle(title);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedManual(false);
  };
  return (
    <>
      <Drawer
        title={
          <Header
            title={selectedManual ? "Edit" : "Add"}
            onClose={onClose}
            name={title}
          />
        }
        width={800}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <ManualDeviationForm
          selectedManual={selectedManual}
          deviationList={deviationList}
          loan_id={loan_id}
          initialValues={manualDataValue}
          toggleRefreshTableData={toggleRefreshTableData}
          setManualDataValue={setManualDataValue}
          onClose={onClose}
        />
      </Drawer>
      <div>
        <div className={`deviation_main_container ${collapsedDeviation ? "with-border" : "no-border"
          }`}>
          <div className="deviation_heading_text">Deviation Details</div>
          <div>
            <div style={{ marginRight: "10px" }}>
              <ConditionalRender
                userPermissions={user_data?.permissions}
                requiredPermissions={["POSTDEVT"]}
              >
                <Button
                  icon={<PlusCircleOutlined />}
                  className="add-sanction-condition-button"
                  onClick={() => showDrawer("Manual Deviation")}
                >
                  Add Manual Deviation
                </Button>
              </ConditionalRender>
              {collapsedDeviation ? (
                <UpOutlined onClick={() => setCollapsedDeviation(!collapsedDeviation)} />
              ) : (
                <DownOutlined onClick={() => setCollapsedDeviation(!collapsedDeviation)} />
              )}
            </div>
          </div>
        </div>
        {(collapsedDeviation && (
          <>
            <AutoDeviation autoDeviationData={autoDeviationData} />
            <ManualDeviation
              loan_id={loan_id}
              manualDeviationData={manualDeviationData}
              showDrawer={showDrawer}
              toggleRefreshTableData={toggleRefreshTableData}
              setManualDataValue={setManualDataValue}
              setSelectedManual={setSelectedManual}
            />
          </>
        ))}

      </div>
    </>

  );
};

export default Deviation;
