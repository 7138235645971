/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../styles/insuranceForm.css";
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  DatePicker,
  Upload,
  message,
  Button,
  Modal,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import moment from "moment";
import dayjs from "dayjs";
import pdf_image from "../assets/pdf.png";
import {
  postICICIPrudentail,
  getAllStateService,
  getAllCityService,
  getallpincode,
  getICICIQuestionTypeService,
  getPersonalDetailsByIdService,
  getAllOccuptationTypeService,
  getBranchAddressByIdService,
  getAllDocumentTypeService,
  getNationalityByIdService,
  getRelationByIdService,
  getInsuranceDetailsByIdService,
  updateICICIPrudential,
  deleteHDFCDocumentDetailsByIdService,
  getAdditionalLoanDetailIdService,
  getInsuranceAddressTypeList,
  getAddressDetailByAddresType,
  insurancePincodeCodeService,
} from "../services/loanInsuranceService";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import ICICIQuestionForm from "./ICICIQuestionForm";
import { ErrorMessage } from "formik";
const { Option } = Select;

// Define the validation schema
const validationSchema = Yup.object().shape({
  loan_detail: Yup.number().required("Loan detail is required"),
  insured_customer_name: Yup.string().required(
    "Insured Customer Name is required"
  ),
  customer_detail: Yup.number().required("Customer detail is required"),
  insurance_company_detail: Yup.number().required(
    "Insurance company detail is required"
  ),
  branch_address: Yup.string().required("Branch address is required"),
  property_type: Yup.string().required("Property type is required"),
  ocupation: Yup.string().required("Occupation is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string().required("Pincode is required"),
  salutation: Yup.string()
    .oneOf(["Mr", "Ms", "Mrs", "Dr"], "Invalid salutation")
    .required("Salutation is required"),
  first_name: Yup.string().required("First name is required"),
  middle_name: Yup.string(),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string()
    .matches(/^\d{10}$/, "Mobile must be exactly 10 digits")
    .required("Mobile number is required"),
  gender: Yup.string()
    .oneOf(["Male", "Female", "Other"], "Invalid gender")
    .required("Gender is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  customer_address_type: Yup.string().required("Address Type is required"),
  address_line_1: Yup.string()
    .required("Address line 1 is required")
    .max(225, "Address Line 1 cannot exceed 225 characters"),
  address_line_2: Yup.string()
    .required("Address line 2 is required")
    .max(225, "Address Line 2 cannot exceed 225 characters"),
  nationality: Yup.string()
    .oneOf(["Indian"], "Invalid nationality")
    .required("Nationality is required"),
  date_of_birth: Yup.date().required("Date of birth is required"),
  pan_no: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN number")
    .required("PAN number is required"),
  mobile_country_code: Yup.string()
    .matches(/^\d{1,3}$/, "Invalid mobile country code")
    .required("Mobile country code is required"),
  occupation: Yup.string().required("Occupation is required"),
  height_in_cms: Yup.string().required("Height is required"),
  weight_in_kgs: Yup.string().required("Weight is required"),
  relation: Yup.number().required("Relation is required"),
  applicant_type: Yup.string().required("Applicant Type is required"),
  share_of_loan: Yup.string().required("Share Of Loan is required"),
  loan_amount: Yup.string().required("Loan Amount is required"),
  tenure: Yup.string().required("Tenure is required"),
  total_premium_amount: Yup.string().required(
    "Total Premium Amount is required"
  ),
  nominee_name: Yup.string().required("Nominee Name is required"),
  nominee_nationality: Yup.string().required("Nationality is required"),
  nominee_email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  nominee_contact_number: Yup.string()
    .matches(/^\d{10}$/, "Nominee Contact Number must be exactly 10 digits")
    .required("Mobile number is required"),
  nominee_mobile_country_code: Yup.string()
    .matches(/^\d{1,3}$/, "Invalid mobile country code")
    .required("Mobile country code is required"),
  nominee_date_of_birth: Yup.date()
    .max(new Date(), "Date of Birth cannot be a today's date or future date")
    .required("Date Of Birth is required"),
  nominee_gender: Yup.string()
    .oneOf(["Male", "Female", "Other"], "Invalid gender")
    .required("Gender is required"),
  nominee_address_line1: Yup.string()
    .required("Address line 1 is required")
    .max(225, "Address Line 1 cannot exceed 225 characters"),
  nominee_address_line2: Yup.string()
    .required("Address line 2 is required")
    .max(225, "Address Line 2 cannot exceed 225 characters"),
  relation_with_assured_person: Yup.string().required(
    "Relation with assured person is required"
  ),
  is_covered_other_customer: Yup.boolean().required(
    "Is covered other customer is required"
  ),
  address_type: Yup.string().required("Address Type is required"),
  nominee_city: Yup.string().required("City is required"),
  nominee_state: Yup.string().required("State is required"),
  nominee_pincode: Yup.string().required("Pincode is required"),
  nominee_relation: Yup.string().required("Relation is required"),
  nominee_relationship_with_life_to_be_assured: Yup.string().required(
    "Relationship with life to be assured is required"
  ),
  monthly_income: Yup.string().required("Monthly income is required"),
  policy_term: Yup.string().required("Policy term is required"),
  sum_assured: Yup.string().required("Sum assured is required"),
  total_premium_incl_gst: Yup.string()
    .matches(
      /^[1-9][0-9]{0,14}$/,
      "Total premium including GST must be a positive number"
    )
    .required("Total premium including GST is required"),
  emi_amount: Yup.string().required("EMI amount is required"),
  age_proof: Yup.string().required("Age proof is required"),
  appointee_name: Yup.string().required("Appointee Name is required"),
  appointee_date_of_birth: Yup.date()
    .max(new Date(), "Date of Birth cannot be today's date or a future date")
    .required("Appointee Date Of Birth is required"),
  appointee_gender: Yup.string().required("Appointee Gender is required"),
  appointee_contact_number: Yup.string()
    .required("Appointee Contact Number is required")
    .matches(/^\d{10}$/, "Appointee Contact Number must be exactly 10 digits"),
  apointee_relationship_with_life_to_be_assured: Yup.string().required(
    "Appointee Relationship is required"
  ),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ICICIInsuranceForm = ({
  id,
  open,
  customerId,
  closeForm,
  loan_id,
  insurance_id,
  customer_id,
  nominee_id,
  insurancecompanydetail,
  toggleRefreshTableData,
  closeDrawers,
  setOpenDrawer,
  onCancel,
  setSelectedCustomer,
  setSelectedInsuranceType,
  toggleRefreshInsurance,
}) => {
  const [form] = Form.useForm();
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [occupationType, setOccupationType] = useState([]);
  const [matchedCustomer, setMatchedCustomer] = useState(null);
  const [matchedNominee, setMatchedNominee] = useState(null);
  const [personalData, setPersonalData] = useState([]);
  const [nominee, setSelectedNominee] = useState();
  const [branches, setBranches] = useState([]);
  const [isUnder18, setIsUnder18] = useState(false);
  const [, setDocuments] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [loanData, setLoanData] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [relation, setRelation] = useState([]);
  const [deleteUploadPicture, setDeleteUploadPicture] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [deletePicture, setDeletePicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState([]);
  const [question] = useState([]);
  const [, setContactNumber] = useState("");
  const [insurancedetail, setInsuranceDetail] = useState();
  const [getAppointee, setAppointee] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRemark, setShowRemark] = useState(0);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [addressType, setAddressType] = useState(null);
  const [nomineeAddressType, setNomineeAddressType] = useState("");
  const [questionRemark, setQuestionRemark] = useState();
  const [insuranceCustomerId, setInsuanceCustomerId] = useState();
  const [ids, setIds] = useState({
    insuredCustomerId: null,
    nomineeId: null,
    nomineeCustomerId:null,
    appointeeId: null,
    documentIds: [],
    coverageId: null,
    customer_detail: null,
    questionId: null,
    loanId:null
  });

  const newCustomerId = insurance_id ? ids?.insuredCustomerId : customerId;
  const newNomineeId = insurance_id ? ids?.nomineeId : nominee;
  useEffect(() => {
    if (customerId) {
      form.resetFields();
      setSelectedValue({});
    }
  }, [form, customerId]);

  const handleInputChange = (e) => {
    setContactNumber(e.target.value);
  };

  const getUserData = async () => {
    if (insurance_id) {
      try {
        const response = await getInsuranceDetailsByIdService(insurance_id);
        const insurance_detail = Array.isArray(
          response?.data?.insured_customer_detail
        )
          ? response?.data?.insured_customer_detail[0]
          : null;
        const nominee_detail = Array.isArray(response?.data?.nominee_detail)
          ? response?.data?.nominee_detail[0]
          : null;
        const appointee_detail = nominee_detail?.appointee;
        const insurance_coverage =
          response?.data?.insurance_coverage_data || {};
        setQuestions(response?.data?.icici_question_data);
        setExistingImages(response?.data?.document);
        setInsuranceDetail(response?.data?.insurance_id);
        setAppointee(response?.data?.nominee_detail?.[0]?.appointee);
        setInsuanceCustomerId(insurance_detail?.customer_detail?.id);
        const insuredCustomerId = insurance_detail?.id;
        const nomineeId = nominee_detail?.id;
        const nominee_customer_id = nominee_detail?.customer_detail?.id ? nominee_detail?.customer_detail?.id : null;
        const appointeeId = appointee_detail?.id;
        const coverageId = insurance_coverage?.id;
        const customer_detail = insurance_detail?.customer_detail?.id;
        const new_loan_id = response?.data?.loan_detail
        // Set IDs in state
        setIds({
          insuredCustomerId,
          nomineeId,
          appointeeId,
          coverageId,
          customer_detail,
          loanId:new_loan_id,
          nomineeCustomerId:nominee_customer_id
        });

        // Convert dates to moment objects
        let dateOfBirthValue = insurance_detail?.date_of_birth
          ? dayjs(insurance_detail.date_of_birth, "DD-MM-YYYY")
          : null;

        let nomineedateOfBirthValue = nominee_detail?.date_of_birth
          ? dayjs(nominee_detail.date_of_birth, "DD-MM-YYYY")
          : null;

        let appointeedateOfBirthValue = appointee_detail?.date_of_birth
          ? dayjs(appointee_detail.date_of_birth, "DD-MM-YYYY")
          : null;

        // Check if appointee exists and is under 18
        if (appointee_detail && Object.keys(appointee_detail).length > 0) {
          setIsUnder18(true);
        }
        form.setFieldsValue({
          insured_customer_name: `${insurance_detail?.first_name || ""} ${
            insurance_detail?.middle_name || ""
          } ${insurance_detail?.last_name || ""}`.trim(),
          gender: insurance_detail?.gender || "",
          dateOfBirth: dateOfBirthValue,
          occupation: insurance_detail?.ocupation?.id || null,
          nationality: insurance_detail?.nationality || "",
          mobile: insurance_detail?.mobile || "",
          email: insurance_detail?.email || "",
          customer_address_type:
            insurance_detail?.customer_address_type?.id || null,
          address_line_1: insurance_detail?.address_line_1 || "",
          address_line_2: insurance_detail?.address_line_2 || "",
          city: insurance_detail?.city?.id || null,
          state: insurance_detail?.state?.id || null,
          pincode: insurance_detail?.pincode?.id || null,
          height_in_cms: insurance_detail?.height || null,
          weight_in_kgs: insurance_detail?.weight || null,
          age_proof: insurance_coverage?.age_proof?.id || null,
          applicant_type: insurance_detail?.customer_type?.name || "",
          branch_address: insurance_coverage?.branch_address?.id || null,
          nominee_name: nominee_detail?.name || "",
          nominee_gender: nominee_detail?.gender || "",
          nominee_contact_number: nominee_detail?.mobile || "",
          relation_with_assured_person: nominee_detail?.relation?.id || null,
          address_type: nominee_detail?.address_type?.id || "",
          nominee_address_line1: nominee_detail?.address_line_1 || "",
          nominee_address_line2: nominee_detail?.address_line_2 || "",
          nominee_city: nominee_detail?.city?.id || null,
          nominee_state: nominee_detail?.state?.id || null,
          nominee_pincode: nominee_detail?.pincode?.id || null,
          nominee_date_of_birth: nomineedateOfBirthValue,
          nominee_nationality: nominee_detail?.nationality || "",
          nominee_relationship_with_life_to_be_assured:
            nominee_detail?.relation?.id || null,
          policy_term: insurance_coverage?.policy_term || null,
          sum_assured: insurance_coverage?.sum_assured || null,
          total_premium_amount:
            insurance_coverage?.total_premium_incl_gst || null,
          tenure: insurance_coverage?.tenure || null,
          loan_amount: insurance_coverage?.loan_amount || null,
          monthly_income: insurance_coverage?.monthly_income || null,
          emi_amount: insurance_coverage?.emi_amount || null,
          property_type: insurance_detail?.property_type?.id || null,
          appointee_name: appointee_detail?.appointee_name || "",
          appointee_date_of_birth: appointeedateOfBirthValue,
          appointee_gender: appointee_detail?.gender || "",
          appointee_contact_number: appointee_detail?.mobile || "",
          apointee_relationship_with_life_to_be_assured:
            appointee_detail?.relation?.id || null,
        });
        setContactNumber(nominee?.mobile);
      } catch (error) {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching insurance details"
        );
      }
    }
  };

  useEffect(() => {
    if (open) {
      getUserData();
    } else {
      form.resetFields();
      setFileList([]);
      setImage([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, insurance_id, toggleRefreshInsurance]);

  const confirmDelete = () => {
    setImage(image.filter((image) => image !== deleteUploadPicture));
    const updatedFileList = fileList.filter(
      (item) => item.uid !== deleteUploadPicture.uid
    );
    setFileList(updatedFileList);
    setOpenUploadModal(false); // Close the modal
    setDeleteUploadPicture(null); // Reset the selected picture
  };

  const deletePropertyPictures = async () => {
    try {
      const response = await deleteHDFCDocumentDetailsByIdService(
        deletePicture.id
      );

      if (response?.status === 200 && response?.success === true) {
        message?.success("Document deleted successfully");
        setOpenModal(false);
        toggleRefreshInsurance();
      }
    } catch (error) {
      // setOpenModal(false);
      message?.error(error?.response?.data?.message);
    }
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      // Parse the given date string in DD-MM-YYYY format
      const parsedDate = moment(dateString, "DD-MM-YYYY");

      // Calculate age
      const age = moment().diff(parsedDate, "years");

      setIsUnder18(age < 18);
    } else {
      setIsUnder18(false);
    }
  };

  const handleNomineeChange = (value) => {
    setSelectedNominee(value);
    if (value === "other") {
      form.setFieldsValue({
        nominee_name: null,
        nominee_date_of_birth: null,
        nominee_gender: null,
        nominee_contact_number: null,
        nominee_nationality: null,
        nominee_relationship_with_life_to_be_assured: undefined,
        nominee_address_line1: "",
        nominee_address_line2: "",
        nominee_city: undefined,
        nominee_state: undefined,
        nominee_pincode: undefined,
        address_type:undefined,
        apointee_name: "",
        apointee_date_of_birth: "",
        apointee_relationship_with_life_to_be_assured: undefined,
        apointee_contact_number: "",
      });
    }
    setNomineeAddressType(null);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadChange = (info) => {
    let updatedFileList = [...info.fileList];

    // Assuming 'updatedFileList' is your array and 'form' is your form reference
    updatedFileList = updatedFileList.map((file, index) => {
      if (index === updatedFileList.length - 1) {
        return {
          ...file,
          documents: file.originFileObj,
        };
      }
      return file;
    });
    setFileList(updatedFileList);
    form.setFieldsValue({
      documents: undefined,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPersonalDetailsByIdService(id);
        setPersonalData(response.data.customers);
      } catch (error) {
        message.error(
          `${error?.response?.status}: ${error?.response?.data?.message}`
        );
      }
    };

    const fetchLoanData = async () => {
      try {
        const response = await getAdditionalLoanDetailIdService(id);
        setLoanData(response.data);
      } catch (error) {
        message.error(
          `${error?.response?.status}: ${error?.response?.data?.message}`
        );
      }
    };

    if (id) {
      fetchData();
      fetchLoanData();
    }
  }, [id]);

  useEffect(() => {
    if (customerId) {
      const foundCustomer = personalData.find(
        (person) => person.id === customerId
      );
      if (foundCustomer) {
        let dateOfBirthValue = null;
        if (
          foundCustomer.date_of_birth &&
          moment(foundCustomer.date_of_birth, "DD-MM-YYYY", true).isValid()
        ) {
          dateOfBirthValue = moment(foundCustomer.date_of_birth, "DD-MM-YYYY"); // assuming the date_of_birth is in 'YYYY-MM-DD' format
        } else {
          console.error("Invalid date format or value");
        }

        form.setFieldsValue({
          dateOfBirth: dateOfBirthValue,
          gender: foundCustomer.gender || "",
          nationality: foundCustomer.nationality || "",
          // Add other fields as needed
        });
        setAddressType(null);
        setMatchedCustomer(foundCustomer);
      }
    }
  }, [customerId, personalData, form]);

  useEffect(() => {
    if (nominee) {
      const foundCustomer = personalData.find(
        (person) => person.id === nominee
      );
      if (foundCustomer) {
        setMatchedNominee(foundCustomer);
        const [day, month, year] = foundCustomer?.date_of_birth.split("-");
        const formattedDate = dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"); // Create a moment object
        const parsedDate = moment(formattedDate, "DD-MM-YYYY");

        // Calculate age
        const age = moment().diff(parsedDate, "years");
        if (age < 18) {
          setIsUnder18(true);
        } else {
          setIsUnder18(false);
        }
        form.setFieldsValue({
          nominee_name: `${foundCustomer?.first_name || ""} ${
            foundCustomer?.middle_name || ""
          } ${foundCustomer?.last_name || ""}`.trim(),
          nominee_gender: foundCustomer.gender || "",
          nominee_contact_number: foundCustomer?.mobile_number || "",
          nominee_date_of_birth: formattedDate,
          nominee_relationship_with_life_to_be_assured: undefined,
          nominee_address_line1: "",
          nominee_address_line2: "",
          nominee_city: undefined,
          nominee_state: undefined,
          nominee_pincode: undefined,
          apointee_name: "",
          apointee_date_of_birth: "",
          apointee_relationship_with_life_to_be_assured: undefined,
          apointee_contact_number: "",
        });
      }
      setNomineeAddressType(null);
    }
  }, [nominee, personalData, form]);

  useEffect(() => {
    const updateAddressDetail = async () => {
      try {
        const response = await getAddressDetailByAddresType(
          newCustomerId,
          addressType
        );
        if (response?.data) {
          const { address_line_1, address_line_2, city, state, pincode } =
            response.data;
          form.setFieldsValue({
            address_line_1: address_line_1 || "",
            address_line_2: address_line_2 || "",
            city: city?.id || undefined,
            state: state?.id || undefined,
            pincode: pincode?.id || undefined,
          });
        }
      } catch (error) {
        console.error("Failed to fetch address details:", error);
      }
    };

    if (newCustomerId && addressType) {
      updateAddressDetail();
    } else {
      form.resetFields([
        "address_line_1",
        "address_line_2",
        "city",
        "state",
        "pincode",
      ]);
    }
  }, [customerId, insurance_id, addressType, form]);

  useEffect(() => {
    const updateAddressDetail = async () => {
      try {
        const response = await getAddressDetailByAddresType(
          newNomineeId,
          nomineeAddressType
        );
        if (response?.data) {
          const { address_line_1, address_line_2, city, state, pincode } =
            response.data;
          form.setFieldsValue({
            nominee_city: city?.id || undefined,
            nominee_state: state?.id || undefined,
            nominee_pincode: pincode?.id || undefined,
            nominee_address_line1: address_line_1 || "",
            nominee_address_line2: address_line_2 || "",
          });
        }
      } catch (error) {
        console.error("Failed to fetch address details:", error);
      }
    };
    if (newNomineeId && nomineeAddressType) {
      updateAddressDetail();
    } else {
      form.resetFields([
        "nominee_address_line1",
        "nominee_address_line2",
        "nominee_city",
        "nominee_state",
        "nominee_pincode",
      ]);
    }
  }, [nominee, nomineeAddressType, form]);

  const handleNomineePincodeChange = async (value) => {
    const selectedPincode = pincode.find((pin) => pin.id === value);

    const pincodeName = selectedPincode ? selectedPincode.name : "";
    try {
      const response = await insurancePincodeCodeService(pincodeName);
      if (response && response.data) {
        const { city, state } = response.data;
        form.setFieldsValue({
          // nominee_pincode: pincode?.id || undefined,
          nominee_city: city?.id || undefined,
          nominee_state: state?.id || undefined,
        });
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllCitylist = async () => {
    try {
      const response = await getAllCityService();
      setCity(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("City details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAddressTypeList = async () => {
    try {
      const response = await getInsuranceAddressTypeList();
      setAddressTypeList(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("City details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllState = async () => {
    try {
      const response = await getAllStateService();
      setStates(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("State details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllPincodelist = async () => {
    try {
      const response = await getallpincode();
      setPincode(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getICICIQuestionlist = async () => {
    try {
      const response = await getICICIQuestionTypeService();
      setQuestions(response?.data);
      if (response && response.data) {
        if (response.status === 200 && response?.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllOccupationlist = async () => {
    try {
      const response = await getAllOccuptationTypeService();
      setOccupationType(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllBrancheslist = async () => {
    try {
      const response = await getBranchAddressByIdService();
      setBranches(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllDocumentslist = async () => {
    try {
      const response = await getAllDocumentTypeService();
      setDocuments(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllNationalitylist = async () => {
    try {
      const response = await getNationalityByIdService();
      setNationalities(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.data?.message);
    }
  };

  const getAllRelationlist = async () => {
    try {
      const response = await getRelationByIdService();
      setRelation(response.data);
      if (response && response.data) {
        if (response.status === 200 && response.data.success) {
          message.success("Pincode details updated successfully");
        }
      }
    } catch (error) {
      message.error(error?.response?.status, error?.response?.message);
    }
  };

  useEffect(() => {
    getAllCitylist();
    getAllState();
    getAllPincodelist();
    if (!insurance_id) {
      getICICIQuestionlist();
    }
    getAllOccupationlist();
    getAllBrancheslist();
    getAllDocumentslist();
    getAllNationalitylist();
    getAllRelationlist();
    getAddressTypeList();
  }, []);

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const newImage = {
      file_path: isJpgOrPng ? URL.createObjectURL(file) : pdf_image,
      uid: file.uid,
    };

    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    return false; // Prevent automatic upload
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const newData = { ...values };
    const nomineeMobile = newData.nominee_contact_number;
    const propertyDocumentDetail = await Promise.all(
      fileList.map(async (file) => {
        const base64 = await fileToBase64(file.originFileObj);
        return base64; // Only return the base64 URL
      })
    );
    const mergedQuestions = [
      ...questions["Cover Continuace"],
      ...questions["Covid-19"], // Corrected the key here
      ...questions["Personal Detail"].flatMap((item) =>
        item.sub_questions ? [item, ...item.sub_questions] : [item]
      ),
    ];

    const formattedQuestions1 = mergedQuestions.map((q) => ({
      question: q.id,
      answer: selectedValue[q.id] === 1 ? "yes" : "no", // Check value in selectedValue
      remark:
        q.remark && selectedValue[q.id] === 1 ? questionRemark : undefined, // Add remark for q.id === 46
    }));

    try {
      if (insurance_id) {
        try {
          const formattedValues = {
            loan_detail: ids.loanId,
            insured_customer_detail: {
              id: ids.insuredCustomerId,
              customer_detail: customer_id ? customer_id : customerId,
              property_type: values.property_type,
              ocupation: values.occupation,
              city: values.city,
              state: values.state,
              pincode: values.pincode,
              address_line_1: values.address_line_1,
              address_line_2: values.address_line_2,
              height: values.height_in_cms,
              weight: values.weight_in_kgs,
              customer_address_type: values.customer_address_type,
            },
            nominne: {
              name: values.nominee_name,
              mobile: values.nominee_contact_number,
              mobile_country_code: "91",
              date_of_birth: values.nominee_date_of_birth?.format("YYYY-MM-DD"),
              gender: values.nominee_gender,
              address_line_1: values.nominee_address_line1,
              address_line_2: values.nominee_address_line2,
              relation: values.nominee_relationship_with_life_to_be_assured,
              city: values.nominee_city,
              state: values.nominee_state,
              pincode: values.nominee_pincode,
              nationality: values.nationality,
              address_type: values.address_type,
             customer_detail :ids?.nomineeCustomerId ? ids.nomineeCustomerId : (nominee === "other" ? null : nominee)
            },

            insurance_coverage: {
              branch_address: values.branch_address,
              policy_term: values.policy_term,
              sum_assured: values.sum_assured,
              monthly_income: values.monthly_income,
              total_premium_incl_gst: values.total_premium_amount,
              emi_amount: values.emi_amount,
            },
            question: formattedQuestions1,

            document: propertyDocumentDetail,
          };

          if (!getAppointee) {
            formattedValues.appointee = {
              appointee_name: values.appointee_name,
              mobile: values.appointee_contact_number,
              mobile_country_code: "91",
              date_of_birth:
                values.appointee_date_of_birth?.format("YYYY-MM-DD"),
              gender: values.appointee_gender,
              relation: values.apointee_relationship_with_life_to_be_assured,
            };
          }

          if (isUnder18 && getAppointee) {
            formattedValues.nominne.appointee_detail = {
              appointee_detail: ids.appointeeId,
              appointee_name: values.appointee_name,
              mobile: values.appointee_contact_number,
              mobile_country_code: "91",
              date_of_birth:
                values.appointee_date_of_birth?.format("YYYY-MM-DD"),
              gender: values.appointee_gender,
              relation: values.apointee_relationship_with_life_to_be_assured,
            };
          }
          const questionsId = question.map((ques) => ques.id);
          const newQuestionData = formattedValues.question.map(
            (item, index) => {
              return {
                ...item,
                id: questionsId[index],
              };
            }
          );

          const newUpdatedFormattedValue = {
            ...formattedValues,
            insurance_detail: insurancedetail,
            // insurance_customer_id:insuranceCustomerId,
            insurance_coverage: {
              ...formattedValues.insurance_coverage,
              id: ids.coverageId,
            },
            Insurance_customer_detail: {
              ...formattedValues.insured_customer_detail,
              id: ids?.insuredCustomerId,
              customer_detail: insuranceCustomerId,
              insurance_customer_id: insuranceCustomerId,
            },
            nominne: {
              ...formattedValues.nominne,
              id: ids?.nomineeId,
              mobile: nomineeMobile,
            },

            question: newQuestionData,

            document: propertyDocumentDetail,
          };
          const response = await updateICICIPrudential(
            newUpdatedFormattedValue
          );
          if (response && response.success) {
            message.success("Details updated successfully");
            form.resetFields();
            toggleRefreshInsurance();
            closeForm();
          } else {
            message.error("Failed to update details");
          }
        } catch (error) {
          console.error("Error submitting form:", error);
          message.error(error.response.data.message);
        }
      } else {
        try {
          const formattedValues = {
            loan_detail: loan_id ? loan_id : id,
            insured_customer_detail: {
              // customer_detail: ids.customer_detail,
              customer_detail: customer_id ? customer_id : customerId,
              property_type: values.property_type,
              ocupation: values.occupation,
              city: values.city,
              state: values.state,
              pincode: values.pincode,
              address_line_1: values.address_line_1,
              address_line_2: values.address_line_2,
              height: values.height_in_cms,
              weight: values.weight_in_kgs,
              customer_address_type: values.address_type,
            },
            nominne: {
              name: values.nominee_name,
              mobile: values.nominee_contact_number,
              mobile_country_code: "91",
              date_of_birth: values.nominee_date_of_birth?.format("YYYY-MM-DD"),
              gender: values.nominee_gender,
              address_type: values?.address_type,
              address_line_1: values.nominee_address_line1,
              address_line_2: values.nominee_address_line2,
              relation: values.nominee_relationship_with_life_to_be_assured,
              city: values.nominee_city,
              state: values.nominee_state,
              pincode: values.nominee_pincode,
              nationality: values.nationality,
              customer_detail:nominee === "other" ? null : nominee
            },

            insurance_coverage: {
              branch_address: values.branch_address,
              policy_term: values.policy_term,
              sum_assured: values.sum_assured,
              monthly_income: values.monthly_income,
              total_premium_incl_gst: values.total_premium_amount,
              emi_amount: values.emi_amount,
            },
            question: formattedQuestions1,
            document: propertyDocumentDetail,
          };

          if (isUnder18) {
            formattedValues.nominne.appointee_detail = {
              appointee_detail: ids.appointeeId,
              appointee_name: values.appointee_name,
              mobile: values.appointee_contact_number,
              mobile_country_code: "91",
              date_of_birth:
                values.appointee_date_of_birth?.format("YYYY-MM-DD"),
              gender: values.appointee_gender,
              relation: values.apointee_relationship_with_life_to_be_assured,
            };
          }
          const response = await postICICIPrudentail(formattedValues);
          if (response && response.success) {
            message.success("Insurance successfully submitted");
            closeForm();
            form.resetFields();
            setSelectedCustomer("");
            setSelectedInsuranceType("");
            toggleRefreshInsurance();
          } else {
            message.error("Failed to update details");
          }
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      }
    } catch (error) {
      console.error("Error occurred while submitting:", error);
      message.error("An error occurred while processing the request");
    } finally {
      setLoading(false); // Stop loading after the process completes
    }
  };

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/[^0-9.]/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const handleInput = (e, field) => {
    // Allow letters, numbers, comma, forward slash, and empty spaces
    const newValue = e.target.value.replace(/[^A-Za-z0-9,/\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleRadioChange = (subItemId, e) => {
    const value = e.target.value;
    // Use functional updates to ensure the state is updated synchronously
    setShowRemark(value);
    if (subItemId === 46 && value === 0) {
      setQuestionRemark();
    }
    setSelectedValue((prevSelectedValue) => ({
      ...prevSelectedValue,
      [subItemId]: value,
    }));
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          // insured_customer_name: "",
          date_of_birth: "",
          // gender: "",
          // occupation,
          nationality: "",
          contact_number: "",
          email: "",
          age_proof: "",
          applicant_type: "",
          // height_in_cms: "",
          reason: "",
          address_line: "",
          loan_amount: "",
          tenure_of_loan: "",
          policy_term: "",
          sum_assured: "",
          monthly_income: "",
          // branch_address: "",
          total_premium_amount: "",
          specify_age_proof: "",
        }}
      >
        {/* Start Of Details ICICI Prudentail Section */}
        <div>
          <div className="details_coverage_hdfc">
            Details Of ICICI Prudential
          </div>
          <div className="drawer-insurance-space">
            <div className="details_of_icici">
              <div className="non_medical_limit">
                <div>Non-Medical Limits ( NML )</div>
                <div className="non_medical_limit_two">
                  <div className="icici_prudential_block">
                    <div className="detail_age">Age</div>
                    <div className="detail_heading">Sum insured (₹)</div>
                  </div>
                  <div className="icici_prudential_block">
                    <div className="detail_age">18-35</div>
                    <div className="detail_heading">1,50,000.00</div>
                  </div>
                  <div className="icici_prudential_block">
                    <div className="detail_age">36-40</div>
                    <div className="detail_heading">1,00,000.00</div>
                  </div>
                  <div className="icici_prudential_block">
                    <div className="detail_age">41-45</div>
                    <div className="detail_heading">75,00,000.00</div>
                  </div>
                  <div className="icici_prudential_block">
                    <div className="detail_age">46-50</div>
                    <div className="detail_heading">50,00,000.00</div>
                  </div>
                  <div className="icici_prudential_block">
                    <div className="detail_age">51-60</div>
                    <div className="detail_heading">25,00,000.00</div>
                  </div>
                </div>
              </div>
              <div>
                <div>ICICI Pru Policy Issuance Guideline</div>
                <ul className="statement-list">
                  <li>
                    Housewife/ Retired / Unemployed - no cover to be offered as
                    Primary Life.
                  </li>
                  <li>
                    StudentLife allowed as Primary Life – If cover offered as
                    secondary or joint life than case will be declined.
                  </li>
                  <li>
                    In case if LA is working in a Army, PPE or his is hazardous
                    in nature than Questionnaire will be called depending upon
                    the occupation.
                  </li>
                  <li>
                    Sourcing of NRI business is allowed from 11th Dec 2021 but
                    the guidelines which Reinsurer have shared.
                  </li>
                  <li>
                    Application for insurance cover will be accepted for 90 days
                    from the day loan has disbursed.
                  </li>
                  <li>
                    Reiterating that Maximum SA allowed is 3crs (Total SA) and
                    Maximum age is 60yrs.
                  </li>
                  <li>
                    Medicals done from the DC which is owned by Life assured
                    will not be considered.
                  </li>
                  <li>STD medicals are allowed for 1yr</li>
                  <li>SUB-STD medicals are allowed for 6 months.</li>
                  <li>
                    Affordable housing Loans will treated as per current
                    guidelines till this month and GECLloans are not allowed.
                  </li>
                  <li>
                    Loan cover should be spilt as per the share of the loan.
                  </li>
                  <li>
                    In case of loan taken on business entity, share has to be
                    spilt as per shareholding or Partnership Ratio.
                  </li>
                  <li>Can offer full cover for Proprietorship firm.</li>
                  <li>Guarantors are not allowed for insurance cover.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* End Of Details ICICI Prudentail Section */}
        {/* Start Of Details Insured Customer Section */}
        <div>
          <div className="details_coverage_hdfc">Insured Customer</div>
          <div className="drawer-insurance-space">
            <div>
              <Row gutter={16} className="insuarnce_row_input">
                <Col span={8}>
                  <Form.Item name="insured_customer_name" label="Name" required>
                    <Input
                      placeholder={
                        `${matchedCustomer?.first_name || ""} ${
                          matchedCustomer?.middle_name || ""
                        } ${matchedCustomer?.last_name || ""}`.trim() ||
                        "Please Enter Customer Name"
                      }
                      value={
                        `${matchedCustomer?.first_name || ""} ${
                          matchedCustomer?.middle_name || ""
                        } ${matchedCustomer?.last_name || ""}`.trim() || ""
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name="dateOfBirth" label="Date Of Birth" required>
                    <DatePicker
                      format="DD-MM-YYYY"
                      className="select_field_insurance"
                      placeholder="Select Date"
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item name="gender" label="Gender" required>
                    <Select
                      placeholder="Search To Select"
                      className="select_field_insurance"
                      disabled
                      allowClear
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Row gutter={16} className="insuarnce_row_input">
              <Col span={8}>
                <Form.Item
                  name="occupation"
                  label="Occupation"
                  required
                  rules={[yupSync]}
                >
                  <Select
                    placeholder="Please Select Occupation"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    allowClear
                  >
                    {occupationType?.map((locations, index) => (
                      <Option key={locations.id} value={locations.id}>
                        {locations.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="nationality" label="Nationality" required>
                  <Input
                    placeholder={`${
                      matchedCustomer?.nationality || "Please Enter Nationality"
                    }`}
                    value={`${matchedCustomer?.nationality || ""}`}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Contact Number" required>
                  <Input.Group compact>
                    <Form.Item noStyle>
                      <Input
                        className="insurance_country_code_block"
                        disabled
                        defaultValue="+91"
                      />
                    </Form.Item>
                    <Form.Item name="mobile" noStyle>
                      <Input
                        className="insurance_mobile_number_field"
                        placeholder={`${
                          matchedCustomer?.mobile_number ||
                          "Please Enter Contact Number"
                        }`}
                        value={`${matchedCustomer?.mobile_number || ""}`}
                        disabled
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="insuarnce_row_input">
              <Col span={8}>
                <Form.Item name="email" label="Email ID" required>
                  <Input
                    placeholder={`${
                      matchedCustomer?.email || "Please Enter Email"
                    }`}
                    value={`${matchedCustomer?.email || ""}`}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="applicant_type"
                  label="Applicant Type "
                  required
                >
                  <Input
                    placeholder={`${
                      matchedCustomer?.customer_type?.display_name ||
                      "Please Enter Applicant Type"
                    }`}
                    value={`${
                      matchedCustomer?.customer_type?.display_name || ""
                    }`}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="height_in_cms"
                  label="Height in Cms"
                  required
                  rules={[yupSync]}
                >
                  <Input
                    placeholder="Please Enter Height in Cms"
                    onChange={(e) => handleNumberFields(e, "height_in_cms")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="insuarnce_row_input">
              <Col span={8}>
                <Form.Item
                  name="weight_in_kgs"
                  label="Weight in Kgs"
                  required
                  rules={[yupSync]}
                >
                  <Input
                    placeholder="Please Enter Weight in Kgs"
                    onChange={(e) => handleNumberFields(e, "weight_in_kgs")}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="customer_address_type"
                  label="Address Type"
                  required
                  rules={[yupSync]}
                >
                  <Select
                    placeholder="Please Select Address Type"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    onChange={(value) => setAddressType(value)}
                    allowClear
                  >
                    {addressTypeList?.map((addressType, index) => (
                      <Option key={addressType.id} value={addressType.id}>
                        {addressType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="address_line_1"
                  label="Address Line 1"
                  required
                  rules={[yupSync]}
                >
                  <Input
                    placeholder="Please Enter Address Line 1"
                    onChange={(e) => handleInput(e, "address_line_1")}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="address_line_2"
                  label="Address Line 2"
                  required
                  rules={[yupSync]}
                >
                  <Input
                    placeholder="Please Enter Address Line 2"
                    onChange={(e) => handleInput(e, "address_line_2")}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="state"
                  label="State"
                  required
                  rules={[yupSync]}
                >
                  <Select
                    placeholder="Please Select State"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    allowClear
                    disabled={true}
                  >
                    {states?.map((states, index) => (
                      <Option key={states.id} value={states.id}>
                        {states.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="pincode"
                  label="Pincode"
                  rules={[yupSync]}
                  required
                >
                  <Select
                    placeholder="Please Select Pincode"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={true}

                    // onChange={handlePincodeChange}
                  >
                    {pincode?.map((pincodes, index) => (
                      <Option key={pincodes.id} value={pincodes.id}>
                        {pincodes.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="city" label="City" required rules={[yupSync]}>
                  <Select
                    placeholder="Please Select City"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    allowClear
                    disabled={true}
                  >
                    {city?.map((locations, index) => (
                      <Option key={locations.id} value={locations.id}>
                        {locations.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        {/* End Of Details Insured Customer Section */}
        {/* Start Of Details Of The Coverage ICICI Prudential Section */}
        <div className="details_coverage_hdfc">
          Details Of The Coverage ICICI Prudential
        </div>
        <div className="drawer-insurance-space">
          <Row gutter={16} className="insuarnce_row_input">
            <Col span={8}>
              <Form.Item label="Loan Amount (₹)" required>
                <Input.Group compact>
                  <Form.Item noStyle>
                    <Input
                      className="insurance_country_code_block"
                      disabled
                      defaultValue="₹"
                    />
                  </Form.Item>
                  <Form.Item name="loan_amount" noStyle required>
                    <Input
                      className="insurance_mobile_number_field"
                      placeholder={`${loanData.approved_amount}`}
                      value={`${loanData.approved_amount}`}
                      disabled
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="tenure"
                label="Tenure Of Loan (In Months)"
                required
              >
                <Input placeholder={`${loanData.approved_tenure}`} disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="policy_term"
                label="Policy Term (In Months)"
                required
                rules={[yupSync]}
              >
                <Input
                  placeholder="Please Enter Policy Term"
                  onChange={(e) => handleNumberFields(e, "policy_term")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="insuarnce_row_input">
            <Col span={8}>
              <Form.Item label="Sum Assured (₹)" required>
                <Input.Group compact>
                  <Form.Item noStyle>
                    <Input
                      className="insurance_country_code_block"
                      disabled
                      defaultValue="₹"
                    />
                  </Form.Item>
                  <Form.Item
                    name="sum_assured"
                    rules={[yupSync]}
                    noStyle
                    required
                  >
                    <Input
                      className="insurance_mobile_number_field"
                      placeholder="Please Enter Sum Assured"
                      onChange={(e) => handleNumberFields(e, "sum_assured")}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Monthly Income" rules={[yupSync]} required>
                <Input.Group compact>
                  <Form.Item noStyle>
                    <Input
                      className="insurance_country_code_block"
                      disabled
                      defaultValue="₹"
                    />
                  </Form.Item>
                  <Form.Item
                    name="monthly_income"
                    rules={[yupSync]}
                    noStyle
                    required
                  >
                    <Input
                      className="insurance_mobile_number_field"
                      placeholder="Please Enter Monthly Income"
                      onChange={(e) => handleNumberFields(e, "monthly_income")}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="branch_address"
                rules={[yupSync]}
                label="Branch Address"
                required
              >
                <Select
                  placeholder={"Please Enter Branch Address" || undefined}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {branches?.map((branch, index) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="insuarnce_row_input">
            <Col span={8}>
              <Form.Item
                label="Total Premium Amount(Including GST) (₹) "
                required
              >
                <Input.Group compact>
                  <Form.Item noStyle>
                    <Input
                      className="insurance_country_code_block"
                      disabled
                      defaultValue="₹"
                    />
                  </Form.Item>
                  <Form.Item
                    name="total_premium_amount"
                    rules={[yupSync]}
                    noStyle
                    required
                  >
                    <Input
                      className="insurance_mobile_number_field"
                      placeholder="Please Enter Total Premium Amount"
                      onChange={(e) =>
                        handleNumberFields(e, "total_premium_amount")
                      }
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="emi_amount"
                label="Emi Amount"
                rules={[yupSync]}
                required
              >
                <Input
                  className="insurance_mobile_number_field"
                  placeholder="Please Enter Emi Amount"
                  onChange={(e) => handleNumberFields(e, "emi_amount")}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {/* End Of Details Of The Coverage ICICI Prudential Section */}
        {/* Start Of Details Of Personal Details of Member Section */}
        <div className="details_coverage_hdfc">Personal Details Of Member</div>
        {/* {false ? (
          <div className="drawer-insurance-space">
            {questions?.map((item, index) => (
              <div key={item.id}>
                <p className="insurance_personal_details_heading">
                  {index + 1}. {item.question}
                </p>
                <Form.Item
                  name={`radio_${item.id}`}
                  rules={[
                    { required: true, message: "Please select an answer" },
                  ]}
                >
                  <Radio.Group>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Radio value={item?.answer === "yes"}>Yes</Radio>
                      </Col>
                      <Col span={12}>
                        <Radio value={item?.answer === "no"}>No</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </div>
            ))}
          </div>
        ) : ( */}
        <div className="drawer-insurance-space">
          {questions?.["Personal Detail"] &&
          questions["Personal Detail"].length > 0 ? (
            questions["Personal Detail"].slice(0).map((item, index) => (
              <div key={index}>
                <ICICIQuestionForm
                  item={item}
                  insurance_id={insurance_id}
                  index={index}
                  setShowRemark={setShowRemark}
                  showRemark={showRemark}
                  handleRadioChange={handleRadioChange}
                  selectedValue={selectedValue}
                  questionRemark={questionRemark}
                  setQuestionRemark={setQuestionRemark}
                  text="personal"
                  form={form}
                />
              </div>
            ))
          ) : (
            <p>No questions available</p>
          )}
        </div>
        {/* )} */}
        <div className="details_coverage_hdfc">
          COVID-19 DETAILS: Please confirm with customer
        </div>
        {
          <div className="drawer-insurance-space">
            {questions?.["Covid-19"]?.map((item, index) => (
              <ICICIQuestionForm
                item={item}
                index={index}
                insurance_id={insurance_id}
                setShowRemark={setShowRemark}
                showRemark={showRemark}
                handleRadioChange={handleRadioChange}
                selectedValue={selectedValue}
                questionRemark={questionRemark}
                setQuestionRemark={setQuestionRemark}
                text="covid"
                form={form}
              />
            ))}
          </div>
        }
        <div className="details_coverage_hdfc">Cover continuance option </div>
        {
          <div className="drawer-insurance-space">
            {questions?.["Cover Continuace"]?.map((item, index) => (
              <ICICIQuestionForm
                item={item}
                index={index}
                insurance_id={insurance_id}
                setShowRemark={setShowRemark}
                showRemark={showRemark}
                handleRadioChange={handleRadioChange}
                selectedValue={selectedValue}
                questionRemark={questionRemark}
                setQuestionRemark={setQuestionRemark}
                text="cover"
                form={form}
              />
            ))}
          </div>
        }
        {/* Start Of Nominee Details ICICI Section */}
        <div className="details_coverage_hdfc">Nominee Details ICICI :</div>
        <div className="drawer-insurance-space">
          <Row gutter={16} className="insuarnce_row_input">
            <Col span={8}>
              <Form.Item name="select_nominee" label="Co-Nominee">
                <Select
                  placeholder="Please Select Nominee"
                  showSearch
                  optionFilterProp="children"
                  onChange={handleNomineeChange}
                  filterOption={filterOption}
                  required
                  allowClear
                >
                  {personalData
                    ?.filter((person) => person.id !== customerId) // Filter to ensure customerId exists
                    .map(
                      (person, index) =>
                        person.customer_type?.name !== "Guarantor" && (
                          <Option key={person.id} value={person.id}>
                            {`${person.first_name} ${person.middle_name} ${person?.last_name}`}
                          </Option>
                        )
                    )}
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="insuarnce_row_input">
            <Col span={8}>
              <Form.Item
                name="nominee_name"
                label="Nominee Insured Customer Name"
                required
                rules={nominee === "other" ? [yupSync] : []}
              >
                <Input
                  placeholder={
                    nominee === "other"
                      ? "Please Nominee Customer Name"
                      : `${matchedNominee?.first_name || ""} ${
                          matchedNominee?.middle_name || ""
                        } ${matchedNominee?.last_name || ""}`.trim() ||
                        "Please Nominee Customer Name"
                  }
                  value={
                    nominee === "other"
                      ? ""
                      : `${matchedNominee?.first_name || ""} ${
                          matchedNominee?.middle_name || ""
                        } ${matchedNominee?.last_name || ""}`.trim()
                  }
                  disabled={!insurance_id && nominee !== "other"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="nominee_date_of_birth"
                rules={[yupSync]}
                label="Date Of Birth"
                required
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  className="select_field_insurance"
                  placeholder="Select Date"
                  onChange={handleDateChange}
                  disabled={!insurance_id && nominee !== "other"}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="nominee_gender"
                label="Gender"
                required
                rules={nominee === "other" ? [yupSync] : []}
              >
                <Select
                  placeholder="Search To Select"
                  className="select_field_insurance"
                  disabled={!insurance_id && nominee !== "other"}
                  allowClear
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="insuarnce_row_input">
            <Col span={8}>
              <Form.Item label="Contact Number" required>
                <Input.Group compact>
                  <Form.Item noStyle>
                    <Input
                      className="insurance_country_code_block"
                      disabled
                      defaultValue="+91"
                    />
                  </Form.Item>
                  <Form.Item
                    name="nominee_contact_number"
                    rules={nominee === "other" ? [yupSync] : []}
                    noStyle
                    required
                  >
                    <Input
                      className="insurance_mobile_number_field"
                      placeholder={"Enter Contact Number" || undefined}
                      onChange={handleInputChange}
                      disabled={!insurance_id && nominee !== "other"}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="nominee_relationship_with_life_to_be_assured"
                label="Relationship with life to be Assured"
                required
                rules={[yupSync]}
              >
                <Select
                  placeholder="Please Select Relation"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  required
                  allowClear
                >
                  {relation?.map((relations, index) => (
                    <Option key={relations.id} value={relations.id}>
                      {relations.display_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="nominee_nationality"
                rules={nominee === "other" ? [yupSync] : []}
                label="Nationality"
                required
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder={
                    nominee === "other"
                      ? "Select Nationality"
                      : matchedNominee?.nationality || "Select Nationality"
                  }
                  value={
                    nominee === "other"
                      ? undefined
                      : matchedNominee?.nationality
                  }
                  disabled={!insurance_id && nominee !== "other"}
                  
                  onChange={(value) => {
                    if (nominee === "other") {
                      form.setFieldsValue({ nominee_nationality: value });
                    }
                  }}
                >
                  {nationalities?.map((nationality, index) => (
                    <Option key={index} value={nationality}>
                      {nationality}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} className="insuarnce_row_input">
            <Col span={8}>
              <Form.Item
                name="address_type"
                label="Address Type"
                required
                className="addtess_line_insurance"
                rules={[yupSync]}
              >
                <Select
                  placeholder="Please Select Address Type"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  onChange={(value) => setNomineeAddressType(value)}
                  allowClear
                >
                  {addressTypeList?.map((addressType, index) => (
                    <Option key={addressType.id} value={addressType.id}>
                      {addressType.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="nominee_address_line1"
                label="Address Line 1"
                required
                className="addtess_line_insurance"
                rules={[yupSync]}
              >
                <Input
                  placeholder="Please Enter Address Line 1"
                  onChange={(e) => handleInput(e, "nominee_address_line1")}
                  disabled={!insurance_id && nominee !== "other"}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="nominee_address_line2"
                label="Address Line 2 "
                required
                rules={[yupSync]}
                className="addtess_line_insurance"
              >
                <Input
                  placeholder="Please Enter Address Line 2"
                  onChange={(e) => handleInput(e, "nominee_address_line2")}
                  disabled={!insurance_id && nominee !== "other"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="nominee_city"
                label="City"
                rules={[yupSync]}
                required
              >
                <Select
                  placeholder="Please Select City"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  required
                  allowClear
                  disabled={!insurance_id && nominee !== "other"}
                >
                  {city?.map((locations, index) => (
                    <Option key={locations.id} value={locations.id}>
                      {locations.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="nominee_state"
                label="State"
                rules={[yupSync]}
                required
              >
                <Select
                  placeholder="Please Select State"
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  required
                  allowClear
                  disabled={!insurance_id && nominee !== "other"}
                >
                  {states?.map((states, index) => (
                    <Option key={states.id} value={states.id}>
                      {states.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="nominee_pincode"
                label="Pincode"
                rules={[yupSync]}
                required
              >
                <Select
                  placeholder="Please Select Pincode"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!insurance_id && nominee !== "other"}
                  onChange={handleNomineePincodeChange}
                >
                  {pincode?.map((pincodes, index) => (
                    <Option key={pincodes.id} value={pincodes.id}>
                      {pincodes.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {isUnder18 && (
            <Row gutter={16} className="insuarnce_row_input">
              <Col span={8}>
                <Form.Item
                  name="appointee_name"
                  label="Appointee Name"
                  rules={[yupSync]}
                  required
                >
                  <Input placeholder="Please Enter Appointee Nominee Name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="appointee_date_of_birth"
                  rules={[yupSync]}
                  label="Date Of Birth"
                  required
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    className="select_field_insurance"
                    placeholder="Select Date"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="appointee_gender"
                  rules={[yupSync]}
                  label="Gender"
                  required
                >
                  <Select
                    placeholder="Search To Select"
                    className="select_field_insurance"
                    allowClear
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Contact Number" required>
                  <Input.Group compact>
                    <Form.Item noStyle>
                      <Input
                        className="insurance_country_code_block"
                        disabled
                        defaultValue="+91"
                      />
                    </Form.Item>
                    <Form.Item
                      name="appointee_contact_number"
                      rules={[yupSync]}
                      noStyle
                    >
                      <Input
                        className="insurance_mobile_number_field"
                        placeholder="Please Enter Contact Number"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="apointee_relationship_with_life_to_be_assured"
                  label="Relationship with Nominee"
                  required
                  rules={[yupSync]}
                >
                  <Select
                    placeholder="Please Select Relation"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    required
                    allowClear
                  >
                    {relation?.map((relations, index) => (
                      <Option key={relations.id} value={relations.id}>
                        {relations.display_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <div className="details_coverage_hdfc">Documents</div>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="documents" label="">
                <Upload
                  single
                  fileList={fileList}
                  onChange={handleUploadChange}
                  beforeUpload={handleBeforeUpload}
                  showUploadList={false}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    className="upload_document_insurance"
                  >
                    Upload Document
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <div className="aplicantin_pdf_insurance_conatiner_first">
            <div className="aplicantin_pdf_insurance_conatiner">
              {image?.map((picture, index) => (
                <div key={index} className="aplicantin_pdf_insurance_image">
                  <img
                    src={picture.file_path}
                    alt=""
                    className="insurance_img_picture"
                  />
                  <div
                    className="dlete_insurance"
                    onClick={() => {
                      setDeleteUploadPicture(picture);
                      setOpenUploadModal(true);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="image-gallery">
            <div className="aplicantin_pdf_insurance_conatiner">
              {existingImages?.map((picture, index) => (
                <div key={index} className="">
                  {picture.file_type === "application/pdf" ? (
                    <img
                      src={pdf_image}
                      width="100px"
                      height="100px"
                      className="inusurance_pdf_img"
                      alt="PDF Preview"
                      onClick={() => window.open(picture.document, "_blank")}
                    />
                  ) : (
                    <RViewerJS
                      options={{
                        url: (image) => image.getAttribute("data-original"),
                      }}
                    >
                      <img
                        src={picture.thumb_document}
                        data-original={picture.document}
                        alt="Preview"
                        className="insurance_img_picture"
                      />
                    </RViewerJS>
                  )}

                  <div
                    className="dlete_insurance"
                    onClick={() => {
                      setDeletePicture(picture);
                      setOpenModal(true);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Modal
            title="Confirm Delete"
            open={openUploadModal}
            onOk={confirmDelete}
            onCancel={() => {
              setOpenUploadModal(false);
              setDeleteUploadPicture(null);
            }}
            okType="danger"
          >
            <p>{`Are you sure you want to delete this upload image?`}</p>
          </Modal>
          <Modal
            title="Confirm Delete"
            open={openModal}
            onOk={deletePropertyPictures}
            onCancel={() => {
              setOpenModal(false);
              setDeletePicture(null);
            }}
            okType="danger"
          >
            <p>Are you sure you want to delete?</p>
          </Modal>

          <Space
            direction="horizontal"
            align="center"
            className="save_cncle_insurance"
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={closeForm}>Cancel</Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};

export default ICICIInsuranceForm;
