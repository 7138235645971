import React, { useEffect, useState } from "react";
import "../../style/customerStyle.css";
// import verify from "../assets/Group.png";
import { message } from "antd";
import { getCrimeCheckDetailsByLoanId, getFraudDetailsByLoanId } from "../services/fraudCrimeCheckServices";
import { useParams } from 'react-router-dom';
import Loading from "../../../../../utils/loading/Loading";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { EyeOutlined } from '@ant-design/icons';
import "../../../../commonCss/commonStyle.css";
import "../styles/fraudCrimeCheckDetails.css"
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";

const FraudCrimeCheck = () => {
  const { encrypted_loan_id } = useParams();
  const [crimeCheckData, setCrimeCheckData] = useState([]);
  const [fraudDetails, setFraudDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  // useEffect(() => {
  //   const fetchCrimeCheckData = async () => {
  //     try {
  //       const data = await getCrimeCheckDetailsByLoanId(loan_id);
  //       setCrimeCheckData(data.data);
  //       setLoading(false);
  //     } catch (error) {
  //       message.error(
  //         ErrorMessage(error?.response?.status, error?.response?.data?.message)
  //       );
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchCrimeCheckData();
  // }, [loan_id]);

  // useEffect(() => {
  //   // Fetch data when the component mounts
  //   const fetchFraudData = async () => {
  //     try {
  //       const data = await getFraudDetailsByLoanId(loan_id);
  //       setFraudDetails(data.data);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       message.error(
  //         ErrorMessage(error?.response?.status, error?.response?.data?.message)
  //       );
  //     }
  //   };

  //   fetchFraudData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const [crimeCheckResponse, fraudDetailsResponse] = await Promise.all([
          getCrimeCheckDetailsByLoanId(loan_id),
          getFraudDetailsByLoanId(loan_id),
        ]);

        // Set data after fetching
        setCrimeCheckData(crimeCheckResponse?.data || []);
        setFraudDetails(fraudDetailsResponse?.data || []);
      } catch (error) {
        // Handle error
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      } finally {
        // Stop loading after fetching is complete
        setLoading(false);
      }
    };

    fetchData();
  }, [loan_id]);

  const tableHeadings = [
    "Crime Data",
    "No of Cases",
    "Report Date",
    "Status",

    "View Full Report"
  ];

  const HunterHeading = [
    "Fraud Data",
    "Score",
    "Date Of Initiation",

    "Decision"];

  return (
    <>
      <div className="tab_form_container">
        <div className="tab_form_main_container fade-in">
          {loading ? (
            <Loading loading={loading} />
          ) : crimeCheckData.length > 0 || fraudDetails.length > 0 ? (

            <table className="tab_form_main_table">
              <thead>
                <tr>
                  <th className="tab_form_key_parameters">Key Parameters</th>
                  {fraudDetails.map((customer) => (
                    <th key={customer.customer_id} className="tab_form_top_heading">
                      <span>{customer.customer_name}</span>
                      <span>
                        <span>{` (${customer.customer_type})`} </span>
                        {/* <img src={verify} alt="group" className="verify_img_fraud_check" /> */}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>





                {/* Render Fraud Details */}


                {HunterHeading.map((heading, rowIndex) => (
                  <tr

                    key={`row-${rowIndex}`}
                    className={

                      heading === "Fraud Data" ||
                        heading === "Fraud Data"
                        ? "address-row"
                        : heading === "PD Entries"
                          ? "address-pd-row "
                          : heading === "Staying for Years"
                            ? "tab_form_highlighted_row_orange"
                            : ""

                    }
                  >
                    <td
                      key={`col-${rowIndex}`}
                      className="tab_form_left_heading ">
                      {heading}
                    </td>

                    {fraudDetails.map((detail, colIndex) => (
                      <td key={colIndex} className="tab_form_main_data_table">

                        {heading === "Score"
                          ? detail.score || "-"
                          : heading === "Date Of Initiation"
                            ? detail.date_of_initiation || "-"
                            : heading === "Decision"
                              ? detail.descision
                              : ""}
                      </td>
                    ))}
                  </tr>
                ))}


                {tableHeadings.map((heading, rowIndex) => (
                  <tr

                    key={`row-${rowIndex}`}
                    className={

                      heading === "Crime Data" ||
                        heading === "Crime Data"
                        ? "address-row"
                        : heading === "PD Entries"
                          ? "address-pd-row "
                          : heading === "Staying for Years"
                            ? "tab_form_highlighted_row_orange"
                            : ""

                    }
                  >
                    <td
                      key={`col-${rowIndex}`}
                      className="tab_form_left_heading ">
                      {heading}
                    </td>
                    {crimeCheckData.map((customer) => {
                      const details = customer.customer_crime_check_details || {};
                      return (
                        <td key={customer.customer_id} className="tab_form_main_data_table">
                          {


                            heading === "No of Cases"
                              ? details.number_of_cases || "-"

                              : heading === "Report Date"
                                ? details.report_date || "-"

                                : heading === "Status"
                                  ? details.status || "-"

                                  : heading === "View Full Report" && (
                                    details?.report ? (
                                      <a
                                        href={details?.report}
                                        target="_blank"
                                        rel="noopener noreferrer"

                                      >
                                        <EyeOutlined className="fraud_check_icon" />
                                      </a>
                                    ) : (
                                      "-"
                                    ))
                          }

                        </td>
                      );
                    })}
                  </tr>
                ))}






              </tbody>
            </table>
          ) : (
            <div className='fade-in'>
              <div>
                <table className='tab_form_main_table no_data_table_height'>
                  <thead>
                    <tr>
                      <th className="tab_form_key_parameters">
                        Key Parameters
                      </th>
                      {fraudDetails?.map((person, index) => (
                        <th
                          key={index}
                          className="tab_form_top_heading no_data_heading_bottom_border"
                        >
                          <span className='no_data_heading_content'>
                            <div>
                              <span>{`${person?.customer_name}`} </span>
                              <span>
                                <span>{`(${person?.customer_type})`} </span>
                                {/* <img
                                  src={verify}
                                  alt="group"
                                  className='verify_icon'
                                /> */}
                              </span>
                            </div>

                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Placeholder to ensure table body aligns */}
                    {fraudDetails?.length && crimeCheckData?.length ? (
                      <tr>
                        <td colSpan={fraudDetails?.length + 1}>
                          {/* Your data rows go here */}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={fraudDetails?.length + 1}
                        ></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className='no_data_icon'
              >
                <CustomNoData />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FraudCrimeCheck;
