import React, { useState } from "react";
import { Tabs } from "antd";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import "../../customerDetails/styles/customerDetails.css";
import "../../../styles/loanTabsApplication.css";
import { customerDetailsTabs } from "../../../../../config/schemeConfig";
import PartnerDetails from "../../partnerDetails/views/PartnerDetails";
import "../../../styles/loanTabsApplication.css"  

const { TabPane } = Tabs;

const CustomerDetails = ({ loanDetails ,onRefresh}) => {
  const [mode,] = useState("top");
  const [showCustomerDetails, setShowCustomerDetails] = useState(true);
  const [activeKey, setActiveKey] = useState("0");
  const [isPartnerTabVisible, setIsPartnerTabVisible] = useState(false);
  // const [isPartnerTabVisible, setIsPartnerTabVisible] = useState(false);

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const toggleCustomerDetails = () => {
    setShowCustomerDetails((prev) => !prev);
  };

  const handleShowPartnerTab = (isPresent) => {
    setIsPartnerTabVisible(isPresent);
  };

  const tabs = [];



  customerDetailsTabs[loanDetails?.loan_type?.display_name]?.forEach((item) => {
    let componentWithProps;
    if (item.label === "Address Details") {
      componentWithProps = <item.component loanDetails={loanDetails} />;
    }
    else if (item.label === "Personal Details") {
      componentWithProps = <item.component onShowPartnerTab={handleShowPartnerTab} onRefresh={onRefresh}/>;
    } else {
      componentWithProps = <item.component />;
    }

    tabs.push({ label: item.label, component: componentWithProps });
  });

  if (isPartnerTabVisible) {
    tabs.push({ label: "Partners", component: <PartnerDetails /> });
  }



  return (
    <div className="customer_main_container">
      <div
        className={`customer_main_heading ${!showCustomerDetails ? "customer_main_heading_border" : ""}`}
      >
        <div>
          <h2 className="heading_loan_details">Customer Details</h2>
        </div>
        <div className="customer_icon">
          {showCustomerDetails ? (
            <AiOutlineUp
              className="customer_collapse_icon"
              onClick={toggleCustomerDetails}
            />
          ) : (
            <AiOutlineDown
              className="customer_collapse_icon"
              onClick={toggleCustomerDetails}
            />
          )}
        </div>
      </div>
      <div className={`${showCustomerDetails ? "customer_block_content_show" : "customer_block_content_hide"}`}>
        <Tabs tabPosition={mode} type="card" className="customer_block_content" onChange={handleTabChange} activeKey={activeKey} defaultActiveKey="1" destroyInactiveTabPane>
          {tabs.map((tab, index) => (
            <TabPane tab={tab.label} key={index}>
              {tab.component}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default CustomerDetails;