import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Space, Drawer, message } from "antd";
import { EditOutlined,  PlusCircleOutlined, } from "@ant-design/icons";
import PropertyForm from "./PropertyForm";
import Header from "../../../../../../layout/views/Header";
import ApplicantEditForm from "./ApplicantEditForm";
import {
  getPropertyAndCustomerDetailByLoanId,
  getPropertyDataIdService,
} from "../services/SecurityInterestPendingList";
import "../styles/CersaiEditForm.css"
import { decrypt } from "../../../../../../../utils/cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
// import { decrypt } from "../../../../../../../utils/cryptoUtils/cryptoUtils";
// import { useParams } from "react-router-dom";

const CersaiEditForm = ({loanId, open }) => {
  console.log("loanId",loanId)
  const { Title } = Typography;
  const [propertyDrawerOpen, setPropertyDrawerOpen] = useState(false);
  const [applicantDrawerOpen, setApplicantDrawerOpen] = useState(false);
  const [, setPropertyData] = useState([]);
  const [propertyDataById, setPropertyDataById] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [branchData, setBranchData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [selectedPropertyData, setSelectedPropertyData] = useState(null);
  const [customerDetailId, setCustomerDetailId] = useState(null);
  const [cersaiDetailList, setCersaiDetailList] = useState(null);
  const [customerDetail, setCustomerDetail] = useState(null);
  const [propertyId , setPropertyId] =useState(null);
  const [cersaidetail , setCersaiDetail] =useState(null);
  const [customerId, setCostumerId] =useState(null)
  const [mode, setMode] = useState("add");

  const { encrypted_loan_id } = useParams();
  let loanidbyparam = null;
  try {
    loanidbyparam = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  // console.log("loanid",loanid)

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  const showPropertyDrawer = () => {
    setPropertyDrawerOpen(true);
  };

  const showApplicantDrawer = (record) => {
    const customerIndex = customerData.findIndex(
      (customer) => customer.id === record.key
    );
    const customerId = customerData.find(
      (customer) => customer.id === record.key
    )?.id;
    setCostumerId(customerId)
    const getcersaidetail = cersaiDetailList[customerIndex];
    setCustomerDetail(getcersaidetail);
    setCustomerDetailId(customerIndex);
    setApplicantDrawerOpen(true);
  };

  const onClosePropertyDrawer = () => {
    setPropertyDrawerOpen(false);
  };

  const onCloseApplicantDrawer = () => {
    setApplicantDrawerOpen(false);
  };

  const handleaddcersai = () => {
    setMode("add"); // Set mode to 'add'
    showPropertyDrawer();
    fetchPropertyAndCustomerDetailByLoanId();
  };
  // const effectiveLoanId = loanIdByApplication || loanId;

  const fetchPropertyAndCustomerDetailByLoanId = async () => {
   
    try {
      const response = await getPropertyAndCustomerDetailByLoanId(loanId||loanidbyparam);
      const propertyDetails = response.data.property_detail;
      const customerDetails = response.data.customer_detail;
      const propertydetailid=propertyDetails.cersai_property_detail;
      const cersaidetailid=propertyDetails.cersai_detail;
      setPropertyId(propertydetailid);
      setCersaiDetail(cersaidetailid)
      const cersaiCustomerDetails = customerDetails.map((customer) => ({
        id: customer.id,
        cersaiDetail: customer.cersai_detail || null, 
        customerDetail: customer.customer_detail || null,
        customertype: customer.customer_type || null,
        addresstype: customer.address_type || null,
        cersaiCustomerDetail: customer.cersai_customer_detail || null,
      }));
      setCersaiDetailList(cersaiCustomerDetails);

      const branchDetails = response.data.branch.location;
      setPropertyData([propertyDetails]);
      setBranchData([branchDetails]);
      setCustomerData(customerDetails);
    } catch (error) {
      message.error("Failed to fetch the Property And Customer Data.");
    }
  };

  const fetchPropertyDetailById = async () => {
    try {
      const response = await getPropertyDataIdService(propertyId);
      const propertyDetailsById = response.data;
      setPropertyDataById([propertyDetailsById]);
    } catch (error) {
      if (propertyDataById.length > 0) {
        message.error("Failed to fetch the Property Data.");
      }
    }
  };

  useEffect(() => {
    fetchPropertyAndCustomerDetailByLoanId();
    if(propertyId){
      fetchPropertyDetailById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, loanId,loanidbyparam, open,propertyId]);


  const customerColumns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "PAN/CIN/CKYC No.",
      key: "panCkyc",
      render: (_, record) => (
        <div>
          <div>PAN: {record.pan}</div>
          <div>CKYC No.: {record.ckyc || "NA"}</div>
        </div>
      ),
    },
    {
      title: "Address",
      key: "address",
      render: (_, record) => {
        const customerIndex = customerData.findIndex(
          (customer) => customer.id === record.key
        );
        const cersaiCustomerDetail = cersaiDetailList[customerIndex]?.cersaiCustomerDetail;
  
        if (cersaiCustomerDetail === null) {
          return <span className="update-address">Please Update Address</span>;
        }
  
        const { address } = record;
        return address ? (
          <div className="customer-detail-address">
            {address}
          </div>
        ) : (
          <span className="update-address">Please Update Address</span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <span>
          <EditOutlined
            onClick={() => showApplicantDrawer(record)}
            className="cersai-customer-detail-edit"
          />
        </span>
      ),
    },
  ];

  // Mapping customer data to table format
  const mapCustomerData = (customers) => {
    return customers.map((customer, index) => {
      const permanentAddress =
        customer?.customer_address_detail?.permanent_address || {};
      return {
        key: customer.id, // Unique key for the table
        srNo: index + 1, // Serial number
        name: `${customer.salutation} ${customer.first_name} ${customer.middle_name} ${customer.last_name}`.trim(),
        pan: customer.pan || "NA", // Assume PAN comes from the backend
        ckyc: customer.ckyc_no || "NA", // Assume CKYC No. comes from the backend
        address: permanentAddress.address_line_1
          ? `${permanentAddress.address_line_1}, ${
              permanentAddress.city || "NA"
            }, ${permanentAddress.state || "NA"}`
          : "Please Update Address", // Concatenated address or fallback
      };
    });
  };
  const mappedData = mapCustomerData(customerData);

  // Columns for Branch Details Table
  const branchColumns = [
    {
      title: "Branch Code",
      dataIndex: "branch_code",
      key: "branch_code",
    },
    {
      title: "Branch Name",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Branch Address",
      key: "branchAddress",
      render: (_, record) => (
        <div>
          <div>Address Line 1:{record.address_line_1}</div>
          <div>Address Line 2:{record.address_line_2}</div>
          <div>State: {record.state}</div>
          <div>City: {record.city}</div>
          <div>District: {record.district}</div>
          <div>Pin Code: {record.pincode}</div>
        </div>
      ),
    },
  ];

  const propertyColumns = [
    {
      title: "Property Type",
      dataIndex: "nature_of_property",
      key: "nature_of_property",
    },
    {
      title: "Plot No",
      dataIndex: "plot_number",
      key: "plot_number",
    },
    {
      title: "Carpet Build Up Area",
      dataIndex: "carpet_build_up_area",
      key: "carpet_build_up_area",
    },
    {
      title: "Address",
      key: "address",
      render: (_, record) => {
        return (
          <div>
            <div>Project Name: {record?.project_name || "N/A"}</div>
            <div>Line 1: {record?.building_number || "N/A"}</div>
            <div>Line 2: {record?.street_number || "N/A"}</div>
            <div>Locality: {record?.landmark || "N/A"}</div>
            <div>Area: {record?.area || "N/A"}</div>
            <div>District: {record?.district || "N/A"}</div>
            <div>State: {record?.state || "N/A"}</div>
            <div>Pin Code: {record?.pincode || "N/A"}</div>
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="cersai-property-detail-edit" >
          <Button
            icon={<EditOutlined  />}
            type="link"
            onClick={() => {
              setMode("edit");
              showPropertyDrawer();
              setSelectedPropertyData(record);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Drawer
        title={
          <Header
            title={selectedPropertyData ? "Edit" : "Add"}
            onClose={onClosePropertyDrawer}
            name="Property Details"
          />
        }
        width={650}
        onClose={onClosePropertyDrawer}
        open={propertyDrawerOpen}
        closable={false}
      >
        <PropertyForm
          open={propertyDrawerOpen}
          closeForm={onClosePropertyDrawer}
          id={selectedPropertyData && selectedPropertyData?.id}
          loan_id={loanId ||loanidbyparam }
          mode={mode} 
          refreshData={toggleRefreshData}
        />
      </Drawer>
      <Drawer
        title={
          <Header
            title={customerDetail?.cersaiCustomerDetail ? "Edit" : "Add"}
            onClose={onCloseApplicantDrawer}
            name="Applicant Details"
          />
        }
        width={850}
        onClose={onCloseApplicantDrawer}
        open={applicantDrawerOpen}
        closable={false}
      >
        <ApplicantEditForm
          open={applicantDrawerOpen}
          closeForm={onCloseApplicantDrawer}
          refreshData={toggleRefreshData}
          customerDetailId={customerDetailId}
          customerData={customerData}
          loan_id={loanId||loanidbyparam}
          customerDetail={customerDetail}
          cersaiDetail={customerDetail?.cersaiDetail} 
          cersaidetail={cersaidetail}
          customerId={customerId}
      
        />
      </Drawer>
      <Space
        direction="horizontal"
        align="center"
        className="property-detail-heading"
      >
        <Title level={5}>Property Details</Title>
        <Button
          type="primary"
          className="add-cersai-button"
          onClick={handleaddcersai}
          disabled={propertyDataById.length > 0}
          icon={<PlusCircleOutlined />}

        >
          Add Cersai
        </Button>
      </Space>
      {propertyDataById.length > 0 && (
        <Table
          dataSource={propertyDataById}
          columns={propertyColumns}
          pagination={false}
          className="customer-detail-table"
        />
      )}

      <Space
        direction="horizontal"
        align="center"
        className="property-detail-heading"
      >
        <Title level={5}>Customer Details</Title>
      </Space>
      <Table
        dataSource={mappedData}
        columns={customerColumns}
        pagination={false}
        bordered
        className="customer-detail-table"
      />

      <Space
        direction="horizontal"
        align="center"
        className="property-detail-heading"
      >
        <Title level={5}>Branch Details</Title>
      </Space>
      <Table
        dataSource={branchData}
        columns={branchColumns}
        pagination={false}
        bordered
        className="customer-detail-table"
      />
    </div>
  );
};

export default CersaiEditForm;
