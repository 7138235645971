import React, { useEffect, useState } from "react";
import EditButton from "../../../../utils/editButton/EditButton";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import BusinessPremisesEditForm from "./BusinessPremisesEditForm";
import { getFiBusinessPremisesByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import Loading from "../../../../utils/loading/Loading";
import CustomNoData from "../../../../utils/noDataIcon/CustomNoData";

const BusinessPremises = ({ selectedCustomerId }) => {
  const [open, setOpen] = useState(false);
  const [collapsed2, setCollapsed2] = useState(true);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [premises, setPremises] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFiBusinessPremisesByCustomerId(selectedCustomerId);
       
        setPremises(response?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, selectedCustomerId]);
  const toggleRefreshData = () => {
    setRefreshTableData((prev) => !prev);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };
  const renderEditButton = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawer();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div className="occupation_ov">
      <Drawer
        title={
          <Header title="Edit" onClose={onClose} name="Business Premises" />
        }
        width={970}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <BusinessPremisesEditForm
          open={open}
          updateId={premises?.id}
          closeForm={onClose}
          refreshData={toggleRefreshData}
          customerDetails={premises}
        />
      </Drawer>
      <div className="occupation_div_container">
      <div className="fade-in">
        {loading ? (
          <Loading loading={loading} /> // Loading component while data is fetching
        ) : premises ? (
          <>

            <div className="fi_description_heading">
              <div className="fi_description_data">Business Premises</div>
              <div className="ov_views_edit_button">
                <div
              
                >
                  {renderEditButton()}
                </div>
                <div
                 className="fi_views_collapse_icon"
                  onClick={toggleCollapse2}
                >
                  {collapsed2 ? <DownOutlined /> : <UpOutlined />}
                </div>
              </div>
            </div>
            {collapsed2 && (
              <div>
                <>
                  <div className="fi_description_basic_card_container">
                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Business Premises Ownership
                        </div>
                        <div className="fi_description_basic_card_data">
                          {premises?.ownership || "-"}
                        </div>
                      </div>
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Surroundings
                        </div>
                        <div className="fi_description_basic_card_data">
                          {premises?.surrounding
                            ? JSON.parse(
                              premises?.surrounding.replace(/'/g, '"')
                            ).join(", ")
                            : "-"}
                        </div>
                      </div>
                    </div>

                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Company Board
                        </div>
                        <div className="fi_description_basic_card_data">
                          {premises?.company_board ? "Yes" : "No"}
                        </div>
                      </div>

                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Type of Office
                        </div>
                        <div className="fi_description_basic_card_data">
                          {premises?.office_type || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Activity
                        </div>
                        <div className="fi_description_basic_card_data">
                          {premises?.activity ? "Yes" : "No"}
                        </div>
                      </div>
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          Location of Office :
                        </div>
                        <div className="fi_description_basic_card_data">
                          {premises?.office_location || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="fi_description_basic_card">
                      <div className="property_description_basic_card-content">
                        <div className="fi_description_basic_card_label">
                          SetUp
                        </div>
                        <div className="fi_description_basic_card_data">
                          {premises?.setup ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            )}
          </>
        ) : (
          <div className="no_data_found_message">
            <CustomNoData />
          </div>
        )}



      </div>
      </div>

    </div>
  );
};

export default BusinessPremises;
