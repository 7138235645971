import {axiosRequest} from "../../../../../utils/api/axiosRequest"

export const getHighMarkReportByIdService = (customer_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/bureau/highmark/fetch-full-highmark-report-by-customer/${customer_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}