import React, { useEffect, useState } from "react";
import edit from "../assets/Editbutton.png";
import "../styles/creaditAnalysisTab.css";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Space, Button, Table, message, Drawer, Modal } from "antd";
import { GoDotFill } from "react-icons/go";
import "../../../customerTabDetails/style/customerStyle.css";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  deleteSanctionConditionById,
  getSanctionDetailsByLoanId,
  getDistanceDetailsByLoanId,
  getStrengthsOfTheCaseByLoanId,
  getDecisionAnalysisByLoanId,
  deleteStrengthById,
} from "../services/creditAnalysisServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import Header from "../../../../layout/views/Header";
import SanctionCondition from "./SanctionCondition";
import DistanceDetailsForm from "./DistanceDetailsForm";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import Deviation from "../../../../../utils/deviation/views/Deviation";
import DecisionQuestionsForm from "./DecisionQuestionsForm";
import DecisionQuestionAddForm from "./DecisionQuestionAddForm";
import StrenthForm from "./StrenthForm";
import WeaknessForm from "./WeaknessForm";
import authorizeTableColumns from "../../../../../utils/authorization/authorizeTableColumns";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";

const CreaditAnalysis = () => {
  const { encrypted_loan_id } = useParams();
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [internalSanctions, setInternalSanctions] = useState([]);
  const [externalSanctions, setExternalSanctions] = useState([]);
  const [open, setOpen] = useState(false);
  const [strengthopen, setStrengthOpen] = useState(false);
  const [openDistanceDetails, setOpenDistanceDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSanction, setSelectedSanction] = useState(null);
  const [sanctionType, setSanctionType] = useState(null);
  const [strengthsData, setStrengthsData] = useState([]);
  const [weaknessData, setWeaknessData] = useState([]);
  const [showEditStrengthForm, setShowEditChequeForm] = useState(false);
  const [showEditWeknessForm, setShowWeaknessForm] = useState(false);
  const [selectedStrengthData, setSelectedStrengthData] = useState(null);
  const [collapseSections, setCollapseSections] = useState(true);
  const [collapsedStrength, setCollapsedStrength] = useState(false);
  const [collapsedWeakness, setCollapsedWeakness] = useState(false);
  const [distanceDetailscollapseSections, setDistanceDetailscollapseSections] =
    useState(true);
  const [distanceDetails, setDistanceDetails] = useState(true);
  const [decisionAnalysis, setDecisionAnalysis] = useState([]);
  const [showEditQuestionsForm, setShowEditQuestionsForm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [collapseDecisionQuestionSections, setCollapseDecisionQuestionSections] = useState(true);

  const { user_data } = useSelector((state) => state.user);

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const columns = [
    {
      title: "Condition",
      dataIndex: "sanction_condition",
      width: 450,
      render: (text) => text || "-",
    },
    {
      title: "Remark",
      dataIndex: "sanction_condition_description",
      width: 350,
      render: (text) => text || "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 250,
      render: (status) => {
        if (!status) {
          return "No status available";
        } else {
          return (
            <>
              {status === "Completed" ? (
                <div className="credit">
                  <GoDotFill className="godotfill" />
                  <span>{status}</span>
                </div>
              ) : status === "Waived" ? (
                <div className="waived_status_div">
                  <GoDotFill className="godotfill" />
                  <span>{status}</span>
                </div>
              ) : (
                <div className="credit_red">
                  <GoDotFill className="godotfill" />
                  <span>{status}</span>
                </div>
              )}
            </>
          );
        }
      },
    },
  ];


  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        // Conditionally render edit and delete options only if status is not 'Completed'
        if (record.status !== "Completed") {
          return (
            <Space size="middle">
              <ConditionalRender
                userPermissions={user_data?.permissions}
                requiredPermissions={["EDITSANC"]}
              >
                <EditOutlined
                  className="internal-section-edit-button"
                  onClick={() => {
                    setSelectedSanction(record);
                    const sanction_condition_type =
                      record.sanction_condition_type;
                    showDrawer(sanction_condition_type);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender
                userPermissions={user_data?.permissions}
                requiredPermissions={["DELTSANC"]}
              >
                <DeleteOutlined
                  className="internal-section-delete-button"
                  onClick={() => {
                    setSelectedSanction(record);
                    setOpenModal(true);
                  }}
                />
              </ConditionalRender>
            </Space>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["AEMASTER", "DLMASTER"]
  );
  const Strenghtcolumns = [
    {
      title: "Sr. No.",
      render: (_, __, index) => index + 1,
      width: 200,
    },

    {
      title: "Description",
      dataIndex: "description",
      width: 600,
    },

  ];

  const authorizedStrengthColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITSTWK"]}
            >
              <EditOutlined
                className="internal-section-edit-button"
                onClick={() => {
                  setSelectedStrengthData(record);
                  showStrengthDrawer(); // Open StrenthForm drawer
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["DELTSTWK"]}
            >
              <DeleteOutlined
                className="internal-section-delete-button"
                onClick={() => {
                  setSelectedStrengthData(record);
                  setStrengthOpen(true);
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },
  ];

  const StreghttableColumns = authorizeTableColumns(
    Strenghtcolumns,
    authorizedStrengthColumns,
    user_data?.permissions,
    ["EDITSTWK", "DELTSTWK"]
  );
  const Weaknesscolumns = [
    {
      title: "Sr.No",
      render: (_, __, index) => index + 1,
      width: 200,
    },

    {
      title: "Description",
      dataIndex: "description",
      width: 600,
    },

  ];

  const authorizedWeaknessColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITSTWK"]}
            >
              <EditOutlined
                className="internal-section-edit-button"
                onClick={() => {
                  setSelectedStrengthData(record);
                  showWeaknessDrawer(true); // Open StrenthForm drawer
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["DELTSTWK"]}
            >
              <DeleteOutlined
                className="internal-section-delete-button"
                onClick={() => {
                  setSelectedStrengthData(record);
                  setStrengthOpen(true);
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },
  ];

  const WeaknesstableColumns = authorizeTableColumns(
    Weaknesscolumns,
    authorizedWeaknessColumns,
    user_data?.permissions,
    ["EDITSTWK", "DELTSTWK"]
  );

  const columns5 = [
    {
      title: "Sr.No",
      dataIndex: "no",
      render: (text, record, index) => {
        const obj = {
          children: index % 2 === 0 ? text : "",
          props: {},
        };

        if (index % 2 === 0) {
          obj.props.rowSpan = 2;
        } else {
          obj.props.rowSpan = 0;
        }

        return obj;
      },
    },

    {
      title: "Parameters",
      dataIndex: "parameters",
    },
    {
      title: "Text",
      dataIndex: "text",
    },
    {
      title: "Written By",
      dataIndex: "written_by",
    },


  ];

  const authorizedDecisionColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (text, record, index) => {
        const obj = {
          children: "",
          props: {},
        };

        if (index % 2 === 0) {
          obj.props.rowSpan = 2;
          obj.children = (
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["EDITDCQN"]}
            >
              <div
                className="decision-questions-edit-button"
                onClick={() => {
                  handleEditClick(record);
                  setSelectedQuestion(record?.id);
                }}
              >
                <EditOutlined style={{ color: "#4880FF", fontSize: "16px" }} />
              </div>
            </ConditionalRender>
          );
        } else {
          obj.props.rowSpan = 0;
        }

        return obj;
      },
    },
  ];

  const tableDecisionColumns = authorizeTableColumns(
    columns5,
    authorizedDecisionColumns,
    user_data?.permissions,
    ["EDITDCQN"]
  );

  const showDrawer = (type) => {
    setOpen(true);
    setSanctionType(type);
    setDrawerVisible(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedSanction(null);
  };

  const showDistanceDetailsDrawer = () => {
    setOpenDistanceDetails(true);
  };

  const onCloseDistanceDetails = () => {
    setOpenDistanceDetails(false);
  };

  const showDrawer1 = () => {
    setVisible(true);
  };

  const onClose1 = () => {
    setVisible(false);
  };

  const onCloseStrenthForm = () => {
    setShowEditChequeForm(false);
    setSelectedStrengthData(null);
  };

  const showStrengthDrawer = () => {
    setShowEditChequeForm(true);
  };
  const onCloseWeknessForm = () => {
    setShowWeaknessForm(false);
    setSelectedStrengthData(null);
  };

  const showWeaknessDrawer = () => {
    setShowWeaknessForm(true);
  };
  const toggleCollapse1 = () => {
    setCollapsedStrength(!collapsedStrength);
  };
  const toggleCollapse2 = () => {
    setCollapsedWeakness(!collapsedWeakness);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const response = await getSanctionDetailsByLoanId(loan_id);
        setInternalSanctions(response.data.Internal);
        setExternalSanctions(response.data.External);
      } catch (error) {
        // setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const fetchDistanceDetailsData = async () => {
      try {
        const response = await getDistanceDetailsByLoanId(loan_id);
        setDistanceDetails(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const decisionAnalysisData = async () => {
      try {
        const response = await getDecisionAnalysisByLoanId(loan_id);
        const data = response.data
          .map((item, index) => {
            return [
              {
                key: `${index + 1}-question`,
                no: index + 1,
                parameters: "Question",
                text: item.question,
                written_by: item.created_by.name,
                id: item.id,
              },
              {
                key: `${index + 1}-answer`,
                no: "",
                parameters: "Answer",
                text: item.answer,
                written_by: item.created_by.name,
                id: item.id,
              },
            ];
          })
          .flat();
        setDecisionAnalysis(data);
        console.log("111", setDecisionAnalysis);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
    fetchDistanceDetailsData();
    decisionAnalysisData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData]);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const handleEditClick = (record) => {
    setSelectedRecord(record); // Optional: Set the selected record if needed
    setShowEditQuestionsForm(true); // Open the Drawer
  };

  const onCloseQuestionForm = () => {
    setShowEditQuestionsForm(false); // Close the Drawer
  };

  console.log("111", drawerVisible);
  const deleteSanctionCondition = async () => {
    console.log("sanction");
    try {
      const response = await deleteSanctionConditionById(selectedSanction.id);

      if (response.status === 200 && response.success === true) {
        message.success("Sanction Condition deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedSanction(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const deleteStrength = async () => {
    console.log("jhugyfcgvhgjkmn");
    try {
      const response = await deleteStrengthById(selectedStrengthData.id);

      if (response.status === 200 && response.success === true) {
        const messageText =
          selectedStrengthData.key_type === "Strength"
            ? "Strength of the case deleted successfully"
            : "Weakness of the case deleted successfully";
        message.success(messageText);
        setStrengthOpen(false);
        toggleRefreshTableData();
        setSelectedStrengthData(null);
      }
    } catch (error) {
      setStrengthOpen(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    const fetchStrengthsData = async () => {
      try {
        const response = await getStrengthsOfTheCaseByLoanId(loan_id);
        setStrengthsData(response?.data?.strength);
        setWeaknessData(response?.data?.weakness);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchStrengthsData();
  }, [loan_id, refreshTableData]);

  return (
    <>
      <Drawer
        title={
          <Header
            title={selectedSanction ? "Edit" : "Add"}
            onClose={onClose}
            name={`${sanctionType} Sanction condition`}
          />
        }
        width={800}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <SanctionCondition
          refreshData={toggleRefreshTableData}
          id={selectedSanction && selectedSanction.id}
          open={open}
          closeForm={onClose}
          sanction_condition_type={sanctionType}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        visible={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteSanctionCondition();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>
      <Drawer
        title={<Header title="Edit" name={"Strength Of The Case"} onClose={onCloseStrenthForm} />}
        width={800}
        onClose={onCloseStrenthForm}
        open={showEditStrengthForm}
        closable={false}
      >
        <StrenthForm
          id={selectedStrengthData && selectedStrengthData?.id}
          closeForm={onCloseStrenthForm}
          open={showEditStrengthForm}
          refreshData={toggleRefreshTableData}
          loan_id={loan_id}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        visible={strengthopen}
        onOk={() => {
          setStrengthOpen(false);
          // deleteStrength();
          deleteStrength();
        }}
        onCancel={() => setStrengthOpen(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>

      <Drawer
        title={<Header title="Edit" name={"Weakness Of The Case"} onClose={onCloseWeknessForm} />}
        width={800}
        onClose={onCloseWeknessForm}
        open={showEditWeknessForm}
        closable={false}
      >
        <WeaknessForm
          id={selectedStrengthData && selectedStrengthData?.id}
          closeForm={onCloseWeknessForm}
          open={showEditWeknessForm}
          refreshData={toggleRefreshTableData}
          loan_id={loan_id}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          // deleteStrength();
          deleteSanctionCondition();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete?`}</p>
      </Modal>

      <div className="fade-in">
        <div className="tab_main_container ">
          <div className="credit-analysis-details-container">
            <div
              className={`credit_heading_text ${collapseSections ? "with-border" : "no-border"
                }`}
            >
              <div>Sanction Condition</div>
              <div className="sanction_condition_content">
                {collapseSections ? (
                  <UpOutlined
                    onClick={() => setCollapseSections(!collapseSections)}
                  />
                ) : (
                  <DownOutlined
                    onClick={() => setCollapseSections(!collapseSections)}
                  />
                )}
              </div>
            </div>

            {collapseSections && (
              <>
                <div>
                  <div
                    className="internal-sanction-content"
                  >
                    <div className="credit_heading_text" style={{ marginTop: "4px" }}>
                      Internal Sanctions
                    </div>
                    <div>
                      <div className="add-sanction-button">
                        <ConditionalRender
                          userPermissions={user_data?.permissions}
                          requiredPermissions={["POSTSANC"]}
                        >
                          <Button
                            icon={<PlusCircleOutlined />}
                            className="add-sanction-condition-button"
                            onClick={() => showDrawer("Internal")}
                          >
                            Add Sanction Condition
                          </Button>
                        </ConditionalRender>
                      </div>
                    </div>
                  </div>

                  <div className="creditanalysis-table-container">
                    <Table
                      className="credit_analysis_table"
                      columns={tableColumns}
                      dataSource={internalSanctions}
                      pagination={false}
                    />
                  </div>
                </div>

                <div>
                  <div className="external-sanction-content">
                    <div className="credit_heading_text">External Sanctions</div>
                    <div>
                      <div className="add-sanction-button">
                        <ConditionalRender
                          userPermissions={user_data?.permissions}
                          requiredPermissions={["POSTSANC"]}
                        >
                          <Button
                            icon={<PlusCircleOutlined />}
                            className="add-sanction-condition-button"
                            onClick={() => showDrawer("External")}
                          >
                            Add Sanction Condition
                          </Button>
                        </ConditionalRender>
                      </div>
                    </div>
                  </div>

                  <div className="creditanalysis-table-container">
                    <Table
                      className="credit_analysis_table"
                      columns={tableColumns}
                      dataSource={externalSanctions}
                      pagination={false}
                      style={{ marginBottom: "20px" }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="credit-analysis-details-container">
            <Deviation
              toggleRefreshTableData={toggleRefreshTableData}
              refreshTableData={refreshTableData}
            />
          </div>

          <div className="credit-analysis-details-container">
            <div
              className={`strength-of-the-case-content ${collapsedStrength ? "no-border" : "with-border"
                }`}
            >
              <div className="strenght_div_conatiner">
                <div className="credit_heading_text">Strengths of the Case</div>


                <div className="strength_div">
                  <div className="button-credit-container">


                    <ConditionalRender
                      userPermissions={user_data?.permissions}
                      requiredPermissions={["EDITSTWK"]}
                    >
                      <Button
                        icon={<PlusCircleOutlined />}
                        type="primary"
                        className="add_button_strenght"
                        onClick={() => {

                          showStrengthDrawer(); // Open StrenthForm drawer
                        }}
                      >Add Strength</Button>
                       <Button 
                        type="primary"
                        className="add_button_strenght"
                        // onClick={() => {

                        //   showStrengthDrawer(); // Open StrenthForm drawer
                        // }}
                      >Autofetch</Button>
                    </ConditionalRender>
                  </div>
                  <div className="toggle-button" onClick={toggleCollapse1} style={{ cursor: "pointer" }}>
                    {collapsedStrength ? <DownOutlined /> : <UpOutlined />}
                  </div>


                </div>

              </div>

            </div>
            <div className="table_container">
              {!collapsedStrength && (
                <Table
                  className="credit_analysis_strength_table"
                  columns={StreghttableColumns}
                  dataSource={strengthsData}
                  pagination={false}
                // style={{ marginBottom: "20px", borderBottom: "none" }}
                />
              )}
            </div>
          </div>

          <div className="credit-analysis-details-container">
            <div
              className={`weakness-of-the-case-content ${collapsedWeakness ? "no-border" : "with-border"
                }`}
            >
              <div className="">
                <div className="credit_heading_text">Weakness of the Case</div>
              </div>
              <div className="strength_div">
              <div className="button-credit-container-secondary">
                <ConditionalRender
                  userPermissions={user_data?.permissions}
                  requiredPermissions={["EDITSTWK"]}
                >
                  <Button
                    icon={<PlusCircleOutlined />}
                    type="primary"
                    className="add_button_strenght"
                    onClick={() => {

                      showWeaknessDrawer(true); // Open StrenthForm drawer
                    }}
                  >Add Weakness</Button>   
 
                <Button 
                        type="primary"
                        className="add_button_strenght"
                        // onClick={() => {

                        //   showStrengthDrawer(); // Open StrenthForm drawer
                        // }}
                      >Autofetch</Button>
                </ConditionalRender>
                </div>
                <div className="toggle-button" onClick={toggleCollapse2} style={{ cursor: "pointer" }}>
                  {collapsedWeakness ? <DownOutlined /> : <UpOutlined />}
                </div>
              </div>
            </div>
            <div className="table_container">
              {!collapsedWeakness && (
                <Table
                  className="credit_analysis_strength_table"
                  columns={WeaknesstableColumns}
                  dataSource={weaknessData}
                  pagination={false}
                />
              )}
            </div>
          </div>
          <div className="loan_distance_container">
            <Drawer
              title={
                <Header
                  title="Edit"
                  onClose={onCloseDistanceDetails}
                  name={`Distance Details`}
                />
              }
              width={970}
              onClose={onCloseDistanceDetails}
              open={openDistanceDetails}
              closable={false}
            >
              <DistanceDetailsForm
                refreshData={toggleRefreshTableData}
                id={loan_id}
                open={openDistanceDetails}
                closeForm={onCloseDistanceDetails}
              />
            </Drawer>

            <div
              className={`distance-details-content ${distanceDetailscollapseSections ? "no-border" : ""
                }`}
            >
              <div className="credit_heading_text">Distance Details</div>
              <div className="distance-details-buttons">
                <ConditionalRender
                  userPermissions={user_data?.permissions}
                  requiredPermissions={["AEDSTNCE"]}
                >
                  <div
                    className="tab_form_sub_edit"
                    onClick={() => {
                      showDistanceDetailsDrawer();
                      // setSelectedCustomer(id);
                      // setSelectedPerson(person);
                    }}
                  >
                    <img src={edit} alt="Edit Button" />
                  </div>
                </ConditionalRender>
                {distanceDetailscollapseSections ? (
                  <UpOutlined
                    onClick={() =>
                      setDistanceDetailscollapseSections(
                        !distanceDetailscollapseSections
                      )
                    }
                  />
                ) : (
                  <DownOutlined
                    onClick={() =>
                      setDistanceDetailscollapseSections(
                        !distanceDetailscollapseSections
                      )
                    }
                  />
                )}
              </div>
            </div>

            {distanceDetailscollapseSections && (
              <div className="distance-detail-table-wrapper">
                <div className="distance-detail-table">
                  <div className="distance_left_headings">
                    <div className="distance_details_headings">
                      <div className="distance_details_heading">
                        Distance From One Location To Another
                      </div>
                    </div>
                    <div className="distance_left_heading left_heading">
                      From Branch to Collateral
                    </div>
                    <div className="distance_left_heading left_heading ">
                      From Branch to Business Premises
                    </div>
                    <div className="distance_left_heading  ">
                      From Branch to Current Premises
                    </div>
                    <div className="distance_left_heading">
                      From Current Residence to Collateral
                    </div>
                    <div className="distance_left_heading_last_row">
                      From Current Business Premises to Collateral
                    </div>
                  </div>

                  <div className="distance_left_headings">
                    <div className="distance_details_headings">
                      <div className="distance_details_heading">
                        User Calculated Distance(in KM)
                      </div>
                    </div>
                    <div className="distance_left_heading">
                      {distanceDetails?.user_distance?.from_branch_to_collateral ||
                        "-"}
                    </div>
                    <div className="distance_left_heading">
                      {distanceDetails?.user_distance
                        ?.from_branch_to_business_premises || "-"}
                    </div>
                    <div className="distance_left_heading">
                      {distanceDetails?.user_distance
                        ?.from_branch_to_current_premises || "-"}
                    </div>
                    <div className="distance_left_heading">
                      {distanceDetails?.user_distance
                        ?.from_current_residence_to_collateral || "-"}
                    </div>
                    <div className="distance_left_heading_last_row">
                      {distanceDetails?.user_distance
                        ?.from_current_business_premises_to_collateral || "-"}
                    </div>
                  </div>

                  <div className="distance_left_headings">
                    <div className="distance_details_headings">
                      <div className="distance_details_heading">
                        System Calculated Distance (in KM)
                      </div>
                    </div>
                    <div className="distance_left_heading">
                      {distanceDetails?.system_distance
                        ?.from_branch_to_collateral || "-"}
                    </div>
                    <div className="distance_left_heading">
                      {distanceDetails?.system_distance
                        ?.from_branch_to_business_premises || "-"}
                    </div>
                    <div className="distance_left_heading">
                      {distanceDetails?.system_distance
                        ?.from_branch_to_current_premises || "-"}
                    </div>
                    <div className="distance_left_heading">
                      {distanceDetails?.system_distance
                        ?.from_current_residence_to_collateral || "-"}
                    </div>
                    <div className="distance_left_heading_last_row">
                      {distanceDetails?.system_distance
                        ?.from_current_business_premises_to_collateral || "-"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="credit-analysis-details-container">
            <div
              className={`decision-questions-content ${collapseDecisionQuestionSections ? "with-border" : "no-border"
                }`}
            >
              <div className="credit_heading_text_decision_questions">Decision Questions</div>
              <div>
                <Drawer
                  title={<Header title="Edit" name={"Decision Questions"} onClose={onCloseQuestionForm} />}
                  width={900}
                  onClose={onCloseQuestionForm}
                  open={showEditQuestionsForm} // Use state to control visibility
                  closable={false}
                >
                  <DecisionQuestionsForm
                    refreshData={toggleRefreshTableData}
                    open={showEditQuestionsForm}
                    closeForm={onCloseQuestionForm}
                    selectedQuestion={selectedQuestion} // Pass selected question ID to the form
                    selectedRecord={selectedRecord}
                  />
                </Drawer>
                <Drawer
                  title={<Header title="Add" name={"Decision Questions"} onClose={onClose1} />}
                  width={900}
                  onClose={onClose1}
                  open={visible}
                  closable={false}
                >
                  <DecisionQuestionAddForm
                    refreshData={toggleRefreshTableData}
                    open={visible}
                    closeForm={onClose1}
                    loan_id={loan_id}
                  />
                </Drawer>

                <div className="decision-question-button" >
                  <ConditionalRender
                    userPermissions={user_data?.permissions}
                    requiredPermissions={["POSTDCQN"]}
                  >
                    <Button
                      icon={<PlusCircleOutlined />}
                      className="add-sanction-condition-button"
                      onClick={showDrawer1}
                    >
                      Add Decision Question
                    </Button>
                  </ConditionalRender>
                  {collapseDecisionQuestionSections ? (
                    <UpOutlined
                      onClick={() => setCollapseDecisionQuestionSections(!collapseDecisionQuestionSections)}
                    />
                  ) : (
                    <DownOutlined
                      onClick={() => setCollapseDecisionQuestionSections(!collapseDecisionQuestionSections)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="table_container">
              {collapseDecisionQuestionSections && (
                <>
                  <Table
                    className="credit_analysis_strength_table"
                    columns={tableDecisionColumns}
                    dataSource={decisionAnalysis}
                    pagination={false}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreaditAnalysis;
