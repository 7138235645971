import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import React, { useState, useEffect } from "react";
import { postDeviationList, updateDeviationList } from "../services/getDeviation"; // Assuming updateDeviationList is the function to update data
import ErrorMessage from "../../errorHandling/ErrorMessage";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  actual_description: Yup.string()
    .required("Actual deviation is required")
    .label("Actual Deviation"),
    mitigates: Yup.string()
    .required("Mitigates is required")
    .label("Mitigates"),
    deviation:Yup.string()
    .required("Deviation is required")
    .label("Deviation"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};


const ManualDeviationForm = ({toggleRefreshTableData, selectedManual,setManualDataValue, deviationList, loan_id, initialValues, onClose }) => {
  const [displayNameSynced, setDisplayNameSynced] = useState(true);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  if (selectedManual && initialValues) {
    form.setFieldsValue({
      ...initialValues,
      deviation: initialValues?.deviation?.id,
    });
  } else {
    form.resetFields(); // Reset form when form is not for editing
  }
}, [selectedManual, initialValues, form]);

const closeForm = () => {
  form.resetFields(); // Clear form fields on cancel
  onClose();
};

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ actual_description: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ mitigates: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true); // Reset sync state if display_name is cleared
    } else {
      setDisplayNameSynced(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = { ...values, loan_detail: loan_id };
  
      if (initialValues) {
        // Update existing data
        const response = await updateDeviationList(values, initialValues.id);
        if (response.status === 200 && response.success === true) {
          message.success("Manual Deviation Updated Successfully");
          setManualDataValue();
          toggleRefreshTableData();
        } else {
          message.error("Failed to update Manual Deviation.");
        }
      } else {
        // Create new data
        const response = await postDeviationList(data, loan_id);
        if (response.status === 201 && response.success === true) {
          message.success("Manual Deviation Created Successfully");
          toggleRefreshTableData();
        } else {
          message.error("Failed to create Manual Deviation.");
        }
      }
  
      form.resetFields();
      onClose();
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
    );
    }finally{  
      setLoading(false)
    }
  };
  

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        actual_description: "",
        mitigates: "",
        deviation:undefined,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="actual_description"
            label="Description"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Description"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="mitigates" label="Mitigates" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Mitigates"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
           name="deviation" 
           label="Deviation" 
           rules={[yupSync]} 
          required>
            <Select placeholder="Please Select" allowClear>
              {deviationList?.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default ManualDeviationForm;
