import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Modal, Spin } from "antd";
import vastu_logo from "../assets/vastulogo.png";
import google_icon from "../assets/google_icon.png";
import login_image from "../assets/login_image.png";
import { useGoogleLogin } from "@react-oauth/google";
import baseConfig from "../../../config/baseConfig";
import "../styles/login.css";
import { loginUserService } from "../services/loginService";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLocation, setUser } from "../../../redux/userSlice";
import { ErrorMessage } from "formik";
import { getUser } from "../../dashboard/services/homeService";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [locationGranted, setLocationGranted] = useState(false);
  const [loading, setLoading] = useState(false);
  const latitude = useSelector((state) => state?.user?.user_location?.latitude);
  const longitude = useSelector(
    (state) => state?.user?.user_location?.longitude
  );

  useEffect(() => {
    // Set the document title when the component mounts
    document.title = "Vastu LMS | Login";

    const locationPermission = localStorage.getItem("locationPermission");

    if (!locationPermission) {
      setIsModalVisible(true);
    } else if (locationPermission === "granted") {
      setLocationGranted(true);
    } else if (locationPermission === "denied") {
      setIsModalVisible(true);
    }
    return () => {
      document.title = "LMS";
    };
  }, []);

  const checkForRedirectionPath = async () => {
    try {
      setLoading(true);
      const redirectPath = location.state?.from?.pathname || "/";
      const response = await getUser();
      if (response.success === true) {
        const userData = response.data;
        dispatch(setUser(userData));
      }
      setLoading(false);
      return redirectPath;
    } catch (error) {
      setLoading(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const onFinish = async (values) => {
    try {
      const updatedValues = {
        ...values,
        latitude: latitude,
        longitude: longitude,
        time_sheet_event_type: "MTSETNNH",
      };
      const response = await loginUserService(updatedValues);
      localStorage.setItem("access", response?.data?.access);
      localStorage.setItem("refresh", response?.data?.refresh);

      const redirectTo = await checkForRedirectionPath();
      message.success("Logged In successfully");

      navigate(redirectTo);
    } catch (error) {
      if (error.response?.data?.message) {
        message.error(error.response?.data?.message);
      } else {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  const handleGoogleLogin = async (respone) => {
    try {
      const response = await axios.get(
        `${baseConfig.serverurl}/api/v1/userauth/auth/login/google/?token=${respone?.access_token}&latitude=${latitude}&longitude=${longitude}`
      );
      localStorage.setItem("access", response?.data?.data?.access);
      localStorage.setItem("refresh", response?.data?.data?.refresh);
      const redirectTo = await checkForRedirectionPath();
      message.success("Logged In successfully");
      navigate(redirectTo);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleLogin(tokenResponse),
  });

  const handleOk = () => {
    setIsModalVisible(false);
    // Request location permission
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(fetchUserLocation({ latitude, longitude }));
          setLocationGranted(true);
          // Save permission granted in local storage
          localStorage.setItem("locationPermission", "granted");
          message.success("Location permission granted...");
        },
        (error) => {
          message.error("Location permission denied...");
          // Save permission denied in local storage
          localStorage.setItem("locationPermission", "denied");
        }
      );
    } else {
      message.error("Geolocation is not supported by this browser.");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    message.info("Location permission not granted.");
    // Save permission denied in local storage
    localStorage.setItem("locationPermission", "denied");
  };
  return (
    <>
      {loading && (
        <div className="full-screen-loader-login">
          <Spin size="large" />
        </div>
      )}
      <div className="login_form_main_container">
        <div className="form_control_container">
          <div className="form_section">
            <Form
              name="loginForm"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <div className="form_content">
                <div className="header_section">
                  <img src={vastu_logo} alt="Logo" />
                  <div className="welcome_header_section">
                    <h2>Welcome back</h2>
                    <p className="welcome_back_heading">
                      Welcome back! Please enter your details.
                    </p>
                  </div>
                </div>
                <div className="input_section">
                  <div className="form_input_section">
                    <div className="input_wrapper">
                      <label>Email</label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Email is required",
                          },
                          {
                            type: "email",
                            message: "Invalid email format",
                          },
                        ]}
                      >
                        <Input
                          style={{
                            height: "38px",
                          }}
                          placeholder="Enter your email"
                        />
                      </Form.Item>
                    </div>
                    <div className="input_wrapper">
                      <label>Password</label>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Password is required",
                          },
                        ]}
                      >
                        <Input.Password
                          style={{
                            height: "38px",
                          }}
                          placeholder="Enter your password"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="login_singup_google">
                    <div className="form-check-and-button">
                      <div className="centered-content">
                        <Form.Item name="remember">
                          <Checkbox>Remember me for 30 days</Checkbox>
                        </Form.Item>
                        {/* <a href="/">Forgot Password</a> */}
                        <Link to="/forget-password">Forgot Password</Link>
                      </div>
                    </div>

                    <div className="form_action_section">
                      <Button
                        style={{
                          padding: "10px 18px",
                          height: "44px",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                        classNames="sign_in_button"
                        type="primary"
                        disabled={!locationGranted}
                        htmlType="submit"
                        className="w-100 mb-2"
                      >
                        Sign in
                      </Button>
                      {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                      <Button
                        onClick={() => googleLogin()}
                        style={{
                          marginTop: "16px",
                          height: "44px",
                        }}
                        variant="light"
                        disabled={!locationGranted}
                        className="sign_with_google_btn w-100 "
                      >
                        <img src={google_icon} alt="Google Icon" />
                        Sign in with Google
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>

          <div className="footer_section">
            <p>© {currentYear} Vastu Housing Finance Corporation ltd</p>
          </div>
        </div>

        <div className="image_control_container">
          <img
            className="right_side_heroImage_wrapper"
            src={login_image}
            alt="Login"
          />
        </div>
      </div>
      <Modal
        title="Location Permission"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Allow"
        cancelText="Deny"
      >
        <p>
          We would like to access your location to provide better services. Do
          you allow us to access your location?
        </p>
      </Modal>
    </>
  );
};

export default Login;
