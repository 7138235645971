import React, { useEffect, useState } from "react";
import {
  DownOutlined,
  UpOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Space, Table, message } from "antd";
import Header from "../../../layout/views/Header";
import IdentificationDocumentsEditForm from "./IdentificationDocumentsEditForm";
import { getIdentificationDocumentsByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { deleteIdentificationDocumentsByIdService } from "../../services/applicantRvServices";

const IdentificationDocuments = ({ selectedCustomerId }) => {
  const [openEditFour, setOpenEditFour] = useState(false);
  const [documentsData, setDocumentsData] = useState(null);
  const [collapsed4, setCollapsed4] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState(null);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getIdentificationDocumentsByCustomerId(selectedCustomerId);
        setDocumentsData(reponse?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData,selectedCustomerId]);
  const toggleCollapse4 = () => {
    setCollapsed4(!collapsed4);
  };
  const showDrawerFour = () => {
    setOpenEditFour(true);
  };
  const onCloseFour = () => {
    setOpenEditFour(false);
    setSelectedDocuments(null)
  };

  const deleteDocument = async () => {
    try {
      const response = await deleteIdentificationDocumentsByIdService(selectedDocuments.id);
      if (response.status === 200 && response.success === true) {
        message.success("Identification document deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedDocuments(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  const columns1 = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Identification Document",
      dataIndex: "document_type",
      render: (document_type) => {
        return document_type ? document_type?.document_name : " - ";
      },
    },
    {
      title: "Details",
      dataIndex: "document_number",
      render: (document_number) => {
        return document_number ? document_number : " -";
      },
    },

    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              
              className="edit_icon_rv"
              onClick={() => {
                setSelectedDocuments(record);
                showDrawerFour();
              }}
            />
            <DeleteOutlined
            
              className="delete_icon_rv"
              onClick={() => {
                setSelectedDocuments(record);
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <div className="indentification_container" >
      <Drawer
        title={
          <Header
            title="Edit"
            onClose={onCloseFour}
            name="Document Identification"
          />
        }
        width={970}
        onClose={onCloseFour}
        open={openEditFour}
        closable={false}
      >
        <IdentificationDocumentsEditForm
          open={openEditFour}
          id={selectedDocuments?.id}
          // loan_id={loan_id}
          closeForm={onCloseFour}
          refreshData={toggleRefreshTableData}
        // customerDetails={documentsData}
        />
      </Drawer>
      <div className="heading_rv_identification_document" >
        <div className="property_description_data">
          Identification Document
        </div>
        <div >
          <div
          className="identification_toggle"
            
            onClick={toggleCollapse4}
          >
            {collapsed4 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed4 && (
        <div className="table_container table_document_rv">
          <Table
            columns={columns1}
            dataSource={documentsData}
            pagination={false}
            
          />
        </div>
      )}
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteDocument()
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this document?`}</p>
      </Modal>
    </div>
  );
};

export default IdentificationDocuments;
