
import {axiosRequest} from "../../../../../utils/api/axiosRequest"


export const getPropertyDetailsById = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-detail/property-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updatePropertyById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/property-detail/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteDocumentDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/loan/property-detail/property-document/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getLandTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choice/land-type`);  
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listConstructionStage  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/construction-stage-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listOwnership  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/ownership-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listAreaCategory  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/area-category-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listCity  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/city/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listTaluka = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/taluka/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listDistrict  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/district/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listPincode  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/pincode/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listState  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/state/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listCountry  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/country/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const pincodeCodeService = (pincode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/pincode/get-data-by-pincode/?name=${pincode}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listPropertyType  = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/property-type/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listDocumentType = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/document-type/list`, {
        params: {
          property_doc_cat_code: true, // Adding the query param
          ...params,
        },
      });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const getSalesPropertyDetailsById = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-detail/property-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
  
export const postSalesProperty = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/loan/property-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const postSalesPropertyDocument = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/loan/property-document-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getSalesPropertyDetailsDocumentById = (property_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/property-document-detail/get-property-id-document/${property_id}?get_inactive=True`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteSalesPropertyDetailsDocumentById = (property_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`api/v1/loan/property-document-detail/${property_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
