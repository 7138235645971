import React from "react";
import { Table, Typography, Button, Space, Row, Col } from "antd";
import { EditOutlined } from "@ant-design/icons";

const CersaiView = () => {
  const { Title ,Text} = Typography;

  // Sample data for the Customer and Branch tables

  const securityInterestData = [
    {
      key: "1",
      assetId: "200084413315",
      securityInterestId: "400082615762",
      creationDate: "08-08-2024",
      transactionReference: "200316506550",
      satisfactionDate: "0000-00-00",
    },
  ];

  const customerData = [
    {
      key: "1",
      srNo: "1",
      name: "Mr Guru Charan Kashyap",
      pan: "AXPFK1102P",
      ckyc: "",
      address: "Please Update Address",
    },
    {
      key: "2",
      srNo: "2",
      name: "Mrs Meena Devi",
      pan: "GFJPD2520C",
      ckyc: "",
      address: "Please Update Address",
    },
  ];

  const branchData = [
    {
      key: "1",
      branchCode: "LUC001",
      branchName: "LUCKNOW",
      branchAddress: {
        line1: "B-3/46 47 Vibhuti Khand Gomti Nagar Lucknow-226010",
        state: "Uttar Pradesh",
        city: "NA",
        district: "NA",
        pinCode: "226010",
      },
    },
  ];

  const securityInterestColumn = [
    {
      title: "Asset Id",
      dataIndex: "assetId",
      key: "assetId",
    },
    {
      title: "Security Interest Id",
      dataIndex: "securityInterestId",
      key: "securityInterestId",
    },
    {
      title: "Security Interest Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
    },
    {
      title: "Transaction Reference Number",
      dataIndex: "transactionReference",
      key: "transactionReference",
    },
    {
      title: "Security Satisfaction Date",
      dataIndex: "satisfactionDate",
      key: "satisfactionDate",
    },
  ];

  // Columns for Customer Details Table
  const customerColumns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "PAN/CIN/CKYC No.",
      key: "panCkyc",
      render: (_, record) => (
        <div>
          <div>PAN: {record.pan}</div>
          <div>CKYC No.: {record.ckyc || "NA"}</div>
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => <span style={{ color: "red" }}>{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: () => <Button icon={<EditOutlined />} type="link"></Button>,
    },
  ];

  // Columns for Branch Details Table
  const branchColumns = [
    {
      title: "Branch Code",
      dataIndex: "branchCode",
      key: "branchCode",
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: "Branch Address",
      key: "branchAddress",
      render: (_, record) => (
        <div>
          <div>{record.branchAddress.line1}</div>
          <div>State: {record.branchAddress.state}</div>
          <div>City: {record.branchAddress.city}</div>
          <div>District: {record.branchAddress.district}</div>
          <div>Pin Code: {record.branchAddress.pinCode}</div>
        </div>
      ),
    },
  ];

  // Property Details Table setup as previously coded
  const propertyData = [
    {
      key: "1",
      propertyType: "INDEPENDENT HOUSE / ROW HOUSE (1)",
      plotNo: ["525 B", "NA", "NA"],
      buildArea: "600 Sq.Ft.",
      address: {
        projectName: "NA",
        line1: "House No",
        line2: "Tehsil-Bakshi Ka Talab",
        locality: "Near Study Zone School",
        area: "Lucknow",
        district: "Lucknow",
        state: "Uttar Pradesh (033)",
        pinCode: "200121",
      },
    },
  ];

  const propertyColumns = [
    {
      title: "Property Type",
      dataIndex: "propertyType",
      key: "propertyType",
    },
    {
      title: "Plot No",
      dataIndex: "plotNo",
      key: "plotNo",
      render: (plotNo) => (
        <div>
          {plotNo.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Carpet Build Up Area",
      dataIndex: "buildArea",
      key: "buildArea",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (
        <div>
          <div>Project Name: {address.projectName}</div>
          <div>Line 1: {address.line1}</div>
          <div>Line 2: {address.line2}</div>
          <div>Locality: {address.locality}</div>
          <div>Area: {address.area}</div>
          <div>District: {address.district}</div>
          <div>State: {address.state}</div>
          <div>Pin Code: {address.pinCode}</div>
        </div>
      ),
    },
  ];

  return (
    <div>

<Title level={3}>Security Interest Details</Title>
      <Table
        dataSource={securityInterestData}
        columns={securityInterestColumn}
        pagination={false}
        bordered
        style={{ marginBottom: 16 }}
      />
      
      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          <Text strong>Security Interest Creation File:</Text>{" "}
          <Text>CRS_F0185_012039_20240808183550.DAT</Text>
        </Col>
      </Row>
      
      <Row style={{ marginTop: 8 }}>
        <Col span={24}>
          <Text strong>Security Interest Response File:</Text>{" "}
          <Text>
            CERSAI_Batch_Report_Download_200316493231_2024_08_09_10_13_51_267f1124ee9a04346f300bb9567f4aa80f.Xls
          </Text>
        </Col>
      </Row>
      
      <Row style={{ marginTop: 8 }}>
        <Col span={24}>
          <Text strong>Security Satisfaction Creation File:</Text>{" "}
          <Text>N/A</Text>
        </Col>
      </Row>
      
      <Row style={{ marginTop: 8 }}>
        <Col span={24}>
          <Text strong>Security Satisfaction Response File:</Text>{" "}
          <Text>N/A</Text>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Title level={5}>Property Details</Title>
        <Button type="primary" 
        style={{ backgroundColor: "#00894" }}>
          Add Cersai
        </Button>
      </Space>
      <Table
        dataSource={propertyData}
        columns={propertyColumns}
        pagination={false}
        bordered
      />

      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Title level={5}>Customer Details</Title>
      </Space>
      <Table
        dataSource={customerData}
        columns={customerColumns}
        pagination={false}
        bordered
        style={{ marginBottom: 24 }}
      />

      <Space
        direction="horizontal"
        align="center"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Title level={5}>Branch Details</Title>
      </Space>
      <Table
        dataSource={branchData}
        columns={branchColumns}
        pagination={false}
        bordered
        style={{ marginBottom: 24 }}
      />
    </div>
  );
};

export default CersaiView;
