import React, { useEffect, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import EditButton from "../../../../utils/editButton/EditButton";
import { Drawer, message } from "antd";
import Header from "../../../layout/views/Header";
import OthersEditForm from "./OthersEditForm";
import { getOthersDetailsByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import Loading from "../../../../utils/loading/Loading";
import CustomNoData from "../../../../utils/noDataIcon/CustomNoData";
const Others = ({ selectedCustomerId }) => {
  const [openEditEight, setOpenEditEight] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [collapsed8, setCollapsed8] = useState(true);
  const [othersData, setOthersData] = useState(null);
  const [loading, setLoading] = useState(true);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getOthersDetailsByCustomerId(selectedCustomerId);
        setOthersData(reponse?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData,selectedCustomerId]);
  const toggleCollapse8 = () => {
    setCollapsed8(!collapsed8);
  };
  const showDrawerEight = () => {
    setOpenEditEight(true);
  };
  const onCloseEight = () => {
    setOpenEditEight(false);
  };
  const renderEditButtonEight = () => {
    return (
      <div
        className="tab_form_sub_edit"
        onClick={() => {
          showDrawerEight();
        }}
      >
        <EditButton />
      </div>
    );
  };
  return (
    <div className="fi_Views_main_container">
      <Drawer
        title={
          <Header title="Edit" onClose={onCloseEight} name="Others" />
        }
        width={970}
        onClose={onCloseEight}
        open={openEditEight}
        closable={false}
      >
        <OthersEditForm
          updateId={othersData?.id}
          // loan_id={loan_id}
          customerDetails={othersData}
          open={openEditEight}
          refreshData={toggleRefreshTableData}
          closeForm={onCloseEight}
        />
      </Drawer>
      <div className="fade-in">
        {loading ? (
          <Loading loading={loading} /> // Loading component while data is fetching
        ) : othersData ? (
<>
      <div className="fi_views_description_heading">
        <div className="fi_views_heading_name">Others</div>
        <div className="fi_views_button_space">
          <div
           className="fi_views_edit_button"
          >
            {renderEditButtonEight()}
          </div>
          <div
          className="fi_views_collapse_icon"
            onClick={toggleCollapse8}
          >
            {collapsed8 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed8 && (
        <div>
          <>
            <div className="fi_description_basic_card_container">
              <div className="property_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Name Plate
                  </div>
                  <div className="property_description_basic_card_data">
                    {othersData?.name_plate ||
                      "-"}
                  </div>
                </div>
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Accessibility
                  </div>
                  <div className="property_description_basic_card_data">
                    {othersData?.accessibility ||
                      "-"}
                  </div>
                </div>
              </div>

              <div className="property_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Society Board:
                  </div>
                  <div className="property_description_basic_card_data">
                    {othersData?.society_board ||
                      "-"}
                  </div>
                </div>
              </div>
              <div className="property_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Negative Area
                  </div>
                  <div className="property_description_basic_card_data">
                    {othersData?.is_negative_area === true
                      ? "Yes"
                      : othersData?.is_negative_area ===
                        false
                        ? "No"
                        : "-"}
                  </div>
                </div>
              </div>
              <div className="property_description_basic_card">
                <div className="property_description_basic_card-content">
                  <div className="property_description_basic_card_label">
                    Political Contacts:
                  </div>
                  <div className="property_description_basic_card_data">
                    {othersData?.has_political_contact === true
                      ? "Yes"
                      : othersData?.has_political_contact ===
                        false
                        ? "No"
                        : "-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
       </>
      ) : (
        <div className="no_data_found_message">
           <CustomNoData />
        </div>
      )}
    </div>
    </div>
  );
};

export default Others;
