import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import { createIcicInsuranceService, getIcicInsuranceByIdService, listQuestionTypeService, updateIcicInsuranceByIdService } from "../services/iciciInsurance";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  question: Yup.string()
    .required("Question is required")
    .label("Question"),
    question_type: Yup.string()
    .required("Question type is required")
    .label("Question"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const IcicInsuranceQuastionerForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [displayNameSynced, setDisplayNameSynced] = useState(true);
  const [questionType, setQuestionType] = useState([])
  const [form] = Form.useForm();

  const getQuestionData = async () => {
    if (id) {
      try {
        const response = await getIcicInsuranceByIdService(id);
        const { name, code, display_name, question, question_type, is_active, is_verified } =
          response?.data;
        form.setFieldsValue({
          name,
          code,
          display_name,
          question,
          question_type,
          is_active,
          is_verified,
        });
        setDisplayNameSynced(true);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };


  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updateIcicInsuranceByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("ICICI Questionnaire successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createIcicInsuranceService(values);
        if ((response.status = 201 && response.success)) {
          message.success("ICICI Questionnaire successfully created");
          refreshData();
          closeForm();
        }
      }
      setDisplayNameSynced(true);
    } catch (error) {
      ;
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };


  // Fetch department data when the form is initially opened or for editing
  useEffect(() => {
    if (open) {
      // If it's opened for editing, fetch department data
      getQuestionData();
    } else {
      form.resetFields();
      setDisplayNameSynced(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };


  const fetchQuestiontype = async () => {
    try {
      const response = await listQuestionTypeService();
     
      setQuestionType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {

    fetchQuestiontype();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);



  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        question: "",
        is_active: true,
        is_verified: true,
      }}
    >


      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="question_type"
            label="Question Type"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please Select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {questionType?.map((status) => (
                <Option key={status} value={status}>
                  {status}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="question" label="Question" rules={[yupSync]} required>
            <Input placeholder="Please Enter question"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input placeholder="Please Enter Code" onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, ""); }} />
            </Form.Item>
          </Col>
        ) : null}


        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default IcicInsuranceQuastionerForm;
