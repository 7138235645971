import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, Select, Input, Button, Table, Space, message, Row, Col, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../styles/incomeDetails.css";
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { createIncomeFormCam, getIncomeCustomerDataByCustomerId, getCustomersByLoanId, listMonthYear, updateIncomeFormByCustomerId, deleteIncomeDetailsByIdService } from '../services/incomeDetailsServices';


const IncomeCashForm = ({ closeForm, open, refreshData, id }) => {
    const [form] = Form.useForm();
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [allMonthYear, setAllMonthYear] = useState([]);
    const [getCustomerId, setCustomerId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const handleChange = (namePath, value) => {
        form.setFieldsValue({ [namePath]: value });
    };
    const { encrypted_loan_id } = useParams();

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }

 

    const getCashData = async () => {
        if (id) {
            try {
                const response = await getIncomeCustomerDataByCustomerId(id);
                let { customer_detail, data } = response?.data;
                setCustomerId(customer_detail?.customer_id)
                // const customerName = getCustomerNameById(customer_detail);
                form.setFieldsValue({
                    customer: customer_detail?.customer_id,
                    salaryDetails: data.map(item => ({
                        id: item.id,
                        month_of_salary: item.month_of_salary,
                        monthly_net_salary_cash: item.monthly_net_salary_cash,
                        rental_income_cash: item.rental_income_cash,
                        rental_income_non_cash: item.rental_income_non_cash,
                        dividend_or_interest: item.dividend_or_interest,
                        agricultural_income: item.agricultural_income,
                    }))
                });
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        }
    };

    useEffect(() => {
        const fetchCustomersByLoanId = async () => {
            try {
                const response = await getCustomersByLoanId(loan_id);
                const customers = response?.data?.customer_list?.map((customer) => {
                    return {
                        id: customer.id,
                        name: `${customer.name} (${customer.customer_type})`,
                    };
                });
                setCustomerList(customers);

                // setLoading(false);
            } catch (error) {
                // setLoading(false);
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        const getMonthYear = async () => {
            try {
                const response = await listMonthYear();
                setAllMonthYear(response.data);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };
        fetchCustomersByLoanId();
        getMonthYear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleCustomerchange = (value) => {
        setSelectedCustomer(value);
    };

  
  

    const handleSubmit = async (values) => {
        try {

            if (id) {
                const payload = {
                    customer_detail: getCustomerId,
                    loan_detail: loan_id,
                    data: values.salaryDetails.map((item) => ({
                        id: item.id,
                        month_of_salary: item.month_of_salary,
                        monthly_net_salary_cash: item.monthly_net_salary_cash,
                        rental_income_cash: item.rental_income_cash,
                        rental_income_non_cash: item.rental_income_non_cash,
                        dividend_or_interest: item.dividend_or_interest,
                        agricultural_income: item.agricultural_income,
                    })),
                };
                const response = await updateIncomeFormByCustomerId(payload);
                if ((response.status = 200 && response.success)) {
                    message.success("Income details of non cash successfully updated");
                    form.resetFields();
                    refreshData();
                    closeForm();
                }
            } else {
                const payload = {
                    loan_detail: loan_id,
                    customer_detail: selectedCustomer,
                    data: values.salaryDetails,         // Use the salaryDetails from the form values
                };

                const response = await createIncomeFormCam(payload);
                if ((response.status = 200 && response.success)) {
                    message.success("Income details of non cash successfully created");
                    form.resetFields();
                    closeForm();
                    refreshData();
                }
            }


        }
        catch (error) {
            message.error(
                ErrorMessage(error?.response?.status, error?.response?.data?.message)
            );
        }
    };

    useEffect(() => {
        if (open) {
            getCashData();

        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, id]);

    const handleDelete = async () => {
        try {
            // Call the service to delete the record from the backend
            const response = await deleteIncomeDetailsByIdService(selectedRecord.id);
            if (response.status === 200 && response.success === true) {
                message.success("Record deleted successfully");
                setOpenModal(false);
                getCashData();
                refreshData();
            }
        } catch (error) {
            setOpenModal(false);
            message.error(error.response.data.message);
        }
    };

    return (
        <>
            <Form
                form={form}
                name="salary-details-form"
                layout="vertical"
                initialValues={{
                    salaryDetails: [{ month_of_salary: null, monthly_net_salary_cash: null, rental_income_cash: null, rental_income_non_cash: null, dividend_or_interest: null, agricultural_income: null }],
                }}
                onFinish={handleSubmit}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="customer" label="Customer" required
                         rules={[{ required: true, message: 'Please select a customer' }]} >
                            <Select
                                value={selectedCustomer}
                                onChange={handleCustomerchange}
                                style={{ width: "300px" }}
                                placeholder="Please Select Customer"
                                disabled={!!id}
                            >
                                {customerList.length > 0 &&
                                    customerList.map((customer) => {
                                        return (
                                            <Select.Option value={customer.id}>{customer.name}</Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="salaryDetails">
                    {(fields, { add, remove }) => (
                        <>
                            <Table
                                columns={[
                                    {
                                        title: 'SR.NO',
                                        dataIndex: 'key',
                                        key: 'key',
                                        fixed: "left",
                                        render: (text, record, index) => <span>{index + 1}</span>,
                                    },
                                    {
                                        title: 'Month & Year',
                                        dataIndex: 'month_of_salary',
                                        key: 'month_of_salary',
                                        width: 150,
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'month_of_salary']}
                                                rules={[{ required: true, message: 'Please select' }]}
                                            >
                                                <Select
                                                    placeholder="Select Select"
                                                    onChange={(value) => handleChange(['salaryDetails', index, 'month_of_salary'], value)}
                                                    allowClear
                                                    style={{ width: '120px' }}
                                                    showSearch
                                                >
                                                    {allMonthYear?.map((branch) => (
                                                        <Select.Option key={branch} value={branch}>
                                                            {branch}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Monthly Net Salary In Cash',
                                        dataIndex: 'monthly_net_salary_cash',
                                        key: 'monthly_net_salary_cash',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'monthly_net_salary_cash']}
                                                rules={[{ required: true, message: 'Enter fixed net salary' }]}
                                            >
                                                <Input
                                                    placeholder="Please enter fixed net salary"
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'monthly_net_salary_cash'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Rental Income - Cash',
                                        dataIndex: 'rental_income_cash',
                                        key: 'rental_income_cash',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'rental_income_cash']}
                                                rules={[{ required: true, message: 'Enter rental income - cash' }]}
                                            >
                                                <Input
                                                    placeholder="Please enter rental income - cash"
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'rental_income_cash'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Rental Income - Non Cash',
                                        dataIndex: 'rental_income_non_cash',
                                        key: 'rental_income_non_cash',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'rental_income_non_cash']}
                                                rules={[{ required: true, message: 'Enter rental income - non cash' }]}
                                            >
                                                <Input
                                                    placeholder="Please enter rental income - non cash"
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'rental_income_non_cash'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Dividend / Interest ',
                                        dataIndex: 'dividend_or_interest',
                                        key: 'dividend_or_interest',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'dividend_or_interest']}
                                                rules={[{ required: true, message: 'Enter dividend / interest' }]}
                                            >
                                                <Input
                                                    placeholder="Please enter dividend / interest"
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'dividend_or_interest'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Agricultural Income ',
                                        dataIndex: 'agricultural_income',
                                        key: 'agricultural_income',
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, 'agricultural_income']}
                                                rules={[{ required: true, message: 'Enter agricultural income' }]}
                                            >
                                                <Input
                                                    placeholder="Please enter agricultural income"
                                                    onChange={(e) => handleChange(['salaryDetails', index, 'agricultural_income'], e.target.value)}
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        fixed: "right",
                                        render: (_, record, index) => {
                                            // Retrieve the actual value of the current salary detail using `form.getFieldValue`
                                            const salaryValues = form.getFieldValue('salaryDetails') || []; // Ensure there's a default value
                                            const currentDetail = salaryValues[index] || {}; // Get the current salary detail values


                                            return (
                                                <DeleteOutlined
                                                    style={{ color: 'red' }}
                                                    onClick={() => {
                                                        if (currentDetail?.id) {
                                                            setOpenModal(true);
                                                            setSelectedRecord(currentDetail)

                                                        } else {
                                                            remove(index);
                                                        }
                                                    }}
                                                />
                                            );
                                        },

                                    },
                                ]}
                                dataSource={fields}
                                rowKey="key"
                                pagination={false}
                                scroll={{ x: true }}
                            />

                            <Button type="primary"
                                onClick={() => add()}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: "10px" }} >
                                Month
                            </Button>
                        </>
                    )}
                </Form.List>

                <Space
                    direction="horizontal"
                    align="center"
                    style={{ display: "flex", flexDirection: "row-reverse",
                marginBottom:"20px" }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button onClick={closeForm}>Cancel</Button>
                </Space>
            </Form>


            <Modal
                title="Confirm Delete"
                open={openModal}
                onOk={() => {
                    setOpenModal(false);
                    handleDelete();
                }}
                onCancel={() => setOpenModal(false)}
                okType="danger"
            >
                <p>{`Are you sure you want to delete?`}</p>
            </Modal>

            {/* <IncomeCalculation
                open={open} />
           
            <LTVCalculation
                open={open} /> */}
        </>

    );
};

export default IncomeCashForm;
