import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Loading from "../../../../utils/loading/Loading";
import dayjs from "dayjs";
import "../../partDisbursedInitiate/styles/partDisbursed.css";
import CustomNoData from "../../../../utils/noDataIcon/CustomNoData";

const PartDisbursalHistory = ({ data }) => {
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Status",
      dataIndex: "current_status",
      key: "current_status",
      render: (current_status) => {
        return current_status && current_status.display_name ? current_status.display_name : "-";
      },
    },
    {
      title: "Initiate Date",
      dataIndex: "initiated_date",
      key: "initiated_date",
      render: (date) =>
        date ? dayjs(date, "DD-MM-YYYY").format("DD-MM-YYYY") : "-", // Ensure correct parsing and formatting
    },
    {
      title: "Completed Date",
      dataIndex: "completed_date",
      key: "completed_date",
      render: (date) =>
        date ? dayjs(date, "DD-MM-YYYY").format("DD-MM-YYYY") : "-", // Ensure correct parsing
    },
    {
      title: "Approval Stage",
      dataIndex: "approval_stage",
      key: "approval_stage",
      render: (approval_stage) => approval_stage || "-",
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      key: "assigned_to",
      render: (assigned_to) => (assigned_to && assigned_to.name) || "-",
    },
  ];

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  return (
    <div>
      <div className="part_disbursment_tables_content">
        <div className="part_disbursed_heading">
          <div className="part_disbursed_heading_data">
            <h3>Part Disbursal History</h3>
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : data.length > 0 ? (
          <div className="table_content">
            <Table
              columns={columns}
              dataSource={data}
              rowKey={(record) => record.id}
              pagination={false}
            />
          </div>
        ) : (
          <div className="no_data_found_message">
            <CustomNoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartDisbursalHistory;
