/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getLoanAllNotification } from '../services/notificationServices';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { clearNotification } from '../../../redux/notification';
import ErrorMessage from '../../../utils/errorHandling/ErrorMessage';

const Notification = () => {
  // const notifications = useSelector((state) => state.notification.notification_data)
  const dispatch = useDispatch();
 
  const [notificationData, setNotificationData] = useState([
    {
      id: 1,
      notification_content: 'Your loan application has been approved.',
    },
    {
      id: 2,
      notification_content: 'Your monthly installment is due on 10th.',
    },
    {
      id: 3,
      notification_content: 'Congratulations! You are eligible for a top-up loan.',
    },
  ]);

  const fetchNotification = async () => {
    try {
      const response = await getLoanAllNotification();
      setNotificationData(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    dispatch(clearNotification())
    fetchNotification();
  }, []);

  return (
    <div style={styles.container}>
      {/* <h2 style={styles.heading}>Notifications</h2> */}
      <div style={styles.listContainer}>
        {notificationData.map((notification, index) => (
          <div
            key={notification.id}
            style={{
              ...styles.notificationCard,
              backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f9f9f9',
            }}
          >
            {notification.notification_content}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  notificationCard: {
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    color: '#555',
    fontSize: '16px',
  },
};

export default Notification;
