import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Radio, Row, Space } from "antd";
import '../../styles/legalDocumentInitiateInputForm.css'
import TextArea from "antd/es/input/TextArea";
import {
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";


const OtherProvisionInputForm = ({ yupSync, form, selectedOwner }) => {
  const [marketClear, setMarketClear] = useState(null);
  const [collapsed1, setCollapsed1] = useState(true);


  const onChange = (e) => {
    const value = e.target.value;
    setMarketClear(value);
    form.setFieldValue('market_clear', value);
  };
  const handleInput = (e, field) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };
  useEffect(() => {
    if (selectedOwner) {
      form.setFieldsValue({
        otherprovision_present_owner: selectedOwner, // Dynamically update input field
      });
    }
  }, [selectedOwner, form]);

  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  return (
    <div className='legal_initiate_input_main_container'>
      <div className="legal_input_block_gap_heading_gap">
        <div className="property_description_data">Other Provisions</div>
        <div className='scrutiny_collapse_button ' 
        // style={{margin:'10px'}}
          onClick={toggleCollapse1} 
          >
        {collapsed1 ? <DownOutlined /> : <UpOutlined />}
          <div className='scrutiny_collapse_button'>
          </div>
        </div>
      </div>

      {collapsed1 && (
      <div className='Conclusion_content'>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="first_owner"
              label="Who was the first owner of the Property?"
              rules={[yupSync]}
              required
            >
              <Input placeholder={"Please Enter First Owner Name"} onChange={(e) => handleInput(e, "first_owner")} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              name="otherprovision_present_owner"
              label="Who is the present owner of the Property?"
              rules={[yupSync]}
              required
            >
              <Input placeholder="Please Enter Present Owner Name" disabled onChange={(e) => handleInput(e, "otherprovision_present_owner")} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              name="market_clear"
              label="Is the title clear and marketable?"
              rules={[yupSync]}
              required
            >
              <Radio.Group onChange={onChange} value={marketClear}>
                <Space direction="vertical">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="chain_of_events"
          label="How the title devolve to current owner by which document (Chain of event must be mention with document no and date.)"
          rules={[yupSync]}
          required
        >
          <TextArea rows={4}  placeholder="Please Enter Chain Of Events"  />
        </Form.Item>
      </div>
      )}
    </div>
  );
};

export default OtherProvisionInputForm;
