import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
  stateArray: [null],
  customerNames: [null],
  addressCount: [null], 
  referenceCount: [null], 
  dependentCount: [null], 
  customerresponseData:[null],
  documentPath:[null],
  referenceIds: {},
  dependentIds: {},
  addressIds: {},
  employeeIds: {},
  bankAccountIds: {},
  investmentIds: {},
  existingLoanIds: {},
  userDetails: [],
  partnersDetail:[],
  partnerIds:{},
  partnerCount:[null],
  loanid: null,
  step: 0,
};

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: { 
    setLoanId: (state, action) => {
      state.loanid = action.payload;
    },
    addState: (state, action) => {
      const { index, value } = action.payload; // Extract index and value from action payload
      // Ensure the array has enough length to accommodate the specified index
      if (index >= state.stateArray.length) {
        // Expand the array to the required index by filling with nulls if needed
        state.stateArray.length = index + 1;
      }
    
      // Update or add the value at the specified index
      state.stateArray[index] = value;
    }, 
    addCustomerUuid: (state, action) => {
      const { index, value } = action.payload; // Extract index and value from action payload
      // Ensure the array has enough length to accommodate the specified index
      if (index >= state.customerresponseData.length) {
        // Expand the array to the required index by filling with nulls if needed
        state.customerresponseData.length = index + 1;
      }
    
      // Update or add the value at the specified index
      state.customerresponseData[index] = value;
    }, 
    addDocumentPath: (state, action) => {
      const { index, value } = action.payload; // Extract index and value from action payload
      // Ensure the array has enough length to accommodate the specified index
      if (index >= state.documentPath.length) {
        // Expand the array to the required index by filling with nulls if needed
        state.documentPath.length = index + 1;
      }
    
      // Update or add the value at the specified index
      state.documentPath[index] = value;
    }, 
    addCustomerName: (state, action) => {
      const { key, value } = action.payload;
      
      // Ensure immutability by creating a new array with the updated value
      const newCustomerNames = [...state.customerNames];
      newCustomerNames[key] = value;
      state.customerNames = newCustomerNames;
    },
    
    addPartner: (state, action) => {
      const { activeKey, value } = action.payload;  
      
      if (activeKey >= 0 && activeKey < state.partnerCount.length) {
        state.partnerCount[activeKey] = value;  
      } else { 
        state.partnerCount.push(value);
      }
    },
    addAddress: (state, action) => {  
      const { activeKey, value } = action.payload;  
      
      if (activeKey >= 0 && activeKey < state.addressCount.length) {
        state.addressCount[activeKey] = value;  
      } else { 
        state.addressCount.push(value);
      }
    },
    addReference: (state, action) => {
      const { activeKey, value } = action.payload;  
      
      if (activeKey >= 0 && activeKey < state.referenceCount.length) {
        state.referenceCount[activeKey] = value;  
      } else { 
        state.referenceCount.push(value);
      }
    },
    addDependent: (state, action) => {
      const { activeKey, value } = action.payload;  
      
      if (activeKey >= 0 && activeKey < state.dependentCount.length) {
        state.dependentCount[activeKey] = value;  
      } else { 
        state.dependentCount.push(value);
      }
    },
    updatePartnerIdAtIndex: (state, action) => {
      const { activeKey, index, value } = action.payload;

      // Ensure that the referenceIds object has an array for the activeKey
      if (!state.partnerIds[activeKey]) {
        state.partnerIds[activeKey] = []; // Initialize the array for this activeKey if not already present
      }

      // Ensure the sub-array is large enough to accommodate the index
      if (index >= state.partnerIds[activeKey].length) {
        // If the index is out of bounds, expand the array by adding nulls until the required index
        state.partnerIds[activeKey].length = index + 1;
      }

      // Update the value at the specified index of the sub-array
      state.partnerIds[activeKey][index] = value;
    },
    
    updateReferenceIdAtIndex: (state, action) => {
      const { activeKey, index, value } = action.payload;
    
      // Ensure that the referenceIds object has an array for the activeKey
      if (!state.referenceIds[activeKey]) {
        state.referenceIds[activeKey] = []; // Initialize the array for this activeKey if not already present
      }
    
      // Ensure the sub-array is large enough to accommodate the index
      if (index >= state.referenceIds[activeKey].length) {
        // If the index is out of bounds, expand the array by adding nulls until the required index
        state.referenceIds[activeKey].length = index + 1; // Ensure the array is large enough for index (index + 1)
      }
    
      // Update the value at the specified index of the referenceIds array
      state.referenceIds[activeKey][index] = value;
    
      // Ensure a value is also stored at index 0 if it was not previously set
      if (index === 0 && state.referenceIds[activeKey][0] === undefined) {
        state.referenceIds[activeKey][0] = value; // Store value at index 0
      }
    },
    

    updateDependentIdAtIndex: (state, action) => {
      const { activeKey, index, value } = action.payload;
    
      // Ensure that the dependentIds object has an array for the activeKey
      if (!state.dependentIds[activeKey]) {
        state.dependentIds[activeKey] = []; // Initialize the array for this activeKey if not already present
      }
    
      // Ensure the sub-array is large enough to accommodate the index
      if (index >= state.dependentIds[activeKey].length) {
        // If the index is out of bounds, expand the array by adding nulls until the required index
        state.dependentIds[activeKey].length = index + 1; // Ensure the array is large enough for index (index + 1)
      }
    
      // Update the value at the specified index of the dependentIds array
      state.dependentIds[activeKey][index] = value;
    
      // Ensure a value is also stored at index 0 if it was not previously set
      if (index === 0 && state.dependentIds[activeKey][0] === undefined) {
        state.dependentIds[activeKey][0] = value; // Store value at index 0
      }
    },
    
    updateAddressIdAtIndex: (state, action) => {
      const { activeKey, index, value, addressType } = action.payload;
    
      // Ensure that the addressIds object has an array for the activeKey
      if (!state.addressIds[activeKey]) {
        state.addressIds[activeKey] = []; // Initialize the array for this activeKey if not already present
      }
    
      // Ensure the sub-array is large enough to accommodate the index
      if (index >= state.addressIds[activeKey].length) {
        // If the index is out of bounds, expand the array by adding nulls until the required index
        state.addressIds[activeKey].length = index + 1;
      }
    
      // Update the value at the specified index of the addressIds array
      state.addressIds[activeKey][index] = {
        addressId: value,        // Store the addressId
        addressType: addressType // Store the addressType
      };
    
      // Store the addressType separately under the addressType key (optional)
      if (!state.addressTypes) {
        state.addressTypes = {};  // Initialize addressTypes object if not already present
      }
    
      if (!state.addressTypes[activeKey]) {
        state.addressTypes[activeKey] = {}; // Initialize the addressType object for the activeKey if not already present
      }
    
      // Store the addressType at the index in the addressTypes object
      state.addressTypes[activeKey][index] = addressType;
    },
    
    updateEmployeeIdAtIndex: (state, action) => {
      const { activeKey, index, value, employeeType } = action.payload;
      
      // Ensure that the employeeIds object has an array for the activeKey
      if (!state.employeeIds[activeKey]) {
        state.employeeIds[activeKey] = []; // Initialize the array for this activeKey if not already present
      }
  
      // Ensure the sub-array is large enough to accommodate the index
      if (index >= state.employeeIds[activeKey].length) {
        // If the index is out of bounds, expand the array by adding nulls until the required index
        state.employeeIds[activeKey].length = index + 1;
      }
  
      // Update the value at the specified index of the employeeIds array
      state.employeeIds[activeKey][index] = {
        employeeId: value,         // Store the employeeId
        employeeType: employeeType // Store the employeeType
      };
  
      // Store the employeeType separately under the employeeTypes key
      if (!state.employeeTypes) {
        state.employeeTypes = {};  // Initialize employeeTypes object if not already present
      }
  
      if (!state.employeeTypes[activeKey]) {
        state.employeeTypes[activeKey] = {}; // Initialize the employeeType object for the activeKey if not already present
      }
  
      // Store the employeeType at the index in the employeeTypes object
      state.employeeTypes[activeKey][index] = employeeType;
    }, 
    
    updateBankAccountIdAtIndex: (state, action) => {
      const { activeKey, index, value } = action.payload;
      
      // Ensure that the bankAccountIds object has an array for the activeKey
      if (!state.bankAccountIds[activeKey]) {
        state.bankAccountIds[activeKey] = []; // Initialize the array for this activeKey if not already present
      }
    
      // Ensure the sub-array is large enough to accommodate the index
      if (index >= state.bankAccountIds[activeKey].length) {
        // If the index is out of bounds, expand the array by adding nulls until the required index
        state.bankAccountIds[activeKey].length = index + 1; // Ensure the array is large enough for index (index + 1)
      }
    
      // Update the value at the specified index of the bankAccountIds array
      state.bankAccountIds[activeKey][index] = value;
    
      // Ensure a value is also stored at index 0 if it was not previously set
      if (index === 0 && state.bankAccountIds[activeKey][0] === undefined) {
        state.bankAccountIds[activeKey][0] = value; // Store value at index 0
      }
    },
    
    

    updateInvestmentIdAtIndex: (state, action) => {
      const { activeKey, index, value } = action.payload;
      
      // Ensure that the investmentIds object has an array for the activeKey
      if (!state.investmentIds[activeKey]) {
        state.investmentIds[activeKey] = []; // Initialize the array for this activeKey if not already present
      }
    
      // Ensure the sub-array is large enough to accommodate the index
      if (index >= state.investmentIds[activeKey].length) {
        // If the index is out of bounds, expand the array by adding nulls until the required index
        state.investmentIds[activeKey].length = index + 1; // Ensure the array is large enough for index (index + 1)
      }
    
      // Update the value at the specified index of the investmentIds array
      state.investmentIds[activeKey][index] = value;
    
      // Ensure a value is also stored at index 0 if it was not previously set
      if (index === 0 && state.investmentIds[activeKey][0] === undefined) {
        state.investmentIds[activeKey][0] = value; // Store value at index 0
      }
    },

  
    
    
    updateExistingLoanIdAtIndex: (state, action) => {
      const { activeKey, index, value } = action.payload;
    
      // Ensure that the existingLoanIds object has an array for the activeKey
      if (!state.existingLoanIds[activeKey]) {
        state.existingLoanIds[activeKey] = []; // Initialize the array for this activeKey if not already present
      }
    
      // Ensure the sub-array is large enough to accommodate the index
      if (index >= state.existingLoanIds[activeKey].length) {
        // If the index is out of bounds, expand the array by adding nulls until the required index
        state.existingLoanIds[activeKey].length = index + 1; // Ensure the array is large enough for index (index + 1)
      }
    
      // Update the value at the specified index of the existingLoanIds array
      state.existingLoanIds[activeKey][index] = value;
    
      // Ensure a value is also stored at index 0 if it was not previously set
      if (index === 0 && state.existingLoanIds[activeKey][0] === undefined) {
        state.existingLoanIds[activeKey][0] = value; // Store value at index 0
      }
    },
    


    
    
    setUserDetails: (state, action) => {
      const { index, name, date_of_birth, gender } = action.payload;

      // Ensure the userDetails array has enough length to accommodate the specified index
      if (state.userDetails[index] === undefined) {
        state.userDetails[index] = {}; // Initialize the object at the specified index if not already present
      }

      // Update the user details at the specified index
      if (name !== undefined) state.userDetails[index].name = name;
      if (date_of_birth !== undefined) state.userDetails[index].date_of_birth = date_of_birth;
      if (gender !== undefined) state.userDetails[index].gender = gender;
    },

    setStep: (state, action) => {
      state.step = action.payload;
    },
    clearStateArray: (state) => {
      state.stateArray = [null];  // Reset stateArray to an array with a single null value
    },
    clearCustomerNames: (state) => {
      state.customerNames = [null]; // Reset customerNames array to an array with a single null value
    },
    clearReferenceIds: (state) => {
      state.referenceIds = {}; // Reset referenceIds object
    },
    clearAddressIds: (state) => {
      state.addressIds = {}; // Reset referenceIds object
    },
    clearCustomerUuid : (state) => {
      state.customerresponseData = [null]
    },
  clearDependentIds: (state) => {
    state.dependentIds = {}; 
  },
  clearEmployeeIds: (state) => {
    state.employeeIds = {}; // Reset referenceIds object
  },
  clearBankIds: (state) => {
    state.bankAccountIds = {}; // Reset referenceIds object
  },
  clearInvestmentids: (state) => {
    state.investmentIds = {}; // Reset referenceIds object
  },
  clearExistingLoanIds: (state) => {
    state.existingLoanIds = {}; // Reset referenceIds object
  }, 
  clearAddressTypesIds: (state) => {
    state.addressTypes = {}; // Reset referenceIds object
  },
  clearLoanIds: (state) => {
    state.loanid = null; // Reset referenceIds object
  },  
  clearPartnerIds: (state) => {
    state.partnerIds = {}; // Reset referenceIds object
  }, 
  clearUserDetail: (state) => {
    state.userDetails = [null];  // Reset stateArray to an array with a single null value
  },

}
}); 

export const {  
  setLoanId, 
  addState, 
  addCustomerName, 
  setStep, 
  addAddress, 
  addReference, 
  addDependent,
  clearStateArray,
  clearCustomerNames,  // Export the new action to clear customerNames
  updateReferenceIdAtIndex, 
  clearReferenceIds,
  updateDependentIdAtIndex,
  clearDependentIds,
  updateAddressIdAtIndex,
  clearAddressIds,
  updateEmployeeIdAtIndex,
  clearEmployeeIds,
  clearBankIds,
  updateBankAccountIdAtIndex,
  updateInvestmentIdAtIndex,
  clearInvestmentids,
  updateExistingLoanIdAtIndex,  // Export the new action for updating existingLoanIds
  clearExistingLoanIds, 
  clearAddressTypesIds,
  clearLoanIds,
  clearCustomerUuid,
  setUserDetails,
  addCustomerUuid,
  addDocumentPath,
  addPartner,
  updatePartnerIdAtIndex,
  clearPartnerIds,
  clearUserDetail
} = salesSlice.actions;

 
export default salesSlice.reducer;
