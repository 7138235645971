import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notification_data: [] 
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification_data.push(action.payload)
    }, 
    clearNotification: (state,action) => {
        state.notification_data = []
    }
}
}); 

export const { 
    setNotification, 
    clearNotification
} = notificationSlice.actions;


export default notificationSlice.reducer;
