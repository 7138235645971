import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import "../styles/react-mentions.css";
const ChatInputBox = ({
  setInputValue,
  inputValue,
  handleSendMessage,
  userNameList,
}) => {
  const handleKeyPress = () => {
    handleSendMessage();
  };

  const mentionStyle = {
    padding: "2px 4px",
    borderRadius: "4px",
    margin: "0 1px",
  };

  const mentionsInputStyle = {
    highlighter: {
      overflow: "hidden",
      padding: "8px 12px",
    },
    input: {
      padding: "0",
      overflow: "auto",
      outline: "none",
      color:'white'
    },
    suggestions: {
      list: {
        border: "1px solid #d9d9d9",
        borderRadius: "6px",
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
        fontSize: "14px",
        maxHeight: "250px",
        overflow: "auto",
        marginTop: "8px",
        position: "absolute",
        bottom: "100%",
        left: 0,
        width: "600px",
        backgroundColor: "#fff",
        zIndex: 1000,
      },
      item: {
        padding: "12px 16px",
        borderBottom: "0px solid #f0f0f0",
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  };

  const handleInputChange = (e, newValue, newPlainTextValue, mentions) => {
    setInputValue(newValue);
  };
  const transformDisplay = (id, display) => `@${display}`;

  return (
    <div>
      <MentionsInput
        value={inputValue}
        className="custom-control"
        style={mentionsInputStyle}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleKeyPress();
          }
        }}
        placeholder="Type something...."
      >
        <Mention
          trigger="@"
          data={userNameList?.map((user) => ({
            id: user.id,
            display: user.name,
            profile_img: user.profile_img,
          }))}
          renderSuggestion={(entry, search, highlightedDisplay) => (
            <div className="flex items-center p-2 h-12">
              <img
                src={entry.profile_img}
                alt=""
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  marginRight: "20px",
                }}
              />
              <span className="text-gray-800 text-base">
                {highlightedDisplay}
              </span>
            </div>
          )}
          style={mentionStyle}
          appendSpaceOnAdd
          displayTransform={transformDisplay}
        />
      </MentionsInput>
    </div>
  );
};

export default ChatInputBox;
