import React, { useState, useEffect } from "react";
import "../styles/videoAudioGallaryDetails.css";
import { getDocumentDetailsByIdService } from "../services/videoAudioGalleryService";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import "../styles/videoAudioGallaryDetails.css";
import { message } from "antd";
import "../../../../commonCss/commonStyle.css";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";

const VideoAudioGallary = () => {
  const { encrypted_loan_id } = useParams();
  const [propertyData, setPropertyData] = useState();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocumentDetailsByIdService(loan_id);
        setPropertyData(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchData();
  }, [loan_id]);

  return (
    <>
      <div className="audio_deatails_container fade-in">
        <div className="audio_card">
          <div className="audio_card-content_first">
            <div className="videoaudio_label_two" >
              Key Parameter's
            </div>
          </div>
          <div className="audio_card-content_second">
            <div className="videoaudio_label">
              <h4 className="vedio_para">Video Gallery</h4>
            </div>
          </div>
          <div className="audio_card-content_third">
            <div className="videoaudio_label">
              <h4 className="audio_para">Audio Gallery</h4>
            </div>
          </div>
        </div>

        <div className="audio_card_second">
          <div className="audio_card-content_first">
            <div className="videoaudio_label_third" >
              Loan Applications Gallery
            </div>
          </div>
          <div className="card_content_video_details">
            {propertyData?.video_list?.map((item) => {
              return (
                <div className="video_gallery_content">
                  <div className="box_gallery">
                    <ReactPlayer
                      url={item?.media_path}
                      className="react-player"
                      width="150px"
                      height="100px"
                      controls={true}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="card_content_audio_details">
            {propertyData?.audio_list?.map((item) => {
              return (
                <div className="audio_gallery_content">
                  <audio controls>
                    <source src={item?.media_path} type="audio/mpeg" />
                  </audio>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default VideoAudioGallary;
