import React, { useState, useEffect } from 'react'
import { getBankAccountTabsData, deleteBankDetailsTabsData } from '../../services/salesformservices';
import { changeActiveBankAccountKey, setInitialCustomerBankAccount, setBankApicalled, removeBankAccount } from '../../../../redux/salesFormSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, message, Modal } from 'antd';
import BankAccountDetailsForm from '../../../loanApplication/customerTabDetails/bankAccountDetails/views/BankAccountDetailsForm';

const SalesFormBankAccountForm = (activeCustomer) => {

  const [openModal, setOpenModal] = useState(false);
  const [targetKey, setTargetKey] = useState(null);

  const dispatch = useDispatch();

  const { customers } = useSelector((state) => state.salesForm);


  const customerIndex = activeCustomer.activeCustomer;

  const bankDetails = customers[parseInt(customerIndex)]?.bankDetails?.bankaccounts;

  const bankDetailskey = customers[parseInt(customerIndex)]?.bankDetails?.activeBankAccountKey;

  const bankapiCalled = customers[parseInt(customerIndex)]?.bankDetails?.bankaccountapicalled;

  const customerId = customers[parseInt(customerIndex)]?.id;

  const { TabPane } = Tabs;



  useEffect(() => {
    const getBankAccountTabData = async () => {
      const response = await getBankAccountTabsData(customerId);
      if (response?.data?.bank_account_detail?.length === 0) {
        dispatch(
          setInitialCustomerBankAccount({
            activeCustomer: customerIndex,
            bankaccounts: [null],
          })
        );
        if (bankapiCalled === 0) {
          dispatch(setBankApicalled({ index: customerIndex }));
        }
      } else {
        dispatch(
          setInitialCustomerBankAccount({
            activeCustomer: customerIndex,
            bankaccounts: response?.data?.bank_account_detail,
          })
        );
        if (bankapiCalled === 0) {
          dispatch(setBankApicalled({ index: customerIndex }));
        }
      }
    };

    if (bankapiCalled === 0) {
      getBankAccountTabData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (targetKey, action) => { 
    if (action === 'add') {
      addNewBankAccount();
    } else {
      setTargetKey(targetKey);
      setOpenModal(true);
    }
  };

  const handleOk = async () => {
    const bankaccount = bankDetails[parseInt(bankDetailskey)];

    if (bankaccount) {
      if (bankDetails.length > 1) {
        const deleteTabById = async () => {
          try {

            const response = await deleteBankDetailsTabsData(bankaccount);

            if (response.status === 200 && response.success === true) {
              message.success("Tab deleted successfully");
              dispatch(removeBankAccount({
                activeCustomerKey: activeCustomer.activeCustomer,
                bankDetailskey: targetKey,
              }));

              if (bankDetailskey > 0)
              {
              dispatch(
                changeActiveBankAccountKey({
                  activeCustomer: activeCustomer.activeCustomer,
                  key: bankDetailskey - 1,
                })
              );
              }
            } else {
              message.error("Failed to delete the tab");
            }
          } catch (error) {
            message.error(error?.response?.data?.message || "Error deleting tab");
          }
        };
        deleteTabById();
      } else {
        message.error("Cannot Delete last tab")
      }

    } else {
      if (bankDetails.length > 1) {
        dispatch(removeBankAccount({
          activeCustomerKey: activeCustomer.activeCustomer,
          bankDetailskey: targetKey,
        }));

        if (bankDetailskey > 0)
        {
        dispatch(
          changeActiveBankAccountKey({
            activeCustomer: activeCustomer.activeCustomer,
            key: bankDetailskey - 1,
          })
        );
        }
      } else {
        message.error("Cannot Delete last tab")
      }

    }

    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };


  const addNewBankAccount = () => {

    const updatedBankDetails = [...bankDetails, null]

    dispatch(
      setInitialCustomerBankAccount({
        bankaccounts: updatedBankDetails,
        activeCustomer: customerIndex
      })
    );

    dispatch(
      changeActiveBankAccountKey({
        activeCustomer: activeCustomer.activeCustomer,
        key: updatedBankDetails.length - 1,
      })
    );
  };

  const onChange = (key) => {
    dispatch(
      changeActiveBankAccountKey({
        activeCustomer: activeCustomer.activeCustomer,
        key: key,
      })
    );
  };


  return (
    <div>
      <Tabs
        type="editable-card"
        onChange={onChange}
        onEdit={onEdit}
        activeKey={bankDetailskey.toString()}
      >
        {bankDetails?.map((bankaccounts, index) => (
          <TabPane tab={`Bank Account Details ${index + 1}`} key={index}>
            <BankAccountDetailsForm customer_id={customerId} id={bankaccounts} activeBankAccountKey={index} activeCustomerKey={activeCustomer} isSalesForm={true} />
          </TabPane>
        ))}
      </Tabs>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okType="danger"
      >
        <p>Are you sure you want to delete this reference tab?</p>
      </Modal>
    </div>
  )
}

export default SalesFormBankAccountForm;
