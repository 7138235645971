import React from 'react';
import { EyeOutlined } from "@ant-design/icons";

const ScoreTable = ({ highMarkData }) => {

  return (
    <div className='score_table_container'>
      <h2 className='score_table_heading'>Score Table</h2>
      <table className='score_table' >
        <thead>
          <tr>
            <th className='score_table_heading'>Bureau</th>
            <th  className='score_table_heading'>Score</th>
            <th  className='score_table_heading'>View Report</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='score_table_data'>
              {highMarkData?.highMarkScore === "02"
                ? 'HighMark'
                : highMarkData?.highMarkScore === "01"
                  ? 'Cibil'
                  : '-'}
            </td>
            <td className='score_table_data'>
              {highMarkData?.data.score_detail["SCORE-VALUE"]}
            </td>
            <td  className='score_table_data'>
              <EyeOutlined />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ScoreTable;
