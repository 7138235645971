import React from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import CustomerPersonalDetail from "./CustomerPersonalDetail";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[A-Za-z ]+$/, "First name can only contain letters")
    .min(2, "First name must be at least 2 characters")
    .max(128, "First name must be at max 128 characters")
    .label("First name"),
  middle_name: Yup.string()
    // .nullable()
    // .matches(/^[A-Za-z_]+$ /, "Middle name can only contain letters and spaces")
    // .min(2, "Middle name must be at least 2 characters")
    .max(128, "Middle name must be at max 128 characters")
    .label("Middle name"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[A-Za-z ]+$/, "Last name can only contain letters")
    .min(2, "Last name must be at least 2 characters")
    .max(128, "Last name must be at max 128 characters")
    .label("Last Name"),
  date_of_birth: Yup.date()
    .required("Date of birth is required")
    .test(
      "valid-age",
      "Customer must be at least 18 years old",
      function (value) {
        const givenDate = dayjs(value); // Convert the given date to dayjs object for comparison
        const currentDate = dayjs(); // Get the current date using dayjs
        const eighteenYearsAgo = currentDate.subtract(18, "year"); // Calculate the date 18 years ago

        return givenDate.isValid() && givenDate.isBefore(eighteenYearsAgo);
      }
    )
    .test(
      "not-future-date",
      "Date of birth cannot be a future date",
      function (value) {
        return value ? dayjs(value).isBefore(dayjs()) : true;
      }
    )
    .label("Date Of Birth"),
  business_construction_type: Yup.string().required("Business Construction Type is required"),
  gender: Yup.string().required("Gender is required").label("Gender"),
  marital_status: Yup.string()
    .required("Marital status is required")
    .label("Marital status"),
  relation_with_applicant: Yup.string()
    .required("Relation with applicant is required")
    .min(1, "Relation with applicant is required"),
  caste_category: Yup.string()
    .required("Caste category is required")
    .label("Caste category"),
  family_type: Yup.string().required("Family type is required"),
  education: Yup.string().required("Education is required"),
  place_of_birth: Yup.string()
    .required("Place of birth is required")
    .matches(/^[A-Za-z ]+$/, "Place of birth can only contain letters")
    .min(2, "Place of birth must be at least 2 characters")
    .max(128, "Place of birth must be at max 128 characters")
    .label("Place of birth"),
  nationality: Yup.string()
    .required("Nationality is required")
    .label("Nationality"),
  religion: Yup.string().required("Religion is required").label("Religion"),
  university: Yup.string()
    .required("University is required")
    .min(2, "University must be at least 2 characters")
    .max(128, "University must be at max 128 characters")
    .label("University"),
  father_name: Yup.string()
    .required("Father name is required")
    .min(2, "Father name must be at least 2 characters")
    .max(128, "Father name must be at max 128 characters")
    .label("Father name"),
  email: Yup.string()
    .required("Email is required")
    .min(2, "Email must be at least 2 characters")
    .max(128, "Email must be at max 128 characters")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/,
      "Email address is not in a valid format"
    )
    .label("Email"),
  company_name:Yup.string().required("Company Name is required"),
  phone_number: Yup.string()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return originalValue.replace(/\D/g, ""); // Remove all non-numeric characters
      }
      return originalValue;
    })
    .matches(/^\d{8,15}$/, {
      message: "Phone number must be between 8 to 15 digits",
      excludeEmptyString: true,
    })
    .label("Phone number"),

  phone_country_code: Yup.string()
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

  alt_mobile_number: Yup.string().matches(
    /^[0-9]*$/,
    "Alt Mobile Number must contain only digits"
  ),

  alt_mobile_country_code: Yup.string()
    // .min(2, "Country Code must be exact 2 digits")
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

  mobile_number: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),

  spouse_name: Yup.string()
    .matches(/^[A-Za-z ]+$/, "Spouse name can only contain letters")
    .min(2, "Spouse name must be at least 2 characters")
    .max(128, "Spouse name must be at max 128 characters")
    .label("Spouse name"),

  mobile_country_code: Yup.string()
    // .min(2, "Country Code must be exact 2 digits")
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

  new_applicant_id: Yup.string().required("Applicant Name is required"),

  new_customer_type: Yup.string().required(
    "Customer Type Of Applicant is required"
  ),
  name: Yup.string()
  .required("Comapny name is required")
  .matches(/^[A-Za-z ]+$/, "Company name can only contain letters")
  .min(2, "Company name must be at least 2 characters")
  .max(128, "Company name must be at max 128 characters")
  .label("Company name"),
office_address_type: Yup.string().required("Office Address type is required"),
spoc_location: Yup.string().required("SPOC Location is required"),
incorporation_date: Yup.date()
  .required("Incorporation Date is required")
  .test(
    "not-future-date",
    "Incorporation Date cannot be a future date",
    function (value) {
      return value ? dayjs(value).isBefore(dayjs()) : true;
    }
  )
  .label("Incorporation Date"),

cin: Yup.string()
  .matches(
    /^(?=.*[0-9])[0-9a-zA-Z]+$/,
    "Cin must contain at least one number and only alphanumeric characters"
  ),
tin: Yup.string()
  .matches(
    /^(?=.*[0-9])[0-9a-zA-Z]+$/,
    "Tin must contain at least one number and only alphanumeric characters"
  ),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const SalesPersonalDetailForm = ({
  loanId,
  customers,
  activeCustomer,
  activeKey,
}) => {
  return (
    <>
        <CustomerPersonalDetail
          activeKey={activeKey}
          loanId={loanId}
          activeCustomer={activeCustomer}
          customers={customers}
          yupSync={yupSync}
        />

    </>
  );
};

export default SalesPersonalDetailForm;
