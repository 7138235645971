import React from "react";
import { Card, Table, Row, Col, Typography, Tooltip } from "antd";
import "../styles/magmaInsuranceView.css"

const { Title } = Typography;

const PlanTable = ({ insurance_magma_plan }) => {
  const columns = [
    {
      title: "Plan",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sum Of Assured",
      dataIndex: "sum_insured",
      key: "sum_assured",
      render: (text) => {
        return text ? `₹${new Intl.NumberFormat('en-IN').format(text)}` : "-";
        
      },
    },
    {
      title: "No. of Adults",
      dataIndex: "no_of_adults",
      key: "no_of_adults",
    },
    {
      title: "No. of Children",
      dataIndex: "no_of_children",
      key: "no_of_children",
    },
    {
      title: "Years 18 to 45",
      dataIndex: "yrs_18_to_45",
      key: "yrs_18_to_45",
    },
    {
      title: "Action",
      dataIndex: "yrs_46_to_65",
      key: "yrs_46_to_65",
    },
  ];

  return (
    <div className="Nominee-details-plan-A-table">
      <Table
        dataSource={insurance_magma_plan}
        columns={columns}
        pagination={false}
        bordered
        className="Nominee-details-plan-A"
      />
    </div>
  );
};

const BenefitTable = ({ insurance_magma_plan }) => {
  const columns = [
    {
      title: "Convalescence Benefit",
      dataIndex: "convalescence_benifits",
      key: "convalescence_benifits",
    },
    {
      title: "Amount in Rs. Exclusive of GST",
      dataIndex: "amount_excl_gst",
      key: "amount_excl_gst",
      render: (text) => (text !== null ? `₹${new Intl.NumberFormat('en-IN').format(text)}` : "-"),
    },
    
  ];

  return (
    <div  className="Nominee-detail-convalescence-benefit-table">
      <Table
        dataSource={insurance_magma_plan}
        columns={columns}
        pagination={false}
        bordered
        scroll={{ x: true }} // Enable horizontal scrolling
      />
    </div>
  );
};


const CustomerTable = ({ insured_customer_under_magma_plan }) => {
  const columns = [
    {
      title: "Insured Customer Full Name",
      dataIndex: "fullName",
      key: "fullName",
      responsive: ["lg"], // Visible only on large screens and above
      render: (value, record) => (
        <p>
          {record?.first_name} {record?.middle_name} {record?.last_name}
        </p>
      ),
    },
    {
      title: "Date of Birth",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
    },
    { title: "Gender", dataIndex: "gender", key: "gender" },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Relationship with life to be Assured",
      dataIndex: "relationship",
      key: "relationship",
      responsive: ["md"], // Visible only on medium screens and above
      render: (value, record) => (
        <p>{record?.relationship_with_insured_customer?.name}</p>
      ),
    },
    { title: "Nationality", dataIndex: "nationality", key: "nationality" },
    {
      title: "Full Address",
      dataIndex: "address_line_1",
      key: "address_line_1",
      render: (text) => (
        <Tooltip title={text}>
          <p
          className="Magma-Insured-Customers-PlanA-Full-Address"
          >
            {text}
          </p>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="Nominee-details-plan-A-table">
      <Table
        dataSource={insured_customer_under_magma_plan}
        columns={columns}
        pagination={false}
        bordered
        scroll={{ x: true }} // Enable horizontal scrolling
      />
    </div>
  );
};

const MagmaPlanData = ({ data }) => {
  const { insurance_magma_plan, insured_customer_under_magma_plan } = data;

  return (
    <div>
      <Card className="Nominee-detail-whole-table">
        <Row gutter={24}> 
          <Col span={12}>
            <Title level={4} className="nominee-detail-PlanA-heading">          
              {insurance_magma_plan?.display_name}
              {insurance_magma_plan?.sum_assured}
            </Title>
            <PlanTable insurance_magma_plan={[insurance_magma_plan]} />
          </Col>
          <Col span={12}>
            <Title level={4} className="nominee-detail-PlanA-heading">
              Convalescence Benefit
            </Title>
            
            <BenefitTable insurance_magma_plan={[insurance_magma_plan]} />
          </Col>
        </Row>
      </Card>

      <Card className="Magma-Insured-Customers-PlanA-table">
        <Title level={4} className="Magma-Insured-Customers-PlanA-heading">
          Magma Insured Customers - {insurance_magma_plan?.display_name}
          {insurance_magma_plan?.sum_assured}
        </Title>
        <CustomerTable
          insured_customer_under_magma_plan={insured_customer_under_magma_plan}
        />
      </Card>
    </div>
  );
};

export default MagmaPlanData;
