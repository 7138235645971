import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPartnersTabsData, deletePartnerTabsData } from '../../services/salesformservices';
import { changeActivePartnerKey, setInitialCustomerPartner, setPartnerApicalled, removePartner } from '../../../../redux/salesFormSlice';
import { Tabs, message, Modal } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import PartnerDetailsForm from '../../../loanApplication/customerTabDetails/partnerDetails/views/PartnerDetailsForm';

const SalesFormPartnerForm = ({ activeCustomer }) => {
  const { customers } = useSelector((state) => state.salesForm);

  const partnersDetails = customers[parseInt(activeCustomer)]?.partnerDetails?.partners;
  const activePartnerKey = customers[parseInt(activeCustomer)]?.partnerDetails?.activePartnerKey;
  const partnerApiCalled = customers[parseInt(activeCustomer)]?.partnerDetails?.partnerapicalled;
  const customerId = customers[parseInt(activeCustomer)]?.id;
  const companyId = customers[parseInt(activeCustomer)]?.companyId;

  const dispatch = useDispatch();

  // Modal related states
  const [openModal, setOpenModal] = useState(false);
  const [targetKey, setTargetKey] = useState(null);

  useEffect(() => {
    const getCustomerPartners = async () => {
      const response = await getPartnersTabsData(customerId);
      if (response?.data?.business_partner.length === 0) {
        dispatch(
          setInitialCustomerPartner({
            activeCustomer: activeCustomer,
            partners: [null],
          })
        );
        if (partnerApiCalled === 0) {
          dispatch(setPartnerApicalled({ index: activeCustomer }));
        }
      } else {
        dispatch(
          setInitialCustomerPartner({
            activeCustomer: activeCustomer,
            partners: response?.data?.business_partner,
          })
        );
        if (partnerApiCalled === 0) {
          dispatch(setPartnerApicalled({ index: activeCustomer }));
        }
      }
    };

    if (partnerApiCalled === 0) {
      getCustomerPartners();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId, activeCustomer]);

  const onChange = (key) => {
    dispatch(
      changeActivePartnerKey({
        activeCustomer: activeCustomer,
        key: key,
      })
    );
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      addNewPartner();
    } else {
      setTargetKey(targetKey); // Store the target key for deletion
      setOpenModal(true); // Show the confirmation modal
    }
  };

  const handleOk = async () => {
    const partner = partnersDetails[parseInt(targetKey)];

    if (partner) {
     if (partnersDetails.length > 1)
     {
      try {
        const response = await deletePartnerTabsData(partner);

        if (response.status === 200 && response.success === true) {
          message.success('Tab deleted successfully');

          dispatch(
            removePartner({
              activeCustomerKey: activeCustomer,
              activePartnerKey: targetKey,
            })
          );

          const newActiveKey = activePartnerKey > 0 ? activePartnerKey - 1 : 0;

          dispatch(
            changeActivePartnerKey({
              activeCustomer: activeCustomer,
              key: newActiveKey,
            })
          );
        } else {
          message.error('Failed to delete the tab');
        }
      } catch (error) {
        message.error(error?.response?.data?.message || 'Error deleting tab');
      }
      } else {
        message.error("Cannot Delete Last Tab")
      }
    }
     else {
    if (partnersDetails.length > 1) {
      dispatch(
        removePartner({
          activeCustomerKey: activeCustomer,
          activePartnerKey: targetKey,
        })
      );

      const newActiveKey = activePartnerKey > 0 ? activePartnerKey - 1 : 0;

      dispatch(
        changeActivePartnerKey({
          activeCustomer: activeCustomer,
          key: newActiveKey,
        })
      );
     } else {
      message.error("Cannot Delete Last Tab")
     }
  } 

    setOpenModal(false); // Close the modal after deletion
  };

  const handleCancel = () => {
    setOpenModal(false); // Close the modal without doing anything
  };

  const addNewPartner = () => {
    const updatedPartners = [...partnersDetails, null];

    dispatch(
      setInitialCustomerPartner({
        activeCustomer: activeCustomer,
        partners: updatedPartners,
      })
    );

    dispatch(
      changeActivePartnerKey({
        activeCustomer: activeCustomer,
        key: updatedPartners.length - 1,
      })
    );
  };

  return (
    <div>
      <Tabs type="editable-card" onChange={onChange} onEdit={onEdit} activeKey={activePartnerKey?.toString()}>
        {partnersDetails?.map((partner, index) => (
          <TabPane tab={`Partner ${index + 1}`} key={index}>
            <PartnerDetailsForm
              activeCustomer={activeCustomer}
              isSalesForm={true}
              customer_id={companyId}
              id={partnersDetails[parseInt(activePartnerKey)]}
              activePartnerKey={activePartnerKey}
              open={true}
            />
          </TabPane>
        ))}
      </Tabs>

      {/* Modal for Confirm Deletion */}
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okType="danger"
      >
        <p>Are you sure you want to delete this partner tab?</p>
      </Modal>
    </div>
  );
};

export default SalesFormPartnerForm;
