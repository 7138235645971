import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  DatePicker,
} from "antd";
import {
  getPersonaByIdService,
  updatePersonalDetailsByIdService,
  listCustomerType,
  listBusinessConstructionTypeService,
  listAddressService,
} from "../services/personalDetailsServices";
import * as Yup from "yup";
import dayjs from "dayjs";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const validationSchema = Yup.object().shape({
  name: Yup.string()
  .required("Company name is required")
  .matches(/^[A-Za-z0-9 ]+$/, "Company name can only contain letters, numbers, and spaces")
  .min(2, "Company name must be at least 2 characters")
  .max(128, "Company name must be at max 128 characters")
  .label("Company name"),
  office_address_type: Yup.string().required("Address type is required"),
  business_construction_type: Yup.string().required("Address type is required"),
  spoc_location: Yup.string().required("Address type is required"),
  mobile_number: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .min(10, "Mobile number must be exact 10 digits")
    .max(10, "Mobile number must be exact 10 digits")
    .label("Mobile number"),
  incorporation_date: Yup.date()
    .required("Incorporation Date is required")
    .test(
      "not-future-date",
      "Incorporation Date cannot be a future date",
      function (value) {
        return value ? dayjs(value).isBefore(dayjs()) : true;
      }
    )
    .label("Incorporation Date"),

  mobile_country_code: Yup.string()
    // .min(2, "Country Code must be exact 2 digits")
    .max(2, "Country code must be exact 2 digits")
    .matches(/^[0-9 ]{2,}$|^$/, "Country code must be contain only 2 digits"),

  new_customer_type: Yup.string().required(
    "Customer Type Of Applicant is required"
  ),
  cin: Yup.string()
    .required("CIN is required")
    .matches(
      /^(?=.*[0-9])[0-9a-zA-Z]+$/,
      "Cin must contain at least one number and only alphanumeric characters"
    ),
  tin: Yup.string()
    .required("TIN is required")
    .matches(
      /^(?=.*[0-9])[0-9a-zA-Z]+$/,
      "Tin must contain at least one number and only alphanumeric characters"
    ),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const CompanyApplicantForm = ({
  refreshData,
  open,
  closeForm,
  id,
  loan_id,
  customer,
  setCustomer,
}) => {
  console.log("id",id)

  const [form] = Form.useForm();
  const [alladdress, setAddressType] = useState([]);
  const [businessConstruction, setBusinessConstruction] = useState([]);
  const [applicanttype] = useState("");
  const [customerType, setCustomerType] = useState([]);
  const [loading, setLoading] = useState(false);
  const ids = id?.id;

  const getPersonalData = async () => {
    if (ids) {
      try {
        const response = await getPersonaByIdService(ids);
        let {
          mobile_number,
          customer_type,
          name,
          office_address_type,
          business_construction_type,
          spoc_location,
          incorporation_date,
          cin,
          tin,
        } = response?.data;
        incorporation_date = dayjs(incorporation_date, "DD-MM-YYYY");
        console.log("116", incorporation_date);
        form?.setFieldsValue({
          customer_type: customer_type?.name,
          name,
          office_address_type,
          business_construction_type,
          spoc_location,
          incorporation_date,
          mobile_number,
          cin,
          tin,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };


  const handleSubmit = async (values) => {
    setLoading(true);

    // Format incorporation_date
    values.incorporation_date = values.incorporation_date.format("YYYY-MM-DD");

    if (values.mobile_number) {
      values.mobile_country_code = "91";
    }
    // Remove customer_type if it's not a number
    if (isNaN(values.customer_type)) {
      delete values.customer_type;
    }

    try {
      if (ids) {
        const response = await updatePersonalDetailsByIdService(ids, values);

        if (response && response?.data) {
          if (response?.status === 200 && response?.success) {
            message.success("Personal details updated successfully");
            refreshData();
            closeForm();
            setCustomer("");
          }
        } else {
          message.error("Failed to update personal details");
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getAllAddress();
      getAllBusinessConstruction();
      getPersonalData();
      getCustomerType();
    } else {
      form?.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form]);

  const getAllAddress = async () => {
    try {
      const response = await listAddressService();
      console.log("188",response)
      setAddressType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getAllBusinessConstruction = async () => {
    try {
      const response = await listBusinessConstructionTypeService();
      setBusinessConstruction(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getCustomerType = async () => {
    try {
      const response = await listCustomerType(ids);
      setCustomerType(response.data);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const handleCustomerTypeChange = (value) => {
    setCustomer(value);
  };

  const handleNumberFields = (e, field) => {
    let newValue = e.target.value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);
    form.setFieldsValue({ [field]: newValue });
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > dayjs().endOf("day");
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        office_address_type: "",
        business_construction_type: "",
        spoc_location: "",
        incorporation_date: "",
        mobile_number: "",
        cin: "",
        tin: "",
      }}
    >
      <Row gutter={16} className="row_compony_applcant">
        <Col span={8}>
          <Form.Item name="customer_type" label="Customer Type " required>
            <Select
              placeholder="Please Select"
              showSearch
              allowClear
              onChange={handleCustomerTypeChange}
            >
              {customerType
                .filter((type) => type.name !== applicanttype) // Filter out the "Co-Applicant" option
                .map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="row_compony_applcant">
        <Col span={8}>
          <Form.Item
            name="name"
            label="Company Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Company Name"
              // onChange={(e) => handleInput(e, "name")}
              
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="office_address_type"
            label="Office Address Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear
              className="select_field_tab_width"

              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {alladdress?.map((add) => {
                return (
                  <Select.Option key={add} value={add}>
                    {add}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="business_construction_type"
            label="Business Construction Type"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please Select"
              required={true}
              allowClear

              className="select_field_tab_width"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {businessConstruction?.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="row_compony_applcant">
        <Col span={8}>
          <Form.Item
            name="spoc_location"
            label="SPOC Location"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter SPOC Location"
              // onChange={(e) => handleInput(e, "spoc_location")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="incorporation_date"
            label="Incorporation Date"
            rules={[yupSync]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"
              className="select_field_tab_width"
              placeholder="Select Incorporation Date"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<span>Mobile Number</span>} required>
            <Input.Group compact>
              <Form.Item name="mobile_country_code" noStyle rules={[yupSync]}>
                <Input
                  className="comp_country_code_block"
                  defaultValue="91"
                  disabled
                />
              </Form.Item>
              <Form.Item name="mobile_number" rules={[yupSync]} noStyle>
                <Input
                 className="comp_input_mobile"
                  placeholder="Please Enter Mobile Number"
                  onChange={(e) => handleNumberFields(e, "mobile_number")}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8} >
          <Form.Item name="cin" label="CIN" >
            <Input placeholder="Please Enter CIN " />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="tin" label="TIN" >
            <Input placeholder="Please Enter TIN" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Space
          direction="horizontal"
          align="center"
         className="customer_tabs_form_buttons"
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
          <Button type="default" onClick={closeForm}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default CompanyApplicantForm;
