import React, { useEffect, useState } from "react";
import { Space, Button, Modal, message, Badge, Typography, Drawer } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined
} from "@ant-design/icons";

import DataTable from "../../../../utils/dataTable/DataTable";
import Header from "../../../layout/views/Header";
import DocumentCategoryForm from "./DocumentCategoryForm";
import { deleteDocumentCategoryByIdService } from "../services/documentCategoryServices";
import DocumentCategoryFilterForm from "./Document CategoryFilterForm";

import ConditionalRender from "../../../../utils/authorization/AuthorizeComponent";
import authorizeTableColumns from "../../../../utils/authorization/authorizeTableColumns";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const DocumentCategory = () => {
  const { Title } = Typography;
  const [document, setDocument] = useState([]);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

  // Filter Document Category
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [filterFormData, setFilterFormData] = useState({
    filter: false,
    is_active: null,
    is_verified: null,
  });

  const { user_data } = useSelector((state) => state.user);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedDocument(null);
  };

  const closeFilterForm = () => {
    setShowFilterForm(false);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    if (!showFilterForm && actionPerformed) {
      toggleRefreshTableData();
      setActionPerformed(false); // Reset the action performed state
    }
  }, [showFilterForm, actionPerformed]);


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
      width: 200,
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      sorter: true,
      render: (display_name) => {
        return display_name !== null ? display_name : " - ";
      },
      width: 200,
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
      render: (code) => {
        return code !== null ? code : "-";
      },
    },

    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (_, record) => {
        return record.is_active ? (
          <Badge
            text="Active"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Inactive"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      render: (_, record) => {
        return record.is_verified ? (
          <Badge
            text="Verified"
            color="#52C41A"
            style={{ fontSize: "16px", color: "#52C41A" }}
          />
        ) : (
          <Badge
            text="Unverified"
            color="#FF4D4F"
            style={{ fontSize: "16px", color: "#FF4D4F" }}
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (_, record) => {
        return record.created_by.name;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",

    },
    {
      title: "Modified By",
      dataIndex: "modified_by",
      render: (_, record) => {
        return record.modified_by.name;
      },
    },
    {
      title: "Modified At",
      dataIndex: "modified_at",
    },

  ];

  const authorizedColumns = [
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["AEMASTER"]}
            >
              <EditOutlined
                onClick={() => {
                  setSelectedDocument(record);
                  showDrawer();
                }}
              />
            </ConditionalRender>
            <ConditionalRender
              userPermissions={user_data?.permissions}
              requiredPermissions={["DLMASTER"]}
            >
              <DeleteOutlined
                onClick={() => {
                  setSelectedDocument(record);
                  setOpenModal(true);
                }}
              />
            </ConditionalRender>
          </Space>
        );
      },
    },
  ];

  const tableColumns = authorizeTableColumns(
    columns,
    authorizedColumns,
    user_data?.permissions,
    ["AEMASTER", "DLMASTER"]
  );


  const deleteCity = async () => {
    try {
      const response = await deleteDocumentCategoryByIdService(selectedDocument.id);

      if (response.status === 200 && response.success === true) {
        message.success("Document Category deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedDocument(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  return (
    <div>
      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Title level={2} align="center">
          Document Category
        </Title>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["VWMASTER"]}
          >
            <Button
              onClick={() => setShowFilterForm(true)}
              icon={<FilterOutlined />}
              style={{ marginLeft: "1rem" }}
            >
              More Filters
            </Button>
          </ConditionalRender>
          <ConditionalRender
            userPermissions={user_data?.permissions}
            requiredPermissions={["AEMASTER"]}
          >
            <Button
              onClick={showDrawer}
              type="primary"
              icon={<PlusCircleOutlined />}
              style={{ marginLeft: "1rem" }}
            >
              Add Document Category
            </Button>
          </ConditionalRender>
        </div>
      </Space>

      <DataTable
        apiPath="/api/v1/master/document-category/"
        tableData={document}
        setTableData={setDocument}
        tableColumns={tableColumns}
        refreshData={refreshTableData}
        searchPlaceholder={"Type to search"}
        filters={filterFormData}
      />
      <Drawer
        title={<Header title={selectedDocument ? "Edit" : "Add"} onClose={onClose} name="Document Category" />}
        width={720}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <DocumentCategoryForm
          refreshData={toggleRefreshTableData}
          id={selectedDocument && selectedDocument.id}
          open={open}
          closeForm={onClose}
          isEditMode={!!selectedDocument}
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title={"Filter"}
            onClose={closeFilterForm}
            name="Document Category"
          />
        }
        width={720}
        onClose={closeFilterForm}
        open={showFilterForm}
        closable={false}
      >
        <DocumentCategoryFilterForm
          open={showFilterForm}
          closeForm={closeFilterForm}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          toggleRefreshTableData={toggleRefreshTableData}
          setActionPerformed={setActionPerformed}
        />
      </Drawer>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteCity();
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete ${selectedDocument?.name}?`}</p>
      </Modal>
    </div>
  );
};

export default DocumentCategory;
