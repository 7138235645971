import React, { useState, useEffect } from 'react'
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Checkbox, Drawer, message } from 'antd';
import Header from "../../../../layout/views/Header";
import '../styles/valuationDetails.css'
import '../../../../loanApplication/loanTabDetails/basicDetails/styles/basicDetails.css'
import ValuePropertyDetailsForm from './ValuePropertyDetailsForm';
import ValueCertificationDetailsForm from './ValueCertificationDetailsForm';
import ValuationPropertyDetailsForm from './ValuationPropertyDetailsForm';
import { getPropertyTechnicalDataByIdService, getPropertyTechMarketValueByIdService } from '../services/valuationDetailsServices';
import "../../commonCSS/commonTechnicalValuation.css"
import Loading from "../../../../../utils/loading/Loading";
import EditButton from '../../../../../utils/editButton/EditButton';
import IndianRupeeFormatter from '../../../../../utils/amountSeperator/IndianRupeeFormatter';
import CustomNoData from '../../../../../utils/noDataIcon/CustomNoData';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';

const ValuationDetails = ({ stage_id }) => {
  const [valuationPropertyData, setValuationPropertyData] = useState(null);
  const [propertyMarketValue, setPropertyMarketValue] = useState(null);
  const [openPropertyForm, setOpenPropertyForm] = useState(false);
  const [openValuationPropertyForm, setOpenValuationPropertyForm] = useState(false);
  const [openCertificationForm, setOpenCertificationForm] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [showValueProperty, setShowValueProperty] = useState(true);
  const [showGovtValueProperty, setShowGovtValueProperty] = useState(true); // State variable to manage visibility
  const [collapseSections, setCollapseSections] = useState(true);
  const [loading, setLoading] = useState(true);
  const showValuationPropertyForm = () => {
    setOpenValuationPropertyForm(true);
  };
  const showPropertyForm = () => {
    setOpenPropertyForm(true);
  };

  const showCertificationForm = () => {
    setOpenCertificationForm(true);
  };

  const handleFormClose = () => {
    setOpenValuationPropertyForm(false);
    setOpenPropertyForm(false);
    setOpenCertificationForm(false);
  };

  const toggleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPropertyTechnicalDataByIdService(stage_id);
        setValuationPropertyData(data.data);
        setLoading(false);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
        setLoading(false);
      }
    };
    fetchData();
  }, [stage_id, refreshData]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPropertyTechMarketValueByIdService(stage_id);
        setPropertyMarketValue(data.data);
        setLoading(false);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
        setLoading(false);
      }
    };
    fetchData();
  }, [stage_id, refreshData]);


  return (
    <>
      <div className='main_basic_container'>
        {loading ? (
          <Loading loading={loading} />
        ) : (
          <>
            <div className='fade-in'>
              <div className='technical_views_form_main_container'>
                <div className='valuation_details valuation_property_blue_heading'  >
                  <div className='technical_details_blue'>Valuation Of Property ( Fair Market Valuation /Distress Valuation)</div>
                  <div>
                    <div className='technical_view_button_space'>
                      <div onClick={showPropertyForm} className='technical_edit_button'>
                        <EditButton />
                      </div>
                      {collapseSections ? (
                        <UpOutlined onClick={() => setCollapseSections(!collapseSections)} className='collapse_icon' />
                      ) : (
                        <DownOutlined onClick={() => setCollapseSections(!collapseSections)} className='collapse_icon' />
                      )}
                    </div>
                  </div>
                  <Drawer
                    title={
                      <Header
                        title="Edit"
                        onClose={handleFormClose}
                        name="Valuation Of Property ( Fair Market Valuation /Distress Valuation)"
                      />
                    }
                    width={970}
                    onClose={handleFormClose}
                    open={openPropertyForm}
                    styles={{ body: { paddingBottom: 80 } }}
                    closable={false}
                    refreshData={toggleRefreshData}
                  >
                    <ValuationPropertyDetailsForm
                      open={openPropertyForm}
                      closeForm={handleFormClose}
                      refreshData={toggleRefreshData}
                      stage_id={stage_id}
                    />
                  </Drawer>
                </div>

                {(collapseSections && (
                  <>
                    {propertyMarketValue?.technical_market_valuation_details ? (
                      <div className='fade-in '>
                        <div className='technical_valuation_of_property_heading '>
                          <div className='valuation_details_data_td_heading'>Area Type</div>
                          <div className='valuation_details_data_td_heading_first'>As per Plan/deed (in sqft)</div>
                          <div className='valuation_details_data_td_heading_first'>As per Site (In sqft)</div>
                          <div className='valuation_details_data_td_heading_first'>Deviation in %</div>
                          <div className='valuation_details_data_td_heading_first'>Rate / Sqft</div>
                          <div className='valuation_details_data_td_heading_first'>% Completed</div>
                          <div className='valuation_details_data_td_heading_first'>% Recommended</div>
                          <div className='valuation_details_data_td_heading_first'>Valuation (₹)</div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Plot Area/UDS</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.as_per_plan}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.as_per_site}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.deviation}</div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.rate} /></div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.percentage_completed}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.percentage_recommended}</div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={propertyMarketValue?.technical_market_valuation_details?.plot_area[0]?.valuation} /></div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Existing construction area</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.as_per_plan}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.as_per_site}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.deviation}</div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.rate} /></div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.percentage_completed}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.percentage_recommended}</div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={propertyMarketValue?.technical_market_valuation_details?.existing_construction_area[0]?.valuation} /></div>
                        </div>

                        <div className='valuation_details_data_red'>
                          <div className='valuation_details_data_td'>Proposed Construction area
                            <div className='valuation_data_red'>(Under-construction properties should be updated here)</div></div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.as_per_plan}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.as_per_site}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.deviation}</div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.rate} /></div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.percentage_completed}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.percentage_recommended}</div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={propertyMarketValue?.technical_market_valuation_details?.proposed_construction_area[0]?.valuation} /></div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Amenities</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.as_per_plan}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.as_per_site}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.deviation}</div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.rate} /></div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.percentage_completed}</div>
                          <div className='valuation_details_data_text_td'>{propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.percentage_recommended}</div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={propertyMarketValue?.technical_market_valuation_details?.amenities[0]?.valuation} /></div>
                        </div>

                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Fair Market Value (₹)</div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={valuationPropertyData?.technical_detail[0]?.completed_value} /></div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Total Property value on 100% completion (₹) </div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={valuationPropertyData?.technical_detail[0]?.total_market_value} /></div>
                        </div>


                        <div className='valuation_details_data'>
                          <div className='valuation_details_data_td'>Forced sale Value(₹) at 75%</div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'></div>
                          <div className='valuation_details_data_text_td'><IndianRupeeFormatter value={valuationPropertyData?.technical_detail[0]?.forced_sale_value} /></div>
                        </div>

                        <div className='valuation_details_data_last'>
                          <div >
                            Considered for valuation is:- Total Property value of 100% completed (₹):{valuationPropertyData?.technical_detail[0]?.total_market_value}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="no_data_found_message">
                        <CustomNoData />
                      </div>
                    )}
                  </>
                )
                )}

              </div>

              <div className='technical_views_form_main_container'>
                <div className='valuation_details valuation_property_blue_heading'>
                  <div className='technical_details_blue'>As Per Govt. Value Of Property</div>
                  <div>
                    <div className='technical_view_button_space'>
                      <div onClick={showValuationPropertyForm} className='technical_edit_button'>
                        <EditButton />
                      </div>
                      {showGovtValueProperty ? (
                        <UpOutlined onClick={() => setShowGovtValueProperty(!showGovtValueProperty)} className='collapse_icon' />
                      ) : (
                        <DownOutlined onClick={() => setShowGovtValueProperty(!showGovtValueProperty)} className='collapse_icon' />
                      )}
                    </div>
                    <Drawer
                      title={<Header title="Edit" onClose={handleFormClose} name="As Per Govt. Value Of Property" />}
                      width={970}
                      onClose={handleFormClose}
                      visible={openValuationPropertyForm}
                      styles={{ body: { paddingBottom: 80 } }}
                      closable={false}
                      refreshData={toggleRefreshData}
                    >
                      <ValuePropertyDetailsForm
                        open={openValuationPropertyForm} closeForm={handleFormClose} refreshData={toggleRefreshData} stage_id={stage_id} />
                    </Drawer>
                  </div>
                </div>

                {showGovtValueProperty && (
                  <>
                    {valuationPropertyData?.technical_detail?.length > 0 ? (
                      <>
                        <div>
                          <div className='location_Property_details'>
                            <div className='location_Property_card'>
                              <div className="basic_card-content">

                                <div className='technical_lable'>
                                  Government guideline/circle rate for land(₹ Per Sq.ft)
                                  {valuationPropertyData ? (
                                    <div className="value_of_property_data">₹ <IndianRupeeFormatter value={valuationPropertyData?.technical_detail[0]?.land_value} /></div>
                                  ) : (
                                    <div className="value_of_property_data">-</div>
                                  )}
                                </div>
                              </div>

                            </div>
                            <div className='location_Property_card'>
                              <div className="basic_card-content">

                                <div className='technical_lable'>
                                  Land value as per govt rate (₹)
                                  {valuationPropertyData ? (
                                    <div className="value_of_property_data value_of_property_data_remark">₹ <IndianRupeeFormatter value={valuationPropertyData?.technical_detail[0]?.land_value_government} /></div>
                                  ) : (
                                    <div className="value_of_property_data value_of_property_data_remark">-</div>
                                  )}
                                </div>
                              </div>

                            </div>
                            <div className='location_Property_card'>
                              <div className="basic_card-content">

                                <div className='technical_lable'>
                                  Government guideline/circle rate for building (₹ Per Sq.ft)
                                  {valuationPropertyData ? (
                                    <div className="value_of_property_data">₹ <IndianRupeeFormatter value={valuationPropertyData?.technical_detail[0]?.government_rate_for_bldg} /></div>
                                  ) : (
                                    <div className="value_of_property_data">-</div>
                                  )}
                                </div>
                              </div>

                            </div>
                            <div className='location_Property_card'>
                              <div className="basic_card-content">

                                <div className='technical_lable'>
                                  Flat/apartment value as per government rate (₹)
                                  {valuationPropertyData ? (
                                    <div className="value_of_property_data">₹ <IndianRupeeFormatter value={valuationPropertyData?.technical_detail[0]?.apt_value_gov} /></div>
                                  ) : (
                                    <div className="value_of_property_data">-</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='location_Property_details'>
                            <div className='location_Property_card'>
                              <div className="basic_card-content">

                                <div className='technical_lable'>
                                  Total valuation of property as per government rate
                                  {valuationPropertyData !== null && valuationPropertyData !== undefined ? (
                                    <div className="value">₹ <IndianRupeeFormatter value={valuationPropertyData?.technical_detail[0]?.total_valuation_goverment ?? "-"} /></div>
                                  ) : (
                                    <div className="value">-</div>
                                  )}
                                </div>
                              </div>

                            </div>
                            <div className='location_Property_card' >
                              <div className="basic_card-content" >

                                <div className='technical_lable_remark'>Remarks
                                  {valuationPropertyData ? (
                                    <div className="value_of_property_data value_of_property_data_remark">{valuationPropertyData?.technical_detail[0]?.valuation_remark}</div>
                                  ) : (
                                    <div className="data">-</div>
                                  )}
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="no_data_found_message">
                        <CustomNoData />
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className='technical_views_form_main_container'>
                <div className='valuation_details valuation_property_blue_heading'>
                  <div className='technical_details_blue'>Valuer Certification</div>
                  <div>
                    <div className='technical_view_button_space'>
                      <div onClick={showCertificationForm} className='technical_edit_button' >
                        <EditButton />
                      </div>
                      {showValueProperty ? (
                        <UpOutlined onClick={() => setShowValueProperty(!showValueProperty)} className='collapse_icon' />
                      ) : (
                        <DownOutlined onClick={() => setShowValueProperty(!showValueProperty)} className='collapse_icon' />
                      )}
                    </div>
                    <Drawer
                      title={<Header title="Edit" onClose={handleFormClose} name="Valuer Certification" />}
                      width={970}
                      onClose={handleFormClose}
                      visible={openCertificationForm}
                      styles={{ body: { paddingBottom: 80 } }}
                      closable={false}
                      refreshData={toggleRefreshData}
                    >
                      <ValueCertificationDetailsForm
                        open={openCertificationForm} closeForm={handleFormClose} refreshData={toggleRefreshData} stage_id={stage_id} />
                    </Drawer>
                  </div>
                </div>

                {showValueProperty && (
                  <>
                    {valuationPropertyData?.technical_detail?.length > 0 ? (
                      <>
                        <div className='location_Property_details'>
                          <div className='location_Property_card'>
                            <div className="basic_card-content">

                              <div className='technical_lable'>
                                Date of visit
                                {valuationPropertyData ? (
                                  <div className="value">{valuationPropertyData?.technical_detail[0]?.date_of_visit}</div>
                                ) : (
                                  <div className="value">-</div>
                                )}
                              </div>
                            </div>

                          </div>
                          <div className='location_Property_card'>
                            <div className="basic_card-content">

                              <div className='technical_lable'>
                                Date of report submission
                                {valuationPropertyData ? (
                                  <div className="value">{valuationPropertyData?.technical_detail[0]?.date_of_valuation}</div>
                                ) : (
                                  <div className="value">-</div>
                                )}
                              </div>
                            </div>

                          </div>
                          <div className='location_Property_card'>
                            <div className="basic_card-content">

                              <div className='technical_lable'>
                                Name of Engineer Visited the property
                                {valuationPropertyData ? (
                                  <div className="value">{valuationPropertyData?.technical_detail[0]?.engineer_name}</div>
                                ) : (
                                  <div className="value">-</div>
                                )}
                              </div>
                            </div>

                          </div>
                          <div className='location_Property_card'>
                            <div className="basic_card-content">

                              <div className='technical_lable'>
                                Place
                                {valuationPropertyData ? (
                                  <div className="value">{valuationPropertyData?.technical_detail[0]?.place}</div>
                                ) : (
                                  <div className="value">-</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className='declaration'>Remark
                            {valuationPropertyData ? (
                              <>
                                <br></br>
                                <Checkbox
                                  className='Valuer_certification_checkbox'
                                  checked={valuationPropertyData?.technical_detail[0]?.declaration === 1}
                                />
                                <span className='Valuer_certification_checkbox_option_first'>I Hereby Declare that</span><br></br>
                                <span className='Valuer_certification_checkbox_option' > I Have visited this property.</span><br></br>
                                <span className='Valuer_certification_checkbox_option'>I Have no Direct or indirect interest in the Property Valued.</span><br></br>
                                <span className='Valuer_certification_checkbox_option'> The Information in this Report is True and Correct to the best of my Knowledge and Belief.
                                </span>

                              </>
                            ) : (
                              <div className="value">-</div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="no_data_found_message">
                        <CustomNoData />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div >
    </>


  );
}

export default ValuationDetails;
