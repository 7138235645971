import React from "react";
import { Steps, message } from "antd";
import { setActiveLoanTabKey } from "../../../redux/salesFormSlice";
import { useDispatch } from "react-redux";

const Stepper = ({ currentStep, steps, loanId }) => {

  const dispatch = useDispatch();

  const onChange = (value) => {
    if (loanId) {
    const index = value
    dispatch(
      setActiveLoanTabKey({
        activeKey: index,
      })
    );
  } else {
    message.error("Please fill out the Basic Details before proceeding");
  }
  };

  const customizedItems = steps
    .filter((steps) => steps?.title) 
    .map((steps, index) => ({
      ...steps,
      status: index < currentStep ? "wait" : index === currentStep ? "process" : "wait",
    }));

  return (
    <div className="stepper"> 

      <Steps
        current={currentStep}
        onChange={onChange}
        direction="vertical"
        items={customizedItems}
      />

    </div>

  );
};

export default Stepper;
