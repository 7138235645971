import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  getOverallStatus
} from "../../services/getCustomerDetailData";
import { updateOverallStatusOvDetails } from "../../services/applicantOvServices";
import * as Yup from "yup";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const OverallStatusOvEditForm = ({ customerDetails, closeForm, updateId, open, refreshData }) => {
  const [form] = Form.useForm();
  const [overAllStatus, setOverAllStatus] = useState([]);
  const { Option } = Select;

  const validationSchema = Yup.object().shape({
    overall_status: Yup.string().required("Overall status is required"),
    neighbor_check: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Neighbor Check must only contain letters")
      .required("Neighbor Check is required"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getOverallStatus, setOverAllStatus);

  }, []);

  const getData = async () => {
    if (updateId) {
      try {
        const { overall_status, neighbor_check } =
          customerDetails;
        form.setFieldsValue({
          overall_status,
          neighbor_check,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (updateId) {
        const response = await updateOverallStatusOvDetails(updateId, values);
        if ((response.status = 200 && response.success)) {
          message.success("Overall status Details successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateId]);

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: "",
          display_name: "",
          code: "",
          is_active: true,
          is_verified: true,
        }}
       
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="overall_status" label="Overall Status" rules={[yupSync]} required>
              <Select
                placeholder="Please Select"
                allowClear
  
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {overAllStatus?.choices?.map((overall) => (
                  <Option value={overall.value}>{overall.value}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="neighbor_check" label="Neighbour Check" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Neighbour Check"
                onChange={(e) => handleInput(e, "neighbor_check")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
         className="rv_button_submit_cancel"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default OverallStatusOvEditForm;
