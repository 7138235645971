import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  getBankAccountByIdService,
  listAccountType,
  listBankBranchService,
  listBankService,
  updateBankAccountDetailsByIdService,
  createAddBankService,
  bankIFCcodeService,

} from "../services/bankAccountDetailsService";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import AccountAggregatorForm from "./AccountAggregatorForm";
import { useDispatch } from "react-redux"; 
import { addNewCustomerBankAccount } from "../../../../../redux/salesFormSlice";

const validationSchema = Yup.object().shape({
  account_holder_name: Yup.string()
    .required("Account holder name is required")
    .min(2, "Account holder name must be at least 2 characters")
    .max(128, "Account holder name must be at max 128 characters"),
  bank: Yup.string().required("Bank  is required"),
  account_number: Yup.string()
    .required("Account number is required")
    .matches(/^[0-9]+$/, "Account number must contain only digits")
    .min(9, "Account number must be between 9 and 18 digits")
    .max(18, "Account number must be between 9 and 18 digits"),
  account_type: Yup.string().required("Account type is required"),
  bank_branch: Yup.string().required("Bank branch is required"),
  ifsc_code: Yup.string()
    .required("IFSC code is required"),
  // .matches(/^[A-Z]{4}0[0-9]{6}$/, "Invalid IFSC code format"),
  micr_code: Yup.string()
    .required("MICR code is required")
    .matches(/^\d{9}$/, "MICR code must be exactly 9 digits"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const BankAccountDetailsForm = ({ refreshData, id, open, closeForm, customer_id, activeBankAccountKey, activeCustomerKey, isSalesForm }) => {
  const [form] = Form.useForm();
  const [allBank, setAllBank] = useState([]);
  const [allBankBranch, setAllBankBranch] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const { encrypted_loan_id } = useParams();
  const [bank, setBank] = useState(null);
  const [bankBranch, setBankBranch] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
 
  
  
  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const getBankAccountData = async () => {
    if (id) {
      try {
        const response = await getBankAccountByIdService(id);
        const {
          account_holder_name,
          bank,
          account_number,
          bank_branch,
          account_type,
          ifsc_code,
          micr_code,
        } = response?.data;
        form.setFieldsValue({
          account_holder_name,
          bank: bank.id,
          account_number,
          bank_branch: bank_branch.id,
          account_type,
          ifsc_code,
          micr_code,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };
  const handleNumberFields = (e, field) => {
    const newValue = e.target.value.replace(/\D/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const handleIFSCChange = async (e) => {
    const ifsc = e.target.value;
    form.setFieldsValue({ ifsc_code: ifsc });

    if (ifsc.length === 11) {
      try {
        const response = await bankIFCcodeService(ifsc);

        // Check if the response data contains valid bank details
        if (!response.data || !response.data.bank) {
          message.error("Invalid IFSC Code");
          return;
        }

        const { bank, display_name, id, micr } = response.data;

        form.setFieldsValue({
          bank: bank.id,
          bank_branch: id,
          micr_code: micr,
        });

        setBank(bank);
        setBankBranch({ id, display_name });

      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    } else {
      // Reset bank and branch if IFSC code length is not 11
      setBank(null);
      setBankBranch(null);
      form.setFieldsValue({
        bank: undefined,
        bank_branch: undefined,
        micr_code: "",
      });
    }
  };


  useEffect(() => {
    const getAllBank = async () => {
      try {
        const response = await listBankService();
        setAllBank(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const getAllBankBranch = async () => {
      try {
        const response = await listBankBranchService();
        setAllBankBranch(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    const fetchAccountTypes = async () => {
      try {
        const response = await listAccountType();
        setAccountTypes(response.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllBank();
    getAllBankBranch();
    fetchAccountTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (id) {
        const response = await updateBankAccountDetailsByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Bank account details successfully updated");
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
        }
      } else {
        values.loan_detail = parseInt(loan_id);
        values.customer_detail = customer_id;
        const response = await createAddBankService(values);
        if ((response.status = 200 && response.success)) {
          message.success("Bank account details successfully created");  
          if (typeof isSalesForm === "undefined") {
            refreshData();
            closeForm();
          }
          if (
            typeof activeBankAccountKey !== "undefined" &&
            typeof activeCustomerKey !== "undefined"
          ) { 
            dispatch(
              addNewCustomerBankAccount({
                activeCustomerKey: activeCustomerKey.activeCustomer,
                activeBankAccountKey: activeBankAccountKey,
                id: response?.data?.id,
              })
            );
          }
        }
      }

    }
    catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );

    } finally {
      setLoading(false)
    }

  };

  useEffect(() => {
    if (open || id) {
      getBankAccountData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const handleClose = () => {
    console.log("Form closed");
    // Add any additional logic you need when closing the form
  };

  return (
    <div>
      <AccountAggregatorForm
        customer_id={customer_id}
        loan_id={loan_id}
        closeForm={handleClose}  // Ensure this is a function
        refreshData={() => { /* logic to refresh data */ }}
      />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          account_holder_name: "",
          bank: null,
          account_number: "",
          // bank_branch: "",
          // account_type: "",
          ifsc_code: "",
          micr_code: "",
        }}
      >

        <p className="manual_enter_block">
          Manually Enter Customer’s Bank Account Details
        </p>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="account_holder_name"
              label="Account holder name"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please enter account holder name"
                onChange={(e) => handleInput(e, "account_holder_name")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="account_number"
              label="Account Number"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please enter your account number"
                onChange={(e) => handleNumberFields(e, "account_number")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="account_type"
              label="Account Type"
              rules={[yupSync]}
              required
            >
              <Select placeholder="Please select" allowClear showSearch filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
                {accountTypes.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="ifsc_code"
              label="IFSC Code"
              rules={[yupSync]}
              required
            >
              <Input
                placeholder="Please Enter IFSC Code"
                onChange={handleIFSCChange}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="bank" label="Bank" rules={[yupSync]} required>
              <Select
                // mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={bank ? bank.id : undefined}
                disabled
              >
                {allBank?.map((bank) => {
                  return (
                    <Select.Option key={bank.id} value={bank.id}>
                      {bank.display_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="bank_branch"
              label="Bank Branch"
              rules={[yupSync]}
              required
            >
              <Select
                // mode="single"
                placeholder="Please select"
                required={true}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={bankBranch ? bankBranch.id : undefined}
                disabled
              >
                {allBankBranch?.map((branch) => {
                  return (
                    <Select.Option key={branch.id} value={branch.id}>
                      {branch.display_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="micr_code" label="MICR Code" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter MICR Code"
                disabled
                onChange={(e) => handleNumberFields(e, "micr_code")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
         className="customer_tabs_form_buttons"
        >
          {
            isSalesForm ? (
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            ) : (
              <>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
                <Button onClick={closeForm}>Cancel</Button>
              </>
            )
          } 
        </Space>
      </Form>
    </div>
  );
};

export default BankAccountDetailsForm;
