import React, { useEffect, useState } from "react";
import { Col, Form, Select, Row, message } from "antd"; 
import '../../styles/legalDocumentInitiateInputForm.css'
import { listOverallTypeService } from "../../services/miscellaneousDetailsService";
import TextArea from "antd/es/input/TextArea";
import {
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;

const ConclusionsInputForm = ({ yupSync,form }) => {
  const [overallstatus, setOverallstatus] = useState([]);
  const [collapsed1, setCollapsed1] = useState(true);

  const fetchoverallstatus = async () => {
    try {
      const response = await listOverallTypeService();
      setOverallstatus(response.data.choices);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {

      fetchoverallstatus();
     
  }, [form]);


  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  return (
    <div  className="legal_initiate_input_main_container" >
      {/* <div className="legal_initiat_input_document_detail_heading">
        <div className="property_description_data">Conclusion</div>
        
      </div> */}

<div className="legal_input_block_gap_heading_gap">
        <div className="property_description_data" >Conclusion</div>
        <div className="scrutiny_collapse_button legal_input_collapse_button"
      
          onClick={toggleCollapse1}
        >
          {collapsed1 ? <DownOutlined /> : <UpOutlined />}
        </div>
      </div>
      {collapsed1 && (
      <div className="Conclusion_content">
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="overall_status"
              label="Overall Status"
              rules={[yupSync]}
              required
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {overallstatus?.map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="conclusion"
          label="Overall Remark"
          rules={[yupSync]}
          required
        > 
          <TextArea rows={4}  placeholder="Please Enter Overall Conclusion Remark"  />
        </Form.Item>
      </div>
      )}
    </div>
  );
};

export default ConclusionsInputForm;
