import React from 'react'
import { Table, } from "antd";
import "../../partDisbursedInitiate/styles/partDisbursed.css"

const BeneficiaryDetails = () => {
  const beneficiaryData = [
    {
      sr_no: "1",
      beneficiary_name: "Yesuraj R.",
      bank_name: "SBI",
      branch: "Nashik",
      acc_no: "99888998800",
      acc_type: "saving",
      amount: "10,000,00",
      instrument_type: "-",
      ifsc: "SBI00000098",
      micr: "99999999999",
      image: "-",
    },
  ];

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text) => text || "-",
    },
    {
      title: "Beneficiary Nam",
      dataIndex: "beneficiary_name",
      key: "beneficiary_name",
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Account No",
      dataIndex: "acc_no",
      key: "acc_no",
    },
    {
      title: "Account type",
      dataIndex: "acc_type",
      key: "acc_type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Instrument type",
      dataIndex: "instrument_type",
      key: "instrument_type",
    },
    {
      title: "IFSC",
      dataIndex: "ifsc",
      key: "acc_type",
    },
    {
      title: "MICR",
      dataIndex: "micr",
      key: "micr",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
    }
  ];

  return (
    <div>
        <>
          <div className='part_disbursment_tables_content'>
            <div className="part_disbursed_heading">
              <div className="part_disbursed_heading_data" >
              <h3>Beneficiary Details</h3>
              </div>
            </div>

            <div className='table_content'>
              <Table
                dataSource={beneficiaryData}
                columns={columns}
                pagination={false}
              />
            </div>
          </div>
        </>
      </div>
  )
}

export default BeneficiaryDetails


