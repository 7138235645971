import React from 'react';
import { Table } from 'antd';
import "../style/disbursementtranche.css"

const columns = [
  {
    title: 'Sr No.',
    dataIndex: 'sr_no',
    key: 'sr_no',

  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Tranche Type',
    dataIndex: 'Tranche_Type',
    key: 'Tranche_Type',
  },
  {
    title: 'Disbursement Amount',
    dataIndex: 'Disbursement_Amount',
    key: 'Disbursement_Amount',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
    key: 'remarks',
  },
];
const data = [
  {
    key: '1',
    sr_no: '1',
    date: "12-03-2024",
    Tranche_Type: 'Partially Disbursed',
    Disbursement_Amount: "₹ 7,48,050",
    remarks: "Partially Disburse - tranch amount includes with insurence  amount (720000+28050)"
  },
  {
    key: '1',
    sr_no: '1',
    date: "12-03-2024",
    Tranche_Type: 'Partially Disbursed',
    Disbursement_Amount: "₹ 7,48,050",
    remarks: "Partially Disburse - tranch amount includes with insurence  amount (720000+28050)"
  },
  {
    key: '1',
    sr_no: '1',
    date: "12-03-2024",
    Tranche_Type: 'Partially Disbursed',
    Disbursement_Amount: "₹ 7,48,050",
    remarks: "Partially Disburse - tranch amount includes with insurence  amount (720000+28050)"
  },
  {
    key: '1',
    sr_no: '1',
    date: "12-03-2024",
    Tranche_Type: 'Partially Disbursed',
    Disbursement_Amount: "₹ 7,48,050",
    remarks: "Partially Disburse - tranch amount includes with insurence  amount (720000+28050)"
  },
  {
    key: '1',
    sr_no: '1',
    date: "12-03-2024",
    Tranche_Type: 'Partially Disbursed',
    Disbursement_Amount: "₹ 7,48,050",
    remarks: "Partially Disburse - tranch amount includes with insurence  amount (720000+28050)"
  },

];
const DisbursementTranche = () => {
  return (
    <>
      <div className="disbursement-tranche-table fade-in">
        <div className="disbursement-table_container">
          <Table columns={columns} dataSource={data} />
        </div>

      </div>
    </>
  )
}

export default DisbursementTranche;