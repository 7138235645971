import { axiosRequest } from "../../../../../../../utils/api/axiosRequest";


export const getSecurityInterestPendingList = (search = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(
        `/api/v1/cersai/pending-list-of-cersai/`,
        { params: { search } }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getFileListData = (search = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/cersai/cersai-file-list/`, { params: { search } });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


  export const getDataForPendingList = (application_numbers) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/cersai/get-data-for-cersai-files/?application_numbers=${application_numbers}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };


  export const getDownloadFile = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/cersai/download-dat-file/${id}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };



  

  export const getPropertyAndCustomerDetailByLoanId = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/cersai/${loan_id}`);
        return resolve(response.data);
      } catch (error) {
        console.log(error)
        return reject(error);
      }
    });
  };

  export const getPropertyDataIdService = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/cersai/cersai-property-detail/${id}`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const postPropertyData = async (data) => {
    try {
      const response = await axiosRequest.post(`/api/v1/cersai/`, data);
      return response.data;
    } catch (error) {
      console.error("API request failed:", error);
      throw error; 
    }
  };

  export const postApplicantData = async (data) => {
    try {
      const response = await axiosRequest.post(`/api/v1/cersai/create-cersai-customer-detail/`, data);
      return response.data;
    } catch (error) {
      console.error("API request failed:", error);
      throw error; 
    }
  };

  export const updatePropertyDataIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/cersai/cersai-property-detail/${id}`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const updateApplicantDataByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/cersai/cersai-customer-detail/${id}`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const updateFileListDataByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/cersai/update-file-list/${id}`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };