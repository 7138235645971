import React, { useEffect, useState } from "react";
import {
  DownOutlined,
  UpOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Space, Table, message } from "antd";
import Header from "../../../layout/views/Header";
import ResidenceNeighborConfirmationEditForm from "./ResidenceNeighborConfirmationEditForm";
import { getResidenceNeighbourByCustomerId } from "../../services/FIViewFormServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import { deleteResidenecDocumentsByIdService } from "../../services/applicantRvServices";

const ResidenceNeighborConfirmation = ({ selectedCustomerId }) => {
  const [openEditFive, setOpenEditFive] = useState(false);
  const [collapsed5, setCollapsed5] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [residenceDocumentsData, setResidenceDocumentsData] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState(null);

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getResidenceNeighbourByCustomerId(selectedCustomerId);
        setResidenceDocumentsData(reponse?.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, selectedCustomerId]);

  const toggleCollapse5 = () => {
    setCollapsed5(!collapsed5);
  };
  const showDrawerFive = () => {
    setOpenEditFive(true);
  };
  const onCloseFive = () => {
    setOpenEditFive(false);
    setSelectedDocuments(null)
  };

  const columns2 = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
    },

    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              className="edit_icon_rv"
              onClick={() => {
                setSelectedDocuments(record)
                showDrawerFive();
              }}
            />
            <DeleteOutlined
              className="delete_icon_rv"
              onClick={() => {
                setSelectedDocuments(record)
                setOpenModal(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const deleteDocument = async () => {
    try {
      const response = await deleteResidenecDocumentsByIdService(selectedDocuments.id);
      if (response.status === 200 && response.success === true) {
        message.success("Residence neighbour  document deleted successfully");
        setOpenModal(false);
        toggleRefreshTableData();
        setSelectedDocuments(null);
      }
    } catch (error) {
      setOpenModal(false);
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };
  return (
    <div className="residenace_container">
      <Drawer
        title={
          <Header
            title="Edit"
            onClose={onCloseFive}
            name="Residence Neighbour Confirmation"
          />
        }
        width={970}
        onClose={onCloseFive}
        open={openEditFive}
        closable={false}
      >
        <ResidenceNeighborConfirmationEditForm
          open={openEditFive}
          id={selectedDocuments?.id}
          // loan_id={loan_id}
          closeForm={onCloseFive}
          refreshData={toggleRefreshTableData}
        // customerDetails={customerDetails}
        />
      </Drawer>
      <div >
      <div className="heading_rv_identification_document" >
        <div className="property_description_data">
          Residence Neighbour Confirmation
        </div>
        <div >
          <div
           className="identification_toggle"
            onClick={toggleCollapse5}
          >
            {collapsed5 ? <DownOutlined /> : <UpOutlined />}
          </div>
        </div>
      </div>
      {collapsed5 && (
        <div className="table_container table_document_rv" >
          <Table
            columns={columns2}
            dataSource={residenceDocumentsData}
            pagination={false}
          />
        </div>
      )}
      
      </div>
      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={() => {
          setOpenModal(false);
          deleteDocument()
        }}
        onCancel={() => setOpenModal(false)}
        okType="danger"
      >
        <p>{`Are you sure you want to delete this document?`}</p>
      </Modal>
    </div>
  );
};

export default ResidenceNeighborConfirmation;
