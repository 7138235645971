import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import {
  createDesignationService,
  getDepartmentListService,
  getDesignationByIdService,
  updateDesignationByIdService,
} from "../services/designationService";
import * as Yup from "yup";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
const { Option } = Select;

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(/^[A-Z0-9]+$/, "Code can only contain capital letters and numbers")
    .min(8, "Code must be at least 8 characters")
    .max(8, "Code must be at max 8 characters")
    .label("Code"),
  department: Yup.string()
    .required("Department is required")
    .label("Department"),
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, "Name can only contain letters")
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .required("Name is required")
    .label("Name"),
  display_name: Yup.string()
    .required("Display name is required")
    .matches(/^[A-Za-z ]+$/, "Display name can only contain letters")
    .min(2, "Display name must be at least 2 characters")
    .max(128, "Display name must be at max 128 characters")
    .required("Display name is required")
    .label("Display name"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const DesignationForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState([]);
  const [displayNameSynced, setDisplayNameSynced] = useState(true);

  const onFinish = async (values) => {
    try {
      if (id) {
        const response = await updateDesignationByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Designation successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createDesignationService(values);
        if ((response.status = 201 && response.success)) {
          message.success("Designation successfully created");
          refreshData();
          closeForm();
        }
      }
      setDisplayNameSynced(true);
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  const getDesignationData = async () => {
    if (id) {
      try {
        const response = await getDesignationByIdService(id);

        const { name, code, display_name, department, is_active, is_verified } =
          response?.data;

        form.setFieldsValue({
          name,
          code,
          display_name,
          department: department?.id,
          is_active,
          is_verified,
        });
        setDisplayNameSynced(true);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  useEffect(() => {
    if (open) {
      getDesignationData();
    } else {
      form.resetFields();
      setDisplayNameSynced(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, open, form]);

  useEffect(() => {
    const getDepartmentList = async () => {
      try {
        const response = await getDepartmentListService();
        setDepartments(response.data);
      } catch (error) {}
    };
    getDepartmentList();
  }, [form]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ display_name: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true); // Reset sync state if display_name is cleared
    } else {
      setDisplayNameSynced(false);
    }
  };
  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open, form]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        department: null,
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input
              placeholder="Please Enter Name"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="display_name"
            label="Display Name"
            rules={[yupSync]}
            required
          >
            <Input
              placeholder="Please Enter Display Name"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input
                placeholder="Please Enter Code"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
                }}
              />
            </Form.Item>
          </Col>
        ) : null}
        <Col span={12}>
          <Form.Item
            name="department"
            label="Departments"
            rules={[yupSync]}
            required
          >
            <Select
              showSearch
              placeholder="Please select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label
                  .toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
            >
              {departments?.map((department) => (
                <Select.Option
                  key={department.id}
                  value={department.id}
                  label={department.name}
                >
                  {department.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default DesignationForm;
