import React, { useState } from "react";
import { Form, Drawer, Space, Table, message, Button } from "antd";
import Header from "../../../layout/views/Header";
import ScrutinyAndLegalOpinionInputForm from "../ScrutinyAndLegalOpinionInputForm";
import PreDisbursementStageInputForm from "../PreDisbursementStageInputForm";
import PostDisbursementStageInputForm from "../PostDisbursementStageInputForm";
import '../../styles/legalDocumentInitiateInputForm.css'
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import moment from "moment";

const LegalDocumentInputForm = ({
  form,
  enteredDataScrutiny,
  setEnteredDataScrutiny,
  enteredDataPreDisbursement,
  setEnteredDataPreDisbursement,
  enteredDataPostDisbursement,
  setEnteredDataPostDisbursement,
}) => {
  const [open, setOpen] = useState(false);
  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const [collapsed3, setCollapsed3] = useState(true);
  const [editing, setEditing] = useState(false);
  const [openSecondDrawer, setOpenSecondDrawer] = useState(false);
  const [openThirdDrawer, setOpenThirdDrawer] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [, setRefreshTableData] = useState(false);
  const [editKey, setEditKey] = useState(null);

  const [formInstance] = Form.useForm();
  const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
  };

  const toggleCollapse2 = () => {
    setCollapsed2(!collapsed2);
  };

  const toggleCollapse3 = () => {
    setCollapsed3(!collapsed3);
  };

  const showDrawer = () => {
    setEditing(false); // Ensure editing mode is false when adding
    setOpen(true);
    formInstance.resetFields(); // Reset form fields
  };

  const onClose = () => {
    setOpen(false);
    formInstance.resetFields();
    setEditValue({});
  };

  const showSecondDrawer = () => {
    setEditing(false); // Ensure editing mode is false when adding
    setOpenSecondDrawer(true);
    formInstance.resetFields(); // Reset form fields

  };

  const onCloseSecondDrawer = () => {
    setOpenSecondDrawer(false);
    formInstance.resetFields(); // Reset form fields
    setEditValue({});

  };

  const showThirdDrawer = () => {
    setEditing(false); // Ensure editing mode is false when adding
    setOpenThirdDrawer(true);
    formInstance.resetFields();// Reset form fields

  };

  const onCloseThirdDrawer = () => {
    setOpenThirdDrawer(false);
    setEditValue({});

  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const handleSubmitScrutiny = (values) => {
    const formattedValues = values.map((item) => ({
      ...item,
      dated: item.dated ? (item.dated).format("YYYY-MM-DD") : "",
    }));

    if (editing) {
      const updatedData = enteredDataScrutiny.map((item) =>
        item.key === editKey ? { ...item, ...formattedValues[0] } : item
      );
      setEnteredDataScrutiny(updatedData);
      setEditing(false);
      setEditKey(null);
    } else {
      const newData = formattedValues.map((item, index) => ({
        ...item,
        key: enteredDataScrutiny.length + index + 1,
      }));
      setEnteredDataScrutiny([...enteredDataScrutiny, ...newData]);
    }

    setOpen(false);
    message.success("Scrutiny Document submitted successfully");
  };

  const handleSubmitPreDisbursement = (values) => {
    const formattedValues = values.map((item) => ({
      ...item,
      dated: item.dated ? (item.dated).format("YYYY-MM-DD") : "",
    }));

    if (editing) {
      // Update the existing row
      const updatedData = enteredDataPreDisbursement.map((item) =>
        item.key === editKey ? { ...item, ...formattedValues[0] } : item
      );
    
      setEnteredDataPreDisbursement(updatedData);
      setEditing(false); // Reset editing mode
      setEditKey(null); // Reset edit key
    } else {
      const newData = formattedValues.map((item, index) => ({
        ...item,
        key: enteredDataPreDisbursement.length + index + 1, // Ensure unique keys for the new data
      }));
      setEnteredDataPreDisbursement([
        ...enteredDataPreDisbursement,
        ...newData,
      ]);
    }

    setOpenSecondDrawer(false); // Close the drawer after submitting
    message.success(" Pre-Disbursement Document submitted successfully");
  };

  const handleSubmitPostDisbursement = (values) => {
    const formattedValues = values.map((item) => ({
      ...item,
      dated: item.dated ? (item.dated).format("YYYY-MM-DD") : "",
    }));
    if (editing) {
      // Update the existing row
      const updatedData = enteredDataPostDisbursement.map((item) =>
        item.key === editKey ? { ...item, ...formattedValues[0] } : item
      );
      setEnteredDataPostDisbursement(updatedData);
      setEditing(false); // Reset editing mode
      setEditKey(null); // Reset edit key
    } else {
      const newData = formattedValues.map((item, index) => ({
        ...item,
        key: enteredDataPostDisbursement.length + index + 1, // Ensure unique keys for the new data
      }));
      setEnteredDataPostDisbursement([
        ...enteredDataPostDisbursement,
        ...newData,
      ]);
    }

    setOpenThirdDrawer(false); // Close the drawer after submitting
    message.success(" Post-Disbursement Document submitted successfully");
  };
  const handleDeleteOrEditForScrutiny = (key, isEdit) => {
    if (isEdit) {
      const selectedRow = enteredDataScrutiny.find((item) => item.key === key);
      setEditValue(selectedRow);
      setEditKey(key);
      setEditing(true);
      setOpen(true);
      form.setFieldsValue({
        documents: [
          {
            document: selectedRow.document,
            dated: selectedRow.dated
              ? moment(selectedRow.dated, "YYYY-MM-DD")
              : null, // Ensure moment object
            document_type: selectedRow.document_type,
          },
        ],
      });
    } else {
      const newData = enteredDataScrutiny.filter((item) => item.key !== key);
      setEnteredDataScrutiny(newData);
      message.success(" Scrutiny Document deleted successfully");
    }
  };

  const handleDeleteOrEditForPreDisbursement = (key, isEdit) => {
    if (isEdit) {
      // Find the selected row data
      const selectedRow = enteredDataPreDisbursement.find(
        (item) => item.key === key
      );
      // Open the form for editing with the selected row data pre-populated
      setEditValue(selectedRow);
      setEditKey(key); // Set the key of the row being edited
      setEditing(true); // Set editing mode
      setOpenSecondDrawer(true);
      // Set the initial values for the form fields
      form.setFieldsValue({
        documents: [
          {
            document: selectedRow.document,
            dated: selectedRow.dated
              ? moment(selectedRow.dated, "YYYY-MM-DD")
              : null, // Ensure moment object
            document_type: selectedRow.document_type,
          },
        ],
      });
    } else {
      // Delete the selected row
      const newData = enteredDataPreDisbursement.filter(
        (item) => item.key !== key
      );
      setEnteredDataPreDisbursement(newData);
      message.success("Pre-Disbursement Document deleted successfully");
    }
  };

  const handleDeleteOrEditForPostDisbursement = (key, isEdit) => {
    if (isEdit) {
      // Find the selected row data
      const selectedRow = enteredDataPostDisbursement.find(
        (item) => item.key === key
      );
      // Open the form for editing with the selected row data pre-populated
      setEditValue(selectedRow);
      setEditKey(key); // Set the key of the row being edited
      setEditing(true); // Set editing mode
      setOpenThirdDrawer(true);
      // Set the initial values for the form fields
      form.setFieldsValue({
        documents: [
          {
            document: selectedRow.document,
            dated: selectedRow.dated
              ? moment(selectedRow.dated, "YYYY-MM-DD")
              : null, // Ensure moment object
            document_type: selectedRow.document_type,
          },
        ],
      });
    } else {
      // Delete the selected row
      const newData = enteredDataPostDisbursement.filter(
        (item) => item.key !== key
      );
      setEnteredDataPostDisbursement(newData);
      message.success(" Post-Disbursement Document deleted successfully");
    }
  };

  const scrutinycolumn = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: "500px",
    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : ""),
    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined
            className="scrutiny_Column_Edit_Button"
            onClick={() => handleDeleteOrEditForScrutiny(record.key, true)}
          />
          <DeleteOutlined
            className="scrutiny_Column_Delete_Button"
            onClick={() => handleDeleteOrEditForScrutiny(record.key, false)}
          />
        </Space>
      ),
    },
  ];

  const predisbursementcolumn = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: "500px",
    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
      render: (test) => (test ? moment(test).format("DD-MM-YYYY") : ""),
    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (test, record) => (
        <Space size="middle">
          <EditOutlined
           className="scrutiny_Column_Edit_Button"
            onClick={() =>
              handleDeleteOrEditForPreDisbursement(record.key, true)
            }
          />
          <DeleteOutlined
           className="scrutiny_Column_Delete_Button"
            onClick={() =>
              handleDeleteOrEditForPreDisbursement(record.key, false)
            }
          />
        </Space>
      ),
    },
  ];

  const postdisbursementcolumn = [
    {
      title: "Sr.No",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: "500px",
    },
    {
      title: "Dated",
      dataIndex: "dated",
      key: "dated",
      render: (te) => (te ? moment(te).format("DD-MM-YYYY") : ""),

    },
    {
      title: "Type of Document",
      dataIndex: "document_type",
      key: "type_of_document",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined
          className="scrutiny_Column_Edit_Button"
            onClick={() =>
              handleDeleteOrEditForPostDisbursement(record.key, true)
            }
          />
          <DeleteOutlined
          className="scrutiny_Column_Delete_Button"
            onClick={() =>
              handleDeleteOrEditForPostDisbursement(record.key, false)
            }
          />
        </Space>
      ),
    },
  ];
  return (
    <div
      className="property_description_container  scrutiny_main_container"
  
    >

      <Drawer
        title={
          <Header
            title={editing ? "Edit" : "Add"}
            onClose={onClose}
            name="List Of Documents Submitted Before Me/Us For Scrutiny And Legal Opinion"
          />
        }
        width={1050}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <ScrutinyAndLegalOpinionInputForm
          open={open}
          editValue={editValue}
          closeForm={onClose}
          setEnteredData={handleSubmitScrutiny}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title={editing ? "Edit" : "Add"}
            onClose={onCloseSecondDrawer}
            name="Essential Documents Required To Be Collected At Pre-Disbursement Stage"
          />
        }
        width={1050}
        onClose={onCloseSecondDrawer}
        open={openSecondDrawer}
        closable={false}
      >
        <PreDisbursementStageInputForm
          open={openSecondDrawer}
          closeForm={onCloseSecondDrawer}
          editValue={editValue}
          setEnteredData={handleSubmitPreDisbursement}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <Drawer
        title={
          <Header
            title={editing ? "Edit" : "Add"}
            onClose={onCloseThirdDrawer}
            name="Documents Required To Be Collected At Post-Disbursement Stage To Create A Valid Mortgage"
          />
        }
        width={1050}
        onClose={onCloseThirdDrawer}
        open={openThirdDrawer}
        closable={false}
      >
        <PostDisbursementStageInputForm
          open={openThirdDrawer}
          closeForm={onCloseThirdDrawer}
          editValue={editValue}
          setEnteredData={handleSubmitPostDisbursement}
          refreshData={toggleRefreshTableData}
        />
      </Drawer>

      <div className="legal_initiate_input_main_container">
        <div className="legal_initiat_input_document_detail_heading">
          <div className="property_description_data">
            List Of Documents Submitted Before Me/Us For Scrutiny And Legal
            Opinion
          </div>
          <div  className="scrutiny_add_button_space">
            <Button
             icon={<PlusCircleOutlined />}
              className="legal_view_document_button"
              onClick={showDrawer}
            >
              Add Documents
            </Button>
            <div
              className="scrutiny_collapse_button"
              onClick={toggleCollapse1}
            >
              {collapsed1 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        {collapsed1 && (
          <div>
              <Table
                columns={scrutinycolumn}
                dataSource={enteredDataScrutiny}
                pagination={false}
              />
          </div>
        )}
      </div>

      <div className="legal_initiate_input_main_container">
        <div className="legal_initiat_input_document_detail_heading">
          <div className="property_description_data">
            Essential Documents Required To Be Collected At Pre-Disbursement
            Stage
          </div>
          <div className="scrutiny_add_button_space">
            <Button
               icon={<PlusCircleOutlined />}
              className="legal_view_document_button"
              onClick={showSecondDrawer}
            >
              Add Documents
            </Button>
            <div
              className="scrutiny_collapse_button"
              onClick={toggleCollapse2}
            >
              {collapsed2 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        <div>
          {collapsed2 && (
            <div>
                <Table
                  columns={predisbursementcolumn}
                  dataSource={enteredDataPreDisbursement}
                  pagination={false}
                />
            </div>
          )}
        </div>
      </div>
      
      <div className="legal_initiate_input_main_container">
        <div className="legal_initiat_input_document_detail_heading" >
          <div className="property_description_data">
            Documents Required To Be Collected At Post-Disbursement Stage To
            Create A Valid Mortgage
          </div>
          <div className="scrutiny_add_button_space">
            <Button
               icon={<PlusCircleOutlined />}
              className="legal_view_document_button"
              onClick={showThirdDrawer}
            >
              Add Documents
            </Button>
            <div
               className="scrutiny_collapse_button"
              onClick={toggleCollapse3}
            >
              {collapsed3 ? <DownOutlined /> : <UpOutlined />}
            </div>
          </div>
        </div>
        <div>
          {collapsed3 && (
            <div>
                <Table
                  columns={postdisbursementcolumn}
                  dataSource={enteredDataPostDisbursement}
                  pagination={false}
                />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LegalDocumentInputForm;
