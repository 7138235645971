import React, { useState, useEffect } from "react";
import { Select, Table } from "antd";

const { Option } = Select;

const MagmaHDIInsurance = ({
  planningListData,
  setCount,
  insurance_id,
  setInsurancePlanMagma,
  formValues,
  setFormValues,
  planDetails,
}) => {
  const [customerPlan, setCustomerPlan] = useState([]);
  const [convalescenceBenefit, setConvalesenceBenefit] = useState({});

  useEffect(() => {
    // Initialize customerPlan with planDetails if available
    if (planDetails) {
      setCustomerPlan(planDetails);
      setConvalesenceBenefit(planDetails);
    }
  }, [planDetails]);

  const handleChange = (value) => {
    const getPlan = planningListData?.find((val) => val.id === value);
  
    setInsurancePlanMagma(getPlan?.id);
    setConvalesenceBenefit(getPlan);
  
    const countCustomerUnderPlan = getPlan?.no_of_adults + getPlan?.no_of_children;
  
    setCount(countCustomerUnderPlan);
    setCustomerPlan(getPlan);
  
    let newFormValues = Array.isArray(formValues) ? [...formValues] : [formValues];
  
    if (newFormValues.length < countCustomerUnderPlan) {
      while (newFormValues.length < countCustomerUnderPlan) {
        // Push empty objects instead of null
        newFormValues.push({});
      }
    } else if (newFormValues.length > countCustomerUnderPlan) {
      // Truncate the array to match the required length
      newFormValues = newFormValues.slice(0, countCustomerUnderPlan);
    }
  
    setFormValues(newFormValues);
  };
  

  const columns = [
    { title: "Plan Name", dataIndex: "name", key: "name" },
    { title: "Sum Insured", dataIndex: "sum_insured", key: "sum_insured" },
    { title: "No of Adults", dataIndex: "no_of_adults", key: "no_of_adults" },
    {
      title: "No of Children",
      dataIndex: "no_of_children",
      key: "no_of_children",
    },
    {
      title: "18 to 45 Years Coverage",
      dataIndex: "yrs_18_to_45",
      key: "yrs_18_to_45",
    },
    {
      title: "46 to 65 Years Coverage",
      dataIndex: "yrs_46_to_65",
      key: "yrs_46_to_65",
    },
  ];

  return (
    <div className="">
      <div className="details_coverage_hdfc">Detail of Magma HDI Insurance</div>
      {!insurance_id && (
        <div className="magma-hdi-select-plan">
          <p>Select Plan:</p>
          <Select
            id="insurance-plan"
            className="magma-hdi-select-plan-content"
            onChange={handleChange}
            placeholder="Select a plan"
            allowClear
            disabled={!!planDetails}
          >
            {planningListData?.map((plan) => (
              <Option key={plan.id} value={plan.id}>
                {plan.name} - {plan.sum_insured}
              </Option>
            ))}
          </Select>
        </div>
      )}
      <div className="Your-insured-Customer-Plan">
        <p>Your insured Customer Plan:</p>
      </div>
      {customerPlan && (
        <Table
          columns={columns}
          dataSource={[customerPlan]}
          pagination={false}
          rowKey="id"
        />
      )}
      <div className="Convalescence-Benefit">
        <p>Convalescence Benefit</p>
        <div
          className="convalescence-benefit-and-amount-heading"
        >
          <div>Convalescence Benefit</div>
          <div>Amount in Rs. Exclusive of GST</div>
        </div>
      </div>
      <div
        className="convalescence-benefit-and-amount-content"
      >
        <div>{convalescenceBenefit?.convalescence_benifits}</div>
        <div style={{marginRight:"151px"}}>{convalescenceBenefit?.amount_excl_gst}</div>
      </div>
    </div>
  );
};

export default MagmaHDIInsurance;
