import { axiosRequest } from "../../../../../utils/api/axiosRequest";



export const getVehicleDetailsByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/vehicle-detail/vehicle-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
  
export const listStateService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`api/v1/master/state/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listVehicleTypeService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/vehicle-type/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  export const listVehicleManufacturesService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/vehicle-manufacturer/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listVehicleBodyService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/vehicle-body/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  export const listVehicleModelService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/vehicle-model/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const listVehicleVariantService = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/master/vehicle-variant/list`);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };
  
  

  export const updateVehicleDetailsByIdService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/loan/vehicle-detail/${id}`, data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }


export const listRoadTaxType = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/road-tax-type-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};




export const listRTOService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/rto-office/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const vehicleManufatureService = (manufactureID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/vehicle-model/get-vehicle-model-by-vehicle-manufacturer/${manufactureID}`);
    
      return resolve(response.data); 
    } catch (error) {
      return reject(error);
    }
  });
};



export const vehicleModelService = (modelID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/vehicle-variant/get-vehicle-variant-by-vehicle-model/${modelID}`);
    
      return resolve(response.data); 
    } catch (error) {
      return reject(error);
    }
  });
};

export const getVehicleSalesService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/vehicle-detail/vehicle-detail/${loan_id}`);
    
      return resolve(response.data); 
    } catch (error) {
      return reject(error);
    }
  });
};




export const postVehicleDetailsByIdService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(`/api/v1/loan/vehicle-detail/`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}