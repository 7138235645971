import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const createOTCPDDService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("api/v1/loan/otc-pdd-details/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getListOfLoan = (text) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/list-loan?search=${text}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getLoanNumberDetail = (loan_number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/get-loan-account-detail?loan_account_number=${loan_number}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updateOTCPDDByIdService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`api/v1/loan/otc-pdd-details/${id}`, data);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};
export const getOTCPDDById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/otc-pdd-details/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteOTCPDDByIdService = (id, remark) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`api/v1/loan/otc-pdd-details/${id}`, { data: { remark } });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const getOTCPDDByLoanIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/loan/otc-pdd-details/otc-pdd-list/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const listDocumentTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/document-type`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/otc-pdd-type`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listGroupService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/group-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listReceivedAtService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/received-at-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listRiskService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/risk-categotization`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listUserService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user-list-by-loan-location?loan_id=${loan_id}`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listLocationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/locations/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};


export const deleteDocumentDetailsByIdService = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/loan/otc-pdd-details/otc-document-delete/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
