import React from 'react';
import { Table, Button, Tooltip, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteDeviation } from '../services/getDeviation';
import ConditionalRender from '../../authorization/AuthorizeComponent';
import { useSelector } from 'react-redux';

const ManualDeviation = ({ loan_id, manualDeviationData, showDrawer, setSelectedManual, setManualDataValue, toggleRefreshTableData }) => {
    const onChange = (pagination, filters, sorter, extra) => {
    };
    const { user_data } = useSelector((state) => state.user);

    const handleRemove = async (item) => {
        const response = await deleteDeviation(item?.id, loan_id);
        if (response.status === 200 && response.success === true) {
            message.success("Manual Deviation deleted successfully");
            toggleRefreshTableData();
        }
    };
    const manualDeviation = manualDeviationData.map((item, index) => {
        return {
            key: index + 1,
            no: index + 1,
            deviation: item.deviation.name,
            actual_deviations: item.actual_description,
            mitigates: item?.mitigates,
            actions: (
                <>
                    <ConditionalRender
                        userPermissions={user_data?.permissions}
                        requiredPermissions={["EDITDEVT"]}
                    >
                        <Tooltip title="Edit">
                            <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={() => handleEdit(item)}
                            />

                        </Tooltip>
                    </ConditionalRender>
                    <ConditionalRender
                        userPermissions={user_data?.permissions}
                        requiredPermissions={["DELTDEVT"]}
                    >
                        <Tooltip title="Remove">
                            <Button
                                type="link"
                                icon={<DeleteOutlined style={{ color: 'red' }} />}
                                onClick={() => handleRemove(item)}

                            />
                        </Tooltip>
                    </ConditionalRender>
                </>
            ),
        };
    });

    const columns2 = [
        {
            title: 'Sr. No.',
            dataIndex: 'no',
        },
        {
            title: 'Deviation',
            dataIndex: 'deviation',
        },
        {
            title: 'Actual Deviation',
            dataIndex: 'actual_deviations',
        },
        {
            title: 'Mitigates',
            dataIndex: 'mitigates',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
        },
    ];

    const handleEdit = (item) => {
        // Add your edit logic here
        showDrawer()
        setSelectedManual(true)
        setManualDataValue(item)

    };



    return (
        <>
            <div className='decision_heading'>
                Manual Deviation
            </div>
            <div className="table_container">
                <Table
                    className="insurance_table"
                    columns={columns2}
                    dataSource={manualDeviation}
                    onChange={onChange}
                    pagination={false}
                    style={{ marginBottom: "20px" }}
                />
            </div>
        </>
    );
};

export default ManualDeviation;
