import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Button, Space, Row, Col, message } from 'antd';
import { listApprovalStatusService, updateApprovalStatusByIdService, listDepartmentService } from '../services/approvalDetailsService';
import ErrorMessage from '../../../../utils/errorHandling/ErrorMessage';

const { TextArea } = Input;

const CreditForm = ({ tabDisbursmentData, tabDisbursementId, onFormSubmit, index }) => {
  const [allApprovalStatus, setAllApprovalStatus] = useState([]);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const updatedValues = {
        ...tabDisbursmentData, // Keep existing data from `tabDisbursmentData`
        ...values, // Override with updated form values
        department: 'MDCDTD9', // Hardcoded department value
      };

      const response = await updateApprovalStatusByIdService(tabDisbursementId, updatedValues);
      if (response && response.success) {
        message.success("credit status updated successfully!");
        form.resetFields();
        onFormSubmit(updatedValues, index);
      } 
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    const getAllDepartmentStatus = async () => {
      try {
        const response = await listDepartmentService();
        if (response?.data?.length) {
        } 
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllDepartmentStatus();
  }, []);

  useEffect(() => {
    const getAllApprovalStatus = async () => {
      try {
        const response = await listApprovalStatusService();
        if (response?.data?.length) {
          setAllApprovalStatus(response.data);
        } else {
          message.warning("No approval status options available.");
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    getAllApprovalStatus();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      construction_completion_recommended_as_per_user: tabDisbursmentData?.construction_completion_recommended_as_per_user || '',
      disbursment_amount_requested: tabDisbursmentData?.disbursment_amount_requested || '',
    });
  }, [tabDisbursmentData, form]);

  return (
    <div className='approval_details_tabs_form'>
      <Form
        name="credit-approval-form"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
      >
        <Row gutter={16} className='approval_details_tabs_form_row'>
          <Col span={8}>
            <Form.Item
              name="current_status"
              label="Approval Status"
              rules={[{ required: true, message: 'Please select approval status' }]}
            >
              <Select
                placeholder="Select approval status"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {allApprovalStatus.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="construction_completion_recommended_as_per_user"
              label="Construction Completion Recommended"
              rules={[{ required: true, message: 'Please enter construction completion recommended' }]}
            >
              <Input placeholder="Enter Construction Completion" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="disbursment_amount_requested"
              label="Disb Amount Requested"
              rules={[{ required: true, message: 'Please enter DIB amount recommended' }]}
            >
              <Input placeholder="Enter Disb Amount Requested" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="remark"
              label="Remarks"
              rules={[{ required: true, message: 'Please enter remarks' }]}
            >
              <TextArea placeholder="Enter remarks" rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Space className='approval_details_tabs_form_button'>
          <Button type="default" onClick={() => form.resetFields()}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default CreditForm;
