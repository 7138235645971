import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  getResidenceChoice,
  getHouseTypeChoice,
} from "../../services/getCustomerDetailData";
import { updateResidenceandOverallDetails } from "../../services/applicantRvServices";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";
import * as Yup from "yup";

const ResidentialDetailsForm = ({ customerDetails, closeForm, updateId, open, refreshData }) => {
  const [form] = Form.useForm();
  const [residenceChoice, setResidenceChoice] = useState([]);
  const [houseType, setHouseType] = useState([]);
  const { Option } = Select;


  const validationSchema = Yup.object().shape({
    residence_ownership: Yup.string()
    .required("Residence ownership is required"),
    house_type: Yup.string()
    .required("House type is required"),  
  });

  const yupSync = {
    async validator({ field }, value) {
      await validationSchema.validateSyncAt(field, { [field]: value });
    },
  };

  useEffect(() => {
    const fetchAllData = async (getData, setData) => {
      const response = await getData();
      setData(response.data);
    };
    fetchAllData(getResidenceChoice, setResidenceChoice);
    fetchAllData(getHouseTypeChoice, setHouseType);
  }, []);

  const getData = async () => {
    if (updateId) {
      try {
        const { residence_ownership, house_type } =
          customerDetails;
        form.setFieldsValue({
          residence_ownership,
          house_type,
        });
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (updateId) {
        const response = await updateResidenceandOverallDetails(updateId, values);
        if ((response.status = 200 && response.success)) {
          message.success("Residence Details successfully updated");
          refreshData();
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateId]);

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"

      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="residence_ownership"
              label="Residence Ownership Details:"
              required
              rules={[yupSync]}
            >
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {residenceChoice?.map((residence) => (
                  <Option value={residence}>{residence}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="house_type" label="Type Of House" required rules={[yupSync]}>
              <Select
                placeholder="Please Select"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {houseType?.map((house) => (
                  <Option value={house}>{house}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Space
          direction="horizontal"
          align="center"
         className="rv_button_submit_cancel"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default ResidentialDetailsForm;
