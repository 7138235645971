import React, { useEffect, useState } from "react";
import { Calendar, Drawer, message } from "antd";
import "../styles/Calendar.css";
import { getCalenderEvents } from "../services/getCalenderEvents";
import CalendarEventView from "./CalendarEventView";
import Header from "../../../layout/views/Header";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const Calender = () => {
  const [eventsDetails, setEventsDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const userId = useSelector((state) => state.user.user_data.id);

  const onClose = () => {
    setOpen(false);
    setSelectedEventId(null);
  };

  const showDrawer = (event) => {
    setSelectedEventId(event.id);
    setOpen(true);
  };

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchEvents = async () => {
      const params = { user: userId, month: currentMonth, year: currentYear };
      try {
        const response = await getCalenderEvents(params);
        setEventsDetails(response?.data || []);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    fetchEvents();
  }, [userId, currentMonth, currentYear]);

  const EVENT_TYPE_COLORS = {
    "Punch in punch out": " #33FF57", // Example color
    Builders: "#FF5733",
    Customer: "#3357FF",
    Dsa: "#F1C40F",
    Connector: "#8E44AD",
    Branch: "#1ABC9C",
    "Customer pre approval": "#E74C3C",
    "Property dealer": "#3498DB",
    "Customer post approval": "#2ECC71",
    Collection: "#9B59B6",
  };

  const generateFallbackColor = (eventTypeName) => {
    const hash = Array.from(eventTypeName).reduce(
      (acc, char) => acc + char.charCodeAt(0),
      0
    );
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 60%)`;
  };

  const getListData = (value) => {
    const currentDate = value.format("DD-MM-YYYY");
    const dayEvents = eventsDetails.filter((event) => {
      const eventDate = event.in_timestamp.split(" ")[0];
      return eventDate === currentDate;
    });

    return dayEvents.map((event) => {
      let eventTime = "-";
      try {
        const timePart = event.in_timestamp.split(" ")[1];
        const cleanTimePart = timePart.split(".")[0];
        const timeDate = new Date(`1970-01-01T${cleanTimePart}`);
        eventTime = timeDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
      } catch (error) {
        console.error("Error parsing event time:", error);
      }

      const eventTypeName = event.time_sheet_event_type.name || "Unknown Event";
      const color =
        EVENT_TYPE_COLORS[eventTypeName] ||
        generateFallbackColor(eventTypeName);

      return {
        id: event.id,
        content: `${eventTime} - ${event.time_sheet_event_type.display_name}`,
        color: color,
        fullDetails: event,
      };
    });
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <div className="event-container">
        {listData.map((item, index) => (
          <div
            key={index}
            className="event-block"
            style={{ backgroundColor: item.color }}
            onClick={() => showDrawer(item.fullDetails)}
          >
            <span className="event-time">{item.content.split("-")[0]}</span>
            <span className="event-text">{item.content.split("-")[1]}</span>
          </div>
        ))}
      </div>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return info.originNode;
  };

  return (
    <>
      <Calendar cellRender={cellRender} />
      <Drawer
        title={<Header onClose={onClose} name="Visit Details" />}
        placement="right"
        onClose={onClose}
        open={open}
        width={650}
        closable={false}
      >
        {selectedEventId && <CalendarEventView eventId={selectedEventId} />}
      </Drawer>
    </>
  );
};

export default Calender;
