
import { axiosRequest } from "../../../../../utils/api/axiosRequest"

export const getSanctionDetailsByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/sanction-condition/sanction-condition-loan-id/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getStrengthsOfTheCaseByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await axiosRequest.get(`/api/v1/loan/strength-weakness/strength-weakness/${loan_id}`);
          return resolve(response.data)
      } catch (error) {
          return reject(error)
      }
  })
}



export const listSanctionConditionService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/sanction-condition/list`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createSanctionConditionService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/sanction-condition/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
  export const createStrengthsOfTheCaseService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/strength-weakness/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

export const updateSanctionConditionById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/sanction-condition/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getSanctionConditionById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/sanction-condition/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const deleteSanctionConditionById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/loan/sanction-condition/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const deleteStrengthById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.delete(`/api/v1/loan/strength-weakness/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const listStatus = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/choices-api/sanctioncondition-status-choice`);
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDistanceDetailsByLoanId = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/calculated-distance/get-distance-detail/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const updateDistanceDetailsService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/customer/calculated-distance/create-and-update-user-distance-detail/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

  export const updateStrengthOfcaseService = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/loan/strength-weakness/${id}`, data);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const deleteStregnthById = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.delete(`/api/v1/loan/strength-weakness/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }
  export const getstrngthById = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/strength-weakness/${id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }







export const getDecisionAnalysisByLoanId = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/decision-question/get-decision-question/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}




export const getDecisionQuestionById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/decision-question/${id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const updateDecisionQuestionService = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.patch(`/api/v1/loan/decision-question/${id}`, data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const createDecisionQuestionService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/decision-question/", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}




export const getDecisionQuestionListService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/master/questionnaire/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const createQuestionService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post(
      "/api/v1/loan/decision-question/",
        data
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};