import React, { useEffect, useState } from "react";
import {
  Select,
  TreeSelect,
  Button,
  Space,
  message,
  Col,
  Row,
  Typography,
} from "antd";
import {
  getAllPermissionsFormatted,
  getAllRolesFormatted,
  getAllUserAssignedRolesAndPermissions,
  updateUserPermissionsDetailsServicebyId,
  updateUserRolesDetailsServicebyId,
} from "../services/userService";

const { TreeNode } = TreeSelect;
const { Option } = Select;

const RolesPermissionsForm = ({
  open,
  onCancel,
  closeDrawer,
  id,
  onNext,
  userData,
  currentRoute,
}) => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [flattenedPermissions, setFlattenedPermissions] = useState([]);
  const [prevSelectedRoles, setPrevSelectedRoles] = useState([]);

  const handleCancel = () => {
    onCancel();
  };

  const handleDrawerClose = () => {
    setSelectedPermissions([]);
    setSelectedRoles([]);
    closeDrawer();
  };

  const handleRoleChange = (value) => {
    // Determine added roles
    let changeType;
    const addedRoles = value.filter(
      (role) => !prevSelectedRoles.includes(role)
    );

    // Determine removed roles
    const removedRoles = prevSelectedRoles.filter(
      (role) => !value.includes(role)
    );

    // Update the selected roles and previous selected roles states
    setSelectedRoles(value);
    setPrevSelectedRoles(value);

    // Determine change type
    if (addedRoles.length > 0) {
      changeType = "Add";
    } else if (removedRoles.length > 0) {
      changeType = "Remove";
    } else {
      changeType = null;
    }

    // Separate variable to track flattened permissions
    let updatedFlattenedPermissions = [...flattenedPermissions];

    // Determine change type
    if (addedRoles.length > 0) {
      changeType = "Add";
      // Concatenate permissions for added roles to updatedFlattenedPermissions
      addedRoles.forEach((roleId) => {
        const role = roles.find((r) => r.id === roleId);
        if (role) {
          updatedFlattenedPermissions = [
            ...updatedFlattenedPermissions,
            ...role.permissions,
          ];
        }
      });

      // Extract unique permission IDs
      updatedFlattenedPermissions = [...new Set(updatedFlattenedPermissions)];
      setFlattenedPermissions(updatedFlattenedPermissions);
    }

    if (changeType === "Remove") {
      // Remove permissions for the removed roles from updatedFlattenedPermissions
      removedRoles.forEach((roleId) => {
        const role = roles.find((r) => r.id === roleId);
        if (role) {
          updatedFlattenedPermissions = updatedFlattenedPermissions.filter(
            (permission) => !role.permissions.includes(permission)
          );
          setFlattenedPermissions(updatedFlattenedPermissions);
        }
      });
    }

    const mixedFormatPermissions = [];

    // Create a copy of flattenedPermissions
    const tempArray = [...updatedFlattenedPermissions];

    permissions.forEach((module) => {
      const modulePermissionIds =
        module.module_permission_list || module.permissions.map((p) => p.id);

      // Check if all permissions of the module are present in tempArray
      if (modulePermissionIds.every((id) => tempArray.includes(id))) {
        // All permissions of the module are present
        if (modulePermissionIds.length > 0) {
          mixedFormatPermissions.push(module.module_name);
        }

        // Remove the permissions of the module from tempArray
        modulePermissionIds.forEach((id) => {
          const index = tempArray.indexOf(id);
          if (index !== -1) {
            tempArray.splice(index, 1);
          }
        });
      }
    });

    // Add any remaining permissions in tempArray
    mixedFormatPermissions.push(...tempArray);

    setSelectedPermissions(mixedFormatPermissions);
  };

  const handlePermissionChange = (selectedPermissionIds) => {
    setSelectedPermissions(selectedPermissionIds);

    // Extract flattened permissions from selected tree array.
    const permissionIds = selectedPermissionIds.map((value) => {
      if (typeof value === "string") {
        const module = permissions.find(
          (module) => module.module_name === value
        );
        return module.permissions.map((permission) => permission.id) || [];
      } else if (typeof value === "number") {
        return [value];
      } else {
        return [];
      }
    });

    // Flatten the array of arrays to get a single array of permission IDs.
    const flattenedPermissionIds = [].concat(...permissionIds);

    // Update the flattenedPermissions state.
    setFlattenedPermissions(flattenedPermissionIds);

    // Check if any roles contain deselected permissions and remove them.
    const rolesToRemove = selectedRoles.filter((roleId) => {
      const role = roles.find((r) => r.id === roleId);
      if (role) {
        // Check if the role's permissions include any of the deselected permissions.
        return role.permissions.some(
          (permId) => !flattenedPermissionIds.includes(permId)
        );
      }
      return false;
    });

    // Remove the identified roles from selectedRoles state.
    const updatedSelectedRoles = selectedRoles.filter(
      (roleId) => !rolesToRemove.includes(roleId)
    );

    setSelectedRoles(updatedSelectedRoles);
    setPrevSelectedRoles(updatedSelectedRoles);
  };

  const renderPermissionTreeNodes = (modules) => {
    return modules.map((module) => (
      <TreeNode
        key={module.module_name}
        title={module.module_name}
        value={module.module_name}
      >
        {module.permissions.map((permission) => (
          <TreeNode
            key={permission.id}
            title={permission.display_name}
            value={permission.id}
          />
        ))}
      </TreeNode>
    ));
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentRoute === "/user_profile") {
          const rolesResponse = await getAllRolesFormatted();
          setRoles(rolesResponse.data);
          setSelectedRoles(userData.roles.map((item) => item.display_name));
          setSelectedPermissions(userData.permissions.map((item) => item.display_name));
          return;
        }
        if (id !== null) {
          const permissionsResponse = await getAllPermissionsFormatted();
          const rolesResponse = await getAllRolesFormatted();

          const userRolesAndPermissionsResponse =
            await getAllUserAssignedRolesAndPermissions(id);

          setPermissions(permissionsResponse.data);
          setRoles(rolesResponse.data);

          if (userRolesAndPermissionsResponse.data) {
            setSelectedRoles(userRolesAndPermissionsResponse.data.roles_list);
            setSelectedPermissions(
              userRolesAndPermissionsResponse.data.permissions_list
            );
          }
        }
      } catch (error) {
        if (currentRoute !== "/user_profile") {
          message.error("Failed to fetch data.");
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async () => {
    if (currentRoute === "/user_profile") {
      onNext();
      return;
    }
    try {
      let tempArray = [...selectedPermissions];
      let allRolePermissionsArray = [];
      // Loop over selected roles
      selectedRoles.forEach((selectedRoleId) => {
        // Find the selected role in dummy_data.roles
        const selectedRole = roles.find((role) => role.id === selectedRoleId);

        allRolePermissionsArray = allRolePermissionsArray.concat(
          selectedRole.permissions
        );
      });

      tempArray = tempArray.filter(
        (permission) => !allRolePermissionsArray.includes(permission)
      );

      const roleResponse = await updateUserRolesDetailsServicebyId(
        id,
        selectedRoles
      );
      const PermissionResponse = await updateUserPermissionsDetailsServicebyId(
        id,
        tempArray
      );
      if (roleResponse.status === 200 && PermissionResponse.status === 200) {
        message.success("Roles and Permissions updated successfully!");
        setFlattenedPermissions([]);
      }
      onNext();
    } catch (error) {
      message.error("Something went wrong!");
    }
  };
  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <div>
            <Typography>Roles</Typography>
            <Select
              mode="multiple"
              placeholder="Select Roles"
              style={{ width: "100%" }}
              onChange={handleRoleChange}
              // value={selectedRoles}
              value={
                selectedRoles?.length === 0
                  ? userData?.roles?.map((item) => item.id)
                  : selectedRoles
              }
              showSearch
              allowClear
              disabled={currentRoute === "/user_profile"}
            >
              {roles.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.display_name}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={12}>
          <div>
            <Typography>Permissions</Typography>
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              value={
                selectedPermissions?.length === 0
                  ? userData?.permissions?.map((item) => item.id)
                  : selectedPermissions
              }
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Permissions"
              allowClear
              treeDefaultExpandAll
              treeCheckable
              showCheckedStrategy="SHOW_PARENT"
              onChange={handlePermissionChange}
              disabled={currentRoute === "/user_profile"}
            >
              {renderPermissionTreeNodes(permissions)}
            </TreeSelect>
          </div>
        </Col>
      </Row>
      {(currentRoute === "/users" || currentRoute === "/master") && (
        <Space
          direction="horizontal"
          align="center"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "1rem",
          }}
        >
          <Button type="primary" onClick={handleSubmit}>
            Next
          </Button>
          <Button onClick={handleCancel}>Prev</Button>
          <Button onClick={handleDrawerClose}>Cancel</Button>
        </Space>
      )}

    </div>
  );
};

export default RolesPermissionsForm;
