import { DatePicker, Form } from "antd";
import React, { useState } from "react";
import { Button, Col, Input, Row, Select, Space, message } from "antd";
import * as Yup from "yup";
import {
  createChargesUnderwritingService,
  getChargesDataByIdServices,
  listChargeNameServices,
  listChargeStatusService,
  updateChargesUnderwritingServiceById,
} from "../services/loanInsuranceService";
import { useEffect } from "react";
import dayjs from "dayjs";

const validationSchema = Yup.object().shape({
  charge: Yup.string()
    .required("Charge Name is required")
    .max(100, "Charge Name cannot exceed 100 characters"),
  charge_percentage: Yup.string()
    .required("Charge Percentage is required")
    .min(0, "Charge Percentage cannot be negative")
    .max(100, "Charge Percentage cannot exceed 100"),
  charge_amount: Yup.string()
    .required("Charge Amount is required")
    .min(0, "Charge Amount cannot be negative"),
  charge_status: Yup.string().required("Charge Status is required"),
  charge_gst_percentage: Yup.number()
    .required("Charge GST Percentage is required")
    .min(0, "Charge GST Percentage cannot be negative")
    .max(100, "Charge GST Percentage cannot exceed 100"),
  charge_gst_amount: Yup.number()
    .required("Charge GST Amount is required")
    .min(0, "Charge GST Amount cannot be negative"),
  charge_date: Yup.date().required("Charge Date is required"),
  due_date: Yup.date().required("Due Date is required"),
  paid_date: Yup.date().required("Paid Date is required"),
  amount: Yup.number()
    .required("Total Amount is required")
    .min(0, "Total Amount cannot be negative"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const ChargesUnderwritingForm = ({
  id,
  loan_id,
  refreshData,
  closeForm,
  open,
}) => {
  const [form] = Form.useForm();
  const [allChargesStatus, setAllChargesStatus] = useState([]);
  const [allChargesName, setAllChargesName] = useState([]);


  const getChargesDataById = async () => {
    if (id) {
      try {
        const response = await getChargesDataByIdServices(id);
        const {
          charge,
          charge_percentage,
          charge_amount,
          charge_status,
          charge_gst_percentage,
          charge_gst_amount,
          charge_date,
          due_date,
          paid_date,
          amount,
        } = response?.data;


        form.setFieldsValue({
          charge: charge?.id,
          charge_percentage,
          charge_amount,
          charge_status: charge_status?.id,
          charge_gst_percentage,
          charge_gst_amount,
          charge_date:dayjs(charge_date, "DD-MM-YYYY") ,
          due_date:dayjs(due_date, "DD-MM-YYYY") ,
          paid_date: dayjs(paid_date, "DD-MM-YYYY"),
          amount,
        });
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Failed to fetch charges data"
        );
      }
    }
  };

  useEffect(() => {
    if (open) {
      getChargesDataById();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  useEffect(() => {
    const getChargeStatus = async () => {
      try {
        const response = await listChargeStatusService();
        setAllChargesStatus(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    const getChargeName = async () => {
      try {
        const response = await listChargeNameServices();
        setAllChargesName(response.data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    };

    getChargeStatus();
    getChargeName();
  }, [form]);

  const handleSubmit = async (values) => {
    try {
      const payload = {
        charge: values.charge || null,
        charge_percentage: values.charge_percentage || null,
        charge_amount: values.charge_amount || null,
        charge_gst_percentage: values.charge_gst_percentage || null,
        charge_gst_amount: values.charge_gst_amount || null,
        charge_status: values.charge_status || null,
        amount: values.amount || null,
        charge_date: values.charge_date
          ? dayjs(values.charge_date).format("YYYY-MM-DD")
          : null,
        due_date: values.due_date
          ? dayjs(values.due_date).format("YYYY-MM-DD")
          : null,
        paid_date: values.paid_date
          ? dayjs(values.paid_date).format("YYYY-MM-DD")
          : null,
        loan_detail: Number(loan_id),
      };
      if (id) {
        const response = await updateChargesUnderwritingServiceById(id, payload);
        if ((response.status = 200 && response?.success)) {
          message.success("Charges successfully updated");
          refreshData();
          closeForm();
        }
      } else {

      const response = await createChargesUnderwritingService(payload);

      if (response.status === 201 && response.success) {
        message.success("Charges successfully created");
        refreshData();
        closeForm();
        form.resetFields();
      }
    }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred.";
      message.error(errorMessage);
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{}}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="charge"
            label="Charge Name"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allChargesName?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="charge_percentage"
            label="Charge Percentage"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Charge Percentage" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="charge_amount"
            label="Charge Amount"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Charge Amount" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="charge_status"
            label="Charge Status"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              required={true}
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allChargesStatus?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="charge_gst_percentage"
            label="Charge Gst Percentage"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Charge Gst Percentage" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="charge_gst_amount"
            label="Charge GST Amount"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Charge GST Amount" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="charge_date"
            label="Charge Date"
            rules={[yupSync]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Select Charge Date"
              className="partner_salect_feilad"
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="due_date"
            label="Due Date"
            rules={[yupSync]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Select Due Date"
              className="partner_salect_feilad"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="paid_date"
            label="Paid Date"
            rules={[yupSync]}
            required
          >
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Select Date"
              className="partner_salect_feilad"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="amount"
            label="Total Amount"
            rules={[yupSync]}
            required
          >
            <Input placeholder="Please Enter Total Amount" />
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button
        // onClick={closeForm}
        >
          Cancel
        </Button>
      </Space>
    </Form>
  );
};

export default ChargesUnderwritingForm;
