import { Select, message } from 'antd'
import React, { useState, useEffect } from 'react'
import RentalForm from '../../../customerTabDetails/employmentDetails/views/RentalForm';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { getCustomersByLoanId } from '../services/incomeDetailsServices';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';

const PureRentalForm = ({ closeForm, open, refreshData }) => {
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const { encrypted_loan_id } = useParams();

    let loan_id = null;
    try {
        loan_id = decrypt(encrypted_loan_id);
    } catch (error) {
        message.error("Invalid Loan ID");
    }



    useEffect(() => {
        const fetchCustomersByLoanId = async () => {
            try {
                const response = await getCustomersByLoanId(loan_id);
                const customers = response?.data?.customer_list?.map((customer) => {
                    return {
                        id: customer.id,
                        name: `${customer.name} (${customer.customer_type})`,
                    };
                });
                setCustomerList(customers);
            } catch (error) {
                message.error(
                    ErrorMessage(error?.response?.status, error?.response?.data?.message)
                );
            }
        };

        fetchCustomersByLoanId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!open) {
            setSelectedCustomer(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleCustomerchange = (value) => {
        setSelectedCustomer(value);
    };
    return (
        <div>
            <Select
                value={selectedCustomer}
                onChange={handleCustomerchange}
                style={{ width: "300px" }}
                placeholder="Please Select Customer"
            >
                {customerList.length > 0 &&
                    customerList.map((customer) => (
                        <Select.Option value={customer.id} key={customer.id}>
                            {customer.name}
                        </Select.Option>
                    ))}
            </Select>
            <div style={{ marginTop: "24px" }}></div>
            {selectedCustomer && (
                <RentalForm
                    refreshData={refreshData}
                    open={open}
                    closeForm={closeForm}
                    customer_id={selectedCustomer}
                    selectedEmploymentType={3}
                />
            )}
        </div>
    )
}

export default PureRentalForm