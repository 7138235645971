import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import { createQuestionnaireService, getQuestionnaireByIdService, listDepartmentService, listQuestionnaireCategoryService, updateQuestionnaireByIdService } from "../services/questionnaireservices";
import * as Yup from "yup";
import TextArea from "antd/es/input/TextArea";
import ErrorMessage from "../../../../utils/errorHandling/ErrorMessage";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  code: Yup.string()
  .required("Code is required")
  .matches(/^[A-Z0-9]+$/, 'Code can only contain capital letters and numbers')
  .min(8, "Code must be at least 8 characters")
  .max(8, "Code must be at max 8 characters")
  .label("Code"),
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z ]+$/, 'Name can only contain letters and spaces')
    .min(2, "Name must be at least 2 characters")
    .max(128, "Name must be at max 128 characters")
    .label("Name"),
  display_name: Yup.string()
    .required("Display name is required")
    .matches(/^[A-Za-z ]+$/, 'Display name can only contain letters and spaces')
    .min(2, "Display name must be at least 2 characters")
    .max(128, "Display name must be at max 128 characters")
    .label("Display name"),
  questionnaire_category: Yup.string()
    .required("Questionnaire category is required")
    .min(1, "Questionnaire category is required"),
  department: Yup.string()
    .required("Department is required")
    .min(1, "Department is required"),
  description: Yup.string()
    .required("Description is required")
    .matches(/^[A-Za-z ]+$/, 'Description name can only contain letters and spaces')
    .min(2, "Description name must be at least 10 characters")
    .max(32, "Description name must be at max 1000 characters")
    .label("Description"),
  answer_type: Yup.string()
    .required("Answer type is required")
    .label("Answer type"),
  is_active: Yup.string()
    .required("Is active is required")
    .label("Is active"),
  is_verified: Yup.string()
    .required("Is verified is required")
    .label("Is verified"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const QuestionnaireForm = ({ refreshData, id, open, closeForm, isEditMode }) => {
  const [form] = Form.useForm();
  const [allQuestionnaireCategory, setAllQuestionnaireCategory] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);
  const [displayNameSynced, setDisplayNameSynced] = useState(true);


  const getQuestionnaireData = async () => {
    if (id) {
      try {
        const response = await getQuestionnaireByIdService(id);
        const { name, code, display_name, department, questionnaire_category, description, answer_type, is_active, is_verified } =
          response?.data;
        form.setFieldsValue({
          name,
          code,
          display_name,
          department: department?.id,
          questionnaire_category: questionnaire_category?.id,
          description,
          answer_type,
          is_active,
          is_verified,
        });
        setDisplayNameSynced(true); 
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    }
  };


  const handleSubmit = async (values) => {
    try {
      if (id) {
        const response = await updateQuestionnaireByIdService(id, values);
        if ((response.status = 200 && response.success)) {
          message.success("Questionnaire successfully updated");
          refreshData();
          closeForm();
        }
      } else {
        const response = await createQuestionnaireService(values);
        if ((response.status = 201 && response.success)) {
          message.success("Questionnaire  successfully created");
          refreshData();
          closeForm();
        }
      }
      setDisplayNameSynced(true); 
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };

  // Fetch department data when the form is initially opened or for editing
  useEffect(() => {
    if (open) {
      // If it's opened for editing, fetch department data
      getQuestionnaireData();
    } else {
      form.resetFields();
      setDisplayNameSynced(true); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id]);

  const handleInput = (e, field) => {
    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    form.setFieldsValue({ [field]: newValue });
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ name: newValue });
    if (displayNameSynced) {
      form.setFieldsValue({ display_name: newValue });
    }
  };

  const handleDisplayNameChange = (e) => {
    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, "");
    newValue = capitalizeWords(newValue);
    form.setFieldsValue({ display_name: newValue });
    if (newValue === "") {
      setDisplayNameSynced(true); // Reset sync state if display_name is cleared
    } else {
      setDisplayNameSynced(false);
    }
  };
  useEffect(() => {
    const fetchAllDepartment = async () => {
      try {
        const response = await listDepartmentService();
        setAllDepartment(response.data);
      } catch (error) {
        message.error("Problem fetching sanction condition category!");
      }
    };
    fetchAllDepartment();
  }, []);

  useEffect(() => {
    const fetchAllQuestionnaireCategory = async () => {
      try {
        const response = await listQuestionnaireCategoryService();
        setAllQuestionnaireCategory(response.data);
      } catch (error) {
        message.error("Problem fetching sanction condition category!");
      }
    };
    fetchAllQuestionnaireCategory();
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        name: "",
        display_name: "",
        code: "",
        is_active: true,
        is_verified: true,
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="name" label="Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Name"
              onChange={handleNameChange}
            />
          </Form.Item>
        </Col>
        
        <Col span={12}>
          <Form.Item name="display_name" label="Display Name" rules={[yupSync]} required>
            <Input placeholder="Please Enter Display Name"
              onChange={handleDisplayNameChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {isEditMode ? (
          <Col span={12}>
            <Form.Item name="code" label="Code" rules={[yupSync]} required>
              <Input placeholder="Please Enter Code" onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, ""); }} />
            </Form.Item>
          </Col>
        ) : null}
        <Col span={12}>
          <Form.Item name="department" label="Department" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allDepartment?.map((department) => {
                return (
                  <Select.Option key={department.id} value={department.id}>
                    {department.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="questionnaire_category" label="Questionnaire Category" rules={[yupSync]} required>
            <Select
              mode="single"
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allQuestionnaireCategory?.map((questionnaire_category) => {
                return (
                  <Select.Option key={questionnaire_category.id} value={questionnaire_category.id}>
                    {questionnaire_category.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="description" label="Description" rules={[yupSync]} required>
            <TextArea placeholder="Please Enter Description"
              onChange={(e) => handleInput(e, "description")}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="answer_type" label="Answer type" rules={[yupSync]} required>
            <Select placeholder="Please Select">
              <Option value="Select">Select</Option>
              <Option value="Plain">Plain</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_active" label="Is Active" rules={[yupSync]} required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="is_verified" label="Is Verified" rules={[yupSync]} required>
            <Select placeholder="Please Select">
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Space
        direction="horizontal"
        align="center"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default QuestionnaireForm;
