import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Select, Upload, message } from "antd";
import getDocumentTypeList from "../../services/getDocumentTypeList";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import pdfIcon from "../../assets/pdf.png";
import RViewerJS from "viewerjs-react";

const { Option } = Select;

const FiUploadInputForm = ({ form, setDocumentUpload, documents }) => {
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [legalDocumentType, setLegalDocumentType] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [, setDeletePicture] = useState(null);
  const [, setDeleteUploadPicture] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imageId, setImageId] = useState();
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
      const [documentNames, setDocumentNames] = useState([]);

  const handleDocumentNameChange = (value,option) => {
    const selectedOption = legalDocumentType.find(
      (type) => type.id === value
    );
    const displayName = selectedOption?.display_name;

    setSelectedDocumentTypes((prevTypes) => {
      const updatedDocumentNames = fileList.map((file, index) =>
        index === option.index ? displayName : documentNames[index]
      );
  

    setDocumentNames(updatedDocumentNames);
      if (!prevTypes.includes(value)) {
        return [...prevTypes, value];
      }
      return prevTypes;
    });

    setUploadDisabled(!value);
  };

  const validationSchema = Yup.object().shape({
    upload_documents: Yup.mixed()
      .required("Document Name is required") // Ensures document selection is mandatory
      .label("Upload Document"),
  });

  const yupSync = {
    async validator({ field }, value) {
      if (fileList.length > 0) {
        // Skip validation if a document is already uploaded
        return Promise.resolve();
      } else {
        // Enforce validation if no document is uploaded
        await validationSchema.validateSyncAt(field, { [field]: value });
      }
    },
  };

  const handleUploadChange = async ({ fileList: newFileList }) => {
    // Prepare an array to store the images and document types
    // const freshImage = newFileList.map((file, index) => ({
    //   file_path: URL.createObjectURL(file.originFileObj || file),
    //   isPDF: file.type === 'application/pdf',
    //   id: index,
    // }));

    // Map through the files and associate them with their respective document types
    const freshImages = newFileList.map(async (file, index) => {
      let base64url = '';

      // Validate the file type first
      const isValidFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';

      if (!isValidFileType) {
        message.error('You can only upload JPG/PNG/PDF files!');
        return null;
      }

      // Convert file to Base64 if valid
      try {
        base64url = await convertFileToBase64(file.originFileObj);
      } catch (error) {
        message.error('Error converting file to base64.');
        return null;
      }

      // Map the correct document type for each file based on the selected index
      const documentType = selectedDocumentTypes[index]; // Use the index to select the appropriate type from selectedDocumentTypes
      return {
        file: base64url,
        document_type_id: documentType, // Assign the document type from the array

      };
    });
    // Wait for all file uploads to be processed
    const processedImages = await Promise.all(freshImages);
    const validImages = processedImages.filter((image) => image !== null);
    // Update the state with the valid images and associated document types
    setDocumentUpload(validImages); // Set the documents for upload
    // setImage(freshImage); // Update the image previews
    setFileList(newFileList); // Update the file list state
    setUploadDisabled(true); // Disable upload button once files are processed
    form.resetFields(['upload_documents']);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const confirmDelete = () => {
    const filteredImages=image.filter((image) => image.id !== imageId)
    setImage(filteredImages);

    setDocumentUpload(filteredImages);
    const filteredFileList = fileList.filter((file) => file.uid !== imageId);
    setFileList(filteredFileList); // Update the file list state
    setOpenUploadModal(false); // Close the modal
  };

  const handleBeforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
      return Upload.LIST_IGNORE;
    }
    const selectedType = legalDocumentType.find(
      (type) => type.id === form.getFieldValue("upload_documents")
    );
    const newImage = {
      id: file.uid, // Use file.uid to uniquely identify each file
      file_path: URL.createObjectURL(file),
      isPDF: file.type === "application/pdf",
    };
    setImage((prevImages) => [...prevImages, newImage]);
    setFileList((prevFileList) => [...prevFileList, file]);
    setDocumentNames((prevNames) => [
      ...prevNames,
      selectedType?.display_name || "Unknown",
    ]);
    return false; // Prevent automatic upload
  };

  const fetchDocumentTypeList = async () => {
    try {
      const response = await getDocumentTypeList();
      setLegalDocumentType(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents");
    }
  };

  useEffect(() => {
    fetchDocumentTypeList();

    form.resetFields(["upload_documents"]);
    setFileList([]);
    setImage([]);
    setUploadDisabled(true);
  
    if (documents && documents.length > 0) {
      const processedDocuments = documents.map((doc, index) => ({
        id: doc.id || index,
        file_path: doc.document_path,
        isPDF: doc.file_type === "application/pdf",
      }));
      setImage(processedDocuments);
    }
  }, [form, documents]);


  return (
    <div>
      <div className="property_description_heading">
        <div className="property_description_data">
          FI Documents and Other Documents
        </div>
        <div>

        </div>
      </div>

      <div>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="upload_documents"
              label="Enter Document Name"
              rules={image.length ? null : [yupSync]}
              required
            >
              <Select
                placeholder="Please select"
                allowClear

                showSearch
                onChange={handleDocumentNameChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {legalDocumentType?.map((legaldocumenttype) => (
                  <Option key={legaldocumenttype.name} value={legaldocumenttype.id}>
                    {legaldocumenttype.display_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="file_path" label=" ">
              <Upload
              single
                fileList={fileList}
                onChange={handleUploadChange}
                // onRemove={handleRemove}
                beforeUpload={handleBeforeUpload} // Handle file upload manually
                showUploadList={false} // Hide the file list
                disabled={uploadDisabled}
              >
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  className="select_upload_fi_input"
                 

               >
                  
                  Upload Document
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <div>
          <div className="fi_upload_input_form">
            {image?.map((picture, index) => (
              <div key={index} className="images_rv_form_two">
                {picture.isPDF ? (
                  <img
                    src={pdfIcon}
                    alt="PDF Icon"

                    className="rv_form_img"
                    onClick={() => window.open(picture.file_path, "_blank")} // Open PDF in new tab
                  />
                ) : (
                  <RViewerJS>
                    <img
                      src={picture.file_path}
                      alt=""

                      className="img_rv_ipnut_form"
                    />
                         <div className="technical_report_document_image_lable">
                              {documentNames[index]}
                            </div>{" "}
                  </RViewerJS>
                )}
                <div
                  className="delet_icon_rv_upload"
                  onClick={() => {
                    setDeleteUploadPicture(picture);
                    setImageId(picture.id);
                    setOpenUploadModal(true);
                  }}
                >
                  <DeleteOutlined />
                </div>
              </div>
            ))}
          </div>
        </div>

     
        <Modal
          title={<h4>Are you sure you want to delete this image?</h4>}
          open={openModal}
          // onOk={deletePropertyPictures}
          onCancel={() => {
            setOpenModal(false);
            setDeletePicture(null);
          }}
          okType="danger"
        ></Modal>
        <Modal
          title={<h4>Are you sure you want to delete this image?</h4>}
          open={openUploadModal}
          onOk={confirmDelete}
          onCancel={() => {
            setOpenUploadModal(false);
            setDeleteUploadPicture(null);
          }}
          okType="danger"
        ></Modal>
      </div>
    </div>
  );
};

export default FiUploadInputForm;
