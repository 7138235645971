import { Form, Row, Col, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { listEmploymentType } from "../services/employmentDetailsServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import SalariedForm from "./SalariedForm";
import SelfEmployedForm from "./SelfEmployedForm";
import PensionerForm from "./PensionerForm";
import RentalForm from "./RentalForm";

const validationSchema = Yup.object().shape({
  employment_type: Yup.string().required("Employment type is required"),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const EmploymentType = ({
  closeForm,
  open,
  refreshData,
  customer_id,
  loan_detail,
  onNext,
  id,
  employment_type_id,
  activeCustomerKey,
  activeEmploymentKey,
  isSalesForm,
}) => {
  const [form] = Form.useForm();
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState(employment_type_id);
  const [employmentId, setEmploymentID] = useState(id)

  const handleEmploymentTypeChange = (value) => {
    setSelectedEmploymentType(value);
  };
  useEffect(() => {
    setSelectedEmploymentType(employment_type_id)
    setEmploymentID(id)
    
  }, [activeEmploymentKey, employment_type_id, id])

//   useEffect(() => {
//     setSelectedEmploymentType(employment_type_id);
//     form.setFieldsValue({ employment_type: employment_type_id });
//   }, [employment_type_id, activeEmploymentKey]);

  // Fetch employment types for the dropdown
  useEffect(() => {
    if (open) {
      const getAllEmploymentTypes = async () => {
        try {
          const response = await listEmploymentType();
          setEmploymentTypes(response.data);
        } catch (error) {
          message.error(
            ErrorMessage(
              error?.response?.status,
              error?.response?.data?.message
            )
          );
        }
      };
      getAllEmploymentTypes();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setSelectedEmploymentType(null);
      setEmploymentID(null)
    }
  }, [open, form]);

  return (
    <>
      <Form form={form} layout="vertical" initialValues={{ employment_type: employment_type_id }}>
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col span={8}>
            <Form.Item
              name="employment_type"
              label="Employment Type"
              rules={[yupSync]}
              required
            >
              <Select
                // mode="single"
                placeholder="Please Select"
                required={true}
                allowClear
                onChange={handleEmploymentTypeChange}
                value={selectedEmploymentType || undefined}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={employment_type_id}
              >
                {employmentTypes?.map((Employment_type) => {
                  return (
                    <Select.Option
                      key={Employment_type.id}
                      value={Employment_type?.id}
                    >
                      {Employment_type?.display_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {/* Conditional rendering based on employment type selection */}
      {selectedEmploymentType === 1 && (
        <SalariedForm
          customer_id={customer_id}
          id={employmentId}
          closeForm={closeForm}
          refreshData={refreshData}
          open={open}
          selectedEmploymentType={selectedEmploymentType}
          loan_detail={loan_detail}
          onNext={onNext}
          activeCustomerKey={activeCustomerKey}
          activeEmploymentKey={activeEmploymentKey}
          isSalesForm={isSalesForm}
        />
      )}
      {selectedEmploymentType === 2 && (
        <SelfEmployedForm
          customer_id={customer_id}
          id={employmentId}
          closeForm={closeForm}
          refreshData={refreshData}
          open={open}
          selectedEmploymentType={selectedEmploymentType}
          loan_detail={loan_detail}
          onNext={onNext}
          activeCustomerKey={activeCustomerKey}
          activeEmploymentKey={activeEmploymentKey}
          isSalesForm={isSalesForm}
        />
      )}
      {selectedEmploymentType === 3 && (
        <RentalForm
          customer_id={customer_id}
          id={employmentId}
          closeForm={closeForm}
          refreshData={refreshData}
          open={open}
          selectedEmploymentType={selectedEmploymentType}
          loan_detail={loan_detail}
          onNext={onNext}
          activeCustomerKey={activeCustomerKey}
          activeEmploymentKey={activeEmploymentKey}
          isSalesForm={isSalesForm}
        />
      )}
      {selectedEmploymentType === 4 && (
        <PensionerForm
          customer_id={customer_id}
          id={employmentId}
          closeForm={closeForm}
          refreshData={refreshData}
          open={open}
          selectedEmploymentType={selectedEmploymentType}
          loan_detail={loan_detail}
          onNext={onNext}
          activeCustomerKey={activeCustomerKey}
          activeEmploymentKey={activeEmploymentKey}
          isSalesForm={isSalesForm}
        />
      )}
    </>
  );
};

export default EmploymentType;
