import {axiosRequest} from "../../../utils/api/axiosRequest"


export const createFiCombineForm = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/fi-combine/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const patchFiCombineForm = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch("/api/v1/loan/fi-combine/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const getInitiatedDataByLoanId = (loan_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/initiate-stage/get-credit-assessment-data-for-fi-input-form/${loan_id}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const createFIInputFormData = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post("/api/v1/loan/fi-combine/", data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const patchFIInputFormData = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.patch(`/api/v1/loan/fi-combine/get-customer-address-detail/${id}`, data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const stageToCompleteService = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(
          "/api/v1/loan/loan-stage-history/",
          data
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  };

  export const getInputformdata = (addressid) => { 
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.get(`/api/v1/loan/fi-combine/get-customer-address-detail/${addressid}`);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  }

  export const fifinalsubmit = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosRequest.post(`/api/v1/loan/fi-combine/check-form-fill-field-investigation-address`,data);
        return resolve(response.data)
      } catch (error) {
        return reject(error)
      }
    })
  } 

  