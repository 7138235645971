import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Divider, Space, message } from "antd";
import { createInitiatConsent, getCustomerMobileByIdService, accountAggregartorStatusService } from '../services/bankAccountDetailsService';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    customer_detail: Yup.string()
        .required("Mobile number is required")
        .matches(/^\d+$/, "Mobile number must contain only numeric characters")
        .min(10, "Mobile number must be exact 10 digits")
        .max(10, "Mobile number must be exact 10 digits")
        .label("Mobile number"),
    to_country_code: Yup.string()
        .max(2, "Country code must be exact 2 digits")
        .matches(/^[0-9 ]{2,}$|^$/, "Country code must contain only 2 digits"),
});

const yupSync = {
    async validator({ field }, value) {
        await validationSchema.validateSyncAt(field, { [field]: value });
    },
};

const AccountAggregatorForm = ({ customer_id, loan_id, closeForm, refreshData }) => {
    const [form] = Form.useForm();
    const [status, setStatus] = useState("");
    const [trackingId, setTrackingId] = useState(null);
    const [referenceId, setReferenceId] = useState(null);
    const [dataDetails, setDataDetails] = useState(null);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        const getCustomerById = async () => {
            if (customer_id) {
                try {
                    const response = await getCustomerMobileByIdService(customer_id);
                    form.setFieldsValue({
                        customer_detail: response?.data?.mobile_number,
                    });
                } catch (error) {
                    message.error(
                        ErrorMessage(error?.response?.status, error?.response?.data?.message)
                    );
                }
            }
        };
        getCustomerById();
    }, [customer_id, form]);


    const onFinish = async (values) => {
        const payload = {
            vuaId: `${values.customer_detail}@onemoney`,
            templateType: "UNDERWRITING",
            redirectionUrl: "http://google.com",
            customer_id: customer_id,
            loan_detail_id: loan_id,
        };
        try {
            const response = await createInitiatConsent(payload);
            const consentStatus = response?.data?.status;
            setStatus(consentStatus);
            setTrackingId(response?.data?.trackingId);
            setReferenceId(response?.data?.referenceId);

            if (consentStatus === "INITIATED") {
                message.success('Consent initiated successfully!');
            }

            refreshData();
            closeForm();
        } catch (error) {
            console.error(error);
            message.error('Failed to initiate consent. Please try again.');
        }
    };

    const handleRefresh = async () => {
        if (!trackingId || !referenceId) {
            message.warning("Tracking ID or Reference ID not available.");
            return;
        }
        try {
            const response = await accountAggregartorStatusService(trackingId, referenceId);
            if (response?.status === "COMPLETED") {
                setDataDetails(response.dataDetails);
                message.success("Data retrieval completed.");
            } else {
                message.info(`Status: ${response?.status}`);
            }
        } catch (error) {
            console.error(error);
            message.error("Failed to refresh data status.");
        }
    };

    const handleNumberFields = (e, field) => {
        let newValue = e.target.value.replace(/\D/g, "").slice(0, 10);
        form.setFieldsValue({ [field]: newValue });
    };

    return (
        <div>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <p className="manual_enter_block">
                    Get Data from Account Aggregator
                </p>
                <Row gutter={16}>
                    <Col span={9}>
                        <Form.Item
                            label={<span>Verify Customer’s mobile number for Consent </span>}
                            required
                        >
                            <Input.Group compact>
                                <Form.Item name="to_country_code" rules={[yupSync]} noStyle>
                                    <Input
                                        className="bank_country_code_block"
                                        disabled
                                        defaultValue="91"
                                    />
                                </Form.Item>
                                <Form.Item name="customer_detail" rules={[yupSync]} noStyle>
                                    <Input
                                        className="bank_mobile_number_field"
                                        placeholder="Please Enter Mobile Number"
                                        onChange={(e) => handleNumberFields(e, "customer_detail")}
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                </Row>

                {status === "INITIATED" && (
                    <p className="bank_initiated_message">
                        Please contact the customer to confirm their approval.
                    </p>
                )}

                <Space
                    direction="horizontal"
                    align="center"
                    className="customer_tabs_form_buttons"
                >
                    <Button onClick={handleRefresh}>Refresh</Button>
                    <Button type="primary" htmlType="submit">
                        Initiate Consent
                    </Button>
                </Space>

                <Divider plain>OR</Divider>

                {dataDetails && (
                    <div>
                        <h3>Data Details</h3>
                        {dataDetails.length > 0 ? (
                            dataDetails.map((session, index) => (
                                <div key={index}>
                                    <p><strong>Session ID:</strong> {session.sessionId}</p>
                                    <p><strong>Data Range:</strong> {session.dataRange.fromDate} - {session.dataRange.toDate}</p>
                                    <p><strong>Accounts:</strong></p>
                                    <ul>
                                        {session.accounts.map((account, accIndex) => (
                                            <li key={accIndex}>
                                                {account.fipName} - {account.accountNumber} ({account.fiType})
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))
                        ) : (
                            <p>No data available.</p>
                        )}
                    </div>
                )}
            </Form>
        </div>
    );
};

export default AccountAggregatorForm;
