import React, { useEffect, useState } from "react";
import { Button, Drawer, message } from "antd";
import {
  PlusCircleOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import CommonManualDeviationForm from "./CommonManualDeviationForm";
import AutoDeviation from "./AutoDeviation";
import "../styles/deviation.css";
import { decrypt } from "../../cryptoUtils/cryptoUtils";
import { useParams } from "react-router-dom";
import { getLegalDeviationList, getRcuDeviationList, getTechnicalDeviationList } from "../services/getDeviation";
import ErrorMessage from "../../errorHandling/ErrorMessage";
import CommonManualDeviation from "./CommonManualDeviation";

const CommonDeviation = ({ toggleRefreshTableData, refreshTableData, manualDeviationData, setManualDeviationData, operationType }) => {
  const [autoDeviationData,] = useState([]);
  const [deviationList, setDeviationList] = useState([]);
  const [selectedManual, setSelectedManual] = useState(false);
  const [manualDataValue, setManualDataValue] = useState(null);
  const [collapsedDeviation, setCollapsedDeviation] = useState(true);
  const [open, setOpen] = useState(false);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchDeviationList = async () => {
      try {
        let result = null;
        if (operationType === "RCU") {
          result = await getRcuDeviationList();
        } else if (operationType === "Legal") {
          result = await getLegalDeviationList();
        } else if (operationType === "Technical") {
          result = await getTechnicalDeviationList();
        }

        if (result) {
          setDeviationList(result?.data || []);
        }
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    if (operationType) {
      fetchDeviationList();
    }
  }, [operationType, refreshTableData]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedManual(false);
    setManualDataValue(null);
  };

  const handleAddManualDeviation = (newDeviation) => {
    const newItem = {
      id: Date.now(), // Add a unique ID for each new item
      loan_detail: loan_id,
      deviation: newDeviation.deviation,
      actual_description: newDeviation.actual_description,
      mitigates: newDeviation.mitigates
    };
    setManualDeviationData(prevData => [...prevData, newItem]);
  };

  const handleEditManualDeviation = (updatedValues, editingId) => {
    setManualDeviationData(prevData =>
      prevData.map(item => {
        // Only update the item with matching ID
        if (item.id === editingId) {
          return {
            ...item,
            deviation: updatedValues.deviation,
            actual_description: updatedValues.actual_description,
            mitigates: updatedValues.mitigates
          };
        }
        return item;
      })
    );
  };

  return (
    <>
      <Drawer
        title={selectedManual ? "Edit Manual Deviation" : "Add Manual Deviation"}
        width={800}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <CommonManualDeviationForm
          selectedManual={selectedManual}
          deviationList={deviationList}
          loan_id={loan_id}
          initialValues={manualDataValue}
          toggleRefreshTableData={toggleRefreshTableData}
          setManualDataValue={setManualDataValue}
          handleEditManualDeviation={handleEditManualDeviation}
          handleAddManualDeviation={handleAddManualDeviation}
          onClose={onClose}
        />
      </Drawer>
      <div>
        <div className={`deviation_main_container ${collapsedDeviation ? "with-border" : "no-border"}`}>
          <div className="deviation_heading_text">Deviation Details</div>
          <div>
            <Button
              icon={<PlusCircleOutlined />}
              className="add-sanction-condition-button"
              onClick={showDrawer}
            >
              Add Manual Deviation
            </Button>
            {collapsedDeviation ? (
              <UpOutlined onClick={() => setCollapsedDeviation(!collapsedDeviation)} />
            ) : (
              <DownOutlined onClick={() => setCollapsedDeviation(!collapsedDeviation)} />
            )}
          </div>
        </div>
        {collapsedDeviation && (
          <>
            <AutoDeviation autoDeviationData={autoDeviationData} />
            <CommonManualDeviation
              manualDeviationData={manualDeviationData}
              setManualDeviationData={setManualDeviationData}
              showDrawer={showDrawer}
              setManualDataValue={setManualDataValue}
              setSelectedManual={setSelectedManual}
              deviationList={deviationList}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CommonDeviation;