import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  message,
  Space,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import "../styles/PDInitiationForm.css";
import ErrorMessage from "../../../../../../utils/errorHandling/ErrorMessage";
import { decrypt } from "../../../../../../utils/cryptoUtils/cryptoUtils";
import {
  createPdInitiationDataService,
  createTelePdService,
  getAllPdType,
  getAllUserListService,
  getApplicantListByIdService,
  getCustomerMobileNumberByIdService,
  getUserPersonalDetailsServiceInPd,
  listAllDocumentCategoryService,
} from "../services/pdService";
import ConditionalRender from "../../../../../../utils/authorization/AuthorizeComponent";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  pd_type: Yup.string()
    .required("PD Type is required.")
    .min(1, "PD Type is required"),
  assign_to: Yup.string()
    .required("Assign to is required.")
    .min(1, "Assign to is required"),
  customer_name: Yup.string()
    .required("Customer name is required.")
    .min(1, "Customer name is required"),

  mobile_number: Yup.string().required("Mobile number is required."),
  // .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits."),
  agent_mobile_number: Yup.string()
    .required("Agent mobile number is required.")
    .matches(/^\d{10}$/, "Agent mobile number must be exactly 10 digits."),
  other_mobile_number: Yup.string()
    .required("Other mobile number is required.")
    .matches(/^\d{10}$/, "Other mobile number must be exactly 10 digits."),
});

const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const PDInitiationForm = ({ closeForm, open, setSubmitStageBar }) => {
  const [form] = Form.useForm();
  const { encrypted_loan_id } = useParams();
  const [allAgencies, setAllAgencies] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allDocumentCategory, setAllDocumentCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPdType, setSelectedPdType] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [customMobile, setCustomMobile] = useState("");
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);
  const [id, setId] = useState(null);
  const { user_data } = useSelector((state) => state.user);

  const handleDropdownChange = (value) => {
    const isOther = value === "Other";
    setIsOtherSelected(isOther);
    if (!isOther) {
      form.setFieldsValue({ other_mobile_number: undefined });
    }
  };

  const handleCustomMobileChange = (e) => {
    setCustomMobile(e.target.value);
  };

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  const getUserData = async () => {
    if (id) {
      try {
        const response = await getUserPersonalDetailsServiceInPd(id);
        const { mobile } = response?.data || {};
        form.setFieldsValue({ agent_mobile_number: mobile });
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Failed to fetch user data."
        );
      }
    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pdTypesResponse = await getAllPdType();
        setAllAgencies(pdTypesResponse.data.choices);

        const usersResponse = await getAllUserListService(loan_id);
        setAllUsers(usersResponse.data);

        const customerResponse = await getApplicantListByIdService(loan_id);
        const customerNames = customerResponse.data.customer_list.map(
          (customer) => ({
            id: customer.id,
            name: customer.name,
          })
        );
        setCustomerName(customerNames);
        if (customerNames.length > 0) {
          setCustomerId(customerNames[0].id);
        }

        const customermobileresponse = await getCustomerMobileNumberByIdService(
          customerNames[0]?.id
        );
        setMobileNumber(customermobileresponse.data);

        const documentCategoriesResponse =
          await listAllDocumentCategoryService();
        setAllDocumentCategory(documentCategoriesResponse.data);
      } catch (error) {
        message.error(error?.response?.data?.message || "Failed to fetch data");
      }
    };
    if (open) {
      fetchData();
    }
  }, [open, loan_id]);

  const handlePdTypeChange = (value) => {
    setSelectedPdType(value);
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedCategories(allDocumentCategory.map((category) => category.id));
    } else {
      setSelectedCategories([]);
    }
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedCategories(checkedValues);
    setSelectAll(checkedValues.length === allDocumentCategory.length);
  };

  const handleCustomerChange = async (value) => {
    setCustomerId(value);
    setIsCustomerSelected(!!value);
    if (!value) {
      setMobileNumber([]);
      setIsOtherSelected(false);
      form.setFieldsValue({
        mobile_number: undefined,
        other_mobile_number: undefined,
      });
      return;
    }
    try {
      const customermobileresponse = await getCustomerMobileNumberByIdService(
        value
      );
      const numbers = customermobileresponse.data;
      setMobileNumber(numbers);
      setIsOtherSelected(false);
      form.setFieldsValue({
        mobile_number: numbers[0],
        other_mobile_number: undefined,
      });
    } catch (error) {
      message.error("Failed to fetch mobile numbers");
    }
  };

  useEffect(() => {
    const assignTo = form.getFieldValue("assign_to");
    if (assignTo) {
      setId(assignTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("assign_to")]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await form.validateFields();

      const assignToId = form.getFieldValue("assign_to");
      if (values.pd_type === "Tele PD") {
        if (!values.customer_name || !values.mobile_number) {
          message.error(
            "Customer Name and Mobile Number are required for Tele PD"
          );
          return;
        }

        const toMobileNumber = isOtherSelected
          ? values.other_mobile_number
          : values.mobile_number;

        if (!toMobileNumber) {
          message.error("Mobile Number is required for Tele PD");
          return;
        }

        const formData = new FormData();
        formData.append("customer_detail", customerId);
        formData.append("loan_detail", loan_id);
        formData.append("from_mobile", values.agent_mobile_number);
        formData.append("to_mobile", toMobileNumber);
        formData.append("stage_initiate", "Pd");
        formData.append("pd_type", values.pd_type);
        formData.append("assign_to", assignToId);
        formData.append("stage", "MSPDDD4");
        formData.append("stage_status", "MSSTIDA4");
        formData.append("loan_stage", "4");

        const response = await createTelePdService(formData);
        if (response.status === 200 && response.success) {
          message.success("Call Successfully initiated");
          form.resetFields();
          setSelectedCategories([]);
          setSelectAll(false);
          closeForm();
        }
      } else {
        // Handle other PD initiation types
        if (selectedCategories.length === 0) {
          message.error("At least one checkbox should be selected");
          return;
        }

        const formData = new FormData();
        formData.append("loan_detail", loan_id);
        formData.append("stage_initiate", "Pd");
        formData.append("pd_type", values.pd_type);
        formData.append("assign_to", values.assign_to);
        formData.append("stage", "MSPDDD4");
        formData.append("stage_status", "MSSTIDA4");
        formData.append(
          "document_categories_array",
          JSON.stringify(selectedCategories)
        );

        const response = await createPdInitiationDataService(formData);
        if (response.status === 200 && response.success) {
          message.success("PD Initiated Successfully");
          setSubmitStageBar();
          form.resetFields();
          setSelectedCategories([]);
          setSelectAll(false);
          closeForm();
        }
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
    setSelectedCategories([]);
    setSelectAll(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeForm]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        picture_type: [],
        assign_to: user_data?.id,
        agent_mobile_number: user_data?.mobile || "",
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="pd_type"
            label="Select PD Type :"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              allowClear
              className="select_field"
              showSearch
              onChange={handlePdTypeChange} 
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allAgencies?.map((agency) => (
                <Select.Option key={agency.value} value={agency.value}>
                  {agency.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="assign_to"
            label="Assign To :"
            rules={[yupSync]}
            required
          >
            <Select
              mode="single"
              placeholder="Please select"
              allowClear
              className="select_field"
              showSearch
              onChange={(value) => setId(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allUsers?.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {selectedPdType === "Tele PD" && (
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="customer_name"
                label="Customer Name :"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  allowClear
                  className="select_field"
                  showSearch
                  onChange={handleCustomerChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {customerName?.map((cust) => (
                    <Select.Option key={cust.id} value={cust.id}>
                      {cust.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="mobile_number"
                label="Mobile Number :"
                rules={[yupSync]}
                required
              >
                <Select
                  mode="single"
                  placeholder="Please select"
                  allowClear
                  className="select_field"
                  showSearch
                  disabled={!isCustomerSelected}
                  onChange={handleDropdownChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {mobileNumber?.map((mobile) => (
                    <Select.Option key={mobile} value={mobile}>
                      {mobile}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {isOtherSelected && isCustomerSelected && (
              <Col span={8}>
                <Form.Item
                  name="other_mobile_number"
                  label="Other Mobile Number"
                  rules={[yupSync]}
                  required
                >
                  <Input
                    placeholder="Enter other mobile number"
                    value={customMobile}
                    onChange={handleCustomMobileChange}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="agent_mobile_number"
                label="Agent Mobile Number :"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Enter agent mobile number"
                  className="input_field"
                  // allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {selectedPdType === "Physical PD" && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="picture_type"
              label="Picture Type :"
              required
              rules={[
                {
                  validator: (_, value) =>
                    value && value.length > 0
                      ? Promise.resolve()
                      : Promise.reject(
                          "At least one checkbox should be selected"
                        ),
                },
              ]}
            >
              <div className="pd_inintion_checkbox">
                <div className="pd_inintion_checkbox_second">
                  <Row gutter={16}>
                    <Col span={24}>
                      <span className="pd_checkbox_content">
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                        >
                          Select All
                        </Checkbox>
                      </span>
                      <Checkbox.Group
                        className="select_field"
                        value={selectedCategories}
                        onChange={handleCheckboxChange}
                      >
                        <Row>
                          {allDocumentCategory?.map((document_category) => (
                            <Col span={6} key={document_category.id}>
                              <div className="checkbox_content">
                                <Checkbox value={document_category.id}>
                                  {document_category.display_name}
                                </Checkbox>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Space
        direction="horizontal"
        align="center"
        className="fi_button_inition"
      >
        <ConditionalRender
          userPermissions={user_data?.permissions}
          requiredPermissions={["PDINITIA"]}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Initiate
          </Button>
        </ConditionalRender>
        <Button onClick={closeForm}>Cancel</Button>
      </Space>
    </Form>
  );
};

export default PDInitiationForm;
