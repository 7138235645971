import { axiosRequest } from "../../../../../../utils/api/axiosRequest";

export const createLegalInitiationDataService = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.post("/api/v1/loan/initiate-stage/technical-initiate", data);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllDataTechnicalInitiationService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/agency-type/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllAgentService = (code, loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/agent/agency-agent-list?code=${code}&loan_detail=${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getAllAgencyService = (params) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await axiosRequest.get(`api/v1/master/agency/agency-list-by-agency-type?code=${params}`);
          return resolve(response.data)
      } catch (error) {
          return reject(error)
      }
  })
}
export const getAllAgencyTypeService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`api/v1/master/agency-type/list`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getAllUserListService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/user/get-user-list-by-loan-location?loan_id=${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getPropertyDetailsById = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/property-detail/property-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}
export const getBasicDetailsDetailsById = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}


export const getLoanDetailsById = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/loan/loan-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const getCustomerNameByIdService = (loan_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosRequest.get(`/api/v1/customer/customer-detail/personal-detail/${loan_id}`);
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
}