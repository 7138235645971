import { Tabs, message, Modal } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveDependentKey,
  setInitialCustomerDependent,
  setDependentApicalled,
  removeDependent,
} from "../../../../redux/salesFormSlice";
import DependentDetailsForm from "../../../loanApplication/customerTabDetails/dependentDetails/views/DependentDetailsForm";
import { getDependentsTabsData, deleteDependentsTabsData } from "../../services/salesformservices";

const SalesFormDependentForm = ({ activeCustomer }) => {
  const { customers } = useSelector((state) => state.salesForm);
  const [openModal, setOpenModal] = useState(false); // State for controlling Modal visibility
  const [targetKey, setTargetKey] = useState(null); // State to store the target key of the tab to be deleted

  const dependentsDetails =
    customers[parseInt(activeCustomer)]?.dependentDetails?.dependents;

  const activeDependentKey =
    customers[parseInt(activeCustomer)]?.dependentDetails?.activeDependentKey;

  const dependentApiCalled =
    customers[parseInt(activeCustomer)]?.dependentDetails?.dependentapicalled;

  const customerId = customers[parseInt(activeCustomer)]?.id;

  const dispatch = useDispatch();

  useEffect(() => {
    const getCustomerDependents = async () => {
      const response = await getDependentsTabsData(customerId);
      if (response?.data?.dependent_detail.length === 0) {
        dispatch(
          setInitialCustomerDependent({
            activeCustomer: activeCustomer,
            dependents: [null],
          })
        );
        if (dependentApiCalled === 0) {
          dispatch(setDependentApicalled({ index: activeCustomer }));
        }
      } else {
        dispatch(
          setInitialCustomerDependent({
            activeCustomer: activeCustomer,
            dependents: response?.data?.dependent_detail,
          })
        );
        if (dependentApiCalled === 0) {
          dispatch(setDependentApicalled({ index: activeCustomer }));
        }
      }
    };

    if (dependentApiCalled === 0) {
      getCustomerDependents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId, activeCustomer, dependentApiCalled]);

  const onChange = (key) => {
    dispatch(
      changeActiveDependentKey({
        activeCustomer: activeCustomer,
        key: key,
      })
    );
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      addNewDependent();
    } else {
      setTargetKey(targetKey); // Set the target key when a tab is about to be deleted
      setOpenModal(true); // Open the confirmation modal
    }
  };

  const handleOk = async () => {
    const dependent = dependentsDetails[parseInt(targetKey)];

    if (dependent) {
      if (dependentsDetails.length > 1) {
        try {
          const response = await deleteDependentsTabsData(dependent);

          if (response.status === 200 && response.success === true) {
            message.success("Tab deleted successfully");

            dispatch(
              removeDependent({
                activeCustomerKey: activeCustomer,
                activeDependentKey: targetKey,
              })
            );

            const newActiveKey = activeDependentKey > 0 ? activeDependentKey - 1 : 0;
            dispatch(
              changeActiveDependentKey({
                activeCustomer: activeCustomer,
                key: newActiveKey,
              })
            );
          } else {
            message.error("Failed to delete the tab");
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "Error deleting tab");
        }
      } else {
        message.error("Cannot delete the last tab");
      }
    } else {
      if (dependentsDetails.length > 1) {
        dispatch(
          removeDependent({
            activeCustomerKey: activeCustomer,
            activeDependentKey: targetKey,
          })
        );

        const newActiveKey = activeDependentKey > 0 ? activeDependentKey - 1 : 0;
        dispatch(
          changeActiveDependentKey({
            activeCustomer: activeCustomer,
            key: newActiveKey,
          })
        );
      } else {
        message.error("Cannot delete the last tab");
      }
    }

    setOpenModal(false); 
  };

  const handleCancel = () => {
    setOpenModal(false); 
  };

  const addNewDependent = () => {
    const updatedDependents = [...dependentsDetails, null];

    dispatch(
      setInitialCustomerDependent({
        activeCustomer: activeCustomer,
        dependents: updatedDependents,
      })
    );

    dispatch(
      changeActiveDependentKey({
        activeCustomer: activeCustomer,
        key: updatedDependents.length - 1,
      })
    );
  };

  return (
    <div>
      <Tabs
        type="editable-card"
        onChange={onChange}
        onEdit={onEdit}
        activeKey={activeDependentKey.toString()}
      >
        {dependentsDetails?.map((dependent, index) => (
          <TabPane tab={`Dependent ${index + 1}`} key={index}>
            <DependentDetailsForm
              activeCustomer={activeCustomer}
              dependent={dependent}
              isSalesForm={true}
              customer_id={customerId}
              id={dependentsDetails[activeDependentKey]}
              activeDependentKey={activeDependentKey}
            />
          </TabPane>
        ))}
      </Tabs>

      <Modal
        title="Confirm Delete"
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okType="danger"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </div>
  );
};

export default SalesFormDependentForm;
