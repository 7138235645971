import CryptoJS from 'crypto-js';

// Access the secret key from environment variables
// const secretKey = process.env.REACT_APP_SECRET_KEY;

// export const encrypt = (text) => {
//   if (!text) {
//     console.warn("Attempted to encrypt a null or undefined value");
//     return "";
//   }
//   const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
//   // Replace '/' with a different character or remove it
//   const modifiedEncrypted = encrypted.replace(/\//g, '@'); // Replace '/' with '@'
 
//   return modifiedEncrypted;
// };



const secretKey = process.env.REACT_APP_SECRET_KEY;

export const encrypt = (text) => {
  if (!text) {
    console.warn("Attempted to encrypt a null or undefined value");
    return "";
  }
  const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
  
  // Replace '/' with '@' and remove '=' characters
  const modifiedEncrypted = encrypted.replace(/\//g, '@').replace(/=*$/, ''); // Remove trailing '='

  return modifiedEncrypted;
};

export const decrypt = (encryptedText) => {
  if (!encryptedText) {
    console.warn("Attempted to decrypt a null or undefined value");
    return "";
  }
  try {
    const modifiedEncryptedText = encryptedText.replace(/@/g, '/'); // Revert back '@' to '/'
  
    const bytes = CryptoJS.AES.decrypt(modifiedEncryptedText, secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedText) throw new Error('Decryption failed');
     
    return decryptedText;
  } catch (error) { 
    return null;
  }
};

export const navigateWithEncryptedId = (navigate, path, id,data) => {
  const encryptedId = encrypt(id);
    navigate(`${path}/${encryptedId}`, { state: data });
};

