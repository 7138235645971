import { Table } from 'antd';
import React, { useState } from 'react'
import IndianNumberFormat from '../../../../../utils/indianNumberFormat/IndianNumberFormat';
import { useSelector } from 'react-redux';
import ConditionalRender from '../../../../../utils/authorization/AuthorizeComponent';
import { UpOutlined, DownOutlined } from "@ant-design/icons";

const IncomeCashView = ({ nonCashIncomeData, renderEditButton, showDrawer }) => {
    const { user_data } = useSelector((state) => state.user);
    const [collapseSections, setCollapseSections] = useState(true);

    const Noncashcolumns = [
        {
            title: 'SR.NO',
            dataIndex: 'key',
            key: 'key',
            fixed: 'left',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Month & Year',
            dataIndex: 'month_of_salary',
            render: (text) => (text !== null && text !== undefined && text !== "" ? text : '-'),
        },
        {
            title: 'Monthly Net Salary In Cash',
            dataIndex: 'monthly_net_salary_cash',
            render: (text) => (text !== null && text !== undefined && text !== "" ? `₹${IndianNumberFormat(text)}` : '-'),
        },
        {
            title: 'Rental Income - Cash',
            dataIndex: 'rental_income_cash',
            key: 'rental_income_cash',
            render: (text) => (text !== null && text !== undefined && text !== "" ? `₹${IndianNumberFormat(text)}` : '-'),
        },
        {
            title: 'Rental Income - Non Cash',
            dataIndex: 'rental_income_non_cash',
            key: 'rental_income_non_cash',
            render: (text) => (text !== null && text !== undefined && text !== "" ? `₹${IndianNumberFormat(text)}` : '-'),
        },
        {
            title: 'Dividend / Interest',
            dataIndex: 'dividend_or_interest',
            key: 'dividend_or_interest',
            render: (text) => (text !== null && text !== undefined && text !== "" ? `₹${IndianNumberFormat(text)}` : '-'),
        },
        {
            title: 'Agricultural Income',
            dataIndex: 'agricultural_income',
            key: 'agricultural_income',
            render: (text) => (text !== null && text !== undefined && text !== "" ? `₹${IndianNumberFormat(text)}` : '-'),
        },
    ];

    return (
        <>
            {nonCashIncomeData.length > 0 && (
                <div className="heading_details_container">
                    <div className={`heading_text_cam_income_details ${collapseSections ? "" : "no-border"}`}>
                        <div>Income details</div>
                        {collapseSections ? (
                            <UpOutlined
                                onClick={() => setCollapseSections(!collapseSections)}
                            />
                        ) : (
                            <DownOutlined
                                onClick={() => setCollapseSections(!collapseSections)}
                            />
                        )}
                    </div>
                    {collapseSections && (
                        <div>
                            {nonCashIncomeData.map((data) => (
                                <div key={data.id} style={{ marginBottom: "20px" }}>
                                    <div className="heading_text">{data.customer?.name}
                                        <ConditionalRender
                                            userPermissions={user_data?.permissions}
                                            requiredPermissions={["EDITCAM1"]}
                                        >
                                            {renderEditButton(showDrawer, data.customer?.id)}
                                        </ConditionalRender>
                                    </div>
                                    <div style={{ overflowX: "auto" }}>
                                        <Table
                                            className="insurance_table"
                                            columns={Noncashcolumns}
                                            dataSource={data.income_detail} // Wrap the data in an array to render it in the table
                                            pagination={false}
                                            rowKey="id"

                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default IncomeCashView