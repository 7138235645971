import { Col, Form, Radio, Row } from "antd";
import React, { useMemo } from "react";

const ICICIQuestion = ({ 
  index, 
  item, 
  selectedValue = {}, 
  handleRadioChange, 
  questionRemark, 
  insurance_id, 
  text 
}) => {
  // Memoized calculations for performance and clarity
  const radioValue = useMemo(() => {
    // Reset radioValue if selectedValue is empty
    if (!selectedValue || Object.keys(selectedValue).length === 0) {
      return undefined;
    }
    // Handle individual item selection
    return selectedValue.hasOwnProperty(item.id) 
      ? selectedValue[item.id] 
      : undefined;
  }, [selectedValue, item.id]);

  // Special condition checks
  const newQuesOccupation = item?.question.includes(
    "Is your occupation associated with any specific hazard"
  );
  const checkForFemale = item?.question.includes("Only For Female Customers");

  // Conditional rendering logic
  const shouldRenderRadio = useMemo(() => {
    if (newQuesOccupation || checkForFemale) return false;
    
    if (text === "personal") {
      return index !== 0 && index !== 2;
    }
    
    return true;
  }, [text, index, newQuesOccupation, checkForFemale]);


  return (
    <div>
      <p className="insurance_personal_details_heading">
        {checkForFemale ? null : index >= 7 ? index + 1 : index + 1}.{" "}
        {item.question}
      </p>
      
      {shouldRenderRadio && (
        <Form.Item
          name={`radio_${item.id}`}
          rules={[{ required: true, message: "Please select an answer" }]}
          initialValue={
            insurance_id 
              ? (item.answer === "no" ? 0 : 1) 
              : null
          }
        >
          <Radio.Group
            value={radioValue}
            onChange={(e) => {
              // Enhanced change handler with additional logging
              handleRadioChange(item.id, e);
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Radio value={1}>Yes</Radio>
              </Col>
              <Col span={12}>
                <Radio value={0}>No</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      )}
    </div>
  );
};

export default ICICIQuestion;