import { Steps, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { changeStepperActiveKey } from "../../../../redux/salesFormSlice";
 

const SalesFormStepper = ({
  newCurrent,
  items,
  activeCustomer,
  customerId,
  customerKey
}) => {
  const dispatch = useDispatch();

  const onChange = (value) => {
    if (customerId === null && value > 1) {
      message.error("Please fill personal details form");

      dispatch(
        changeStepperActiveKey({
          index: activeCustomer,
          value: newCurrent,
        })
      );
    } else {
      dispatch(
        changeStepperActiveKey({
          index: activeCustomer,
          value,
        })
      );
       
      

    }
  };

  // Filter items to exclude those without a title and update statuses dynamically
  const customizedItems = items
    .filter((item) => item?.title) // Exclude steps without a title
    .map((item, index) => ({
      ...item,
      status:
        index < newCurrent ? "wait" : index === newCurrent ? "process" : "wait",
    }));

  return (
    <Steps
      current={newCurrent}
      onChange={onChange}
      direction="vertical"
      items={customizedItems}
    />
  );
};

export default SalesFormStepper;
