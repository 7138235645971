import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Space, Select, message } from "antd";
import { getRemarkDataByIdService, listOverallStatusTypeService, updateRemarkDataByIdService } from "../services/documentDetailsServices";
import * as Yup from "yup";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";

const { TextArea } = Input;
const { Option } = Select;

const validationSchema = Yup.object().shape({
  overall_status: Yup.string().required("Overall Status is required"),
  remark: Yup.string().required("Property Address is required"),
});

const ObservationPropertyForm = ({ refreshData, id, open, closeForm, stage_id }) => {

  const [form] = Form.useForm();
  const [technicalid, setTechnicalid] = useState()
  const [overallstatus, setOverallstatus] = useState([]);


  const fetchoverallstatus = async () => {
    try {
      const response = await listOverallStatusTypeService();

      setOverallstatus(response.data.choices)
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };


  useEffect(() => {
    if (open) {
      getPersonalData();
    } else {
      form?.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, form]);

  useEffect(() => {
    if (open) {

      fetchoverallstatus();
    } else {
      form.resetFields();
    }

  }, [open, form]);

  const getPersonalData = async () => {

    try {
      const response = await getRemarkDataByIdService(stage_id);
      setTechnicalid(response.data.technical_detail[0].id)

      let {
        overall_status,
        remark
      } = response?.data?.technical_detail[0];
      form?.setFieldsValue({
        overall_status,
        remark
      });
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }

  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,

    }
    try {

      const response = await updateRemarkDataByIdService(technicalid, payload);

      if (response && response?.data) {
        if (response?.status === 200 && response?.success) {
          message.success("Property Specific Remarks & Observation updated successfully");
          refreshData();
          closeForm();
        }
      } else {
        message.error("Failed to update Property Specific Remarks & Observation");
      }

    } catch (error) {
      closeForm();
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
    }
  };




  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          // overall_status: "yes",
          // remark: "",
        }}
        onFinish={handleSubmit}

        validationSchema={validationSchema} // Integrate Yup validation schema
      >
        <Row gutter={16} >
          <Col span={8}>
            <Form.Item
              name="overall_status"
              label="Overall Status"
              rules={[{ required: true, message: "Overall Status is required" }]}
            >

              <Select
                mode="single"
                placeholder="Please Select"
                allowClear
                className="selct_field_observation"
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

              >
                {overallstatus?.map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </Select>

            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="remark"
          label="Remarks /Observation ( please enter NA if none)"
          rules={[{ required: true, message: "Remarks/Observation is required" }]}
        >
          <TextArea
            showCount
            maxLength={100}
            className="observation_text_area"
          />

        </Form.Item>
        <Space
          direction="horizontal"
          align="center"
          className="save_cancle_button_observation"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </>
  );
};

export default ObservationPropertyForm;
