import React, { useEffect, useState } from "react";
import "../styles/loanInsurance.css";
import { Button, message, Drawer, Table } from "antd";
import "../../../../../utils/styles/heading.css";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";

import {
  getChargesByIdService,
  getLoanInsuranceByIdService,
  getInsurancesByIdService,
  getInsuranceDetailsCamService,
} from "../services/loanInsuranceService";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../../utils/cryptoUtils/cryptoUtils";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import InsuranceForm from "./InsuranceForm";
import Header from "../../../../layout/views/Header";
import HDFCInsuranceview from "./HDFCInsuranceview";
import MagmaHDIInsuranceview from "./MagmaHDIInsuranceview";
import ICICIInsuranceview from "./ICICIInsuranceview";
import Loading from "../../../../../utils/loading/Loading";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../../../utils/authorization/AuthorizeComponent";
import ChargesUnderwritingForm from "./ChargesUnderwritingForm";
import CustomNoData from "../../../../../utils/noDataIcon/CustomNoData";
import { LTVCalculationsByLoanId } from "../../IncomeEvaluation/services/incomeDetailsServices";
import ErrorMessage from "../../../../../utils/errorHandling/ErrorMessage";
import IndianNumberFormat from "../../../../../utils/indianNumberFormat/IndianNumberFormat";

const LoanInsurance = () => {
  const [basicData, setBasicData] = useState(null);
  const [chargesData, setChargesData] = useState(null);
  const { encrypted_loan_id } = useParams();
  const [activeButton, setActiveButton] = useState(null); // State to track active insurance ID
  const [selectedInsurance, setSelectedInsurance] = useState(null); // State to track selected insurance
  const [collapseSections, setCollapseSections] = useState(true);
  const [loanDetailsCollapseSections, setLoanDetailsCollapseSections] =
    useState(true);
  const [chargesCollapseSections, setChargesCollapseSections] = useState(true);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [refeshInsurance, setRefreshInsurance] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [openCharges, setOpenCharges] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [selectedCharges, setSelectedCharges] = useState(null);
  const [getLTVCalculation, setLTVCalculation] = useState([]);

  const { user_data } = useSelector((state) => state.user);

  const showChargesDrawer = () => {
    setOpenCharges(true);
  };

  const onCloseChargesDrawer = () => {
    setOpenCharges(false);
    setSelectedCharges(null);
  };

  const showChargesEditDrawer = () => {
    setOpenCharges(true);
  };

  const toggleRefreshInsurance = () => {
    setRefreshInsurance((prev) => !prev);
  };

  const toggleRefreshTableData = () => {
    setRefreshTableData((prev) => !prev);
  };

  const columns = [
    {
      title: "Charge Name",
      dataIndex: "charge",
    },

    {
      title: "Charge Percentage",
      dataIndex: "charge_percentage",
      render: (text) => {
        return text !== undefined && text !== null ? `${text}%` : "-";
      },
    },

    {
      title: "Charge Amount",
      dataIndex: "charge_amount",
      render: (text) => {
        return text ? `₹${new Intl.NumberFormat("en-IN").format(text)}` : "-";
      },
    },

    {
      title: "Charge Gst Percentage",
      dataIndex: "charge_gst_percentage",
      render: (text) => {
        return text !== undefined && text !== null ? `${text}%` : "-";
      },
    },
    {
      title: "Charge GST Amount",
      dataIndex: "charge_gst_amount",
      render: (text) => {
        return text ? `₹${new Intl.NumberFormat("en-IN").format(text)}` : "-";
      },
    },

    {
      title: "Total Amount",
      dataIndex: "total_amount",
      render: (text) =>
        text && !isNaN(text)
          ? `₹${new Intl.NumberFormat("en-IN").format(text)}`
          : "-",
    },

    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        return (
          <div>
            <EditOutlined
              onClick={() => {
                setSelectedCharges(record);
                showChargesEditDrawer();
              }}
            />
          </div>
        );
      },
    },
  ];

  let loan_id = null;
  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error("Invalid Loan ID");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getLoanInsuranceByIdService(loan_id);
        setBasicData(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [loan_id]);

  useEffect(() => {
    const fetchChargesData = async () => {
      try {
        setLoading(true);
        const res = await getChargesByIdService(loan_id);
        setChargesData(res.data.charge_details);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching charges data:", error);
        setLoading(false);
      }
    };


    const fetchLTVCalculation = async () => {
      try {
          const response = await LTVCalculationsByLoanId(loan_id);
          setLTVCalculation(response.data);
      } catch (error) {
          message.error(
              ErrorMessage(error?.response?.status, error?.response?.data?.message)
          );
      } finally {
          setLoading(false); // Stop loading regardless of success or error
      }
  };

  fetchLTVCalculation();
    fetchChargesData();
  }, [refreshTableData, loan_id]);

  useEffect(() => {
    const fetchInsuranceData = async () => {
      try {
        const res = await getInsurancesByIdService(loan_id);
        setInsurances(res.data);
        if (res.data.length > 0) {
          setSelectedInsurance(res.data[0]);
          setActiveButton(res.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching charges data:", error);
        setLoading(false);
      }
    };
    fetchInsuranceData();
  }, [loan_id, refeshInsurance]);

  const data = chargesData
    ? chargesData.map((item, index) => ({
        key: index.toString(),
        id: item.id,
        no: (index + 1).toString(),
        charge: item.charge || "-",
        charge_percentage: item.charge_percentage || "-",
        charge_amount: item.charge_amount || "-",
        charge_gst_percentage: item.charge_gst_percentage || "-",
        charge_gst_amount: item.charge_gst_amount || "-",
        total_amount: item.total_amount || "-",
      }))
    : [];

  const showDrawer = async () => {
    try {
      setLoading(true); // Show loading indicator while fetching data
      const response = await getInsuranceDetailsCamService(loan_id); // Call the API
      if ((response.status = 200 && response.success)) {
        setOpen(true);
      }
    } catch (error) {
      message.error("Please complete Cam, then fill insurance detail.");
    } finally {
      setLoading(false);
    }
  };


  // const showDrawer = () => setOpen(true);
  const onClose = () => {
    setCount(0);
    setOpen(false);
    toggleRefreshInsurance();
    setDisabledButton(true);
  };

  const handleButtonClick = (insurance) => {
    setActiveButton(insurance.id);
    setSelectedInsurance(insurance);
  };

  const renderInsuranceView = () => {
    if (!selectedInsurance) return null;
    switch (selectedInsurance.insurance_company_detail__code) {
      case "MICHHDF4":
        return (
          <HDFCInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
          />
        );
      case "MICAMIM2":
        return (
          <ICICIInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
          />
        );
      case "MICMADD2":
        return (
          <MagmaHDIInsuranceview
            insurance_id={selectedInsurance.id}
            loan_id={loan_id}
            toggleRefreshInsurance={toggleRefreshInsurance}
            setDisabledButton={setDisabledButton}
            disabledButton={disabledButton}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        title={
          <Header
            title={selectedCharges ? "Edit" : "Add"}
            onClose={onCloseChargesDrawer}
            name={`Charges`}
          />
        }
        width={750}
        onClose={onCloseChargesDrawer}
        open={openCharges}
        closable={false}
      >
        <ChargesUnderwritingForm
          refreshData={toggleRefreshTableData}
          id={selectedCharges && selectedCharges?.id}
          loan_id={loan_id}
          open={openCharges}
          closeForm={onCloseChargesDrawer}
        />
      </Drawer>
      <Drawer
        title={<Header title={"Add"} onClose={onClose} name="Insurance" />}
        bodyStyle={{ padding: "0px" }}
        width={1000}
        onClose={onClose}
        open={open}
        closable={false}
      >
        <InsuranceForm
          id={loan_id}
          open={open}
          closeForm={onClose}
          toggleRefreshInsurance={toggleRefreshInsurance}
          count={count}
          setCount={setCount}
          setDisabledButton={setDisabledButton}
          disabledButton={disabledButton}
        />
      </Drawer>
      <div className="fade-in">
        <div className="heading_details_container">
          <div
            className={`heading_text ${
              loanDetailsCollapseSections ? "" : "no-border"
            }`}
          >
            <div>Loan Details</div>
        
            <div>
              {loanDetailsCollapseSections ? (
                <UpOutlined
                  onClick={() =>
                    setLoanDetailsCollapseSections(!loanDetailsCollapseSections)
                  }
                />
              ) : (
                <DownOutlined
                  onClick={() =>
                    setLoanDetailsCollapseSections(!loanDetailsCollapseSections)
                  }
                />
              )}
            </div>
          </div>

          {loanDetailsCollapseSections && (
            <>
              {basicData ? (
                <>
                <div className="loan_and_insurance_container">
          
                  <div className="laon_insurance_Basic_card">
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">
                        Application Number
                      </div>

                      <div className="loan_inusurance_data">
                        {basicData?.application_number || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">
                        Loan Amount
                      </div>

                      <div className="loan_inusurance_data">
                        {basicData?.additional_loan_detail?.approved_amount
                          ? `₹${IndianNumberFormat(
                              basicData?.additional_loan_detail?.approved_amount
                            )}`
                          : "-"}
                      </div>

                      <div className="loan_insurance_basic_card_content">
                        <div className="loan_inusurance_label">
                          Insurance amount
                        </div>

                        <div className="loan_inusurance_data">
                        ₹ {IndianNumberFormat(basicData?.insurance_amount) || "-"}
                        </div>
                      </div>
                    </div>
                    {/* <div className="laon_insurance_Basic_card"> */}
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Tenure</div>
                      <div className="loan_inusurance_data">
                        {basicData?.additional_loan_detail?.approved_tenure || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Appraised Obligations 3234</div>
                      <div className="loan_inusurance_data">
                      {getLTVCalculation?.approved_amount ? `₹ ${IndianNumberFormat(getLTVCalculation.approved_amount)}` : '-'}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Max EMI</div>
                      <div className="loan_inusurance_data">
                      {getLTVCalculation?.max_emi ? `₹ ${IndianNumberFormat(getLTVCalculation?.max_emi)}` : '-'}
                      </div>
                    </div>
             
                    {/* </div> */}
                  </div>
                  <div className="laon_insurance_Basic_card">
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Scheme </div>
                      <div className="loan_inusurance_data">
                        {basicData?.scheme?.display_name || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Branch</div>
                      <div className="loan_inusurance_data">
                        {basicData?.location?.display_name || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Transaction Type </div>
                      <div className="loan_inusurance_data">
                        {basicData?.transaction_type|| "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Risk Categorization</div>
                      <div className="loan_inusurance_data">
                        {basicData?.risk_categorization || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">EMI Factor</div>
                      <div className="loan_inusurance_data">
                      {getLTVCalculation?.emi_factor ? `₹ ${IndianNumberFormat(getLTVCalculation.emi_factor)}` : '-'}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Eligibility (In ₹)</div>
                      <div className="loan_inusurance_data">
                      {getLTVCalculation?.eligibility ? `₹ ${IndianNumberFormat(getLTVCalculation.eligibility)}` : '-'}
                      </div>
                    </div>
                
                  </div>
                  <div className="laon_insurance_Basic_card">
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">User</div>
                      <div className="loan_inusurance_data">
                        {basicData?.applicant_name || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Product</div>
                      <div className="loan_inusurance_data">
                        {basicData?.product_end_use?.display_name || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Due Date</div>
                      <div className="loan_inusurance_data">
                        {basicData?.emi_due_date || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">EMI</div>
                      <div className="loan_inusurance_data">
                      ₹ {IndianNumberFormat(basicData?.additional_loan_detail?.emi) || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Value of the property(₹ In lacs)</div>
                      <div className="loan_inusurance_data">
                      {getLTVCalculation?.property_value ? `₹ ${IndianNumberFormat(getLTVCalculation.property_value)}` : '-'}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Total Income</div>
                      <div className="loan_inusurance_data">
                      {getLTVCalculation?.total_income ? `₹ ${IndianNumberFormat(getLTVCalculation.total_income)}` : '-'}                      </div>
                    </div>
                    
                  </div>
                  <div className="laon_insurance_Basic_card">
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Interest Type</div>
                      <div className="loan_inusurance_data">
                        {basicData?.interest_type || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Rate Of Interest</div>
                      <div className="loan_inusurance_data">
                        {basicData?.interest_type || "-"}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">
                        Actual FOIR(including present loan)
                      </div>
                      <div className="loan_inusurance_data">
                      {` ${getLTVCalculation?.foir || '-'}`}
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">Actual LTV</div>
                      <div className="loan_inusurance_data">
                      {`${getLTVCalculation?.actual_ltv || '-'}`}%
                      </div>
                    </div>
                    <div className="loan_insurance_basic_card_content">
                      <div className="loan_inusurance_label">ROI</div>
                      <div className="loan_inusurance_data">
                      {` ${getLTVCalculation?.approved_roi || '-'}`}%
                      </div>
                    </div>
                  </div>
                </div>
                </>
              ) : (
                <div className="no_data_found_message">
                  <CustomNoData />
                </div>
              )}
            </>
          )}
        </div>

        
        <div className="heading_details_container">
          <div
            className={`heading_text_insurance_details ${
              collapseSections ? "" : "no-border"
            }`}
          >
            <div className="insurance-details-heading">Insurance Details</div>
            <div>
              <ConditionalRender
                userPermissions={user_data?.permissions}
                requiredPermissions={["POSTINSR"]}
              >
                <Button
                  icon={<PlusCircleOutlined />}
                  className="add-insurance-button"
                  onClick={() => showDrawer("External")}
                >
                  Add Insurance
                </Button>
              </ConditionalRender>
              {collapseSections ? (
                <UpOutlined
                  onClick={() => setCollapseSections(!collapseSections)}
                />
              ) : (
                <DownOutlined
                  onClick={() => setCollapseSections(!collapseSections)}
                />
              )}
            </div>
          </div>
          {collapseSections && (
            <>
              <div className="magma-hdfc-icici">
                {insurances && insurances.length > 0 ? (
                  insurances.map((insurance) => (
                    <Button
                      key={insurance.id}
                      className={`magma-hdfc-icici-tabs ${
                        activeButton === insurance.id ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick(insurance)}
                    >
                      {insurance.insurance_company_detail__name}
                    </Button>
                  ))
                ) : (
                  <div className="no_data_found_message">
                    <CustomNoData />
                  </div>
                )}
              </div>
              {insurances && insurances.length > 0 && renderInsuranceView()}
            </>
          )}
        </div>
        <div className="heading_details_container">
          <div
            className={`charges_heading ${
              chargesCollapseSections ? "" : "no-border"
            }`}
          >
            <div className="charges">Charges</div>
            <div className="charges-underwriting-buttons">
              <div
                onClick={() => {
                  showChargesDrawer();
                  // setSelectedCharges(id);
                  // setSelectedPerson(person);
                }}
              >
                <Button
                  icon={<PlusCircleOutlined />}
                  className="add-charges-button"
                  // onClick={() => showDrawer("External")}
                >
                  Add Charges
                </Button>
              </div>
              {chargesCollapseSections ? (
                <UpOutlined
                  onClick={() =>
                    setChargesCollapseSections(!chargesCollapseSections)
                  }
                />
              ) : (
                <DownOutlined
                  onClick={() =>
                    setChargesCollapseSections(!chargesCollapseSections)
                  }
                />
              )}
            </div>
          </div>
          {chargesCollapseSections && (
            <>
              {chargesData ? (
                <div className="charges_table_container">
                  <Table
                    className="insurance_table"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                </div>
              ) : (
                <Loading loading={loading} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LoanInsurance;
