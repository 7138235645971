import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  message,
} from "antd";
import * as Yup from "yup";
import {
  getDepartmentListService,
  getDesignationListService,
  getLocationListService,
  getUserWorkDetailsService,
  listOfAuthorityLevelService,
  listOfUsersService,
  updateUserWorkDetailsService,
} from "../services/userService";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";
import dayjs from "dayjs";

const validationSchema = Yup.object().shape({
  department: Yup.string().required("Department is required"),
  designation: Yup.string().required("Designation is required"),
  location: Yup.string().required("Location is required"),
  date_of_joining: Yup.date()
    .nullable() // Allows null values
    .transform((value, originalValue) => (originalValue === "" ? null : value)) // Converts "" to null
    .required("Date of joining is required")
    .max(new Date(), "Date of joining cannot be in the future"),
  confirmation_status: Yup.string()
    .required("Confirmation status is required")
    .oneOf(["Probation", "Confirm"], "Invalid confirmation status"),
  l1_manager: Yup.string().required("L1 Manager is required"),
  l2_manager: Yup.string().required("L2 Manager is required"),
  authority_level: Yup.mixed().required("Authority Level is required"),
});
const yupSync = {
  async validator({ field }, value) {
    await validationSchema.validateSyncAt(field, { [field]: value });
  },
};

const WorkDetailsForm = ({
  onNext,
  onCancel,
  closeDrawer,
  open,
  id,
  userData,
  currentRoute,
}) => {
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState([]);
  const [desigantions, setDesigantions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [, setSelectedDepartment] = useState(null);
  const [designationDisabled, setDesignationDisabled] = useState(true);
  const [users, setUsers] = useState([]);
  const [authorityLevel, setAuthorityLevel] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersResponse = await listOfUsersService();
        setUsers(usersResponse.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };

    const fetchAuthorityLevel = async () => {
      try {
        const usersResponse = await listOfAuthorityLevelService();
        console.log("usersResponse", usersResponse);
        setAuthorityLevel(usersResponse.data);
      } catch (error) {
        message.error(
          ErrorMessage(error?.response?.status, error?.response?.data?.message)
        );
      }
    };
    fetchUsers();
    fetchAuthorityLevel();
  }, [open]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch department and location lists
        const departmentResponse = await getDepartmentListService();
        setDepartments(departmentResponse.data);

        const locationResponse = await getLocationListService();
        setLocations(locationResponse.data);

        if (id && open) {
          // Editing existing data
          const workDetailsResponse = await getUserWorkDetailsService(id);
          const {
            department,
            designation,
            location,
            date_of_joining,
            confirmation_status,
            authority_level,
            l1_manager,
            l2_manager,
          } = workDetailsResponse?.data;

          if (department?.id) {
            const designationResponse = await getDesignationListService(
              department.id
            );
            setDesigantions(designationResponse.data);
          }

          // Set form fields with fetched data
          form.setFieldsValue({
            department: department?.id,
            designation: designation?.id,
            location: location?.id,
            date_of_joining: date_of_joining
              ? dayjs(date_of_joining, "YYYY-MM-DD")
              : "",
            confirmation_status,
            authority_level,
            l1_manager: l1_manager?.id,
            l2_manager: l2_manager?.id,
          });

          // Update selected department and location
          setSelectedDepartment(department?.id);
        } else {
          // Adding new data
          form.resetFields();
          setSelectedDepartment(null);
          setDesignationDisabled(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, open, form]);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async (values) => {
    try {
      // Check if the current route is "/user_profile", if so, skip the rest
      if (currentRoute === "/user_profile") {
        onNext();
        return;
      }

      // Format the date_of_joining if it's available and is a moment object
      const formattedDateOfJoining = values.date_of_joining
        ? values.date_of_joining.format("YYYY-MM-DD") // Ensure it is in YYYY-MM-DD format
        : null; // If no date is provided, set it to null

      // Update values with the formatted date_of_joining
      const updatedValues = {
        ...values,
        date_of_joining: formattedDateOfJoining, // Add formatted date here
      };

      if (id) {
        const response = await updateUserWorkDetailsService(id, updatedValues);
        if (response.status === 200 && response.success) {
          message.success("User Work Details Successfully Updated");
        } else {
          message.error("Failed to update user work details");
        }
      }

      onNext();
    } catch (error) {
      form.setFieldsValue({
        department: "",
        designation: "",
        location: "",
        date_of_joining: "",
        confirmation_status: "",
        authority_level: "",
        l1_manager: "",
        l2_manager: "",
      });
      message.error("An error occurred while updating user work details");
    }
  };

  const handleDepartmentChange = async (value) => {
    setSelectedDepartment(value);
    setDesignationDisabled(false);
    // Reset the designation field when department changes
    form.setFieldsValue({ designation: undefined });
    try {
      const designationResponse = await getDesignationListService(value);
      setDesigantions(designationResponse.data);
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "An error occurred while fetching designations!"
      );
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{
        department: userData?.department?.display_name || "",
        designation: userData?.designation?.display_name || "",
        location: userData?.location?.display_name || "",
        date_of_joining: userData?.date_of_joining
          ? dayjs(userData.date_of_joining, "YYYY-MM-DD")
          : null,
        confirmation_status: userData?.confirmation_status || "",
        authority_level: userData?.authority_level || "",
        l1_manager: userData?.l1_manager?.name || "",
        l2_manager: userData?.l2_manager?.name || "",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="department"
            label={<span>Department</span>}
            required
            rules={[yupSync]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label
                  .toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              onChange={handleDepartmentChange}
              disabled={currentRoute === "/user_profile"}
            >
              {departments.map((department) => (
                <Select.Option
                  key={department.id}
                  value={department.id}
                  label={department.display_name}
                >
                  {department.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="designation"
            label={<span>Designation</span>}
            required
            rules={[yupSync]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label
                  .toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              disabled={designationDisabled || currentRoute === "/user_profile"}
            >
              {desigantions.map((designation) => (
                <Select.Option
                  key={designation.id}
                  value={designation.id}
                  label={designation.display_name}
                >
                  {designation.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="location"
            label={<span>Location</span>}
            required
            rules={[yupSync]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.label
                  .toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              disabled={currentRoute === "/user_profile"}
            >
              {locations.map((location) => (
                <Select.Option
                  key={location.id}
                  value={location.id}
                  label={location.display_name}
                >
                  {location.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="date_of_joining"
            label="Date of Joining"
            rules={[yupSync]}
            required
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              placeholder="DD-MM-YYYY"
              disabled={currentRoute === "/user_profile"}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="confirmation_status"
            label="Confirmation Status"
            required
            rules={[yupSync]}
          >
            <Select
              placeholder="Select Status"
              disabled={currentRoute === "/user_profile"}
            >
              <Select.Option value="Probation">Probation</Select.Option>
              <Select.Option value="Confirm">Confirm</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="authority_level"
            label="Authority Level"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                form.setFieldsValue({ status: value });
              }}
              disabled={currentRoute === "/user_profile"}
            >
              {authorityLevel?.map((authority) => (
                <Select.Option key={authority} value={authority}>
                  {authority}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="l1_manager"
            label="L1 Manager"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                form.setFieldsValue({ status: value });
              }}
              disabled={currentRoute === "/user_profile"}
            >
              {users?.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="l2_manager"
            label="L2 Manager"
            rules={[yupSync]}
            required
          >
            <Select
              placeholder="Please select"
              allowClear
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                form.setFieldsValue({ status: value });
              }}
              disabled={currentRoute === "/user_profile"}
            >
              {users?.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {currentRoute === "/users" && (
        <Space
          direction="vertical"
          align="center"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button type="primary" htmlType="submit">
            Next
          </Button>
          <Button onClick={handleCancel}>Prev</Button>
          <Button onClick={closeDrawer}>Cancel</Button>
        </Space>
      )}
    </Form>
  );
};

export default WorkDetailsForm;
