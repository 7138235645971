import React from 'react';
import { Table, Button, Tooltip, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ConditionalRender from '../../authorization/AuthorizeComponent';
import { useSelector } from 'react-redux';

const CommonManualDeviation = ({ 
    manualDeviationData, 
    showDrawer, 
    setSelectedManual, 
    setManualDataValue, 
    toggleRefreshTableData,
    setManualDeviationData,
    deviationList 
}) => {
    const { user_data } = useSelector((state) => state.user);

    const onChange = (pagination, filters, sorter, extra) => {
        // Handle table changes if needed
    };

    const manualDeviation = manualDeviationData?.map((item, index) => {
        const deviationName = deviationList.find((dev) => dev.id === item.deviation)?.name;
        // Create a unique composite key from all relevant fields and index
        const uniqueKey = `${item.deviation}-${item.actual_description}-${item.mitigates}-${index}`;
        
        return {
            uniqueKey,  // Store the unique key
            key: uniqueKey,  // Use it as the row key
            deviation: deviationName,
            actual_deviations: item.actual_description,
            mitigates: item?.mitigates,
            originalItem: {...item, uniqueKey}  // Include uniqueKey in original item
        };
    });

    const columns2 = [
        {
            title: 'Sr. No.',
            dataIndex: 'no',
            render: (_, __, index) => index + 1,
        },
        {
            title: 'Discription',
            dataIndex: 'deviation',
        },
        {
            title: 'Deviation',
            dataIndex: 'actual_deviations',
        },
        {
            title: 'Mitigates',
            dataIndex: 'mitigates',
        },
        {
            title: 'Actions',
            render: (_, record) => (
                <>
                    <ConditionalRender
                        userPermissions={user_data?.permissions}
                        requiredPermissions={["EDITDEVT"]}
                    >
                        <Tooltip title="Edit">
                            <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={() => handleEdit(record.originalItem)}
                            />
                        </Tooltip>
                    </ConditionalRender>
                    <ConditionalRender
                        userPermissions={user_data?.permissions}
                        requiredPermissions={["DELTDEVT"]}
                    >
                        <Tooltip title="Remove">
                            <Button
                                type="link"
                                icon={<DeleteOutlined style={{ color: 'red' }} />}
                                onClick={() => handleRemove(record.originalItem)}
                            />
                        </Tooltip>
                    </ConditionalRender>
                </>
            ),
        },
    ];

    const handleEdit = (item) => {
        showDrawer();
        setSelectedManual(true);
        setManualDataValue(item);
    };

    const handleRemove = (item) => {
        // Remove the specific row using the uniqueKey
        const updatedData = manualDeviationData.filter((_, index) => {
            const currentKey = `${item.deviation}-${item.actual_description}-${item.mitigates}-${index}`;
            return currentKey !== item.uniqueKey;
        });
        
        setManualDeviationData(updatedData);
        message.success("Manual Deviation deleted successfully");
    };

    return (
        <>
            <div className='decision_heading'>
                Manual Deviation
            </div>
            <div className="table_container">
                <Table
                    className="insurance_table"
                    columns={columns2}
                    dataSource={manualDeviation}
                    onChange={onChange}
                    pagination={false}
                    style={{ marginBottom: "20px" }}
                />
            </div>
        </>
    );
};

export default CommonManualDeviation;