import { Avatar, Card, message, Popover, Spin, Typography } from "antd";
import React, { useState } from "react";
import { axiosRequest } from "../../../utils/api/axiosRequest";
import ErrorMessage from "../../../utils/errorHandling/ErrorMessage";

const UserPopover = ({ username, part,isCurrentUser }) => {
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  // Extract number between @ symbols
  const extractNumber = (text) => {
    // This regex looks for any numbers that appear in the text between @ symbols
    const match = text.match(/@.*?(\d+).*?@/);
    return match ? match[1] : null;
  };

  // Remove numbers from username
  const cleanUsername = (username) => {
    return username.replace(/\d+/g, "");
  };

  const fetchUserInfo = async () => {
    // Don't fetch if we already have the data
    if (userInfo) return;

    setLoading(true);
    setError(null);

    try {
      const id = extractNumber(part) || 14; // Fallback to 14 if no ID found

      const response = await axiosRequest.get(
        `/api/v1/communication/messages/get-user-info-by-uer-id/${id}`
      );

      if (response.data.data) {
        setUserInfo(response.data.data);
      } else {
        setError("User information not found");
      }
    } catch (error) {
      message.error(
        ErrorMessage(error?.response?.status, error?.response?.data?.message)
      );
      // setError("Failed to load user information");
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (loading) {
      return <Spin size="small" />;
    }

    if (error) {
      return <Text type="danger">{error}</Text>;
    }

    if (!userInfo) {
      return <Spin size="small" />;
    }

    const {
      name,
      profile_img,
      thumb_profile_img,
      department,
      designation,
      user_type,
    } = userInfo;

    return (
      <Card style={{ width: 300 }} hoverable>
        <Avatar
          size={64}
          src={thumb_profile_img || profile_img}
          alt={`${name}'s Profile`}
          style={{ marginBottom: 16 }}
        />
        <Title level={4}>{name}</Title>
        <Text>
          <strong>Department:</strong> {department}
        </Text>
        <br />
        <Text>
          <strong>Designation:</strong> {designation}
        </Text>
        <br />
        <Text>
          <strong>User Type:</strong> {user_type}
        </Text>
      </Card>
    );
  };

  return (
    <Popover
      content={renderContent()}
      trigger="hover"
      placement="top"
      onMouseEnter={fetchUserInfo}
    >
      <Text
        strong
        style={{
          color:isCurrentUser ? "black": "white",
          cursor: "pointer",
          margin: "0 8px", // Adds gap on left and right
        }}
      >
        {`@${cleanUsername(username)}`}
      </Text>
    </Popover>
  );
};

export default UserPopover;
