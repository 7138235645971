
import { Button, Form, Input, Table, message, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../../../../utils/errorHandling/ErrorMessage';
import { createBankBalance, getAllBankBalanceByLoanId, updateBankBalaceByIdService } from '../services/monthlyExpensesService';
import { decrypt } from '../../../../../utils/cryptoUtils/cryptoUtils';
import { listMonthYear } from '../services/incomeDetailsServices';

const BankBalance = ({ closeForm, customer_id, bank_id, refreshData, id, open, refreshTableIncomeData }) => {
  const [form] = Form.useForm();
  const [allMonthYear, setAllMonthYear] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const { encrypted_loan_id } = useParams();

  let loan_id = null;

  try {
    loan_id = decrypt(encrypted_loan_id);
  } catch (error) {
    message.error('Invalid Loan ID');
  }


  useEffect(() => {
    const fetchMonthYear = async () => {
      try {
        const response = await listMonthYear();
        setAllMonthYear(response.data);
      } catch (error) {
        message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
      }
    };

    fetchMonthYear();
  }, []);

  useEffect(() => {
    const fetchBankBalanceData = async () => {
      if (id && customer_id) {
        try {
          const response = await getAllBankBalanceByLoanId(id);
          if (response.status === 200 && response.success) {
            const transformedData = response.data.results.flatMap(customer => {
              const balanceMap = {};
              customer.bank_balance_details.forEach(balance => {
                const month = new Date(balance.bank_balance_date).toLocaleString('default', { month: 'long', year: 'numeric' });
                if (!balanceMap[month]) {
                  balanceMap[month] = {
                    month,
                    balance_5: null,
                    balance_15: null,
                    balance_25: null,
                    id: balance.id,
                  };
                }
                const day = new Date(balance.bank_balance_date).getDate();
                if (day === 5) {
                  balanceMap[month].balance_5 = {
                    balance: balance.balance_amount,
                    id: balance.id,
                  };
                } else if (day === 15) {
                  balanceMap[month].balance_15 = {
                    balance: balance.balance_amount,
                    id: balance.id,
                  };
                } else if (day === 25) {
                  balanceMap[month].balance_25 = {
                    balance: balance.balance_amount,
                    id: balance.id,
                  };
                }
              });
              return Object.values(balanceMap);
            });
            setBankDetails(transformedData);
          }
        } catch (error) {
          message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
        }
      }

    };

    if (open) {
      fetchBankBalanceData();
    } else {
      form.resetFields();
    }
  }, [open, id, customer_id, form]);

  const handleSubmit = async (values) => {

    try {
      if (id) {
        const bankDetailsFromForm = values.bankDetails;
        const bankBalancesToUpdate = bankDetailsFromForm.flatMap((item, index) => {
          const monthYear = bankDetails[index]?.month;

          const [month, year] = monthYear.split(' ');
          const monthNumber = new Date(Date.parse(`${month} 1, ${year}`)).getMonth() + 1;


          const formattedMonth = String(monthNumber).padStart(2, '0');
          const bankDetail = bankDetails[index];


          return [
            {
              id: bankDetail.balance_5?.id,
              bank_balance_date: `${year}-${formattedMonth}-05`,
              balance_amount: item.balance_5,
            },
            {
              id: bankDetail.balance_15?.id,
              bank_balance_date: `${year}-${formattedMonth}-15`,
              balance_amount: item.balance_15,
            },
            {
              id: bankDetail.balance_25?.id,
              bank_balance_date: `${year}-${formattedMonth}-25`,
              balance_amount: item.balance_25,
            },
          ];
        });

        const response = await updateBankBalaceByIdService(bankBalancesToUpdate);
        if (response.status === 200 && response.success) {
          message.success('Bank Balance successfully updated');
          form.resetFields();
          refreshData();
          refreshTableIncomeData();
          closeForm();
        }
      } else {

        const bankformattedValues = values.bankDetails.map((detail, index) => {
          const monthYear = allMonthYear[index];

          const [monthString, year] = monthYear.split(' '); // Split it into month and year
          const monthMap = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12'
          };

          const month = monthMap[monthString];

          return [
            { bank_balance_date: `${year}-${month}-05`, balance_amount: detail.balance_5, customer_detail: customer_id, loan_detail: loan_id, bank_detail: bank_id },
            { bank_balance_date: `${year}-${month}-15`, balance_amount: detail.balance_15, customer_detail: customer_id, loan_detail: loan_id, bank_detail: bank_id },
            { bank_balance_date: `${year}-${month}-25`, balance_amount: detail.balance_25, customer_detail: customer_id, loan_detail: loan_id, bank_detail: bank_id },
          ];
        }).flat().filter(Boolean);

        const response = await createBankBalance(bankformattedValues);
        if (response.status === 200 && response.success) {
          message.success('Bank Balance details successfully created');
          closeForm();
          refreshData();
          refreshTableIncomeData();
        }
      }
    } catch (error) {
      message.error(ErrorMessage(error?.response?.status, error?.response?.data?.message));
    }
  };

  return (
    <div>
      <Form
        form={form}
        name="bank-balance-form"
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{ bankDetails: allMonthYear.map(month => ({ month })) }}
      >
        <Form.List name="bankDetails">

          {(fields) => (
            <>
              <div className="table-responsive">
                <Table
                  columns={[
                    {
                      title: 'Month & Year',
                      dataIndex: 'month',
                      key: 'month',
                      render: (_, record) => <div>{record.month}</div>,
                    },
                    {
                      title: 'Day 5',
                      dataIndex: 'balance_5',
                      key: 'balance_5',
                      render: (_, record, index) => (
                        <Form.Item
                          name={[index, 'balance_5']}
                          initialValue={record.balance_5?.balance || ''}
                          rules={[{ required: true, message: 'Please input balance for Day 5!' }]}
                        >
                          <Input placeholder="Balance for Day 5" />
                        </Form.Item>
                      ),
                    },
                    {
                      title: 'Day 15',
                      dataIndex: 'balance_15',
                      key: 'balance_15',
                      render: (_, record, index) => (
                        <Form.Item
                          name={[index, 'balance_15']}
                          initialValue={record.balance_15?.balance || ''}
                          rules={[{ required: true, message: 'Please input balance for Day 15!' }]}
                        >
                          <Input placeholder="Balance for Day 15" />
                        </Form.Item>
                      ),
                    },
                    {
                      title: 'Day 25',
                      dataIndex: 'balance_25',
                      key: 'balance_25',
                      render: (_, record, index) => (
                        <Form.Item
                          name={[index, 'balance_25']}
                          initialValue={record.balance_25?.balance || ''}
                          rules={[{ required: true, message: 'Please input balance for Day 25!' }]}
                        >
                          <Input placeholder="Balance for Day 25" />
                        </Form.Item>
                      ),
                    },
                  ]}
                  dataSource={id ? bankDetails : allMonthYear.map(month => ({ month }))}
                  rowKey={(record) => record.month}
                  pagination={false}
                />
              </div>

            </>
          )}
        </Form.List>

        <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={closeForm}>Cancel</Button>
        </Space>
      </Form>
    </div>
  );
};

export default BankBalance;
